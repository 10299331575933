import React from "react"

import { Box } from "./Box"
import { COLORS } from "./theme/colors"
import { useIsDarkMode } from "./ThemeProvider"

import type { BoxProps } from "./Box"

/**
 * A component that displays a horizontal divider.
 */
export function Divider({ style, ...rest }: BoxProps) {
  const isDark = useIsDarkMode()
  return (
    <Box
      {...rest}
      style={[
        {
          backgroundColor: isDark
            ? COLORS.$divider.dark
            : COLORS.$divider.light,
          height: 1,
        },
        style,
      ]}
    />
  )
}
