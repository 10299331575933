/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_500Medium,
  OpenSans_600SemiBold,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
} from "@expo-google-fonts/open-sans"
import {
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_800ExtraBold,
} from "@expo-google-fonts/poppins"
import {
  RobotoSlab_500Medium,
  RobotoSlab_700Bold,
  RobotoSlab_900Black,
} from "@expo-google-fonts/roboto-slab"

export const PRELOAD_FONTS = {
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_500Medium,
  OpenSans_600SemiBold,
  OpenSans_700Bold,
  RobotoSlab_700Bold,
  OpenSans_700Bold_Italic,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_800ExtraBold,
  RobotoSlab_500Medium,
  RobotoSlab_900Black,
}
export type CachedFontName = keyof typeof PRELOAD_FONTS

/**
 * Type-safe font name getter
 */
export function getFontName(key: CachedFontName) {
  if (__DEV__) {
    if (!Object.keys(PRELOAD_FONTS).includes(key)) {
      throw new TypeError(`Invalid font name: ${key}`)
    }
  }
  return key
}

export const FONT_SIZES = {
  $3xs: 8,
  $2xs: 10,
  $xs: 12,
  $sm: 14,
  $md: 16,
  $lg: 18,
  $xl: 20,
  $body: 16,
  $h1: 28,
  $h2: 24,
  $h3: 20,
  $h4: 18,
  $h5: 16,
  $h6: 14,
} as const
