import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { COLORS, RADII, SPACING } from "./theme"
import { useIsDarkMode } from "./ThemeProvider"
import { View } from "./View"

import type { ViewProps } from "./View"

export const OUTLINE_STYLE = StyleSheet.create({
  root: {
    borderColor: COLORS.$divider.light,
    borderRadius: RADII.$default,
    borderWidth: 1,
    paddingHorizontal: SPACING.$2,
    paddingVertical: SPACING.$1,
  },
  rootDark: {
    borderColor: COLORS.$divider.dark,
  },
})

export function Outline({ style, ...rest }: ViewProps) {
  const isDark = useIsDarkMode()
  return (
    <View
      style={useStyle(() => {
        return [
          OUTLINE_STYLE.root,
          isDark ? OUTLINE_STYLE.rootDark : undefined,
          style,
        ]
      }, [isDark, style])}
      {...rest}
    />
  )
}
