import React from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { ActionButtons } from "./ActionButtons"
import {
  AppIcons,
  Box,
  Button,
  Divider,
  FlatList,
  getIconForSensor,
  MenuItemButton,
} from "./components"
import { AppText } from "./components/Text"
import { SPACING } from "./components/theme"
import { TitleWithIcon } from "./components/TitleWithIcon"
import * as CreateDeviceConfiguration from "./CreateConfiguration"
import { isSensorCompatibleWithConfiguration } from "./device-configurations.reducer"
import {
  ChooseSensorPort,
  PressureSliderConnected,
  SensorTextInput,
} from "./DeviceSensorFields"
import { CONFIG_SENSOR_NAMES } from "./sensor-configurations"
import { useFormatSensorName } from "./sensor-formatting"
import { isMutableSensorName } from "./sensors"

import type {
  FlatListProps,
  StyleProp,
  ViewProps,
  ViewStyle,
} from "react-native"
import type { DeviceConfiguration } from "./device-configurations.reducer"
import type { ConfigSensorName, SensorName } from "./sensor-configurations"
import type { ProtectedButtonProps } from "./types"
export type AddableSensorName = SensorName<"flow" | "pressure">
/**
 *
 */
function FormWrapper({
  children,
  sensorName,
  ...rest
}: ViewProps & { sensorName: AddableSensorName }) {
  const { t } = useTranslation("deviceConfiguration")
  const { isLoading, onCancel, onSubmit } =
    CreateDeviceConfiguration.useContext()
  const formatSensorName = useFormatSensorName()
  const titleText: string = t("addSensorTitleWithSensorName", {
    sensorName: formatSensorName(sensorName),
  })
  return (
    <View {...rest}>
      <TitleWithIcon
        IconComponent={getIconForSensor(sensorName)}
        titleText={titleText}
      />
      {children}
      <ActionButtons
        isLoading={isLoading}
        mt="$4"
        onPressCancel={onCancel}
        onPressSubmit={onSubmit}
      />
    </View>
  )
}

function FormComponent({
  selectedSensorName,
  viewStyle,
}: {
  selectedSensorName: AddableSensorName
  viewStyle?: StyleProp<ViewStyle>
}) {
  switch (selectedSensorName) {
    case "flow": {
      return (
        <FormWrapper sensorName="flow" style={viewStyle}>
          <SensorTextInput
            fieldName="mlPerPulse"
            flex={1}
            minW="$32"
            sensorName="flow"
          />
          <Divider my="$4" />
          <ChooseSensorPort sensorName="flow" />
        </FormWrapper>
      )
    }
    case "pressure": {
      return (
        <FormWrapper sensorName="pressure" style={viewStyle}>
          <Box>
            <Box mb="$4">
              <PressureSliderConnected />
            </Box>
            <ChooseSensorPort sensorName="pressure" />
          </Box>
        </FormWrapper>
      )
    }
  }
}

/**
 *
 */
export function AddSensorLinkButton({
  onPress,
  trackEvent,
  withPermissions,
  ...rest
}: ProtectedButtonProps): React.JSX.Element {
  const { t } = useTranslation("deviceConfiguration")
  return (
    <Button
      IconComponent="Add"
      id="add-sensor-btn"
      text={t("addSensor")}
      {...rest}
      onPress={withPermissions({
        callback: () => {
          onPress()
          trackEvent({
            elementName: "add-sensor-btn",
            name: "element_press",
          })
        },
        required: "canManageDeviceConfiguration",
      })}
    />
  )
}

export function SelectSensor({
  configuration,
  onChange,
  onError,

  ...rest
}: Pick<FlatListProps<ConfigSensorName>, "contentContainerStyle" | "style"> & {
  configuration: DeviceConfiguration | undefined
  onChange: (sensorName: AddableSensorName) => void
  onError: (text: string) => void
}) {
  const { t } = useTranslation("deviceConfiguration")
  const formatSensorName = useFormatSensorName()

  return (
    <FlatList
      {...rest}
      ItemSeparatorComponent={Divider}
      data={CONFIG_SENSOR_NAMES.filter(isMutableSensorName)}
      scrollEnabled={false}
      ListHeaderComponent={
        <Box>
          <TitleWithIcon
            IconComponent={AppIcons.Sensor}
            titleText={t("addSensor")}
          />
          <AppText>{t("addSensorInstructions")}</AppText>
        </Box>
      }
      renderItem={({ item: sensorName }) => {
        const sensorNameFormatted = formatSensorName(sensorName)
        const handlePress = () => {
          if (
            !isSensorCompatibleWithConfiguration({
              configuration,
              sensorName,
            })
          ) {
            return onError(t("addSensorNotCompatibleErrorMessage"))
          }
          if (configuration && configuration[sensorName]) {
            return onError(
              t("addSensorAlreadyAddedWithSensorNameErrorMessage", {
                sensorName: sensorNameFormatted,
              }),
            )
          }
          if (sensorName !== "flow" && sensorName !== "pressure") {
            return onError(t("addSensorCannotAddToExistingErrorMessage"))
          }
          return onChange(sensorName)
        }
        const iconComponent = getIconForSensor(sensorName)
        return (
          <MenuItemButton
            key={sensorName}
            IconComponent={iconComponent}
            style={{ paddingVertical: SPACING.$3 }}
            text={sensorNameFormatted}
            onPress={handlePress}
          />
        )
      }}
    />
  )
}

/**
 *
 */
export function AddSensorForm({
  configuration,
  onClose: handleClose,
  onSuccess: handleSuccess,
  sensorName,
  viewStyle,
}: {
  configuration: DeviceConfiguration
  onClose: () => void
  onSuccess: () => void
  sensorName: AddableSensorName
  viewStyle?: StyleProp<ViewStyle>
}) {
  return (
    <CreateDeviceConfiguration.Provider
      codaDeviceAlias={configuration.codaDeviceAlias}
      currentConfigurationData={{ ...configuration }}
      deviceId={configuration.deviceId}
      deviceInstallationType={configuration.deviceInstallationType}
      deviceName={configuration.deviceName}
      hardwareGeneration={configuration.hardwareGeneration}
      onCancel={handleClose}
      onClearInstallationType={null}
      onSuccess={handleSuccess}
    >
      <FormComponent selectedSensorName={sensorName} viewStyle={viewStyle} />
    </CreateDeviceConfiguration.Provider>
  )
}
