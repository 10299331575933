import React from "react"
import { useStyle } from "react-native-style-utilities"

import { Box } from "./Box"
import { COLORS } from "./theme/colors"
import { useIsDarkMode } from "./ThemeProvider"

import type { BoxProps } from "./Box"

export interface PaperProps extends BoxProps {
  isDisabled?: boolean
}

/**
 * A component that displays a paper-like surface with a shadow.
 */
export function Paper({ children, isDisabled, style, ...rest }: PaperProps) {
  const isDark = useIsDarkMode()
  return (
    <Box
      {...rest}
      style={useStyle(() => {
        let backgroundColor = COLORS.$paper.light
        if (isDark) {
          backgroundColor = COLORS.$paper.dark
        }

        if (isDisabled === true) {
          if (isDark) {
            backgroundColor = COLORS.$gray[700]
          } else {
            backgroundColor = COLORS.$gray[200]
          }
        }
        return [{ backgroundColor }, style]
      }, [isDark, isDisabled, style])}
    >
      {children}
    </Box>
  )
}
