import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"
import { generatePath, useNavigate } from "react-router-dom"

import { DeviceCycler, DeviceSummaryContext, RenameDevice } from "@fhq/app"
import {
  AlertDialog,
  AlertDialogScrollView,
  AppIcons,
  AppText,
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Menu,
  Pressable,
  Row,
  useBreakpointValue,
} from "@fhq/app/components"
import { SIZES, SPACING } from "@fhq/app/components/theme"
import { InstallationTypeIndicator } from "@fhq/app/InstallationTypeIndicator"

import { SEGMENT_CLIENT, useTrackEvent } from "./base/Analytics"
import { Environment } from "./base/base"
import { CustomNavLink, MenuItemNavLink } from "./base/CustomLink"
import { ScrollingContent } from "./base/Layout"
import { usePermissionCheckedAction } from "./base/usePermissionCheckedAction"
import { useCurrentRoutePath } from "./base/useRoutePath"

import type { AcceptsChildren } from "@fhq/app/components"

import type { SelectDeviceHandler, OptionalTestId } from "@fhq/app"

const STYLE = {
  mapContainer: {
    flex: 1,
    minHeight: 200,
    minWidth: 200,
  },
  menuLink: {
    paddingBottom: SPACING.$1,
    paddingTop: SPACING.$1,
  },
}

/**
 * Common layout for each device profile page
 */
function useDeviceProfileLayout() {
  const { codaDeviceAlias } = DeviceSummaryContext.useContext()

  const [isRenaming, setIsRenaming] = React.useState(false)

  const navigate = useNavigate()
  const currentPath = useCurrentRoutePath()
  // Relative path to device configuration page
  const configPath = "configuration"

  let nextPath = currentPath
  // split url into sections e.g.['', 'devices', ':codaDeviceAlias', 'pairs']
  const urlSections = nextPath.split("/")
  // the third item is the current sub-page in the device profile
  const pagePath = urlSections[3]

  if (pagePath === configPath) {
    /*
     * The configuration sub-path has additional url params - sensorName and
     * fieldName. These may not be valid for the next device when cycling
     * through devices, so we fallback to the base configuration route.
     * For example, given devices A and B, on /devices/A/configuration/pump, the
     * device cycler button will lead to /devices/B/configuration.
     */
    nextPath = urlSections.slice(0, 4).join("/")
  }

  /**
   * The :codaDeviceAlias route param is replaced here with the next device's
   * actual coda device alias value
   */
  const handleCycleDevice: SelectDeviceHandler = React.useCallback(
    (nextDevice) => {
      const value = nextPath.replace(
        ":codaDeviceAlias",
        nextDevice.codaDeviceAlias,
      )
      setIsRenaming(false)
      navigate(value)
    },
    [navigate, nextPath],
  )

  return {
    codaDeviceAlias,
    handleCycleDevice,
    isRenaming,
    pages: React.useMemo(() => {
      return (
        [
          {
            IconComponent: AppIcons.Timeline,
            id: "device-activity",
            name: i18next.t("deviceActivity.linkTo", { ns: "devices" }),
            relativePath: "",
          },
          {
            IconComponent: AppIcons.DeviceCommandsRemote,
            id: "device-command-history",
            name: i18next.t("deviceActionHistoryListTitle", {
              ns: "deviceActions",
            }),
            relativePath: "command-history",
          },
          {
            IconComponent: AppIcons.DeviceConfiguration,
            id: "device-configuration",
            name: i18next.t("linkTo", { ns: "deviceConfiguration" }),
            pages: {
              createConfiguration: "create",
              sensor: {
                fieldName: ":fieldName",
                root: ":sensorName",
              },
            },
            relativePath: "configuration",
          },
          {
            IconComponent: AppIcons.History,
            id: "device-event-history",
            name: i18next.t("deviceEventHistory:linkTo"),
            relativePath: "event-history",
          },
          {
            IconComponent: AppIcons.NotificationsOn,
            id: "device-notifications",
            name: i18next.t("deviceNotifications:notificationRules"),
            relativePath: "notifications",
          },
        ] as const
      ).map((value) => {
        const absolutePath =
          `/devices/:codaDeviceAlias/${value.relativePath}` as const
        return {
          ...value,
          absolutePath: generatePath(absolutePath, { codaDeviceAlias }),
          linkId: `${value.id}-link` as const,
          name: value.name,
        }
      })
    }, [codaDeviceAlias]),
    setIsRenaming,
  }
}

type ContextValue = ReturnType<typeof useDeviceProfileLayout>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
}: AcceptsChildren): React.JSX.Element | null {
  const value = useDeviceProfileLayout()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(
      `DeviceProfileLayout Context must be used inside of provider`,
    )
  }
  return ctx
}

/**
 *
 */
function NavMenu(): React.JSX.Element {
  const { t } = useTranslation()
  const { pages } = useContext()
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const currentPage = pages.find((page) => {
    // TODO: pages nested in configuration should turn up as 'configuration'
    // here
    if (page.absolutePath === location.pathname) {
      return true
    }
    return false
  })

  const titleText =
    currentPage?.name ?? t("deviceActivity.title", { ns: "devices" })
  const menuTitle = t("pages")
  const handleClose = () => setIsMenuOpen(false)
  return (
    <React.Fragment>
      <Button
        IconComponent={currentPage?.IconComponent ?? "ExpandMore"}
        RightIconComponent={AppIcons.ExpandMore}
        id="nav-menu-open-btn"
        text={titleText}
        onPress={() => setIsMenuOpen(true)}
      />
      <Menu id="nav-menu" isOpen={isMenuOpen} onClose={handleClose}>
        <AppText colorScheme="secondary">{menuTitle}</AppText>
        {pages.map((page): React.JSX.Element => {
          return (
            <MenuItemNavLink
              key={page.id}
              IconComponent={page.IconComponent}
              id={page.linkId}
              to={page.absolutePath}
              onClick={handleClose}
            >
              {page.name}
            </MenuItemNavLink>
          )
        })}
      </Menu>
    </React.Fragment>
  )
}

interface LayoutProps extends AcceptsChildren, OptionalTestId {
  showCycler?: boolean
  showRenameButton?: boolean
}

/**
 * Show selected device name and allows user to rename it
 */
function DeviceNameDisplay({
  showRenameButton = false,
}: Pick<LayoutProps, "showRenameButton">): React.JSX.Element {
  const trackEvent = useTrackEvent()
  const { isRenaming, setIsRenaming } = useContext()
  const { t } = useTranslation("deviceConfiguration")
  const { withPermissions } = usePermissionCheckedAction()
  const { deviceId, deviceInstallationType, deviceName } =
    DeviceSummaryContext.useContext()

  const handleCancelRename = () => setIsRenaming(false)
  const handlePressRename = withPermissions({
    callback: () => {
      setIsRenaming(true)
      trackEvent({
        elementName: "rename-device-btn",
        name: "element_press",
      })
    },
    required: "canManageDeviceConfiguration",
  })
  let renameFormElement: React.JSX.Element | null = null
  let nameElement = (
    <Heading isTruncated variant="h2">
      {deviceName}
    </Heading>
  )
  if (showRenameButton) {
    renameFormElement = (
      <AlertDialog
        isOpen={isRenaming}
        maxWidth={SIZES.$sm}
        titleElement={t("renameDevice")}
        onClose={handleCancelRename}
      >
        <AlertDialogScrollView>
          <RenameDevice.RenameDeviceForm
            deviceId={deviceId}
            deviceName={deviceName}
            onClosed={handleCancelRename}
          />
        </AlertDialogScrollView>
      </AlertDialog>
    )
    nameElement = (
      <Pressable
        accessibilityHint={t("renameThisDevice")}
        id="rename-device-btn"
        onPress={handlePressRename}
      >
        <Row pb="$2">
          {nameElement}
          <Box ml="$2">
            <AppIcons.Edit />
          </Box>
        </Row>
      </Pressable>
    )
  }
  return (
    <Box>
      {renameFormElement}
      {nameElement}
      <Box mr="auto" mt="$1">
        <InstallationTypeIndicator value={deviceInstallationType} />
      </Box>
    </Box>
  )
}

/**
 *
 */
function CycleButtons(): React.JSX.Element {
  const { codaDeviceAlias, handleCycleDevice } = useContext()
  return (
    <DeviceCycler
      analyticsClient={SEGMENT_CLIENT}
      codaDeviceAlias={codaDeviceAlias}
      environmentInfo={Environment}
      onPress={handleCycleDevice}
    />
  )
}

/**
 *
 */
function TitleRow(props: AcceptsChildren): React.JSX.Element {
  return (
    <Row alignItems="baseline" flexWrap="nowrap" justifyContent="space-between">
      {props.children}
    </Row>
  )
}

const LAYOUTS = {
  base: function Mobile({
    children,
    id,
    showCycler,
    showRenameButton,
  }: LayoutProps): React.JSX.Element {
    return (
      <Box id={id} pt="$4" px="$4">
        <TitleRow>
          <DeviceNameDisplay showRenameButton={showRenameButton} />
          {showCycler === true ? <CycleButtons /> : null}
        </TitleRow>
        {children}
        <Box ml="auto" my="$4">
          <NavMenu />
        </Box>
      </Box>
    )
  },
  lg: function Desktop({
    children,
    id,
    showCycler,
    showRenameButton,
  }: LayoutProps): React.JSX.Element {
    const { pages } = useContext()

    return (
      <Box id={id} maxW="$4xl" mx="auto" pt="$2">
        <TitleRow>
          <DeviceNameDisplay showRenameButton={showRenameButton} />
          {showCycler === true ? <CycleButtons /> : null}
        </TitleRow>
        <Row accessibilityRole="tabbar" id="nav-menu" mt="$2">
          {pages.map((page): React.JSX.Element => {
            return (
              <Box key={page.absolutePath} mx="$2">
                <CustomNavLink
                  IconComponent={page.IconComponent}
                  data-testid={`${page.id}-link`}
                  id={page.linkId}
                  style={STYLE.menuLink}
                  to={page.absolutePath}
                >
                  {page.name}
                </CustomNavLink>
              </Box>
            )
          })}
        </Row>
        <Divider my="$2" />
        <Box>{children}</Box>
      </Box>
    )
  },
  sm: function Tablet({
    children,
    id,
    showCycler,
    showRenameButton,
  }: LayoutProps): React.JSX.Element {
    return (
      <Container id={id} style={{ paddingVertical: SPACING.$2 }}>
        <TitleRow>
          <DeviceNameDisplay showRenameButton={showRenameButton} />
          <Row>
            <NavMenu />
            <Box ml="$2">{showCycler === true ? <CycleButtons /> : null}</Box>
          </Row>
        </TitleRow>
        <Box>{children}</Box>
      </Container>
    )
  },
} as const

/**
 *
 */
export function DeviceProfileLayout({
  children,
  showCycler,
  showRenameButton,
}: LayoutProps): React.JSX.Element | null {
  const getBreakpointValue = useBreakpointValue()
  const Component = getBreakpointValue(LAYOUTS)

  if (!Component) {
    return null
  }
  return (
    <Provider>
      <ScrollingContent>
        <Component
          id="device-profile"
          showCycler={showCycler}
          showRenameButton={showRenameButton}
        >
          {children}
        </Component>
      </ScrollingContent>
    </Provider>
  )
}
