import React from "react"
import { Platform, Pressable } from "react-native"

import type { PressableProps } from "react-native"
export function Backdrop({ style, ...rest }: PressableProps) {
  return (
    <Pressable
      importantForAccessibility="no"
      style={(state) => [
        {
          backgroundColor: "rgb(0, 0, 0)",
          bottom: 0,
          //@ts-ignore
          cursor: Platform.OS === "web" ? "default" : undefined,
          left: 0,
          opacity: 0.3,
          position: "absolute",
          right: 0,
          top: 0,
        },
        typeof style === "function" ? style(state) : style,
      ]}
      {...rest}
    />
  )
}
