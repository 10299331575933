import React from "react"
import { StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { COLORS } from "./theme/colors"
import { getFontName } from "./theme/fonts"
import { SIZES } from "./theme/sizes"
import { SPACING } from "./theme/spacing"
import { useColorMode } from "./ThemeProvider"

import type { RadioProps } from "./Radio"

const styles = StyleSheet.create({
  input: {
    height: SIZES.$6,
    width: SIZES.$6,
  },
  label: {
    fontFamily: getFontName("OpenSans_400Regular"),
    margin: SPACING.$1,
  },
  labelContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    margin: SPACING.$1,
  },

  root: {
    display: "flex",
  },
})

export function Radio({
  onChange,
  options,
  orientation = "vertical",
  selectedValue,
  style,
  ...rest
}: RadioProps) {
  const colorMode = useColorMode()

  return (
    <View
      accessibilityRole="radiogroup"
      {...rest}
      style={useStyle(
        () => [
          styles.root,
          orientation === "horizontal"
            ? {
                flexDirection: "row",
              }
            : undefined,
          style,
        ],
        [orientation, style],
      )}
    >
      {options.map((option) => {
        return (
          <View key={option.value} style={styles.labelContainer}>
            <input
              checked={option.value === selectedValue}
              data-testid={option.id}
              id={option.label}
              style={styles.input}
              type="radio"
              value={option.value}
              onChange={(e) => onChange(e.target.value)}
            />
            <label
              htmlFor={option.label}
              style={{
                ...styles.label,
                color:
                  colorMode === "dark"
                    ? COLORS.$textLight.primary
                    : COLORS.$textDark.primary,
              }}
            >
              {option.label}
            </label>
          </View>
        )
      })}
    </View>
  )
}
