/* eslint-disable sort-keys-fix/sort-keys-fix */
import { SPACING } from "./spacing"

// const CONTAINER = { lg: 1024, md: 768, sm: 640, xl: 1280 }
export const SIZES = {
  // "container": CONTAINER,
  ...SPACING,
  $3xs: 224,
  $2xs: 256,
  $xs: 320,
  $sm: 384,
  $md: 448,
  $lg: 512,
  $xl: 576,
  $2xl: 672,
  $3xl: 812,
  $4xl: 1024,
  $5xl: 1280,
} as const

export type SizeToken = keyof typeof SIZES
