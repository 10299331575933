import React from "react"
import { StyleSheet, View } from "react-native"

import { isTruthyString, isValidNumber } from "../type-guards"
import { Background } from "./Background"
import { Box } from "./Box"
import { Heading } from "./Heading"
import { AppIcons, renderIconFromIconProp } from "./icons"
import { ListNumber } from "./ListNumber"
import { Pressable } from "./Pressable"
import { Row } from "./Row"
import { Spinner } from "./Spinner"
import { testIds } from "./test-id"
import { AppText } from "./Text"
import { SPACING } from "./theme"

import type { ViewStyle } from "react-native"
import type { PressableProps } from "./Pressable"

import type { BoxProps } from "./Box"
import type { HeadingProps } from "./Heading"
import type { AppIconProps, IconProp } from "./icons"
import type { OptionalTestId } from "./test-id"
import type { AppTextProps } from "./Text"
import type { AcceptsChildren } from "./types"

const styles = StyleSheet.create({
  listItemContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingVertical: SPACING.$4,
  },

  listItemLoader: {
    bottom: 0,
    left: 0,
    opacity: 30,
    position: "absolute",
    right: 0,
    top: 0,
  },
})

type ContextValue = BoxProps

const Context = React.createContext<ContextValue>({})

export function useContext(): ContextValue {
  return React.useContext(Context)
}
// const HEADER_FONT_WEIGHT: IHeadingProps["fontWeight"] = `medium`;
export const STICKY_HEADER_INDICES = [0]
export interface ListItemTextProps extends AppTextProps {
  isDisabled?: boolean
}

export function ListItemTextPrimary({
  children,
  ...rest
}: ListItemTextProps): React.JSX.Element | null {
  return <AppText {...rest}>{children}</AppText>
}

export function ListItemTextSecondary({
  children,
  ...rest
}: ListItemTextProps): React.JSX.Element | null {
  return (
    <AppText colorScheme="secondary" {...rest}>
      {children}
    </AppText>
  )
}
export type ListItemContainerProps = AcceptsChildren &
  BoxProps & {
    position?: ViewStyle["position"]
  }

type EndComponentType =
  | JSX.Element
  | ((props: { [key: string]: unknown }) => JSX.Element | null)
export interface ListItemProps extends OptionalTestId {
  EndComponent?: EndComponentType
  IconComponent?: IconProp
  isLoading?: boolean
  numberedValue?: number
  primary?: React.ReactNode
  secondary?: React.ReactNode
  startElement?: React.ReactNode
}

// export function ListItemLoader(props: {
//   isOpen: boolean
// }): React.JSX.Element | null {
//   if (props.isOpen) {
//     return (
//       <Background style={styles.listItemLoader}>
//         <Spinner />
//       </Background>
//     )
//   }
//   return null
// }

export function ListItemContainer({
  id,
  style,
  ...rest
}: ListItemContainerProps): React.JSX.Element {
  const contextProps = useContext()
  return (
    <View
      {...contextProps}
      {...testIds(id)}
      style={[styles.listItemContainer, style]}
      {...rest}
    />
  )
}
export function ListItemLink({
  IconComponent,
  onPress,
  text,
  ...rest
}: Omit<PressableProps, "children"> & {
  IconComponent: IconProp
  text: React.JSX.Element | string
}) {
  if (typeof text === "string") {
    text = <ListItemTextPrimary>{text}</ListItemTextPrimary>
  }
  return (
    <Pressable accessibilityRole="link" onPress={onPress} {...rest}>
      <Row justifyContent="space-between" my="$3.5">
        <Row>
          <Box mr="$4">{renderIconFromIconProp(IconComponent)}</Box>
          {text}
        </Row>
        <AppIcons.ListItemEnd />
      </Row>
    </Pressable>
  )
}

/**
 *
 */
export function ListItem({
  EndComponent,
  IconComponent,
  isLoading = false,
  numberedValue,
  primary,
  secondary,
  ...rest
}: ListItemProps): React.JSX.Element {
  let position: ViewStyle["position"] | undefined
  let loaderElement: React.JSX.Element | undefined

  if (isLoading) {
    position = "relative"
    loaderElement = (
      <Background style={styles.listItemLoader}>
        <Spinner />
      </Background>
    )
  }

  const iconElement = renderIconFromIconProp(IconComponent)

  const numberElement = isValidNumber(numberedValue) ? (
    <ListNumber value={numberedValue} />
  ) : null

  const startElement = isTruthyString(rest.startElement) ? (
    <ListItemTextSecondary>{rest.startElement}</ListItemTextSecondary>
  ) : React.isValidElement(rest.startElement) ? (
    rest.startElement
  ) : null

  return (
    <ListItemContainer position={position} {...rest}>
      {loaderElement}
      {iconElement || numberElement || startElement ? (
        <Box mr="$2">
          {iconElement}
          {numberElement}
          {startElement}
        </Box>
      ) : null}
      <View>
        {typeof primary === "string" ? (
          <ListItemTextPrimary>{primary}</ListItemTextPrimary>
        ) : React.isValidElement(primary) ? (
          primary
        ) : null}
        {typeof secondary === "string" ? (
          <ListItemTextSecondary>{secondary}</ListItemTextSecondary>
        ) : null}
      </View>
      <Box ml="auto">
        {typeof EndComponent === "function" ? (
          <EndComponent />
        ) : typeof EndComponent === "string" ? (
          <ListItemTextSecondary>{EndComponent}</ListItemTextSecondary>
        ) : React.isValidElement(EndComponent) ? (
          EndComponent
        ) : null}
      </Box>
    </ListItemContainer>
  )
}

export function ListHeaderWrapper({
  children,
}: AcceptsChildren): React.JSX.Element {
  return <Box py="$2">{children}</Box>
}

export function ListSubHeader({
  children,
  ...rest
}: HeadingProps): React.JSX.Element {
  return (
    <Heading
      colorScheme="secondary"
      fontFamily="Poppins_500Medium"
      fontSize="$sm"
      {...rest}
    >
      {children}
    </Heading>
  )
}

export function ListItemEndIcon(props: AppIconProps): React.JSX.Element {
  return <AppIcons.ListItemEnd {...props} />
}
