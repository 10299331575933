import React from "react"

import { useColorMode } from "./components"
import { COLORS } from "./components/theme"

export type ThemePreference = "dark" | "light"

export type ThemedValue<T> =
  | T
  | {
      [key in ThemePreference]: T
    }
export function useThemedValue(): <T>(value: ThemedValue<T>) => T
export function useThemedValue<T>(value: ThemedValue<T>): T
export function useThemedValue<T>(
  initial?: ThemedValue<T>,
): T | ((value: ThemedValue<T>) => T) {
  const colorMode = useColorMode()

  const getValue = React.useCallback(
    (value: ThemedValue<T>) => {
      if (typeof value === "object" && value !== null && "light" in value) {
        return value[colorMode]
      }
      return value
    },
    [colorMode],
  )
  if (typeof initial === "undefined") {
    return getValue
  }
  return getValue(initial)
}
export function useScreenBackgroundColor(variant?: "default" | "paper") {
  const getThemedValue = useThemedValue()
  return getThemedValue({
    dark: undefined,
    light: variant === "paper" ? COLORS.$paper.light : undefined,
  })
}
export function useBottomSheetBackgroundColor() {
  return useThemedValue(COLORS.$bottomSheet)
}
