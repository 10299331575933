import { useLocation, useParams } from "react-router-dom"

import { objectEntries } from "@fhq/app/utility"

/**
 * Function converts path like /user/123 to /user/:id
 */
export function useCurrentRoutePath() {
  const { pathname } = useLocation()
  const params = useParams()
  if (!Object.keys(params).length) {
    return pathname // we don't need to replace anything
  }
  return objectEntries(params).reduce((acc, [key, value]) => {
    if (typeof value === "string" || typeof value === "number") {
      return acc.replace(value, `:${key}`)
    }
    return acc
  }, pathname)
}
