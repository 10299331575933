import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import { useNavigate } from "react-router-dom"

import {
  getActiveFeatureNameFromState,
  getIsDemoModeActiveFromState,
  setActiveFeature,
  useIsPending,
  UserFarmsList,
  useRootDispatch,
  useRootSelector,
  useShallowEqualSelector,
} from "@fhq/app"
import {
  AlertDialog,
  Box,
  Divider,
  FlatList,
  Pressable,
} from "@fhq/app/components"
import { SPACING } from "@fhq/app/components/theme"
import { PrepareDemoButton } from "@fhq/app/PrepareDemoButton"

import { Environment } from "./base/base"

import type { AlertDialogProps } from "@fhq/app/components"

const styles = StyleSheet.create({
  contentContainerStyle: {
    paddingHorizontal: SPACING.$4,
  },
})

/**
 * Show a list of farms that the user belongs to
 */
export function ChangeFarmsDialog(
  props: Partial<AlertDialogProps>,
): React.JSX.Element {
  const navigate = useNavigate()
  const otherFarms = useShallowEqualSelector((state) =>
    UserFarmsList.getNonActiveFarmsFromState(state).sort((a, b) =>
      a.name.localeCompare(b.name),
    ),
  )

  const handleChange = UserFarmsList.useChangeActiveFarm()

  const isDemoModeEnabled = useRootSelector(getIsDemoModeActiveFromState)
  const handleFarmChanged = () => navigate("/", { replace: true })
  const dispatch = useRootDispatch()
  const { t } = useTranslation("account")

  const isOpen = useRootSelector(
    (state) => getActiveFeatureNameFromState(state) === "change-farm-account",
  )
  const disableButtons = useIsPending(
    "PrepareDemoEnvironment",
    "LoadActiveUser",
    "LoadApp",
    "SetActiveFarmId",
    "ExitDemoMode",
  )

  const handleClose = () => dispatch(setActiveFeature(undefined))
  return (
    <AlertDialog
      IconComponent="SwapHorizontal"
      isOpen={isOpen}
      maxWidth={600}
      titleElement={t("userFarmsListTitle")}
      onClose={handleClose}
      {...props}
    >
      <FlatList
        ItemSeparatorComponent={Divider}
        contentContainerStyle={styles.contentContainerStyle}
        data={otherFarms}
        id="other-farms"
        ListHeaderComponent={
          <Box>
            <UserFarmsList.ActiveFarm />
            <UserFarmsList.DemoModeActive />
          </Box>
        }
        renderItem={({ item }) => {
          return (
            <Pressable trackHover trackPress onPress={() => handleChange(item)}>
              <UserFarmsList.RenderFarmListItem {...item} />
            </Pressable>
          )
        }}
      />

      {isDemoModeEnabled ? null : (
        <Box ml="auto" p="$4">
          <PrepareDemoButton
            isDisabled={disableButtons}
            size="sm"
            onSuccess={handleFarmChanged}
            {...Environment}
          />
        </Box>
      )}
    </AlertDialog>
  )
}
