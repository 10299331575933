import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { Box, Divider, FormControl } from "./components"
import { Row } from "./components/Row"
import { AppText } from "./components/Text"
import {
  getIsAdminModeEnabledFromState,
  getUserMeasurementPreferenceFromState,
} from "./selectors"
import { SENSOR_DEFAULTS } from "./sensor-configurations"
import { convertSensorValue } from "./sensor-conversions"
import { useSensorUnitLabel } from "./sensor-formatting"
import { SingleValueSlider } from "./sliders"
import { isValidNumber } from "./type-guards"
import { useRootSelector } from "./useRootSelector"

import type { DeviceConfiguration } from "./device-configurations.reducer"

const RANGE = {
  linearSpeedMmHMax: {
    defaultValue: SENSOR_DEFAULTS.reel.linearSpeedMmHMax,
    max: 1000000,
    min: 100000,
  },
  linearSpeedMmHMin: {
    defaultValue: SENSOR_DEFAULTS.reel.linearSpeedMmHMin,
    max: 25000,
    min: 5000,
  },
}
const RANGE_KEYS = ["min", "max"] as const

function ReelSpeedSlider(props: {
  defaultValueDescription: string
  description: string
  fieldName: "linearSpeedMmHMax" | "linearSpeedMmHMin"
}) {
  const { t } = useTranslation("sensorFields")
  const measurementPreference = useRootSelector(
    getUserMeasurementPreferenceFromState,
  )
  const getUnitLabel = useSensorUnitLabel()

  const convertValue = (nextValue: number) => {
    return convertSensorValue({
      fieldName: props.fieldName,
      measurementPreference,
      rawValue: nextValue,
      target: "user",
    })
  }

  const valuesRange = RANGE[props.fieldName]

  const unitLabel = getUnitLabel(props.fieldName)
  const formatValue = (nextValue: number) => `${nextValue} ${unitLabel}`

  const { control } = useFormContext<DeviceConfiguration>()

  return (
    <Controller
      control={control}
      name="reel"
      render={({ field, fieldState }) => {
        let value: number = convertValue(valuesRange.defaultValue)
        const sensorData = field.value
        if (sensorData) {
          const nextValue = sensorData[props.fieldName]
          if (isValidNumber(nextValue)) {
            value = nextValue
          }
        }

        const errorMessage = fieldState.error?.message
        const labelText: string = t(`${props.fieldName}.displayName`)
        const valueFormatted: string = formatValue(value)
        return (
          <FormControl.Provider isInvalid={Boolean(errorMessage)}>
            <View>
              <Row justifyContent="space-between" mb="$2">
                <FormControl.Label>{labelText}</FormControl.Label>
                <AppText fontWeight="bold">{valueFormatted}</AppText>
              </Row>
              <SingleValueSlider
                ref={field.ref}
                maximumValue={convertValue(valuesRange.max)}
                minimumValue={convertValue(valuesRange.min)}
                step={1}
                value={value}
                onSlidingComplete={field.onBlur}
                onValueChange={(next: number) => {
                  field.onChange({
                    ...field.value,
                    [props.fieldName]: next,
                  })
                }}
              />
              <Row justifyContent="space-between" my="$2">
                {RANGE_KEYS.map((key) => {
                  const rangeValueFormatted: string = formatValue(
                    convertValue(valuesRange[key]),
                  )
                  return <AppText key={key}>{rangeValueFormatted}</AppText>
                })}
              </Row>
              <AppText colorScheme="secondary">{props.description}</AppText>
              <Box mb="$2">
                <AppText colorScheme="secondary">
                  {props.defaultValueDescription}
                </AppText>
              </Box>
            </View>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
export function ReelSpeedSliders(): React.JSX.Element {
  const { t } = useTranslation("devices")
  const isAdminModeEnabled = useRootSelector(getIsAdminModeEnabledFromState)
  return (
    <View>
      <ReelSpeedSlider
        defaultValueDescription={t("reelSpeed.min.defaultValueHint")}
        description={t("reelSpeed.min.description")}
        fieldName="linearSpeedMmHMin"
      />

      {isAdminModeEnabled ? (
        <React.Fragment>
          <Box my="$8">
            <Divider />
          </Box>
          <ReelSpeedSlider
            defaultValueDescription={t("reelSpeed.max.defaultValueHint")}
            description={t("reelSpeed.max.description")}
            fieldName="linearSpeedMmHMax"
          />
        </React.Fragment>
      ) : null}
    </View>
  )
}
