import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

import { DeviceConnectionsList } from "@fhq/app"
import { Background, Button, TitleWithIcon } from "@fhq/app/components"

import { Help } from "./base/Help"
import { usePermissionCheckedAction } from "./base/usePermissionCheckedAction"

const STICKY_HEADER = [0]

/**
 * Screen to display all connections between devices
 */
export function DeviceConnectionsListPage() {
  const { t } = useTranslation("deviceConnections")
  const navigate = useNavigate()
  const { withPermissions } = usePermissionCheckedAction()

  return (
    <DeviceConnectionsList.DeviceConnectionsList
      stickyHeaderIndices={STICKY_HEADER}
      variant="page"
      withPermissions={withPermissions}
      ListHeaderComponent={
        <Background pb="$2" px="$4">
          <TitleWithIcon
            IconComponent="DeviceConnection"
            helpButtonElement={<Help {...DeviceConnectionsList.HELP_CONTENT} />}
            titleText={t("connectionsListTitle")}
            rightElement={
              <Button
                IconComponent="Add"
                text={t("connectDevices")}
                onPress={() => navigate("create")}
              />
            }
          />
        </Background>
      }
      onPressCreateConnection={() => navigate("create")}
      onPressDevice={(device) => navigate(`/devices/${device.codaDeviceAlias}`)}
    />
  )
}
