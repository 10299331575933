// https://stackoverflow.com/questions/308122/simple-regular-expression-for-a-decimal-with-a-precision-of-2

import { useTranslation } from "react-i18next"

/**
 * Provides a set of form validation patterns and messages
 */
export function useFormValidation() {
  const { t } = useTranslation("validation")
  return {
    decimalPattern: {
      message: t("decimalNumber"),
      value: /^\d*(\.\d+)?$/,
    },
    integerPattern: {
      message: t("integerOnly"),
      value: /^\d*$/,
    },
    maxValue: (value: number) => ({
      message: t("maxValueWithVal", { val: value }),
      value,
    }),
    minValue: (value: number) => ({
      message: t("minValueWithVal", { val: value }),
      value,
    }),
    required: {
      message: t("required"),
      value: true,
    },
  }
}
