import type { ValuesType } from "utility-types"

export const CognitoErrorMessage = {
  NO_CURRENT_USER: "No current user",
  REFRESH_TOKEN_EXPIRED: "Refresh Token has expired",
} as const
export const COGNITO_ERROR_CODE = {
  CodeDeliveryFailureException: "CodeDeliveryFailureException",
  CodeMismatchException: "CodeMismatchException",
  ExpiredCodeException: "ExpiredCodeException",
  InvalidParemeterException: "InvalidParemeterException",
  LimitExceededException: "LimitExceededException",
  NotAuthorizedException: "NotAuthorizedException",
  UserNotConfirmedException: "UserNotConfirmedException",
  UserNotFoundException: "UserNotFoundException",
  UsernameExistsException: "UsernameExistsException",
} as const
export type AuthErrorCode = ValuesType<typeof COGNITO_ERROR_CODE>
