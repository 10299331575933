import * as Fonts from "expo-font"
import React from "react"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { Outlet, RouterProvider } from "react-router-dom"

import {
  AppIsLoading,
  Geo,
  getActiveFarmSubscriptionDueDate,
  getAppViewFromState,
  isDateWithinSubscriptionWarningThreshold,
  logger,
  SubscriptionRenewal,
  useAppLoader,
  useRootDispatch,
} from "@fhq/app"
import { onShareAvailable } from "@fhq/app/app-state.reducer"
import {
  Background,
  ICON_SETS,
  ThemeProvider,
  ToastProvider,
} from "@fhq/app/components"
import { PRELOAD_FONTS } from "@fhq/app/components/theme"
import { GOOGLE_MAPS_API_KEY } from "@fhq/app/Internal"
import { Loader, ModalLoader } from "@fhq/app/Loader"
import { useIsPending } from "@fhq/app/selectors"
import { useRootSelector } from "@fhq/app/useRootSelector"
import { LoadScriptNext } from "@react-google-maps/api"

import { AUTHENTICATED_ROUTES } from "./Authenticated.Routes"
import { ContactFarmHqUnauthenticatedPage } from "./ContactFarmHqUnauthenticatedPage"
import { SEGMENT_CLIENT, useRecordPageVisit } from "./pages/base/Analytics"
import { Environment, sentryCreateBrowserRouter } from "./pages/base/base"
import { PageErrorBoundary } from "./pages/base/PageErrorBoundary"
import { RootLayout } from "./pages/base/RootLayout"

/**
 * Load fonts and icons asynchronously
 */
Fonts.loadAsync({
  ...ICON_SETS,
  ...PRELOAD_FONTS,
}).catch((e) => {
  logger.error(e)
})
const AppInfoPage = React.lazy(
  //
  async () => import("./pages/AppInfoPage"),
)
export const ContactUsPage = React.lazy(
  //
  async () => import("./pages/CreateSupportTicketPage"),
)
const PasswordResetPage = React.lazy(
  //
  async () => import("./pages/PasswordResetPage"),
)
const SignInPage = React.lazy(
  //
  async () => import("./pages/SignInPage"),
)
const SignOutPage = React.lazy(
  //
  async () => import("./pages/SignOutPage"),
)
const SignUpPage = React.lazy(
  //
  async () => import("./pages/SignUpPage"),
)

const ConfirmSignUpPage = React.lazy(
  //
  async () => import("./pages/ConfirmSignUpPage"),
)

const TIMEOUT_SECONDS = 30

function TimeoutTracker() {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      throw new TypeError("Load app timed out")
    }, TIMEOUT_SECONDS * 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])
  return null
}

function Root(): React.JSX.Element {
  const dispatch = useRootDispatch()

  // Collect analytics data on each page change
  useRecordPageVisit()

  const showModalLoader = useIsPending("PrepareDemoEnvironment")
  const appView = useRootSelector(getAppViewFromState)
  const farmSubscriptionDueDate = useRootSelector(
    getActiveFarmSubscriptionDueDate,
  )

  /**
   * Show/hide share buttons based on whether the browser/platform supports it
   */
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const isShareAvailable = Boolean(navigator.share)
  React.useEffect(() => {
    if (isShareAvailable) {
      // Share is available
      dispatch(onShareAvailable())
    }
  }, [dispatch, isShareAvailable])

  if (appView === "loading") {
    return (
      <React.Fragment>
        <TimeoutTracker />
        <AppIsLoading />
      </React.Fragment>
    )
  }

  if (appView === "load-error") {
    // This should trigger the error boundary
    throw new TypeError("Load Error")
  }
  if (appView === "account-locked") {
    return (
      <Background style={{ flex: 1 }}>
        <SubscriptionRenewal.AccountLockedDueToNonPayment />
      </Background>
    )
  }

  return (
    <React.Fragment>
      <ModalLoader isOpen={showModalLoader} />
      {typeof farmSubscriptionDueDate === "number" &&
      isDateWithinSubscriptionWarningThreshold(farmSubscriptionDueDate) ? (
        <SubscriptionRenewal.FarmAccountNonPaymentWarning
          dueDate={farmSubscriptionDueDate}
        />
      ) : null}
      <Outlet />
    </React.Fragment>
  )
}

const router = sentryCreateBrowserRouter([
  {
    children: [
      { element: <AppInfoPage />, path: "/app-info" },
      { element: <SignOutPage />, path: "/sign-out" },
      { element: <SignInPage />, path: "/sign-in" },
      { element: <SignUpPage />, path: "/sign-up" },
      { element: <ConfirmSignUpPage />, path: "/confirm-sign-up" },
      { element: <ConfirmSignUpPage />, path: "/confirm-account" },
      { element: <PasswordResetPage />, path: "/password-reset" },
      { element: <ContactFarmHqUnauthenticatedPage />, path: "/contact" },
      { ...AUTHENTICATED_ROUTES, path: "/" },
    ],
    element: (
      <ToastProvider isEndToEndTest={Environment.isEndToEndTest}>
        <SafeAreaProvider>
          <ThemeProvider>
            <React.Suspense fallback={<Loader />}>
              <LoadScriptNext
                googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                libraries={Geo.GOOGLE_MAPS_LIBRARIES}
                loadingElement={<AppIsLoading />}
              >
                <RootLayout>
                  <Root />
                </RootLayout>
              </LoadScriptNext>
            </React.Suspense>
          </ThemeProvider>
        </SafeAreaProvider>
      </ToastProvider>
    ),
    errorElement: <PageErrorBoundary />,
    path: "/",
  },
])

/**
 * Entrypoint for FarmHQ web dashboard
 */
export function WebApp(): React.JSX.Element {
  useAppLoader({
    analyticsClient: SEGMENT_CLIENT,
    targetDatabaseName: Environment.targetDatabaseName,
  })

  return <RouterProvider router={router} />
}
