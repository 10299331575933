import React from "react"

import { Paper } from "./Paper"
import { useBreakpointValue } from "./ThemeProvider"

import type { PaperProps } from "./Paper"
export function ListItemCard({ children, ...rest }: PaperProps) {
  const getValue = useBreakpointValue()
  return (
    <Paper
      pb="$2"
      pt="$2"
      px="$4"
      rounded={getValue({ md: "$default" })}
      {...rest}
    >
      {children}
    </Paper>
  )
}
