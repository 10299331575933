import { useNavigate } from "react-router-dom"

import { canSeedTestDatabase, SeedDatabaseOptionsForm } from "@fhq/app"
import { Box, Card, TitleWithIcon } from "@fhq/app/components"
import { NotFound404 } from "@fhq/app/NotFound404"

import { Environment } from "./base/base"

export function SeedDatabasePage() {
  const navigate = useNavigate()

  return canSeedTestDatabase(Environment.targetDatabaseName) ? (
    <Box maxW="$3xl" mx="auto" w="$full">
      <Card p="$4">
        <TitleWithIcon IconComponent="Database" titleText="Seed Database" />
        <SeedDatabaseOptionsForm
          targetDatabaseName={Environment.targetDatabaseName}
          onClose={() => navigate(-1)}
          onSuccess={() => {
            navigate("/")
          }}
        />
      </Card>
    </Box>
  ) : (
    <NotFound404 />
  )
}
