/* eslint-disable sort-keys-fix/sort-keys-fix */
export const RADII = {
  "$none": 0,
  "$xs": 2,
  "$sm": 4,
  "$md": 6,
  "$lg": 8,
  "$2xl": 16,
  "$xl": 12,
  "$3xl": 24,
  "$default": 6,
  "$full": 9999,
}
export type RadiusProp = keyof typeof RADII
