import React from "react"
import { StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { SIZES } from "./theme"

import type { ViewProps } from "react-native"

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
})

/**
 * A horizontal stack of elements
 */
export function HStack({
  children,
  spacing,
  style,
}: ViewProps & {
  spacing?: number
}) {
  if (typeof spacing === "undefined") {
    spacing = SIZES.$4
  }

  return (
    <View
      style={useStyle(() => {
        return [styles.root, style]
      }, [style])}
    >
      {React.Children.map(children, (child, index) => {
        const childStyle =
          index === React.Children.count(children) - 1
            ? {}
            : { marginRight: spacing }
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.JSX.Element, {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            style: [child.props.style, childStyle],
          })
        }
        return child
      })}
    </View>
  )
}
