import React from "react"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import {
  AppText,
  Box,
  Button,
  IconButton,
  Row,
  useThemeProviderContext,
} from "./components"
import { notNullish } from "./type-guards"

import type { ButtonProps } from "./components"
const VARIANTS: Array<Pick<ButtonProps, "IconComponent" | "variant">> = [
  // { iconName: "add", variant: "ghost" },
  // { iconName: "accept-terms-of-service", variant: "link" },
  { IconComponent: "Wheel", variant: "outline" },
  { IconComponent: "ZoomIn", variant: "primary" },
  { IconComponent: "Magnet", variant: "subtle" },
  { IconComponent: "GpsFixed", variant: "text" },
]

/**
 * Demo of various button styles
 */
export function ButtonsDemo(): React.JSX.Element {
  const { colorMode, onColorModeChanged: onChange } = useThemeProviderContext()
  const { t } = useTranslation()

  return (
    <Box m="auto" maxW="$lg" px="$4" w="$full">
      <AppText>{t("buttonsDemo.title", { ns: "dev" })}</AppText>
      <Button
        text="color mode"
        onPress={() => onChange(colorMode === "light" ? "dark" : "light")}
      />
      <Box>
        {VARIANTS.map(({ IconComponent, variant }) => {
          if (!Boolean(variant)) {
            return null
          }
          if (typeof variant !== "string") {
            throw new TypeError("Invalid variant")
          }
          return (
            <Row key={variant} justifyContent="space-between" my="$2">
              <Box maxW="$xs">
                <Button
                  IconComponent={IconComponent}
                  flex={1}
                  // maxWidth="xs"
                  text={variant}
                  variant={variant}
                />
              </Box>
              {notNullish(IconComponent) ? (
                <IconButton IconComponent={IconComponent} variant={variant} />
              ) : null}
            </Row>
          )
        })}
      </Box>
      <AppText>{t("buttonsDemo.actionButtons", { ns: "dev" })}</AppText>
      <ActionButtons isLoading={false} onPressSubmit={() => undefined} />
    </Box>
  )
}
