import React from "react"
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { KeyboardAvoidingView, Platform, View } from "react-native"

import { ActionButtons } from "./ActionButtons"
import { Box, FormControl, Row } from "./components"
import { ListItem, ListItemEndIcon } from "./components/ListItem"
import { testIds } from "./components/test-id"
import { SPACING } from "./components/theme"
import countries from "./country-region.json"
import { DataCollectionNoticeDialog } from "./DataCollectionNoticeDialog"
import { createFarmAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import { geocodeFarmAddressAsync } from "./geocoding"
import { loadActiveFarmAsync } from "./load-app"
import { logger } from "./logger"
import { getFetchStatusByName } from "./selectors"
import i18n from "./translations/i18n"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { AcceptsChildren, HelpContentStatic } from "./components"

import type { FarmAccountProperties } from "./geocoding"

import type { ListItemProps } from "./components/ListItem"
export const HELP_CONTENT: HelpContentStatic = {
  bodyText: i18n.t("noFarms:farmAccountHelpBodyText"),
  subject: "create_farm",
  titleText: i18n.t("noFarms:createFarmHelpTitle"),
}
const PRIORITY_COUNTRIES = ["US", "CA", "MX", "IT"]

const COUNTRIES_SORTED = [...countries]
  .sort((a, b): -1 | 0 | 1 => {
    if (a.slug === b.slug) {
      return 0
    }
    if (PRIORITY_COUNTRIES.includes(a.slug.toLocaleUpperCase())) {
      return -1
    }
    return 1
  })
  .map(({ name, regions, slug }) => ({
    key: name,
    label: name,
    regions,
    value: slug,
  }))

/**
 *
 */
export function CreateFarmListItem(rest: ListItemProps): React.JSX.Element {
  const { t } = useTranslation("noFarms")
  return (
    <ListItem
      EndComponent={<ListItemEndIcon />}
      IconComponent="Add"
      id="create-farm-link"
      primary={t("createFarmLinkTo", { ns: "noFarms" })}
      {...rest}
    />
  )
}
/**
 *
 */
export function JoinFarmListItem(rest: ListItemProps): React.JSX.Element {
  const { t } = useTranslation("noFarms")
  return (
    <ListItem
      EndComponent={<ListItemEndIcon />}
      IconComponent="Link"
      id="join-farm-with-code-link"
      primary={t("joinFarmLinkTo", { ns: "noFarms" })}
      {...rest}
    />
  )
}

interface ProviderProps {
  onCancel: (() => void) | undefined
  onSuccess: (() => void) | undefined
  defaultValues?: Partial<FarmAccountProperties>
}

/**
 *
 */
function useCreateFarm({ defaultValues, onCancel, onSuccess }: ProviderProps) {
  const dispatch = useRootDispatch()
  const handleError = useErrorHandler()
  const [isCreatingFarm, setIsCreatingFarm] = React.useState(false)

  const isLoading = useRootSelector(
    (state) =>
      getFetchStatusByName(state, "CreateFarm") === "pending" ||
      getFetchStatusByName(state, "GeocodeFarmAddress") === "pending",
  )

  const form = useForm<FarmAccountProperties>({
    defaultValues: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      country: "US",
      name: "",
      postalCode: "",
      region: "WA",
      ...defaultValues,
    },
  })

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      // Extra check to prevent duplicate submissions
      setIsCreatingFarm(true)
      if (!isCreatingFarm && !isLoading) {
        const farmWithGeocodedLocation = await dispatch(
          geocodeFarmAddressAsync(values),
        ).unwrap()
        const gpsLocation = farmWithGeocodedLocation.gpsLocation ?? null

        const {
          farm: { id: activeFarmId },
        } = await dispatch(createFarmAsync({ ...values, gpsLocation })).unwrap()

        await dispatch(loadActiveFarmAsync({ activeFarmId }))

        if (onSuccess) {
          onSuccess()
        }

        return activeFarmId
      }
    } catch (error) {
      handleError(error, { toastMessage: "default" })
      logger.error(error)
    } finally {
      setIsCreatingFarm(false)
    }
    return undefined
  })

  return {
    form,
    isLoading: isLoading || isCreatingFarm,
    onCancel,
    onSubmit,
  }
}

type ContextValue = ReturnType<typeof useCreateFarm>

const Context = React.createContext<ContextValue | undefined>(undefined)
export type FormValues = FarmAccountProperties

/**
 *
 */
export function Provider({
  children,
  defaultValues,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useCreateFarm({ ...rest, defaultValues })
  return (
    <Context.Provider value={value}>
      <FormProvider {...value.form}>{children}</FormProvider>
    </Context.Provider>
  )
}

function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(
      `CreateFarmForm Context must be used inside of provider`,
    )
  }
  return ctx
}

/**
 *
 */
function FarmNameField(): React.JSX.Element {
  const { required } = useFormValidation()
  const { t } = useTranslation("noFarms")
  const { control } = useFormContext<FarmAccountProperties>()
  return (
    <Controller
      control={control}
      name="name"
      rules={{ required }}
      render={({
        field: { onChange, ...field },
        fieldState,
      }): React.JSX.Element => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        return (
          <FormControl.Provider id="name" isInvalid={isInvalid}>
            <FormControl.Label>{t("createFarmName.label")}</FormControl.Label>
            <FormControl.Input
              placeholder={t("createFarmName.placeholder")}
              textContentType="organizationName"
              onChangeText={onChange}
              {...field}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
function AddressLineOneField(): React.JSX.Element {
  const { control } = useFormContext<FarmAccountProperties>()
  const { t } = useTranslation("noFarms")
  const { required } = useFormValidation()

  return (
    <Controller
      control={control}
      name="address_line_1"
      rules={{ required }}
      render={({
        field: { onChange, ...field },
        fieldState,
      }): React.JSX.Element => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        return (
          <FormControl.Provider id="address_line_1" isInvalid={isInvalid}>
            <FormControl.Label>
              {t("createFarmAddress_line_1.label")}
            </FormControl.Label>
            <FormControl.Input
              {...field}
              autoComplete="postal-address-region"
              placeholder={t("createFarmAddress_line_1.placeholder")}
              textContentType="streetAddressLine1"
              onChangeText={onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
function AddressLineTwoField(): React.JSX.Element {
  const { control } = useFormContext<FarmAccountProperties>()
  const { t } = useTranslation("noFarms")

  return (
    <Controller
      control={control}
      name="address_line_2"
      render={({
        field: { onChange, value, ...field },
        fieldState,
      }): React.JSX.Element => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        return (
          <FormControl.Provider id="address_line_2" isInvalid={isInvalid}>
            <FormControl.Label>
              {t("createFarmAddress_line_2.label")}
            </FormControl.Label>
            <FormControl.Input
              {...field}
              placeholder={t("createFarmAddress_line_2.placeholder")}
              textContentType="streetAddressLine2"
              value={value ?? ""}
              onChangeText={onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
function CityField(): React.JSX.Element | null {
  const { control } = useFormContext<FarmAccountProperties>()
  const { t } = useTranslation("noFarms")
  const { required } = useFormValidation()

  return (
    <Controller
      control={control}
      name="city"
      rules={{ required }}
      render={({
        field: { onChange, ...field },
        fieldState,
      }): React.JSX.Element => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        return (
          <FormControl.Provider id="city" isInvalid={isInvalid}>
            <FormControl.Label>{t("createFarmCity.label")}</FormControl.Label>
            <FormControl.Input
              {...field}
              placeholder={t("createFarmCity.placeholder")}
              textContentType="addressCity"
              onChangeText={onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
function PostalCodeField(): React.JSX.Element | null {
  const { control } = useFormContext<FarmAccountProperties>()
  const { t } = useTranslation("noFarms")
  const { required } = useFormValidation()

  return (
    <Controller
      control={control}
      name="postalCode"
      rules={{ required }}
      render={({
        field: { onChange, ...field },
        fieldState,
      }): React.JSX.Element => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)
        return (
          <FormControl.Provider id="postalCode" isInvalid={isInvalid}>
            <FormControl.Label>
              {t("createFarmPostalCode.label")}
            </FormControl.Label>
            <FormControl.Input
              {...field}
              placeholder={t("createFarmPostalCode.placeholder")}
              textContentType="postalCode"
              onChangeText={onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
function CountrySelectField(): React.JSX.Element | null {
  const { control } = useFormContext<FarmAccountProperties>()
  const { t } = useTranslation("noFarms")
  const { required } = useFormValidation()

  return (
    <Controller
      control={control}
      name="country"
      rules={{ required }}
      render={({
        field: { onChange, value },
        fieldState,
      }): React.JSX.Element => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        return (
          <FormControl.Provider id="country" isInvalid={isInvalid}>
            <FormControl.Label>
              {t("createFarmCountry.label")}
            </FormControl.Label>
            <FormControl.Select
              options={COUNTRIES_SORTED}
              placeholder={t("createFarmCountry.placeholder")}
              selectedValue={value}
              onValueChange={onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
function RegionSelectField(): React.JSX.Element | null {
  const { control, setValue, watch } = useFormContext<FarmAccountProperties>()
  const countryValue = watch("country")
  const { t } = useTranslation("noFarms")
  const { required } = useFormValidation()

  const options = React.useMemo(() => {
    const regions: Array<{ label: string; value: string }> = []
    const selectedCountry = COUNTRIES_SORTED.find((country) => {
      return country.value === countryValue
    })
    if (selectedCountry) {
      for (const [regionName, regionSlug] of selectedCountry.regions) {
        if (typeof regionName === "string" && typeof regionSlug === "string") {
          regions.push({
            label: regionName,
            value: regionSlug,
          })
        }
      }
    }
    return regions
  }, [countryValue])
  return (
    <Controller
      control={control}
      name="region"
      rules={{ required }}
      render={({
        field: { onChange, value },
        fieldState,
      }): React.JSX.Element => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        return (
          <FormControl.Provider
            id="region"
            isDisabled={!countryValue}
            isInvalid={isInvalid}
          >
            <FormControl.Label>{t("createFarmRegion.label")}</FormControl.Label>
            <FormControl.Select
              options={options}
              placeholder={t("createFarmRegion.placeholder")}
              selectedValue={value}
              onValueChange={(nextValue): void => {
                if (nextValue !== countryValue) {
                  setValue("region", "")
                }
                return onChange(nextValue)
              }}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
function FormFields(): React.JSX.Element {
  const { t } = useTranslation("noFarms")
  const { isLoading, onCancel, onSubmit } = useContext()
  const spacing = "$1"

  return (
    <React.Fragment>
      <Box mb={spacing} mt="$2">
        <FarmNameField />
      </Box>
      <Box mb={spacing}>
        <AddressLineOneField />
      </Box>
      <Box mb={spacing}>
        <AddressLineTwoField />
      </Box>
      <Row mb={spacing}>
        <Box flexGrow={1} mr="$4" w="$1/2">
          <CityField />
        </Box>
        <Box flexGrow={1}>
          <PostalCodeField />
        </Box>
      </Row>
      <Row mb={spacing}>
        <View
          style={{
            flex: 1,
            marginRight: SPACING.$4,
            minWidth: 150,
            width: "50%",
          }}
        >
          <CountrySelectField />
        </View>
        <View style={{ flex: 1, minWidth: 150, width: "50%" }}>
          <RegionSelectField />
        </View>
      </Row>
      <ActionButtons
        isDisabled={isLoading}
        isLoading={isLoading}
        my="$4"
        onPressCancel={onCancel}
        onPressSubmit={onSubmit}
      />
      <DataCollectionNoticeDialog
        bodyText={t("whatWeUseFarmLocationForText")}
        titleText={t("whatWeUseFarmLocationForTitle")}
      />
    </React.Fragment>
  )
}

let DEFAULT_VALUES_DEV_ONLY: FormValues | undefined
if (__DEV__) {
  const useDefaultsInWeb = Platform.OS === "web" && Boolean(window.Cypress)
  if (useDefaultsInWeb || Platform.OS === "ios" || Platform.OS === "android") {
    DEFAULT_VALUES_DEV_ONLY = {
      address_line_1: "1545 NW Market Street Apt 636",
      city: "Seattle",
      country: "US",
      name: "Test Farm",
      postalCode: "98107",
      region: "WA",
    }
  }
}

/**
 *
 */
export function CreateFarmForm(props: ProviderProps) {
  return (
    <Provider defaultValues={DEFAULT_VALUES_DEV_ONLY} {...props}>
      <KeyboardAvoidingView {...testIds("create-farm")}>
        <FormFields />
      </KeyboardAvoidingView>
    </Provider>
  )
}
