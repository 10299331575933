import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { getIsUserAuthenticatedFromState, useRootSelector } from "@fhq/app"
import { SignOutButton } from "@fhq/app/Auth"
import { Button, Row } from "@fhq/app/components"
import { ErrorPageWrapper } from "@fhq/app/ErrorPageWrapper"
import {
  SeedDatabaseListItem,
  SeedDatabaseOptionsForm,
} from "@fhq/app/SeedDatabase"

import { Environment } from "./base/base"

/**
 *
 */
export default function Page500({
  onReset,
}: Partial<Error> & { onReset?: () => void }): React.JSX.Element {
  const navigate = useNavigate()

  const isAuthenticated = useRootSelector(getIsUserAuthenticatedFromState)
  const [isSeedingDatabase, setIsSeedingDatabase] = React.useState(false)
  const { t } = useTranslation()
  if (isSeedingDatabase) {
    return (
      <SeedDatabaseOptionsForm
        targetDatabaseName={Environment.targetDatabaseName}
        onClose={() => setIsSeedingDatabase(false)}
        onSuccess={() => {
          navigate("/")
        }}
      />
    )
  }
  return (
    <ErrorPageWrapper testId="page-500" title="500">
      <SeedDatabaseListItem
        targetDatabaseName={Environment.targetDatabaseName}
        onPress={() => setIsSeedingDatabase(true)}
      />
      <Row justifyContent="space-around">
        {onReset ? <Button text={t("dismiss")} onPress={onReset} /> : null}
        {isAuthenticated ? (
          <SignOutButton
            variant="text"
            onSuccess={() => navigate("/sign-in")}
          />
        ) : null}
      </Row>
    </ErrorPageWrapper>
  )
}
