import { useNavigate } from "react-router-dom"

import { NewFarmSuccess, useRootDispatch } from "@fhq/app"
import { dismissNewFarmSuccess } from "@fhq/app/user-farms.reducer"

import { usePermissionCheckedAction } from "./base/usePermissionCheckedAction"

/**
 *
 */
export function NewFarmSuccessDialog(): React.JSX.Element {
  const navigate = useNavigate()
  const dispatch = useRootDispatch()
  const { withPermissions } = usePermissionCheckedAction()
  const handleClose = (cb: () => void) => {
    return () => {
      dispatch(dismissNewFarmSuccess())
      cb()
    }
  }
  return (
    <NewFarmSuccess
      withPermissions={withPermissions}
      onPressAddDevice={handleClose(() => navigate("/devices/add"))}
      onPressCreateField={handleClose(() => navigate("/fields/create"))}
      onPressSupport={handleClose(() => navigate("/support"))}
    />
  )
}
