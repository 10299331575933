import React from "react"
import { useNavigate } from "react-router"
import { Outlet, useParams } from "react-router-dom"

import {
  CreateDeviceConfiguration,
  DeviceSummaryContext,
  getDeviceStatusDataByDeviceIdFromState,
  Models,
  SetLinearPath,
  useRootSelector,
} from "@fhq/app"
import { Loader } from "@fhq/app/Loader"
import { NotFound404 } from "@fhq/app/NotFound404"

import { DeviceConfigurationRoutes } from "./DeviceConfiguration.Routes"
import AddSensorFormPage from "./pages/AddSensorConfiguration"
import { DeviceProfileLayout } from "./pages/DeviceProfileLayout"
import { SetCenterPivotPathPage } from "./pages/SetCenterPivotPathPage"
import { LinearPathConfiguration } from "./pages/SetLinearPathPage"

import type { RouteObject } from "react-router"

const CreateCustomNotificationPage = React.lazy(async () => {
  return import("./pages/CreateCustomNotificationPage")
})

const NotificationsListPage = React.lazy(async () => {
  return import("./pages/DeviceNotificationRulesListPage")
})

const DeviceActionHistoryPage = React.lazy(async () => {
  return import("./pages/DeviceActionHistoryPage")
})

const DeviceActivityPage = React.lazy(async () => {
  return import("./pages/DeviceActivityPage")
})

const EventHistoryPage = React.lazy(async () => {
  return import("./pages/DeviceEventHistoryInspectorPage")
})

/**
 * Common layout for device profile pages
 */
function Root(): React.JSX.Element | null {
  const params = useParams<{ codaDeviceAlias: string }>()

  const numConfigs = useRootSelector(Models.deviceConfiguration.selectTotal)
  const deviceStatus = useRootSelector((state) =>
    getDeviceStatusDataByDeviceIdFromState(
      state,
      Models.deviceConfiguration
        .selectAll(state)
        .find((dc) => params.codaDeviceAlias === dc.codaDeviceAlias)?.deviceId,
    ),
  )
  // If the device configurations are loaded into the app, we should always
  // be able to find one with the given alias
  if (!deviceStatus) {
    if (numConfigs > 0) {
      throw new TypeError(
        `Device with alias ${params.codaDeviceAlias ?? ""} not found`,
      )
    }
    // App loading must be in progress in this case
    return <Loader />
  }

  // Configuration is found; show its device profile
  return (
    <DeviceSummaryContext.Provider {...deviceStatus}>
      <Outlet />
    </DeviceSummaryContext.Provider>
  )
}

function SetLinearPathPage() {
  const {
    codaDeviceAlias,
    deviceId,
    deviceInstallationType,
    deviceName,
    hardwareGeneration,
  } = DeviceSummaryContext.useContext()
  const navigate = useNavigate()
  const configuration = useRootSelector((state) =>
    Models.deviceConfiguration.selectById(state, deviceId),
  )
  if (configuration) {
    const handleCancel = () => {
      navigate(-1)
    }
    const handleSuccess = () => {
      navigate(`/devices/${codaDeviceAlias}`)
    }
    return (
      <CreateDeviceConfiguration.Provider
        codaDeviceAlias={codaDeviceAlias}
        deviceId={deviceId}
        deviceInstallationType={deviceInstallationType}
        deviceName={deviceName}
        hardwareGeneration={hardwareGeneration}
        currentConfigurationData={{
          ...configuration,
          linearPath: null,
          linearPathStopsLabels: null,
          linearSpanHeadingDegrees: null,
          linearSpanWidthMm: null,
        }}
        onCancel={handleCancel}
        onClearInstallationType={null}
        onSuccess={handleSuccess}
      >
        <SetLinearPath.Provider onCancel={handleCancel}>
          <LinearPathConfiguration />
        </SetLinearPath.Provider>
      </CreateDeviceConfiguration.Provider>
    )
  }
  return <NotFound404 />
}
export const DEVICE_PROFILE_ROUTES: RouteObject = {
  children: [
    {
      element: (
        <DeviceProfileLayout showCycler showRenameButton>
          <DeviceActivityPage />
        </DeviceProfileLayout>
      ),
      index: true,
    },
    {
      ...DeviceConfigurationRoutes,
      path: "configuration",
    },
    {
      element: (
        <DeviceProfileLayout>
          <DeviceActionHistoryPage />
        </DeviceProfileLayout>
      ),
      path: "command-history",
    },
    {
      children: [
        {
          element: <NotificationsListPage />,
          index: true,
        },
        {
          element: <CreateCustomNotificationPage />,
          path: "create",
        },
      ],
      element: (
        <DeviceProfileLayout>
          <Outlet />
        </DeviceProfileLayout>
      ),
      id: "device-notifications",
      path: "notifications",
    },
    {
      element: (
        <DeviceProfileLayout>
          <EventHistoryPage />
        </DeviceProfileLayout>
      ),
      id: "event-history",
      path: "event-history",
    },
    {
      element: <SetCenterPivotPathPage />,
      path: "set-center-pivot-path",
    },
    {
      element: <SetLinearPathPage />,
      path: "set-linear-path",
    },
    {
      element: (
        <DeviceProfileLayout>
          <AddSensorFormPage />
        </DeviceProfileLayout>
      ),
      id: "add-sensor",
      path: "add-sensor",
    },
  ],
  element: <Root />,
}
