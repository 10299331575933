import type { Reducer } from "@reduxjs/toolkit"

import { createSlice, isAnyOf } from "@reduxjs/toolkit"

import { signOutAsync } from "./auth.reducer"
import {
  removeDeviceFromFarmAsync,
  toggleNotificationsFromDevicesAsync,
} from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"

import type { DeviceUserAssociation, ModelState } from "./models"
const adapter = Models.deviceUserAssociation.adapter

type DeviceUserAssociationsState = ModelState<DeviceUserAssociation, string>

const initialState: DeviceUserAssociationsState = Models.getInitialEntityState()

const deviceUserAssociations = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(loadActiveFarmAsync.fulfilled, (state, { payload }) => {
        adapter.setAll(state, payload.deviceUserAssociations)
      })
      .addCase(toggleNotificationsFromDevicesAsync.pending, (state, action) => {
        adapter.setAll(
          state,
          action.meta.arg.ids.map((deviceId) => ({
            deviceId,
            notificationsSuppressed: action.meta.arg.action === "SUPPRESS",
          })),
        )
      })
      .addCase(removeDeviceFromFarmAsync.fulfilled, (state, { meta }) => {
        adapter.removeOne(state, meta.arg.deviceId)
      })
      .addMatcher(
        isAnyOf(signOutAsync.fulfilled, loadActiveFarmAsync.pending),
        () => ({ ...initialState }),
      ),

  initialState,
  name: `deviceUserAssociations`,
  reducers: {},
})

export const deviceUserAssociationsReducer: Reducer<DeviceUserAssociationsState> =
  deviceUserAssociations.reducer
