import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { ActionButtons } from "./ActionButtons"
import { AlertCard, Box, Row, SubmitButton } from "./components"
import { useToasts } from "./components/useToasts"
import { setReelRunDirectionAsync } from "./farmhq-api"
import * as Geo from "./geo"
import * as Models from "./models"
import { getSwathGeometry } from "./reel-runs.reducer"
import { getDeviceSummaryByDeviceIdFromState } from "./selectors"
import { useConvertSensorValue } from "./sensor-conversions"
import { AzimuthDisplayText, SingleValueSlider } from "./sliders"
import { isValidNumber } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"
import { getActiveFarmCoordinatesFromState } from "./user-farms.selectors"
import { useShallowEqualSelector } from "./useRootSelector"

import type { AcceptsChildren } from "./components"
import type { PermissionCheckProps, TrackEventProps } from "./types"
interface ProviderProps extends TrackEventProps, PermissionCheckProps {
  selectedRunId: number | undefined
}

function useSelectedReelRun({
  selectedRunId,
  trackEvent,
  withPermissions,
  ...rest
}: ProviderProps) {
  const farmLocation = useShallowEqualSelector(
    getActiveFarmCoordinatesFromState,
  )
  const [actionName, setActionName] = React.useState<
    "setReelDistance" | "setRunDirection"
  >()
  const runData = useShallowEqualSelector((state) =>
    Models.reelRunsActive.selectById(state, selectedRunId),
  )

  const deviceId = runData?.deviceId ?? undefined
  const deviceSummary = useShallowEqualSelector((state) =>
    getDeviceSummaryByDeviceIdFromState(state, deviceId),
  )
  const reelEvent = useShallowEqualSelector(
    (state) => Models.deviceEventLast.selectById(state, deviceId)?.reel,
  )
  const convertDistance = useConvertSensorValue("runDistanceMmCurrent")

  const defaultDistanceMmCurrent = reelEvent?.runDistanceMmCurrent ?? 0
  const defaultDistanceMmMax = reelEvent?.runDistanceMmMax ?? 0
  const defaultDirection =
    runData?.directionOverrideAzimuthDegrees ??
    runData?.extendHeadingDegrees ??
    0
  const form = useForm({
    defaultValues: {
      directionOverride: defaultDirection,
      distanceCurrent: convertDistance(defaultDistanceMmCurrent, "user"),
      distanceMax: convertDistance(defaultDistanceMmMax, "user"),
    },
  })
  /**
   * Because the form never unmounts, we need to manually update it here,
   * 'subscription' style
   */
  React.useEffect(() => {
    if (isValidNumber(defaultDistanceMmCurrent)) {
      form.setValue(
        "distanceCurrent",
        convertDistance(defaultDistanceMmCurrent, "user"),
      )
    }
    if (isValidNumber(defaultDistanceMmMax)) {
      form.setValue(
        "distanceMax",
        convertDistance(defaultDistanceMmMax, "user"),
      )
    }

    if (isValidNumber(defaultDirection)) {
      form.setValue("directionOverride", defaultDirection)
    }
  }, [
    convertDistance,
    defaultDirection,
    defaultDistanceMmCurrent,
    defaultDistanceMmMax,
    form,
  ])

  const directionOverride = form.watch("directionOverride")
  const swathGeometry = runData
    ? getSwathGeometry({
        ...runData,
        distanceMmCurrent: defaultDistanceMmCurrent,
        distanceMmMax: defaultDistanceMmMax,
        extendHeadingDegrees: directionOverride,
        farmLocation,
      })
    : null

  return {
    actionName,
    centerLatLng: Geo.createMultiPoint(
      Geo.polygon(swathGeometry?.outlinePath?.native)?.getCenter(),
    ),
    deviceSummary,
    form,
    onCloseForms: () => {
      form.reset()
      setActionName(undefined)
    },
    onPressSetDirection: withPermissions({
      callback: () => {
        setActionName("setRunDirection")
        trackEvent({
          elementName: "set-run-direction-link",
          name: "element_press",
        })
      },
      required: "canManageDeviceConfiguration",
    }),
    onPressSetReelDistance: withPermissions({
      callback: () => {
        setActionName("setReelDistance")
        trackEvent({
          elementName: "set-reel-distance-link",
          name: "element_press",
        })
      },
      required: "canManageDeviceConfiguration",
    }),
    reelEvent,
    reelRunId: selectedRunId,
    runData,
    setActionName,
    swathGeometry,
    ...rest,
  }
}

type ContextValue = ReturnType<typeof useSelectedReelRun>

const Context = React.createContext<ContextValue | undefined>(undefined)

/**
 * Reel Run context provider
 *
 * NOTE: This component must be high up in the tree so that it can pass
 * props to child components that are both INSIDE of and OUTSIDE of map views.
 * On mobile in particular, this is an absolute requirement due to the way
 * React Native Maps renders its children; arbitrary cards, boxes, texts etc.
 * WILL NOT WORK if they are children of a map view.
 *
 * As a result we cannot render this component conditionally. Annoying right?
 *
 * Therefore, since the run polygons need to read from a useForm
 * hook, we have to always render the form.
 */
export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useSelectedReelRun(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`SelectedRun Context must be used inside of provider`)
  }
  return ctx
}
const styles = StyleSheet.create({
  sliderContainer: {
    flex: 1,
    minWidth: 150,
  },
})

/**
 *
 * NOTE: This uses reel run context
 *
 */
export function SetReelRunDirectionForm(): React.JSX.Element | null {
  const { t } = useTranslation("statusMap")

  const { form, onCloseForms, reelRunId } = useContext()

  const toasts = useToasts()
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    setReelRunDirectionAsync,
  )

  const azimuth = form.watch("directionOverride")

  const makeSubmitHandler =
    ({ flipValue }: { flipValue: boolean }) =>
    () => {
      if (typeof reelRunId === "undefined") {
        throw new TypeError(
          `In SetReelRunDirectionForm: reel run id is undefined`,
        )
      }

      sendRequest({
        reelRunId,
        value: flipValue ? (azimuth + 180) % 360 : azimuth,
      })
        .then(() => {
          toasts.success()
          return onCloseForms()
        })
        .catch((error) =>
          handleError(error, {
            toastMessage: "default",
          }),
        )
    }

  const onFlip = makeSubmitHandler({ flipValue: true })
  const onSubmit = makeSubmitHandler({ flipValue: false })

  return (
    <View>
      <AlertCard bodyText={t("setRunDirection.instructions")} severity="info" />
      <Row my="$3">
        <Controller
          control={form.control}
          name="directionOverride"
          render={({ field }) => {
            return (
              <Row>
                <SingleValueSlider
                  containerStyle={styles.sliderContainer}
                  disabled={isLoading}
                  maximumValue={359}
                  minimumValue={0}
                  step={1}
                  value={field.value}
                  onValueChange={field.onChange}
                />
                <Box ml="$2">
                  <AzimuthDisplayText value={azimuth} />
                </Box>
              </Row>
            )
          }}
        />
        <SubmitButton
          IconComponent="SwapVertical"
          isDisabled={isLoading}
          text={t("setRunDirection.flipButton")}
          variant="outline"
          onPress={onFlip}
        />
      </Row>
      <ActionButtons
        isLoading={isLoading}
        onPressCancel={onCloseForms}
        onPressSubmit={onSubmit}
      />
    </View>
  )
}
