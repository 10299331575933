import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { AppText } from "../Text"
import { COLORS, SPACING } from "../theme"
import { getFontName } from "../theme/fonts"
import { useFormControlContext } from "./base"

import type { AppTextProps } from "../Text"

const styles = StyleSheet.create({
  invalid: {
    color: COLORS.$invalid,
  },
  root: {
    fontFamily: getFontName("OpenSans_400Regular"),
    marginBottom: SPACING.$1,
  },
})

export interface FormControlLabelProps extends AppTextProps {
  isInvalid?: boolean
}

export function Label({
  children,
  disabled,
  isInvalid,
  style,
  ...rest
}: FormControlLabelProps) {
  const ctx = useFormControlContext()

  if (typeof isInvalid === "undefined") {
    isInvalid = ctx.isInvalid
  }
  return (
    <AppText
      colorScheme="secondary"
      disabled={disabled}
      style={useStyle(() => {
        return [
          styles.root,
          isInvalid === true ? styles.invalid : undefined,
          style,
        ]
      }, [isInvalid, style])}
      {...rest}
    >
      {children}
    </AppText>
  )
}
