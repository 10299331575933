import React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import {
  AppText,
  Box,
  FormControl,
  Heading,
  Link,
  Row,
  WordDivider,
} from "./components"
import { useToasts } from "./components/useToasts"
import { createSupportTicketAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import { PhoneNumbers } from "./Internal"
import { getActiveUserEmailFromState } from "./selectors"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

/**
 * Form to create a support ticket
 */
export function CreateSupportTicketForm({
  onClose,
  showTitle = true,
  ...rest
}: {
  onClose: () => void
  showTitle?: boolean
}): React.JSX.Element {
  const userEmail =
    useRootSelector(getActiveUserEmailFromState) ?? "unknown email"
  const { t } = useTranslation("support")
  const { required } = useFormValidation()
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    createSupportTicketAsync,
  )

  const toast = useToasts()

  const form = useForm({
    defaultValues: {
      body: "",
      subject: "",
    },
    mode: "all",
  })

  const onSubmit = form.handleSubmit((values) => {
    sendRequest({
      ...values,
      email: userEmail,
    })
      .then(() => {
        toast.success(t("weHaveReceivedYourMessage"))
        return onClose()
      })
      .catch((error) => {
        handleError(error, {
          toastMessage: "default",
        })
      })
  })
  return (
    <FormProvider {...form}>
      <Box id="create-support-ticket" {...rest}>
        {showTitle ? (
          <Heading variant="h4">{t("sendUsAMessage")}</Heading>
        ) : null}
        <Controller
          control={form.control}
          name="subject"
          rules={{ required }}
          render={({ field: { onChange, ref, ...field }, fieldState }) => {
            const errorMessage = fieldState.error?.message
            return (
              <FormControl.Provider
                id="subject"
                isInvalid={Boolean(errorMessage)}
              >
                <FormControl.Label>{t("subjectInputLabel")}</FormControl.Label>
                <FormControl.Input
                  ref={ref}
                  placeholder={t("subjectInputPlaceholder")}
                  onChangeText={onChange}
                  {...field}
                />
                <FormControl.ErrorMessage>
                  {errorMessage}
                </FormControl.ErrorMessage>
              </FormControl.Provider>
            )
          }}
        />
        <Controller
          control={form.control}
          name="body"
          rules={{ required }}
          render={({ field: { onChange, ref, ...field }, fieldState }) => {
            const errorMessage = fieldState.error?.message
            return (
              <FormControl.Provider id="body" isInvalid={Boolean(errorMessage)}>
                <FormControl.Label>{t("bodyInputLabel")}</FormControl.Label>
                <FormControl.Input
                  ref={ref}
                  multiline
                  id="input"
                  numberOfLines={8}
                  placeholder={t("bodyInputPlaceholder")}
                  returnKeyType="default"
                  style={{ height: 200 }}
                  onChangeText={onChange}
                  {...field}
                />
                <FormControl.ErrorMessage>
                  {errorMessage}
                </FormControl.ErrorMessage>
              </FormControl.Provider>
            )
          }}
        />
        <ActionButtons
          isLoading={isLoading}
          mt="$4"
          onPressCancel={onClose}
          onPressSubmit={onSubmit}
        />
        <Box my="$4">
          <WordDivider />
        </Box>
        <Row justifyContent="space-between">
          <AppText>{t("callUs")}</AppText>
          <Box>
            <Link to={`tel:${PhoneNumbers.CUSTOMER_SERVICE}`}>
              <AppText fontWeight="bold">
                {PhoneNumbers.CUSTOMER_SERVICE}
              </AppText>
            </Link>
          </Box>
        </Row>
      </Box>
    </FormProvider>
  )
}
