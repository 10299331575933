import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { Box } from "./Box"

import type { BoxProps } from "./Box"

const styles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "row",
  },
})

export interface RowProps extends BoxProps {
  _items?: BoxProps
  space?: "$1" | "$2" | "$3" | "$4"
}

export function Row({
  _items,
  alignItems = "center",
  children,
  flexWrap = "wrap",
  justifyContent = "flex-start",
  space,
  style,
  ...rest
}: RowProps) {
  const numChildren = React.Children.count(children) - 1
  let isChildRendered = false
  return (
    <Box
      style={useStyle(() => {
        return [styles.root, { alignItems, flexWrap, justifyContent }, style]
      }, [alignItems, flexWrap, justifyContent, style])}
      {...rest}
    >
      {space
        ? React.Children.map(children, (child, index) => {
            if (child === null) {
              return child
            }
            if (!isChildRendered) {
              isChildRendered = true
              return (
                <Box {..._items} key={index} mr={space}>
                  {child}
                </Box>
              )
            }
            if (index === numChildren) {
              return (
                <Box {..._items} key={index} ml={space}>
                  {child}
                </Box>
              )
            }
            return (
              <Box {..._items} key={index} mx={space}>
                {child}
              </Box>
            )
          })
        : children}
    </Box>
  )
}
