import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { ActionButtons } from "./ActionButtons"
import { AlertBodyText, AppText, Box, Button } from "./components"
import { SPACING } from "./components/theme"
import * as DeviceSummaryContext from "./DeviceSummaryContext"
import { restoreConfigurationDefaultsAsync } from "./farmhq-api"
import { getTriggersByDeviceIdFromState } from "./triggers.reducer"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { DeviceIdProps, ProtectedButtonProps } from "./types"
/**
 * Protected, tracked
 */
export function FactoryResetButton({
  onPress,
  trackEvent,
  withPermissions,
  ...props
}: Omit<ProtectedButtonProps, "text">): React.JSX.Element {
  const { t } = useTranslation("deviceConfiguration")
  return (
    <Button
      text={t("resetDeviceLinkTo")}
      variant="outline"
      onPress={withPermissions({
        callback: () => {
          onPress()
          trackEvent({
            elementName: "reset-device-btn",
            name: "element_press",
          })
        },
        required: "canManageDeviceConfiguration",
      })}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  text: {
    marginBottom: SPACING.$4,
  },
})

/**
 * Confirm reset device
 */
export function ConfirmResetDevice({
  onCancel,
  onSuccess,
}: DeviceIdProps & {
  onCancel: () => void
  onSuccess: () => void
}): React.JSX.Element {
  const { deviceId } = DeviceSummaryContext.useContext()
  const { t } = useTranslation("deviceConfiguration")

  const { handleError, isLoading, sendRequest } = useBackendRequest(
    restoreConfigurationDefaultsAsync,
  )

  const triggers = useRootSelector((state) =>
    getTriggersByDeviceIdFromState(state, deviceId),
  )

  const handleSubmit = () => {
    sendRequest({ deviceId })
      .then(onSuccess)
      .catch((error) => handleError(error, { toastMessage: "default" }))
  }
  const titleText: string = t("resetDeviceAreYouSure")
  return (
    <Box>
      <AppText style={styles.text}>{titleText}</AppText>
      <AppText style={styles.text}>
        {t("resetDeviceYouWillLoseConfigurations")}
      </AppText>
      {triggers.length > 0 ? (
        <AlertBodyText style={styles.text}>
          {t("resetDeviceYouWillLoseTriggers")}
        </AlertBodyText>
      ) : null}
      <ActionButtons
        isLoading={isLoading}
        mt="$4"
        onPressCancel={onCancel}
        onPressSubmit={handleSubmit}
      />
    </Box>
  )
}
