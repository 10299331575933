import _ from "lodash"

import { isTruthyString, isValidNumber } from "./type-guards"

export interface TextFormatOption {
  textFormat?: "lowerCase" | "titleCase" | "upperFirst"
}

export type LabelLength = "long" | "short"
// export interface LabelLengthOption {
//   labelLength?: LabelLength
// }
// export type HumanizeTextOptions = LabelLengthOption & TextFormatOption
export interface SensorDatetimeOptions {
  dtFormat?: `${"" | "PP" | `P`}${" pp" | ""}`
}
export interface SensorFormatOptions {
  sigDigits?: number
}

/**
 *
 */
export function humanize(
  value: number | string | null | undefined,
  options?: TextFormatOption,
): string {
  if (isValidNumber(value)) {
    return humanize(value.toString(), options)
  }
  if (isTruthyString(value)) {
    const result = _.kebabCase(value).split("-").join(" ")
    if (options) {
      if (options.textFormat === "upperFirst") {
        return _.upperFirst(result)
      }
      if (options.textFormat === "titleCase") {
        return _.startCase(result.toLocaleLowerCase())
      }
      if (options.textFormat === "lowerCase") {
        return result.toLocaleLowerCase()
      }
    }
    return result
  }
  return ``
}

// /**
//  * Pluralizes a word if given a count greater than 1
//  *
//  * @param args
//  * @param args.word
//  * @param args.count
//  * @example
//  * // returns 'taco'
//  * pluralize('taco', 1)
//  * @example
//  * // returns 'tacos'
//  * pluralize('tacos', 2)
//  */
// export function pluralize({
//   count,
//   word,
// }: {
//   word: string
//   count?: number | null
// }): string {
//   const isPlural = (count ?? 0) !== 1
//   if (isPlural && !word.toLocaleLowerCase().endsWith("s")) {
//     return `${word}s`
//   }
//   return word
// }
