import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useStyle } from "react-native-style-utilities"

import { ActionButtons } from "./ActionButtons"
import { setFieldActionsName } from "./actions"
import { FormControl } from "./components"
import { COLORS, FONT_SIZES, getFontName } from "./components/theme"
import { renameFieldAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import { SingleValueSlider } from "./sliders"
import { useBackendRequest } from "./useBackendRequest"

import type { CachedFontName } from "./components/theme"
import type { Path } from "react-hook-form"
import type { FormControlProviderProps } from "./components/form-control/base"

interface FieldLabelStyle {
  color: string
  fontFamily: CachedFontName
  fontSize: number
  textShadowColor: string
  textShadowRadius: number
  transform: Array<{
    rotate: string
  }>
}

export function useFieldLabelStyle({
  fontFamily,
  rotation,
}: {
  rotation: number | null | undefined
  fontFamily?: CachedFontName
}): FieldLabelStyle {
  return useStyle((): FieldLabelStyle => {
    return {
      color: "white",
      fontFamily: fontFamily ?? getFontName("Poppins_500Medium"),
      fontSize: FONT_SIZES.$xs,
      textShadowColor: COLORS.$black,
      textShadowRadius: 2,
      transform: [{ rotate: `${rotation ?? "0"}deg` }],
    }
  }, [fontFamily, rotation])
}
export interface FormValues {
  fieldName: string
  labelRotationDegrees: number
}

export function FieldNameInput({
  isLoading,
  ...rest
}: FormControlProviderProps & {
  isLoading?: boolean
}): React.JSX.Element {
  const { required } = useFormValidation()

  const { control } = useFormContext<FormValues>()
  const { t } = useTranslation("fields")
  return (
    <Controller
      control={control}
      name="fieldName"
      rules={{ required }}
      render={({
        field: { name, onBlur, onChange, ref, value },
        fieldState,
      }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        return (
          <FormControl.Provider
            {...rest}
            id="fieldName"
            isDisabled={isLoading}
            isInvalid={isInvalid}
          >
            <FormControl.Label>{t("setFieldNameInputLabel")}</FormControl.Label>
            <FormControl.Input
              ref={ref}
              placeholder={t("setFieldNamePlaceholderText")}
              testID={name}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}
export function DirectionSlider<
  V extends Pick<FormValues, "labelRotationDegrees">,
>({
  isLoading,
  ...rest
}: FormControlProviderProps & { isLoading?: boolean }): React.JSX.Element {
  const { t } = useTranslation("fields")
  const { control } = useFormContext<FormValues>()
  const { required } = useFormValidation()

  const maximumValue = 360
  const minimumValue = 0
  return (
    <Controller
      control={control}
      name={"labelRotationDegrees" as Path<V>}
      rules={{ required }}
      render={({ field: { name, onChange, ref, value } }) => {
        return (
          <FormControl.Provider testID={name} {...rest}>
            <FormControl.Label>
              {t("setFieldLabelRotationLabel")}
            </FormControl.Label>
            <SingleValueSlider
              ref={ref}
              disabled={isLoading}
              maximumValue={maximumValue}
              minimumValue={minimumValue}
              step={1}
              value={value}
              onValueChange={onChange}
            />
          </FormControl.Provider>
        )
      }}
    />
  )
}
export function Buttons({
  fieldId,
  onClosed,
}: {
  fieldId: number
  onClosed?: () => void
}): React.JSX.Element {
  const { dispatch, handleError, isLoading, sendRequest } =
    useBackendRequest(renameFieldAsync)

  const { handleSubmit } = useFormContext<FormValues>()
  const onSubmit = handleSubmit(({ fieldName, labelRotationDegrees }) => {
    sendRequest({ fieldId, fieldName, labelRotationDegrees })
      .then(onClosed)
      .catch((error) => handleError(error, { toastMessage: "default" }))
  })

  const handleClosed = () => dispatch(setFieldActionsName(undefined))
  return (
    <ActionButtons
      isLoading={isLoading}
      onPressCancel={onClosed ?? handleClosed}
      onPressSubmit={onSubmit}
    />
  )
}
