import React from "react"
import { useTranslation } from "react-i18next"

import { Box } from "./Box"
import { Divider } from "./Divider"
import { Row } from "./Row"
import { AppText } from "./Text"

import type { RowProps } from "./Row"

/**
 * A component that displays a horizontal divider with text in the middle.
 */
export function WordDivider({
  text,
  ...rest
}: RowProps & {
  text?: string
}) {
  const { t } = useTranslation()
  return (
    <Row alignItems="center" px="$4" {...rest}>
      <Divider style={{ flex: 1 }} />
      <Box mx="$3">
        <AppText fontFamily="OpenSans_400Regular">{text ?? t("or")}</AppText>
      </Box>
      <Divider style={{ flex: 1 }} />
    </Row>
  )
}
