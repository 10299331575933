import React from "react"

import * as Geo from "./geo"
import { isValidNumber } from "./type-guards"

import type { GeoPoint, PointInput, RnmLatLng } from "./geo"
import type { InstallationType } from "./sensor-configurations"
const ARROW_ANGLE = 150
const ARROW_SIZE_METERS = 30

export interface ArrowLineProps {
  center: PointInput | null | undefined
  direction: number | null | undefined
  isClickable?: boolean
  isHidden?: boolean
  lengthMm?: number | undefined
}

/**
 * Create hook to generate direction arrow props for each platform
 * @param pointTransformer - convert to platform lat lng type
 */
export function makeUseDirectionArrow<
  T extends google.maps.LatLngLiteral | RnmLatLng,
>(pointTransformer: (pt: GeoPoint | undefined) => T | undefined) {
  return function useDirectionArrow({
    center,
    direction,
    lengthMm = 200000,
  }: ArrowLineProps) {
    const lengthMeters = lengthMm / 1000
    const { lat, lng } = Geo.point(center)?.toGmaps() ?? {}

    return React.useMemo(() => {
      if (
        isValidNumber(direction) &&
        isValidNumber(lng) &&
        isValidNumber(lat)
      ) {
        const centerWrapped = Geo.point({ lat, lng })
        const start = pointTransformer(
          centerWrapped?.project({
            direction,
            distanceMeters: lengthMeters,
          }),
        )
        const end = pointTransformer(
          centerWrapped?.project({
            direction: direction - 180,
            distanceMeters: lengthMeters,
          }),
        )

        const origin = Geo.point(end)

        const originConverted = pointTransformer(origin)

        const endLeftConverted = pointTransformer(
          origin?.project({
            direction: direction + 180 + ARROW_ANGLE,
            distanceMeters: ARROW_SIZE_METERS,
          }),
        )
        const endRightConverted = pointTransformer(
          origin?.project({
            direction: direction + 180 + -ARROW_ANGLE,
            distanceMeters: ARROW_SIZE_METERS,
          }),
        )
        return {
          arrowLineLeft:
            originConverted && endLeftConverted
              ? [originConverted, endLeftConverted]
              : undefined,
          arrowLineRight:
            originConverted && endRightConverted
              ? [originConverted, endRightConverted]
              : undefined,
          mainLine: start && end ? [start, end] : undefined,
        }
      }
      return undefined
    }, [direction, lat, lengthMeters, lng])
  }
}

/**
 * Create hook to generate direction arrow props for each platform.
 * If the device is not moving, or it can't move (e.g. it is a pump)
 * or the direction/coordinates are invalid, returns undefined
 * @param pointTransformer - convert to platform lat lng type
 */
export function makeUseDeviceMarkerArrow<
  T extends google.maps.LatLngLiteral | RnmLatLng,
>(pointTransformer: (pt: GeoPoint | undefined) => T | undefined) {
  return function useDirectionArrow({
    center,
    deviceInstallationType,
    direction,
    isInMotion,
    lengthMm = 200000,
  }: ArrowLineProps & {
    deviceInstallationType: InstallationType | null | undefined
    isInMotion: boolean
  }) {
    const lengthMeters = lengthMm / 1000
    const { lat, lng } = Geo.point(center)?.toGmaps() ?? {}

    const canMove =
      deviceInstallationType === "center_pivot" ||
      deviceInstallationType === "linear_move" ||
      deviceInstallationType === "traveller_soft"
    //  isMobileInstallationType(deviceInstallationType)

    return React.useMemo(() => {
      if (
        canMove &&
        isInMotion &&
        isValidNumber(direction) &&
        isValidNumber(lng) &&
        isValidNumber(lat)
      ) {
        const centerWrapped = Geo.point({ lat, lng })
        const start = pointTransformer(centerWrapped)
        const end = pointTransformer(
          centerWrapped?.project({
            direction,
            distanceMeters: lengthMeters,
          }),
        )

        // Arrow
        const origin = Geo.point(end)
        const originConverted = pointTransformer(origin)
        const endLeftConverted = pointTransformer(
          origin?.project({
            direction: direction + ARROW_ANGLE,
            distanceMeters: ARROW_SIZE_METERS,
          }),
        )
        const endRightConverted = pointTransformer(
          origin?.project({
            direction: direction + -ARROW_ANGLE,
            distanceMeters: ARROW_SIZE_METERS,
          }),
        )
        return {
          arrowLineLeft:
            originConverted && endLeftConverted
              ? [originConverted, endLeftConverted]
              : undefined,
          arrowLineRight:
            originConverted && endRightConverted
              ? [originConverted, endRightConverted]
              : undefined,
          mainLine: start && end ? [start, end] : undefined,
        }
      }
      return undefined
    }, [canMove, direction, isInMotion, lat, lengthMeters, lng])
  }
}
