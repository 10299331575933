import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import {
  AppText,
  Badge,
  Box,
  Divider,
  Heading,
  IconButton,
  Row,
  Switch,
} from "./components"
import { SPACING } from "./components/theme"
import i18n from "./translations/i18n"

import type { AppTextProps, NoChildren, BadgeProps } from "./components"

import type { FarmUserPermissionName } from "./permissions"
/**
 * Description of permissions that should appear with every help description of
 * and individual permission
 */
export function FarmUsePermissionsHelpSummary(
  props: NoChildren<AppTextProps>,
): React.JSX.Element {
  const { t } = useTranslation("farmUserPermissions")
  return <AppText {...props}>{t("farmJoinCodeHelpSummary")}</AppText>
}

export interface PermissionListItemProps {
  displayName: string
  permissionName: FarmUserPermissionName
  showDivider: boolean
  value: boolean
  isDisabled?: boolean
  onPressHelp?: () => void
  onValueChange?: (next: boolean) => void
}

const styles = StyleSheet.create({
  text: {
    marginHorizontal: SPACING.$2,
  },
})

/**
 * Display name of the permission along with its status (enabled vs. disabled).
 * Passing onValueChange handler will replace the status display with a switch
 * to toggle it.
 */
export function PermissionListItem({
  isEnabled,
  name,
  onPressHelp,
  onValueChange,
}: {
  isEnabled: boolean
  name: FarmUserPermissionName
  onPressHelp: (name: FarmUserPermissionName) => void
  onValueChange?: (next: boolean) => void
}) {
  /**
   * If onValueChange is provided, display a switch to toggle the permission.
   *
   * Otherwise, show a badge displaying the status of the permission.
   */
  let rightElement: React.JSX.Element
  if (onValueChange) {
    rightElement = <Switch value={isEnabled} onValueChange={onValueChange} />
  } else {
    let text = i18n.t("disabled")
    let colorScheme: BadgeProps["colorScheme"] = undefined
    if (isEnabled) {
      text = i18n.t("enabled")
      colorScheme = "$success"
    }
    rightElement = <Badge colorScheme={colorScheme}>{text}</Badge>
  }
  return (
    <Row key={name} justifyContent="space-between" py="$1">
      <Row flexGrow={1} flexWrap="nowrap">
        <IconButton
          IconComponent="Help"
          size="sm"
          variant="text"
          onPress={() => onPressHelp(name)}
        />
        <AppText style={styles.text}>
          {i18n.t(`farmUserPermissions:${name}.displayName`)}
        </AppText>
      </Row>
      <Box ml="auto">{rightElement}</Box>
    </Row>
  )
}

export function PermissionSectionHeader({
  descriptionText,
  titleText,
}: {
  descriptionText: string
  titleText: string
}) {
  return (
    <Box mb="$2">
      <Heading>{titleText}</Heading>
      <AppText colorScheme="secondary">{descriptionText}</AppText>
      <Divider mt="$2" />
    </Box>
  )
}

export function DevicePermissionsHeader() {
  const { t } = useTranslation("farmUserPermissions")
  return (
    <PermissionSectionHeader
      descriptionText={t("devicePermissionsDescription")}
      titleText={t("devicePermissionsTitle")}
    />
  )
}

export function FarmPermissionsHeader() {
  const { t } = useTranslation("farmUserPermissions")
  return (
    <PermissionSectionHeader
      descriptionText={t("farmPermissionsDescription")}
      titleText={t("farmPermissionsTitle")}
    />
  )
}
