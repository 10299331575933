import { Platform } from "react-native"

import { isTruthyString } from "../type-guards"

import type {
  AlertSeverity,
  AppFeatureName,
  AuthPageId,
  DeviceProfilePageId,
  FormFieldName,
  HelpKey,
} from "../constants"
import type { MapItemType } from "../geo"
import type { AnyScreenId, FieldActionName } from "../navigation"
import type { FarmUserPermissionName } from "../permissions"
import type { InstallationTypeName, SensorName } from "../sensor-configurations"
import type { AnySensorKey } from "../sensors"
import type { SupportPageId, SupportProductId } from "../Support"
import type { TriggerFilterKey } from "../triggers.reducer"
import type { RelayActionType } from "../device-commands.reducer"
export type DashboardPageId =
  | `account`
  | `admin`
  | `app-notifications`
  | `create-farm`
  | `dev`
  | `device-connections`
  | `device-profile`
  | `devices`
  | `farm`
  | `field-irrigation-history`
  | `fields`
  | `join-farm-with-code`
  | `manage-users`
  | `schedules`
  | `status-map`
  | `support`
  | `view-terms-of-service`

export type PageId = AuthPageId | DashboardPageId

export type MakeLinkId<T extends string> = `${T}-link`
export type LinkId = MakeLinkId<
  | DeviceProfilePageId
  | PageId
  | SupportPageId
  | `contact-us`
  | `create-configuration`
  | `create-custom-automation`
  | `debug-info`
  | `field-profile-landing`
  | `manage-field`
  | `manage-permissions`
  | `map-settings`
  | `set-reel-distance`
  | `set-run-direction`
  | `sign-out`
  | `view-permissions`
>
type ComponentIds =
  | `dialog`
  | `nav-menu`
  | `sidebar-content`
  | `sidebar-links`
  | `sidebar-root`
type ButtonId = `${
  | FieldActionName
  | `${`next` | `previous`}-${`device` | `field` | `reel-run`}`
  | `${Lowercase<RelayActionType>}`
  | `add-device`
  | `add-more-devices`
  | `add-phone-number`
  | `add-sensor`
  | `cancel`
  | `center-pivot`
  | `close-${
      | `device-commands`
      | `dialog`
      | `field-actions`
      | `page`
      | `sidebar`}`
  | `close`
  | `create-${`custom-notification` | `field` | `pair` | `trigger`}`
  | `customize-notification`
  | `decline`
  | `delete-pair`
  | `delete-sensor`
  | `delete${`-automation` | `-notification` | `-phone-number` | ``}`
  | `dislike`
  | `done`
  | `edit${`-configuration` | `-sensor` | ``}`
  | `farm-join-code-copy`
  | `feedback`
  | `focus-${MapItemType}`
  | `force-update`
  | `help`
  | `like`
  | `metric`
  | `nav-menu-open`
  | `new-join-code`
  | `newer`
  | `older`
  | `open-field-roster-actions`
  | `phone-number-options-menu`
  | `polygon`
  | `print`
  | `quick-actions`
  | `reload-events`
  | `remove-device`
  | `rename-${`device`}`
  | `resend-code`
  | `reset-device`
  | `set-${"center-pivot" | "linear"}-path-link`
  | `set-up-device`
  | `show-${`devices` | `fields`}`
  | `show-${SensorName}-sensor`
  | `show-more`
  | `sidebar-toggle`
  | `skip-for-now`
  | `submit`
  | `switch-controls-${`close` | `toggle`}`
  | `switch-farms`
  | `test-message`
  | `toggle-theme`
  | `us`
  | `user-account-menu-toggle`
  | `verify-phone-number`}-btn`

export type TestId =
  | AlertSeverity
  | AnyScreenId
  | AnySensorKey
  | AppFeatureName
  | AuthPageId
  | ButtonId
  | ComponentIds
  | DashboardPageId
  | FarmUserPermissionName
  | FieldActionName
  | FormFieldName
  | HelpKey
  | InstallationTypeName
  | LinkId
  | PageId
  | SensorName
  | SupportPageId
  | "experimental-feature-warning"
  | `${MapItemType}-cycler`
  | `${SensorName}-sensor`
  | `${SupportProductId}-link`
  | `account-locked-due-to-nonpayment`
  | `account`
  | `active-farm`
  | `add-device-form`
  | `add-phone-number`
  | `admin`
  | `app-bar`
  | `app`
  | `appLanguageSelect`
  | `azimuth-${`slider` | `text`}`
  | `background`
  | `battery-indicator`
  | `checkbox`
  | `choose-installation-type`
  | `coda-farm-tech`
  | `coda-section`
  | `confirm-configuration`
  | `confirm-delete-${TriggerFilterKey}`
  | `copy-location-button`
  | `create-${InstallationTypeName}`
  | `create-device-comment${"-link" | ""}`
  | `create-support-ticket`
  | `customize-notification-form`
  | `cycle-left`
  | `cycle-right`
  | `dashboard`
  | `delete-phone-number${`-confirmation`}`
  | `dev`
  | `device-commands-history-list`
  | `device-configuration`
  | `device-event-timestamp`
  | `device-in-field`
  | `device-location${"-link" | ""}`
  | `device-marker`
  | `device-notifications-list-item-${number}`
  | `device-notifications-list`
  | `device-notifications-toggle-${`disabled` | `enabled`}`
  | `device-notifications-toggle-denied`
  | `device-profile`
  | `device-status-indicators`
  | `device-status-list-item-${number}`
  | `devices-list-item-${number}`
  | `devices`
  | `enter-email-form`
  | `farm-join-code`
  | `feedback-form`
  | `field-actions`
  | `fields-list-item-${number}`
  | `floating-map-card`
  | `flow-indicator`
  | `flow-sensor-yes-or-no`
  | `forbidden`
  | `form-error`
  | `google-maps-link`
  | `gps-indicator`
  | `hallSwitch-indicator`
  | `help-content`
  | `help-text`
  | `input`
  | `installation-type-indicator`
  | `instructions`
  | `internal-only`
  | `load-error`
  | `loader`
  | `manage-farm`
  | `max-date`
  | `measurement-preference`
  | `min-date`
  | `no-list-items`
  | `notification-message-item-${number}`
  | `notify-checkbox`
  | `other-farms`
  | `page-404`
  | `page-500`
  | `page-content`
  | `page-header`
  | `page-title`
  | `password-visibility-checkbox`
  | `permission-text`
  | `permissions`
  | `phone-number-options-menu`
  | `phone-numbers-list-item-${number}`
  | `phone-numbers-list-link`
  | `pressure-indicator`
  | `pressure-sensor-yes-or-no`
  | `pressure-slider`
  | `print-table-of-content`
  | `radius-input`
  | `relay-indicator`
  | `rename-device-form`
  | `root-loader`
  | `run-completion-pct`
  | `run-eta-indicator`
  | `run-progress`
  | `run-speed-indicator`
  | `search`
  | `selected-device`
  | `sensor-buttons`
  | `sensorName`
  | `set-vfd-speed-form`
  | `source-device-select`
  | `source-sensor-select`
  | `source-sensor-state-${"current" | "previous"}${"-select" | ""}`
  | `source-sensor`
  | `subscription-due-reminder`
  | `subscription-overdue-warning`
  | `success`
  | `support-page-print-all`
  | `support-table-of-contents`
  | `support`
  | `switch-control-${number | "link"}`
  | `switch-controls`
  | `target-${"action" | "device"}-select`
  | `terms-of-service`
  | `toast-container`
  | `toggle-switch`
  | `toolbar`
  | `unconfigured-device-warning`
  | `unknown-fields-list-item-${number}`
  | `unknown-fields`
  | `unread-indicator`
  | `user-account-menu`
  | `user-email`
  | `voltage-indicator`
  | `welcome-dialog`

export interface TestIdOptions {
  preserveId?: boolean
}
export interface TestIdProps {
  id: TestId
  preserveId?: boolean
}
export type WithTestId<T, Id = TestId> = Omit<
  T,
  "id" | "nativeID" | "testID"
> & {
  id: Id & TestId
  preserveId?: boolean
}
export interface OptionalTestId<Id = TestId> {
  id?: Id & TestId
  preserveId?: boolean
}
export type WithOptionalTestId<T, Id = TestId> = Omit<
  T,
  "id" | "nativeID" | "testID"
> &
  OptionalTestId<Id>
/**
 * Some features depend on ids being present; they are removed unless
 * we opt-in to preserve them
 */
function getShouldPreserveTestIds(options?: TestIdOptions): boolean {
  const shouldPreserve = options?.preserveId === true

  const isCypressRunning =
    Platform.OS === "web" && typeof window.Cypress !== "undefined"

  if (isCypressRunning) {
    // Keep test ids if cypress is running
    return true
  }
  // Some ids should be preserved in production;
  // we can override the default behavior
  if (__DEV__ || shouldPreserve) {
    return true
  }
  return false
}
/**
 * Remove native and test ids in production unless we need them for something
 * @see {@link{https://reactnative.dev/docs/view#nativeid}}
 * @param options
 * @param options.keepInProduction
 * @param id
 */

export function testId(
  id: TestId | undefined,
  options?: TestIdOptions,
): TestId | undefined {
  if (getShouldPreserveTestIds(options)) {
    return id
  }
  return undefined
}
export interface TestIds {
  nativeID?: TestId | undefined
  testID?: TestId | undefined
}
/**
 * Remove native and test ids in production unless we need them for something
 * @see {@link{https://reactnative.dev/docs/view#nativeid}}
 * @param id
 * @param options
 * @param options.keepInProduction
 */

export function testIds(
  id: TestId | undefined,
  options?: TestIdOptions,
): TestIds | undefined {
  const shouldKeep = getShouldPreserveTestIds(options)
  if (shouldKeep && isTruthyString(id)) {
    return { nativeID: id, testID: id }
  }
  return undefined
}
