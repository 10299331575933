import React from "react"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import {
  AlertDialog,
  AlertDialogBodyText,
  AlertDialogScrollView,
  Box,
  Divider,
  FlatList,
  IconButton,
  Switch,
} from "./components"
import { Heading } from "./components/Heading"
import { Row } from "./components/Row"
import { AppText } from "./components/Text"

import type { FarmUserPermissionName } from "./permissions"

export function FarmUserPermissionToggle({
  isEnabled,
  onChange,
  onPressHelp,
  permissionName,
}: {
  isEnabled: boolean
  onChange: (nextValue: boolean) => void
  onPressHelp: (item: FarmUserPermissionName) => void
  permissionName: FarmUserPermissionName
}) {
  const { t } = useTranslation("farmUserPermissions")
  return (
    <Row alignItems="center" id={permissionName} py="$1">
      <Box mr="$2">
        <IconButton
          IconComponent="Info"
          size="sm"
          variant="text"
          onPress={() => {
            onPressHelp(permissionName)
          }}
        />
      </Box>
      <AppText style={{ flex: 1 }}>
        {t(`${permissionName}.displayName`)}
      </AppText>
      <Box ml="auto">
        <Switch id="toggle-switch" value={isEnabled} onValueChange={onChange} />
      </Box>
    </Row>
  )
}

export function useFarmUserPermissionsHelpDialog() {
  const [helpKey, setHelpKey] = React.useState<FarmUserPermissionName>()
  return {
    helpKey,
    onCloseDialog: () => setHelpKey(undefined),
    onOpenDialog: (key: FarmUserPermissionName) => setHelpKey(key),
  }
}

export function FarmUserPermissionHelpDialog({
  helpKey,
  onClose,
}: {
  helpKey: FarmUserPermissionName | undefined
  onClose: () => void
}) {
  const { t } = useTranslation("farmUserPermissions")
  let bodyText: string | undefined
  if (helpKey) {
    bodyText = t(`${helpKey}.extendedDescription`)
    return (
      <AlertDialog
        IconComponent="Permissions"
        isOpen={Boolean(helpKey)}
        titleElement={t("aboutFarmUserPermissions")}
        onClose={onClose}
      >
        <AlertDialogScrollView>
          <AlertDialogBodyText>
            {t("farmUserPermissionsAllowYouTo")}
          </AlertDialogBodyText>
          <Divider my="$4" />
          <AppText colorScheme="secondary">{t(`thisPermission`)}</AppText>
          <Heading variant="h4">{t(`${helpKey}.displayName`)}</Heading>
          <Box mt="$2" />
          <AlertDialogBodyText>{bodyText}</AlertDialogBodyText>
        </AlertDialogScrollView>
      </AlertDialog>
    )
  }
  return null
}
export function PermissionsForm<K extends FarmUserPermissionName>({
  cancelText,
  defaultValues,
  isLoading,
  keys,
  onCancel,
  onSubmit,
  subheaderText,
  submitText,
  titleText,
}: {
  isLoading: boolean
  keys: K[]
  onSubmit: (values: {
    [key in K]: boolean
  }) => void
  cancelText?: string
  defaultValues?: {
    [key in K]?: boolean | null | undefined
  }
  onCancel?: () => void
  subheaderText?: string
  submitText?: string
  titleText?: string
}) {
  type ValuesType = {
    [key in K]: boolean
  }

  const [values, setValues] = React.useState<ValuesType>(() => {
    return keys.reduce((acc, key) => {
      const defaultValue = defaultValues?.[key] ?? false
      return {
        ...acc,
        [key]: defaultValue,
      }
    }, {} as ValuesType)
  })
  const {
    helpKey,
    onCloseDialog: onClose,
    onOpenDialog,
  } = useFarmUserPermissionsHelpDialog()
  const spacing = "$4"

  return (
    <React.Fragment>
      <FarmUserPermissionHelpDialog helpKey={helpKey} onClose={onClose} />
      <FlatList
        ItemSeparatorComponent={() => <Divider my="$2" />}
        data={keys}
        ListFooterComponent={
          <ActionButtons
            cancelText={cancelText}
            isLoading={isLoading}
            mt={spacing}
            orientation="horizontal"
            submitText={submitText}
            onPressCancel={onCancel}
            onPressSubmit={() => onSubmit(values)}
          />
        }
        ListHeaderComponent={
          typeof titleText === "string" ? (
            <Box mb={spacing}>
              <Heading>{titleText}</Heading>
              <AppText colorScheme="secondary">{subheaderText}</AppText>
            </Box>
          ) : null
        }
        renderItem={({ item }) => {
          const onPressHelp = () => onOpenDialog(item)
          const onChange = (nextValue: boolean) => {
            setValues((previous) => {
              return {
                ...previous,
                [item]: nextValue,
              }
            })
          }
          const isEnabled = values[item]
          return (
            <FarmUserPermissionToggle
              isEnabled={isEnabled}
              permissionName={item}
              onChange={onChange}
              onPressHelp={onPressHelp}
            />
          )
        }}
      />
    </React.Fragment>
  )
}
