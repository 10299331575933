import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { SignOutButton } from "./Auth"
import {
  AppText,
  Avatar,
  Button,
  Divider,
  Heading,
  Row,
  View,
} from "./components"
import { SIZES, SPACING } from "./components/theme"
import { CreateSupportTicketForm } from "./CreateSupportTicketForm"
import { FarmHqLogo } from "./FarmHqLogo"
import { PrepareDemoButton } from "./PrepareDemoButton"
import { SeedDatabaseButton } from "./SeedDatabase"
import { getAuthStatusFromState } from "./selectors"
import { useRootSelector } from "./useRootSelector"

import type { ButtonProps } from "./components"
import type { EnvironmentProps } from "./PrepareDemoButton"

const BUTTON_PROPS: Omit<ButtonProps, "onPress"> = {
  flex: 1,
  variant: "outline",
}

const styles = StyleSheet.create({
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    maxWidth: "xl",
    paddingBottom: "toolbar",
    width: "100%",
  },
  root: {
    flex: 1,
    paddingBottom: SPACING.$toolbar,
    paddingHorizontal: SPACING.$4,
  },
})

export function LoadError({
  children,
  homeLink,
  onPrepareDemoSuccess,
  onSignedOut,
  targetDatabaseName,
}: EnvironmentProps & {
  onPrepareDemoSuccess: () => void
  children?: React.ReactNode
  homeLink?: React.JSX.Element
  onSignedOut?: () => void
}): React.JSX.Element {
  const authStatus = useRootSelector(getAuthStatusFromState)
  const [showContactForm, setShowContactForm] = React.useState(false)
  const handleOpenSupport = () => setShowContactForm(true)
  const { t } = useTranslation("errors")

  return (
    <View id="page-500" style={styles.root}>
      <View style={styles.header}>
        {showContactForm ? (
          <CreateSupportTicketForm onClose={() => setShowContactForm(false)} />
        ) : (
          <View>
            <Row>
              <Avatar>
                <FarmHqLogo
                  name="leaf-iso.png"
                  style={{
                    height: SIZES.$8,
                    width: SIZES.$full,
                  }}
                />
              </Avatar>
              <Heading fontFamily="Poppins_800ExtraBold">
                {t("loadErrorCode")}
              </Heading>
            </Row>

            <Heading colorScheme="secondary" fontFamily="Poppins_500Medium">
              {t("somethingWentWrong", { ns: "common" })}
            </Heading>
            <AppText>{t("loadErrorMessage")}</AppText>
            <Button
              {...BUTTON_PROPS}
              text={t("loadErrorSupportTicketLink")}
              onPress={handleOpenSupport}
            />
            {authStatus === "fulfilled" ? (
              <SignOutButton {...BUTTON_PROPS} onSuccess={onSignedOut} />
            ) : (
              homeLink
            )}
            {__DEV__ ? (
              <React.Fragment>
                <Divider />
                <AppText>{t("devOnly", { ns: "common" })}</AppText>
                <PrepareDemoButton
                  {...BUTTON_PROPS}
                  IconComponent="FlowOn"
                  targetDatabaseName={targetDatabaseName}
                  onSuccess={onPrepareDemoSuccess}
                />
                <SeedDatabaseButton
                  {...BUTTON_PROPS}
                  targetDatabaseName={targetDatabaseName}
                  onPress={undefined}
                />
              </React.Fragment>
            ) : null}
          </View>
        )}
      </View>
      {children}
    </View>
  )
}
