/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import {
  AppIcons,
  AppText,
  Pressable,
  Row,
  useIsDarkMode,
  View,
} from "./components"
import { OUTLINE_STYLE } from "./components/Outline"
import { SIZES, SPACING } from "./components/theme"

import type * as Models from "./models"

import type { SelectFieldHandler } from "./types"

/**
 *
 */
export function DeviceInFieldIndicator({
  centerLatLng,
  fieldId,
  fieldName,
  onPressField,
}: Pick<Models.FarmField, "centerLatLng" | "fieldId" | "fieldName"> & {
  onPressField: SelectFieldHandler
}): React.JSX.Element {
  const { t } = useTranslation("devices")

  const handlePress = () => {
    onPressField({ centerLatLng, fieldId })
  }
  const isDark = useIsDarkMode()
  return (
    <Pressable
      trackHover
      trackPress
      accessibilityHint={t("goToFieldProfile")}
      accessibilityRole="link"
      id="device-in-field"
      style={[
        styles.root,
        OUTLINE_STYLE.root,
        isDark && OUTLINE_STYLE.rootDark,
      ]}
      onPress={handlePress}
    >
      <View>
        <Row flex={1}>
          <AppIcons.Field />
          <AppText
            isTruncated
            colorScheme="secondary"
            fontSize="$sm"
            style={styles.text}
          >
            {t("inFieldWithColon")}
          </AppText>
        </Row>
        <AppText>{fieldName}</AppText>
      </View>
      <AppIcons.ArrowRight />
    </Pressable>
  )
}

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: SIZES.$48,
  },
  text: {
    marginLeft: SPACING.$1,
  },
})
