import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import { AppText, Box, Checkbox, FormControl, Row } from "./components"
import { useToasts } from "./components/useToasts"
import { ExperimentalFeatureWarning } from "./ExperimentalFeatureWarning"
import { callNamedDeviceActionAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import { getIsAdminModeEnabledFromState, useIsPending } from "./selectors"
import { SingleValueSlider } from "./sliders"
import { isValidNumber } from "./type-guards"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { DeviceIdProps } from "./types"
// {
//     "id": 20,
//     "sensorName": "vfd",
//     "serialized": null,
//     "expirationDate": "9999-12-31T00:00:00Z",
//     "installationType": "pump_vfd",
//     "hardwareGeneration": null,
//     "sensorType": "VFD",
//     "sensorId": 42,
//     "actionType": "VFDPIDS",
//     "arguments": null,
//     "name": "pump_vfd_pid_setpoint",
//     "displayName": "Set VFD Tracking Speed",
//     "apiVisible": true,
//     "expectedOutcome": "wet",
//     "iconKey": null,
//     "expiredByUser": null,
//     "device": null
//   }

export const SET_PID_SETPOINT_FORM_ACTION_NAME = "pump_vfd_pid_setpoint"

const DEFAULT_VALUES = {
  derivative: 5,
  gain: 10,
  integral: 0.1,
}
// const PID_DERIVATIVE_COEFF_DEFAULT = 5
// const PID_GAIN_COEFF_DEFAULT = 10
// const PID_INTEGRAL_COEFF_DEFAULT = 0.1
// TODO: Add checkbox and disclaimer
export function SetPidSetpointForm({
  actionId,
  deviceId,
  onClose,
  onSuccess,
}: DeviceIdProps & {
  actionId: number
  onClose: () => void
  onSuccess: () => void
}) {
  const psiMax = 160
  const psiMin = 0

  const dispatch = useRootDispatch()
  const toasts = useToasts()
  const handleError = useErrorHandler()
  const { t } = useTranslation("devices")
  const [isResponsibilityAccepted, setIsResponsibilityAccepted] =
    React.useState(false)

  const showAdminFields = useRootSelector((state) => {
    return __DEV__ || getIsAdminModeEnabledFromState(state)
  })
  const form = useForm<{
    pressureSetpointPsi: number
    derivative?: string
    gain?: string
    integral?: string
  }>({
    defaultValues: {
      derivative: `${DEFAULT_VALUES.derivative}`,
      gain: `${DEFAULT_VALUES.gain}`,
      integral: `${DEFAULT_VALUES.integral}`,
      pressureSetpointPsi: 40,
    },
  })

  const { decimalPattern, maxValue, minValue, required } = useFormValidation()

  const isLoading = useIsPending("CallNamedDeviceAction")
  const handleSubmit = form.handleSubmit((data) => {
    if (!isResponsibilityAccepted) {
      throw new TypeError(
        "User must accept responsibility before setting PID setpoint",
      )
    }

    const toNumber = (nextValue: number | string | undefined) => {
      if (typeof nextValue === "number") {
        return nextValue
      }
      if (typeof nextValue === "string") {
        const asNumber = Number.parseFloat(nextValue)
        if (isValidNumber(asNumber)) {
          return asNumber
        }
      }
      return undefined
    }

    // DERIVATIVE
    const pidDerivativeCoeff =
      toNumber(data.derivative) ?? DEFAULT_VALUES.derivative
    // GAIN
    const pidGainCoeff = toNumber(data.gain) ?? DEFAULT_VALUES.gain
    // INTEGRAL
    const pidIntegralCoeff = toNumber(data.integral) ?? DEFAULT_VALUES.integral

    // SET POINT
    const pidSensorSetpoint = toNumber(data.pressureSetpointPsi)

    if (typeof pidSensorSetpoint === "undefined") {
      throw new TypeError("pidSensorSetpoint is undefined")
    }

    if (!isValidNumber(pidDerivativeCoeff)) {
      throw new TypeError(
        `Invalid derivative: ${JSON.stringify(pidDerivativeCoeff)}`,
      )
    }
    if (!isValidNumber(pidGainCoeff)) {
      throw new TypeError(`Invalid gain: ${JSON.stringify(pidGainCoeff)}`)
    }
    if (!isValidNumber(pidIntegralCoeff)) {
      throw new TypeError(
        `Invalid integral: ${JSON.stringify(pidIntegralCoeff)}`,
      )
    }

    dispatch(
      callNamedDeviceActionAsync({
        actionId,
        arguments: {
          pidDerivativeCoeff,
          pidGainCoeff,
          pidIntegralCoeff,
          pidSensorId: 20,
          pidSensorSetpoint,
          pidSensorType: "P",
        },
        deviceId,
      }),
    )
      .unwrap()
      .then(() => {
        toasts.info(t("requestSentToDevice", { ns: "common" }))
        return onSuccess()
      })
      .catch((error) => {
        handleError(error, { toastMessage: "default" })
      })
  })

  return (
    <React.Fragment>
      <Controller
        control={form.control}
        name="pressureSetpointPsi"
        render={({ field, fieldState }) => {
          const errorMessage = fieldState.error?.message
          let asNumber = Number(field.value)
          if (!isValidNumber(asNumber)) {
            asNumber = 0
          }
          const labelText: string = t("setPidSetpointFormSetPointInputLabel")
          return (
            <FormControl.Provider isInvalid={Boolean(errorMessage)} my="$2">
              <FormControl.Label>{labelText}</FormControl.Label>
              <Row>
                <Box flexGrow={1} minW="$32" mr="$4">
                  <SingleValueSlider
                    maximumValue={psiMax}
                    minimumValue={psiMin}
                    value={asNumber}
                    onValueChange={(value) => {
                      field.onChange(value)
                    }}
                  />
                  <Row justifyContent="space-between">
                    <AppText>{psiMin}</AppText>
                    <AppText>{psiMax}</AppText>
                  </Row>
                </Box>
                <Box w="$16">
                  <FormControl.Input
                    keyboardType="numeric"
                    returnKeyLabel="Done"
                    returnKeyType="done"
                    value={`${field.value}`}
                    onChangeText={field.onChange}
                  />
                </Box>
              </Row>
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
        rules={{
          max: maxValue(psiMax),
          min: minValue(psiMin),
          pattern: decimalPattern,
          required,
        }}
      />
      {showAdminFields ? (
        <Row justifyContent="space-between" my="$2">
          <Box flex={1} w="$24">
            <Controller
              control={form.control}
              name="gain"
              render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message
                const labelText = "Gain"
                return (
                  <FormControl.Provider isInvalid={Boolean(errorMessage)}>
                    <FormControl.Label>{labelText}</FormControl.Label>
                    <FormControl.Input
                      keyboardType="numeric"
                      returnKeyLabel="Done"
                      returnKeyType="done"
                      value={`${field.value ?? ""}`}
                      onChangeText={field.onChange}
                    />
                    <FormControl.ErrorMessage>
                      {errorMessage}
                    </FormControl.ErrorMessage>
                  </FormControl.Provider>
                )
              }}
              rules={{
                pattern: decimalPattern,
              }}
            />
          </Box>
          <Box flex={1} w="$24">
            <Controller
              control={form.control}
              name="integral"
              render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message
                const labelText = "Integral"
                return (
                  <FormControl.Provider isInvalid={Boolean(errorMessage)}>
                    <FormControl.Label>{labelText}</FormControl.Label>
                    <FormControl.Input
                      keyboardType="numeric"
                      returnKeyLabel="Done"
                      returnKeyType="done"
                      value={`${field.value ?? ""}`}
                      onChangeText={field.onChange}
                    />
                    <FormControl.ErrorMessage>
                      {errorMessage}
                    </FormControl.ErrorMessage>
                  </FormControl.Provider>
                )
              }}
              rules={{
                pattern: decimalPattern,
              }}
            />
          </Box>
          <Box flex={1} w="$24">
            <Controller
              control={form.control}
              name="derivative"
              render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message
                const labelText = "Derivative"
                return (
                  <FormControl.Provider isInvalid={Boolean(errorMessage)}>
                    <FormControl.Label>{labelText}</FormControl.Label>
                    <FormControl.Input
                      keyboardType="numeric"
                      returnKeyLabel="Done"
                      returnKeyType="done"
                      value={`${field.value ?? ""}`}
                      onChangeText={field.onChange}
                    />
                    <FormControl.ErrorMessage>
                      {errorMessage}
                    </FormControl.ErrorMessage>
                  </FormControl.Provider>
                )
              }}
              rules={{
                pattern: decimalPattern,
              }}
            />
          </Box>
        </Row>
      ) : null}
      <Row mt="$4">
        <Box flex={1} mr="$4">
          <AppText fontSize="$sm">
            {t("setPidSetpointFormDisclaimerAcknowledgement")}
          </AppText>
        </Box>
        <Box>
          <Checkbox
            isChecked={isResponsibilityAccepted}
            onChange={setIsResponsibilityAccepted}
          />
        </Box>
      </Row>
      <ExperimentalFeatureWarning
        bodyText={t("setPidSetpointFormDisclaimerBody")}
        mt="$4"
      />
      <ActionButtons
        isLoading={isLoading}
        isSubmitDisabled={!isResponsibilityAccepted}
        onPressCancel={onClose}
        onPressSubmit={handleSubmit}
      />
    </React.Fragment>
  )
}
