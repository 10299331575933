import { useTranslation } from "react-i18next"

import { identifyMissingPermissions } from "./permissions"
import { getFarmUserPermissionsFromState } from "./selectors"
import { useRootDispatch } from "./useRootDispatch"
import { useShallowEqualSelector } from "./useRootSelector"

import type { FarmUserPermissionName } from "./permissions"
import type { RootDispatch } from "./store"

export type WithPermissions = (params: {
  callback: () => unknown
  required: FarmUserPermissionName | FarmUserPermissionName[]
}) => () => unknown

/**
 * This hook is used to check if the user has the required permissions
 * to perform an action. If the user does not have the required permissions,
 * the `onDenied` callback is called.
 */
export function makePermissionCheckHook(params: {
  onDenied: (params: {
    dispatch: RootDispatch
    message: string
    missingPermissions: FarmUserPermissionName[]
    title: string
  }) => unknown
}) {
  return function usePermissionCheckedAction() {
    const dispatch = useRootDispatch()
    const provided = useShallowEqualSelector(getFarmUserPermissionsFromState)
    const { t } = useTranslation("farmUserPermissions")
    const message = t("youDoNotHavePermission")
    const title = t("permissionDenied")

    const withPermissions: WithPermissions = ({ callback, required }) => {
      const missingPermissions = identifyMissingPermissions({
        provided,
        required,
      })
      return () => {
        // If any permissions are missing, the user cannot perform the action
        if (missingPermissions.length > 0) {
          return params.onDenied({
            dispatch,
            message,
            missingPermissions,
            title,
          })
        }

        // Let the action proceed
        return callback()
      }
    }

    return {
      withPermissions,
    }
  }
}

/**
 * Use this to deduplicate differing logic for checking
 * permissions in components
 */
export interface WithPermissionsProps {
  withPermissions: WithPermissions
}
