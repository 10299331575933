import { shallowEqual, useSelector } from "react-redux"

import type { TypedUseSelectorHook } from "react-redux"

import type { RootState } from "./root.reducer"

export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector
export function useShallowEqualSelector<R>(
  selector: (state: RootState) => R,
): R {
  return useRootSelector<R>(selector, shallowEqual)
}
