import React from "react"
import { Path, Rect, Svg } from "react-native-svg"

import { COLORS } from "../theme"
import { useIsDarkMode } from "../ThemeProvider"

import type { SvgProps } from "react-native-svg"
const DEFAULT_HEIGHT = 20
const DEFAULT_WIDTH = DEFAULT_HEIGHT

function useCustomIconProps() {
  const isDark = useIsDarkMode()
  return {
    fill: isDark ? COLORS.$textLight.primary : COLORS.$textDark.primary,
  }
}
export function ReelSvgIcon(props: SvgProps): React.JSX.Element | null {
  return (
    <Svg
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      {...useCustomIconProps()}
      {...props}
      viewBox="0 0 51.84 45.04"
    >
      <Path d="M5.84,10.11l.11,0A.34.34,0,0,0,6.06,10l.06,0,0,0a.61.61,0,0,0,.07-.1.31.31,0,0,0,0-.1.36.36,0,0,0,0-.1.5.5,0,0,0,0-.12v0a.29.29,0,0,0,0-.09s0-.05,0-.08a.87.87,0,0,0-.11-.18l0,0A14.53,14.53,0,0,0,2.82,6.42,2.37,2.37,0,0,0,1.31,6.3,1.77,1.77,0,0,0,.14,7.47,1.88,1.88,0,0,0,1.49,10a14.58,14.58,0,0,0,4.27.12ZM1.91,8.89c-.89-.34-.74-.76-.64-1s.27-.4.38-.43a1.27,1.27,0,0,1,.76.09A8.77,8.77,0,0,1,4.31,9,9,9,0,0,1,1.91,8.89Z" />
      <Path d="M7.56,6.1l.07,0,.1.06.13,0,.07,0h.15l.11,0,.09,0,.1-.08,0,0A.56.56,0,0,0,8.46,6l0-.06a.73.73,0,0,0,.06-.21v0a14.51,14.51,0,0,0-.28-4.26A2.37,2.37,0,0,0,7.34.23,1.78,1.78,0,0,0,5.69.17a1.88,1.88,0,0,0-1,2.72A14.67,14.67,0,0,0,7.56,6.1ZM6.15,1.28a.63.63,0,0,1,.58,0,1.25,1.25,0,0,1,.44.62,8.49,8.49,0,0,1,.22,2.4A8.48,8.48,0,0,1,5.85,2.43C5.48,1.55,5.9,1.38,6.15,1.28Z" />
      <Path d="M48.64,33.76V6.09A2.65,2.65,0,0,0,46,3.44h-.28a2.65,2.65,0,0,0-2.64,2.65V33.76a6.06,6.06,0,0,0-2.61,2.71H7.24A2.64,2.64,0,0,0,4.6,39.11v.27a2.62,2.62,0,0,0,1.82,2.5L9,44.46a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29L12.85,42H40.67a6,6,0,1,0,8-8.27Zm-3.56-.69v-27a.65.65,0,0,1,.64-.65H46a.65.65,0,0,1,.64.65v27ZM9.71,42.34,9.39,42H10ZM39.88,40H7.24a.65.65,0,0,1-.64-.65v-.27a.64.64,0,0,1,.64-.64H39.88Zm6,3a4,4,0,1,1,4-4A4,4,0,0,1,45.86,43Z" />
      <Path d="M45.86,37.28a1.78,1.78,0,1,0,1.78,1.78A1.78,1.78,0,0,0,45.86,37.28Zm-.22,1.78a.22.22,0,0,1,.44,0C46.08,39.3,45.64,39.3,45.64,39.06Z" />
      <Path d="M10.66,12.4v.95H8a1,1,0,0,0-1,1v9.57a1,1,0,0,0,2,0V15.35h5.35v11a.5.5,0,0,1-.5.5H7.1a2.51,2.51,0,0,0,0,5H17.9V33a2.64,2.64,0,0,0,2.64,2.64h.28A2.64,2.64,0,0,0,23.46,33V6.63A2.65,2.65,0,0,0,20.82,4h-.28A2.65,2.65,0,0,0,17.9,6.63V29.88H7.1a.51.51,0,0,1,0-1h6.73a2.5,2.5,0,0,0,2.5-2.5v-12a1,1,0,0,0-1-1H12.66V12a1,1,0,0,0-.3-.71L10.81,9.73A1,1,0,0,0,9.4,11.14ZM19.9,6.63A.65.65,0,0,1,20.54,6h.28a.65.65,0,0,1,.64.65V33a.64.64,0,0,1-.64.64h-.28A.64.64,0,0,1,19.9,33Z" />
      <Rect height="24.46" rx="2.13" width="4.26" x="24.84" y="7.57" />
      <Rect height="24.46" rx="2.13" width="4.26" x="31.23" y="7.57" />
      <Rect height="24.46" rx="2.13" width="4.26" x="37.62" y="7.57" />
    </Svg>
  )
}

export function PumpSvgIcon(props: SvgProps): React.JSX.Element {
  const iconElement = (
    <Svg
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      {...useCustomIconProps()}
      {...props}
      viewBox="0 0 72 42.57"
    >
      <Path d="M1.38,0A1.38,1.38,0,0,0,0,1.38V14a1.38,1.38,0,0,0,2.76,0V1.38A1.38,1.38,0,0,0,1.38,0Z" />
      <Path d="M70.62,27.22a1.38,1.38,0,0,0-1.38,1.38V41.18a1.38,1.38,0,1,0,2.76,0V28.6A1.38,1.38,0,0,0,70.62,27.22Z" />
      <Path d="M59.12,27.22H56.29A21.27,21.27,0,0,0,35.85,0H12.34A5.34,5.34,0,0,0,7,5.34V10a5.34,5.34,0,0,0,5.34,5.34h3.08A21.28,21.28,0,0,0,35.85,42.57H59.12a5.34,5.34,0,0,0,5.33-5.34V32.55A5.33,5.33,0,0,0,59.12,27.22ZM12.34,12.58A2.59,2.59,0,0,1,9.76,10V5.34a2.59,2.59,0,0,1,2.58-2.58h13a21.4,21.4,0,0,0-8.94,9.82ZM36.22,39.8h-.64a18.47,18.47,0,1,1,.64,0Zm25.47-2.58a2.58,2.58,0,0,1-2.57,2.58H46.34A21.48,21.48,0,0,0,55.27,30h3.85a2.58,2.58,0,0,1,2.57,2.57Z" />
      <Path d="M35.85,19.81a1.77,1.77,0,1,0,1.77,1.77A1.77,1.77,0,0,0,35.85,19.81Z" />
      <Path d="M49.36,20.88l-1.24,0h0l-1.38,0h0L45,21h0l-1.56,0-2,0a5.62,5.62,0,0,0-1.33-3.1L46,12S41,6.35,35.26,8v.34h0V9h0l0,1.43,0,1.64h0l0,1.39h0l0,2.47a5.59,5.59,0,0,0-3,1.2l-5.87-5.88s-5.68,5-4.08,10.78h1.2l1.59,0,1.51,0h0l1.38,0h0l2.14,0a5.66,5.66,0,0,0,1.26,3.23l-5.59,5.4c2.91,5.18,10.5,4.74,10.5,4.74V27.2a5.57,5.57,0,0,0,3.11-1.32l5.78,5.78S51,26.61,49.36,20.88ZM35.85,24.46a2.88,2.88,0,1,1,2.88-2.88A2.88,2.88,0,0,1,35.85,24.46Z" />
    </Svg>
  )
  return iconElement
}

export function FarmSvgIcon(props: SvgProps): React.JSX.Element {
  const iconElement = (
    <Svg
      height={DEFAULT_HEIGHT}
      width={DEFAULT_HEIGHT}
      {...useCustomIconProps()}
      {...props}
      viewBox="0 0 43.5 43.97"
      // color={"white"}
      // stroke={"white"}
    >
      <Path
        // stroke={"white"}
        // fill="white"
        d="M43.43,27.15l-3.62-9.27a1,1,0,0,0-.55-.57L28.46,12.8h0a1,1,0,0,0-.39-.08,1,1,0,0,0-.39.08h0l-5.8,2.42V9.54a9.55,9.55,0,0,0-19.09,0v30L.27,42.26A1,1,0,0,0,1,44H22.36a1,1,0,0,0,.38-.08,1.08,1.08,0,0,0,.18-.11,1.1,1.1,0,0,0,.14-.09l5-4.82,5,4.82a.75.75,0,0,0,.15.09l.17.11a1,1,0,0,0,.39.08H40a1,1,0,0,0,1-1V28.54h1.53a1,1,0,0,0,.84-.45A1,1,0,0,0,43.43,27.15ZM16.88,17.31a1,1,0,0,0-.55.57l-3.62,9.27a1,1,0,0,0,.1.94,1,1,0,0,0,.84.45h1.53V39H4.83V10.56h15v5.51ZM12.34,2a7.52,7.52,0,0,1,7.43,6.49H4.91A7.51,7.51,0,0,1,12.34,2ZM4.25,41H15.18v1H3.36Zm23.82-8h3.19l-3.19,3.07L24.88,33Zm-4.7,7.58v-6.2l3.23,3.1Zm9.4-6.2v6.2l-3.23-3.1Zm2,7.57V32s0-.09,0-.14a1,1,0,0,0-.05-.24,1.64,1.64,0,0,0-.14-.22s0-.08-.07-.11h0a1,1,0,0,0-.27-.19l-.05,0s-.09,0-.13,0A1.19,1.19,0,0,0,33.8,31H22.34a.94.94,0,0,0-.28.06L22,31a.18.18,0,0,0-.07,0,.81.81,0,0,0-.26.18h0s0,.08-.07.11a1.64,1.64,0,0,0-.14.22,2,2,0,0,0-.05.24c0,.05,0,.09,0,.14v10H17.21V28.53a1,1,0,0,0,.86-.59l2.77-6.07,7.23-3.33,7.23,3.33,2.77,6.07a1,1,0,0,0,.86.59v13.4ZM39.65,26.5,37,20.68a1,1,0,0,0-.5-.5l-8-3.68h0a.8.8,0,0,0-.21,0,1.13,1.13,0,0,0-.18,0,1,1,0,0,0-.17,0,.79.79,0,0,0-.22,0h0l-8,3.68a1,1,0,0,0-.5.5L16.49,26.5H15.14L18.07,19l10-4.18,10,4.18L41,26.5Z"
      />
      <Path
        // stroke={"white"}
        // fill={"white"}
        d="M28.07,20.93A2.91,2.91,0,1,0,31,23.84,2.91,2.91,0,0,0,28.07,20.93Zm0,3.78a.87.87,0,1,1,.87-.87A.87.87,0,0,1,28.07,24.71Z"
        strokeWidth="32"
      />
    </Svg>
  )
  return iconElement
}

export function CenterPivotSvgIcon(props: SvgProps): React.JSX.Element {
  return (
    <Svg
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      {...useCustomIconProps()}
      viewBox="0 0 110 110"
      {...props}
    >
      <Path
        d="M 503.387 247.654 A 111.859 111.859 0 1 1 391.527 135.795 L 391.527 247.654 Z"
        transform="matrix(0.480307012796402, 0, 0, 0.4647510349750519, -128.5478973388672, -58.133209228515625)"
      />
      <Path
        d="M 126.693 195.144 H 151.723 L 146.587 186.999 L 167.867 197.683 L 146.587 208.368 L 151.723 200.222 H 126.693 V 195.144 Z"
        transform="matrix(0.848046, 0.529922, -0.529922, 0.848046, 71.845596, -219.814959)"
      />
    </Svg>
  )
}

export function LinearMoveIcon(props: SvgProps) {
  return (
    <Svg
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      {...useCustomIconProps()}
      viewBox="0 0 200 200"
      {...props}
    >
      <Path
        d="M 27.46 119.846 H 112.265 L 112.265 91.346 L 165.265 136.846 L 112.265 182.346 L 112.265 153.846 H 27.46 V 119.846 Z"
        transform="matrix(0.9999979734420775, 0.00203300011344254, -0.00203300011344254, 0.9999979734420775, 6.673548221588135, -34.80413818359375)"
      />
      <Rect
        height="200"
        transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -3.552713678800501e-15)"
        width="9.695"
        x="186.73"
        y="4.33"
      />
      <Rect
        height="200"
        transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -3.552713678800501e-15)"
        width="9.695"
        x="7.254"
        y="4.33"
      />
    </Svg>
  )
}
