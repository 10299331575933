import React from "react"
import { useTranslation } from "react-i18next"

import { Geo, SetLinearPath } from "@fhq/app"
import { Box } from "@fhq/app/components"
import { COLORS, getFontName } from "@fhq/app/components/theme"
import {
  DrawingManager,
  GoogleMap,
  Marker,
  Polyline,
} from "@react-google-maps/api"

import { DeviceMarker } from "./base/DeviceMarker"
import { FloatingMapCard } from "./base/FloatingMapCard"
import LinearSwathOutlineVisualization from "./base/LinearSwathOutlineVisualization"

import type { GoogleMapProps, PolylineProps } from "@react-google-maps/api"
const MAP_OPTIONS: google.maps.MapOptions = {
  disableDefaultUI: true,
  mapTypeControl: false,
  mapTypeId: "satellite",
}

const MAP_CONTAINER_STYLE: GoogleMapProps["mapContainerStyle"] = {
  flex: 1,
}

const DRAWING_MANAGER_OPTIONS: google.maps.drawing.DrawingManagerOptions = {
  drawingControlOptions: {
    // We cannot directly use the google maps library here and cache this
    // object simultaneously because the library is loaded asynchronously.
    drawingModes: ["marker" as google.maps.drawing.OverlayType.MARKER],
  },
}

/**
 * Add custom style to marker text
 */
function makeLabel(text: string): google.maps.MarkerLabel {
  return {
    color: COLORS.$textLight.primary,
    fontFamily: getFontName("Poppins_500Medium"),
    fontSize: "15px",
    text,
  }
}

const PATH_OPTIONS: PolylineProps["options"] = {
  icons: [
    {
      icon: {
        path: "M 0,-1 0,1",
        scale: 5,
        strokeOpacity: 1,
      },
      offset: "0",
      repeat: "30px",
    },
  ],
  strokeColor: SetLinearPath.LINEAR_PATH_COLORS.path.stroke,
  strokeOpacity: 1,
  strokeWeight: 2,
}

export function LinearPathConfiguration() {
  const { t } = useTranslation()

  const {
    activeFarmLat,
    activeFarmLng,
    deviceMarkerData,
    drawingMode,
    handlePlaceMarker,
    initialLat,
    initialLng,
    isSwathVisible,
    linearSpanHeadingDegrees,
    linearSpanWidthMm,
    parkingSpots,
    pointEnd,
    pointStart,
    stage,
  } = SetLinearPath.useContext()

  // convert the points to LatLngLiteral
  const pointStartLatLng = Geo.point(pointStart)?.toGmaps()
  const pointEndLatLng = Geo.point(pointEnd)?.toGmaps()
  const parkingSpotsLatLng = parkingSpots.map((spot) =>
    Geo.point(spot.coordinates)?.toGmaps(),
  )

  /**
   * If the device location is not null, use it as the initial center.
   * Otherwise, use the initial lat/lng from the farm.
   */
  const initialCenter = React.useMemo(():
    | google.maps.LatLngLiteral
    | undefined => {
    if (typeof initialLat === "number" && typeof initialLng === "number") {
      return {
        lat: initialLat,
        lng: initialLng,
      }
    }
    if (
      typeof activeFarmLat === "number" &&
      typeof activeFarmLng === "number"
    ) {
      return {
        lat: activeFarmLat,
        lng: activeFarmLng,
      }
    }
    return undefined
  }, [activeFarmLat, activeFarmLng, initialLat, initialLng])

  return (
    <Box bottom="$0" flex={1} left="$0" position="absolute" right="$0" top="$0">
      <GoogleMap
        center={initialCenter}
        mapContainerStyle={MAP_CONTAINER_STYLE}
        options={MAP_OPTIONS}
        zoom={15}
      >
        {deviceMarkerData ? (
          <DeviceMarker
            showDeviceActivityVisualizations
            showParkingSpots={false}
            {...deviceMarkerData}
          />
        ) : null}
        <DrawingManager
          drawingMode={drawingMode as google.maps.drawing.OverlayType | null}
          options={DRAWING_MANAGER_OPTIONS}
          onMarkerComplete={
            /**
             * When a marker is placed, remove it from the map and
             * add it to the list of parking spots or the start/end.
             */
            React.useCallback(
              (marker: google.maps.Marker) => {
                marker.setMap(null)
                const latLng = marker.getPosition()?.toJSON()
                handlePlaceMarker({ latLng })
              },
              [handlePlaceMarker],
            )
          }
        />
        {pointStartLatLng && pointEndLatLng ? (
          <Polyline
            options={PATH_OPTIONS}
            path={[pointStartLatLng, pointEndLatLng]}
          />
        ) : null}
        {isSwathVisible && pointStart && pointEnd ? (
          <LinearSwathOutlineVisualization
            isClickable={false}
            linearSpanHeadingDegrees={linearSpanHeadingDegrees}
            linearSpanWidthMm={linearSpanWidthMm}
            linearPathStops={[
              { coordinates: pointStart },
              { coordinates: pointEnd },
            ]}
          />
        ) : null}
        {pointStartLatLng ? (
          <Marker label={makeLabel(t("start"))} position={pointStartLatLng} />
        ) : null}
        {pointEndLatLng ? (
          <Marker label={makeLabel(t("end"))} position={pointEndLatLng} />
        ) : null}
        {parkingSpotsLatLng.map((spot, i) => {
          if (spot) {
            return (
              <Marker
                key={i}
                draggable
                label={makeLabel(`Spot ${i + 1}`)}
                position={spot}
                onDragEnd={(e) => {
                  const latLng = e.latLng?.toJSON()
                  handlePlaceMarker({ latLng, replacePointAtIndex: i })
                }}
              />
            )
          }
          return null
        })}
      </GoogleMap>
      <FloatingMapCard>
        <SetLinearPath.TitleWithStage />
        <SetLinearPath.Instructions />
        {stage === "swath" ? (
          <Box mb="$4">
            <SetLinearPath.LinearSpanWidthField />
          </Box>
        ) : null}
        <SetLinearPath.Actions />
      </FloatingMapCard>
    </Box>
  )
}
