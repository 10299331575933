import type * as Geo from "./geo"
import type { SensorName } from "./sensor-configurations"

import type { ValuesType } from "utility-types"

export const SENSOR_NAME_TO_STATES = {
  battery: {
    batteryDead: `BD`,
    batteryEmergencyShutdown: `BES`,
    batteryLow: `BL`,
    batteryNormal: `BN`,
  },
  device: {
    deviceConfigured: `C`,
    deviceNoSignal: `NSIG`,
    deviceNotConfigured: `NC`,
    deviceSleep: `DVS`,
    deviceSleepAbort: `DVSA`,
    deviceSleepQueued: `DVSQ`,
    deviceSleepWake: `DVSW`,
  },
  flow: {
    flowFlowing: `FLF`,
    flowNotFlowing: `FLN`,
  },
  gps: {
    gpsActive: `A`,
    gpsDisabled: `GDSBL`,
    gpsError: `GERR`,
    gpsInactive: `I`,
    gpsOff: `GOFF`,
    gpsOnLockedStable: `GST`,
    gpsOnLockedUnstable: `GLK`,
    gpsOnUnlocked: `GULK`,
  },
  hallSwitch: {
    magnetAbsent: `MA`,
    magnetPresent: `MP`,
  },
  icm: {
    icmActive: `A`,
    icmInactive: `I`,
  },
  pressure: {
    pressureHigh: `PHI`,
    pressureLow: `PLO`,
    pressureOver: `POV`,
  },
  pressureSwitch: {
    pressureSwitchHigh: `PHI`,
    pressureSwitchLow: `PLO`,
  },
  reel: {
    reelExtending: `RE`,
    reelRetracting: `RR`,
    reelStopped: `RS`,
  },
  relay: {
    relayOff: `OFF`,
    relayOn: `ON`,
  },
  temperature: {
    temperatureHigh: `THI`,
    temperatureLow: `TLO`,
  },
  vfd: {
    off: "VFDOF",
    onRamping: "VFDRP",
    onStable: "VFDST",
    pidTracking: "VFDPIDT",
    vfdSpeedOverMax: "VFDOV",
    vfdSpeedUnderMin: "VFDUN",
  },
  wheel: {
    wheelFast: `WF`,
    wheelSlow: `WL`,
    wheelStopped: `WS`,
    wheelUnknown: `WUNK`,
  },
} as const
export type AnySensorState = ValuesType<
  (typeof SENSOR_NAME_TO_STATES)[SensorName]
>
type GetStatesOfSensor<S extends SensorName> = S extends S
  ? ValuesType<(typeof SENSOR_NAME_TO_STATES)[S]>
  : never
export type SensorState<
  S extends SensorName = SensorName,
  V extends GetStatesOfSensor<S> = GetStatesOfSensor<S>,
> = S extends S ? (V extends V ? V | `NONE` : never) : never

export const BASE_SENSOR_EVENT_KEYS = [
  `sensorTriggeredUpdate`,
  `stateCurrent`,
  `stateCurrentInitDt`,
  `statePrevious`,
  `statePreviousInitDt`,
] as const
export const SENSOR_NAME_TO_EVENT_KEYS = {
  battery: [
    ...BASE_SENSOR_EVENT_KEYS,
    `voltageMv`,
    `voltageChangeMvPerHour`,
    `internalSoc`,
  ],
  device: [
    ...BASE_SENSOR_EVENT_KEYS,
    `configurationId`,
    `sensorPriority`,
    `signalStrength`,
    `signalQuality`,
    `otaEnabled`,
  ],
  flow: [
    ...BASE_SENSOR_EVENT_KEYS,
    `rateLpmAvg`,
    `rateLpmMax`,
    `rateLpmMin`,
    `reportingPeriodEndDt`,
    `reportingPeriodStartDt`,
    `volumeLInitial`,
    `volumeLFinal`,
    `volumeLTotal`,
  ],
  gps: [...BASE_SENSOR_EVENT_KEYS, `location`, `altitude`, `nSats`, `heading`],
  hallSwitch: [...BASE_SENSOR_EVENT_KEYS],
  icm: [...BASE_SENSOR_EVENT_KEYS],
  pressure: [...BASE_SENSOR_EVENT_KEYS, `readingKpa`],
  pressureSwitch: [...BASE_SENSOR_EVENT_KEYS],
  reel: [
    ...BASE_SENSOR_EVENT_KEYS,
    `runDistanceMmCurrent`,
    `runDistanceMmMax`,
    `runSpeedMmpm`,
    `linearSpeedMmHMin`,
    `linearSpeedMmHMax`,
    `runMagnetCountCurrent`,
    `runMagnetCountMax`,
    `speedMillirpm`,
  ],
  relay: [...BASE_SENSOR_EVENT_KEYS],
  temperature: [...BASE_SENSOR_EVENT_KEYS, `readingCelsius`],
  vfd: [
    ...BASE_SENSOR_EVENT_KEYS,
    `currentPercentage`,
    `deviceFunctionCall`,
    `pidDerivativeCoeff`,
    `pidGainCoeff`,
    `pidIntegralCoeff`,
    `pidSensorSetpoint`,
    `rampSecondsRemaining`,
    `rampSecondsTotal`,
    `scheduledDeviceAction`,
    `startRampPercentage`,
    `targetRampPercentage`,
    // `deviceFunctionCall`,
    // `scheduledDeviceAction`
  ],
  wheel: [...BASE_SENSOR_EVENT_KEYS, `speedMmpm`, `milliRpmAvg`],
} as const
type GetKeysOfSensorEvent<S extends SensorName> =
  (typeof SENSOR_NAME_TO_EVENT_KEYS)[S][number]

export type SensorEventKey<S extends SensorName = SensorName> = S extends S
  ? GetKeysOfSensorEvent<S>
  : never
export type GetSensorEventValue<
  S extends SensorName,
  K extends SensorEventKey<S> = SensorEventKey<S>,
> = K extends K
  ? K extends `stateCurrent` | `statePrevious`
    ? SensorState<S>
    : K extends
        | `reportingPeriodEndDt`
        | `reportingPeriodStartDt`
        | `stateCurrentInitDt`
        | `statePreviousInitDt`
    ? string
    : K extends `sensorTriggeredUpdate`
    ? boolean
    : K extends `location`
    ? Geo.PointGeoJson
    : number | null | undefined
  : never
export type SensorEvent<S extends SensorName> = S extends S
  ? {
      [key in SensorEventKey<S>]: GetSensorEventValue<S, key> | null | undefined
    }
  : never

export interface SensorEvents {
  battery?: SensorEvent<`battery`> | null
  device?: SensorEvent<`device`> | null
  flow?: SensorEvent<`flow`> | null
  gps?: SensorEvent<`gps`> | null
  hallSwitch?: SensorEvent<`hallSwitch`> | null
  icm?: SensorEvent<`icm`> | null
  /**
   * Default for pumps and reels from FC2 onward
   */
  pressure?: SensorEvent<`pressure`> | null

  /**
   * Default for legacy PC1
   */
  pressureSwitch?: SensorEvent<`pressureSwitch`> | null

  reel?: SensorEvent<`reel`> | null
  relay?: SensorEvent<`relay`> | null
  temperature?: SensorEvent<`temperature`> | null
  vfd?: SensorEvent<"vfd"> | null
  wheel?: SensorEvent<`wheel`> | null
}

const cardinalDirections = [
  "N",
  "NE",
  "E",
  "SE",
  "S",
  "SW",
  "W",
  "NW",
  "N",
] as const

/**
 * Get direction from degrees
 */
export function degreesToCardinalDirection(degrees: number) {
  // Define an array of cardinal directions in order

  // Calculate the index of the cardinal direction based on the degrees
  const index = Math.round(degrees / 45) % 8

  // Return the cardinal direction
  return cardinalDirections[index]
}
