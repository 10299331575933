/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from "react"
import { Animated, Platform } from "react-native"

import type { ViewProps } from "react-native"

export interface ISupportedTransitions {
  opacity?: number
  rotate?: string
  scale?: number
  scaleX?: number
  scaleY?: number
  translateX?: number
  translateY?: number
}
export interface ITransitionConfig {
  bounciness?: number
  damping?: number
  delay?: number
  duration?: number
  easing?: (value: number) => number
  friction?: number
  mass?: number
  overshootClamping?: boolean
  restDisplacementThreshold?: number
  restSpeedThreshold?: number
  speed?: number
  stiffness?: number
  tension?: number
  type?: "spring" | "timing"
  useNativeDriver?: boolean
  velocity?: number | { x: number; y: number }
}
const transformStylesMap = {
  rotate: true,
  scale: true,
  scaleX: true,
  scaleY: true,
  translateX: true,
  translateY: true,
}

const defaultStyles = {
  opacity: 1,
  rotate: "0deg",
  scale: 1,
  scaleX: 1,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
}

const getAnimatedStyles =
  (animateValue: Animated.Value) =>
  (initial: ISupportedTransitions, to: ISupportedTransitions) => {
    const styles: {
      [key: string]: any
      transform: any[]
    } = {
      transform: [],
    }
    for (const key in initial) {
      if (key === "transition") {
        continue
      }
      if (Platform.OS === "web") {
        // NOTE: This code causes a compilation error in mobile apps
        // but we only need it for web currently.
        // This part is taken directly from native base; idk
        // what it does.
        if (key in transformStylesMap) {
          styles.transform.push({
            [key]: animateValue.interpolate({
              inputRange: [0, 1],
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              outputRange: [(initial as any)[key], (to as any)[key]],
            }),
          })
        } else {
          styles[key] = animateValue.interpolate({
            inputRange: [0, 1],
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            outputRange: [(initial as any)[key], (to as any)[key]],
          })
        }
      }
    }

    return styles
  }

const defaultTransitionConfig: ITransitionConfig = {
  delay: 0,
  duration: 250,
  type: "timing",
  useNativeDriver: true,
}
export interface ITransitionStyleProps extends ISupportedTransitions {
  transition?: ITransitionConfig
}
export interface ITransitionProps extends ViewProps {
  /**
   * Entry animation styles
   */
  animate?: ITransitionStyleProps
  animationExited?: boolean
  as?: any
  children?: any
  /**
   * Exit animation styles
   */
  exit?: ITransitionStyleProps

  /**
   * Styles before the transition starts
   */
  initial?: ISupportedTransitions
  /**
   * Callback invoked when the transition is completed.
   */
  onTransitionComplete?: (s: "entered" | "exited") => any
  /**
   * Determines whether to start the animation
   */
  visible?: boolean
}
export interface IPresenceTransitionProps extends ViewProps {
  /**
   * Entry animation styles.
   */
  animate?: ITransitionStyleProps
  /**
   * Accepts a Component to be rendered as Wrapper. Defaults to `View`.
   */
  as?: React.ReactNode
  children?: React.ReactNode
  /**
   * Exit animation styles.
   */
  exit?: ITransitionStyleProps
  /**
   * Styles before the transition starts.
   */
  initial?: ISupportedTransitions
  /**
   * Callback invoked when the transition is completed.
   */
  onTransitionComplete?: (s: "entered" | "exited") => unknown
  /**
   * Determines whether to start the animation.
   */
  visible?: boolean
}

export const Transition = forwardRef(function Transition(
  {
    animate,
    as,
    children,
    exit,
    initial,
    onTransitionComplete,
    style,
    visible = false,
    ...rest
  }: ITransitionProps,
  ref: any,
) {
  const animateValue = React.useRef(new Animated.Value(0)).current

  const Component = React.useMemo(() => {
    if (Boolean(as)) {
      return Animated.createAnimatedComponent(as)
    }
    return Animated.View
  }, [as])

  const [animationState, setAnimationState] = React.useState("")

  const prevVisible = React.useRef(visible)

  React.useEffect(() => {
    if (animationState === "entering" || animationState === "exiting") {
      const entryTransition = {
        ...defaultTransitionConfig,
        ...animate?.transition,
      }
      const exitTransition = {
        ...defaultTransitionConfig,
        ...exit?.transition,
      }

      const startAnimation = animationState === "entering" ? 1 : 0

      const transition = startAnimation ? entryTransition : exitTransition

      Animated.sequence([
        // @ts-ignore - delay is present in defaultTransitionConfig
        Animated.delay(transition.delay),
        Animated[transition.type ?? "timing"](animateValue, {
          toValue: startAnimation,
          useNativeDriver: true,
          ...transition,
        }),
      ]).start(() => {
        if (animationState === "entering") {
          setAnimationState("entered")
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (animationState === "exiting") {
          setAnimationState("exited")
        }
      })
      // });
    }

    if (animationState === "exited") {
      onTransitionComplete && onTransitionComplete("exited")
    } else if (animationState === "entered") {
      onTransitionComplete && onTransitionComplete("entered")
    }
    // if (animationState === 'entering') {
    //   //
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationState, onTransitionComplete])

  React.useEffect(() => {
    // if (!visible) {
    if (prevVisible.current !== visible && !visible) {
      setAnimationState("exiting")
    }

    if (visible) {
      setAnimationState("entering")
    }
    prevVisible.current = visible
    // }
  }, [visible])

  // If exit animation is present and state is exiting, we replace 'initial' with 'exit' animation
  //  const initialState = { ...defaultStyles, ...initial };
  // const initialState =
  //   animationState === "exited" && exit
  //     ? { ...defaultStyles, ...exit }
  //     : { ...defaultStyles, ...initial }
  // const initialState = { ...defaultStyles, ...initial };
  // initial =
  //   animationState === 'exited'
  //     ? { ...defaultStyles, ...exit }
  //     : { ...defaultStyles, ...initial };

  // const [initialState, setInitialState] = React.useState({
  //   ...defaultStyles,
  //   ...initial,
  // });

  // const [animateState] = React.useState({ ...defaultStyles, ...animate });
  const styles = React.useMemo(() => {
    return [
      getAnimatedStyles(animateValue)(
        animationState === "exited" && exit
          ? { ...defaultStyles, ...exit }
          : ({ ...defaultStyles, ...initial } as ISupportedTransitions),
        { ...defaultStyles, ...animate } as ISupportedTransitions,
      ),
      style,
    ]
  }, [animate, animateValue, animationState, exit, initial, style])

  return (
    <Component
      // pointerEvents="box-none"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ref={ref}
      // https://github.com/facebook/react-native/issues/23090#issuecomment-710803743
      // needsOffscreenAlphaCompositing
      // style={[styles]}
      pointerEvents={visible ? "box-none" : "none"}
      style={styles}
      {...rest}
    >
      {children}
    </Component>
  )
})

export const ExitAnimationContext = React.createContext({
  exited: true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setExited: (_exited: boolean) => {
    //
  },
})

export const PresenceTransition = React.forwardRef<
  any,
  IPresenceTransitionProps
>(function PresenceTransition(
  { onTransitionComplete, visible = false, ...rest },
  ref,
) {
  // const [animationExited, setAnimationExited] = React.useState(!visible);
  const { setExited } = React.useContext(ExitAnimationContext)

  return (
    <Transition
      visible={visible}
      onTransitionComplete={(state) => {
        if (state === "exited") {
          setExited(true)
        } else {
          setExited(false)
        }
        onTransitionComplete && onTransitionComplete(state)
      }}
      {...rest}
      ref={ref}
    />
  )
})
