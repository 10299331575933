import React from "react"
import { useTranslation } from "react-i18next"
import { createSelector } from "reselect"

import {
  getIconForSensor,
  MenuItemButton,
  renderIconFromIconProp,
} from "./components"
import { Box } from "./components/Box"
import {
  ListItemEndIcon,
  ListItemTextPrimary,
  ListItemTextSecondary,
} from "./components/ListItem"
import { Row } from "./components/Row"
import * as Models from "./models"
import { listConfigSensors } from "./sensor-configurations"
import { isMutableSensorName } from "./sensors"

import type { PressableProps } from "react-native"
import type { PermissionCheckProps, TrackEventProps } from "./types"

import type { ConfigSensorName } from "./sensor-configurations"
export const getData = createSelector(
  Models.deviceConfiguration.selectById,
  (configuration) => {
    if (configuration) {
      return listConfigSensors(configuration).map((sensorName) => {
        const sensorConfig = configuration[sensorName]
        let sensorPortNumber: number | null = null

        if (sensorConfig && "sensorPort" in sensorConfig) {
          if (typeof sensorConfig.sensorPort === "number") {
            sensorPortNumber = sensorConfig.sensorPort
          }
        }
        return {
          isReadonly: !isMutableSensorName(sensorName),
          key: sensorName,
          sensorName,
          sensorPortNumber,
        }
      })
    }
    return []
  },
)

/**
 * Render configured sensors list item...shows sensor name and permission-protected link
 */
export function RenderItem({
  onPressSensor,
  sensorName,
  sensorPortNumber,
  textPrimary,
  trackEvent,
  withPermissions,
  ...rest
}: Omit<PressableProps, "onPress"> &
  PermissionCheckProps &
  TrackEventProps & {
    onPressSensor: (sensorName: ConfigSensorName) => void
    sensorName: ConfigSensorName
    sensorPortNumber: number | null
    textPrimary: string
  }): React.JSX.Element {
  const { t } = useTranslation("sensorFields")
  const handlePress = withPermissions({
    callback: () => {
      onPressSensor(sensorName)
      trackEvent(
        {
          elementName: "edit-sensor-btn",
          name: "element_press",
        },
        { sensorName },
      )
    },
    required: "canManageDeviceConfiguration",
  })
  return (
    <MenuItemButton
      {...rest}
      IconComponent={getIconForSensor(sensorName)}
      id={`${sensorName}-sensor`}
      text={textPrimary}
      onPress={handlePress}
    >
      <Row
        alignItems="center"
        flexWrap="nowrap"
        id={`${sensorName}-sensor`}
        justifyContent="space-between"
        w="$full"
      >
        {renderIconFromIconProp(getIconForSensor(sensorName))}
        <Box mx="$2">
          <ListItemTextPrimary isTruncated textTransform="none">
            {textPrimary}
          </ListItemTextPrimary>
        </Box>
        {typeof sensorPortNumber === "number" ? (
          <ListItemTextSecondary>
            {t("sensorPort.displayNameWithVal", { val: sensorPortNumber })}
          </ListItemTextSecondary>
        ) : null}
        <Box id="edit-sensor-btn" ml="auto">
          <ListItemEndIcon />
        </Box>
      </Row>
    </MenuItemButton>
  )
}
