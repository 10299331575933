import React from "react"
import { useParams } from "react-router"

import { isTruthyString } from "@fhq/app"
import { SUPPORT_PAGE_DEVICES, SUPPORT_PAGE_ID } from "@fhq/app/Support"
import * as Sentry from "@sentry/react"

import {
  FC2deviceOverview,
  FC2inTheBox,
  FC2pumpHardwareInstallation,
  FC2reelHardwareInstallation,
  FC2troubleshooting,
  PC1deviceOverview,
  PC1inTheBox,
  PC1pumpHardwareInstallation,
  PC1reelHardwareInstallation,
  SolarPanelDeviceOverview,
  TC3deviceOverview,
  TC3electricPumpInstallation,
  TC3flowmeter,
  TC3inTheBox,
  TC3pumpHardwareInstallation,
  TC3reelHardwareInstallation,
  TC3troubleshooting,
} from "./base/support-files"

import type { AcceptsChildren } from "@fhq/app/components"

import type { Support } from "@fhq/app"
import type { FetchStatus } from "@fhq/app/Requests"

const SUPPORT_PRODUCT_TO_FILE_MAP = {
  "FC2": {
    [SUPPORT_PAGE_ID.deviceOverview]: FC2deviceOverview,
    [SUPPORT_PAGE_ID.inTheBox]: FC2inTheBox,
    [SUPPORT_PAGE_ID.pumpHardwareInstallation]: FC2pumpHardwareInstallation,
    [SUPPORT_PAGE_ID.reelHardwareInstallation]: FC2reelHardwareInstallation,
    [SUPPORT_PAGE_ID.troubleshooting]: FC2troubleshooting,
  },
  "PC1": {
    [SUPPORT_PAGE_ID.deviceOverview]: PC1deviceOverview,
    [SUPPORT_PAGE_ID.inTheBox]: PC1inTheBox,
    [SUPPORT_PAGE_ID.pumpHardwareInstallation]: PC1pumpHardwareInstallation,
    [SUPPORT_PAGE_ID.reelHardwareInstallation]: PC1reelHardwareInstallation,
  },
  "TC3": {
    [SUPPORT_PAGE_ID.deviceOverview]: TC3deviceOverview,
    [SUPPORT_PAGE_ID.electricPump]: TC3electricPumpInstallation,
    [SUPPORT_PAGE_ID.flowmeter]: TC3flowmeter,
    [SUPPORT_PAGE_ID.inTheBox]: TC3inTheBox,
    [SUPPORT_PAGE_ID.pumpHardwareInstallation]: TC3pumpHardwareInstallation,
    [SUPPORT_PAGE_ID.reelHardwareInstallation]: TC3reelHardwareInstallation,
    [SUPPORT_PAGE_ID.troubleshooting]: TC3troubleshooting,
  },
  "solar-panel": {
    [SUPPORT_PAGE_ID.deviceOverview]: SolarPanelDeviceOverview,
  },
} as const
export const DEFAULT_PAGE_ID = "device-overview"
export const DEFAULT_PRODUCT_ID = "TC3"
interface SupportState {
  fetchStatus: FetchStatus
  markdownText?: string | undefined
}

const INITIAL_STATE: SupportState = { fetchStatus: `pending` }

function useSupportPage() {
  const params = useParams<{
    pageId: Support.SupportPageId
    productId: Support.SupportProductId
  }>()
  const currentPageId = params.pageId ?? DEFAULT_PAGE_ID
  const currentProductId = params.productId ?? DEFAULT_PRODUCT_ID
  const files = SUPPORT_PRODUCT_TO_FILE_MAP[currentProductId]
  const [expandedIndex, setExpandedIndex] = React.useState<number | undefined>(
    0,
  )

  let markdownFileName: string | undefined

  if (typeof files === "object") {
    markdownFileName = (files as { [key: string]: string })[currentPageId]
  } else {
    markdownFileName = undefined
  }

  const [{ fetchStatus, markdownText }, setState] =
    React.useState<SupportState>(() => ({ ...INITIAL_STATE }))
  // const isSmallScreen = useIsSmallScreen();

  React.useEffect(
    /**
     * Fetch file contents asynchronously to dynamically generate page
     */
    () => {
      async function fetchMarkdownFile() {
        if (isTruthyString(markdownFileName)) {
          setState({ ...INITIAL_STATE })
          const response = await fetch(markdownFileName)
          setState({
            fetchStatus: "fulfilled",
            markdownText: await response.text(),
          })
        }
      }

      fetchMarkdownFile().catch((error) => {
        Sentry.captureException(error)
        setState({ fetchStatus: "rejected" })
      })
    },
    [markdownFileName],
  )
  const productName = SUPPORT_PAGE_DEVICES.find(
    (item) => item.id === currentProductId,
  )?.name

  return React.useMemo(
    () => ({
      currentPageId,
      currentProductId,
      expandedIndex,
      fetchStatus,
      markdownText,
      productName,
      setExpandedIndex,
    }),
    [
      currentPageId,
      currentProductId,
      expandedIndex,
      fetchStatus,
      markdownText,
      productName,
    ],
  )
}

type ContextValue = ReturnType<typeof useSupportPage>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
}: AcceptsChildren): React.JSX.Element | null {
  const value = useSupportPage()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`SupportPage Context must be used inside of provider`)
  }
  return ctx
}
