import type { Primitive } from "type-fest"

import type { Falsy, Nullish } from "./types"

export function isNullish(target: unknown): target is Nullish {
  return target === null || typeof target === "undefined"
}
export function isFalsy(target: unknown): target is Falsy {
  return (
    target === false ||
    typeof target === "undefined" ||
    target === null ||
    target === 0 ||
    target === "" ||
    Number.isNaN(target)
  )
}
export function isPrimitive(value: unknown): value is Primitive {
  if (typeof value === "object") {
    return false
  }
  return (
    typeof value === "undefined" ||
    typeof value === "symbol" ||
    typeof value === "string" ||
    typeof value === "number" ||
    value === null ||
    value === true ||
    value === false
  )
}

export function isTruthyString(target: unknown): target is string {
  return typeof target === "string" && target.length > 0
}

export function isValidNumber(target: unknown): target is number {
  return Boolean(
    typeof target === "number" &&
      !Number.isNaN(target) &&
      Number.isFinite(target),
  )
}

export function notNullish<T>(target: T): target is NonNullable<T> {
  return !isNullish(target)
}

export function makeValidator<T extends number | string>(
  options: T[] | readonly T[],
) {
  return (target: number | string | null | undefined): target is T => {
    if (target !== null && typeof target !== "undefined") {
      return options.includes(target as T)
    }
    return false
  }
}

export function listObjectKeys<
  K extends string,
  O extends {
    [key in K]: unknown
  },
>(obj: O): Array<keyof O> {
  return Object.keys(obj) as Array<keyof O>
}
// const test = {
//   A: { text: "1" },
//   B: { text: "2" },
// } as const
// const t = listObjectKeys(test)
