import React from "react"
import { ScrollView, StyleSheet, Text } from "react-native"

import { FONT_SIZES, getFontName } from "./components/theme"

import type { ScrollViewProps } from "react-native"
// IMPORTANT: No native-base in this file

const styles = StyleSheet.create({
  text: {
    flex: 1,
    fontFamily: getFontName("OpenSans_400Regular"),
    fontSize: FONT_SIZES.$sm,
  },
})

export function ErrorDebugInfo({
  children,
  isInternal,
  ...props
}: ScrollViewProps & { isInternal: boolean }): React.JSX.Element | null {
  if (isInternal) {
    return (
      <ScrollView {...props}>
        <Text style={styles.text}>{children}</Text>
      </ScrollView>
    )
  }
  return null
}
