import camelcaseKeys from "camelcase-keys"
import snakecaseKeys from "snakecase-keys"

import { Auth } from "@aws-amplify/auth"
import { createAsyncThunk } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/core"

import { trackEvent } from "./Analytics"
import { getNodeEnv, REQUEST_BLOCKED_IN_DEMO } from "./constants"
import { makeDatabaseValueGetter } from "./Internal"
import { makeFileLogger } from "./logger"
import { getApiUrl } from "./Requests"
import { getFarmUserPermissionsFromState } from "./selectors"
import {
  getActiveFarmIdFromState,
  getIsDemoModeActiveFromState,
} from "./user-farms.selectors"

import type { JsonObject } from "type-fest"

import type { AsyncThunk, AsyncThunkOptions } from "@reduxjs/toolkit"
import type { RequestNameToHandler } from "./farmhq-api"
import type { CognitoRequestName } from "./constants"
import type { DatabaseValue } from "./Internal"
import type {
  FarmUserPermissionName,
  FarmUserPermissions,
  RequiredPermissions,
} from "./permissions"
import type { BackendRequestBody, RootThunkExtra } from "./Requests"
import type { RootState, RootThunkConfig } from "./root.reducer"
export type BackendRequestName<
  T extends keyof RequestNameToHandler = keyof RequestNameToHandler,
> = T extends T ? T : never
export type AnyRequestName =
  | BackendRequestName
  | CognitoRequestName
  | "CreateSupportTicket"
  | "ExitDemoMode"
  | "GeocodeFarmAddress"
  | "ReadLocalStorage"
  | "SaveLocalValues"
  | "SetActiveFarmId"

export type GetActionArgumentsType<N extends BackendRequestName> = N extends N
  ? RequestNameToHandler[N]["actionArguments"]
  : never
export type GetResponseDataType<N extends BackendRequestName> = N extends N
  ? RequestNameToHandler[N]["responseData"]
  : never

export type GetRequestBodyType<N extends BackendRequestName> =
  BackendRequestBody<N, GetActionArgumentsType<N>>

export interface BackendRequest<A = void, R = undefined> {
  actionArguments: A
  responseData: R
}

const logger = makeFileLogger({
  fileName: "send-request.ts",
})

export class BadRequestError extends Error {
  statusCode: number
  constructor({
    code,
    message,
    requestedAction,
  }: {
    code: number
    message: string
    requestedAction: AnyRequestName
  }) {
    if (!Boolean(message)) {
      message = `The following request failed: ${requestedAction}`
    }
    super(message)
    this.name = "BadRequestError"

    this.statusCode = code
  }
}
export class AuthTokenRejectedError extends Error {
  statusCode = 401
  name = "AuthTokenRejectedError"
  constructor(message: string) {
    super(message)
  }
}
export async function sendRequest<
  N extends BackendRequestName,
  A extends GetActionArgumentsType<N>,
>(
  requestedAction: N,
  {
    actionArguments,
    activeFarmId,
    analyticsClient,
    appName,
    appVersion,
    environmentInfo,
    isEndToEndTest,
    targetDatabaseName,
  }: RootThunkExtra & {
    actionArguments: A
    activeFarmId: number | null | undefined
  },
): Promise<GetResponseDataType<N>> {
  // Tags for sentry
  Sentry.setTag("requested_action", requestedAction)
  Sentry.setTag("active_farm_id", `${activeFarmId ?? "None"}`)

  const nodeEnv = getNodeEnv()

  const body: BackendRequestBody = {
    actionArguments,
    activeFarmId,
    extra: {
      appName,
      appVersion,
      isEndToEndTest,
      nodeEnv,
      targetDatabaseName,
    },
    requestedAction,
  }

  try {
    const authSession = await Auth.currentSession()

    const { url } = getApiUrl({
      isEndToEndTest,
      localhost: environmentInfo.localhost,
      targetDatabaseName,
    })

    // Backend request
    const response = await fetch(url, {
      body: JSON.stringify(snakecaseKeys(body, { deep: true })),
      headers: {
        Authorization: authSession.getIdToken().getJwtToken(),
        "Content-Type": "application/json",
      },
      method: "POST",
    })

    if (response.ok) {
      const asJson = (await response.json()) as JsonObject | null | undefined

      return camelcaseKeys(asJson ?? {}, {
        deep: true,
        exclude: ["address_line_1", "address_line_2"],
      }) as GetResponseDataType<N>
    }

    let error: Error
    if (response.status === 401) {
      // Don't log this one to sentry
      error = new AuthTokenRejectedError(
        "Auth token was rejected by API gateway",
      )
    } else {
      error = new BadRequestError({
        code: response.status,
        message: response.statusText,
        requestedAction,
      })
    }
    Sentry.captureException(error)
    // Pass error to the catch block
    throw error
  } catch (e) {
    logger.error(e)
    // In a thunk, this will get automatically serialized to include name, message
    // If it it just a string, only message is included
    throw e
  } finally {
    trackEvent(analyticsClient, {
      activeFarmId,
      environmentInfo,
      name: "backend_request",
      requestedAction,
    })
  }
}

export type GetAsyncThunkType<
  N extends BackendRequestName,
  R extends GetResponseDataType<N> = GetResponseDataType<N>,
  A = GetActionArgumentsType<N>,
> = AsyncThunk<R, A, RootThunkConfig>

type GetRequiredPermissions<
  N extends keyof RequestNameToHandler,
  A extends GetActionArgumentsType<N>,
> = (actionArguments: A, state: RootState) => RequiredPermissions | undefined
function checkPermissions({
  providedPermissions: provided,
  requiredPermissions: required,
}: {
  providedPermissions: FarmUserPermissions | null | undefined
  requiredPermissions: RequiredPermissions | null | undefined
}):
  | { missing: FarmUserPermissionName[]; result: "missing" }
  | { result: "ok"; missing?: undefined } {
  const requiredArray = Array.isArray(required)
    ? required
    : typeof required === "string"
    ? [required]
    : []
  const missing: FarmUserPermissionName[] = []
  for (const key of requiredArray) {
    let isEnabled = false
    if (provided) {
      isEnabled = provided[key]
    }
    if (!isEnabled) {
      missing.push(key)
    }
  }
  if (missing.length > 0) {
    return { missing, result: "missing" }
  }
  return { result: "ok" }
}

export function createHandler<
  N extends keyof RequestNameToHandler,
  A extends GetActionArgumentsType<N>,
  R extends GetResponseDataType<N>,
>(
  requestedAction: N,
  options?: {
    demoMode?: { allowRequest?: boolean }
    requiredPermissions?: GetRequiredPermissions<N, A> | RequiredPermissions
    routeNameOverride?: Partial<DatabaseValue<string>>
    thunkOptions?: AsyncThunkOptions<A, RootThunkConfig>
    transformArguments?: (params: {
      actionArguments: A
      getState: () => RootState
    }) => A
  },
): AsyncThunk<R, A, RootThunkConfig> {
  const {
    demoMode,
    requiredPermissions,
    routeNameOverride,
    thunkOptions,
    transformArguments,
  } = options ?? {}

  const defaultOptions: AsyncThunkOptions<A, RootThunkConfig> | undefined = {
    condition: (_, { getState }) => {
      const requests = getState().requests.entities
      const fetchStatus = requests[requestedAction]?.fetchStatus
      if (fetchStatus === "pending") {
        return false
      }
      return true
    },
  }
  return createAsyncThunk<R, A, RootThunkConfig>(
    requestedAction,
    async (
      actionArguments,
      { extra: { targetDatabaseName, ...extra }, getState, rejectWithValue },
    ) => {
      const getDbValue = makeDatabaseValueGetter(targetDatabaseName)
      Sentry.setTag("requested_action", requestedAction)
      Sentry.setTag("target_db_name", targetDatabaseName)

      const allowRequestInDemo = demoMode?.allowRequest === true

      const routeName = getDbValue({
        defaultValue: requestedAction,
        ...routeNameOverride,
      }) as N

      const state = getState()

      const activeFarmId = getActiveFarmIdFromState(state)

      // DENY REQUESTS THAT ARE MISSING PERMISSIONS

      let required: RequiredPermissions | undefined
      if (typeof requiredPermissions === "function") {
        required = requiredPermissions(actionArguments, state)
      } else {
        required = requiredPermissions
      }

      const missingPermissions = checkPermissions({
        providedPermissions: getFarmUserPermissionsFromState(state),
        requiredPermissions: required,
      })
      // Reducers can subscribe to this ("rejected with value")
      if (missingPermissions.result === "missing") {
        return rejectWithValue(missingPermissions)
      }

      // When demo is active, requests should not go through to the backend
      // unless explicitly given permission
      const isDemoModeEnabled = getIsDemoModeActiveFromState(state)

      // Some requests should be blocked in demo mode to prevent
      // changes to database state
      if (!allowRequestInDemo && isDemoModeEnabled) {
        return rejectWithValue({
          reason: REQUEST_BLOCKED_IN_DEMO,
        })
      }

      //Get the response from the backend
      const responseData = await sendRequest(routeName, {
        // Apply any dynamic changes to action arguments if needed
        actionArguments: transformArguments
          ? transformArguments({ actionArguments, getState })
          : actionArguments,
        activeFarmId,
        targetDatabaseName,
        ...extra,
      })

      return responseData as R
    },
    { ...defaultOptions, ...thunkOptions },
  )
}
