import React from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { AlertCard, AlertDialog, AppText, Box, Button } from "./components"
import { dismissNewFarmSuccess } from "./user-farms.reducer"
import { getActiveFarmNameFromState } from "./user-farms.selectors"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { ButtonProps } from "./components"
import type { PermissionCheckProps } from "./types"
interface NewFarmSuccessProps extends PermissionCheckProps {
  onPressAddDevice: () => void
  onPressCreateField: () => void
  onPressSupport: () => void
}

/**
 * Closes new farm success dialog on press
 */
function ActionButton({
  description,
  onPress,
  text,
  ...props
}: Omit<ButtonProps, "children" | "onPress"> & {
  description: string
  text: string
  onPress?: () => void
}): React.JSX.Element {
  const dispatch = useRootDispatch()
  const handlePress = () => {
    if (onPress) {
      onPress()
    } else {
      dispatch(dismissNewFarmSuccess())
    }
  }
  return (
    <View>
      <Box my="$2">
        <AppText colorScheme="secondary">{description}</AppText>
      </Box>
      <Button onPress={handlePress} {...props} text={text} />
    </View>
  )
}

export function NewFarmSuccessContent({
  onPressAddDevice,
  onPressCreateField,
  onPressSupport,
  withPermissions,
}: NewFarmSuccessProps) {
  const { t } = useTranslation("noFarms")

  return (
    <React.Fragment>
      <AlertCard bodyText={t("successInstructions")} severity="success" />
      <Box mb="$4">
        <ActionButton
          IconComponent="Add"
          description={t("successAddDevices.description")}
          id="add-device-btn"
          text={t("successAddDevices.text")}
          onPress={withPermissions({
            callback: onPressAddDevice,
            required: "canManageDevicePermissions",
          })}
        />
      </Box>
      <Box mb="$4">
        <ActionButton
          IconComponent="Draw"
          description={t("successDrawFields.description")}
          id="create-field-btn"
          text={t("successDrawFields.text")}
          onPress={withPermissions({
            callback: onPressCreateField,
            required: "canManageFields",
          })}
        />
      </Box>
      <ActionButton
        IconComponent="Info"
        description={t("successViewSupport.description")}
        id="support-link"
        text={t("successViewSupport.text")}
        onPress={onPressSupport}
      />
    </React.Fragment>
  )
}

export function NewFarmSuccess(
  props: NewFarmSuccessProps,
): React.JSX.Element | null {
  const dispatch = useRootDispatch()

  const { t } = useTranslation("noFarms")

  const farmName = useRootSelector(getActiveFarmNameFromState)
  const isOpen = useRootSelector((state) => state.userFarms.showNewFarmSuccess)
  const handleClose = () => dispatch(dismissNewFarmSuccess())
  return (
    <AlertDialog
      IconComponent="Success"
      id="new-farm-success"
      isOpen={isOpen}
      subheaderElement={farmName}
      titleElement={t("welcome", { ns: "common" })}
      onClose={handleClose}
    >
      <NewFarmSuccessContent {...props} />
    </AlertDialog>
  )
}
