import { subDays, subYears } from "date-fns"

import AsyncStorage from "@react-native-async-storage/async-storage"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { captureException } from "@sentry/core"

import { isValidMapTypeId } from "./geo"
import { logger, makeFileLogger } from "./logger"
import { isValidMeasurementPreference } from "./sensor-conversions"
import {
  isValidDeviceSortKey,
  isValidFieldSortKey,
  SortDirections,
} from "./Sorting"
import { isValidNumber, makeValidator } from "./type-guards"

import type { MapTypeId } from "./geo"
import type { RootThunkConfig } from "./root.reducer"
import type { MeasurementPreference } from "./sensor-conversions"

import type { ObjectKeys } from "react-hook-form/dist/types/path/common"
import type { DeviceSortKey, FieldSortKey, SortDirectionKey } from "./Sorting"
export const FIELD_COLOR_SCHEMES = [
  "red",
  "orange",
  "amber",
  "yellow",
  "green",
  "indigo",
  "darkBlue",
  "dark",
] as const

export type FieldColorScheme = (typeof FIELD_COLOR_SCHEMES)[number]

export const isValidFieldColorScheme = makeValidator(FIELD_COLOR_SCHEMES)

const fileLogger = makeFileLogger({ fileName: "async-storage.ts" })

export interface StoredValues {
  activeFarmId: number | null
  demoFarmId: number | null
  deviceAnalyticsDateMax: number
  deviceAnalyticsDateMin: number
  deviceSortDirection: SortDirectionKey
  deviceSortKey: DeviceSortKey
  deviceStatusFilterKey: "all" | "visible"
  farmAnalyticsDateMsMax: number
  farmAnalyticsDateMsMin: number
  fieldColorPreference: FieldColorScheme
  fieldIrrigationHistoryDateMax: number
  fieldIrrigationHistoryDateMin: number
  fieldRunHistoryAutozoomEnabled: boolean
  fieldSortDirection: SortDirectionKey
  fieldSortKey: FieldSortKey
  hideMissingFieldsDialog: boolean | undefined
  isAdminModeEnabled: boolean | undefined
  mapTypeId: MapTypeId
  measurementSystem: MeasurementPreference
  phoneNumberPromptStatus: "skipped" | null
  runHeatmapContrast: "default" | "high"
  showReelRunOutline: boolean
}
export const STORAGE_DEFAULTS: StoredValues = {
  activeFarmId: null,
  demoFarmId: null,
  deviceAnalyticsDateMax: Date.now(),
  deviceAnalyticsDateMin: subDays(new Date(), 1).getTime(),
  deviceSortDirection: "desc",
  deviceSortKey: "device_activity",
  deviceStatusFilterKey: "all",
  farmAnalyticsDateMsMax: Date.now(),
  farmAnalyticsDateMsMin: subDays(new Date(), 30).getTime(),
  fieldColorPreference: "yellow",
  fieldIrrigationHistoryDateMax: Date.now(),
  fieldIrrigationHistoryDateMin: subYears(new Date(), 1).getTime(),
  fieldRunHistoryAutozoomEnabled: true,
  fieldSortDirection: "asc",
  fieldSortKey: "alphabetical",
  hideMissingFieldsDialog: true,
  isAdminModeEnabled: undefined,
  mapTypeId: "SATELLITE",
  measurementSystem: "us",
  phoneNumberPromptStatus: null,
  runHeatmapContrast: "high",
  showReelRunOutline: true,
}
export type AsyncStorageKey = keyof StoredValues

function makeStorageKey<K extends AsyncStorageKey>(key: K): `@${K}` {
  return `@${key}`
}

/**
 * Prepare value for storage in AsyncStorage
 */
export function encodeAsyncStorageValue<K extends AsyncStorageKey>(params: {
  key: K
  value: StoredValues[K] | null
}): string | null {
  let encoded: string | null = null
  if (params.value === null) {
    encoded = null
  } else {
    switch (params.key) {
      case "deviceStatusFilterKey":
      case "deviceSortDirection":
      case "deviceSortKey":
      case "fieldColorPreference":
      case "fieldSortDirection":
      case "fieldSortKey":
      case "mapTypeId":
      case "phoneNumberPromptStatus":
      case "measurementSystem": {
        if (typeof params.value === "string") {
          encoded = params.value
        }
        break
      }
      // numbers
      case "fieldIrrigationHistoryDateMax":
      case "fieldIrrigationHistoryDateMin":
      case "deviceAnalyticsDateMax":
      case "deviceAnalyticsDateMin":
      case "farmAnalyticsDateMsMin":
      case "farmAnalyticsDateMsMax":
      case "runHeatmapContrast":
      case "demoFarmId":
      case "activeFarmId": {
        if (typeof params.value === "number") {
          encoded = `${params.value}`
        }
        // else if (params.value === null) {
        //   encoded = null
        // }
        break
      }
      // booleans
      case "hideMissingFieldsDialog":
      case "isAdminModeEnabled":
      case "fieldRunHistoryAutozoomEnabled":
      case "showReelRunOutline": {
        if (params.value === true) {
          encoded = "true"
        } else if (params.value === false) {
          encoded = "false"
        }
        break
      }
    }
  }
  return encoded
}

async function getAllValues() {
  const result = { ...STORAGE_DEFAULTS }

  try {
    const keys = Object.keys(STORAGE_DEFAULTS) as Array<
      ObjectKeys<StoredValues>
    >
    const keyValuePairs = await AsyncStorage.multiGet(
      keys.map((key) => makeStorageKey(key)),
    )

    for (const kv of keyValuePairs) {
      const key = kv[0].slice(1)
      const value = kv[1]

      switch (key) {
        case "phoneNumberPromptStatus": {
          result[key] = value === "skipped" ? value : null
          break
        }
        case "deviceStatusFilterKey": {
          result[key] =
            value === "all" || value === "visible"
              ? value
              : STORAGE_DEFAULTS.deviceStatusFilterKey
          break
        }
        case "fieldSortDirection":
        case "deviceSortDirection": {
          if (
            value === SortDirections.ASCENDING ||
            value === SortDirections.DESCENDING
          ) {
            result[key] = value
          }
          break
        }
        case "deviceSortKey": {
          if (isValidDeviceSortKey(value)) {
            result[key] = value
          }
          break
        }
        case "fieldSortKey": {
          if (isValidFieldSortKey(value)) {
            result[key] = value
          }
          break
        }
        case "fieldColorPreference": {
          if (isValidFieldColorScheme(value)) {
            result[key] = value
          }
          break
        }
        case "mapTypeId": {
          if (isValidMapTypeId(value)) {
            result[key] = value
          }
          break
        }
        case "measurementSystem": {
          if (isValidMeasurementPreference(value)) {
            result[key] = value
          }
          break
        }
        case "runHeatmapContrast": {
          if (value === "high") {
            result[key] = value
          }
          break
        }
        // numbers
        case "farmAnalyticsDateMsMax":
        case "farmAnalyticsDateMsMin":
        case "fieldIrrigationHistoryDateMax":
        case "fieldIrrigationHistoryDateMin":
        case "deviceAnalyticsDateMin":
        case "deviceAnalyticsDateMax":
        case "demoFarmId":
        case "activeFarmId": {
          try {
            if (value !== null) {
              const asNumber = Number.parseInt(value)
              if (isValidNumber(asNumber)) {
                result[key] = asNumber
              }
            }
          } catch (error) {
            fileLogger.error(error)
          }
          break
        }
        // booleans
        case "isAdminModeEnabled":
        case "hideMissingFieldsDialog":
        case "fieldRunHistoryAutozoomEnabled":
        case "showReelRunOutline": {
          if (value === "true") {
            result[key] = true
          }
          break
        }
      }
    }

    return result
  } catch (error) {
    fileLogger.error("getAllValues", error)
    return { ...STORAGE_DEFAULTS }
  }
}

export const readLocalStorageAsync = createAsyncThunk(
  "ReadLocalStorage",
  async () => {
    try {
      return await getAllValues()
    } catch (error) {
      fileLogger.error(error)
      throw error
    }
  },
)

function makeValueSetter<K extends AsyncStorageKey, V extends StoredValues[K]>(
  key: K,
) {
  // const dispatch = useDispatch<RootDispatch>()
  return createAsyncThunk<void, V, RootThunkConfig>(
    `asyncStorage/set${key}`,
    async (value: V) => {
      try {
        const storageKey = makeStorageKey(key)
        const encodedValue = encodeAsyncStorageValue({ key, value })
        if (typeof encodedValue === "string") {
          await AsyncStorage.setItem(storageKey, encodedValue)
        } else if ((encodedValue as unknown) === null) {
          await AsyncStorage.removeItem(storageKey)
        } else {
          throw new TypeError(
            `Failed to encode value ${JSON.stringify(
              encodedValue,
            )} for key ${key}`,
          )
        }
      } catch (error) {
        fileLogger.error(error)
        throw error
      }
    },
  )
}

// export const setActiveFarmId = makeValueSetter("activeFarmId")
export const setActiveFarmId = createAsyncThunk(
  "setActiveFarm",
  async (id: number) => {
    try {
      const key = makeStorageKey("activeFarmId")
      const encoded = encodeAsyncStorageValue({
        key: "activeFarmId",
        value: id,
      })
      if (typeof encoded !== "string") {
        throw new TypeError(`Failed to encode active farm id`)
      }
      await Promise.all([
        AsyncStorage.setItem(key, encoded),
        AsyncStorage.removeItem(makeStorageKey("demoFarmId")),
      ]).catch(captureException)
    } catch (error) {
      fileLogger.error(error)
      throw error
    }
  },
)

export const exitDemoMode = createAsyncThunk("ExitDemoMode", async () => {
  try {
    await AsyncStorage.removeItem(makeStorageKey("demoFarmId")).catch(
      captureException,
    )
  } catch (error) {
    logger.error(error)
    throw error
  }
})

export const setAdminMode = makeValueSetter("isAdminModeEnabled")
export const setMeasurementPreference = makeValueSetter("measurementSystem")
export const setFieldColorPreference = makeValueSetter("fieldColorPreference")
export const setShowRunOutlines = makeValueSetter("showReelRunOutline")
export const setDeviceSortKey = makeValueSetter("deviceSortKey")
export const setDeviceSortDirection = makeValueSetter("deviceSortDirection")
export const setFieldSortKey = makeValueSetter("fieldSortKey")
export const setFieldSortDirection = makeValueSetter("fieldSortDirection")
export const setMapTypeId = makeValueSetter("mapTypeId")
export const setDeviceAnalyticsDateMin = makeValueSetter(
  "deviceAnalyticsDateMin",
)
export const setDeviceAnalyticsDateMax = makeValueSetter(
  "deviceAnalyticsDateMax",
)
export const setIrrigationHistoryDateMin = makeValueSetter(
  "fieldIrrigationHistoryDateMin",
)
export const setIrrigationHistoryDateMax = makeValueSetter(
  "fieldIrrigationHistoryDateMax",
)
export const setRunHeatmapContrast = makeValueSetter("runHeatmapContrast")
export const setFieldProfileAutoZoom = makeValueSetter(
  "fieldRunHistoryAutozoomEnabled",
)
export const setHideUnknownFieldsWarning = makeValueSetter(
  "hideMissingFieldsDialog",
)
export const setFarmAnalyticsDateMsMin = makeValueSetter(
  "farmAnalyticsDateMsMin",
)
export const setFarmAnalyticsDateMsMax = makeValueSetter(
  "farmAnalyticsDateMsMax",
)
export const setDeviceStatusFilterKey = makeValueSetter("deviceStatusFilterKey")
export const setPhoneNumberPromptStatus = makeValueSetter(
  "phoneNumberPromptStatus",
)
export async function removeItem(key: AsyncStorageKey) {
  logger.debug(`Removing stored value for ${key}`)
  return AsyncStorage.removeItem(makeStorageKey(key))
}
export async function clearStoredFarmIds() {
  try {
    const keysToClear: AsyncStorageKey[] = ["demoFarmId", "activeFarmId"]
    await AsyncStorage.multiRemove(keysToClear.map(makeStorageKey))
  } catch (error) {
    fileLogger.error(error)
    throw error
  }
}

export async function setItem<K extends AsyncStorageKey>({
  key,
  value,
}: {
  key: K
  value: StoredValues[K]
}) {
  const encoded = encodeAsyncStorageValue({
    key,
    value,
  })
  if (encoded === null) {
    throw new TypeError(
      `Failed to encode value for ${key} ${JSON.stringify(value)}`,
    )
  }
  return AsyncStorage.setItem(makeStorageKey(key), encoded)
}
