import React from "react"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import {
  AlertDialogBodyText,
  AppIcons,
  Box,
  Button,
  Card,
  Divider,
  HelpDialog,
  IconButton,
  View,
} from "./components"
import { Row } from "./components/Row"
import { AppText } from "./components/Text"
import { useClipboard } from "./components/useClipboard"
import { createFarmJoinCodeAsync } from "./farmhq-api"
import { FarmUserPermissionsSectionList } from "./FarmUserPermissionsSectionList"
import {
  DEVICE_PERMISSIONS,
  FARM_PERMISSIONS,
  FARM_USER_PERMISSIONS,
} from "./permissions"
import { PermissionsForm } from "./PermissionsForm"
import { SuccessHandler } from "./SuccessHandler"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"

import type { IconButtonProps ,
  HelpContentStatic } from "./components"
import type { FarmJoinCode } from "./models"
import type { FarmUserPermissions } from "./permissions"
export const HELP_CONTENT: HelpContentStatic = {
  bodyText: i18n.t("farmUserPermissions:newFarmJoinCodeHelpSummary"),
  subject: "create_farm_join_code",
  titleText: i18n.t("farmUserPermissions:createFarmJoinCodeHelpTitle"),
}
export function FarmJoinCodeCopyButton({
  codeStatus,
  codeStr,
  ...rest
}: Omit<IconButtonProps, "IconComponent" | "onPress"> &
  Pick<FarmJoinCode, "codeStatus" | "codeStr">): React.JSX.Element | null {
  const { onCopy } = useClipboard()

  if (codeStatus === "EXPIRED") {
    return null
  }
  return (
    <IconButton
      IconComponent={AppIcons.Clipboard}
      id="farm-join-code-copy-btn"
      {...rest}
      onPress={() => onCopy(codeStr)}
    />
  )
}

function CreateFarmJoinCodeSuccess({
  newCode,
  onClose,
}: {
  newCode: FarmJoinCode
  onClose: () => void
}) {
  const { t } = useTranslation("farmUserPermissions")
  const [showHelp, setShowHelp] = React.useState(false)

  const fontSize = "$lg"
  const spacing = "$4"
  return (
    <React.Fragment>
      <HelpDialog
        isOpen={showHelp}
        titleElement={t("farmJoinCode")}
        onClose={() => setShowHelp(false)}
      >
        <AlertDialogBodyText>
          {t("farmJoinCodeHelpSummary")}
        </AlertDialogBodyText>
      </HelpDialog>
      <Card mx={spacing}>
        <SuccessHandler
          message={t("newFarmJoinCodeSuccessMessage")}
          actionElement={
            <IconButton
              IconComponent="Info"
              variant="text"
              onPress={() => setShowHelp(true)}
            />
          }
        >
          <Row justifyContent="space-between" my={spacing}>
            <AppText colorScheme="secondary" fontSize={fontSize}>
              {t("codeWithColon")}
            </AppText>
            <Row>
              <Box mr={spacing}>
                <AppText fontSize={fontSize} fontWeight="bold">
                  {newCode.codeStr}
                </AppText>
              </Box>
              <IconButton IconComponent="Clipboard" />
            </Row>
          </Row>
          <Row justifyContent="space-between" mb={spacing}>
            <AppText colorScheme="secondary" fontSize={fontSize}>
              {t("codeExpiresWithColon")}
            </AppText>
            <AppText fontSize={fontSize}>
              {t("datetimeWithVal", {
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                month: "numeric",
                ns: "common",
                val: new Date(newCode.expirationDate),
              })}
            </AppText>
          </Row>
          <Button
            id="done-btn"
            size="lg"
            text={t("done", { ns: "common" })}
            variant="primary"
            onPress={onClose}
          />
        </SuccessHandler>
      </Card>
    </React.Fragment>
  )
}
// const NEW_CODE: FarmJoinCode = {
//   "codeStatus": "ACTIVE",
//   "codeStr": "2B6770",
//   "createDate": "2024-07-28T02:14:34.058942+00:00",
//   "createdByUserId": "FUCK",
//   "expirationDate": "2024-07-31T02:14:34.058947+00:00",

//   "id": 4,
//   "permissionExpirationDate": "9999-12-31T00:00:00+00:00",
//   "permissions": {
//     "canControlDevicesRemotely": false,
//     "canManageCustomTriggers": false,
//     "canManageDeviceConfiguration": false,
//     "canManageDeviceNotifications": false,
//     "canManageDevicePermissions": false,
//     "canManageFarmAccount": false,
//     "canManageFields": false,
//     "canManageJoinCodes": false,
//     "canManageSchedules": false,
//     "canManageUserPermission": false,
//   },
// }

export function CreateFarmJoinCodeForm({ onClose }: { onClose: () => void }) {
  const stages = ["farm", "device", "review"] as const
  const [state, setState] = React.useState<{
    stageIndex: number
    values: FarmUserPermissions
    newCode?: FarmJoinCode
  }>({
    // newCode: NEW_CODE,
    stageIndex: 0,
    values: FARM_USER_PERMISSIONS.allFalse(),
  })
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    createFarmJoinCodeAsync,
  )
  const { t } = useTranslation("farmUserPermissions")
  const stage = stages[state.stageIndex] ?? stages[0]
  const handleSubmit = () => {
    sendRequest({
      permissions: state.values,
    })
      .then((newCode) => {
        return setState({ ...state, newCode })
      })
      .catch((error) => {
        handleError(error, { toastMessage: "default" })
      })
  }
  const handleCancel = () => {
    setState((previous) => ({
      ...previous,
      stageIndex: previous.stageIndex - 1,
    }))
  }

  return (
    <View id="create-farm-join-code" style={{ flex: 1 }}>
      {state.newCode ? (
        <CreateFarmJoinCodeSuccess newCode={state.newCode} onClose={onClose} />
      ) : stage === "farm" ? (
        <PermissionsForm
          isLoading={false}
          keys={FARM_PERMISSIONS.getKeys()}
          subheaderText={t("createFarmJoinCodeFarmInstructions")}
          submitText={t("next", { ns: "common" })}
          titleText={t("farmPermissionsTitle")}
          onCancel={onClose}
          onSubmit={(nextValues) => {
            setState((previous) => {
              return {
                stageIndex: previous.stageIndex + 1,
                values: { ...previous.values, ...nextValues },
              }
            })
          }}
        />
      ) : stage === "device" ? (
        <PermissionsForm
          cancelText={t("back", { ns: "common" })}
          isLoading={false}
          keys={DEVICE_PERMISSIONS.getKeys()}
          subheaderText={t("createFarmJoinCodeDeviceInstructions")}
          submitText={t("next", { ns: "common" })}
          titleText={t("devicePermissionsTitle")}
          onCancel={() => {
            setState((previous) => {
              return {
                ...previous,
                stageIndex: previous.stageIndex - 1,
              }
            })
          }}
          onSubmit={(nextValues) => {
            setState((previous) => {
              return {
                stageIndex: previous.stageIndex + 1,
                values: { ...previous.values, ...nextValues },
              }
            })
          }}
        />
      ) : (
        <FarmUserPermissionsSectionList
          values={state.values}
          ListFooterComponent={
            <ActionButtons
              buttonSize="lg"
              cancelText={t("back", { ns: "common" })}
              isLoading={isLoading}
              my="$2"
              orientation="horizontal"
              onPressCancel={handleCancel}
              onPressSubmit={handleSubmit}
            />
          }
          ListHeaderComponent={
            <Box mt="$2">
              <AppText fontWeight="bold">{t("reviewPermissionsTitle")}</AppText>
              <AppText colorScheme="secondary">
                {t("reviewPermissionsInstructions")}
              </AppText>
              <Divider my="$2" />
            </Box>
          }
        />
      )}
    </View>
  )
}
