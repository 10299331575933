import type { Reducer } from "@reduxjs/toolkit"
import { createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit"

import { signOutAsync } from "./auth.reducer"
import {
  loadNotificationsAsync,
  setNotificationReadStatusAsync,
} from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"

import type { ModelState } from "./models"
export type NotificationState = ModelState<Models.NotificationEntity>

const initialState: NotificationState = Models.getInitialEntityState()

const notifications = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(loadActiveFarmAsync.fulfilled, (state, { payload }) => {
        Models.appNotifications.adapter.setAll(state, payload.appNotifications)
      })
      .addCase(loadNotificationsAsync.fulfilled, (state, { payload }) => {
        Models.appNotifications.adapter.setAll(state, payload.items)
      })
      .addCase(setNotificationReadStatusAsync.pending, (state, { meta }) => {
        Models.appNotifications.adapter.updateMany(
          state,
          meta.arg.ids.map((id) => ({ changes: { readStatus: "seen" }, id })),
        )
      })
      .addMatcher(isAnyOf(signOutAsync.fulfilled), () => {
        return { ...initialState }
      }),

  initialState,
  name: `notifications`,
  reducers: {},
})
export const notificationReducer: Reducer<NotificationState> =
  notifications.reducer

export const getUnreadNotificationsFromState = createSelector(
  Models.appNotifications.selectAll,
  (values) => values.filter((value) => value.readStatus !== "seen"),
)
export const getNumUnreadNotificationsFromState = createSelector(
  getUnreadNotificationsFromState,
  (items) => items.length,
)
