import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"

import { humanize } from "@fhq/app"
import { AppText, Box, Row, useIsDarkMode } from "@fhq/app/components"
import { COLORS } from "@fhq/app/components/theme"

import type { RowProps } from "@fhq/app/components"
const LINK_STYLE: React.CSSProperties = {
  textDecoration: "none",
}
export interface BreadcrumbNavigationProps extends RowProps {
  renderPath?: (params: { index: number; value: string }) => string
}
const SLASH = "/"

export function BreadcrumbNavigation({
  renderPath,
  ...props
}: BreadcrumbNavigationProps): React.JSX.Element {
  const location = useLocation()
  const chunks = location.pathname.split("/")
  const { t } = useTranslation()
  const isDark = useIsDarkMode()
  return (
    <Row {...props}>
      {chunks.map((value, index): React.JSX.Element => {
        const text = renderPath
          ? renderPath({ index, value })
          : value === ""
          ? t("home")
          : humanize(value, { textFormat: "titleCase" })

        const path = index === 0 ? "/" : chunks.slice(0, index + 1).join("/")

        const isCurrent = index === chunks.length - 1

        const textColor = isDark ? COLORS.$link.dark : COLORS.$link.light
        if (isCurrent) {
          return (
            <AppText key={value} color={textColor}>
              {text}
            </AppText>
          )
        }

        return (
          <Row key={value}>
            <Link style={LINK_STYLE} to={path}>
              <AppText color={textColor}>{text}</AppText>
            </Link>
            <Box mx="$1">
              <AppText color={textColor}>{SLASH}</AppText>
            </Box>
          </Row>
        )
      })}
    </Row>
  )
}
