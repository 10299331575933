import type { Reducer } from "@reduxjs/toolkit"
import { createSlice, isAnyOf } from "@reduxjs/toolkit"

import { signOutAsync } from "./auth.reducer"
import {
  addDeviceAsync,
  removeDeviceFromFarmAsync,
  replaceDeviceAsync,
} from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"

import type { DeviceFarmAssociation, ModelState } from "./models"
const { adapter } = Models.deviceFarmAssociation

type DeviceFarmAssociationState = ModelState<DeviceFarmAssociation, string>

const initialState: DeviceFarmAssociationState = Models.getInitialEntityState()

/**
 *
 */
const deviceFarmAssociations = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(
        addDeviceAsync.fulfilled,
        /**
         * On response, either add the device or show an error code
         */
        (state, { payload }) => {
          if (payload.status === "VALID" && payload.deviceFarmAssociation) {
            adapter.upsertOne(state, payload.deviceFarmAssociation)
          }
        },
      )
      .addCase(replaceDeviceAsync.fulfilled, (state, action) => {
        if (action.payload.status === "VALID") {
          if (action.payload.deviceFarmAssociation) {
            adapter.upsertOne(state, action.payload.deviceFarmAssociation)
          }
          adapter.removeOne(state, action.meta.arg.deviceIdOld)
        }
      })
      .addCase(loadActiveFarmAsync.fulfilled, (state, { payload }) => {
        adapter.setAll(state, payload.deviceFarmAssociations)
      })
      .addCase(removeDeviceFromFarmAsync.fulfilled, (state, { meta }) => {
        adapter.removeOne(state, meta.arg.deviceId)
      })
      .addMatcher(isAnyOf(signOutAsync.fulfilled), () => ({ ...initialState })),
  initialState,
  name: `deviceFarmAssociations`,
  reducers: {},
})
export const deviceFarmAssociationsReducer: Reducer<DeviceFarmAssociationState> =
  deviceFarmAssociations.reducer
