/* eslint-disable import/no-named-as-default */
import { endOfDay, roundToNearestMinutes, startOfDay } from "date-fns"
import React from "react"
import ReactDatePicker from "react-datepicker"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import { useNavigate } from "react-router"

import { CreateSchedule } from "@fhq/app"
import {
  Box,
  FormControl,
  FormError,
  Row,
  ScrollView,
} from "@fhq/app/components"
import { SPACING } from "@fhq/app/components/theme"

import { Environment } from "./base/base"
import { Page } from "./base/Page"

import type { FormControlProviderProps } from "@fhq/app/components/form-control/base"

import type { BoxProps, RowProps } from "@fhq/app/components"

import type { ReactDatePickerProps } from "react-datepicker"
type PickerProps = Omit<ReactDatePickerProps, "onChange">

function useTexts() {
  return useTranslation("createSchedule")
}

/**
 *
 */
function StartDayEndDayPickers(props: RowProps) {
  const { form, isRepeating } = CreateSchedule.useContext()
  const { t } = useTexts()

  const dayPickerProps: PickerProps = {
    customInput: <FormControl.Input />,
    fixedHeight: true,
  }

  if (isRepeating) {
    return (
      <Row {...props}>
        <Box flexGrow={1} minW="$32" zIndex={2}>
          <FormControl.Label>{t("scheduleStartLabel")}</FormControl.Label>
          <Controller
            control={form.control}
            name="scheduleStartMs"
            render={({ field: { onChange, value } }) => {
              const handleChange = (date: Date | null) => {
                if (date) {
                  onChange(startOfDay(date).getTime())
                }
              }
              return (
                <ReactDatePicker
                  {...dayPickerProps}
                  selected={new Date(value)}
                  onChange={handleChange}
                />
              )
            }}
          />
        </Box>
        <Box flexGrow={1} minW="$32" zIndex={3}>
          <FormControl.Label>{t("scheduleEndLabel")}</FormControl.Label>
          <Controller
            control={form.control}
            name="scheduleEndMs"
            render={({ field: { onChange, value } }) => {
              return (
                <ReactDatePicker
                  {...dayPickerProps}
                  selected={new Date(value)}
                  onChange={(date) => {
                    if (date) {
                      onChange(endOfDay(date).getTime())
                    }
                  }}
                />
              )
            }}
          />
        </Box>
      </Row>
    )
  }
  return null
}

const pickerProps: PickerProps = {
  customInput: <FormControl.Input />,
  dateFormat: "Pp",
  // disabled: isLoading,
  fixedHeight: true,
  showTimeInput: true,
  showTimeSelect: true,
  showTimeSelectOnly: false,
  timeIntervals: 15,
}

/**
 *
 */
function ExecutionTimePicker(props: FormControlProviderProps) {
  const { t } = useTexts()

  const { errorCode, form, isLoading, isPumpOnForDurationAction } =
    CreateSchedule.useContext()

  let errorMessage: string | undefined
  let isInvalid = false
  if (errorCode === "EXECUTION_TIME_IN_PAST") {
    errorMessage = t("errorMessages.EXECUTION_TIME_IN_PAST")
    isInvalid = true
  }

  let labelText: string
  if (isPumpOnForDurationAction) {
    labelText = t("turnOnThePumpAtWithTimezone", {
      timezone: Environment.getTimezone(),
    })
  } else {
    labelText = t("executeAt")
  }
  return (
    <FormControl.Provider isInvalid={isInvalid} {...props}>
      <FormControl.Label>{labelText}</FormControl.Label>
      <Controller
        control={form.control}
        name="executionTimeMs"
        render={({ field: { onChange, value } }) => {
          return (
            <ReactDatePicker
              {...pickerProps}
              disabled={isLoading}
              selected={new Date(value)}
              onChange={(date) => {
                if (date) {
                  onChange(roundToNearestMinutes(date).getTime())
                }
              }}
            />
          )
        }}
      />

      <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
    </FormControl.Provider>
  )
}

/**
 *
 */
function ExecutionTimePickerRepeating(props: FormControlProviderProps) {
  const { errorCode, form, isLoading, isPumpOnForDurationAction } =
    CreateSchedule.useContext()
  const { t } = useTexts()

  let errorMessage: string | undefined
  let isInvalid = false
  if (errorCode === "EXECUTION_TIME_IN_PAST") {
    errorMessage = t("errorMessages.EXECUTION_TIME_IN_PAST")
    isInvalid = true
  }

  let labelText: string
  if (isPumpOnForDurationAction) {
    labelText = t("turnOnThePumpAtWithTimezone", {
      timezone: Environment.getTimezone(),
    })
  } else {
    labelText = t("executeAt")
  }
  return (
    <FormControl.Provider isInvalid={isInvalid} {...props}>
      <FormControl.Label>{labelText}</FormControl.Label>
      <Controller
        control={form.control}
        name="startTime"
        render={({ field: { onChange, value } }) => {
          const selected = new Date()
          selected.setHours(value.hours)
          selected.setMinutes(value.minutes)
          return (
            <ReactDatePicker
              {...pickerProps}
              showTimeSelectOnly
              dateFormat="h:mm aa"
              disabled={isLoading}
              selected={selected}
              onChange={(date) => {
                if (date) {
                  const hours = date.getHours()
                  const minutes = date.getMinutes()
                  onChange({ hours, minutes })
                }
              }}
            />
          )
        }}
      />

      <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
    </FormControl.Provider>
  )
}

const styles = StyleSheet.create({
  weekdays: {
    marginVertical: SPACING.$1,
    paddingVertical: SPACING.$1,
  },
})

/**
 *
 */
function Form(props: BoxProps): React.JSX.Element {
  const { t } = useTexts()
  const { formErrorMessage, isPumpOnForDurationAction, isRepeating, stage } =
    CreateSchedule.useContext()

  const timePickerProps: FormControlProviderProps = {
    flex: 1,
    minW: "$48",
    zIndex: 2,
  }

  return (
    <Box {...props}>
      {stage === "device" ? (
        <React.Fragment>
          <CreateSchedule.DeviceSelect
            onNoCompatibleDevices={() => {
              // eslint-disable-next-line no-alert
              alert(t("noCompatibleDevices"))
            }}
          />
          <CreateSchedule.ActionSelect />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CreateSchedule.CurrentSelections />
          <Row>
            <Box zIndex={3}>
              {isRepeating ? (
                <ExecutionTimePickerRepeating {...timePickerProps} />
              ) : (
                <ExecutionTimePicker {...timePickerProps} />
              )}
            </Box>
            <Box ml="auto">
              <CreateSchedule.FrequencyRadio
                ml="$4"
                my="$2"
                orientation="vertical"
              />
            </Box>
          </Row>
          {/* Time Pickers */}
          {isPumpOnForDurationAction ? (
            <Box my="$2">
              <CreateSchedule.DurationFields />
            </Box>
          ) : null}
          {/* Frequency */}
          {isRepeating ? (
            <CreateSchedule.WeekdaysCheckbox style={styles.weekdays} />
          ) : null}
          <StartDayEndDayPickers zIndex={2} />
        </React.Fragment>
      )}
      <FormError>{formErrorMessage}</FormError>
      <CreateSchedule.Actions />
      <CreateSchedule.UsageWarning />
    </Box>
  )
}

/**
 *
 */
function CreateDeviceActionSchedulePage(): React.JSX.Element | null {
  const navigate = useNavigate()
  const { t } = useTexts()

  const titleText: string = t("quickSchedulerTitle")
  const handleSuccess = () => navigate("..")

  return (
    <CreateSchedule.Provider
      executionTimezone={Environment.getTimezone()}
      onCancel={handleSuccess}
      onSuccess={handleSuccess}
    >
      <ScrollView variant="pageScroll">
        <Page
          IconComponent="Clock"
          helpContent={CreateSchedule.HELP_CONTENT}
          id="createSchedule"
          maxW="$2xl"
          mx="auto"
          px="$4"
          requiredPermissions="canManageSchedules"
          titleText={titleText}
        >
          <Form />
        </Page>
      </ScrollView>
    </CreateSchedule.Provider>
  )
}

export default CreateDeviceActionSchedulePage
