import React from "react"
import { useTranslation } from "react-i18next"

import { Switch } from "./components"
import { useToasts } from "./components/useToasts"
import { toggleNotificationsFromDevicesAsync } from "./farmhq-api"
import * as Models from "./models"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { RootState } from "./root.reducer"
import type { SwitchProps } from "./components"
import type { TestId } from "./components/test-id"
import type { DeviceIdProps } from "./types"
export interface ToggleSwitchProps {
  onDenied: () => void
}

function getNotificationStatusFromState(state: RootState, deviceId: string) {
  const verifiedPhoneNumbers = Models.phoneNumber
    .selectAll(state)
    .filter((pn) => pn.isVerified)

  if (verifiedPhoneNumbers.length === 0) {
    return "no phone numbers"
  }
  const isSuppressed =
    Models.deviceUserAssociation.selectById(state, deviceId)
      ?.notificationsSuppressed === true

  if (isSuppressed) {
    return "suppressed"
  }
  return "enabled"
}

/**
 * Allow user to mute/unmute notifications for the specific device
 */

export function DeviceNotificationsToggleSwitch({
  deviceId,
  onDenied,
  ...props
}: DeviceIdProps & SwitchProps & ToggleSwitchProps): React.JSX.Element {
  const { t } = useTranslation("deviceNotifications")
  const toast = useToasts()

  const notificationsStatus = useRootSelector((state) =>
    getNotificationStatusFromState(state, deviceId),
  )

  const { handleError, isLoading, sendRequest } = useBackendRequest(
    toggleNotificationsFromDevicesAsync,
  )

  const isChecked = notificationsStatus === "enabled"

  let id: TestId = "device-notifications-toggle-disabled"
  let action: "ENABLE" | "SUPPRESS" = "ENABLE"
  let successMessage = t("notificationsEnabled")

  if (notificationsStatus === "enabled") {
    action = "SUPPRESS"
    id = "device-notifications-toggle-enabled"
    successMessage = t("notificationsDisabled")
  }

  const handleChange = () => {
    if (notificationsStatus === "no phone numbers") {
      onDenied()
    } else {
      sendRequest({ action, ids: [deviceId] })
        .then(() => toast.success(successMessage))
        .catch((error) => handleError(error, { toastMessage: "default" }))
    }
  }
  return (
    <Switch
      {...props}
      accessibilityLabel="notifications-toggle-switch"
      id={id}
      isDisabled={isLoading}
      value={isChecked}
      onValueChange={handleChange}
    />
  )
}
