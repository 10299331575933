import type { ValuesType } from "utility-types"

import type { PayloadAction, Reducer } from "@reduxjs/toolkit"
import { createSlice, isAnyOf } from "@reduxjs/toolkit"

import {
  readLocalStorageAsync,
  setFarmAnalyticsDateMsMax,
  setFarmAnalyticsDateMsMin,
  STORAGE_DEFAULTS,
} from "./async-storage"
import { signOutAsync } from "./auth.reducer"
import {
  createFarmJoinCodeAsync,
  loadFlowMeteringAnalyticsAsync,
  loadFlowMeteringReportAsync,
} from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import { makeValidator } from "./type-guards"

import type { MinDateMaxDate } from "./types"
import type { ChartDatum } from "./GraphComponents"
import type { FarmJoinCode } from "./models"
import type { RootState } from "./root.reducer"
export interface FarmAnalyticsData {
  reportBytes: string | null
}

export interface FlowAnalyticsData {
  deviceTotals: Array<{
    dailyCumsumAcreFeet: ChartDatum[]
    dailyPeakGpm: ChartDatum[]
    dailyVolumeTotalAcreFeet: ChartDatum[]
    deviceName: string | null
    devicePeakGpm: number
    deviceVolumeTotalAcreFeet: number
  }>
  farmTotals: {
    dailyCumsumAcreFeet: ChartDatum[]
    dailyVolumeTotalAcreFeet: ChartDatum[]
    farmTotalAcreFeet: number
  }
}
export const FlowAnalyticsSources = {
  ESTIMATED: "estimated",
  METERED: "metered",
} as const
export const isValidFlowAnalyticsSource = makeValidator(
  Object.values(FlowAnalyticsSources),
)
export type FlowAnalyticsSource = ValuesType<typeof FlowAnalyticsSources>

interface FarmState {
  farmAnalytics: FarmAnalyticsData &
    MinDateMaxDate & {
      flowAnalytics: FlowAnalyticsData | null
      source: FlowAnalyticsSource
    }
  // users: ModelState<OtherFarmUser, string>
  joinCode?: FarmJoinCode | null
  newCodeCreated?: boolean
}

const initialState: FarmState = {
  // users: Models.getInitialEntityState(),
  farmAnalytics: {
    dateMsMax: STORAGE_DEFAULTS.farmAnalyticsDateMsMax,
    dateMsMin: STORAGE_DEFAULTS.farmAnalyticsDateMsMin,
    flowAnalytics: null,
    reportBytes: null,
    source: FlowAnalyticsSources.METERED,
  },
}

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(readLocalStorageAsync.fulfilled, (state, { payload }) => {
        // MIN DATE
        state.farmAnalytics.dateMsMin = payload.farmAnalyticsDateMsMin
        // MAX DATE
        state.farmAnalytics.dateMsMax = payload.farmAnalyticsDateMsMax
      })
      .addCase(loadActiveFarmAsync.pending, (state) => {
        // usersAdapter.removeAll(state.users)
        state.joinCode = undefined
        state.farmAnalytics.reportBytes = null
      })
      .addCase(loadActiveFarmAsync.fulfilled, (state, { payload }) => {
        state.joinCode = payload.farmJoinCode
      })
      .addCase(createFarmJoinCodeAsync.fulfilled, (state, { payload }) => {
        state.newCodeCreated = true
        state.joinCode = payload
      })
      // MIN DATE
      .addCase(setFarmAnalyticsDateMsMin.pending, (state, { meta }) => {
        state.farmAnalytics.dateMsMin = meta.arg
      })
      // MAX DATE
      .addCase(setFarmAnalyticsDateMsMax.pending, (state, { meta }) => {
        state.farmAnalytics.dateMsMax = meta.arg
      })
      .addCase(loadFlowMeteringReportAsync.fulfilled, (state) => {
        state.farmAnalytics.reportBytes = null
      })

      .addCase(
        loadFlowMeteringAnalyticsAsync.fulfilled,
        (state, { payload }) => {
          state.farmAnalytics.flowAnalytics = payload
        },
      )
      .addMatcher(isAnyOf(signOutAsync.fulfilled), () => {
        return { ...initialState }
      })
      .addDefaultCase((state) => {
        state.newCodeCreated = false
      }),
  initialState,
  name: "farm",
  reducers: {
    setFlowAnalyticsSource: (
      state,
      action: PayloadAction<FarmState["farmAnalytics"]["source"]>,
    ) => {
      state.farmAnalytics.source = action.payload
    },
  },
})

export const farmReducer: Reducer<FarmState> = slice.reducer
export const { setFlowAnalyticsSource } = slice.actions
export function getFlowAnalyticsSourceFromState(state: RootState) {
  return state.farm.farmAnalytics.source
}
