import React from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import {
  AppIcons,
  AppText,
  Box,
  FormControl,
  TitleWithIcon,
} from "./components"
import { Row } from "./components/Row"
import { setReelDistanceAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import { DeviceReturnCode } from "./models"
import { useContext } from "./SelectedRunContext"
import { useConvertSensorValue } from "./sensor-conversions"
import { useSensorUnitLabel } from "./sensor-formatting"
import { isValidNumber } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"

import type { BoxProps } from "./components"
import type { FormControlProviderProps } from "./components/form-control/base"
import type { InputProps } from "./components/form-control/Input"
const FIELD_NAME = "runDistanceMmCurrent" as const

function handleInput(nextValue: string) {
  const asNumber = Number.parseInt(nextValue)
  if (isValidNumber(asNumber)) {
    return asNumber
  }
  return nextValue
}

const inputProps: InputProps = {
  keyboardType: "number-pad",
  returnKeyType: "done",
}

/**
 * Allows user to manually override the direction of the reel run
 * !NOTE!: This uses reel run context
 */

export function SetSprinklerDistanceForm({
  onClosed,
  onSuccess,
  ...rest
}: BoxProps & {
  onClosed: (arg?: unknown) => void
  onSuccess?: () => void
}): React.JSX.Element {
  const { t } = useTranslation("statusMap")

  const { integerPattern, required } = useFormValidation()
  const { handleError, isLoading, sendRequest, toasts } =
    useBackendRequest(setReelDistanceAsync)

  const convertValue = useConvertSensorValue(FIELD_NAME)
  const unitLabel = useSensorUnitLabel()(FIELD_NAME)

  const { form, runData } = useContext()

  const deviceId = runData?.deviceId

  // While the form holds the value, it is in the user's preferred system
  const onSubmit = form.handleSubmit(({ distanceCurrent, distanceMax }) => {
    // Convert back to db value
    const distanceMmCurrent = convertValue(distanceCurrent, "database")
    const distanceMmMax = convertValue(distanceMax, "database")

    if (typeof deviceId === "undefined" || deviceId === null) {
      throw new TypeError(`Device ID is undefined`)
    }

    sendRequest({
      deviceId,
      distanceMmCurrent,
      distanceMmMax,
    })
      .then((response) => {
        if (response.deviceReturnCode === DeviceReturnCode.SUCCESS) {
          toasts.success(t("setReelDistance.successMessage"))
          if (onSuccess) {
            onSuccess()
          }
          return onClosed({
            distanceMax,
            distanceMmCurrent,
          })
        }
        // Tell user to try again
        return toasts.error(t("setReelDistance.errorMessage"))
      })
      .catch((error) =>
        handleError(error, {
          toastMessage: "default",
        }),
      )
  })

  /**
   * Space horizontally and disable on submit
   */
  const formControlProps: FormControlProviderProps = {
    // flex: 1,
    isDisabled: isLoading,
  }

  /**
   * MIN DISTANCE
   */
  const renderDistanceInputCurrent = () => {
    const minValue = 0
    const labelText = t(
      "setReelDistance.distanceMmCurrent.labelWithUnitLabel",
      { unitLabel },
    )
    return (
      <Controller
        control={form.control}
        name="distanceCurrent"
        render={({ field: { onChange, value, ...field }, fieldState }) => {
          const errorMessage = fieldState.error?.message
          return (
            <FormControl.Provider
              {...formControlProps}
              isInvalid={Boolean(errorMessage)}
            >
              <FormControl.Label>{labelText}</FormControl.Label>
              <FormControl.Input
                {...field}
                {...inputProps}
                value={`${value}`}
                onChangeText={(nextValue) => onChange(handleInput(nextValue))}
              />
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
        rules={{
          min: {
            message: t("minValueWithVal", {
              ns: "validation",
              val: minValue,
            }),
            value: minValue,
          },
          pattern: integerPattern,
          required,
        }}
      />
    )
  }

  /**
   * MAX DISTANCE
   */
  const renderDistanceInputMax = () => {
    const labelText = t("setReelDistance.distanceMmMax.labelWithUnitLabel", {
      unitLabel,
    })
    // Max distance MUST be greater than 0
    const minValue = 0
    return (
      <Controller
        control={form.control}
        name="distanceMax"
        render={({ field: { onChange, value, ...field }, fieldState }) => {
          const errorMessage = fieldState.error?.message
          return (
            <FormControl.Provider
              {...formControlProps}
              isInvalid={Boolean(errorMessage)}
            >
              <FormControl.Label>{labelText}</FormControl.Label>
              <FormControl.Input
                {...field}
                {...inputProps}
                value={`${value}`}
                onChangeText={(nextValue) => onChange(handleInput(nextValue))}
              />
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
        rules={{
          min: {
            message: t("minValueWithVal", {
              ns: "validation",
              val: minValue,
            }),
            value: minValue,
          },
          pattern: integerPattern,
          required,
        }}
      />
    )
  }

  const titleText = t("setReelDistance.title")
  return (
    <Box {...rest}>
      <TitleWithIcon IconComponent="ReelRun" titleText={titleText} />
      <AppText>{t("setReelDistance.instructions")}</AppText>
      <Row mt="$2">
        <Box mr="$2">
          <AppIcons.Warning />
        </Box>
        <AppText colorScheme="secondary">
          {t("setReelDistance.warning")}
        </AppText>
      </Row>
      <Row mt="$2">
        <Box flexGrow={1} mr="$2">
          {renderDistanceInputMax()}
        </Box>
        <Box flexGrow={1}>{renderDistanceInputCurrent()}</Box>
      </Row>
      <ActionButtons
        isDisabled={isLoading}
        isLoading={isLoading}
        mt="$4"
        onPressCancel={onClosed}
        onPressSubmit={onSubmit}
      />
    </Box>
  )
}
