import { t } from "i18next"

import i18n from "./translations/i18n"
import { makeValidator } from "./type-guards"

import type { ValuesType } from "utility-types"
export const SortDirections = {
  ASCENDING: "asc",
  DESCENDING: "desc",
} as const
export type SortDirectionKey = ValuesType<typeof SortDirections>
export const DeviceSortKeys = {
  alphabetical: {
    IconComponent: "SortAlphabetical",
    text: i18n.t("deviceSortKeys.alphabetical", { ns: "devices" }),
  },
  device_activity: {
    IconComponent: "Timeline",
    text: t("deviceSortKeys.device_activity", { ns: "devices" }),
  },
  device_installation_type: {
    IconComponent: "DeviceConfiguration",
    text: t("deviceSortKeys.device_installation_type", { ns: "devices" }),
  },
} as const

export type DeviceSortKey = keyof typeof DeviceSortKeys
export function getDeviceSortKeys(): DeviceSortKey[] {
  return Object.keys(DeviceSortKeys) as DeviceSortKey[]
}
export const isValidDeviceSortKey = makeValidator(getDeviceSortKeys())

export const FieldSortKeys = {
  alphabetical: {
    IconComponent: "SortAlphabetical",
    text: i18n.t("fieldsRosterSortKeys.alphabetical", { ns: "fields" }),
  },
  field_status: {
    IconComponent: "Timeline",
    text: t("fieldsRosterSortKeys.fieldStatus", { ns: "fields" }),
  },
} as const
export type FieldSortKey = keyof typeof FieldSortKeys
export function getFieldSortKeys(): FieldSortKey[] {
  return Object.keys(FieldSortKeys) as FieldSortKey[]
}
export const isValidFieldSortKey = makeValidator(getFieldSortKeys())
