import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { Box } from "./Box"
import { COLORS } from "./theme/colors"
import { useIsDarkMode } from "./ThemeProvider"

import type { BoxProps } from "./Box"

const styles = StyleSheet.create({
  dark: {
    backgroundColor: COLORS.$background.dark,
  },
  light: {
    backgroundColor: COLORS.$background.light,
  },
  root: {
    flex: 1,
  },
})

export function Background({ style, ...rest }: BoxProps) {
  const isDark = useIsDarkMode()
  return (
    <Box
      style={useStyle(() => {
        return [styles.root, isDark ? styles.dark : styles.light, style]
      }, [isDark, style])}
      {...rest}
    />
  )
}
