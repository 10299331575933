import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"

import * as turf from "@turf/turf"

import * as Geo from "./geo"
import { logger } from "./logger"
import { getUserMeasurementPreferenceFromState } from "./selectors"
import {
  convertSensorValue,
  getSensorConversionSpec,
  requiresConversion,
} from "./sensor-conversions"
import i18n from "./translations/i18n"
import { isTruthyString, isValidNumber } from "./type-guards"
import { useRootSelector } from "./useRootSelector"

import type { SnakeCase } from "type-fest"

import type {
  InstalledOrientation,
  SensorName,
  SprinklerType,
  SwitchType,
} from "./sensor-configurations"
import type { MeasurementPreferenceProps } from "./sensor-conversions"
import type { AnySensorState, SensorState } from "./sensor-events"
import type { AnySensorKey } from "./sensors"
import type { LabelLength, SensorDatetimeOptions } from "./string-formatting"
/**
 * Format sensor value for user display purposes. Converts value if necessary
 * TODO: More unit tests here
 */

export function formatSensorValue({
  dtFormat = "P pp",
  fieldName,
  measurementPreference,
  rawValue,
}: MeasurementPreferenceProps &
  SensorDatetimeOptions & {
    fieldName: AnySensorKey
    rawValue: unknown
  }): string | undefined {
  const conversionUnits = getSensorConversionSpec({
    fieldName,
    measurementPreference,
    target: `user`,
  })

  if (rawValue === null || typeof rawValue === `undefined`) {
    return i18n.t("none", { ns: "common" })
  }

  // Gps Location
  if (
    typeof rawValue === `object` &&
    "coordinates" in rawValue &&
    Array.isArray(rawValue.coordinates)
  ) {
    const coordinates = rawValue.coordinates
    const [lng, lat] = coordinates.map((coord) => {
      if (isValidNumber(coord)) {
        return turf.round(coord, 2)
      }
      return null
    })
    if (typeof lng === `number` && typeof lat === `number`) {
      return `longitude=${lng}, latitude=${lat}`
    }
    return i18n.t("none")
  }
  // Booleans
  if (rawValue === true) {
    return i18n.t("true")
  }
  if (rawValue === false) {
    return i18n.t("false")
  }

  // Value should be string or number here
  const valueIsString = typeof rawValue === `string`
  const valueIsNumber = typeof rawValue === `number`
  if (!valueIsNumber && !valueIsString) {
    return i18n.t("notApplicable")
  }
  switch (fieldName) {
    case "heading": {
      if (valueIsNumber || valueIsString) {
        return i18n.t("heading.unitLabel", { ns: "sensorFields" })
      }
      return undefined
    }
    case "voltageChangeMvPerHour": {
      if (!valueIsNumber) {
        return i18n.t("none")
      }
      if (rawValue < 50 && rawValue > -50) {
        return i18n.t("notCharging")
      }
      if (rawValue < -50) {
        return i18n.t(`discharging`)
      }
      return i18n.t(`charging`)
    }
    case "reportingPeriodEndDt":
    case "reportingPeriodStartDt":
    case "statePreviousInitDt":
    case "stateCurrentInitDt": {
      try {
        return format(new Date(rawValue), dtFormat)
      } catch {
        logger.error(`Failed to format ${rawValue} with template ${dtFormat}`)

        if (valueIsString) {
          return rawValue
        }
        return `${rawValue}`
      }
    }
    case "switchType": {
      const values = i18n.t("switchType.values", { ns: "sensorFields" })
      if (valueIsString) {
        return values[rawValue as SwitchType]
      }
      return undefined
    }
    case "sprinklerType": {
      const values = i18n.t("sprinklerType.values", { ns: "sensorFields" })
      if (valueIsString) {
        return values[rawValue as SprinklerType]
      }
      return undefined
    }
    case "stateCurrent":
    case "statePrevious": {
      try {
        return i18n.t(`sensorStates:${rawValue as AnySensorState}.short`, {
          ns: `devices`,
        })
      } catch (error) {
        logger.error(error)
        return isTruthyString(rawValue) ? rawValue : ``
      }
    }
    case "gpsType": {
      if (valueIsString) {
        return rawValue
      }
      return undefined
    }
    case "location": {
      if (
        typeof rawValue === `object` &&
        `type` in rawValue &&
        `coordinates` in rawValue
      ) {
        const [lng, lat] = Geo.point(rawValue)?.getCoords() ?? []
        if (isValidNumber(lng) && isValidNumber(lat)) {
          return `Longitude: ${lng.toFixed(2)};\nLatitude: ${lat.toFixed(2)}`
        }
      } else {
        return i18n.t("none")
      }
      return undefined
    }
    case "installedOrientation": {
      return rawValue as InstalledOrientation
    }
    case "nSats": {
      if (valueIsNumber) {
        if (rawValue < 3) {
          return i18n.t("poor")
        }
        if (rawValue < 6) {
          return i18n.t("moderate")
        }
        return i18n.t("good")
      }
      break
    }
    case "sensorPort": {
      try {
        if (valueIsNumber) {
          if (rawValue === 1) {
            return i18n.t("sensorPort.values.port-1", { ns: "sensorFields" })
          }
          if (rawValue === 2) {
            return i18n.t("sensorPort.values.port-2", { ns: "sensorFields" })
          }
        }
      } catch (error) {
        logger.error(error)
      }
      return undefined
    }
    case "altitude":
    case "applicationRateEstimatedMm":
    case "applicationRateReportsMm":
    case "calibrationIntercept":
    case "calibrationSlope":
    case "configurationId":
    case "currentPercentage":
    case "deviceFunctionCall":
    case "diameterMm":
    case "distanceObservedMm":
    case "flowRateEstimatedLpm":
    case "hoseDiameterMm":
    case "i2CAddress":
    case "internalSoc":
    case "ioPin":
    case "linearSpeedMmHMax":
    case "linearSpeedMmHMin":
    case "maxTrackingPercentage":
    case "milliRpmAvg":
    case "milliRpmFast":
    case "milliRpmSlow":
    case "minTrackingPercentage":
    case "mlPerPulse":
    case "nMagnets":
    case "nNozzles":
    case "nozzleDiameterMm":
    case "nWrapsOuterLayer":
    case "otaEnabled":
    case "outerHoseWrapRadiusMm":
    case "pidDerivativeCoeff":
    case "pidGainCoeff":
    case "pidIntegralCoeff":
    case "pidSensorSetpoint":
    case "pivotRadiusMeters":
    case "pressureObservedKpa":
    case "pressureReportsKpa":
    case "rampSecondsRemaining":
    case "rampSecondsTotal":
    case "rateLpmAvg":
    case "rateLpmMax":
    case "rateLpmMin":
    case "readingCelsius":
    case "readingKpa":
    case "runDistanceMmCurrent":
    case "runDistanceMmElapsed":
    case "runDistanceMmMax":
    case "runMagnetCountCurrent":
    case "runMagnetCountMax":
    case "runSpeedMmpm":
    case "scheduledDeviceAction":
    case "sensorPriority":
    case "sensorTriggeredUpdate":
    case "signalQuality":
    case "signalStrength":
    case "speedMillirpm":
    case "speedMmpm":
    case "speedObservedMmpm":
    case "speedReportsMmpm":
    case "startRampPercentage":
    case "swathWidthMm":
    case "targetRampPercentage":
    case "threshold":
    case "thresholdPsiLower":
    case "thresholdPsiUpper":
    case "voltageIntercept":
    case "voltageMv":
    case "voltageSlope":
    case "voltageThresholdLowMv":
    case "volumeLFinal":
    case "volumeLInitial":
    case "volumeLTotal":
    case "widthMm": {
      /*
       * DEFAULT FORMATTING
       */
      const sigDigits = conversionUnits?.sigDigits ?? 0

      // Coerce to number if possible
      let valueAsNumber: number | undefined
      if (valueIsString) {
        valueAsNumber = Number.parseFloat(rawValue)
      } else if (valueIsNumber) {
        valueAsNumber = rawValue
      }
      // Convert number with correct sig digits
      if (isValidNumber(valueAsNumber)) {
        if (requiresConversion(fieldName)) {
          valueAsNumber = convertSensorValue({
            fieldName,
            measurementPreference,
            rawValue: valueAsNumber,
            target: "user",
          })
        }
        // Format number as string
        if (sigDigits > 0) {
          return valueAsNumber.toFixed(sigDigits)
        }
        return `${Math.round(valueAsNumber)}`
      }
    }
  }
  return undefined
}
export function getSensorUnitLabel({
  fieldName,
  labelLength = "short",
  measurementPreference,
}: MeasurementPreferenceProps & {
  fieldName: AnySensorKey
  labelLength?: LabelLength
}): string {
  const label = i18n.t(`${fieldName}.unitLabel`, { ns: "sensorFields" })

  if (typeof label === `string`) {
    return label
  }
  if (label) {
    // Can be a string or have properties 'short' and 'long'
    const result = label[measurementPreference]
    if (typeof result === "string") {
      return result
    }
    return result[labelLength]
  }
  return ``
}

export function useSensorUnitLabel() {
  const measurementPreference = useRootSelector(
    getUserMeasurementPreferenceFromState,
  )
  return (
    key: AnySensorKey,
    options?: {
      labelLength?: LabelLength
    },
  ): string => {
    return getSensorUnitLabel({
      fieldName: key,
      labelLength: options?.labelLength,
      measurementPreference,
    })
  }
}

export function useFormatSensorValue() {
  const measurementPreference = useRootSelector(
    getUserMeasurementPreferenceFromState,
  )

  return React.useCallback(
    (fieldName: AnySensorKey, rawValue: unknown) => {
      return formatSensorValue({
        fieldName,
        measurementPreference,
        rawValue,
      })
    },
    [measurementPreference],
  )
}
export const formatSensorName = (
  sensorName: SensorName | SnakeCase<SensorName> | undefined,
): string => {
  if (sensorName) {
    switch (sensorName) {
      case "battery": {
        return i18n.t("sensors:battery")
      }
      case "flow": {
        return i18n.t("sensors:flow")
      }
      case "gps": {
        return i18n.t("sensors:gps")
      }
      case "hall_switch":
      case "hallSwitch": {
        return i18n.t("sensors:hallSwitch")
      }
      case "icm": {
        return i18n.t("sensors:icm")
      }

      case "pressure": {
        return i18n.t("sensors:pressure")
      }
      case "pressure_switch":
      case "pressureSwitch": {
        return i18n.t("sensors:pressureSwitch")
      }
      case "reel": {
        return i18n.t("sensors:reel")
      }
      case "relay": {
        return i18n.t("sensors:relay")
      }
      case "temperature": {
        return i18n.t("sensors:temperature")
      }
      case "vfd": {
        return i18n.t("sensors:vfd")
      }
      case "wheel": {
        return i18n.t("sensors:wheel")
      }
      case "device": {
        return i18n.t("sensors:device")
      }
    }
  }
  return ""
}
/**
 *
 */
export function useFormatSensorName() {
  return formatSensorName
}
export function useFormatSensorState() {
  const { t } = useTranslation("sensorStates")
  return (
    sensorState: SensorState,
    options?: { labelLength?: LabelLength },
  ) => {
    const labelLength = options?.labelLength ?? "short"
    return t(`${sensorState}.${labelLength}`)
  }
}
