import React from "react"

import { Box, CloseIconButton, Row, TitleWithIcon } from "@fhq/app/components"

import { Help } from "./Help"

import type { HelpContentStatic, TitleWithIconProps } from "@fhq/app/components"
export interface PageHeaderProps extends TitleWithIconProps {
  helpContent?: HelpContentStatic | undefined
  onClose?: () => void
}

/**
 * PageHeader
 */
export function PageHeader({
  IconComponent,
  helpContent,
  onClose,
  titleText,
  ...rest
}: PageHeaderProps) {
  const helpElement = helpContent ? (
    <Help
      bodyText={helpContent.bodyText}
      subject={helpContent.subject}
      titleText={helpContent.titleText}
    />
  ) : null
  const closePageElement = onClose ? (
    <Box ml="$2">
      <CloseIconButton id="close-page-btn" variant="text" onPress={onClose} />
    </Box>
  ) : null
  return (
    <TitleWithIcon
      IconComponent={IconComponent}
      helpButtonElement={helpElement}
      id="page-header"
      rightElement={closePageElement ? <Row>{closePageElement}</Row> : null}
      titleText={titleText}
      {...rest}
    />
  )
}
