import React from "react"
import { useTranslation } from "react-i18next"
import { Toast } from "react-native-toast-notifications"

/**
 * A hook that provides functions to show toasts.
 */
export function useToasts() {
  const { t } = useTranslation()
  const success = React.useCallback(
    (message?: string) => {
      return Toast.show(message ?? t("changesSaved"), { type: "success" })
    },
    [t],
  )
  const info = React.useCallback((message: string) => {
    return Toast.show(message, { type: "info" })
  }, [])
  const error = React.useCallback((message: string) => {
    return Toast.show(message, { type: "danger" })
  }, [])
  const warning = React.useCallback((message: string) => {
    return Toast.show(message, { type: "warning" })
  }, [])
  const show = React.useCallback((message: string) => Toast.show(message), [])
  return React.useMemo(
    () => ({
      error,
      info,
      show,
      success,
      warning,
    }),
    [error, info, show, success, warning],
  )
}
export type UseToastReturn = ReturnType<typeof useToasts>
