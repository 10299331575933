import React from "react"
import { useTranslation } from "react-i18next"

import { IconButton, Row } from "./components"
import { ListItemTextPrimary } from "./components/ListItem"
import { loadOtherFarmUsersAsync } from "./farmhq-api"
import { logger } from "./logger"
import { NoListItems } from "./NoListItems"
import { getIsAdminModeEnabledFromState } from "./selectors"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { HelpContentStatic } from "./components"
import type { PermissionCheckProps } from "./types"
import type * as Models from "./models"

export const HELP_CONTENT: HelpContentStatic = {
  bodyText: i18n.t("farm:farmUsersListHelpBodyText"),
  subject: "farm_users_list",
  titleText: i18n.t("farm:farmUsersListHelpTitle"),
}

export function keyExtractor(item: { email: string }) {
  return item.email
}

export function ListEmptyComponent(): React.JSX.Element {
  const { t } = useTranslation("farm")
  return <NoListItems message={t("farmUsersListListEmpty")} />
}

export function RenderItem({
  email,
  onPressManagePermissions,
  withPermissions,
}: PermissionCheckProps & {
  email: string
  onPressManagePermissions: () => void
}) {
  return (
    <Row justifyContent="space-between" py="$2" w="$full">
      <ListItemTextPrimary textTransform="none">{email}</ListItemTextPrimary>
      <IconButton
        IconComponent="Edit"
        id="manage-permissions-link"
        size="sm"
        onPress={withPermissions({
          callback: onPressManagePermissions,
          required: "canManageUserPermission",
        })}
      />
    </Row>
  )
}

/**
 * Provides handlers to fetch other users of the current active farm
 * from the backend.
 *
 * NOTE: This does not automatically fetch the data - that should be handled
 * in the components
 */
export function useFarmUsersListData() {
  const [data, setData] = React.useState<Models.OtherFarmUser[]>([])
  const { fetchStatus, sendRequest, ...rest } = useBackendRequest(
    loadOtherFarmUsersAsync,
  )

  const isActiveUserAdminModeEnabled = useRootSelector(
    getIsAdminModeEnabledFromState,
  )
  return {
    data,
    fetchStatus,
    handleFetch: React.useCallback(() => {
      sendRequest()
        .then((response) =>
          setData(
            response.items.filter((otherUser) => {
              // If the active user is admin, they can see other admin users
              if (otherUser.isAdmin) {
                return isActiveUserAdminModeEnabled
              }
              return true
            }),
          ),
        )
        .catch((error) => {
          logger.error(error)
          throw error
        })
    }, [isActiveUserAdminModeEnabled, sendRequest]),
    ...rest,
  }
}
