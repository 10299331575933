import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { AppLanguageSelect } from "./AppLanguageSelect"
import { setAdminMode } from "./async-storage"
import { useSignOutButton } from "./Auth"
import {
  AppIcons,
  AppText,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  Link,
  Pressable,
  renderIconFromIconProp,
  Row,
  Switch,
  useThemeProviderContext,
  View,
} from "./components"
import { Counter } from "./components/Counter"
import {
  ListItem,
  ListItemContainer,
  ListItemEndIcon,
  ListItemTextPrimary,
} from "./components/ListItem"
import { testIds } from "./components/test-id"
import { SPACING } from "./components/theme"
import { CreateFarmListItem, JoinFarmListItem } from "./CreateFarmForm"
import { FarmJoinCodeCopyButton } from "./CreateFarmJoinCode"
import { prepareDemoEnvironmentAsync } from "./farmhq-api"
import { FarmHqLogo } from "./FarmHqLogo"
import { FarmJoinCodeStatusBadge } from "./FarmJoinCodeStatusBadge"
import { Urls } from "./Internal"
import { logger } from "./logger"
import { MeasurementRadio } from "./MeasurementRadio"
import { getNumUnreadNotificationsFromState } from "./notifications.reducer"
import { useExitDemoButton } from "./PrepareDemoButton"
import { SeedDatabaseListItem } from "./SeedDatabase"
import {
  getActiveUserEmailFromState,
  getFarmJoinCodeStatusFromState,
  getFarmJoinCodeStringFromState,
  getIsAdminModeEnabledFromState,
  getUserIsAdminFromState,
  getUserNameFamilyFromState,
  getUserNameGivenFromState,
} from "./selectors"
import { ShareFarmJoinCodeButton } from "./ShareFarmJoinCodeButton"
import i18n from "./translations/i18n"
import { isTruthyString } from "./type-guards"
import { useErrorHandler } from "./useErrorHandler"
import {
  getIsDemoModeActiveFromState,
  getRealFarmCountFromState,
} from "./user-farms.selectors"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type {
  AcceptsChildren,
  IconButtonProps,
  IconProp,
  RowProps,
} from "./components"

import type { TestIdProps } from "./components/test-id"
import type { PermissionCheckProps } from "./types"

import type { TargetDatabaseName } from "./Internal"

import type { FarmJoinCodeStatus } from "./models"
const styles = StyleSheet.create({
  notificationsCounter: {
    position: "absolute",
    right: -20,
    top: -10,
  },
})

/**
 *
 */
function Section({
  IconComponent,
  children,
  id,
  subtitle,
  title,
}: AcceptsChildren &
  TestIdProps & {
    title: string
    IconComponent?: IconProp
    subtitle?: React.JSX.Element | string
  }): React.JSX.Element {
  const iconElement = renderIconFromIconProp(IconComponent)
  return (
    <View id={id}>
      <Box pt="$2">
        <Row>
          {iconElement ? <Avatar size="xs">{iconElement}</Avatar> : null}
          <Box ml="$2">
            <Heading fontWeight="bold" variant="h4">
              {title}
            </Heading>
          </Box>
        </Row>
        {isTruthyString(subtitle) ? (
          <Box mt="$2">
            <Heading colorScheme="secondary" flex={1} fontSize="$sm">
              {subtitle}
            </Heading>
          </Box>
        ) : (
          subtitle
        )}
      </Box>
      {children}
    </View>
  )
}

interface UserSectionProps {
  onDemoLoaded: () => unknown
  onPressCreateFarmAccount: () => void
  onPressJoinFarmAccount: () => void
  onPressManagePhoneNumbers: () => void
  onPressViewNotifications: () => void
}

function SignOutLink(): React.JSX.Element {
  const { id, onPress, text } = useSignOutButton()
  return (
    <Pressable onPress={onPress}>
      <ListItem
        EndComponent={ListItemEndIcon}
        IconComponent="SignOut"
        id={id}
        primary={text}
      />
    </Pressable>
  )
}

/**
 *
 */
export function ThemeToggle(): React.JSX.Element {
  const { t } = useTranslation("account")
  const { colorMode, onColorModeChanged } = useThemeProviderContext()
  return (
    <ListItem
      IconComponent="ThemeLightDark"
      id="toggle-theme-btn"
      primary={t("darkModeToggle")}
      EndComponent={
        <Switch
          value={colorMode === "dark"}
          onValueChange={(isDark) =>
            onColorModeChanged(isDark ? "dark" : "light")
          }
        />
      }
    />
  )
}

/**
 *
 */
function PhoneNumbersListLink(props: {
  onPress: () => void
}): React.JSX.Element {
  const { t } = useTranslation("account")
  return (
    <Pressable {...testIds("phone-numbers-list-link")} onPress={props.onPress}>
      <ListItem
        EndComponent={ListItemEndIcon}
        IconComponent="VerifyPhoneNumber"
        primary={t("phoneNumbersListLink")}
      />
    </Pressable>
  )
}

/**
 *
 */
function ViewNotificationsLink(props: {
  onPress: () => void
}): React.JSX.Element {
  const { t } = useTranslation("account")
  const numNotifications = useRootSelector(getNumUnreadNotificationsFromState)

  return (
    <Pressable onPress={props.onPress}>
      <ListItem
        EndComponent={ListItemEndIcon}
        IconComponent="NotificationsOn"
        id="app-notifications-link"
        primary={
          <Row>
            <ListItemTextPrimary>
              {t("appNotificationsLinkTo")}
            </ListItemTextPrimary>
            {numNotifications > 0 ? (
              <Counter
                count={numNotifications}
                style={styles.notificationsCounter}
              >
                {numNotifications}
              </Counter>
            ) : null}
          </Row>
        }
      />
    </Pressable>
  )
}

export function AdminModeToggleItem() {
  const isAdminModeEnabled = useRootSelector(getIsAdminModeEnabledFromState)
  const dispatch = useRootDispatch()
  const { t } = useTranslation("account")
  return (
    <ListItemContainer>
      <Row w="$full">
        <Box mr="$2">
          <AppIcons.Admin />
        </Box>
        <ListItemTextPrimary>{t("adminModeToggle")}</ListItemTextPrimary>
        <Box ml="auto">
          <Switch
            value={isAdminModeEnabled}
            onValueChange={(nextValue) => {
              dispatch(setAdminMode(nextValue)).catch((e) => {
                logger.error(e)
                throw e
              })
            }}
          />
        </Box>
      </Row>
    </ListItemContainer>
  )
}
function LoadDemoListItem({ onSuccess }: { onSuccess: () => void }) {
  const dispatch = useRootDispatch()
  const handleError = useErrorHandler()

  const handlePress = () => {
    dispatch(prepareDemoEnvironmentAsync())
      .then(onSuccess)
      .catch((error) => handleError(error, { toastMessage: "default" }))
  }
  return (
    <Pressable onPress={handlePress}>
      <ListItem
        EndComponent={<AppIcons.ListItemEnd />}
        IconComponent="Truck"
        primary="Load Demo"
      />
    </Pressable>
  )
}

function ExitDemoListItem(): React.JSX.Element {
  const { onPress, text } = useExitDemoButton()
  return (
    <Pressable onPress={onPress}>
      <ListItem
        EndComponent={<ListItemEndIcon />}
        IconComponent="SignOut"
        primary={text}
      />
    </Pressable>
  )
}

export function UserSection(props: UserSectionProps): React.JSX.Element {
  const { t } = useTranslation("account")
  const isAdminUser = useRootSelector(getUserIsAdminFromState)

  const isDemoModeActive = useRootSelector(getIsDemoModeActiveFromState)
  const email = useRootSelector(getActiveUserEmailFromState)
  const nameGiven = useRootSelector(getUserNameGivenFromState)
  const nameFamily = useRootSelector(getUserNameFamilyFromState)
  return (
    <Section
      IconComponent="Account"
      id="account"
      title={t("userAccountTitle")}
      subtitle={
        <View>
          <Row>
            <AppText style={{ marginRight: SPACING.$2 }}>
              {t("userNameWithNameGivenAndNameFamily", {
                nameFamily,
                nameGiven,
              })}
            </AppText>
            {isAdminUser ? (
              <Badge fontSize="$sm">{t("adminUser")}</Badge>
            ) : null}
          </Row>
          <AppText colorScheme="secondary">{email}</AppText>
        </View>
      }
    >
      <ListItemContainer>
        <Row w="$full">
          <Box mr="$2">
            <AppIcons.Measurement />
          </Box>
          <ListItemTextPrimary>
            {t("measurementPreferenceLabel")}
          </ListItemTextPrimary>
          <Box ml="auto">
            <MeasurementRadio />
          </Box>
        </Row>
      </ListItemContainer>
      <Divider />
      <ThemeToggle />
      <Divider />
      <ListItemContainer>
        <AppLanguageSelect />
      </ListItemContainer>
      <Divider />
      <PhoneNumbersListLink onPress={props.onPressManagePhoneNumbers} />
      <Divider />
      <ViewNotificationsLink onPress={props.onPressViewNotifications} />
      <Divider />
      <Pressable onPress={props.onPressCreateFarmAccount}>
        <CreateFarmListItem />
      </Pressable>
      <Divider />
      <Pressable onPress={props.onPressJoinFarmAccount}>
        <JoinFarmListItem />
      </Pressable>
      <Divider />
      <SignOutLink />
      <Divider />
      {isDemoModeActive ? (
        <ExitDemoListItem />
      ) : (
        <LoadDemoListItem onSuccess={props.onDemoLoaded} />
      )}
      {isAdminUser ? (
        <React.Fragment>
          <Divider />
          <AdminModeToggleItem />
        </React.Fragment>
      ) : null}
    </Section>
  )
}
interface FarmsSectionProps {
  // farmAddressFormatted: string | undefined
  farmName: string
  joinFarmUrl: string
  onPressChangeFarmAccount: () => void
  onPressCreateJoinCode: () => void
  onPressUsersList: () => void
  onPressViewPermissions: () => void
}

/**
 * Show current farm join code
 */
export function FarmJoinCodeListItem({
  activeFarmName,
  codeStatus,
  codeString,
  joinFarmUrl,
  onPressCreateJoinCode,
  withPermissions,
  ...rest
}: PermissionCheckProps &
  Pick<FarmsSectionProps, "onPressCreateJoinCode"> &
  RowProps & {
    activeFarmName: string
    codeStatus: FarmJoinCodeStatus | undefined
    codeString: string | undefined
    joinFarmUrl: string
  }): React.JSX.Element {
  const { t } = useTranslation("account")
  const iconButtonProps: Omit<IconButtonProps, "IconComponent"> = {
    variant: "subtle",
  }
  const isCodeActive = codeStatus === "ACTIVE"
  return (
    <Box flexWrap="wrap" id="farm-join-code" w="$full" {...rest}>
      <Row>
        <Box mr="$2">
          <AppIcons.FarmJoinCode />
        </Box>
        <ListItemTextPrimary style={{ marginRight: SPACING.$2 }}>
          {t("farmJoinCodeTitle")}
        </ListItemTextPrimary>
        {codeStatus ? (
          <FarmJoinCodeStatusBadge codeStatus={codeStatus} />
        ) : null}
      </Row>
      <Row mt="$2" mx="$2">
        <AppText
          fontFamily="Poppins_800ExtraBold"
          fontSize="$xl"
          style={{ marginRight: SPACING.$4 }}
        >
          {codeString}
        </AppText>
        {isCodeActive && isTruthyString(codeString) ? (
          <Box mr="$4">
            <FarmJoinCodeCopyButton
              {...iconButtonProps}
              codeStatus={codeStatus}
              codeStr={codeString}
            />
          </Box>
        ) : null}
        {isCodeActive &&
        isTruthyString(codeString) &&
        isTruthyString(activeFarmName) ? (
          <ShareFarmJoinCodeButton
            {...iconButtonProps}
            activeFarmName={activeFarmName}
            codeStatus={codeStatus}
            codeString={codeString}
            joinFarmUrl={joinFarmUrl}
          />
        ) : null}
        <Box ml="auto">
          <Button
            {...iconButtonProps}
            IconComponent="Add"
            id="new-join-code-btn"
            text="New Farm Join Code"
            variant="text"
            onPress={withPermissions({
              callback: onPressCreateJoinCode,
              required: "canManageJoinCodes",
            })}
          />
        </Box>
      </Row>
    </Box>
  )
}

/**
 * Shows options for managing user's current active farm
 */
export function FarmsSection({
  farmName,
  joinFarmUrl,
  withPermissions,
  ...props
}: FarmsSectionProps & PermissionCheckProps): React.JSX.Element | null {
  const numFarms = useRootSelector(getRealFarmCountFromState)
  const codeStatus = useRootSelector(getFarmJoinCodeStatusFromState)
  const codeStr = useRootSelector(getFarmJoinCodeStringFromState)
  const { t } = useTranslation("account")
  return (
    <Section
      IconComponent="Farm"
      id="manage-farm"
      subtitle={`${farmName}`}
      title={t("activeFarmTitle")}
    >
      {numFarms > 1 ? (
        <React.Fragment>
          <Pressable onPress={props.onPressChangeFarmAccount}>
            <ListItem
              EndComponent={ListItemEndIcon}
              IconComponent="SwapHorizontal"
              id="switch-farms-btn"
              primary={t("userFarmsListLinkTo")}
            />
          </Pressable>
          <Divider />
        </React.Fragment>
      ) : null}
      <Pressable
        {...testIds("manage-users-link")}
        onPress={withPermissions({
          callback: props.onPressUsersList,
          required: "canManageUserPermission",
        })}
      >
        <ListItem
          EndComponent={ListItemEndIcon}
          IconComponent="ManageUsers"
          primary={t("farmUsersListLinkTo", { ns: "farm" })}
        />
      </Pressable>
      <Divider />
      <Pressable onPress={props.onPressViewPermissions}>
        <ListItem
          EndComponent={ListItemEndIcon}
          IconComponent="Permissions"
          id="view-permissions-link"
          primary={t("viewFarmUserPermissionsLinkTo")}
        />
      </Pressable>
      <Divider />
      <FarmJoinCodeListItem
        activeFarmName={farmName}
        codeStatus={codeStatus}
        codeString={codeStr}
        joinFarmUrl={joinFarmUrl}
        mt="$2"
        withPermissions={withPermissions}
        onPressCreateJoinCode={props.onPressCreateJoinCode}
      />
    </Section>
  )
}
interface InternalsSectionProps {
  onPressCrash: (() => void) | undefined
  onPressDebugInfo: () => void
  onPressSeedDatabase: () => void
  targetDatabaseName: TargetDatabaseName
}

export function InternalsSection(
  props: InternalsSectionProps,
): React.JSX.Element {
  const { t } = useTranslation("account")
  return (
    <Section
      IconComponent="Admin"
      id="internal-only"
      title={t("internalOnlySectionTitle")}
    >
      <SeedDatabaseListItem
        targetDatabaseName={props.targetDatabaseName}
        onPress={props.onPressSeedDatabase}
      />
      <Divider />
      {props.onPressCrash ? (
        <React.Fragment>
          <Pressable onPress={props.onPressCrash}>
            <ListItem
              EndComponent={ListItemEndIcon}
              IconComponent="Crash"
              primary={t("crashButton")}
            />
          </Pressable>
          <Divider />
        </React.Fragment>
      ) : null}
      <Pressable onPress={props.onPressDebugInfo}>
        <ListItem
          EndComponent={ListItemEndIcon}
          IconComponent="Info"
          primary={t("viewDebugInfoButton")}
        />
      </Pressable>
    </Section>
  )
}
interface CodaSectionProps {
  onPressAppInfo: () => void
  onPressContactUs: () => void
  onPressShareFeedback: () => void
  onPressViewPrivacyPolicy: (() => void) | undefined
  onPressViewTermsOfService: () => void
  updatesButton?: React.JSX.Element
}

export function CodaSection({
  onPressAppInfo,
  onPressContactUs,
  onPressShareFeedback,
  onPressViewPrivacyPolicy,
  onPressViewTermsOfService,
  updatesButton,
}: CodaSectionProps): React.JSX.Element {
  const { t } = useTranslation("account")
  const privacyListItem = (
    <ListItem
      EndComponent={ListItemEndIcon}
      IconComponent="PrivacyPolicy"
      primary={t("viewPrivacyPolicyLinkTo")}
    />
  )
  return (
    <Section
      IconComponent={<FarmHqLogo name="leaf-iso.png" />}
      id="coda-section"
      title={t("companySectionTitle")}
    >
      <Pressable onPress={onPressAppInfo}>
        <ListItem
          EndComponent={ListItemEndIcon}
          IconComponent="Info"
          primary={t("appInfoLinkTo")}
        />
      </Pressable>
      <Divider />
      {updatesButton ? (
        <React.Fragment>
          {updatesButton}
          <Divider />
        </React.Fragment>
      ) : null}
      <Pressable onPress={onPressViewTermsOfService}>
        <ListItem
          EndComponent={ListItemEndIcon}
          IconComponent="TermsOfService"
          primary={i18n.t("termsOfService:linkTo")}
        />
      </Pressable>
      <Divider />
      <Pressable onPress={onPressShareFeedback}>
        <ListItem
          EndComponent={ListItemEndIcon}
          IconComponent="Feedback"
          id="feedback-btn"
          primary={i18n.t("feedback:linkTo")}
        />
      </Pressable>
      <Divider />
      <Pressable onPress={onPressContactUs}>
        <ListItem
          EndComponent={ListItemEndIcon}
          IconComponent="CustomerService"
          id="contact-us-link"
          primary={t("contactUs", { ns: "support" })}
        />
      </Pressable>
      <Divider />
      {onPressViewPrivacyPolicy ? (
        <Pressable onPress={onPressViewPrivacyPolicy}>
          {privacyListItem}
        </Pressable>
      ) : (
        <Link to={Urls.PRIVACY_POLICY}>{privacyListItem}</Link>
      )}
    </Section>
  )
}
