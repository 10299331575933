import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { AppText, useIsDarkMode } from "./components"
import { COLORS, SIZES, SPACING } from "./components/theme"
import { FONT_SIZES } from "./components/theme/fonts"
import { RADII } from "./components/theme/radii"
import { generateRunProgressFractionText } from "./reel-runs.reducer"
import { useMeasurementPreference } from "./selectors"
import { isValidNumber } from "./type-guards"

import type { ViewProps } from "react-native"
import type { NoChildren } from "./components"
import type { RunCompletion } from "./reel-runs.reducer"

const styles = StyleSheet.create({
  barFill: {
    backgroundColor: COLORS.$blue[300],
    borderBottomLeftRadius: RADII.$md,
    borderTopLeftRadius: RADII.$md,
    bottom: 0,
    left: 0,
    position: "absolute",
    top: 0,
  },
  progressBar: {
    alignItems: "center",
    backgroundColor: COLORS.$gray[300],
    borderBottomLeftRadius: RADII.$md,
    borderTopLeftRadius: RADII.$md,
    display: "flex",
    flexDirection: "row",

    justifyContent: "space-between",
    maxHeight: SIZES.$9,
    minWidth: 50,
    paddingHorizontal: SPACING.$2,
    position: "relative",
  },
  progressBarDark: {
    backgroundColor: COLORS.$gray[500],
  },
  textInner: {
    color: COLORS.$textDark.primary,
    fontSize: FONT_SIZES.$md,
    marginVertical: "auto",
    zIndex: 1,
  },
  textLeftContainer: {
    marginRight: SPACING.$4,
  },
})

export function ProgressBar({
  barFillColor,
  fillPercentage,
  style,
  textLeft,
  textRight,
  ...rest
}: NoChildren<ViewProps> & {
  fillPercentage: number
  barFillColor?: string
  textLeft?: string
  textRight?: string
}) {
  const isDark = useIsDarkMode()

  const right = `${100 - fillPercentage}%`
  if (!Boolean(barFillColor)) {
    barFillColor = COLORS.$blue[300]
  }
  return (
    <View
      style={useStyle(() => {
        return [
          styles.progressBar,
          isDark ? styles.progressBarDark : undefined,
          style,
        ]
      }, [isDark, style])}
      {...rest}
    >
      <View
        style={[styles.barFill, { backgroundColor: barFillColor, right }]}
      />
      <View style={styles.textLeftContainer}>
        <AppText style={styles.textInner}>{textLeft}</AppText>
      </View>
      <AppText style={styles.textInner}>{textRight}</AppText>
    </View>
  )
}

/**
 * A progress bar that shows the completion percentage of a run.
 */
export function RunProgressBar({
  runCompletionPct,
  runDistanceMmElapsed,
  runDistanceMmMax,
  ...rest
}: RunCompletion & ViewProps) {
  const { t } = useTranslation()
  const measurementPreference = useMeasurementPreference()

  if (isValidNumber(runCompletionPct) && runDistanceMmMax > 0) {
    return (
      <ProgressBar
        {...rest}
        fillPercentage={runCompletionPct}
        textLeft={t("percentWithVal", { val: runCompletionPct })}
        textRight={generateRunProgressFractionText({
          measurementPreference,
          runDistanceMmElapsed,
          runDistanceMmMax,
        })}
      />
    )
  }

  return null
}
