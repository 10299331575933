import type { Reducer } from "@reduxjs/toolkit"

import { utimes } from "fs"

import { createSlice } from "@reduxjs/toolkit"

import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"
import i18n from "./translations/i18n"

import type { InstallationType } from "./sensor-configurations"
export type NamedDeviceActionsState =
  Models.ModelState<Models.NamedDeviceAction>

const INITIAL_STATE: NamedDeviceActionsState = {
  entities: {},
  ids: [],
}
const { reducer } = createSlice({
  extraReducers: (builder) =>
    builder.addCase(loadActiveFarmAsync.fulfilled, (state, action) => {
      Models.namedDeviceAction.adapter.setAll(
        state,
        action.payload.namedDeviceActions,
      )
    }),
  initialState: INITIAL_STATE,
  name: "namedDeviceActions",
  reducers: {},
})
export const namedDeviceActions: Reducer<NamedDeviceActionsState> = reducer

export function useFormatDeviceActionDisplayName() {
  return (
    action:
      | Pick<
          Models.NamedDeviceAction,
          "displayName" | "expectedOutcome" | "id" | "name"
        >
      | null
      | undefined,
  ) => {
    if (action) {
      return action.displayName ?? action.name ?? `Action ${action.id}`
    }
    return i18n.t("deviceActions:unknownAction")
  }
}

export function canScheduleDurationToggle(
  deviceInstallationType: InstallationType,
) {
  return (
    deviceInstallationType === "pump_on_off" ||
    deviceInstallationType === "valve"
  )
}
export function canScheduleActions(deviceInstallationType: InstallationType) {
  return (
    deviceInstallationType === "pump" ||
    deviceInstallationType === "pump_vfd" ||
    deviceInstallationType === "pump_on_off" ||
    deviceInstallationType === "pump_off_only" ||
    deviceInstallationType === "valve"
  )
}
export function canUseDelayedShutdown(
  deviceInstallationType: InstallationType,
) {
  return (
    canScheduleActions(deviceInstallationType) ||
    deviceInstallationType === "reel_with_booster_off_only"
  )
}
