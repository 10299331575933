import React from "react"
import { Modal, Pressable, StyleSheet, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStyle } from "react-native-style-utilities"

import { Box } from "./Box"
import { renderIconFromIconProp } from "./icons"
import { ListItemEndIcon } from "./ListItem"
import { Overlay } from "./Overlay"
import { testIds } from "./test-id"
import { AppText } from "./Text"
import { SPACING } from "./theme"
import { COLORS } from "./theme/colors"
import { useIsDarkMode } from "./ThemeProvider"
import { useDisableScroll } from "./useDisableScroll"

import type { PressableState } from "./Button"
import type { IconProp } from "./icons"
import type { TestId } from "./test-id"
import type { ViewProps } from "./View"
import type { PressableProps } from "./Pressable"

const styles = StyleSheet.create({
  content: {
    backgroundColor: COLORS.$paper.light,
    borderRadius: 10,
    elevation: 5,
    marginLeft: "auto",
    maxWidth: 400,
    padding: 20,
    zIndex: 100,
  },
  contentDark: {
    backgroundColor: COLORS.$paper.dark,
  },
  menuItemContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingVertical: SPACING.$3,
  },

  root: {
    position: "absolute",
    right: SPACING.$6,
    top: SPACING.$4,
  },
})

interface MenuContentProps extends ViewProps {
  isOpen: boolean
  onClose: () => void
}

/**
 * A component that displays a menu.
 */
function MenuContent({
  children,
  id,
  onClose,
  style,
  ...rest
}: MenuContentProps) {
  useDisableScroll()

  const isDark = useIsDarkMode()
  const { bottom, top } = useSafeAreaInsets()
  const resolvedStyle = useStyle(
    () => [
      styles.content,
      isDark ? styles.contentDark : undefined,
      { marginBottom: bottom, marginTop: top },
      style,
    ],
    [bottom, isDark, style, top],
  )
  return (
    <Overlay onPress={onClose}>
      <View
        accessibilityRole="menu"
        style={resolvedStyle}
        {...testIds(id)}
        {...rest}
      >
        {children}
      </View>
    </Overlay>
  )
}

export type MenuProps = MenuContentProps

export function Menu({ isOpen, onClose, style, ...rest }: MenuProps) {
  const contentStyle = useStyle(() => [styles.root, style], [style])

  return (
    <Modal
      transparent
      animationType="fade"
      visible={isOpen}
      onRequestClose={onClose}
    >
      <MenuContent
        isOpen={isOpen}
        style={contentStyle}
        onClose={onClose}
        {...rest}
      />
    </Modal>
  )
}

interface MenuItemProps {
  text: string
  EndIconComponent?: IconProp
  IconComponent?: IconProp
  color?: string
  id?: TestId
}

export function MenuItem({
  EndIconComponent,
  IconComponent,
  color,
  id,
  text,
}: MenuItemProps) {
  return (
    <View
      {...testIds(id)}
      accessibilityRole="menuitem"
      style={styles.menuItemContainer}
    >
      <Box mr="$2">
        {renderIconFromIconProp(
          IconComponent,
          typeof color === "string" ? { color } : {},
        )}
      </Box>
      <AppText color={color}>{text}</AppText>
      {Boolean(EndIconComponent) ? (
        <Box ml="auto">{renderIconFromIconProp(EndIconComponent)}</Box>
      ) : null}
    </View>
  )
}

export function MenuItemButton({
  EndIconComponent,
  IconComponent,
  color,
  id,
  text,
  ...rest
}: MenuItemProps & PressableProps) {
  const isDark = useIsDarkMode()
  const isDisabled = rest.disabled === true
  return (
    <Pressable
      accessibilityRole="button"
      accessibilityState={{ disabled: isDisabled }}
      disabled={isDisabled}
      style={(state) => {
        const { hovered = false, pressed } = state as PressableState
        let backgroundColor: string | undefined
        if (pressed) {
          backgroundColor = COLORS.$gray[300]
          if (isDark) {
            backgroundColor = COLORS.$gray[600]
          }
        } else if (hovered) {
          backgroundColor = COLORS.$gray[200]
          if (isDark) {
            backgroundColor = COLORS.$gray[700]
          }
        }

        return { backgroundColor }
      }}
      {...rest}
    >
      <MenuItem
        EndIconComponent={EndIconComponent ?? ListItemEndIcon}
        IconComponent={IconComponent}
        color={color}
        id={id}
        text={text}
      />
    </Pressable>
  )
}
