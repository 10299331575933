import type { Reducer } from "@reduxjs/toolkit"
import { AsYouType } from "libphonenumber-js"
import { useTranslation } from "react-i18next"
import { Alert, Platform } from "react-native"

import { createSlice, isAnyOf } from "@reduxjs/toolkit"

import {
  readLocalStorageAsync,
  setPhoneNumberPromptStatus,
} from "./async-storage"
import {
  completeSmsVerificationAsync,
  createPhoneNumberInternationalAsync,
  deletePhoneNumberAsync,
  loadActiveUserAsync,
  setNotificationStatusForPhoneNumberAsync,
} from "./farmhq-api"
import * as Models from "./models"
import { isTruthyString } from "./type-guards"

import type { CountryCode, E164Number } from "libphonenumber-js"
import type { StoredValues } from "./async-storage"

import type { ModelState, PhoneNumber } from "./models"
import type { RootState } from "./root.reducer"
export const PHONE_NUMBERS_PER_USER_MAX = 2

export type NotificationsStatus = "disabled" | "enabled"

export type ParsedPhoneNumber =
  | {
      isValid: false
      phoneNumber?: undefined
    }
  | {
      isValid: true
      phoneNumber: {
        country: CountryCode
        countryCallingCode: string
        nationalFormat: string
        number: E164Number
      }
    }

/**
 * Takes a string and attempts to parse into a valid international phone number.
 * @param numberInput
 */
export function parsePhoneNumberInput(
  numberInput: string | null,
): ParsedPhoneNumber {
  if (numberInput !== null) {
    const value = numberInput.startsWith("+") ? numberInput : `+${numberInput}`

    const asYouType = new AsYouType()
    asYouType.input(value)

    const parserResult = asYouType.getNumber()

    if (
      asYouType.isValid() &&
      typeof parserResult !== "undefined" &&
      parserResult.country
    ) {
      return {
        isValid: true,
        phoneNumber: {
          country: parserResult.country,
          countryCallingCode: parserResult.countryCallingCode,
          nationalFormat: parserResult.formatNational(),
          number: parserResult.number,
        },
      }
    }
  }
  return { isValid: false }
}

export function serializePhoneNumber(
  { digits, e164 }: PhoneNumber,
  options: { showCountryName: boolean },
) {
  let serialized: string | undefined
  if (e164) {
    const parsed = parsePhoneNumberInput(e164)
    if (parsed.isValid) {
      serialized = parsed.phoneNumber.nationalFormat
      if (options.showCountryName) {
        serialized = `${parsed.phoneNumber.country}: ${serialized}`
      }
    }
  } else if (
    Boolean(digits) &&
    isTruthyString(digits.countryCode) &&
    isTruthyString(digits.areaCode) &&
    isTruthyString(digits.prefix) &&
    isTruthyString(digits.suffix)
  ) {
    serialized = `${digits.countryCode}-(${digits.areaCode})-${digits.prefix}-${digits.suffix}`
  }
  return serialized ?? e164
}

const { adapter } = Models.phoneNumber

export interface PhoneNumbersState extends ModelState<PhoneNumber> {
  errorCode?: "INVALID"
  promptStatus?: StoredValues["phoneNumberPromptStatus"]
}
const initialState: PhoneNumbersState = {
  entities: {},
  ids: [],
}

const slice = createSlice({
  extraReducers: (builder) =>
    builder

      .addCase(readLocalStorageAsync.fulfilled, (state, { payload }) => {
        state.promptStatus = payload.phoneNumberPromptStatus
      })
      .addCase(setPhoneNumberPromptStatus.pending, (state, { meta }) => {
        state.promptStatus = meta.arg
      })
      .addCase(loadActiveUserAsync.fulfilled, (state, { payload }) => {
        adapter.setAll(
          state,
          payload.phoneNumbers.map((phoneNumber) => {
            const serialized = serializePhoneNumber(phoneNumber, {
              showCountryName: false,
            })
            return { ...phoneNumber, serialized }
          }),
        )
      })
      .addCase(
        completeSmsVerificationAsync.fulfilled,
        (state, { meta, payload }) => {
          if (payload.status === "INVALID") {
            state.errorCode = payload.status
          } else {
            adapter.updateOne(state, {
              changes: {
                isVerified: true,
                notificationsEnabled: true,
              },
              id: meta.arg.upnId,
            })
          }
        },
      )
      .addCase(deletePhoneNumberAsync.fulfilled, (state, { meta }) => {
        adapter.removeOne(state, meta.arg.upnId)
      })
      .addCase(
        setNotificationStatusForPhoneNumberAsync.fulfilled,
        (state, { meta }) => {
          adapter.updateOne(state, {
            changes: {
              notificationsEnabled: meta.arg.nextStatus === "enabled",
            },
            id: meta.arg.upnId,
          })
        },
      )
      .addMatcher(
        isAnyOf(createPhoneNumberInternationalAsync.fulfilled),
        (state, { payload }) => {
          adapter.upsertOne(state, {
            ...payload,
            serialized: serializePhoneNumber(payload, {
              showCountryName: false,
            }),
          })
        },
      ),
  initialState,
  name: `userPhoneNumbers`,
  reducers: {},
})

const userPhoneNumbers: Reducer<PhoneNumbersState> = slice.reducer
export default userPhoneNumbers
export function getCanUserAddPhoneNumbers(state: RootState) {
  return Models.phoneNumber.selectTotal(state) < PHONE_NUMBERS_PER_USER_MAX
}

/**
 * Hook to display an alert when the user tries to add more than
 * the allowed number of phone numbers.
 */
export function useTooManyPhoneNumbers() {
  const { t } = useTranslation("phoneNumbers")

  return () => {
    const message: string = t("tooManyPhoneNumbersMessage")
    if (Platform.OS === "web") {
      // eslint-disable-next-line no-alert
      alert(message)
    } else {
      Alert.alert(t("tooManyPhoneNumbersTitle"), message)
    }
  }
}
