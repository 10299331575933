import type { ValuesType } from "utility-types"
import type { EnvironmentProps } from "./PrepareDemoButton"
import { makeValidator, notNullish } from "./type-guards"

import type { CodaDeviceAliasProps } from "./types"

export const GOOGLE_MAPS_API_KEY =
  "AIzaSyAOxulEeTVG2U1_N0WopZYXv8-SRqQLIAc" as const

const CODA_EMAIL_SUFFIX = "@farmhq.com" as const
export function isAdminUserEmail(email: string | null | undefined): boolean {
  if (typeof email === "string") {
    return email.endsWith(CODA_EMAIL_SUFFIX)
  }
  return false
}
export const EmailAddresses = {
  CODA_SUFFIX: CODA_EMAIL_SUFFIX,
  SALES: `sales@farmhq.com`,
  USER_TECHNICAL_SUPPORT: `support${CODA_EMAIL_SUFFIX}`,
  isAdminUserEmail,
} as const
const MARKETING_SITE_URL = "https://codafarmtech.com" as const

export const PhoneNumbers = {
  CUSTOMER_SERVICE: `360-818-2632`,
} as const

export const ADMIN_MODES = ["disabled", "enabled"] as const
export type AdminMode = (typeof ADMIN_MODES)[number]
export const isValidAdminMode = makeValidator(ADMIN_MODES)

export const RemoteDatabaseNames = {
  BETA: "BETA",
  DEMO: "DEMO",
  DEV: "DEV",
  PROD: "PROD",
} as const

const WEBAPP_URL = {
  default: "https://app.farmhq.com",
  demo: "https://demo.farmhq.com",
  local: "http://localhost:3000",
} as const

export type RemoteDatabaseName = ValuesType<typeof RemoteDatabaseNames>

export const TargetDatabaseNames = {
  ...RemoteDatabaseNames,
  LOCAL: "LOCAL",
} as const
export const isValidDatabaseName = makeValidator(
  Object.values(TargetDatabaseNames),
)
export type TargetDatabaseName = ValuesType<typeof TargetDatabaseNames>

export type DatabaseValue<T> = {
  [key in TargetDatabaseName]?: T
} & {
  defaultValue: T
}

export function makeDatabaseValueGetter(
  targetDatabaseName: TargetDatabaseName,
) {
  return function getDatabaseValue<T>({
    defaultValue,
    ...overrides
  }: DatabaseValue<T>): T {
    return overrides[targetDatabaseName] ?? defaultValue
  }
}

const IMAGE_HOST = `https://coda-farm-technologies.imgix.net`

const GRAFANA_BASE_URL = `https://admin.farmhq.com/grafana/d/1brB3XZVk/device-dashboard?orgId=1`

export function getGrafanaUrlForDevice(
  params: CodaDeviceAliasProps & Pick<EnvironmentProps, "targetDatabaseName">,
) {
  return `${GRAFANA_BASE_URL}&var-device_name=${params.codaDeviceAlias}&var-Environment=${params.targetDatabaseName}`
}

export const Urls = {
  IMAGE_HOST,
  PRIVACY_POLICY: `${MARKETING_SITE_URL}/privacy-policy`,
  SENTRY_DSN: {
    default:
      "https://4d92d520622f48a8a3e78745f947ef26@o372469.ingest.sentry.io/6752359",
    web: "https://2513b55afb4f453fad15f3e42f9e70ab@o372469.ingest.sentry.io/5283389",
  },
  WEBAPP: WEBAPP_URL,
  // ZENDESK: "https://codafarmtechhelp.zendesk.com/api/v2",
  buildSupportImageUrl: (name: string) => `${IMAGE_HOST}/support/${name}`,
  getRemoteBackend: (targetDatabaseName: RemoteDatabaseName) => {
    return `${"https://3rknbjtgn8.execute-api.us-west-2.amazonaws.com/live/private"}/${targetDatabaseName}` as const
  },
  getReverseGeocode: (address: string) => {
    return `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_MAPS_API_KEY}`
  },
  getWebappUrls: (
    dbName: TargetDatabaseName,
    localhostOverride: string | undefined,
  ) => {
    let baseUrl: string = WEBAPP_URL.default

    if (dbName === "LOCAL") {
      baseUrl = WEBAPP_URL.local
      if (notNullish(localhostOverride)) {
        baseUrl = `http://${localhostOverride}:3000`
      }
    } else if (dbName === "DEMO") {
      baseUrl = WEBAPP_URL.demo
    }
    return {
      baseUrl,
      joinFarm: `${baseUrl}${"/join-farm"}`,
      support: `${baseUrl}${"/support"}`,
    } as const
  },
} as const
