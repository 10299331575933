import React from "react"
import { StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { testIds } from "./test-id"
import { SIZES } from "./theme"

import type { SpaceToken } from "./theme"
import type { ViewProps } from "react-native"
import type { OptionalTestId } from "./test-id"

const styles = StyleSheet.create({
  root: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
})

/**
 * A component that lays out its children in a column.
 */
export function Column({
  children,
  id,
  spacing,
  style,
}: OptionalTestId &
  ViewProps & {
    spacing?: SpaceToken | number
  }) {
  if (typeof spacing === "undefined") {
    spacing = 0
  }
  let spacingFinal: number | string
  if (typeof spacing === "string") {
    spacingFinal = SIZES[spacing]
  } else if (typeof spacing === "number") {
    spacingFinal = spacing
  }

  return (
    <View
      {...testIds(id)}
      style={useStyle(() => {
        return [styles.root, style]
      }, [style])}
    >
      {React.Children.map(children, (child, index) => {
        const childStyle =
          index === React.Children.count(children) - 1
            ? {}
            : { marginBottom: spacingFinal }
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.JSX.Element, {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            style: [child.props.style, childStyle],
          })
        }
        return child
      })}
    </View>
  )
}
