import React from "react"
import { useTranslation } from "react-i18next"

import {
  AlertDialog,
  AlertDialogBodyText,
  AlertDialogScrollView,
} from "./AlertDialog"
import { CloseButton, HelpIconButton } from "./Button"

import type { SetOptional } from "type-fest"

import type { makeEventTrackerHook } from "../Analytics"
import type { AlertDialogProps } from "./AlertDialog"
import type { ElementOrComponent } from "./types"

export function makeUseHelpDialog(
  useTrackEvent: ReturnType<typeof makeEventTrackerHook>,
) {
  return function useHelpDialog({ subject }: { subject: string }) {
    const trackEvent = useTrackEvent()
    const [isOpen, setIsOpen] = React.useState(false)
    return {
      isHelpOpen: isOpen,
      onCloseHelp: () => {
        return setIsOpen(false)
      },
      onOpenHelp: () => {
        setIsOpen(true)
        trackEvent({
          help_topic: subject,
          name: "open_help_dialog",
        })
      },
    }
  }
}
export interface HelpContentStatic {
  bodyText: string
  subject: string
  ContentComponent?: ElementOrComponent
  titleText?: string
}
export function HelpDialog({
  bodyText,
  children,
  onClose,
  titleElement,
  ...rest
}: SetOptional<AlertDialogProps, "titleElement"> & {
  bodyText?: string
}) {
  const { t } = useTranslation()
  return (
    <AlertDialog
      IconComponent="Info"
      actionElement={<CloseButton onPress={onClose} />}
      id="help-content"
      titleElement={titleElement ?? t("help")}
      onClose={onClose}
      {...rest}
    >
      <AlertDialogScrollView>
        {typeof bodyText === "string" ? (
          <AlertDialogBodyText>{bodyText}</AlertDialogBodyText>
        ) : null}
        {children}
      </AlertDialogScrollView>
    </AlertDialog>
  )
}

export function makeHelpComponent(
  trackEvent: ReturnType<typeof makeEventTrackerHook>,
) {
  const useHelpDialog = makeUseHelpDialog(trackEvent)
  return function HelpComponent({
    bodyText,
    subject,
    titleText,
  }: HelpContentStatic) {
    const { isHelpOpen, onCloseHelp, onOpenHelp } = useHelpDialog({
      subject,
    })
    return (
      <React.Fragment>
        <HelpIconButton onPress={onOpenHelp} />
        <HelpDialog
          bodyText={bodyText}
          isOpen={isHelpOpen}
          titleElement={titleText}
          onClose={onCloseHelp}
        />
      </React.Fragment>
    )
  }
}
