import React from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import { ActionButtons } from "./ActionButtons"
import {
  AlertDialog,
  AlertDialogBody,
  AppIcons,
  AppText,
  Box,
  FlatList,
  IconButton,
  Paper,
  Row,
  TextWithIcon,
} from "./components"
import { SPACING } from "./components/theme"
import { CreateButton } from "./CreateCustomNotification"
import { DeviceNotificationsToggleSwitch } from "./DeviceNotificationsToggleSwitch"
import * as DeviceSummaryContext from "./DeviceSummaryContext"
import { deleteEventActionTriggersAsync } from "./farmhq-api"
import * as Models from "./models"
import { NoListItems } from "./NoListItems"
import { isCustomNotificationTrigger } from "./selectors"
import { useFormatSensorName, useFormatSensorState } from "./sensor-formatting"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useShallowEqualSelector } from "./useRootSelector"

import type { HelpContentStatic } from "./components"
import type { ToggleSwitchProps } from "./DeviceNotificationsToggleSwitch"
import type { PermissionCheckProps } from "./types"

import type { EventActionTrigger } from "./models"

function useTexts() {
  return useTranslation("deviceNotifications")
}

export const HELP_CONTENT: HelpContentStatic = {
  bodyText: i18n.t("deviceNotifications:createNotificationHelpBodyText"),
  subject: "device_notifications",
  titleText: i18n.t("deviceNotifications:aboutCustomNotifications"),
}

/**
 * Custom notification list item
 */
function RenderItem({
  deleteButtonElement,
  ...props
}: PermissionCheckProps &
  Pick<
    EventActionTrigger,
    | "id"
    | "sourceSensor"
    | "sourceSensorStateCurrent"
    | "sourceSensorStatePrevious"
  > & {
    deleteButtonElement?: React.JSX.Element
  }) {
  const { t } = useTexts()
  const formatSensorName = useFormatSensorName()
  const formatSensorState = useFormatSensorState()

  const sourceSensorText: string = formatSensorName(props.sourceSensor)
  const statePreviousText: string = formatSensorState(
    props.sourceSensorStatePrevious,
  )
  const stateCurrentText: string = formatSensorState(
    props.sourceSensorStateCurrent,
  )

  return (
    <Row key={props.id} alignItems="flex-start" py="$2">
      <AppText id="source-sensor" style={{ marginRight: SPACING.$2 }}>
        {sourceSensorText}
      </AppText>
      <Box>
        <AppText colorScheme="secondary" style={{ marginRight: SPACING.$2 }}>
          {t("changesFrom")}
        </AppText>
        <Row>
          <AppText id="source-sensor-state-previous">
            {statePreviousText}
          </AppText>
          <AppIcons.ArrowRight />
          <AppText id="source-sensor-state-current">{stateCurrentText}</AppText>
        </Row>
      </Box>
      <Box ml="auto" mt="$2">
        {deleteButtonElement}
      </Box>
    </Row>
  )
}

function ConfirmDeleteDeviceNotificationDialogDialog({
  onCancelDelete,
  rowToDelete,
  withPermissions,
}: PermissionCheckProps & {
  onCancelDelete: () => void
  rowToDelete: EventActionTrigger
}): React.JSX.Element {
  const { deviceId } = DeviceSummaryContext.useContext()
  const { t } = useTranslation("deviceNotifications")
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    deleteEventActionTriggersAsync,
  )

  const handleSubmit = () => {
    sendRequest({
      deviceId,
      ids: [rowToDelete.id],
      kind: "notification",
    })
      .then(() => {
        onCancelDelete()
        return toasts.success()
      })
      .catch((error) => {
        handleError(error, { toastMessage: "default" })
      })
  }
  return (
    <AlertDialog
      isOpen
      id="confirm-delete-notification"
      titleElement={t("deleteThisRule")}
      actionElement={
        <ActionButtons
          buttonSize="sm"
          isLoading={isLoading}
          orientation="horizontal"
          onPressCancel={onCancelDelete}
          onPressSubmit={handleSubmit}
        />
      }
      onClose={onCancelDelete}
    >
      <AlertDialogBody>
        <RenderItem {...rowToDelete} withPermissions={withPermissions} />
      </AlertDialogBody>
    </AlertDialog>
  )
}

const ItemSeparatorComponent = () => <Box my="$2" />
/**
 * List of custom notifications for specified (in context) device
 */
export function DeviceNotificationsList({
  onDenied,
  onPressCreate,
  withPermissions,
}: PermissionCheckProps &
  ToggleSwitchProps & {
    onPressCreate: () => void
  }): React.JSX.Element {
  const { t } = useTexts()
  const { deviceId } = DeviceSummaryContext.useContext()
  const [idToDelete, setIdToDelete] = React.useState<number>()

  const rows = useShallowEqualSelector((state) => {
    const allTriggers = Models.trigger.selectAll(state)
    return allTriggers.filter((trigger) => {
      return (
        trigger.sourceDeviceId === deviceId &&
        isCustomNotificationTrigger(trigger)
      )
    })
  })
  const rowToDelete = rows.find((row) => row.id === idToDelete)

  const onCancelDelete = () => setIdToDelete(undefined)

  return (
    <FlatList
      ItemSeparatorComponent={ItemSeparatorComponent}
      data={rows}
      id="device-notifications-list"
      ListEmptyComponent={
        <Box px="$4">
          <NoListItems
            message={t(
              "noCustomNotificationRulesHaveBeenCreatedForThisDevice",
              { ns: "deviceNotifications" },
            )}
          />
        </Box>
      }
      ListFooterComponent={
        <Box ml="auto" pb="$4" pt="$2" px="$4">
          <CreateButton
            withPermissions={withPermissions}
            onPress={onPressCreate}
          />
        </Box>
      }
      ListHeaderComponent={
        <React.Fragment>
          {rowToDelete ? (
            <ConfirmDeleteDeviceNotificationDialogDialog
              rowToDelete={rowToDelete}
              withPermissions={withPermissions}
              onCancelDelete={onCancelDelete}
            />
          ) : null}
          <Paper px="$4" py="$3" rounded="$default">
            <TextWithIcon
              IconComponent="NotificationsOn"
              text={t("notificationsEnabled")}
              actionElement={
                <DeviceNotificationsToggleSwitch
                  deviceId={deviceId}
                  onDenied={onDenied}
                />
              }
            />
          </Paper>
          <AppText
            colorScheme="secondary"
            flex={1}
            style={{ padding: SPACING.$4 }}
          >
            {t("sendANotificationWhen")}
          </AppText>
        </React.Fragment>
      }
      renderItem={({ index, item }) => {
        const handlePressDelete = withPermissions({
          callback: () => setIdToDelete(item.id),
          required: "canManageDeviceNotifications",
        })
        return (
          <Paper
            key={item.id}
            id={`device-notifications-list-item-${index}`}
            px="$4"
            rounded={Platform.select({ web: "$default" })}
          >
            <RenderItem
              withPermissions={withPermissions}
              deleteButtonElement={
                <IconButton
                  IconComponent="TrashCan"
                  id="delete-notification-btn"
                  variant="outline"
                  onPress={handlePressDelete}
                />
              }
              {...item}
            />
          </Paper>
        )
      }}
    />
  )
}
