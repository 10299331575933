import { useTranslation } from "react-i18next"
import { Image, Pressable, StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"
import { useNavigate } from "react-router"

import { Urls } from "@fhq/app"
import {
  AppText,
  Box,
  Row,
  ScrollView,
  useBreakpointValue,
} from "@fhq/app/components"
import { SUPPORT_PAGE_DEVICES } from "@fhq/app/Support"

import { ImageHeader } from "./base/ImageHeader"
import { Page } from "./base/Page"

const styles = StyleSheet.create({
  deviceImage: {
    flex: 1,
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
})

/**
 * Show a grid of clickable link images for each product
 */

export default function SupportLandingPage(): React.JSX.Element {
  const navigate = useNavigate()
  const { t } = useTranslation("support")
  const getResponsiveValue = useBreakpointValue()
  const imageWidth = getResponsiveValue({
    base: "100%",
    sm: "50%",
  })
  const imageButtonStyle = useStyle(
    () => ({
      height: 275,
      // margin: SIZES.$1,
      position: "relative",
      width: imageWidth,
    }),
    [imageWidth],
  )
  return (
    <ScrollView variant="pageScroll">
      <Page
        IconComponent="Support"
        px="$4"
        subheaderElement={t("chooseYourProduct")}
        titleText={t("supportCenter")}
      >
        <Box flex={1} pb="$toolbar" py="$2" w="$full">
          <Row>
            {SUPPORT_PAGE_DEVICES.map(
              (device): React.JSX.Element => (
                <Pressable
                  key={device.name}
                  // _hover={{ borderColor: "gray.200", borderWidth: 2, shadow: 2 }}
                  style={imageButtonStyle}
                  onPress={() => navigate(device.id)}
                >
                  <Image
                    alt={device.name}
                    resizeMode="cover"
                    source={{ uri: Urls.buildSupportImageUrl(device.imageUrl) }}
                    style={styles.deviceImage}
                  />

                  <ImageHeader>
                    <Box ml="$2" mr="auto" my="auto">
                      <AppText
                        colorScheme="lightText"
                        fontFamily="Poppins_800ExtraBold"
                        fontSize="$sm"
                      >
                        {device.name}
                      </AppText>
                    </Box>
                  </ImageHeader>
                </Pressable>
              ),
            )}
          </Row>
        </Box>
      </Page>
    </ScrollView>
  )
}
