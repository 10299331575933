import {
  addMilliseconds,
  differenceInHours,
  format,
  subMilliseconds,
} from "date-fns"
import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"
import {
  VictoryAxis,
  VictoryChart,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
} from "victory-native"

import { AppText, useBreakpointValue, useIsDarkMode } from "./components"
import { COLORS, getFontName } from "./components/theme"
import { notNullish } from "./type-guards"
import { useThemedValue } from "./useThemedValue"

import type { BreakpointInputValue } from "./components"
import type { ThemedValue } from "./useThemedValue"
import type { MinDateMaxDate, Union } from "./types"

import type {
  BlockProps,
  VictoryAxisProps,
  VictoryChartProps,
  VictoryLegendProps,
  VictoryLineProps,
  VictoryVoronoiContainerProps,
} from "victory"
export interface ChartDatum<T = number> {
  x: number
  y: T
}
export interface ChartData<T extends number | null = number | null> {
  maxValue: number
  values: Array<ChartDatum<T>>
}

export type ChartChildname = `chart-line-${number}`

export type GraphPaddingProp = BreakpointInputValue<BlockProps>
export const VICTORY_THEME = VictoryTheme.material

export function useTimeseriesGraph({
  axisLabel,
  dateMsMax,
  dateMsMin,
  padding,
  scaleAxis,
  shouldAnimate,
}: {
  dateMsMax: number
  dateMsMin: number
  axisLabel?: string
  padding?: GraphPaddingProp
  scaleAxis?: boolean
  shouldAnimate?: boolean
}): {
  axis: {
    getX: (options?: { extraTickCount?: number }) => VictoryAxisProps
    getY: (options: { maxValue: number }) => VictoryAxisProps
  }
  chart: VictoryChartProps
  getLine: (options: {
    maxValue: number
    stroke?: ThemedValue<string>
  }) => VictoryLineProps
  getVoronoiContainer: (
    options: Union<
      {
        getUnitLabel?: (childName?: ChartChildname) => string
        unitLabel?: string
      },
      {
        getUnitLabel?: undefined
        unitLabel?: undefined
      }
    >,
  ) => VictoryVoronoiContainerProps
  legend: VictoryLegendProps
} {
  const dateMin = new Date(dateMsMin)
  const dateMax = new Date(dateMsMax)

  const themedValue = useThemedValue()
  const isDark = useIsDarkMode()
  const textColor = themedValue({
    dark: COLORS.$textLight.primary,
    light: COLORS.$textDark.primary,
  })

  const getValue = useBreakpointValue()
  const responsiveValues = getValue({
    base: {
      axis: { style: { tickLabels: { fontSize: 14 } } },
      axisLabel: { fontSize: 24, padding: 90 },
      chart: {
        height: 160,
        padding: {
          bottom: 30,
          left: 35,
          right: Platform.select({ default: 40, web: 20 }),
          top: 30,
          ...padding?.base,
        },
      },
      legend: {
        centerTitle: true,
        // gutter: 20,
        style: {
          labels: {
            fontSize: 14,
          },
          title: {
            fontSize: 14,
          },
        },
        // symbolSpacer: 14,
        titleOrientation: "left" as const,
      },
      tooltip: { style: { fontSize: 14 } },
    },
    lg: {
      axis: { style: { tickLabels: { fontSize: 24 } } },
      axisLabel: { fontSize: 24, padding: 50 },
      chart: {
        height: 400,
        padding: {
          bottom: 40,
          left: 75,
          right: 40,
          top: 60,
          ...padding?.lg,
        },
      },
      legend: {
        // centerTitle: false,
        style: {
          labels: { fontSize: 24 },
          title: { fontSize: 24 },
        },
        // gutter: 12,
        // symbolSpacer: 8,
        titleOrientation: "left" as const,
      },
      tooltip: { style: { fontSize: 24 } },
    },
    sm: {
      axis: { style: { tickLabels: { fontSize: 16 } } },
      axisLabel: { fontSize: 16, padding: 30 },
      chart: {
        height: 400,
        padding: {
          bottom: 60,
          left: 60,
          right: 40,
          top: 40,
          ...padding?.sm,
        },
      },
      legend: {
        centerTitle: true,
        style: { labels: { fontSize: 16 }, title: { fontSize: 16 } },
        symbolSpacer: 8,
        // gutter: 20,
        titleOrientation: "left" as const,
      },
      tooltip: { style: { fontSize: 16 } },
    },
  })
  const axisProps: VictoryAxisProps = {
    style: {
      axisLabel: {
        fontSize: responsiveValues?.axisLabel.fontSize,
        padding: responsiveValues?.axisLabel.padding,
      },
      grid: {
        stroke: isDark ? COLORS.$dark[200] : COLORS.$white[200],
      },
      tickLabels: {
        ...responsiveValues?.axis.style.tickLabels,
        color: textColor,
        fill: textColor,
      },
    },
  }
  return {
    axis: {
      getX: (options) => {
        const extraTickCount = options?.extraTickCount ?? 4
        const tickValues = [dateMsMin]
        const total = subMilliseconds(dateMax, dateMin.getTime()).getTime()
        // get evenly spaced date values in between min and max
        const interval = total / extraTickCount

        for (const i of _.range(extraTickCount)) {
          const nextValue = addMilliseconds(
            dateMin,
            interval * (i + 1),
          ).getTime()
          if (nextValue < dateMax.getTime()) {
            tickValues.push(nextValue)
          }
        }
        tickValues.push(dateMsMax)

        return {
          ...axisProps,
          style: {
            ...axisProps.style,
          },
          tickFormat: (value: number) => {
            const dateValue = new Date(value)
            const diff = differenceInHours(dateMax, dateMin)
            if (diff > 24 * 7) {
              return format(dateValue, "M/d")
            }

            if (diff > 24) {
              const time = format(dateValue, "p")
              const day = format(dateValue, "EEEEEE")
              return `${time}\n${day}`
            }
            return format(dateValue, "p")
          },
          tickValues,
        }
      },
      getY: ({ maxValue }) => {
        let tickFormat: VictoryAxisProps["tickFormat"] = (
          tickValue: number,
        ) => {
          return Math.round(tickValue * maxValue).toFixed(0)
        }
        const tickValues: VictoryAxisProps["tickValues"] = [
          0, 0.25, 0.5, 0.75, 1,
        ]
        let domain: VictoryAxisProps["domain"] = [0, 1]
        // [0, maxValue];

        if (scaleAxis === true) {
          domain = [0, 1]

          tickFormat = (tickValue: number) => {
            return Math.round(tickValue * maxValue).toFixed(0)
          }
        }
        return {
          ...axisProps,
          dependentAxis: true,
          domain,
          label: axisLabel,
          style: { ...axisProps.style },
          tickFormat,
          tickValues,
        }
      },
    },
    chart: {
      ...responsiveValues?.chart,
      animate: shouldAnimate === true ? { duration: 400 } : undefined,
      style: { parent: { zIndex: -1 } },
      theme: VictoryTheme.material,
    },
    getLine: (options) => ({
      interpolation: "stepAfter",
      style: {
        data: {
          stroke: themedValue(options.stroke),
          strokeLinecap: "round",
          strokeWidth: 2,
        },
      },
      y: (datum: ChartDatum) => {
        return datum.y / options.maxValue
      },
    }),
    getVoronoiContainer: (options) => {
      const getUnitLabel =
        options.getUnitLabel ?? (() => options.unitLabel ?? "")
      const backgroundColor = themedValue(COLORS.$background)
      return {
        labelComponent: (
          <VictoryTooltip
            constrainToVisibleArea
            flyoutPadding={2}
            renderInPortal={false}
            flyoutStyle={{
              backgroundColor,
              color: textColor,
              fill: backgroundColor,
              strokeWidth: 0.5,
            }}
            style={{
              ...responsiveValues?.tooltip.style,
              backgroundColor,
              fill: textColor,
              fontFamily: getFontName("Poppins_500Medium"),
              fontWeight: 400,
            }}
          />
        ),
        labels: ({
          datum,
        }: {
          datum: ChartDatum & { childName: `chart-line-${number}` | undefined }
        }) => {
          const valueFormatted = notNullish(datum.y)
            ? `${datum.y} ${getUnitLabel(datum.childName)}`
            : "N/A"
          return `${valueFormatted}\n${format(datum.x, "Pp")}`
        },
        mouseFollowTooltips: false,
        style: {},
        voronoiDimension: "x",
      }
    },
    legend: {
      ...responsiveValues?.legend,
      style: {
        labels: {
          ...responsiveValues?.legend.style.labels,
          fill: textColor,
          fontFamily: getFontName("Poppins_500Medium"),
          fontWeight: 400,
        },
        title: {
          ...responsiveValues?.legend.style.title,
          fill: textColor,
          // fontFamily: getFont("OpenSans_400Regular"),
          fontWeight: 500,
        },
      },
      // title,
    },
  }
}

interface EmptyGraphProps extends MinDateMaxDate {
  dateMsMax: number
  dateMsMin: number
  axisLabel?: string
  graphColor?: string
  graphTitle?: string
  maxValue?: number
  padding?: GraphPaddingProp
  text?: string
}

const styles = StyleSheet.create({
  root: {
    position: "relative",
  },
  textWrapper: {
    alignItems: "center",
    bottom: 0,
    justifyContent: "center",
    left: 0,
    opacity: 0.7,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 9,
  },
})

export function EmptyGraph({
  dateMsMax,
  dateMsMin,
  graphColor,
  graphTitle,
  maxValue = 200,
  padding,
  text,
}: EmptyGraphProps): React.JSX.Element {
  const { t } = useTranslation()
  if (typeof text === "undefined") {
    text = t("dataNotAvailable")
  }
  const isDark = useIsDarkMode()
  const graph = useTimeseriesGraph({
    dateMsMax,
    dateMsMin,
    padding,
  })
  return (
    <View style={styles.root}>
      <View
        style={useStyle(() => {
          return [
            styles.textWrapper,
            {
              backgroundColor: isDark
                ? COLORS.$paper.dark
                : COLORS.$paper.light,
            },
          ]
        }, [isDark])}
      >
        <AppText>{text}</AppText>
      </View>
      <VictoryChart {...graph.chart}>
        {typeof graphTitle === "string" ? (
          <VictoryLegend
            {...graph.legend}
            data={[
              {
                name: graphTitle,
                symbol: {
                  fill: graphColor,
                },
              },
            ]}
          />
        ) : null}
        <VictoryAxis {...graph.axis.getX()} />
        <VictoryAxis {...graph.axis.getY({ maxValue })} />
      </VictoryChart>
    </View>
  )
}
