import React from "react"

import { Geo } from "@fhq/app"
import { makeUseDirectionArrow } from "@fhq/app/useDirectionArrow"
import { Polyline as BasePolyline } from "@react-google-maps/api"

import type { PolylineProps } from "@react-google-maps/api"
import type { ArrowLineProps } from "@fhq/app/useDirectionArrow"

const useDirectionArrow = makeUseDirectionArrow(Geo.pointToGmaps)

function ArrowSegment({
  path,
  strokeColor,
}: Pick<PolylineProps, "path"> & {
  strokeColor?: string
}) {
  return (
    <BasePolyline
      path={path}
      options={{
        strokeColor: strokeColor ?? "orange",
        strokeWeight: 3,
        zIndex: 4,
      }}
    />
  )
}
export function ArrowBase(props: {
  arrowLineLeft: google.maps.LatLngLiteral[] | undefined
  arrowLineRight: google.maps.LatLngLiteral[] | undefined
  mainLine: google.maps.LatLngLiteral[] | undefined
  strokeColor?: string
}) {
  if (props.arrowLineLeft && props.arrowLineRight && props.mainLine) {
    return (
      <React.Fragment>
        <ArrowSegment
          path={props.arrowLineLeft}
          strokeColor={props.strokeColor}
        />
        <ArrowSegment
          path={props.arrowLineRight}
          strokeColor={props.strokeColor}
        />
        <ArrowSegment path={props.mainLine} strokeColor={props.strokeColor} />
      </React.Fragment>
    )
  }
  return null
}

/**
 * Display directional arrow on the map
 */
export function Arrow({
  strokeColor,
  ...rest
}: ArrowLineProps & {
  strokeColor?: string
}): React.JSX.Element | null {
  const arrow = useDirectionArrow(rest)
  if (arrow) {
    return <ArrowBase strokeColor={strokeColor} {...arrow} />
  }
  return null
}
