import type { Reducer } from "@reduxjs/toolkit"
import { createSlice, isAnyOf } from "@reduxjs/toolkit"

import { signOutAsync } from "./auth.reducer"
import {
  addDeviceAsync,
  createDeviceShareCodeAsync,
  invalidateDeviceShareCodeAsync,
  loadFarmsBorrowingDeviceAsync,
  removeDeviceFromFarmAsync,
  replaceDeviceAsync,
} from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"

import type {
  DeviceFarmAssociation,
  DeviceShareCode,
  ModelState,
} from "./models"
const adapter = Models.deviceShareCode.adapter

interface DeviceShareCodesState extends ModelState<DeviceShareCode, string> {
  farmsBorrowingDevice: DeviceFarmAssociation[]
}

const initialState: DeviceShareCodesState = {
  ...Models.getInitialEntityState(),
  farmsBorrowingDevice: [],
}

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(loadActiveFarmAsync.fulfilled, (state, { payload }) => {
        adapter.setAll(state, payload.deviceShareCodes)
      })
      .addCase(createDeviceShareCodeAsync.fulfilled, (state, { payload }) => {
        adapter.upsertOne(state, payload)
      })
      .addCase(addDeviceAsync.fulfilled, (state, { meta, payload }) => {
        if (
          payload.status === "VALID" &&
          meta.arg.inputMode === "device_share_code" &&
          payload.deviceShareCode
        ) {
          adapter.upsertOne(state, payload.deviceShareCode)
        }
      })
      .addCase(replaceDeviceAsync.fulfilled, (state, action) => {
        if (action.payload.status === "VALID") {
          if (action.payload.deviceShareCode) {
            adapter.upsertOne(state, action.payload.deviceShareCode)
          }
          adapter.removeOne(state, action.meta.arg.deviceIdOld)
        }
      })
      .addCase(
        invalidateDeviceShareCodeAsync.fulfilled,
        (state, { payload }) => {
          adapter.removeOne(state, payload.deviceId)
        },
      )
      .addCase(
        loadFarmsBorrowingDeviceAsync.fulfilled,
        (state, { payload }) => {
          state.farmsBorrowingDevice = payload.items
        },
      )
      .addCase(removeDeviceFromFarmAsync.fulfilled, (state, { meta }) => {
        adapter.removeOne(state, meta.arg.deviceId)
      })
      .addMatcher(isAnyOf(signOutAsync.fulfilled), () => ({ ...initialState })),
  initialState,
  name: "deviceShareCodes",
  reducers: {},
})

export const deviceShareCodesReducer: Reducer<DeviceShareCodesState> =
  slice.reducer
