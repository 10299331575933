import { useStyle } from "react-native-style-utilities"

import { Card, IconButton, useBreakpointValue } from "@fhq/app/components"
import { SIZES, SPACING, Z_INDEX } from "@fhq/app/components/theme"

import type { CardProps } from "@fhq/app/components"

export function FloatingMapCard({
  id = "floating-map-card",
  onClose,
  ...rest
}: CardProps & {
  onClose?: () => void
}): React.JSX.Element {
  const getValue = useBreakpointValue()
  const right = getValue({ base: 0, md: SPACING.$2 })
  const top = getValue<number | string>({ base: "auto", md: SPACING.$2 })
  const bottom = getValue<number | string>({ base: 0, md: "auto" })
  return (
    <Card
      // {...STYLE_PROPS}
      // {...POSITION_PROPS}
      id={id}
      actionElement={
        onClose ? (
          <IconButton
            IconComponent="Close"
            size="sm"
            variant="text"
            onPress={onClose}
          />
        ) : undefined
      }
      style={useStyle(() => {
        return {
          bottom,
          maxWidth: SIZES.$lg,
          minWidth: SIZES.$xs,
          position: "absolute",
          right,
          top,
          width: "100%",
          zIndex: Z_INDEX.fab,
        }
      }, [bottom, right, top])}
      {...rest}
    />
  )
}
