import React from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { AppText, Box, Button, Divider, Heading, Pressable } from "./components"
import { CreateFarmListItem, JoinFarmListItem } from "./CreateFarmForm"
import { usePrepareDemoButton } from "./PrepareDemoButton"

import type {
  AppTextProps,
  NoChildren,
  ButtonProps,
  AcceptsChildren,
} from "./components"

import type { PrepareDemoButtonProps } from "./PrepareDemoButton"

export function NewFarmLanding({
  onPressCreate,
  onPressJoin,
}: {
  onPressCreate: () => void
  onPressJoin: () => void
}): React.JSX.Element {
  return (
    <View style={{ flex: 1 }}>
      <Pressable onPress={onPressCreate}>
        <CreateFarmListItem />
      </Pressable>
      <Divider />
      <Pressable onPress={onPressJoin}>
        <JoinFarmListItem />
      </Pressable>
    </View>
  )
}

export function JoinFarmInstructions(props: NoChildren<AppTextProps>) {
  const { t } = useTranslation("noFarms")
  return <AppText {...props}>{t("joinFarmDescription")}</AppText>
}
export function CreateFarmInstructions(props: NoChildren<AppTextProps>) {
  const { t } = useTranslation("noFarms")

  return <AppText {...props}>{t("createFarmDescription")}</AppText>
}

export function CreateFarmLinkButton(props: ButtonProps) {
  const { t } = useTranslation("noFarms")

  return (
    <Box my="$4">
      <Button id="create-farm-link" text={t("createFarmLinkTo")} {...props} />
    </Box>
  )
}

export function ListRow({
  children,
  title,
}: AcceptsChildren & {
  title: string | null
}): React.JSX.Element | null {
  return (
    <View style={{ flex: 1 }}>
      {title === null ? null : (
        <Box mb="$2" mr="$2">
          <Heading variant="h4">{title}</Heading>
        </Box>
      )}
      {children}
    </View>
  )
}

export function CreateFarmRow({
  onPress,
}: {
  onPress: () => void
}): React.JSX.Element | null {
  const { t } = useTranslation("noFarms")

  return (
    <ListRow title={t("createFarmLinkTo")}>
      <CreateFarmInstructions flex={1} />
      <CreateFarmLinkButton
        IconComponent="Farm"
        variant="outline"
        onPress={onPress}
      />
    </ListRow>
  )
}
export function LoadDemoRow({
  onSuccess,
  targetDatabaseName,
}: PrepareDemoButtonProps): React.JSX.Element | null {
  const { t } = useTranslation("demoMode")

  const demoButtonProps = usePrepareDemoButton({
    onSuccess,
    targetDatabaseName,
  })
  return (
    <ListRow title={t("tryTheDemo")}>
      <AppText>{t("exploreTheAppInDemoMode")}</AppText>
      <Box my="$2">
        <Button variant="outline" {...demoButtonProps} />
      </Box>
    </ListRow>
  )
}
