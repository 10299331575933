import { useNavigate } from "react-router"

import { Schedules } from "@fhq/app"

import { Help } from "./base/Help"
import PageContainer from "./base/PageContainer"
import { useIsTabFocused } from "./base/useIsTabFocused"
import { usePermissionCheckedAction } from "./base/usePermissionCheckedAction"

/**
 *
 */
export default function DeviceSchedulesListPage() {
  const navigate = useNavigate()
  const onPressNewSchedule = () => navigate("create")
  const { withPermissions } = usePermissionCheckedAction()

  const isFocused = useIsTabFocused()
  return (
    <PageContainer>
      <Schedules.DeviceActionSchedulesSectionList
        HelpComponent={<Help {...Schedules.HELP_CONTENT} />}
        isFocused={isFocused}
        withPermissions={withPermissions}
        onPressNewSchedule={onPressNewSchedule}
      />
    </PageContainer>
  )
}
