import { logger as logging } from "react-native-logs"

import { makeValidator } from "./type-guards"

import type { ValuesType } from "utility-types"

export const LogScopes = {
  CORE: "CORE",
  MOBILE: "MOBILE",
  REDUX: "REDUX",
  WEB: "WEB",
} as const
export type LogScope = ValuesType<typeof LogScopes>
export const LogLevels = {
  DEBUG: "debug",
  ERROR: "error",
  INFO: "info",
  WARN: "warn",
} as const
export const isValidLogLevel = makeValidator(Object.values(LogLevels))

export type LogLevel = ValuesType<typeof LogLevels>
export type LogLevelInput = LogLevel | "DISABLED"

export type LogHandler = (...arguments_: unknown[]) => void
export type LogHandlers = {
  [K in LogLevel]: LogHandler
}
export function getLogLevel({
  logLevel,
  targetDatabaseName,
}: {
  logLevel: string | undefined
  targetDatabaseName: string | null
}): LogLevelInput {
  if (targetDatabaseName === "PROD") {
    return "DISABLED"
  }
  if (isValidLogLevel(logLevel)) {
    return logLevel
  }
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.warn(`Invalid log level received: ${JSON.stringify(logLevel)}`)
  }
  return "debug"
}

const SCOPES = Object.values(LogScopes)
const levels = {
  debug: 0,
  error: 3,
  info: 1,
  warn: 2,
} as const
/*
 * COLORS
 * black	30
 * red	31
 * green	32
 * yellow	33
 * blue	34
 * magenta	35
 * cyan	36
 * white	37
 * grey	90
 * redBright	91
 * greenBright	92
 * yellowBright	93
 * blueBright	94
 * magentaBright	95
 * cyanBright	96
 * whiteBright	97
 */
// interface BaseLoggerReturn extends LogHandlers {
//   disable: (scope?: LogScope) => void
//   enable: (scope?: LogScope) => void
//   extend: (scope: LogScope) => LogHandlers
//   setSeverity: (lvl: LogLevel) => string
// }

export const baseLogger = logging.createLogger<keyof typeof levels>({
  enabled: false,
  enabledExtensions: SCOPES,
  levels,
  transportOptions: {
    colors: {
      debug: "grey",
      error: "red",
      info: "cyan",
      warn: "yellow",
    },
    extensionColors: {
      [LogScopes.CORE]: "grey",
      [LogScopes.REDUX]: "magenta",
      [LogScopes.MOBILE]: "redBright",
      [LogScopes.WEB]: "redBright",
    },
  },
})

export const logger = baseLogger.extend(LogScopes.CORE)
// export const loggerMobile = baseLogger.extend(LogScopes.MOBILE)

export const Loggers: {
  [key in LogScope]: LogHandlers
} = {
  [LogScopes.REDUX]: baseLogger.extend(LogScopes.REDUX),
  [LogScopes.CORE]: logger,
  [LogScopes.MOBILE]: baseLogger.extend(LogScopes.MOBILE),
  [LogScopes.WEB]: baseLogger.extend(LogScopes.WEB),
} as const
export function configureLogging(params: {
  level: LogLevelInput | undefined
}): void {
  if (params.level === "DISABLED") {
    baseLogger.disable()
    for (const scope of SCOPES) {
      baseLogger.disable(scope)
    }
  } else {
    baseLogger.enable()
    if (params.level) {
      baseLogger.setSeverity(params.level)
    }
    for (const scope of SCOPES) {
      baseLogger.enable(scope)
    }
  }
}

export function fileLoggerFactory(scope: LogScope) {
  const base = Loggers[scope]
  return function fileScopedLogger(
    params: string | { fileName: string },
  ): LogHandlers {
    const prefix = `[${
      typeof params === "string" ? params : params.fileName
    }]: `

    const makeHandler = (key: LogLevel): LogHandler => {
      const printMessage = base[key]
      return (...args: unknown[]) => {
        try {
          printMessage(prefix, ...args)
        } catch (error) {
          printMessage(prefix, JSON.stringify(args))
          base.error(error)
        }
      }
    }
    return {
      debug: makeHandler("debug"),
      error: makeHandler("error"),
      info: makeHandler("info"),
      warn: makeHandler("warn"),
    }
  }
}
export const makeFileLogger = fileLoggerFactory("CORE")
export const disableLogging = () => {
  baseLogger.disable(LogScopes.CORE)
  baseLogger.disable(LogScopes.WEB)
  baseLogger.disable(LogScopes.MOBILE)
  baseLogger.disable(LogScopes.REDUX)
  baseLogger.disable()
}
