import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import { useNavigate } from "react-router-dom"

import {
  AppLanguageSelect,
  canSeedTestDatabase,
  getActiveUserEmailFromState,
  getIsAdminModeEnabledFromState,
  getIsDemoModeActiveFromState,
  getIsUserAuthenticatedFromState,
  getUserIsAdminFromState,
  i18n,
  isTruthyString,
  testIds,
  useErrorHandler,
  useIsPending,
  useRootDispatch,
  useRootSelector,
} from "@fhq/app"
import { exitDemoMode, setAdminMode } from "@fhq/app/async-storage"
import { useSignOutButton } from "@fhq/app/Auth"
import {
  AppText,
  Box,
  Divider,
  Heading,
  IconButton,
  Menu,
  MenuItem,
  Pressable,
  Row,
  Switch,
  useThemeProviderContext,
  View,
} from "@fhq/app/components"
import { FarmSvgIcon } from "@fhq/app/components/icons/CustomIcons"
import { COLORS, RADII, SIZES } from "@fhq/app/components/theme"
import { getNumUnreadNotificationsFromState } from "@fhq/app/notifications.reducer"
import { usePrepareDemoButton } from "@fhq/app/PrepareDemoButton"

import { FeedbackDialog } from "../FeedbackDialog"
import { Environment, makeFileLogger } from "./base"
import { MenuItemNavLink } from "./CustomLink"

import type { DashboardPageId } from "@fhq/app"
import type { IconProp } from "@fhq/app/components"
import type { PrepareDemoButtonProps } from "@fhq/app/PrepareDemoButton"

const styles = StyleSheet.create({
  unreadIndicator: {
    backgroundColor: COLORS.$red[600],
    borderRadius: RADII.$full,
    height: SIZES.$3,
    position: "absolute",
    right: 0,
    top: 0,
    width: SIZES.$3,
  },
})

const logger = makeFileLogger("AppbarMenu")

/**
 * Links to main dashboard pages
 */
export function useAppbarPages() {
  const isAdminModeEnabled = useRootSelector(getIsAdminModeEnabledFromState)
  return React.useMemo(() => {
    const pages: Array<{
      IconComponent: IconProp
      children: string
      id: DashboardPageId
      to: string
      isAdminOnly?: boolean
      isDevOnly?: boolean
    }> = [
      {
        IconComponent: "StatusMap",
        children: i18n.t("appbarLinks.home"),
        id: "status-map",
        to: "/",
      },
      {
        IconComponent: "DeviceGeneric",
        children: i18n.t("appbarLinks.devices"),
        id: "devices",
        to: "/devices",
      },
      {
        IconComponent: "DeviceConnection",
        children: i18n.t("appbarLinks.deviceConnections"),
        id: "device-connections",
        to: "/device-connections",
      },
      {
        IconComponent: "Clock",
        children: i18n.t("appbarLinks.schedules"),
        id: "schedules",
        to: "/schedules",
      },
      {
        IconComponent: "Field",
        children: i18n.t("appbarLinks.fields"),
        id: "fields",
        to: "/fields",
      },
      {
        IconComponent: "Account",
        children: i18n.t("appbarLinks.account"),
        id: "account",
        to: "/account",
      },
      {
        IconComponent: "Info",
        children: i18n.t("appbarLinks.support"),
        id: "support",
        to: "/support",
      },
      {
        IconComponent: "Analytics",
        children: i18n.t("appbarLinks.flowAnalytics.short"),
        id: "farm",
        to: "/farm",
      },
    ]

    return pages.filter((page) => {
      if (page.isAdminOnly === true && !isAdminModeEnabled) {
        return false
      }
      if (page.isDevOnly === true && !__DEV__) {
        return false
      }
      return true
    })
  }, [isAdminModeEnabled])
}

/**
 * Enable/disabled admin features
 */
function AdminModeToggle(): React.JSX.Element | null {
  const value = useRootSelector(getIsAdminModeEnabledFromState)
  const isAdminUser = useRootSelector(getUserIsAdminFromState)
  const dispatch = useRootDispatch()
  const { t } = useTranslation("account")
  if (!isAdminUser) {
    return null
  }

  return (
    <Row justifyContent="space-between" py="$2">
      <AppText>{t("adminModeToggle")}</AppText>
      <Switch
        id="admin-mode-switch"
        value={value}
        onValueChange={(nextValue) => {
          dispatch(setAdminMode(nextValue)).catch((e) => {
            logger.error(e)
            throw e
          })
        }}
      />
    </Row>
  )
}

/**
 * Allow user to seed database
 */
function SeedDb({
  onPress,
}: {
  onPress: () => void
}): React.JSX.Element | null {
  const navigate = useNavigate()
  if (!canSeedTestDatabase(Environment.targetDatabaseName)) {
    return null
  }
  let text = "Seed Database"
  if (Environment.targetDatabaseName !== "PROD") {
    text += ` (${Environment.targetDatabaseName})`
  }
  return (
    <Pressable
      trackHover
      trackPress
      onPress={() => {
        onPress()
        navigate("/seed-database")
      }}
    >
      <MenuItem IconComponent="Database" text={text} />
    </Pressable>
  )
}

/**
 * Allow user to toggle between light and dark theme
 */
function DarkModeToggle(): React.JSX.Element {
  const { colorMode, onColorModeChanged } = useThemeProviderContext()
  const { t } = useTranslation("account")
  return (
    <Pressable
      trackHover
      trackPress
      onPress={() =>
        onColorModeChanged(colorMode === "dark" ? "light" : "dark")
      }
    >
      <MenuItem IconComponent="ThemeLightDark" text={t("darkModeToggle")} />
    </Pressable>
  )
}

/**
 * Opens feedback dialog
 */
function ShareFeedback(): React.JSX.Element {
  const { t } = useTranslation("feedback")
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <React.Fragment>
      <FeedbackDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Pressable trackHover trackPress onPress={() => setIsOpen(true)}>
        <MenuItem
          IconComponent="Feedback"
          id="feedback-btn"
          text={t("linkTo")}
        />
      </Pressable>
    </React.Fragment>
  )
}

/**
 * Log out user
 */
function SignOutLink(): React.JSX.Element {
  const navigate = useNavigate()
  const { id, onPress, text } = useSignOutButton({
    onSuccess: () => navigate("/sign-in"),
  })
  const isLoading = useIsPending("signOut")

  return (
    <Pressable trackHover trackPress disabled={isLoading} onPress={onPress}>
      <MenuItem IconComponent="SignOut" id={id} text={text} />
    </Pressable>
  )
}

/**
 * Opens contact form for support tickets
 */
function ContactUs(): React.JSX.Element {
  const navigate = useNavigate()

  return (
    <Pressable
      trackHover
      trackPress
      id="contact-us-link"
      onPress={() => navigate("contact")}
    >
      <MenuItem
        IconComponent="Support"
        text={i18n.t("account:contactUsLink")}
      />
    </Pressable>
  )
}

/**
 * Initialize the demo in prod
 */
function PrepareDemo({
  onSuccess,
}: Pick<PrepareDemoButtonProps, "onSuccess">): React.JSX.Element {
  const { iconName, isLoading, onPress, text } = usePrepareDemoButton({
    onSuccess,
    targetDatabaseName: Environment.targetDatabaseName,
  })
  return (
    <Pressable trackHover trackPress disabled={isLoading} onPress={onPress}>
      <MenuItem IconComponent={iconName} text={text} />
    </Pressable>
  )
}
/**
 * Clear the demo mode
 */
function ExitDemoMode(): React.JSX.Element {
  const { t } = useTranslation("farm")
  const dispatch = useRootDispatch()
  const isLoading = useIsPending("ExitDemoMode")
  const handleError = useErrorHandler()

  return (
    <Pressable
      disabled={isLoading}
      onPress={() => {
        dispatch(exitDemoMode()).catch((error) => {
          handleError(error)
        })
      }}
    >
      <MenuItem text={t("exitDemoButton", { ns: "demoMode" })} />
    </Pressable>
  )
}
export function AppbarMenu({
  pages,
}: {
  pages: ReturnType<typeof useAppbarPages>
}): React.JSX.Element {
  const { t } = useTranslation("account")
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = React.useState(false)
  const handleCloseMenu = () => setIsOpen(false)

  const isUserAuthenticated = useRootSelector(getIsUserAuthenticatedFromState)
  const unreadCount = useRootSelector(getNumUnreadNotificationsFromState)
  const email = useRootSelector(getActiveUserEmailFromState)
  const isAdminUser = useRootSelector(getUserIsAdminFromState)
  const isDemoModeActive = useRootSelector(getIsDemoModeActiveFromState)
  return (
    <View>
      <Box ml="$4" position="relative" rounded="$full">
        <IconButton
          IconComponent={<FarmSvgIcon fill={COLORS.$dark[200]} />}
          bg={COLORS.$primary[500]}
          id="user-account-menu-toggle-btn"
          size="md"
          variant="primary"
          onPress={() => setIsOpen(true)}
        />
        {unreadCount > 0 ? (
          // Indicates user has unread notifications
          <View id="unread-indicator" style={styles.unreadIndicator} />
        ) : null}
      </Box>
      <Menu id="user-account-menu" isOpen={isOpen} onClose={handleCloseMenu}>
        <Box {...testIds("user-email")} pb="$2">
          {isTruthyString(email) ? (
            <AppText isTruncated colorScheme="secondary">
              {email}
            </AppText>
          ) : null}
          {isAdminUser ? (
            <AppText color={COLORS.$tertiary[600]} fontSize="$sm">
              {t("adminUser")}
            </AppText>
          ) : null}
        </Box>
        {isUserAuthenticated ? (
          <React.Fragment>
            <Heading colorScheme="secondary" variant="h6">
              {t("pages", { ns: "common" })}
            </Heading>
            {/* TODO: Make these a collapse which is only expanded in small screens by default */}
            {pages.map((link, index): React.JSX.Element => {
              return (
                <React.Fragment key={link.id}>
                  <MenuItemNavLink
                    IconComponent={link.IconComponent}
                    id={`${link.id}-link`}
                    to={link.to}
                    onClick={handleCloseMenu}
                  >
                    {link.children}
                  </MenuItemNavLink>
                  {index < pages.length - 1 ? <Divider /> : null}
                </React.Fragment>
              )
            })}
          </React.Fragment>
        ) : null}
        <Divider />
        <React.Fragment>
          <Box mt="$4">
            <Heading colorScheme="secondary" variant="h6">
              {t("actions", { ns: "common" })}
            </Heading>
          </Box>
          <ShareFeedback />
          <Divider />
          <ContactUs />
          <Divider />
          <DarkModeToggle />
          <Divider />
          <PrepareDemo onSuccess={() => navigate("/", { replace: true })} />
          <Divider />
          <SeedDb onPress={handleCloseMenu} />
          <Divider />
          <React.Fragment>
            <Box mx="$2" my="$1">
              <AppLanguageSelect />
            </Box>
            <Divider />
          </React.Fragment>
          <AdminModeToggle />
          <SignOutLink />
        </React.Fragment>
        {isDemoModeActive ? <ExitDemoMode /> : null}
      </Menu>
    </View>
  )
}
