import { useTranslation } from "react-i18next"

import { AlertCard, AppText, Box, Divider } from "@fhq/app/components"
import { EmailAddresses, PhoneNumbers } from "@fhq/app/Internal"
import { getIsUserAuthenticatedFromState } from "@fhq/app/selectors"
import { useRootSelector } from "@fhq/app/useRootSelector"

import { AuthLayout } from "./pages/base/AuthLayout"
import CreateSupportTicketPage from "./pages/CreateSupportTicketPage"

const linkStyle: React.CSSProperties = {
  color: "inherit",
  textDecoration: "none",
}

/**
 *
 */
export function ContactFarmHqUnauthenticatedPage() {
  const { t } = useTranslation("support")
  const isAuthenticated = useRootSelector(getIsUserAuthenticatedFromState)
  if (isAuthenticated) {
    return <CreateSupportTicketPage />
  }

  const emailText = `${t("emailUs")} - (${
    EmailAddresses.USER_TECHNICAL_SUPPORT
  })`
  const phoneText = `${t("callUs")} - ${PhoneNumbers.CUSTOMER_SERVICE}`

  return (
    <AuthLayout id="contact-farmhq" title={t("contactUs")}>
      <AlertCard bodyText={t("farmHqIsHereToHelp")} />
      <Box mt="$4">
        <a
          href={`mailto:${EmailAddresses.USER_TECHNICAL_SUPPORT}`}
          style={linkStyle}
        >
          <AppText>{emailText}</AppText>
        </a>
      </Box>
      <Divider my="$4" />
      <a href={`tel:${PhoneNumbers.CUSTOMER_SERVICE}`} style={linkStyle}>
        <AppText>{phoneText}</AppText>
      </a>
    </AuthLayout>
  )
}
