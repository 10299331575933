import React from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { Heading } from "./components/Heading"
import {
  ListItemTextPrimary,
  ListItemTextSecondary,
} from "./components/ListItem"
import { Row } from "./components/Row"
import { useFormatSensorName, useSensorUnitLabel } from "./sensor-formatting"
import { isAdminOnlyField } from "./sensors"

import type { Entry } from "type-fest"
import type { DeviceConfiguration } from "./device-configurations.reducer"
import type { SensorConfigKey, SensorName } from "./sensor-configurations"

/**
 * Display the fields of the given sensor with an edit button
 */
export function SensorConfigValuesList({
  sensorName,
}: {
  sensorName: SensorName
}): React.JSX.Element | null {
  const { t } = useTranslation("sensorFields")
  const { watch } = useFormContext<DeviceConfiguration>()
  const getUnitLabel = useSensorUnitLabel()
  const formatSensorName = useFormatSensorName()
  const props = watch(sensorName)

  if (!props) {
    return null
  }
  const data = Object.entries(props).filter(([key]) => {
    if (isAdminOnlyField(key as SensorConfigKey)) {
      return false
    }
    return true
  })
  return (
    <View>
      <Heading variant="h3">{formatSensorName(sensorName)}</Heading>
      {data.map((item) => {
        const [fieldName, rawValue] = item as Entry<typeof props>
        return (
          <Row
            key={fieldName}
            id={fieldName}
            justifyContent="space-between"
            py="$1"
          >
            <ListItemTextSecondary>
              {t(`${fieldName}.displayName`, { ns: "sensorFields" })}
            </ListItemTextSecondary>
            <ListItemTextPrimary>
              {`${rawValue ?? "None"} ${getUnitLabel(fieldName)}`}
            </ListItemTextPrimary>
          </Row>
        )
      })}
    </View>
  )
}
