import { updatedDiff } from "deep-object-diff"

import { Loggers } from "./logger"

import type { Dispatch, Middleware } from "@reduxjs/toolkit"

import type { RootState } from "./root.reducer"

// eslint-disable-next-line @typescript-eslint/ban-types
export const reduxLogger: Middleware<{}, RootState> =
  (store) => (next: Dispatch) => (action: { type: string }) => {
    const previousState = store.getState()
    const result = next(action)

    Loggers.REDUX.info(action.type)
    if ("payload" in action) {
      Loggers.REDUX.debug("action.payload", action.payload)
    }
    const diff = updatedDiff(previousState, store.getState())
    if (__DEV__ && Object.keys(diff).length > 0) {
      // Loggers.REDUX.info(diff)
    }
    return result
  }
