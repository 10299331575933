import * as Df from "date-fns"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import { createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit"

import { parseCronExpression } from "../../node_modules/cron-schedule/dist/index"
import * as CronUtils from "./cron-utils"
import {
  createDeviceActionScheduleAsync,
  createPumpOnForDurationScheduleAsync,
  deleteScheduledDeviceActionAsync,
  loadDeviceSchedulesAsync,
} from "./farmhq-api"
import * as Models from "./models"
import i18n from "./translations/i18n"
import { isValidNumber } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"

import type { Reducer } from "@reduxjs/toolkit"
import type { DeviceConfiguration } from "./device-configurations.reducer"
export type WeekdaysMap = {
  [key in "0" | "1" | "2" | "3" | "4" | "5" | "6"]: boolean
}
type DeviceActionSchedulesState = Models.ModelState<Models.DeviceActionSchedule>

const INITIAL_STATE: DeviceActionSchedulesState = {
  entities: {},
  ids: [],
}

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(loadDeviceSchedulesAsync.fulfilled, (state, { payload }) => {
        Models.deviceActionSchedules.adapter.setAll(
          state,
          payload.activeDeviceActionSchedules,
        )
      })
      .addCase(
        deleteScheduledDeviceActionAsync.fulfilled,
        (state, { meta }) => {
          Models.deviceActionSchedules.adapter.removeOne(
            state,
            meta.arg.deviceActionScheduleId,
          )
        },
      )
      .addMatcher(
        isAnyOf(
          createDeviceActionScheduleAsync.fulfilled,
          createPumpOnForDurationScheduleAsync.fulfilled,
        ),
        (state, { payload }) => {
          if (payload.status === "ok") {
            Models.deviceActionSchedules.adapter.upsertOne(
              state,
              payload.result,
            )
          }
        },
      ),
  initialState: INITIAL_STATE,
  name: "deviceActionSchedules",
  reducers: {},
})

const deviceActionSchedules: Reducer<DeviceActionSchedulesState> = slice.reducer
export default deviceActionSchedules

const BASE_DATE = new Date("Sun Jun 04 2023")
/**
 * Get weekday display names, like Mon, Tues, Wed based on locale
 */
export function useWeekdayNamesTranslated() {
  const { t } = useTranslation("common")
  return _.range(7).map((el) => {
    const date = Df.addDays(BASE_DATE, el)
    return t("datetimeWithVal", {
      ns: "common",
      val: date,
      weekday: "short",
    })
  })
}
export function useDeleteDeviceSchedule({
  deviceActionScheduleId,
  onSuccess,
}: {
  deviceActionScheduleId: number
  onSuccess?: () => void
}) {
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    deleteScheduledDeviceActionAsync,
  )
  return {
    handleSubmit: () => {
      sendRequest({
        deviceActionScheduleId,
        shouldSyncSchedule: true,
      })
        .then(() => {
          if (onSuccess) {
            onSuccess()
          }
          return toasts.success()
        })
        .catch((error) => handleError(error, { toastMessage: "default" }))
    },
    isLoading,
  }
}

export function useGetWeekdayNamesFromCronString() {
  const daysOfWeek = useWeekdayNamesTranslated()
  return (cronString: string | undefined) => {
    if (typeof cronString === "undefined") {
      return undefined
    }

    const selectedDays = cronString.split(" ")[4]
    if (typeof selectedDays === "undefined") {
      return undefined
    }

    const weekdayIndices = CronUtils.cronToParts(cronString).daysOfWeek

    return weekdayIndices.map((i) => {
      const weekdayString = daysOfWeek[i]
      if (typeof weekdayString === "undefined") {
        throw new TypeError(`Weekday string is undefined at index ${i}`)
      }
      return weekdayString
    })
  }
}

const sortScheduleByStartTime: Models.SortComparator<{
  deviceName: string | null
  executeAtTs: string | null
  startTimeMsDerived: number | null
}> = (a, b) => {
  if (typeof a?.deviceName === "string" && typeof b?.deviceName === "string") {
    return a.deviceName.localeCompare(b.deviceName)
  }

  const startTimeA = a?.startTimeMsDerived
  const startTimeB = b?.startTimeMsDerived
  if (isValidNumber(startTimeA) && isValidNumber(startTimeB)) {
    return startTimeA - startTimeB
  }

  if (typeof a?.executeAtTs === "string") {
    if (typeof b?.executeAtTs === "string") {
      return (
        new Date(a.executeAtTs).getTime() - new Date(b.executeAtTs).getTime()
      )
    }
    return -1
  }
  return 0
}

function generateSchedulesListItem(
  schedule: Models.DeviceActionSchedule,
  action: Models.NamedDeviceAction | null | undefined,
  configuration: DeviceConfiguration | null | undefined,
) {
  // Convert the cronstring into a list of future executions
  // This is used to display the next few executions in the UI
  const cronString = schedule.cronSchedule?.raw
  const futureExecutionsFromCronString =
    typeof cronString === "string"
      ? CronUtils.buildCronExecutionsForWeek({
          baseDate: new Date(),
          cronParts: CronUtils.cronToParts(cronString),
        })
      : undefined

  // calculate duration
  const seconds = action?.arguments?.seconds
  const durationMs =
    typeof seconds === "number" ? Df.secondsToMilliseconds(seconds) : null

  // Get start time to format to hours and minutes
  let startTimeMsDerived: number | undefined
  // For one-off executions, use the timestamp
  if (typeof schedule.executeAtTs === "string") {
    startTimeMsDerived = new Date(schedule.executeAtTs).getTime()
  } else if (futureExecutionsFromCronString) {
    // For repeated executions, we can grab any time
    const [firstDate] = futureExecutionsFromCronString
    if (isValidNumber(firstDate)) {
      startTimeMsDerived = new Date(firstDate).getTime()
    }
  }

  // Check if the end time is on the next day
  // If so, we show an indicator in the UI
  let isNextDay = false
  let actionEndTimeMs: number | undefined

  let scheduleEndTimeMs: number | undefined
  if (typeof schedule.scheduleEnd === "string") {
    scheduleEndTimeMs = new Date(schedule.scheduleEnd).getTime()
  }

  if (isValidNumber(startTimeMsDerived) && isValidNumber(durationMs)) {
    actionEndTimeMs = startTimeMsDerived + durationMs
    const dateStart = new Date(startTimeMsDerived).getDate()
    const dateEnd = new Date(actionEndTimeMs).getDate()
    if (dateStart < dateEnd) {
      isNextDay = true
    }
  }

  return {
    ...schedule,
    actionDisplayName: action?.displayName,
    actionEndTimeMs,
    deviceInstallationType: configuration?.deviceInstallationType,
    deviceName: configuration?.deviceName ?? configuration?.codaDeviceAlias,
    durationMs,
    futureExecutions: futureExecutionsFromCronString,
    isNextDay,
    scheduleEndTimeMs,
    scheduleId: schedule.id,
    startTimeMsDerived,
  }
}

export type DeviceActionScheduleListItem = ReturnType<
  typeof generateSchedulesListItem
>

export const getAllDeviceSchedulesCached = createSelector(
  Models.deviceActionSchedules.selectAll,
  Models.deviceConfiguration.selectEntities,
  Models.namedDeviceAction.selectEntities,
  (schedules, configurations, actions) => {
    return schedules
      .map((schedule) => {
        const configuration = configurations[schedule.deviceId]
        // The action should always be here AFAIK but just in case...
        const action =
          schedule.namedDeviceAction ?? actions[schedule.namedDeviceActionId]

        return generateSchedulesListItem(schedule, action, configuration)
      })
      .sort(sortScheduleByStartTime)
  },
)

function isScheduleExpired(schedule: Models.DeviceActionSchedule) {
  // CRON SCHEDULE
  if (
    typeof schedule.scheduleEnd === "string" &&
    typeof schedule.cronSchedule?.raw === "string"
  ) {
    const cron = parseCronExpression(schedule.cronSchedule.raw)
    const nextExecution = cron.getNextDate()
    if (nextExecution.getTime() > new Date(schedule.scheduleEnd).getTime()) {
      return true
    }
    return false
  }

  // ONE-OFF EXECUTION

  if (typeof schedule.executeAtTs === "string") {
    const executeAtTs = new Date(schedule.executeAtTs)
    if (executeAtTs.getTime() < new Date().getTime()) {
      // The schedule is a one-off execution, and the execution time has passed
      return true
    }
  }
  return false
}
export const getDeviceSchedulesByPastAndFuture = createSelector(
  getAllDeviceSchedulesCached,
  (schedules) => {
    const expired: DeviceActionScheduleListItem[] = []
    const active: DeviceActionScheduleListItem[] = []

    for (const schedule of schedules) {
      if (isScheduleExpired(schedule)) {
        expired.push(schedule)
      } else {
        active.push(schedule)
      }
    }

    const isActiveEmpty = active.length === 0
    const isExpiredEmpty = expired.length === 0

    return [
      {
        data: active,
        id: "active" as const,
        isEmpty: isActiveEmpty,

        title: i18n.t("schedulesActiveTitle", { ns: "schedules" }),
      },
      {
        data: expired,
        id: "expired" as const,
        isEmpty: isExpiredEmpty,

        title: i18n.t("schedulesExpiredTitle", { ns: "schedules" }),
      },
    ]
  },
)
