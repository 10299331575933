import {
  addHours,
  addMonths,
  endOfDay,
  formatDuration,
  getDay,
  hoursToMilliseconds,
  minutesToMilliseconds,
  roundToNearestMinutes,
  startOfDay,
} from "date-fns"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import {
  AlertCard,
  AppText,
  Box,
  Checkbox,
  CloseIconButton,
  Divider,
  FormControl,
  getIconForInstallationType,
  Radio,
  TextWithIcon,
} from "./components"
import { FormError } from "./components/FormError"
import { Row } from "./components/Row"
import { COLORS } from "./components/theme"
import { useToasts } from "./components/useToasts"
import { useWeekdayNamesTranslated } from "./device-action-schedules.reducer"
import { useFormatDeviceSelectOption } from "./device-configurations.reducer"
import {
  createDeviceActionScheduleAsync,
  createPumpOnForDurationScheduleAsync,
  loadDeviceActionsAsync,
} from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import * as Models from "./models"
import {
  canScheduleActions,
  canScheduleDurationToggle,
} from "./named-device-actions.reducer"
import { getFetchStatusByName } from "./selectors"
import i18n from "./translations/i18n"
import { isValidNumber } from "./type-guards"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"
import { objectEntries } from "./utility"

import type { ControllerProps } from "react-hook-form"
import type {
  AcceptsChildren,
  BoxProps,
  HelpContentStatic,
  RadioProps,
} from "./components"
import type { FormControlProviderProps } from "./components/form-control/base"
import type { InputProps } from "./components/form-control/Input"
import type { WeekdaysMap } from "./device-action-schedules.reducer"
function useTexts() {
  return useTranslation("createSchedule")
}
export const HELP_CONTENT: HelpContentStatic = {
  bodyText: i18n.t("createSchedule:createScheduleHelpContent"),
  subject: "create_schedule",
  titleText: i18n.t("createSchedule:createScheduleHelpTitle"),
}

export interface ProviderProps {
  executionTimezone: string | null | undefined
  onCancel: () => void
  onSuccess: () => void
}

// Pump session is only for pump on/off or valve actions
// ONLY THEY can schedule a duration
// other devices have different actions

/**
 * Generates a cron that represents the time on each day of the week
 * that is selected
 */
function generateCronExpression({
  hours,
  minutes,
  weekdays,
}: {
  hours: number
  minutes: number
  weekdays: WeekdaysMap
}) {
  const daysToAdd: Array<keyof WeekdaysMap> = []
  objectEntries(weekdays).forEach(([key, value]) => {
    if (value) {
      daysToAdd.push(key)
    }
  })
  return `${minutes} ${hours} * * ${daysToAdd.join(",")}` as const
}
export interface FormValues {
  deviceId: string
  durationHours: number
  durationMinutes: number
  executionTimeMs: number
  namedDeviceActionId: number | undefined
  repeatOnDays: WeekdaysMap
  scheduleEndMs: number
  scheduleStartMs: number
  startTime: {
    day: number
    hours: number
    minutes: number
  }
}

const MIN_DURATION_MINUTES = 0

function getError({
  devices,
  formValues: {
    durationHours,
    durationMinutes,
    executionTimeMs,
    repeatOnDays,
    scheduleEndMs,
    scheduleStartMs,
  },
  isRepeating,
}: {
  devices: unknown[] | undefined
  formValues: FormValues
  isRepeating: boolean
}) {
  let formErrorMessage: string | undefined
  let errorCode
  if (devices && devices.length === 0) {
    errorCode = "NO_COMPATIBLE_DEVICES"
  } else if (isRepeating) {
    if (
      Object.values(repeatOnDays).every((value) => {
        return !value
      })
    ) {
      errorCode = "NO_REPEATING_DAYS"
    } else if (scheduleStartMs < startOfDay(new Date()).getTime()) {
      formErrorMessage = i18n.t("errorMessages.SCHEDULE_START_IN_PAST", {
        ns: "createSchedule",
      })
      errorCode = "SCHEDULE_START_IN_PAST"
    } else if (scheduleEndMs < endOfDay(new Date()).getTime()) {
      formErrorMessage = i18n.t("errorMessages.SCHEDULE_END_IN_PAST", {
        ns: "createSchedule",
      })
      errorCode = "SCHEDULE_END_IN_PAST"
    } else if (scheduleEndMs < scheduleStartMs) {
      formErrorMessage = i18n.t("errorMessages.SCHEDULE_END_BEFORE_START", {
        ns: "createSchedule",
      })
      errorCode = "SCHEDULE_END_BEFORE_START"
    }
  } else if (executionTimeMs <= new Date().getTime()) {
    errorCode = "EXECUTION_TIME_IN_PAST"
    formErrorMessage = i18n.t("errorMessages.EXECUTION_TIME_IN_PAST", {
      ns: "createSchedule",
    })
  } else if (durationMinutes <= MIN_DURATION_MINUTES && durationHours <= 0) {
    errorCode = "DURATION_BELOW_MINIMUM"
  }
  return {
    errorCode,
    formErrorMessage,
  }
}

/**
 * Select should either be a pre-defined action or a pump session
 */
type ActionOption = Models.NamedDeviceAction | { id: "PUMPING_SESSION" }

function useCreateDeviceSchedule({
  executionTimezone = "",
  onCancel,
  onSuccess,
  ...rest
}: ProviderProps) {
  const { t } = useTranslation("createSchedule")

  const formatOption = useFormatDeviceSelectOption()
  const [stage, setStage] = React.useState<"device" | "timing">("device")

  const devices = useShallowEqualSelector((state) => {
    return Models.deviceConfiguration
      .selectAll(state)
      .filter((device) => {
        return canScheduleActions(device.deviceInstallationType)
      })
      .map((dc) => {
        return {
          key: dc.deviceId,
          label: formatOption(dc),
          value: dc.deviceId,
        }
      })
  })

  const [namedDeviceActions, setNamedDeviceActions] = React.useState<
    ActionOption[]
  >([])
  const [isRepeating, setIsRepeating] = React.useState(true)
  const handleError = useErrorHandler()
  const toasts = useToasts()
  const dispatch = useRootDispatch()

  const currentDay = getDay(new Date())

  const defaultDays = {
    "0": false,
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false,
    "6": false,
  }
  if (typeof currentDay === "number") {
    defaultDays[`${currentDay}`] = true
  }
  const form = useForm<FormValues>({
    defaultValues: {
      deviceId: devices[1]?.value ?? "",
      durationHours: 4,
      durationMinutes: 0,
      executionTimeMs: roundToNearestMinutes(addHours(new Date(), 3)).getTime(),
      repeatOnDays: defaultDays,
      scheduleEndMs: endOfDay(addMonths(new Date(), 1)).getTime(),
      scheduleStartMs: startOfDay(new Date()).getTime(),
      startTime: {
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
      },
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  })

  const displayName = t("turnOfThePumpForWithVal", {
    val:
      (formatDuration({
        hours: form.watch("durationHours"),
        minutes: form.watch("durationMinutes"),
      }) as string | undefined) ?? "",
  })

  const { errorCode, formErrorMessage } = getError({
    devices,
    formValues: form.watch(),
    isRepeating,
  })

  /**
   * Load actions for the selected device
   */
  const selectedDeviceId = form.watch("deviceId")
  const selectedDeviceConfiguration = useRootSelector((state) => {
    return Models.deviceConfiguration.selectById(state, selectedDeviceId)
  })
  const canDeviceScheduleDurationToggle = Boolean(
    selectedDeviceConfiguration &&
      canScheduleDurationToggle(
        selectedDeviceConfiguration.deviceInstallationType,
      ),
  )

  React.useEffect(() => {
    if (typeof selectedDeviceId === "string" && selectedDeviceId.length > 0) {
      dispatch(
        loadDeviceActionsAsync({
          deviceId: selectedDeviceId,
        }),
      )
        .unwrap()
        .then((result) => {
          const defaultActionId = result.items[0]?.id
          if (typeof defaultActionId === "number") {
            form.setValue("namedDeviceActionId", defaultActionId)
          }
          if (canDeviceScheduleDurationToggle) {
            const items: ActionOption[] = [...result.items]
            // We add an arbitrary option here to allow the user to fill
            // out the duration fields if applicable
            items.unshift({
              id: "PUMPING_SESSION",
            })

            return setNamedDeviceActions(items)
          }
          return setNamedDeviceActions(result.items)
        })
        .catch((error) => {
          handleError(error)
        })
    }
  }, [
    canDeviceScheduleDurationToggle,
    dispatch,
    form,
    handleError,
    selectedDeviceId,
  ])

  /**
   * If timezone is available, include it in the date. Otherwise, skip it (this avoids empty parentheses)
   */
  const isTimezoneValid = typeof executionTimezone === "string"

  const formatTimeWithTimezone = (val: Date | number) => {
    let timeTranslationKey: `datetimeWithVal${"" | "AndTimezone"}` =
      "datetimeWithVal"

    const timeFormatProps: { [key: string]: Date | number | string } = {
      hour: "numeric",
      minute: "numeric",
      ns: "common",
      val,
    }

    if (isTimezoneValid) {
      // Add timezone to text if possible
      timeTranslationKey = "datetimeWithValAndTimezone"
      timeFormatProps.timezone = executionTimezone
    }
    return t(timeTranslationKey, { ...timeFormatProps })
  }

  const selectedActionId = form.watch("namedDeviceActionId")

  const selectedAction = namedDeviceActions.find(
    (action) => action.id === selectedActionId,
  )

  const isSchedulingPumpingSession = selectedAction?.id === "PUMPING_SESSION"

  const onSubmit = form.handleSubmit((values) => {
    if (stage === "device") {
      return setStage("timing")
    }
    // Calculate the duration in milliseconds
    const durationMs =
      hoursToMilliseconds(values.durationHours) +
      minutesToMilliseconds(values.durationMinutes)

    if (typeof executionTimezone === "undefined") {
      throw new TypeError(`In CreateScheduleForm: User timezone is undefined`)
    }
    if (typeof values.executionTimeMs === "undefined") {
      throw new TypeError(`Named device action ID is undefined`)
    }
    let actionArguments
    if (isRepeating) {
      // Create a schedule with a repeating cron
      actionArguments = {
        cronScheduleRaw: generateCronExpression({
          hours: values.startTime.hours,
          minutes: values.startTime.minutes,
          weekdays: values.repeatOnDays,
        }),
        deviceId: values.deviceId,
        executionTimezone,
        scheduleEndMs: values.scheduleEndMs,
        scheduleStartMs: values.scheduleStartMs,
      }
    } else {
      // No cron;
      actionArguments = {
        deviceId: values.deviceId,
        executionTimeMs: values.executionTimeMs,
        executionTimezone,
      }
    }

    const handleSuccess = () => {
      toasts.success()
      return onSuccess()
    }
    if (isSchedulingPumpingSession) {
      dispatch(
        createPumpOnForDurationScheduleAsync({
          ...actionArguments,
          displayName,
          durationMs,
        }),
      )
        .unwrap()
        .then(handleSuccess)
        .catch((error) => {
          handleError(error, {
            toastMessage: "default",
          })
        })
    } else {
      if (typeof values.namedDeviceActionId === "undefined") {
        throw new TypeError("No action selected")
      }
      if (!isTimezoneValid) {
        throw new TypeError(`User timezone is undefined`)
      }
      dispatch(
        createDeviceActionScheduleAsync({
          ...actionArguments,
          executionTimezone,
          isEnabled: true,
          namedDeviceActionId: values.namedDeviceActionId,
          shouldSyncSchedule: true,
        }),
      )
        .unwrap()
        .then(handleSuccess)
        .catch((error) => {
          handleError(error, {
            toastMessage: "default",
          })
        })
    }
    return undefined
  })
  const isLoading = useRootSelector((state) => {
    return (
      getFetchStatusByName(
        state,
        isSchedulingPumpingSession
          ? "CreatePumpOnForDurationSchedule"
          : "CreateDeviceActionSchedule",
      ) === "pending"
    )
  })
  const handleCancel = () => {
    return onCancel()
  }
  let cancelText: string = t("cancel")
  let submitText: string = t("next")
  if (stage === "timing") {
    onCancel = () => {
      form.reset()
      return setStage("device")
    }
    cancelText = t("back")
    submitText = t("next")
  }

  return {
    cancelText,
    devices,
    displayName,
    errorCode,
    executionTimezone,
    form,
    formErrorMessage,
    formatTimeWithTimezone,
    isLoading,
    isPumpOnForDurationAction: isSchedulingPumpingSession,
    isRepeating,
    isSubmitDisabled: isLoading || Boolean(errorCode),
    namedDeviceActions,
    onCancel: handleCancel,
    onSubmit,
    selectedAction,
    selectedDeviceConfiguration,
    setIsRepeating,
    stage,
    submitText,
    ...rest,
  }
}

type ContextValue = ReturnType<typeof useCreateDeviceSchedule>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useCreateDeviceSchedule(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(
      `CreateSchedule Context must be used inside of provider`,
    )
  }
  return ctx
}

/**
 *
 */
export function DeviceSelect(
  props: FormControlProviderProps & {
    onNoCompatibleDevices: () => void
  },
) {
  const { devices, errorCode, form, isLoading } = useContext()
  const { t } = useTexts()
  const { required } = useFormValidation()
  const noCompatibleDevices = devices.length === 0
  const labelText: string = t("deviceSelectLabel")
  const placeholderText: string = t("deviceSelectPlaceholder")
  return (
    <Controller
      control={form.control}
      name="deviceId"
      rules={{ required }}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)
        return (
          <FormControl.Provider
            {...props}
            isDisabled={isLoading}
            isInvalid={isInvalid}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Select
              label={labelText}
              options={devices}
              placeholder={placeholderText}
              selectedValue={field.value}
              onFocusWhenEmptyNative={props.onNoCompatibleDevices}
              onValueChange={field.onChange}
              onFocus={
                noCompatibleDevices ? props.onNoCompatibleDevices : undefined
              }
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
            <FormError>
              {errorCode === "NO_COMPATIBLE_DEVICES"
                ? t("errorMessages.NO_COMPATIBLE_DEVICES")
                : undefined}
            </FormError>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
function handleNumericSelectInput(nextValue: string): number | string {
  const asNumber = Number.parseInt(nextValue)
  if (isValidNumber(asNumber)) {
    return asNumber
  }
  return nextValue
}

/**
 *
 */
export function ActionSelect() {
  const { form, namedDeviceActions } = useContext()
  const { required } = useFormValidation()
  const { t } = useTexts()
  const labelText = t("actionSelectLabel")
  return (
    <Controller
      control={form.control}
      name="namedDeviceActionId"
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider isInvalid={Boolean(errorMessage)}>
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Select
              placeholder="(Select an action)"
              selectedValue={`${field.value ?? ""}`}
              options={namedDeviceActions.map((action) => {
                if (action.id === "PUMPING_SESSION") {
                  return {
                    label: "Schedule Pumping Session",
                    value: "PUMPING_SESSION",
                  }
                }
                return {
                  label: action.displayName ?? `${action.id}`,
                  value: `${action.id}`,
                }
              })}
              onValueChange={(nextValue) => {
                field.onChange(handleNumericSelectInput(nextValue))
              }}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
      rules={{
        required,
      }}
    />
  )
}

/**
 *
 */
function useDurationInput(): {
  handleInput: (nextValue: string) => number | string
  inputProps: InputProps
  rules: ControllerProps["rules"]
} {
  const { integerPattern, required } = useFormValidation()
  const { t } = useTranslation()
  return {
    handleInput: handleNumericSelectInput,
    inputProps: {
      keyboardType: "number-pad",
      returnKeyLabel: t("done"),
      returnKeyType: "done",
    },
    rules: {
      pattern: integerPattern,
      required,
    },
  }
}

/**
 *
 */
export function DurationHoursInput(props: FormControlProviderProps) {
  const { form, isLoading } = useContext()
  const { handleInput, inputProps, rules } = useDurationInput()
  const { t } = useTexts()

  const labelText: string = t("durationHoursLabel")
  return (
    <Controller
      control={form.control}
      name="durationHours"
      render={({ field: { onChange, value, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider
            isDisabled={isLoading}
            isInvalid={Boolean(errorMessage)}
            {...props}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Input
              {...inputProps}
              {...field}
              placeholder={labelText}
              value={`${value}`}
              onChangeText={(nextValue) => onChange(handleInput(nextValue))}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
      rules={{
        ...rules,
        validate: (value) => {
          if (value === 0 && form.watch("durationMinutes") === 0) {
            return "Duration must be greater than 0"
          }
          return true
        },
      }}
    />
  )
}

/**
 *
 */
export function DurationMinutesInput(props: FormControlProviderProps) {
  const { form, isLoading } = useContext()
  const { handleInput, inputProps, rules } = useDurationInput()
  const { t } = useTexts()
  const labelText: string = t("durationMinutesLabel")
  return (
    <Controller
      control={form.control}
      name="durationMinutes"
      rules={rules}
      render={({ field: { onChange, value, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider
            isDisabled={isLoading}
            isInvalid={Boolean(errorMessage)}
            {...props}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Input
              {...inputProps}
              {...field}
              placeholder={labelText}
              value={`${value}`}
              onChangeText={(nextValue) => onChange(handleInput(nextValue))}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
export function WeekdaysCheckbox(props: BoxProps) {
  const weekdays = useWeekdayNamesTranslated()
  const { errorCode, form } = useContext()
  const { t } = useTexts()

  let errorMessage: string | undefined
  if (errorCode === "NO_REPEATING_DAYS") {
    errorMessage = t("required", { ns: "validation" })
  }
  const labelText: string = t("repeatOnDays")
  return (
    <Box {...props}>
      <FormControl.Label>{labelText}</FormControl.Label>
      <Row>
        {weekdays.map((dayName, index) => {
          return (
            <Box
              key={dayName}
              my="$1"
              px="$3"
              py="$1"
              w="$28"
              {...(index < weekdays.length - 1
                ? {
                    borderRightColor: COLORS.$divider.light,
                    borderRightWidth: 1,
                  }
                : undefined)}
            >
              <Controller
                control={form.control}
                name={`repeatOnDays.${`${index}` as keyof WeekdaysMap}`}
                render={({ field: { onChange, value } }) => (
                  <Row
                    key={dayName}
                    flexWrap="nowrap"
                    justifyContent="space-between"
                  >
                    <AppText>{dayName}</AppText>
                    <Box>
                      <Checkbox
                        key={dayName}
                        isChecked={value}
                        isInvalid={Boolean(errorMessage)}
                        onChange={() => onChange(!value)}
                      />
                    </Box>
                  </Row>
                )}
              />
            </Box>
          )
        })}
      </Row>
      <FormError>{errorMessage}</FormError>
    </Box>
  )
}

/**
 *
 */
export function UsageWarning(): React.JSX.Element | null {
  const { t } = useTexts()

  const [isOpen, setIsOpen] = React.useState(true)
  if (isOpen) {
    return (
      <AlertCard
        IconComponent={null}
        actionElement={<CloseIconButton onPress={() => setIsOpen(false)} />}
        bodyText={t("usageWarning")}
        mt="$4"
        severity="warning"
        titleText={t("noteWithColon", { ns: "common" })}
      />
    )
  }
  return null
}
/**
 * User chooses frequency, e.g. 'once' or 'repeating'
 */
export function FrequencyRadio({
  orientation = "horizontal",
  ...rest
}: BoxProps & Pick<RadioProps, "orientation">): React.JSX.Element {
  const { isLoading, isRepeating, setIsRepeating } = useContext()
  const { t } = useTexts()
  // const size = "md"
  const handleChange = (nextValue: string) => {
    setIsRepeating(nextValue === "true")
  }
  const labelText: string = t("frequencyLabel")
  return (
    <Box {...rest}>
      <FormControl.Label style={{ marginRight: "auto" }}>
        {labelText}
      </FormControl.Label>
      <Radio
        aria-labelledby="frequency"
        isDisabled={isLoading}
        orientation={orientation}
        selectedValue={isRepeating ? "true" : "false"}
        options={[
          {
            label: t("scheduleFrequencyTypes.doOnce"),
            value: "false",
          },
          {
            label: t("scheduleFrequencyTypes.repeat"),
            value: "true",
          },
        ]}
        onChange={handleChange}
      />
    </Box>
  )
}
/**
 *
 */
export function DurationFields() {
  const { errorCode } = useContext()
  const { t } = useTexts()

  const isDurationInvalid = errorCode === "DURATION_BELOW_MINIMUM"
  return (
    <React.Fragment>
      <AppText>{t("turnOffThePumpAfter")}</AppText>
      <Row>
        <Box flex={1} mr="$1">
          <DurationHoursInput flex={1} isInvalid={isDurationInvalid} />
        </Box>
        <Box flex={1} ml="$1">
          <DurationMinutesInput flex={1} isInvalid={isDurationInvalid} />
        </Box>
      </Row>
      <FormError>
        {isDurationInvalid
          ? t("errorMessages.DURATION_BELOW_MINIMUM")
          : undefined}
      </FormError>
    </React.Fragment>
  )
}

export function CurrentSelections() {
  const { selectedAction, selectedDeviceConfiguration } = useContext()
  const { t } = useTexts()
  const formatDevice = useFormatDeviceSelectOption()
  let selectedActionDisplayText: string | null | undefined
  if (selectedAction?.id === "PUMPING_SESSION") {
    selectedActionDisplayText = t("schedulePumpingSession")
  } else if (selectedAction) {
    selectedActionDisplayText = selectedAction.displayName
  }

  return (
    <React.Fragment>
      {selectedDeviceConfiguration ? (
        <TextWithIcon
          text={formatDevice(selectedDeviceConfiguration)}
          IconComponent={getIconForInstallationType(
            selectedDeviceConfiguration.deviceInstallationType,
          )}
        />
      ) : null}
      {typeof selectedActionDisplayText === "string" ? (
        <React.Fragment>
          <TextWithIcon
            IconComponent="DeviceCommandsRemote"
            _text={{ colorScheme: "secondary" }}
            my="$2"
            text={selectedActionDisplayText}
          />
          <Divider />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export function Actions() {
  const {
    cancelText,
    isLoading,
    isSubmitDisabled,
    onCancel,
    onSubmit,
    submitText,
  } = useContext()
  return (
    <ActionButtons
      cancelText={cancelText}
      isLoading={isLoading}
      isSubmitDisabled={isSubmitDisabled}
      submitText={submitText}
      onPressCancel={onCancel}
      onPressSubmit={onSubmit}
    />
  )
}
