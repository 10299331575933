import React from "react"
import { useTranslation } from "react-i18next"

import { AlertBodyText, AlertCard, Box, IconButton } from "./components"
import { COLORS } from "./components/theme"

/**
 * This should be displayed when users are updating a configuration value,
 * including sensors, device names, installation types etc.
 */
export function UpdateConfigurationWarning() {
  const [isOpen, setIsOpen] = React.useState(true)
  const { t } = useTranslation("deviceConfiguration")
  const titleText = t("warning", { ns: "common" })
  if (isOpen) {
    const actionElement = (
      <IconButton
        IconComponent="Close"
        iconColor={COLORS.$textDark.primary}
        size="sm"
        variant="text"
        onPress={() => setIsOpen(false)}
      />
    )
    return (
      <AlertCard
        actionElement={actionElement}
        maxW="$full"
        severity="warning"
        titleText={titleText}
        w="$full"
      >
        <Box mt="$1" my="$2">
          <AlertBodyText fontSize="$sm">
            {t("updateConfigurationSwitchOnToOffWarning")}
          </AlertBodyText>
        </Box>
        <AlertBodyText fontSize="$sm">
          {t("updateConfigurationDataLossWarning")}
        </AlertBodyText>
      </AlertCard>
    )
  }
  return null
}
