import React from "react"
import { View } from "react-native"

import {
  Box,
  Divider,
  Heading,
  ListItemTextPrimary,
  Row,
  useIsDarkMode,
} from "@fhq/app/components"
import { COLORS } from "@fhq/app/components/theme"

import type {
  AppTextProps,
  BoxProps,
  HeadingProps,
  NoChildren,
} from "@fhq/app/components"
export interface TableRowProps {
  rowData: Array<number | string>
  rowTitle: string
  showDivider?: boolean
}

const LEFT_TEXT_PROPS: Pick<BoxProps, "minW"> = {
  minW: "$16",
} as const

const TEXT_PROPS: NoChildren<AppTextProps> = {
  fontSize: "$sm",
}

function DividerVertical() {
  const isDark = useIsDarkMode()
  return (
    <View
      style={{
        backgroundColor: isDark ? COLORS.$divider.dark : COLORS.$divider.light,
        height: 20,
        width: 1,
      }}
    />
  )
}

function TableRow({ rowData, rowTitle, showDivider = false }: TableRowProps) {
  return (
    <Row
      flexWrap="nowrap"
      justifyContent="space-between"
      py="$2"
      w="$full"
      {...(showDivider
        ? {
            borderBottomColor: COLORS.$divider.light,
            borderBottomWidth: 1,
          }
        : undefined)}
    >
      <Box mr="$2">
        <ListItemTextPrimary
          {...LEFT_TEXT_PROPS}
          {...TEXT_PROPS}
          flex={1}
          fontWeight="normal"
        >
          {rowTitle}
        </ListItemTextPrimary>
      </Box>
      {rowData.map((text, index) => {
        return (
          <Box key={index} mr="$2">
            <DividerVertical />
            <ListItemTextPrimary {...TEXT_PROPS} flex={1} textAlign="right">
              {text}
            </ListItemTextPrimary>
          </Box>
        )
      })}
    </Row>
  )
}

interface ColumnProps {
  columnTitle: string
  columnAction?: React.JSX.Element
}

interface TableHeadingProps {
  tableTitle: string
  _headingProps?: NoChildren<HeadingProps>
  columns?: ColumnProps[]
}

const HEADING_PROPS: NoChildren<HeadingProps> = {
  fontSize: "$md",
}

function TableHeading({
  _headingProps,
  columns,
  tableTitle,
}: TableHeadingProps) {
  return (
    <Row flexWrap="nowrap" w="$full">
      <Box {...LEFT_TEXT_PROPS} mr="$2">
        <Heading
          colorScheme="secondary"
          flex={1}
          {...HEADING_PROPS}
          {..._headingProps}
        >
          {tableTitle}
        </Heading>
      </Box>
      {columns?.map(({ columnAction, columnTitle }) => {
        return (
          <React.Fragment key={columnTitle}>
            <DividerVertical />
            <Row
              alignItems="center"
              flex={1}
              justifyContent="flex-end"
              ml="auto"
            >
              {columnAction}
              <Box ml="$1">
                <Heading
                  colorScheme="secondary"
                  textAlign="right"
                  {...HEADING_PROPS}
                  {..._headingProps}
                >
                  {columnTitle}
                </Heading>
              </Box>
            </Row>
          </React.Fragment>
        )
      })}
    </Row>
  )
}

interface TableProps extends TableHeadingProps {
  rows: TableRowProps[]
  tableTitle: string
  columns?: ColumnProps[]
}

export function Table({
  _headingProps,
  columns,
  rows,
  tableTitle,
}: TableProps) {
  return (
    <React.Fragment>
      <TableHeading
        _headingProps={_headingProps}
        columns={columns}
        tableTitle={tableTitle}
      />
      <Box my="$1">
        <Divider />
      </Box>
      {rows.map((row, index) => {
        return (
          <TableRow
            key={row.rowTitle}
            rowData={row.rowData}
            rowTitle={row.rowTitle}
            showDivider={index < rows.length - 1}
          />
        )
      })}
    </React.Fragment>
  )
}
