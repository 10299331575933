import * as Linking from "expo-linking"
import React from "react"
import { Platform } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { logger } from "../logger"
import { Pressable } from "./Pressable"
import { testIds } from "./test-id"
import { AppText } from "./Text"
import { COLORS } from "./theme"
import { useIsDarkMode } from "./ThemeProvider"

import type { TestId } from "./test-id"
import type { AppTextProps } from "./Text"
export interface LinkProps extends React.PropsWithChildren {
  id?: TestId
  isDisabled?: boolean
  onPress?: () => void
  text?: string
  to?: string
}

/**
 * A component that displays a link.
 */
export function LinkText({ children, style, ...rest }: AppTextProps) {
  const isDark = useIsDarkMode()

  return (
    <AppText
      accessibilityRole="link"
      style={useStyle(() => {
        return [
          {
            color: isDark ? COLORS.$link.dark : COLORS.$link.light,
          },
          style,
        ]
      }, [isDark, style])}
      {...rest}
    >
      {children}
    </AppText>
  )
}

/**
 * A component that displays a link.
 */
function LinkMobile(props: LinkProps) {
  const children = props.children ?? props.text
  const isDisabled = props.isDisabled
  return (
    <Pressable
      accessibilityRole="link"
      {...testIds(props.id)}
      accessibilityState={{ disabled: isDisabled }}
      disabled={isDisabled}
      onPress={() => {
        if (props.onPress) {
          props.onPress()
        }
        if (typeof props.to === "string") {
          Linking.openURL(props.to).catch((err) =>
            logger.error("An error occurred", err),
          )
        }
      }}
    >
      {typeof children === "string" ? (
        <LinkText>{children}</LinkText>
      ) : (
        children
      )}
    </Pressable>
  )
}

const WEB_STYLE: React.CSSProperties = {
  cursor: "pointer",
}

/**
 * A component that displays a link.
 */
function LinkWeb(props: LinkProps) {
  return (
    <a
      aria-disabled={props.isDisabled}
      href={props.to}
      style={WEB_STYLE}
      onClick={props.onPress}
    >
      {typeof props.text === "string" ? (
        <LinkText {...testIds(props.id)}>{props.text}</LinkText>
      ) : (
        props.children
      )}
    </a>
  )
}
export function Link(props: LinkProps) {
  const Component = Platform.OS === "web" ? LinkWeb : LinkMobile
  return <Component {...props} />
}
