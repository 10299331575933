import type { Dictionary, Reducer } from "@reduxjs/toolkit"
import { createCachedSelector } from "re-reselect"

import { createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit"

import { signOutAsync } from "./auth.reducer"
import {
  addDeviceAsync,
  loadDeviceActivityAsync,
  loadLastEventForDeviceAsync,
  removeDeviceFromFarmAsync,
  replaceDeviceAsync,
} from "./farmhq-api"
import { Urls } from "./Internal"
import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"
import {
  calculateReelRunEta,
  calculateReelRunStatus,
  calculateRunCompletion,
} from "./reel-runs.reducer"
import {
  findFieldForDevice,
  getDeviceSortDirectionFromState,
  getDeviceSortKeyFromState,
  getIsAdminModeEnabledFromState,
  getUserMeasurementPreferenceFromState,
  makeDeviceEventCacheKey,
  makeDeviceSummary,
  makeLocationValidator,
} from "./selectors"
import { isDeviceInMotion } from "./sensor-configurations"
import { formatSensorValue, getSensorUnitLabel } from "./sensor-formatting"
import {
  sortByDeviceInstallationType,
  sortByDeviceName,
  sortDeviceStatusByUrgency,
} from "./sort-device-status"
import i18n from "./translations/i18n"
import { isTruthyString, isValidNumber } from "./type-guards"
import { getActiveFarmCoordinatesFromState } from "./user-farms.selectors"

import type { SetDifference } from "utility-types"
import type { BadgeProps, NoChildren } from "./components"
import type { IconKey } from "./components/icons/AppIcons"
import type { TestId } from "./components/test-id"
import type { ColorSchemeType } from "./components/theme"
import type { InstallationType, SensorName } from "./sensor-configurations"
import type { SensorEvent, SensorEventKey, SensorState } from "./sensor-events"
import type { VfdIndicatorProps } from "./VfdIndicator"

import type * as Geo from "./geo"
import type {
  MeasurementPreference,
  MeasurementPreferenceProps,
} from "./sensor-conversions"

import type {
  DeviceConfiguration,
  DeviceSummary,
} from "./device-configurations.reducer"

import type { DeviceEvent, ModelState } from "./models"
import type {
  ReelRunStatus,
  RunCompletion,
  RunEtaParams,
} from "./reel-runs.reducer"
import type { RootState } from "./root.reducer"
export interface StatusBadgeProps extends NoChildren<BadgeProps> {
  iconName: IconKey
  id: TestId
  text: string | undefined
}

type DeviceEventLastState = ModelState<DeviceEvent, string>

const adapter = Models.deviceEventLast.adapter
const initialState: DeviceEventLastState = Models.getInitialEntityState()

// // TODO:DELETE
// export const TEST_VFD_EVENTS: Array<SensorEvent<"vfd">> = [
//   {
//     currentPercentage: null,
//     deviceFunctionCall: null,
//     pidDerivativeCoeff: null,
//     pidGainCoeff: null,
//     pidIntegralCoeff: null,
//     pidSensorSetpoint: null,
//     rampSecondsRemaining: null,
//     rampSecondsTotal: null,
//     scheduledDeviceAction: null,
//     sensorTriggeredUpdate: false,
//     startRampPercentage: null,
//     stateCurrent: "VFDOF",
//     stateCurrentInitDt: new Date().toUTCString(),
//     statePrevious: "VFDOF",
//     statePreviousInitDt: new Date().toUTCString(),
//     targetRampPercentage: null,
//   },
//   {
//     currentPercentage: 75,
//     deviceFunctionCall: null,
//     pidDerivativeCoeff: null,
//     pidGainCoeff: null,
//     pidIntegralCoeff: null,
//     pidSensorSetpoint: null,
//     rampSecondsRemaining: null,
//     rampSecondsTotal: null,
//     scheduledDeviceAction: null,
//     sensorTriggeredUpdate: false,
//     startRampPercentage: null,
//     stateCurrent: "VFDST",
//     stateCurrentInitDt: new Date().toUTCString(),
//     statePrevious: "VFDOF",
//     statePreviousInitDt: new Date().toUTCString(),
//     targetRampPercentage: null,
//   },
//   {
//     currentPercentage: 25,
//     deviceFunctionCall: null,
//     pidDerivativeCoeff: null,
//     pidGainCoeff: null,
//     pidIntegralCoeff: null,
//     pidSensorSetpoint: null,
//     rampSecondsRemaining: null,
//     rampSecondsTotal: null,
//     scheduledDeviceAction: null,
//     sensorTriggeredUpdate: false,
//     startRampPercentage: null,
//     stateCurrent: "VFDRP",
//     stateCurrentInitDt: new Date().toUTCString(),
//     statePrevious: "VFDOF",
//     statePreviousInitDt: new Date().toUTCString(),
//     targetRampPercentage: 60,
//   },
// ]
const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(loadLastEventForDeviceAsync.fulfilled, (state, { payload }) => {
        if (payload.event) {
          adapter.upsertOne(state, payload.event)
        }
      })
      .addCase(removeDeviceFromFarmAsync.fulfilled, (state, { meta }) => {
        adapter.removeOne(state, meta.arg.deviceId)
      })
      .addCase(addDeviceAsync.fulfilled, (state, { payload }) => {
        if (payload.status === "VALID" && payload.deviceEvent) {
          adapter.upsertOne(state, payload.deviceEvent)
        }
      })
      .addCase(replaceDeviceAsync.fulfilled, (state, { meta, payload }) => {
        if (payload.status === "VALID") {
          if (payload.deviceEvent) {
            adapter.upsertOne(state, payload.deviceEvent)
          }
          adapter.removeOne(state, meta.arg.deviceIdOld)
        }
      })

      .addMatcher(
        isAnyOf(
          loadDeviceActivityAsync.fulfilled,
          loadActiveFarmAsync.fulfilled,
        ),
        (state, { payload }) => {
          // The device is 'primary keyed' on device id so upsert
          // should be sufficient to keep events fresh
          const events = payload.deviceEvents
          adapter.setAll(state, events)
        },
      )
      .addMatcher(isAnyOf(signOutAsync.fulfilled), () => {
        return { ...initialState }
      }),
  initialState,
  name: `deviceEventLast`,
  reducers: {},
})
const deviceEventLast: Reducer<DeviceEventLastState> = slice.reducer
export default deviceEventLast

/**
 * If the run is in progress, show its ETA.
 */
function getRunEtaIndicatorProps({
  runDistanceMmCurrent,
  runSpeedMmpm,
  runStatus,
}: RunEtaParams & {
  runStatus: ReelRunStatus | null | undefined
}): StatusBadgeProps | undefined {
  const eta = calculateReelRunEta({ runDistanceMmCurrent, runSpeedMmpm })
  const id: TestId = "run-eta-indicator" as const
  const colorScheme: BadgeProps["colorScheme"] = undefined

  if (runStatus === "stopped short") {
    return {
      colorScheme: "$orange",
      iconName: "Warning",
      id,
      text: i18n.t("runStoppedShort", { ns: "devices" }),
    }
  }
  if (runStatus === "complete") {
    return {
      colorScheme: "$green",
      iconName: "Success",
      id,
      text: i18n.t("runComplete", { ns: "devices" }),
    }
  }
  if (runStatus === "retracting" && isValidNumber(eta)) {
    const options = {
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      month: "numeric",
    } as const

    return {
      colorScheme,
      iconName: "Clock",
      id,
      text: i18n.t("runEtaWithTimestamp", {
        ...options,
        ns: "devices",
        val: new Date(eta),
      }),
    }
  }
  return undefined
}

/**
 * If the reel is moving, show its state and speed
 */
function getRunSpeedBadgeProps({
  measurementPreference,
  runSpeedMmpm,
  runStatus,
}: MeasurementPreferenceProps & {
  runSpeedMmpm: number | null | undefined
  runStatus: ReelRunStatus
}) {
  const colorScheme: BadgeProps["colorScheme"] = "$info"

  if (runStatus === "stopped short") {
    return undefined
  }
  if (runStatus === "complete") {
    return undefined
  }

  const fieldName = "runSpeedMmpm"
  const base: Omit<StatusBadgeProps, "text"> = {
    iconName: "Speed",
    id: "run-speed-indicator",
  }

  if (runStatus === "stopped") {
    return {
      ...base,
      text: i18n.t("runStopped", { ns: "devices" }),
    }
  }
  let sensorState: string
  if (runStatus === "extending") {
    sensorState = i18n.t(`sensorStates:RE.short`)
  } else {
    sensorState = i18n.t(`sensorStates:RR.short`)
  }
  if (isValidNumber(runSpeedMmpm)) {
    return {
      ...base,
      colorScheme,
      text: i18n.t("sensorStateWithSensorReadingAndUnitLabel", {
        ns: "devices",
        sensorReading: formatSensorValue({
          fieldName,
          measurementPreference,
          rawValue: runSpeedMmpm,
        }),
        sensorState,
        unitLabel: getSensorUnitLabel({
          fieldName,
          measurementPreference,
        }),
      }),
    }
  }
  return undefined
}

/**
 * Show a warning badge ONLY if the device has bad gps
 */
function getGpsBadgeProps({
  isLocationValid,
}: {
  isLocationValid: boolean
}): StatusBadgeProps | undefined {
  const id: TestId = "gps-indicator"
  if (isLocationValid) {
    return undefined
  }
  const text = i18n.t("noGps", { ns: "devices" })
  return {
    colorScheme: "$warning",
    iconName: "GpsOff",
    id,
    text,
  }
}

/**
 * Badge for relay sensor (on/off)
 */
function getRelayBadgeProps({
  deviceInstallationType,
  stateCurrent,
}: {
  deviceInstallationType: InstallationType
  stateCurrent: SensorState<"relay"> | null | undefined
}): StatusBadgeProps | undefined {
  const id: TestId = "relay-indicator"
  if (
    deviceInstallationType === "valve" ||
    deviceInstallationType === "pump" ||
    deviceInstallationType === "pump_on_off" ||
    deviceInstallationType === "pump_vfd" ||
    deviceInstallationType === "reel_with_booster_off_only"
  ) {
    switch (stateCurrent) {
      case null:
      case undefined:
      case "NONE": {
        break
      }
      case "OFF": {
        return {
          iconName: "SwitchClosed",
          id,
          text: i18n.t("sensorStates:OFF.short"),
        }
      }
      case "ON": {
        return {
          colorScheme: "$success",
          iconName: "SwitchOpen",
          id,
          text: i18n.t("sensorStates:ON.short"),
        }
      }
    }
  }
  return undefined
}

/**
 * Badge for hall switch
 */
function getHallSwitchProps({
  deviceInstallationType,
  stateCurrent,
}: {
  deviceInstallationType: InstallationType
  stateCurrent: SensorState<"hallSwitch"> | null | undefined
}): StatusBadgeProps | undefined {
  const id: TestId = "hallSwitch-indicator"

  if (
    deviceInstallationType === "pump" ||
    deviceInstallationType === "prototype"
  ) {
    switch (stateCurrent) {
      case null:
      case undefined:
      case "NONE": {
        break
      }
      case "MA": {
        return {
          iconName: "Magnet",
          id,
          text: i18n.t("sensorStates:MA.short"),
        }
      }
      case "MP": {
        return {
          colorScheme: "$success",
          iconName: "MagnetOn",
          id,
          text: i18n.t("sensorStates:MP.short"),
        }
      }
    }
  }
  return undefined
}

/**
 * Show a flow badge if possible, otherwise no flow.
 */
function getFlowBadgeProps({
  flowRateLpmAvg,
  flowStateCurrent,
  measurementPreference,
}: {
  flowRateLpmAvg: number | null | undefined
  flowStateCurrent: SensorState<"flow"> | null | undefined
  measurementPreference: MeasurementPreference
}): StatusBadgeProps {
  const id: TestId = "flow-indicator"
  if (!flowStateCurrent || flowStateCurrent === "NONE") {
    flowStateCurrent = "FLN"
  }

  // Format the text
  const sensorState = i18n.t(`sensorStates:${flowStateCurrent}.short`)

  let text = sensorState
  if (isValidNumber(flowRateLpmAvg)) {
    const sensorReading = formatSensorValue({
      fieldName: "rateLpmAvg",
      measurementPreference,
      rawValue: flowRateLpmAvg,
    })
    if (typeof sensorReading === "string") {
      const unitLabel = getSensorUnitLabel({
        fieldName: "flowRateEstimatedLpm",
        measurementPreference,
      })
      text = `${sensorReading} ${unitLabel}`
    }
  }

  // Get badge style
  let iconName: IconKey
  let colorScheme: ColorSchemeType
  if (flowStateCurrent === "FLF") {
    iconName = "FlowOn"
    colorScheme = "$blue"
  } else {
    iconName = "FlowOff"
    colorScheme = "$gray"
  }

  return { colorScheme, iconName, id, text }
}

/**
 * Badge for battery external voltage
 */
function getVoltageBadgeProps({
  internalSoc,
  measurementPreference,
  stateCurrent,
  voltageMv,
}: MeasurementPreferenceProps &
  Pick<SensorEvent<"battery">, "internalSoc" | "stateCurrent" | "voltageMv">):
  | StatusBadgeProps
  | undefined {
  let colorScheme: BadgeProps["colorScheme"] = "$gray"

  switch (stateCurrent) {
    case undefined:
    case "NONE":
    case null: {
      break
    }
    case "BES":
    case "BD":
    case "BL": {
      colorScheme = "$warning"
      break
    }
    case "BN": {
      colorScheme = "$success"
      break
    }
  }

  if (isValidNumber(voltageMv)) {
    let voltageFormatted = formatSensorValue({
      fieldName: "voltageMv",
      measurementPreference,
      rawValue: voltageMv,
    })

    if (isTruthyString(voltageFormatted)) {
      voltageFormatted = `${voltageFormatted}V`
      if (typeof internalSoc === "number") {
        voltageFormatted = `${voltageFormatted} (${internalSoc}%)`
      }
      return {
        colorScheme,
        iconName: "VoltageBolt",
        id: "voltage-indicator",
        text: voltageFormatted,
      }
    }
  }
  return undefined
}

/**
 * Badge for pressure sensor
 */
function getPressureBadgeProps({
  measurementPreference,
  pressureReadingPsi,
  pressureState,
}: MeasurementPreferenceProps & {
  pressureReadingPsi: number | null | undefined
  pressureState: SensorState<"pressure"> | null | undefined
}): StatusBadgeProps | undefined {
  const sensorState = pressureState ?? "NONE"

  let iconName: IconKey | undefined
  let colorScheme: BadgeProps["colorScheme"] = "$gray"
  let text = i18n.t(`sensorStates:${sensorState}.short`)
  switch (pressureState) {
    case "POV": {
      iconName = "GaugeFull"
      colorScheme = "$warning"
      break
    }
    case "PHI": {
      iconName = "Gauge"
      colorScheme = "$blue"
      break
    }
    case "PLO":
    case "NONE":
    case undefined:
    case null: {
      iconName = "GaugeEmpty"
      colorScheme = "$gray"
      break
    }
  }
  if (isValidNumber(pressureReadingPsi)) {
    const sensorReading = formatSensorValue({
      fieldName: "readingKpa",
      measurementPreference,
      rawValue: pressureReadingPsi,
    })
    if (isTruthyString(sensorReading)) {
      text = i18n.t(`sensorStateWithSensorReadingAndUnitLabel`, {
        ns: "devices",
        sensorReading,
        sensorState: i18n.t(`sensorStates:${sensorState}.short`),
        unitLabel: getSensorUnitLabel({
          fieldName: "readingKpa",
          measurementPreference,
        }),
      })
    }

    return {
      colorScheme,
      iconName,
      id: "pressure-indicator",
      text,
    }
  }
  return undefined
}

function getWheelBadgeProps({
  event,
  measurementPreference,
}: MeasurementPreferenceProps & {
  event:
    | Pick<SensorEvent<"wheel">, "speedMmpm" | "stateCurrent">
    | null
    | undefined
}): StatusBadgeProps {
  const reportedSpeedMmpm = event?.speedMmpm ?? 0

  let colorScheme: ColorSchemeType
  let reportedState: string

  switch (event?.stateCurrent) {
    case undefined:
    case null:
    case "WUNK":
    case "NONE":
    case "WS": {
      reportedState = i18n.t("sensorStates:WS.short")
      colorScheme = "$gray"
      break
    }
    case "WF": {
      reportedState = i18n.t("sensorStates:WF.short")
      colorScheme = "$blueGray"
      break
    }
    case "WL": {
      reportedState = i18n.t("sensorStates:WL.short")
      colorScheme = "$blue"
      break
    }
  }
  const fieldName: SensorEventKey = "speedMmpm"
  return {
    colorScheme,
    iconName: "Wheel",
    id: "wheel",
    text: i18n.t("sensorStateWithSensorReadingAndUnitLabel", {
      ns: "devices",
      sensorReading: formatSensorValue({
        fieldName,
        measurementPreference,
        rawValue: reportedSpeedMmpm,
      }),
      sensorState: reportedState,
      unitLabel: getSensorUnitLabel({
        fieldName,
        measurementPreference,
      }),
    }),
  }
}

export type SensorStatusBadges = {
  [key in
    | SetDifference<SensorName, "battery" | "vfd">
    | "batteryStateOfChargeInternal"
    | "batteryVoltageExternal"
    | "gpsHeading"
    | "runEta"]?: StatusBadgeProps
} & {
  vfdStatus?: VfdIndicatorProps | null
}

/**
 * We synthesize different properties of the device in order to display
 * important information to users about it. This interface is the result
 * of that post-api processing and is used in the StatusMap, the DeviceRoster,
 * the SelectedDevice interface and DeviceProfile. Consistency is key for
 * good UX across these platforms!
 */
export interface DeviceStatusData extends DeviceSummary {
  badges: SensorStatusBadges
  batteryState: SensorState<"battery"> | null | undefined
  commentActive: Models.DeviceComment | undefined
  deviceEventTimestamp: string | undefined
  deviceStatus: "offline" | "online"
  gpsHeading: number | null | undefined

  /**
   * TODO: Double-check this
   */
  hasDeviceActivity: boolean
  inField: Models.FarmField | null
  isInMotion: boolean
  isStoppedShort: boolean
  isUpdating: boolean
  nextScheduledAction: Models.ScheduledDeviceAction | undefined
  pressureState: SensorState<"pressure" | "pressureSwitch"> | undefined
  reelRunStatus: ReelRunStatus | null | undefined
  /**
   * Run completion will be calculated if the device event last
   * has a reel sensor with a valid distance current and distance max,
   * with 'valid' meaning an integer; not null or NaN.
   */
  runCompletion: RunCompletion | undefined
  showAdminInfo: boolean
}

/**
 *
 */
function makeDeviceStatusListItemCreator({
  deviceActivities,
  farmLocation,
  fields,
  isAdminModeEnabled,
  measurementPreference,
}: {
  deviceActivities: Dictionary<Models.DeviceActivity>
  farmLocation: Geo.AnyPoint | undefined
  fields: Models.FarmField[]
  isAdminModeEnabled: boolean
  measurementPreference: MeasurementPreference
}) {
  const validateLocation = makeLocationValidator({ farmLocation })
  return function generateListItem({
    commandTracker,
    comment,
    configuration,
    event,
  }: {
    commandTracker: Models.DeviceCommandTracker | undefined
    comment: Models.DeviceComment | undefined
    configuration: DeviceConfiguration
    event: DeviceEvent | undefined
  }): DeviceStatusData {
    const deviceInstallationType = configuration.deviceInstallationType
    const {
      runDistanceMmCurrent,
      runDistanceMmMax,
      runSpeedMmpm,
      stateCurrent: reelStateCurrent,
    } = event?.reel ?? {}

    const runCompletion = calculateRunCompletion({
      runDistanceMmCurrent,
      runDistanceMmMax,
    })

    const runCompletionPct = runCompletion?.runCompletionPct
    const reelRunStatus = calculateReelRunStatus({
      runCompletionPct,
      stateCurrent: reelStateCurrent,
    })

    const pressureReading = event?.pressure?.readingKpa ?? undefined

    const pressureStateCurrent =
      event?.pressure?.stateCurrent ??
      event?.pressureSwitch?.stateCurrent ??
      undefined

    const wheelStateCurrent = event?.wheel?.stateCurrent
    const flowStateCurrent = event?.flow?.stateCurrent

    const badges: SensorStatusBadges = {
      gps: getGpsBadgeProps({
        isLocationValid: validateLocation(
          event?.gps?.location,
          event?.gps?.stateCurrent,
        ),
      }),
    }

    if (configuration.pressure || configuration.pressureSwitch) {
      badges.pressure = getPressureBadgeProps({
        measurementPreference,
        pressureReadingPsi: pressureReading,
        pressureState: pressureStateCurrent,
      })
    }

    if (configuration.flow) {
      // FLOW BADGE

      badges.flow = getFlowBadgeProps({
        flowRateLpmAvg: event?.flow?.rateLpmAvg,
        flowStateCurrent,
        measurementPreference,
      })
    }

    if (configuration.relay) {
      // RELAY BADGE
      badges.relay = getRelayBadgeProps({
        deviceInstallationType,
        stateCurrent: event?.relay?.stateCurrent,
      })
    }
    if (configuration.hallSwitch) {
      // HALL SWITCH BADGE
      badges.hallSwitch = getHallSwitchProps({
        deviceInstallationType,
        stateCurrent: event?.hallSwitch?.stateCurrent,
      })
    }
    if (event) {
      // VFD
      if (event.vfd) {
        badges.vfdStatus = {
          currentPercentage: event.vfd.currentPercentage,
          pidSensorSetpoint: event.vfd.pidSensorSetpoint,
          stateCurrent: event.vfd.stateCurrent,
          targetRampPercentage: event.vfd.targetRampPercentage,
        }
      }
      // GPS
      if (event.gps) {
        // badges.gpsHeading = getGpsHeadingProps({
        //   configuration,
        //   heading: event.gps.heading,
        //   sensorState: reelStateCurrent ?? wheelStateCurrent,
        // })
      }
      // BATTERY BADGES
      if (event.battery) {
        const { internalSoc, stateCurrent, voltageMv } = event.battery

        badges.batteryVoltageExternal = getVoltageBadgeProps({
          internalSoc,
          measurementPreference,
          stateCurrent,
          voltageMv,
        })

        if (typeof internalSoc === "number" && internalSoc <= 50) {
          badges.batteryStateOfChargeInternal = {
            colorScheme: "$warning",
            iconName: "Battery",
            id: "internalSoc",
            text: `${internalSoc}%`,
          }
        }
      }
      // WHEEL
      if (configuration.wheel) {
        badges.wheel = getWheelBadgeProps({
          event: event.wheel,
          measurementPreference,
        })
      }
      // REEL INDICATORS
      if (configuration.reel && event.reel) {
        if (reelRunStatus) {
          badges.reel = getRunSpeedBadgeProps({
            measurementPreference,
            runSpeedMmpm,
            runStatus: reelRunStatus,
          })
        }
        badges.runEta = getRunEtaIndicatorProps({
          runDistanceMmCurrent,
          runSpeedMmpm,
          runStatus: reelRunStatus,
        })
      }
    }

    /**
     *
     */
    let hasDeviceActivity = false
    if (Boolean(deviceActivities[configuration.deviceId])) {
      if (deviceInstallationType === "center_pivot") {
        hasDeviceActivity = true
      }
    }

    return {
      commentActive:
        comment && comment.status === "active" ? comment : undefined,
      gpsHeading: event?.gps?.heading,
      hasDeviceActivity,
      isInMotion: isDeviceInMotion(reelStateCurrent ?? wheelStateCurrent),
      nextScheduledAction: event?.scheduledDeviceActions[0],
      pressureState: pressureStateCurrent,
      showAdminInfo: isAdminModeEnabled,
      ...makeDeviceSummary({
        configuration,
        event,
        farmLocation,
      }),
      badges,
      batteryState: event?.battery?.stateCurrent,
      deviceEventTimestamp: event?.deviceEventTimestamp,
      deviceStatus: Boolean(event) ? "online" : "offline",
      inField: findFieldForDevice({ event, fields }),
      isStoppedShort: reelRunStatus === "stopped short",
      isUpdating: Boolean(commandTracker),
      reelRunStatus,
      runCompletion,
    }
  }
}

/**
 * This selector produces a DeviceStatusData object for each
 * device configuration in the farm's account, regardless
 * of whether it has an event in deviceEventLast
 */
export const getDeviceStatusListItemsFromState = createSelector(
  [
    Models.deviceConfiguration.selectEntities,
    Models.deviceEventLast.selectEntities,
    Models.deviceCommandTracker.selectEntities,
    Models.deviceComment.selectEntities,
    getActiveFarmCoordinatesFromState,
    getIsAdminModeEnabledFromState,
    getUserMeasurementPreferenceFromState,
    Models.field.selectAll,
    Models.deviceActivity.selectEntities,
  ],
  (
    configurations,
    events,
    commandTrackers,
    comments,
    farmLocation,
    isAdminModeEnabled,
    measurementPreference,
    fields,
    deviceActivities,
  ) => {
    const generateListItem = makeDeviceStatusListItemCreator({
      deviceActivities,
      farmLocation,
      fields,
      isAdminModeEnabled,
      measurementPreference,
    })
    const result: DeviceStatusData[] = []
    for (const configuration of Object.values(configurations)) {
      const deviceId = configuration?.deviceId
      if (isTruthyString(deviceId) && configuration) {
        result.push(
          generateListItem({
            commandTracker: commandTrackers[deviceId],
            comment: comments[deviceId],
            configuration,
            event: events[deviceId],
          }),
        )
      }
    }
    result.sort(sortDeviceStatusByUrgency)
    return result
  },
)
/**
 * Synthesizes device events along with their configuration, comments, triggers,
 * and other relevant data into a single object that can be used to render
 * a device status display
 */
export const getDeviceStatusDataByDeviceIdFromState: (
  state: RootState,
  deviceId: string | undefined,
) => DeviceStatusData | undefined = createCachedSelector(
  [
    Models.deviceConfiguration.selectById,
    Models.deviceConfiguration.selectEntities,
    Models.deviceEventLast.selectEntities,
    Models.deviceCommandTracker.selectById,
    Models.deviceComment.selectById,
    Models.trigger.selectEntities,
    Models.field.selectAll,
    getActiveFarmCoordinatesFromState,
    getIsAdminModeEnabledFromState,
    getUserMeasurementPreferenceFromState,
    Models.deviceActivity.selectEntities,
  ],
  (
    configuration,
    configurations,
    events,
    commandTracker,
    comment,
    triggers,
    fields,
    farmLocation,
    isAdminModeEnabled,
    measurementPreference,
    deviceActivities,
  ) => {
    const generateListItem = makeDeviceStatusListItemCreator({
      deviceActivities,
      farmLocation,
      fields,
      isAdminModeEnabled,
      measurementPreference,
    })

    if (configuration) {
      const deviceId = configuration.deviceId
      return generateListItem({
        commandTracker,
        comment,
        configuration,
        event: events[deviceId],
      })
    }
    return undefined
  },
)(makeDeviceEventCacheKey)

/**
 * Get all device status data sorted by the current sort key and direction
 */
export const getDeviceStatusListItemsSortedFromState = createSelector(
  [
    getDeviceStatusListItemsFromState,
    getDeviceSortKeyFromState,
    getDeviceSortDirectionFromState,
  ],
  (devices, sortKey, sortDirection) => {
    const devicesCopied = [...devices]
    let sortCallback: Models.SortComparator<DeviceStatusData>
    switch (sortKey) {
      case "alphabetical": {
        sortCallback = Models.makePrioritySort(sortDirection, sortByDeviceName)
        break
      }
      case "device_activity": {
        sortCallback = Models.makePrioritySort(
          sortDirection,
          sortDeviceStatusByUrgency,
        )
        break
      }
      case "device_installation_type": {
        sortCallback = Models.makePrioritySort(
          sortDirection,
          sortByDeviceInstallationType,
          sortByDeviceName,
        )
        break
      }
    }
    devicesCopied.sort(sortCallback)
    return devicesCopied
  },
)

const MARKER_VERSION = 5
/**
 * Get the marker image source for a device based on its installation type
 */
export const getMarkerImageSourceByDeviceIdFromState: (
  state: RootState,
  deviceId: string | undefined,
) => string = createCachedSelector(
  Models.deviceEventLast.selectById,
  (state, deviceId: string | undefined) =>
    Models.deviceConfiguration.selectById(state, deviceId)
      ?.deviceInstallationType,
  (event, installationType) => {
    const pressureStateCurrent =
      event?.pressure?.stateCurrent ?? event?.pressureSwitch?.stateCurrent
    const flowStateCurrent = event?.flow?.stateCurrent

    let isMoving: boolean | undefined

    if (
      installationType === "reel" ||
      installationType === "reel_with_booster_off_only" ||
      installationType === "center_pivot" ||
      installationType === "traveller_soft" ||
      installationType === "linear_move"
    ) {
      isMoving = isDeviceInMotion(
        event?.reel?.stateCurrent ?? event?.wheel?.stateCurrent,
      )
    }

    let uri = `${Urls.IMAGE_HOST}/generated/v_${MARKER_VERSION}/`
    switch (installationType) {
      case undefined: {
        throw new Error("Not implemented yet: undefined case")
      }
      case "center_pivot": {
        uri += "ag_pivot"
        break
      }
      case "traveller_soft":
      case "linear_move": {
        uri += "ag_linear"
        break
      }
      case "pump":
      case "pump_off_only": {
        uri += "pump_off"
        break
      }
      case "pump_on_off": {
        uri += "pump_io"
        break
      }
      case "pump_vfd": {
        uri += "pump_var"
        break
      }
      case "reel_with_booster_off_only":
      case "reel": {
        // If reel is stopped short, we should show a warning icon
        const runCompletion = calculateRunCompletion({
          runDistanceMmCurrent: event?.reel?.runDistanceMmCurrent,
          runDistanceMmMax: event?.reel?.runDistanceMmMax,
        })?.runCompletionPct
        if (
          calculateReelRunStatus({
            runCompletionPct: runCompletion,
            stateCurrent: event?.reel?.stateCurrent,
          }) === "stopped short"
        ) {
          //
          uri += "warning"
        } else {
          uri += "reel"
        }
        break
      }
      case "valve": {
        uri += "valve"
        break
      }

      case "prototype": {
        uri += "prototype"
        break
      }
      case "unconfigured": {
        uri += "unconfigured"
        break
      }
    }
    // Pressure status
    if (pressureStateCurrent === "PHI" || flowStateCurrent === "FLF") {
      uri += "_pressure-high"
    } else if (pressureStateCurrent === "PLO") {
      uri += "_pressure-low"
    } else if (pressureStateCurrent === "POV") {
      uri += "_pressure-over"
    } else {
      uri += "_pressure-null"
    }
    // Movement status
    if (isMoving === true) {
      uri += "_movement-true"
    } else {
      uri += "_movement-null"
    }
    uri += ".png"

    return uri
  },
)((state, deviceId): string => {
  const eventId = Models.deviceEventLast.selectById(state, deviceId)?.id
  return `${eventId ?? "None"}`
})
