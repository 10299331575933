import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { Collapse } from "./Collapse"
import { Divider } from "./Divider"
import { AppIcons } from "./icons"
import { Pressable } from "./Pressable"
import { AppText } from "./Text"
import { COLORS, RADII, SPACING } from "./theme"
import { useIsDarkMode } from "./ThemeProvider"
import { View } from "./View"

import type { ViewProps } from "./View"

const styles = StyleSheet.create({
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: SPACING.$1,
    paddingBottom: SPACING.$2,
    paddingHorizontal: SPACING.$2,
    paddingTop: SPACING.$2,
  },
  buttonDark: {
    borderColor: COLORS.$divider.dark,
  },
  root: {
    borderColor: COLORS.$divider.light,
    borderRadius: RADII.$default,
    borderWidth: 1,
    paddingHorizontal: SPACING.$2,
  },
})

export function Accordion({
  children,
  defaultIsOpen = false,
  style,
  titleText,
  ...rest
}: ViewProps & {
  titleText: string
  defaultIsOpen?: boolean
}): React.JSX.Element {
  const [isOpen, setIsOpen] = React.useState(defaultIsOpen)
  const isDark = useIsDarkMode()
  return (
    <View style={useStyle(() => [styles.root, style], [style])} {...rest}>
      <Pressable
        trackHover
        style={useStyle(
          () => [styles.button, isDark && styles.buttonDark],
          [isDark],
        )}
        onPress={() => setIsOpen(!isOpen)}
      >
        <AppText>{titleText}</AppText>
        {isOpen ? <AppIcons.ChevronUp /> : <AppIcons.ChevronDown />}
      </Pressable>
      {isOpen ? <Divider /> : null}
      <Collapse isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {children}
      </Collapse>
    </View>
  )
}
