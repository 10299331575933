import React from "react"
import { useTranslation } from "react-i18next"

import { AppText, Box, Row } from "./components"
import * as Models from "./models"
import { useFormatSensorValue, useSensorUnitLabel } from "./sensor-formatting"
import { useRootSelector } from "./useRootSelector"

import type { DeviceIdProps } from "./types"

export function useTitleText({ deviceId }: DeviceIdProps) {
  const configuration = useRootSelector((state) =>
    Models.deviceConfiguration.selectById(state, deviceId),
  )
  let titleText = "Device Activity"
  if (configuration?.deviceInstallationType === "center_pivot") {
    titleText = "Center Pivot Activity"
  }
  return {
    codaDeviceAlias: configuration?.codaDeviceAlias,
    deviceName: configuration?.deviceName ?? configuration?.codaDeviceAlias,
    titleText,
  }
}

function TextRow({
  label,
  value,
}: {
  label: string
  value: number | string | null | undefined
}) {
  return (
    <Row mb="$1">
      <Box flex={1} mr="$4">
        <AppText colorScheme="secondary">{label}</AppText>
      </Box>
      <AppText>{value}</AppText>
    </Row>
  )
}

export function SignificantFigures(activityData: Models.DeviceActivity) {
  const formatValue = useFormatSensorValue()
  const { t } = useTranslation()
  const unitLabel = useSensorUnitLabel()

  const currentSpeedMmpm =
    activityData.wheelSpeedMmpms?.[activityData.wheelSpeedMmpms.length - 1]

  let speedFormatted = formatValue("speedMmpm", currentSpeedMmpm)
  if (typeof speedFormatted === "string") {
    speedFormatted += ` ${unitLabel("speedMmpm")}`
  }

  const travelerNextStopExpectedTimestamp =
    activityData.travelerNextStopExpectedTimestamps?.[
      activityData.travelerNextStopExpectedTimestamps.length - 1
    ]

  let nextStopFormatted: string | undefined
  if (
    typeof travelerNextStopExpectedTimestamp === "number" ||
    typeof travelerNextStopExpectedTimestamp === "string"
  ) {
    nextStopFormatted = t("datetimeWithVal", {
      // dateStyle: "short",
      ns: "common",
      timeStyle: "medium",
      val: new Date(travelerNextStopExpectedTimestamp),
    })
  }

  return (
    <React.Fragment>
      <TextRow label="Speed" value={speedFormatted} />
      <TextRow label="Next stop" value={nextStopFormatted} />
    </React.Fragment>
  )
}
