import type { SupportImageName, TestId, WithOptionalTestId } from "@fhq/app"
import Markdown from "markdown-to-jsx"
import React from "react"
import { Controlled as ControlledZoom } from "react-medium-image-zoom"
import { Image, View } from "react-native"

import { isTruthyString, testIds, Urls } from "@fhq/app"
import {
  AppText,
  Box,
  Divider,
  Heading,
  IconButton,
  Paper,
  Row,
} from "@fhq/app/components"
import { SPACING } from "@fhq/app/components/theme"
import { captureMessage } from "@sentry/react"

import type {
  AppTextProps,
  HeadingProps,
  IconButtonProps,
} from "@fhq/app/components"

/**
 * Convert headings from markdown page to query-selectable ids
 * @example
 * convertHeadingToValidId("Some Topic")
 * >> 'some-topic'
 */
export function convertHeadingToValidId(heading: string): string {
  return heading
    .toLowerCase()
    .replace(/\d/g, "")
    .replace(/[^\d A-Za-z-]/g, "")
    .replace(/ /g, "-")
}

/**
 * Factory for Heading text components from markdown
 *
 * @param variant - h1, h2, etc.
 * @param defaultProps  - default style applied
 */
function makeMarkdownTextComponent<V extends `h${1 | 2 | 3 | 4 | 5 | 6}`>(
  variant: V,
  defaultProps?: HeadingProps,
): { (props: JSX.IntrinsicElements[V]): React.JSX.Element; displayName: V } {
  function RenderText(props: JSX.IntrinsicElements[V]): React.JSX.Element {
    const id = isTruthyString(props.id)
      ? convertHeadingToValidId(props.id)
      : undefined

    return (
      <Heading
        {...defaultProps}
        {...testIds(id as TestId, { preserveId: true })}
        variant={variant}
      >
        {props.children}
      </Heading>
    )
  }
  RenderText.displayName = variant
  return RenderText
}

const OPTIONS: React.ComponentProps<typeof Markdown>["options"] = {
  overrides: {
    br: Divider,
    div: function Div(
      props: WithOptionalTestId<JSX.IntrinsicElements["div"]>,
    ): React.JSX.Element {
      if (props.className === "h-stack") {
        return <Row w="$full">{props.children}</Row>
      }

      return (
        <Paper preserveId id={props.id}>
          {props.children}
        </Paper>
      )
    },
    figcaption: function FigCaption(
      props: WithOptionalTestId<JSX.IntrinsicElements["figcaption"]>,
    ): React.JSX.Element {
      return (
        <Box preserveId id={props.id} w="$full">
          <AppText fontStyle="italic" fontWeight="bold">
            {props.children as string}
          </AppText>
        </Box>
      )
    },
    h1: makeMarkdownTextComponent("h1", {
      fontWeight: "900",
      // py: "4",
      // size: "xl"
    }),
    h2: makeMarkdownTextComponent("h2", {
      // py: "2",
      // size: "md"
    }),
    h3: makeMarkdownTextComponent("h3", {
      // py: "2",
      // size: "md"
    }),
    h4: makeMarkdownTextComponent("h4"),
    h5: makeMarkdownTextComponent("h5"),
    h6: makeMarkdownTextComponent("h6"),
    img: function MarkdownImg({
      alt,
      onPress,
      src,
      ...rest
    }: {
      src: SupportImageName
      alt?: string
      onPress?: IconButtonProps["onPress"]
    }): React.JSX.Element {
      const [isZoomed, setIsZoomed] = React.useState(false)
      const [loaded, setIsLoaded] = React.useState(true)

      const handleImgLoadFail = (): void => {
        captureMessage(`Failed to load image from source ${src}`)
        setIsLoaded(false)
      }

      const handleZoomChange = (shouldZoom: boolean): void => {
        if (loaded) {
          setIsZoomed(shouldZoom)
        }
      }

      return (
        <Box w="$full">
          <ControlledZoom
            isZoomed={isZoomed}
            wrapStyle={{ position: "relative" }}
            onZoomChange={handleZoomChange}
          >
            <Image
              {...rest}
              alt={alt ?? src}
              resizeMode="contain"
              source={{ uri: Urls.buildSupportImageUrl(src) }}
              style={{
                alignSelf: "center",
                marginVertical: SPACING.$4,
                minHeight: 300,
                width: "100%",
              }}
              onError={handleImgLoadFail}
            />
            {loaded && onPress ? (
              <Box bottom="$4" h="$2" position="absolute" right="$4" w="$2">
                {isZoomed ? null : (
                  <IconButton
                    IconComponent="ZoomIn"
                    bg="rgba(0,0,0,0.5)"
                    onPress={onPress}
                  />
                )}
              </Box>
            ) : null}
          </ControlledZoom>
        </Box>
      )
    },

    li: function ListItem(
      props: JSX.IntrinsicElements["li"],
    ): React.JSX.Element {
      const { _text, index, ul } = props as {
        _text?: AppTextProps
        index?: number | undefined
        ul?: boolean
      }

      return (
        <View
          // borderBottomColor="divider.light.default"
          // borderBottomWidth="1"
          nativeID={props.id}
          testID={props.id}
          // _dark={{borderBottomColor: "divider.dark.default"}}
        >
          {ul === false && typeof index === "number" ? (
            <Box mr="$4">
              <AppText {..._text} fontWeight="bold">
                {index + 1}
              </AppText>
            </Box>
          ) : null}
          <AppText>{props.children}</AppText>
        </View>
      )
    },

    ol: function OrderedList(
      props: JSX.IntrinsicElements["ol"],
    ): React.JSX.Element {
      return (
        <View nativeID={props.id} testID={props.id}>
          {props.children as JSX.Element}
        </View>
      )
    },
    p: function Paragraph(
      props: WithOptionalTestId<JSX.IntrinsicElements["p"]>,
    ) {
      return (
        <Box id={props.id} my="$2" pb="$2" rounded="$sm">
          <AppText>{props.children}</AppText>
        </Box>
      )
    },

    strong: function Strong(
      props: JSX.IntrinsicElements["strong"],
    ): React.JSX.Element {
      if (
        Array.isArray(props.children) &&
        isTruthyString(props.children[0]) &&
        props.children[0].startsWith("WARNING")
      ) {
        return (
          <Box
            bg="warning.100"
            id={props.id as TestId}
            p="$1"
            rounded="$default"
          >
            <AppText fontWeight="bold">{props.children}</AppText>
          </Box>
        )
      }
      return (
        <AppText fontWeight="bold" {...testIds(props.id as TestId)}>
          {props.children}
        </AppText>
      )
    },

    ul: function UnorderedList({
      children,
      id,
    }: JSX.IntrinsicElements["ol"]): React.JSX.Element {
      return <View {...testIds(id as TestId)}>{children as JSX.Element}</View>
    },
  },
}
/**
 *
 * @param param0
 * @returns
 */
export const RenderMarkdown = React.memo(function MarkdownAsJSX({
  text,
}: {
  text: string
}): React.JSX.Element {
  return <Markdown options={OPTIONS}>{text}</Markdown>
})
