import React, { useEffect, useState } from "react"
import { LayoutAnimation, Platform, UIManager } from "react-native"

import { Box } from "./Box"
import { usePrevious } from "./usePrevious"

import type { ViewStyle } from "react-native"
import type { BoxProps } from "./Box"
export interface CollapseProps extends BoxProps {
  animateOpacity?: boolean
  duration?: number
  endingHeight?: number
  isOpen?: boolean
  onAnimationEnd?: () => void
  onAnimationStart?: () => void
  onClose?: () => void
  onOpen?: () => void
  startingHeight?: number
  style?: ViewStyle
}

/**
 * A component that animates the showing and hiding of its children.
 */
export function Collapse({
  children,
  duration,
  endingHeight,
  isOpen = false,
  onAnimationEnd,
  onAnimationStart,
  onClose,
  onOpen,
  startingHeight = 1,
  ...props
}: CollapseProps) {
  if (Platform.OS === "android") {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    Boolean(UIManager.setLayoutAnimationEnabledExperimental) &&
      UIManager.setLayoutAnimationEnabledExperimental(true)
  }

  const defaultStartHeight = isOpen
    ? endingHeight
    : startingHeight
    ? startingHeight
    : 1
  const [animatedStyle, setAnimatedStyle] = useState({
    height: defaultStartHeight,
  })
  const [size, setSize] = useState(startingHeight)

  const wasOpen = usePrevious(isOpen)

  useEffect(() => {
    if (wasOpen.value !== isOpen) {
      if (onAnimationStart) {
        onAnimationStart()
      }

      const newHeight = isOpen ? endingHeight : defaultStartHeight

      LayoutAnimation.configureNext(
        {
          create: {
            property: LayoutAnimation.Properties.opacity,
            type: LayoutAnimation.Types.easeInEaseOut,
          },
          duration: typeof duration === "number" ? duration : 400,
          update: {
            type: LayoutAnimation.Types.easeInEaseOut,
          },
        },
        () => {
          if (onAnimationEnd) {
            onAnimationEnd()
          }
          if (isOpen && onOpen) {
            onOpen()
          }
          if (!isOpen && onClose) {
            onClose()
          }
        },
      )

      setAnimatedStyle({ height: newHeight })
    }
  }, [
    defaultStartHeight,
    duration,
    endingHeight,
    isOpen,
    onAnimationEnd,
    onAnimationStart,
    onClose,
    onOpen,
    wasOpen.value,
  ])

  const provideSize = (layoutSize: { height: number }) => {
    setSize(layoutSize.height)
  }

  let heightWeb = 0
  if (isOpen) {
    heightWeb = endingHeight ?? size
  } else {
    heightWeb = startingHeight
  }

  return (
    <Box
      overflow="hidden"
      style={{
        ...animatedStyle,
        ...(Platform.OS === "web" && {
          height: heightWeb,
          transition: `height ${duration ?? "400"}ms`,
        }),
      }}
    >
      <Box
        // @ts-expect-error overflow auto is only available on web
        overflow={Platform.OS === "web" ? "auto" : "scroll"}
        onLayout={(e) => provideSize(e.nativeEvent.layout)}
        {...props}
      >
        {children}
      </Box>
    </Box>
  )
}
