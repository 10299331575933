import React from "react"
import { Outlet } from "react-router"

import { ScrollingContent } from "./pages/base/Layout"
import { PageErrorBoundary } from "./pages/base/PageErrorBoundary"

import type { RouteObject } from "react-router"

const AccountLandingPage = React.lazy(
  async () => import("./pages/AccountLandingPage"),
)
const CreateFarmJoinCodePage = React.lazy(
  async () => import("./pages/CreateFarmJoinCodePage"),
)
const MapPreferencesPage = React.lazy(
  async () => import("./pages/MapPreferencesPage"),
)
const ViewPermissionsPage = React.lazy(
  async () => import("./pages/ViewPermissionsPage"),
)
const ViewTermsOfServicePage = React.lazy(
  async () => import("./pages/ViewTermsOfServicePage"),
)
const AddPhoneNumberPage = React.lazy(
  async () => import("./pages/AddPhoneNumberPage"),
)
const PhoneNumbersListPage = React.lazy(
  async () => import("./pages/PhoneNumbersListPage"),
)
const FarmUsersListPage = React.lazy(
  async () => import("./pages/FarmUsersListPage"),
)
const ManagePermissionsPage = React.lazy(
  async () => import("./pages/ManagePermissionsPage"),
)

// const PATHS = Paths.account

export const ACCOUNT_ROUTES: RouteObject = {
  children: [
    { element: <AccountLandingPage />, index: true },
    { element: <MapPreferencesPage />, path: "map-preferences" },
    { element: <ViewPermissionsPage />, path: "view-permissions" },
    { element: <ViewTermsOfServicePage />, path: "view-terms-of-service" },
    { element: <CreateFarmJoinCodePage />, path: "new-farm-join-code" },
    {
      children: [
        { element: <FarmUsersListPage />, index: true },
        {
          element: <ManagePermissionsPage />,
          path: ":targetUserEmail/:targetUserId",
        },
      ],
      errorElement: <PageErrorBoundary />,
      path: "manage-users",
    },
    {
      children: [
        { element: <PhoneNumbersListPage />, index: true },
        { element: <AddPhoneNumberPage />, path: "add-phone-number" },
      ],
      path: "phone-numbers",
    },
  ],
  element: (
    <ScrollingContent id="account">
      <Outlet />
    </ScrollingContent>
  ),
  errorElement: <PageErrorBoundary />,
}
