import React from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router"

import { CreateConnection, useRootDispatch, useRootSelector } from "@fhq/app"
import { AppIcons, Box, Card, ScrollView } from "@fhq/app/components"

import { Help } from "./base/Help"
import { Page } from "./base/Page"

import type { CreateConnectionRouteParams } from "@fhq/app"
export function CreateDeviceConnectionPage() {
  const { t } = useTranslation("createDeviceConnection")
  const dispatch = useRootDispatch()
  const navigate = useNavigate()
  const routerState = useLocation().state as
    | CreateConnectionRouteParams
    | undefined
  const initiatorDeviceId = routerState?.initiatorDeviceId

  CreateConnection.useInitiatorDevice({ initiatorDeviceId })

  /**
   * Reset the form when the component is unmounted
   */
  React.useEffect(() => {
    return () => {
      dispatch(CreateConnection.resetCreateConnectionsForm())
    }
  }, [dispatch])
  const isCreatingDevicePair = useRootSelector(
    CreateConnection.isCreatingDevicePair,
  )
  const handleClose = () => navigate(-1)
  const titleText = t("newDeviceConnection")

  return (
    <ScrollView variant="pageScroll">
      <Page
        IconComponent="DeviceConnection"
        maxW="$2xl"
        mx="auto"
        px="$4"
        rightElement={<Help {...CreateConnection.HELP_CONTENT} />}
        titleText={titleText}
        w="$full"
      >
        <Card titleText={t("conditionSectionTitle")}>
          <Box my="$1">
            <CreateConnection.SourceDeviceSelect />
          </Box>
          <Box my="$1">
            <CreateConnection.SourceSensorSelect />
          </Box>
          <Box my="$1">
            <CreateConnection.SourceSenseorStatePreviousSelect />
          </Box>
          <Box my="$1">
            <CreateConnection.SourceSensorStateCurrentSelect />
          </Box>
        </Card>
        <Box mx="auto" my="$2">
          <AppIcons.ArrowDown />
        </Box>
        <Card titleText={t("actionSectionTitle")}>
          <Box my="$2">
            <CreateConnection.TargetDeviceSelect />
          </Box>
          <Box my="$2">
            <CreateConnection.TargetActionSelect />
          </Box>
          <CreateConnection.FormErrorMessage />
          <CreateConnection.Actions onClose={handleClose} />
        </Card>
        {isCreatingDevicePair ? (
          <CreateConnection.CreatingDevicePairAlert />
        ) : null}
      </Page>
    </ScrollView>
  )
}
