import React from "react"
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Image, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { ActionButtons } from "./ActionButtons"
import {
  AlertBodyText,
  AlertCard,
  AppIcons,
  AppText,
  Avatar,
  Button,
  Divider,
  getIconForInstallationType,
  getIconForSensor,
  MenuItemButton,
  Row,
  SubmitButton,
  TextWithIcon,
  TitleWithIcon,
} from "./components"
import { Box } from "./components/Box"
import { Radio } from "./components/Radio"
import { testIds } from "./components/test-id"
import { SIZES } from "./components/theme"
import {
  ChooseSensorPort,
  ChooseSprinklerType,
  ChooseSwitchType,
  DeviceNameInput,
  MagnetSelect,
  PressureSliderConnected,
  SensorTextInput,
} from "./DeviceSensorFields"
import { createConfigurationAsync } from "./farmhq-api"
import * as Geo from "./geo"
import { Urls } from "./Internal"
import { makeFileLogger } from "./logger"
import {
  getIsAdminModeEnabledFromState,
  getUserMeasurementPreferenceFromState,
  useIsPending,
  useMeasurementPreference,
} from "./selectors"
import {
  CONFIG_SENSOR_NAMES,
  MUTABLE_SENSOR_NAMES,
  SENSOR_DEFAULTS,
  SensorPorts,
} from "./sensor-configurations"
import {
  convertSensorValue,
  getSensorConversionSpec,
  requiresConversion,
} from "./sensor-conversions"
import { useSensorUnitLabel } from "./sensor-formatting"
import { SensorConfigValuesList } from "./SensorConfigValuesList"
import { isValidNumber } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { ImageProps, ViewProps } from "react-native"
import type { BoxProps } from "./components/Box"
import type { GetResponseDataType } from "./send-request"

import type { Entry } from "type-fest"
import type { AppTextProps, AcceptsChildren, IconProp } from "./components"
import type { TestId } from "./components/test-id"

import type {
  ConfigSensorName,
  HardwareGeneration,
  InstallationType,
  SensorConfig,
  SensorConfigKey,
  SensorConfigurations,
  SensorName,
} from "./sensor-configurations"
import type {
  ConversionTarget,
  MeasurementPreferenceProps,
} from "./sensor-conversions"

import type { ActionButtonProps } from "./ActionButtons"
import type { DeviceConfiguration } from "./device-configurations.reducer"

const fileLogger = makeFileLogger({ fileName: "CreateConfiguration" })

function InstructionsText(props: AppTextProps) {
  return <AppText {...props} />
}

type CreateConfigFormSensorValues = {
  [key in SensorName<
    "flow" | "pressure" | "pressureSwitch" | "reel" | "vfd" | "wheel"
  >]: SensorConfig<key> | null
} & {
  linearPathStopsCoordinates: Geo.Coordinates[] | null | undefined
  linearPathStopsLabels: string[] | null | undefined
  linearSpanHeadingDegrees: number | null
  linearSpanWidthMm: number | null
  pivotCenterGpsLocation: Geo.PointGeoJson | null
  pivotPathStopsCoordinates: Geo.Coordinates[] | null
  pivotPathStopsHeadingsDegrees: number[] | null
  pivotPathStopsLabels: string[] | null
  pivotRadiusMeters: number | null
}

/**
 *
 */
export function getDefaultSensorValues({
  hardwareGeneration,
  installationType,
}: {
  hardwareGeneration: HardwareGeneration
  installationType: InstallationType
}): CreateConfigFormSensorValues {
  const result: CreateConfigFormSensorValues = {
    flow: null,
    linearPathStopsCoordinates: null,
    linearPathStopsLabels: null,
    linearSpanHeadingDegrees: null,
    linearSpanWidthMm: null,
    pivotCenterGpsLocation: null,
    pivotPathStopsCoordinates: null,
    pivotPathStopsHeadingsDegrees: null,
    pivotPathStopsLabels: null,
    pivotRadiusMeters: null,
    pressure: null,
    pressureSwitch: null,
    reel: null,
    vfd: null,
    wheel: null,
  }
  switch (installationType) {
    case "reel_with_booster_off_only":
    case "reel": {
      result.reel = {
        hoseDiameterMm: null,
        linearSpeedMmHMax: SENSOR_DEFAULTS.reel.linearSpeedMmHMax,
        linearSpeedMmHMin: SENSOR_DEFAULTS.reel.linearSpeedMmHMin,
        nMagnets: 16,
        nNozzles: null,
        nWrapsOuterLayer: null,
        nozzleDiameterMm: null,
        outerHoseWrapRadiusMm: null,
        sensorPort: 1,
        sprinklerType: "gun",
        swathWidthMm: null,
        widthMm: null,
      }
      break
    }
    case "prototype": {
      break
    }
    case "pump_vfd":
    case "pump_on_off":
    case "pump_off_only":
    case "pump": {
      if (hardwareGeneration === "PC1") {
        result.pressureSwitch = {
          ...SENSOR_DEFAULTS.pressureSwitch,
        }
      } else {
        result.pressure = {
          ...SENSOR_DEFAULTS.pressure,
        }
      }
      break
    }
    case "center_pivot":
    case "linear_move":
    case "traveller_soft": {
      result.wheel = {
        diameterMm: null,
        milliRpmFast: null,
        milliRpmSlow: null,
        nMagnets: null,
        sensorPort: 1,
      }
      result.linearSpanWidthMm = 100 * 1000
      result.linearSpanHeadingDegrees = 0
      break
    }
    case "unconfigured": {
      break
    }
    // TODO: IMPLEMENT NEW INSTALLATION TYPES
    case "valve": {
      result.pressure = {
        ...SENSOR_DEFAULTS.pressure,
        sensorPort: 1,
        thresholdPsiLower: 20,
        thresholdPsiUpper: 80,
      }
      result.flow = {
        ...SENSOR_DEFAULTS.flow,
        sensorPort: 2,
      }
      break
    }
  }
  return result
}

/**
 *
 */
function coerceRawSensorValueToNumberIfPossible({
  fieldName,
  measurementPreference,
  rawValue,
}: MeasurementPreferenceProps & {
  fieldName: SensorConfigKey
  rawValue: unknown
}): number | undefined {
  // We need to know whether the user sees this value as a fraction or not
  const sigDigits =
    getSensorConversionSpec({
      fieldName,
      measurementPreference,
      target: "user",
    })?.sigDigits ?? 0

  let result: number | undefined
  if (typeof rawValue === "string") {
    try {
      // Most values do not have decimals
      if (sigDigits === 0) {
        result = Number.parseInt(rawValue)
      } else {
        // For those that do, round them to appropriate sig digits
        result = Geo.round(Number.parseFloat(rawValue), sigDigits)
      }
    } catch (error) {
      fileLogger.debug(error)
    }
  } else if (isValidNumber(rawValue)) {
    result = rawValue
  }
  return result
}

/**
 * For each value in the sensor configuration,
 * find out if the value needs to be converted based on the field name.
 * If it does, try to coerce the value to a valid number, then convert it,
 * replacing the 'raw' value in the sensor configuration with the converted
 * value. Otherwise, leave the value as is - it could be null/undefined,
 * be a string (e.g. GPS chip type), or an empty string
 */
function convertSensorConfiguration<T extends ConfigSensorName>({
  measurementPreference,
  target,
  values,
}: MeasurementPreferenceProps & {
  target: ConversionTarget
  values: Partial<SensorConfigurations[T]>
}) {
  return Object.entries(values).reduce(
    (acc, entry) => {
      const [fieldName, rawValue] = entry as Entry<SensorConfigurations[T]>
      const valueAsNumber = coerceRawSensorValueToNumberIfPossible({
        fieldName,
        measurementPreference,
        rawValue,
      })
      if (requiresConversion(fieldName) && isValidNumber(valueAsNumber)) {
        const convertedValue = convertSensorValue({
          fieldName,
          measurementPreference,
          rawValue: valueAsNumber,
          target,
        })
        return { ...acc, [fieldName]: convertedValue }
      }
      return acc
    },
    { ...values },
  )
}
type ConfigSensorData = Pick<
  DeviceConfiguration,
  | ConfigSensorName
  | "linearPath"
  | "linearPathStopsLabels"
  | "linearSpanHeadingDegrees"
  | "linearSpanWidthMm"
  | "pivotRadiusMeters"
>

/**
 * For each sensor in the configuration, convert values based on the target
 */
export function useConvertConfiguration() {
  const measurementPreference = useRootSelector(
    getUserMeasurementPreferenceFromState,
  )
  return React.useCallback(
    (deviceConfiguration: ConfigSensorData, target: ConversionTarget) => {
      return CONFIG_SENSOR_NAMES.reduce(
        (accumulator, sensorName) => {
          const values = accumulator[sensorName]
          if (values) {
            return {
              ...accumulator,
              [sensorName]: convertSensorConfiguration({
                measurementPreference,
                target,
                values,
              }),
            }
          }
          return accumulator
        },
        {
          ...deviceConfiguration,
          pivotRadiusMeters:
            typeof deviceConfiguration.pivotRadiusMeters === "number"
              ? convertSensorValue({
                  fieldName: "pivotRadiusMeters",
                  measurementPreference,
                  rawValue: deviceConfiguration.pivotRadiusMeters,
                  target,
                })
              : null,
        },
      )
    },
    [measurementPreference],
  )
}

export interface ProviderProps {
  codaDeviceAlias: string

  /**
   * This needs to be provided in order to initialize the form with
   * the current configuration data converted to the user's preferred
   * measurement system which will be held in the form while it is modified
   */
  currentConfigurationData: ConfigSensorData
  deviceId: string
  deviceInstallationType: InstallationType
  deviceName: string | undefined
  hardwareGeneration: HardwareGeneration
  onCancel: () => void
  onClearInstallationType: (() => void) | null
  onSuccess: (response?: GetResponseDataType<"CreateConfiguration">) => void
}

/**
 *
 */
function useCreateConfiguration({
  // SetLinearPathComponent,
  codaDeviceAlias,
  currentConfigurationData,
  deviceId,
  deviceInstallationType,
  deviceName,
  hardwareGeneration,
  onCancel,
  onClearInstallationType,
  onSuccess,
}: ProviderProps) {
  const measurementPreference = useMeasurementPreference()
  const convertConfiguration = useConvertConfiguration()
  const isMetric = useMeasurementPreference() === "metric"
  const isAdminModeEnabled = useRootSelector(getIsAdminModeEnabledFromState)
  const { handleError, isLoading, sendRequest, toasts, ...rest } =
    useBackendRequest(createConfigurationAsync)

  const form = useForm<
    DeviceConfiguration & {
      linearPathEnd: Geo.Coordinates | null | undefined
      linearPathStart: Geo.Coordinates | null | undefined
    }
  >({
    defaultValues: {
      codaDeviceAlias,
      deviceId,
      deviceInstallationType,
      deviceName: deviceName ?? codaDeviceAlias,
      linearSpanHeadingDegrees: 0,
      linearSpanWidthMm: isMetric ? 100 : 300,
      ...convertConfiguration(currentConfigurationData, "user"),
    },
  })

  return {
    ...rest,
    configuration: currentConfigurationData,
    deviceId,
    form,
    isLoading,
    onCancel,
    onClearInstallationType: onClearInstallationType ?? undefined,
    onSubmit: form.handleSubmit(
      /**
       * Convert the user input to the database storage format and
       * create a new configuration on the backend.
       *
       * @param formValues - values in the users preferred measurement system
       */
      (formValues) => {
        // Convert the sensor values to the format that the backend/db expect
        const convertedValues = convertConfiguration(formValues, "database")

        /**
         * Initialize the empty sensor data
         */
        const resultSensorData: CreateConfigFormSensorValues = {
          flow: null,
          linearPathStopsCoordinates: null,
          linearPathStopsLabels: null,
          linearSpanHeadingDegrees: null,
          linearSpanWidthMm: null,
          pivotCenterGpsLocation: null,
          pivotPathStopsCoordinates: null,
          pivotPathStopsHeadingsDegrees: null,
          pivotPathStopsLabels: null,
          pivotRadiusMeters: null,
          pressure: null,
          pressureSwitch: null,
          reel: null,
          vfd: null,
          wheel: null,
        }
        resultSensorData.flow = convertedValues.flow ?? null

        switch (deviceInstallationType) {
          case "prototype": {
            if (!isAdminModeEnabled) {
              throw new TypeError(
                `User is attempting to manage configuration for a prototype 
                device while admin mode is not enabled`,
              )
            }
            break
          }
          case "pump_off_only":
          case "pump_on_off":
          case "pump_vfd":
          case "pump": {
            if (hardwareGeneration === "PC1") {
              // PC1 had a pressure switch and no pressure sensor
              if (!convertedValues.pressureSwitch) {
                throw new TypeError(`No values for pressur switch`)
              }
              resultSensorData.pressureSwitch = convertedValues.pressureSwitch
            } else if (convertedValues.pressure) {
              // All other pumps have a pressure sensor
              // if (!convertedValues.pressure) {
              //   throw new TypeError(`No values for pressure sensor`)
              // }
              resultSensorData.pressure = convertedValues.pressure
            }
            if (deviceInstallationType === "pump_vfd") {
              // VFD io pin
              resultSensorData.vfd = {
                i2CAddress: convertedValues.vfd?.i2CAddress,
                ioPin: convertedValues.vfd?.ioPin,
                // TODO: Add these fields to the form
                maxTrackingPercentage:
                  convertedValues.vfd?.maxTrackingPercentage,
                minTrackingPercentage:
                  convertedValues.vfd?.minTrackingPercentage,
              }
            }
            break
          }
          case "reel_with_booster_off_only":
          case "reel": {
            if (!convertedValues.reel) {
              throw new TypeError("No values for reel")
            }
            if (typeof convertedValues.reel.sensorPort === "undefined") {
              throw new TypeError("Sensor port cannot be undefined")
            }
            resultSensorData.reel = convertedValues.reel

            if (convertedValues.pressure) {
              resultSensorData.pressure = convertedValues.pressure
            }

            break
          }
          case "linear_move":
          case "center_pivot":
          case "traveller_soft": {
            if (convertedValues.wheel) {
              resultSensorData.wheel = convertedValues.wheel
            }
            if (convertedValues.pressure) {
              resultSensorData.pressure = convertedValues.pressure
            }
            // Handle linear move setup data
            if (formValues.linearPathStart && formValues.linearPathEnd) {
              const labels: string[] = ["Start"]
              const coordinates: Geo.Coordinates[] = [
                formValues.linearPathStart,
              ]
              for (const [index, item] of (
                formValues.linearPath ?? []
              ).entries()) {
                labels.push(item.label ?? `Spot ${index + 1}`)
                coordinates.push(item.coordinates)
              }
              labels.push("End")
              coordinates.push(formValues.linearPathEnd)

              resultSensorData.linearPathStopsCoordinates = coordinates
              resultSensorData.linearPathStopsLabels = labels
              // To save time, we are pretending this is swath width to find
              // the correct conversion factor
              // TODO: Incorporate this into convertSensorValue
              resultSensorData.linearSpanWidthMm = convertSensorValue({
                fieldName: "swathWidthMm",
                measurementPreference,
                rawValue: formValues.linearSpanWidthMm ?? 0,
                target: "database",
              })
              resultSensorData.linearSpanHeadingDegrees =
                formValues.linearSpanHeadingDegrees ?? null
            }
            // Special handling for center pivots
            if (formValues.deviceInstallationType === "center_pivot") {
              // Grab the center from the form
              // if (!formValues.pivotCenterGpsLocation) {
              //   throw new TypeError(
              //     "In useCreateConfiguration: Center pivot location is required",
              //   )
              // }
              resultSensorData.pivotCenterGpsLocation =
                formValues.pivotCenterGpsLocation ?? null

              // Grab the radius from the form
              // if (typeof formValues.pivotRadiusMeters !== "number") {
              //   throw new TypeError(
              //     "In useCreateConfiguration: Pivot radius must be a number",
              //   )
              // }
              resultSensorData.pivotRadiusMeters =
                convertedValues.pivotRadiusMeters ?? null

              // Initialize coordinates and labels...these will be
              // empty arrays for circular pivots
              resultSensorData.pivotPathStopsCoordinates = []
              resultSensorData.pivotPathStopsLabels = []

              // Grab the stop angles from the form if any exist
              const [stopFirst, stopLast] =
                formValues.pivotPathStopsHeadingsDegrees ?? []
              if (
                typeof stopFirst === "number" &&
                typeof stopLast === "number" &&
                stopFirst !== stopLast
              ) {
                // We only need to record these if they are different;
                // meaning that the pivot is not irrigating a full circle
                resultSensorData.pivotPathStopsHeadingsDegrees = [
                  Math.round(stopFirst),
                  Math.round(stopLast),
                ]
                // Unsure why we need to record these, but we do
                resultSensorData.pivotPathStopsLabels = ["Start", "End"]
              }
              // TODO: Add support for pivot path stops
            }
            break
          }
          case "unconfigured": {
            break
          }
          case "valve": {
            resultSensorData.pressure = convertedValues.pressure ?? null
            resultSensorData.flow = convertedValues.flow ?? null
            break
          }
        }

        sendRequest({
          deviceId,
          deviceInstallationType: formValues.deviceInstallationType,
          deviceName: formValues.deviceName ?? codaDeviceAlias,
          ...resultSensorData,
        })
          .then((response) => {
            toasts.success()
            return onSuccess(response)
          })
          .catch((error) => {
            handleError(error, {
              toastMessage: "default",
            })
          })
      },
    ),
  }
}

type ContextValue = ReturnType<typeof useCreateConfiguration>
const Context = React.createContext<ContextValue | undefined>(undefined)

/**
 *
 */
export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useCreateConfiguration(rest)
  return (
    <Context.Provider value={value}>
      <FormProvider {...value.form}>{children}</FormProvider>
    </Context.Provider>
  )
}

/**
 *
 */
export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(
      `useCreateConfigurationContext must be used inside of provider`,
    )
  }
  return ctx
}

getIconForSensor
//TODO: Make these colors actually work for the buttons
const INSTALLATION_TYPE_OPTIONS: Array<{
  value: InstallationType
}> = [
  {
    value: "reel",
  },
  {
    value: "reel_with_booster_off_only",
  },
  {
    value: "pump",
  },
  {
    value: "traveller_soft",
  },
  {
    value: "linear_move",
  },
  {
    value: "center_pivot",
  },
  {
    value: "valve",
  },
]

/**
 *
 */
export function ChooseInstallationType({
  onChange,
}: {
  onChange: (nextValue: InstallationType) => void
}): React.JSX.Element {
  const { t } = useTranslation("deviceConfiguration")

  return (
    <Box id="choose-installation-type">
      <TitleWithIcon
        IconComponent="DeviceConfiguration"
        mb="$2"
        titleText={t("chooseInstallationType")}
      />

      {INSTALLATION_TYPE_OPTIONS.map(({ value }, index) => {
        const text = t(`deviceInstallationTypes.${value}`, { ns: "common" })
        const IconComponent = getIconForInstallationType(value)
        const onPress = () => onChange(value)
        const element = (
          <MenuItemButton
            key={value}
            IconComponent={IconComponent}
            id={value}
            text={text}
            onPress={onPress}
          />
        )

        if (index < INSTALLATION_TYPE_OPTIONS.length - 1) {
          return (
            <React.Fragment key={value}>
              {element}
              <Divider />
            </React.Fragment>
          )
        }
        return element
      })}
    </Box>
  )
}

/**
 * If a device has a pressure sensor, shows the pressure threshold form
 */
export function DoesItHavePressureSensor({
  children,
  ...rest
}: BoxProps): React.JSX.Element {
  const { t } = useTranslation("deviceConfiguration")
  const { control, watch } = useFormContext<DeviceConfiguration>()
  const hasPressure = Boolean(watch("pressure"))
  const deviceInstallationType = watch("deviceInstallationType")
  const hardwareGeneration = watch("hardwareGeneration")

  const promptText: string = t("doesItHaveAPressureSensor")

  const Values = {
    no: "no",
    yes: "yes",
  } as const
  return (
    <Box id="pressure-sensor-yes-or-no" w="$full" {...rest}>
      <Box mb="$4">
        <AppText>{promptText}</AppText>
      </Box>
      <Controller
        control={control}
        name="pressure"
        render={({ field: { onChange, value } }) => {
          const handleChange = (nextValue: string) => {
            let pressureConfig: SensorConfig<"pressure"> | null
            if (nextValue === Values.yes) {
              let otherValueAsNumber: number | undefined

              for (const sensorName of MUTABLE_SENSOR_NAMES) {
                if (sensorName !== "pressure") {
                  // Look for another sensor with a sensor port and
                  // parse its value
                  const otherValue = watch(`${sensorName}.sensorPort`)
                  if (typeof otherValue === "string") {
                    otherValueAsNumber = Number.parseInt(otherValue)
                  } else if (typeof otherValue === "number") {
                    otherValueAsNumber = otherValue
                  }
                }
              }
              const resetValues =
                getDefaultSensorValues({
                  hardwareGeneration,
                  installationType: deviceInstallationType,
                }).pressure ?? SENSOR_DEFAULTS.pressure

              // If there is another sensor with a sensor port,
              // make sure this gets initialized to the OTHER port
              pressureConfig = {
                ...resetValues,
                sensorPort:
                  otherValueAsNumber === SensorPorts.ONE
                    ? SensorPorts.TWO
                    : SensorPorts.ONE,
              }
            } else {
              pressureConfig = null
            }
            onChange(pressureConfig)
          }
          const currentValue = Boolean(value) ? Values.yes : Values.no
          return (
            <Radio
              accessibilityLabel={promptText}
              orientation="horizontal"
              selectedValue={currentValue}
              options={[
                {
                  label: t(Values.yes, { ns: "common" }),
                  value: Values.yes,
                },
                {
                  label: t(Values.no, { ns: "common" }),
                  value: Values.no,
                },
              ]}
              onChange={handleChange}
            />
          )
        }}
      />
      {hasPressure ? (
        <React.Fragment>
          <PressureSliderConnected />
          {children}
        </React.Fragment>
      ) : null}
    </Box>
  )
}
export function Buttons(
  props: Omit<ActionButtonProps, "onPressSubmit">,
): React.JSX.Element {
  const { isLoading, onSubmit } = useContext()
  return (
    <ActionButtons {...props} isLoading={isLoading} onPressSubmit={onSubmit} />
  )
}

function SupportImage({
  altText,
  captionText,
  fileName,
  style,
  ...rest
}: Omit<ImageProps, "source"> & {
  altText: string
  captionText: string | null
  fileName: string
}) {
  return (
    <Box>
      <Image
        alt={altText}
        source={{ uri: Urls.buildSupportImageUrl(fileName) }}
        style={useStyle(() => {
          return [
            {
              height: SIZES.$64,
              width: "100%",
            },
            style,
          ]
        })}
        {...rest}
      />
      {typeof captionText === "string" ? (
        <AppText colorScheme="secondary">{captionText}</AppText>
      ) : null}
    </Box>
  )
}

/**
 *
 */
function CreateReelHelpContent({
  stage,
}: {
  stage: keyof SensorConfig<"reel">
}) {
  const { t } = useTranslation("sensorFields")
  const getUnitLabel = useSensorUnitLabel()

  const labelLength = "long"
  switch (stage) {
    case "nozzleDiameterMm": {
      return (
        <Box>
          <Box mb="$1">
            <AppText>
              {t("nozzleDiameterMm.instructionsWithUnitLabel", {
                unitLabel: getUnitLabel("nozzleDiameterMm", { labelLength }),
              })}
            </AppText>
          </Box>
          <SupportImage
            altText={t("nozzleDiameterMm.images.nozzle_diameter.png.altText")}
            fileName="nozzle_diameter.png"
            resizeMode="cover"
            style={{ height: SIZES.$80 }}
            captionText={t(
              "nozzleDiameterMm.images.nozzle_diameter.png.caption",
            )}
          />
        </Box>
      )
    }
    case "nNozzles": {
      return (
        <Box>
          <Box mb="$1">
            <AppText>{t("nNozzles.description")}</AppText>
          </Box>
          <Box mb="$1">
            <SupportImage
              altText={t("nNozzles.images.irrigation_boom.png.caption")}
              captionText={t("nNozzles.images.irrigation_boom.png.caption")}
              fileName="irrigation_boom.png"
            />
          </Box>
          <SupportImage
            altText={t("nNozzles.images.irrigation_gun.png.caption")}
            captionText={t("nNozzles.images.irrigation_gun.png.caption")}
            fileName="irrigation_gun.png"
          />
        </Box>
      )
    }
    case "swathWidthMm": {
      return (
        <AppText>
          {t("swathWidthMm.descriptionWithUnitLabel", {
            unitLabel: getUnitLabel("swathWidthMm", {
              labelLength,
            }),
          })}
        </AppText>
      )
    }
    case "sprinklerType": {
      return <AppText>{t("sprinklerType.description")}</AppText>
    }
    case "outerHoseWrapRadiusMm": {
      return (
        <View>
          <Box mb="$2">
            <AppText>{t("outerHoseWrapRadiusMm.description")}</AppText>
          </Box>
          <Box mb="$2">
            <AppText>
              {t("outerHoseWrapRadiusMm.instructions.introduction")}
            </AppText>
          </Box>
          <Box mb="$2">
            <AppText>
              {t("outerHoseWrapRadiusMm.instructions.options.1")}
            </AppText>
          </Box>
          <Box mb="$2">
            <AppText>
              {t("outerHoseWrapRadiusMm.instructions.options.2")}
            </AppText>
          </Box>
          <Box mb="$2">
            <SupportImage
              captionText={null}
              fileName="outer_hose_wrap_radius.png"
              altText={t(
                "outerHoseWrapRadiusMm.images.reel_outer_hose_wraps.png.altText",
              )}
            />
          </Box>
        </View>
      )
    }
    case "nWrapsOuterLayer": {
      return (
        <View>
          <Box mb="$2">
            <AppText>{t("nWrapsOuterLayer.description")}</AppText>
          </Box>
          <SupportImage
            fileName="reel_outer_hose_wraps.png"
            altText={t(
              "nWrapsOuterLayer.images.reel_outer_hose_wraps.png.caption",
            )}
            captionText={t(
              "nWrapsOuterLayer.images.reel_outer_hose_wraps.png.caption",
            )}
          />
        </View>
      )
    }
    case "hoseDiameterMm": {
      return (
        <View>
          <Box mb="$2">
            <AppText>{t("hoseDiameterMm.instructions.1")}</AppText>
          </Box>
          <Box mb="$2">
            <AppText>{t("hoseDiameterMm.instructions.2")}</AppText>
          </Box>
          <SupportImage
            altText={t("hoseDiameterMm.images.hose_diameter.png.caption")}
            captionText={t("hoseDiameterMm.images.hose_diameter.png.caption")}
            fileName="hose_diameter.png"
          />
        </View>
      )
    }
    case "widthMm": {
      return (
        <View>
          <Box mb="$2">
            <AppText>{t("widthMm.instructions")}</AppText>
          </Box>
          <SupportImage
            altText={t("widthMm.images.reel_width.png.caption")}
            captionText={t("widthMm.images.reel_width.png.caption")}
            fileName="reel_width.png"
            style={{ height: SIZES.$96 }}
          />
        </View>
      )
    }
    case "nMagnets": {
      return (
        <View>
          <Box mb="$2">
            <AppText>{t("nMagnets.description")}</AppText>
          </Box>
          <SupportImage
            fileName="magnet_installation_positions.png"
            altText={t(
              "nMagnets.images.magnet_installation_positions.png.caption",
            )}
            captionText={t(
              "nMagnets.images.magnet_installation_positions.png.caption",
            )}
          />
        </View>
      )
    }
    case "sensorPort": {
      return (
        <View>
          <Box mb="$2">
            <AppText>{t("sensorPort.description")}</AppText>
          </Box>
          <SupportImage
            altText={t("sensorPort.images.enclosure_front.png.caption")}
            captionText={t("sensorPort.images.enclosure_front.png.caption")}
            fileName="enclosure_front.png"
          />
        </View>
      )
    }
    case "linearSpeedMmHMin": {
      return null
    }
    case "linearSpeedMmHMax": {
      return null
    }
  }
}

const CREATE_REEL_STAGES = [
  // nozzle
  "nozzleDiameterMm",
  "nNozzles",
  "swathWidthMm",
  "sprinklerType",
  // hose
  "outerHoseWrapRadiusMm",
  "nWrapsOuterLayer",
  "hoseDiameterMm",
  // spool
  "widthMm",
  "nMagnets",
  // Misc.
  "sensorPort",
  "pressureSensor",
  "deviceName",
  "confirm",
] as const
/**
 * New Create Reel Flow
 */
export function ConfigureReel() {
  const { t } = useTranslation("deviceConfiguration")
  const [stage, setStage] = React.useState<(typeof CREATE_REEL_STAGES)[number]>(
    CREATE_REEL_STAGES[0],
  )

  const stageIndex = CREATE_REEL_STAGES.indexOf(stage)
  const { form, isLoading, onClearInstallationType, onSubmit } = useContext()

  const goForward = form.handleSubmit(() => {
    setStage((prev) => {
      return CREATE_REEL_STAGES[stageIndex + 1] ?? prev
    })
  })

  const goBack = () => {
    const currentStage = CREATE_REEL_STAGES.indexOf(stage)
    if (currentStage === 0) {
      if (onClearInstallationType) {
        onClearInstallationType()
      }
    } else {
      setStage((prev) => {
        return CREATE_REEL_STAGES[stageIndex - 1] ?? prev
      })
    }
  }

  const width = "$full"
  const isFinalStage = stage === "confirm"

  return (
    //
    <View>
      <TitleWithIcon
        IconComponent={<AppIcons.DeviceReel />}
        mb="$2"
        titleText={t("setUpReel")}
      />

      <Box minH="$24" w="$full">
        {stage === "confirm" ? (
          <Box id="confirm-configuration">
            <SensorConfigValuesList sensorName="reel" />
            <SensorConfigValuesList sensorName="pressure" />
          </Box>
        ) : stage === "deviceName" ? (
          <DeviceNameInput />
        ) : stage === "sensorPort" ? (
          <ChooseSensorPort sensorName="reel" w={width} />
        ) : stage === "pressureSensor" ? (
          <DoesItHavePressureSensor />
        ) : stage === "sprinklerType" ? (
          <ChooseSprinklerType w={width} />
        ) : stage === "nMagnets" ? (
          <MagnetSelect sensorName="reel" w={width} />
        ) : (
          <SensorTextInput fieldName={stage} sensorName="reel" w={width} />
        )}
      </Box>
      <Row w="$full">
        <Box flex={1} mr="$4">
          <Button
            flex={1}
            isDisabled={isLoading}
            text="back"
            variant="outline"
            onPress={goBack}
          />
        </Box>
        {isFinalStage ? (
          <SubmitButton
            flex={1}
            id="submit-btn"
            isLoading={isLoading}
            variant="primary"
            onPress={onSubmit}
          />
        ) : (
          <Button
            flex={1}
            id="submit-btn"
            text={t("next", { ns: "common" })}
            variant="primary"
            onPress={goForward}
          />
        )}
      </Row>
      {stage === "deviceName" ||
      stage === "confirm" ||
      stage === "pressureSensor" ? null : (
        <Box py="$2">
          <CreateReelHelpContent stage={stage} />
        </Box>
      )}
    </View>
  )
}

function PumpTypeOption({
  IconComponent,
  labelText,
  onChange,
  ...rest
}: BoxProps & {
  IconComponent: IconProp
  labelText: string
  onChange: () => void
}) {
  const { t } = useTranslation()
  return (
    <Box alignItems="center" my="$1" {...rest}>
      <TextWithIcon IconComponent={IconComponent} text={labelText} />
      <Box ml="auto" mt="$2">
        <Button
          id="submit-btn"
          text={t(`select`, { ns: "common" })}
          onPress={onChange}
        />
      </Box>
    </Box>
  )
}

/**
 *
 */
export function CreatePump(): React.JSX.Element {
  const { setValue, watch } = useFormContext<DeviceConfiguration>()
  const { t } = useTranslation("deviceConfiguration")
  const isLoading = useIsPending("CreateConfiguration")

  const installationType = watch("deviceInstallationType")

  if (installationType === "pump") {
    return (
      <View>
        <TitleWithIcon
          IconComponent={<AppIcons.DevicePump />}
          mb="$4"
          titleText={t("choosePumpTypeTitle")}
        />
        <Box mb="$4">
          <AppText>{t("choosePumpTypeInstructions")}</AppText>
        </Box>
        <PumpTypeOption
          IconComponent="DevicePump"
          id="pump_off_only"
          labelText={t("choosePumpType_pump_off_only.description")}
          onChange={() => setValue("deviceInstallationType", "pump_off_only")}
        />
        <Divider my="$2" />
        <PumpTypeOption
          IconComponent="VoltageBolt"
          id="pump_on_off"
          labelText={t("choosePumpType_pump_on_off.description")}
          onChange={() => setValue("deviceInstallationType", "pump_on_off")}
        />
        <Divider my="$2" />
        <PumpTypeOption
          IconComponent="PumpOn"
          id="pump_vfd"
          labelText={t("choosePumpType_pump_vfd.description")}
          onChange={() => setValue("deviceInstallationType", "pump_vfd")}
        />
      </View>
    )
  }

  let labelText: string | undefined
  switch (installationType) {
    case "pump_off_only": {
      labelText = t("choosePumpType_pump_off_only.label")
      break
    }
    case "pump_on_off": {
      labelText = t("choosePumpType_pump_off_only.label")
      break
    }
    case "pump_vfd": {
      labelText = t("choosePumpType_pump_off_only.label")
      break
    }
    default: {
      break
    }
  }

  const pumpTypeIndicator =
    typeof labelText === "string" ? (
      <Row>
        <AppText>{t("pumpType")}</AppText>
        <AppText>{labelText}</AppText>
      </Row>
    ) : null

  const handleClearPumpType = () => setValue("deviceInstallationType", "pump")
  if (watch("hardwareGeneration") === "PC1") {
    return (
      <React.Fragment>
        {pumpTypeIndicator}
        <DeviceNameInput />
        <ChooseSwitchType sensorName="pressureSwitch" />
        <ChooseSensorPort sensorName="pressureSwitch" />
        <Buttons isLoading={isLoading} onPressCancel={handleClearPumpType} />
      </React.Fragment>
    )
  }
  return (
    <View>
      <Box mb="$2">{pumpTypeIndicator}</Box>
      <Box mb="$2">
        <DeviceNameInput />
      </Box>
      <Box mb="$2">
        <ChooseSensorPort sensorName="pressure" />
      </Box>
      <Box mb="$2">
        <PressureSliderConnected />
      </Box>
      {installationType === "pump_vfd" ? (
        <Row mb="$2">
          <Box flexGrow={1} mr="$2">
            <SensorTextInput
              fieldName="minTrackingPercentage"
              sensorName="vfd"
            />
          </Box>
          <Box flexGrow={1}>
            <SensorTextInput
              fieldName="maxTrackingPercentage"
              sensorName="vfd"
            />
          </Box>
        </Row>
      ) : null}
      <Buttons isLoading={isLoading} onPressCancel={handleClearPumpType} />
    </View>
  )
}

/**
 * Initial configuration form for travelers, center-pivots, and linear-move
 */
export function CreateTraveler({
  iconElement,
  id,
  titleText,
}: {
  iconElement: React.JSX.Element
  id: TestId
  titleText: string
}): React.JSX.Element {
  enum Stages {
    diameterMm = 0,
    nMagnets = 1,
    sensorPort = 2,
    pressureSensor = 3,
    // configurePathYesOrNo = 4,
    // configurePath = 5,
    deviceName = 4,
  }
  const context = useContext()
  const isLoading = context.isLoading

  const { handleSubmit } = useFormContext<DeviceConfiguration>()

  const [stage, setStage] = React.useState<Stages>(0)

  const { t } = useTranslation("deviceConfiguration")
  const space = "$6"
  const stackProps: ViewProps = {
    ...testIds(id),
  }
  const goBack = () => setStage((prev) => prev - 1)
  const goForward = handleSubmit(() => setStage((prev) => prev + 1))

  const submitText: string = t(
    stage === Stages.deviceName ? "submit" : "next",
    { ns: "common" },
  )
  const cancelText: string = t("back", { ns: "common" })
  switch (stage) {
    case Stages.diameterMm: {
      return (
        <View {...stackProps}>
          <TitleWithIcon
            IconComponent={iconElement}
            mb={space}
            titleText={titleText}
          />
          <Box mb={space}>
            <InstructionsText>
              {t("wheelDiameterMmInstructions")}
            </InstructionsText>
          </Box>
          <Box mb={space}>
            <SensorTextInput
              _input={{ autoFocus: true }}
              fieldName="diameterMm"
              sensorName="wheel"
            />
          </Box>
          <ActionButtons
            cancelText={cancelText}
            isLoading={isLoading}
            submitText={submitText}
            onPressCancel={context.onClearInstallationType}
            onPressSubmit={goForward}
          />
        </View>
      )
    }
    case Stages.nMagnets: {
      return (
        <View {...stackProps}>
          <Box mb={space}>
            <TitleWithIcon IconComponent={iconElement} titleText={titleText} />
          </Box>
          <Box mb={space}>
            <InstructionsText>
              {t("wheelNMagnetsInstructions")}
            </InstructionsText>
          </Box>
          <Box mb={space}>
            <MagnetSelect sensorName="wheel" />
          </Box>
          <ActionButtons
            cancelText={cancelText}
            isLoading={isLoading}
            submitText={submitText}
            onPressCancel={goBack}
            onPressSubmit={goForward}
          />
        </View>
      )
    }
    case Stages.sensorPort: {
      return (
        <View {...stackProps}>
          <Box mb={space}>
            <TitleWithIcon IconComponent={iconElement} titleText={titleText} />
          </Box>
          <Box mb={space}>
            <InstructionsText>
              {t("wheelSensorPortInstructions")}
            </InstructionsText>
          </Box>
          <Box mb={space}>
            <AlertCard severity="info" titleText={t("hintWithColon")}>
              <AlertBodyText fontSize="$sm">
                {t("sensorPortHint")}
              </AlertBodyText>
            </AlertCard>
          </Box>
          <Box mb={space}>
            <ChooseSensorPort sensorName="wheel" />
          </Box>
          <ActionButtons
            cancelText={cancelText}
            isLoading={isLoading}
            submitText={submitText}
            onPressCancel={goBack}
            onPressSubmit={goForward}
          />
        </View>
      )
    }
    case Stages.pressureSensor: {
      return (
        <View {...stackProps}>
          <Box mb={space}>
            <TitleWithIcon IconComponent={iconElement} titleText={titleText} />
          </Box>
          <Box mb={space}>
            <DoesItHavePressureSensor />
          </Box>
          <ActionButtons
            cancelText={cancelText}
            isLoading={isLoading}
            submitText={submitText}
            onPressCancel={goBack}
            onPressSubmit={() => {
              setStage(Stages.deviceName)
            }}
          />
        </View>
      )
    }

    case Stages.deviceName: {
      return (
        <View {...stackProps}>
          <Box mb={space}>
            <TitleWithIcon IconComponent={iconElement} titleText={titleText} />
          </Box>
          <Box mb={space}>
            <InstructionsText>
              {t("customizeDeviceNameInstructions")}
            </InstructionsText>
          </Box>
          <Box mb={space}>
            <DeviceNameInput />
          </Box>
          <ActionButtons
            cancelText={cancelText}
            isLoading={context.isLoading}
            submitText={submitText}
            onPressCancel={goBack}
            onPressSubmit={context.onSubmit}
          />
        </View>
      )
    }
  }
}

/**
 * Create/Update for Center Pivot
 */
function CreateCenterPivot() {
  const { t } = useTranslation("deviceConfiguration")
  return (
    <CreateTraveler
      iconElement={<AppIcons.DeviceCenterPivot />}
      id="create-center_pivot"
      titleText={t("setUpCenterPivot")}
    />
  )
}

/**
 * Create/Update for Linear Move
 */
function CreateLinearMove() {
  const { t } = useTranslation("deviceConfiguration")
  return (
    <CreateTraveler
      iconElement={<AppIcons.DeviceLinearMove />}
      id="create-linear_move"
      titleText={t("setUpLinearMove")}
    />
  )
}

/**
 * Create/Update for SoftHose Traveler
 */
function CreateSoftHoseTraveler() {
  const { t } = useTranslation("deviceConfiguration")
  return (
    <CreateTraveler
      iconElement={<AppIcons.DeviceTravellerSoft />}
      id="create-traveller_soft"
      titleText={t("setUpSoftHoseTraveler")}
    />
  )
}

function DoesItHaveFlowSensor({ ...rest }: ViewProps) {
  const { t } = useTranslation("deviceConfiguration")
  const { control, watch } = useFormContext<DeviceConfiguration>()
  const hasFlow = Boolean(watch("flow"))
  const deviceInstallationType = watch("deviceInstallationType")
  const hardwareGeneration = watch("hardwareGeneration")
  const measurementPreference = useMeasurementPreference()
  const promptText: string = t("doesItHaveAFlowSensor")

  const Values = { no: "no", yes: "yes" } as const
  return (
    <View {...testIds("flow-sensor-yes-or-no")} {...rest}>
      <Box mb="$4">
        <AppText>{promptText}</AppText>
      </Box>
      <Controller
        control={control}
        name="flow"
        render={({ field: { onChange, value } }) => {
          const handleChange = (nextValue: string) => {
            let flowConfig: SensorConfig<"flow"> | null
            if (nextValue === Values.yes) {
              let otherValueAsNumber: number | undefined

              for (const sensorName of MUTABLE_SENSOR_NAMES) {
                if (sensorName !== "flow") {
                  // Look for another sensor with a sensor port and
                  // parse its value
                  const otherValue = watch(`${sensorName}.sensorPort`)
                  if (typeof otherValue === "string") {
                    otherValueAsNumber = Number.parseInt(otherValue)
                  } else if (typeof otherValue === "number") {
                    otherValueAsNumber = otherValue
                  }
                }
              }
              const resetValues = convertSensorConfiguration({
                measurementPreference,
                target: "user",
                values:
                  getDefaultSensorValues({
                    hardwareGeneration,
                    installationType: deviceInstallationType,
                  }).flow ?? SENSOR_DEFAULTS.flow,
              })

              // If there is another sensor with a sensor port,
              // make sure this gets initialized to the OTHER port
              flowConfig = {
                ...SENSOR_DEFAULTS.flow,
                ...resetValues,
                sensorPort:
                  otherValueAsNumber === SensorPorts.ONE
                    ? SensorPorts.TWO
                    : SensorPorts.ONE,
              }
            } else {
              flowConfig = null
            }
            onChange(flowConfig)
          }
          const selectedValue = Boolean(value) ? Values.yes : Values.no
          return (
            <Radio
              selectedValue={selectedValue}
              options={[
                { label: t(Values.yes, { ns: "common" }), value: Values.yes },
                { label: t(Values.no, { ns: "common" }), value: Values.no },
              ]}
              onChange={handleChange}
            />
          )
        }}
      />
      {hasFlow ? (
        <React.Fragment>
          <Box mb="$4">
            <SensorTextInput fieldName="mlPerPulse" sensorName="flow" />
          </Box>
          <ChooseSensorPort sensorName="flow" />
        </React.Fragment>
      ) : null}
    </View>
  )
}

function CreateValve() {
  const { t } = useTranslation("deviceConfiguration")
  const { isLoading, onClearInstallationType, onSubmit } = useContext()

  enum Stages {
    pressureSensor = 0,

    flowSensor = 1,
  }

  const [stage, setStage] = React.useState<Stages>(0)
  let formContent: React.JSX.Element
  switch (stage) {
    case Stages.pressureSensor: {
      formContent = (
        <React.Fragment>
          <DoesItHavePressureSensor>
            <ChooseSensorPort sensorName="pressure" />
          </DoesItHavePressureSensor>
          <Box mt="$4">
            <ActionButtons
              isLoading={isLoading}
              onPressCancel={onClearInstallationType}
              onPressSubmit={() => setStage((prev) => prev + 1)}
            />
          </Box>
        </React.Fragment>
      )
      break
    }
    case Stages.flowSensor: {
      formContent = (
        <React.Fragment>
          <DoesItHaveFlowSensor />
          <Box mt="$4">
            <ActionButtons
              isLoading={isLoading}
              onPressCancel={() => setStage((prev) => prev - 1)}
              onPressSubmit={onSubmit}
            />
          </Box>
        </React.Fragment>
      )
      break
    }
  }
  return (
    <React.Fragment>
      <TitleWithIcon
        titleText={t("setUpValve")}
        IconComponent={
          <Avatar size="sm">
            <AppIcons.DeviceValve />
          </Avatar>
        }
      />
      {formContent}
    </React.Fragment>
  )
}

/**
 * Select form component based on installation type
 */
export function getFormComponent(installationType: InstallationType): React.FC {
  switch (installationType) {
    case "reel_with_booster_off_only":
    case "reel": {
      return ConfigureReel
    }
    case "prototype": {
      break
    }
    case "pump_vfd":
    case "pump_off_only":
    case "pump_on_off":
    case "pump": {
      return CreatePump
    }
    case "center_pivot": {
      return CreateCenterPivot
    }
    case "linear_move": {
      return CreateLinearMove
    }
    case "traveller_soft": {
      return CreateSoftHoseTraveler
    }
    case "unconfigured": {
      break
    }

    case "valve": {
      return CreateValve
    }
  }
  throw new TypeError(
    `getFormComponent: Not implemented: ${installationType} case`,
  )
}
