import _ from "lodash"

import { Box } from "@fhq/app/components"

import * as Tables from "./Table"

const itemCount = 6
export function TablesPage() {
  return (
    <Box maxW="$xl" mx="auto" my="$8" w="$full">
      <Tables.Table
        tableTitle="Title"
        columns={_.range(itemCount).map((value) => ({
          columnTitle: `Col ${value}`,
        }))}
        rows={[
          {
            rowData: _.range(itemCount),
            rowTitle: "Device1",
          },
          {
            rowData: _.range(itemCount),
            rowTitle: "Device2",
          },
        ]}
      />
    </Box>
  )
}
