import React from "react"
import { Platform, ScrollView as Base, StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { SPACING } from "./theme"

import type { TestId } from "./test-id"
import type { ScrollViewProps as BaseProps } from "react-native"
export type ScrollViewProps = Omit<BaseProps, "id"> & {
  id?: TestId
  variant?: "pageScroll" | "screenContainer"
}

const styles = StyleSheet.create({
  contentContainer: {
    padding: SPACING.$4,
  },
  pageScroll: {
    marginBottom: SPACING.$20,
  },
})

export const HEADER_LARGE_CHILD_SCROLL_ADJUSTMENT: ScrollViewProps["contentInsetAdjustmentBehavior"] =
  Platform.select({
    default: undefined,
    ios: "automatic",
  })

export const HEADER_LARGE_SCROLL_CHILD_PROPS = {
  contentAdjustmentBehavior: HEADER_LARGE_CHILD_SCROLL_ADJUSTMENT,
}

export const ScrollView = React.forwardRef<Base, ScrollViewProps>(
  function ScrollView({ contentContainerStyle, variant, ...rest }, ref) {
    return (
      <Base
        ref={ref}
        // removeClippedSubviews
        {...HEADER_LARGE_SCROLL_CHILD_PROPS}
        contentContainerStyle={useStyle(
          () => [
            variant === "screenContainer"
              ? styles.contentContainer
              : variant === "pageScroll"
              ? styles.pageScroll
              : undefined,
            contentContainerStyle,
          ],
          [contentContainerStyle, variant],
        )}
        {...rest}
      />
    )
  },
)
