import type { Reducer } from "@reduxjs/toolkit"

import React from "react"

import { createSlice, isAnyOf } from "@reduxjs/toolkit"

import {
  callNamedDeviceActionAsync,
  loadDeviceActivityAsync,
  loadDeviceFunctionCallsAsync,
  loadLastEventForDeviceAsync,
  requestUpdateFromDeviceAsync,
} from "./farmhq-api"
import * as Models from "./models"
import { useBackendRequest } from "./useBackendRequest"

import type { ValuesType } from "utility-types"
import type { RootState } from "./root.reducer"
import type { DeviceIdProps } from "./types"
/**
 * Commands in the form of string literals forwarded by our backend to devices
 */
export const RelayActionTypes = {
  activate: "RLYA",
  deactivate: "RLYD",
  toggleForDuration: "RLYAS",
} as const
export type RelayActionType = ValuesType<typeof RelayActionTypes>

/**
 * Includes other commands that can be sent to devices (non-relay)
 */
export type DeviceFunctionName =
  | RelayActionType
  | "send_device_event"
  | `call_device_commands`

export interface RelayAction {
  actionType: RelayActionType
  seconds: number | null
}

const deviceCommandsAdapter = Models.deviceCommandTracker.adapter

type DeviceCommandsState = Models.ModelState<
  Models.DeviceCommandTracker,
  string
> & {
  // selectedActionType?: RelayActionType
}

const initialState: DeviceCommandsState = { entities: {}, ids: [] }

const FORCE_UPDATE_MAX_RETRIES = 2

const deviceCommandsSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(loadLastEventForDeviceAsync.fulfilled, (state, { payload }) => {
        const deviceId = payload.deviceId
        const previous = state.entities[deviceId]
        const numTries = previous?.forceUpdateCount ?? 0

        /*
         * The response received dictates what we need to do next:
         */
        const newEventFound = payload.status === "SUCCESS"

        if (newEventFound || numTries === FORCE_UPDATE_MAX_RETRIES) {
          // Stop the update cycle by removing the device's tracker
          deviceCommandsAdapter.removeOne(state, deviceId)
        } else {
          // Increment the count, try again.
          deviceCommandsAdapter.setOne(state, {
            ...previous,
            deviceId,
            forceUpdateCount: numTries + 1,
          })
        }
      })
      .addCase(loadDeviceActivityAsync.fulfilled, (state) => {
        // If we get a fresh batch of events,
        // stop the force update cycle for all devices
        deviceCommandsAdapter.removeAll(state)
      })
      .addMatcher(
        isAnyOf(
          callNamedDeviceActionAsync.fulfilled,
          requestUpdateFromDeviceAsync.pending,
        ),
        /**
         * Initialize the force update cycle/
         */
        (state, { meta }) => {
          deviceCommandsAdapter.upsertOne(state, {
            actionId: meta.arg.actionId,
            deviceId: meta.arg.deviceId,
            forceUpdateCount: 0,
          })
        },
      )
      .addMatcher(
        isAnyOf(
          requestUpdateFromDeviceAsync.rejected,
          loadLastEventForDeviceAsync.rejected,
        ),
        (state, action) => {
          // If the request to load an event fails, cancel the process
          deviceCommandsAdapter.removeOne(state, action.meta.arg.deviceId)
        },
      ),
  initialState,
  name: "remoteControls",
  reducers: {},
})

export const deviceCommandsReducer: Reducer<typeof initialState> =
  deviceCommandsSlice.reducer
// export const { clearRelayActionConfirmation } = remoteControlsSlice.actions
/**
 * Load records of device function calls
 * TODO: Make these paginate as well
 */
export function useLoadDeviceFunctionCallHistory({ deviceId }: DeviceIdProps) {
  const { fetchStatus, handleError, sendRequest } = useBackendRequest(
    loadDeviceFunctionCallsAsync,
  )
  const handleFetch = React.useCallback(() => {
    sendRequest({ deviceId }).catch((error) => {
      handleError(error)
    })
  }, [deviceId, handleError, sendRequest])

  return {
    fetchStatus,
    handleFetch,
  }
}

export function getDeviceIsForcingUpdateByDeviceIdFromState(
  state: RootState,
  deviceId: string,
): boolean {
  return Boolean(state.deviceCommands.entities[deviceId])
}
