import React from "react"
import { View } from "react-native"

import { AppText } from "@fhq/app/components"
import { OverlayView } from "@react-google-maps/api"

import type { AppTextProps } from "@fhq/app/components"
export function FieldLabel({
  fieldName,
  labelRotation = 0,
  location,
  ...rest
}: Omit<AppTextProps, "children"> & {
  fieldName: string
  labelRotation: number | null | undefined
  location: google.maps.LatLngLiteral | undefined
}): React.JSX.Element | null {
  const labelStyle = {
    color: "white",
    textShadowRadius: 5,
    transform: [{ rotate: `${labelRotation ?? "0"}deg` }],
  }

  return location ? (
    <OverlayView mapPaneName="floatPane" position={location}>
      <View>
        <AppText {...rest} style={labelStyle}>
          {fieldName}
        </AppText>
      </View>
    </OverlayView>
  ) : null
}
