import React from "react"
import { StyleSheet, View } from "react-native"

import { Heading } from "./components"
import { testIds } from "./components/test-id"
import { SPACING } from "./components/theme"

import type { ViewProps } from "react-native"
import type { TestId } from "./components/test-id"

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  text: {
    marginBottom: SPACING.$2,
  },
})

/**
 *
 */

export function ErrorPageWrapper({
  children,
  testId,
  title,
  ...rest
}: ViewProps & {
  testId: TestId
  title: string
  children?: React.ReactNode
}): React.JSX.Element {
  return (
    <View style={styles.root} {...testIds(testId)} {...rest}>
      <Heading style={styles.text} variant="h2">
        {title}
      </Heading>
      {children}
    </View>
  )
}
