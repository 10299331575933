import { useTranslation } from "react-i18next"

import { makeValidator } from "./type-guards"

import type { DeepNonNullable, ValuesType } from "utility-types"
import type { SensorState } from "./sensor-events"

export type InstalledOrientation = `bow` | `port` | `starboard` | `stern`
export const SensorPorts = {
  ONE: 1,
  TWO: 2,
} as const
type SensorPort = ValuesType<typeof SensorPorts>

export type GpsType = "CAM_M8Q" | "SAM_M8Q"
export const HardwareGenerations = {
  FC2: `FC2`,
  PC1: `PC1`,
  TC3: `TC3`,
} as const
export const isValidHardwareGeneration = makeValidator(
  Object.values(HardwareGenerations),
)
export type HardwareGeneration = ValuesType<typeof HardwareGenerations>
export const SprinklerTypes = {
  BOOM: `boom`,
  GUN: `gun`,
} as const
export type SprinklerType = ValuesType<typeof SprinklerTypes>

export type ParticlePlatform = `argon` | `boron` | `bsom` | `tracker`
export const SwitchTypes = {
  CLOSED: `C`,
  OPEN: `O`,
} as const
export type SwitchType = ValuesType<typeof SwitchTypes>

export const InstallationTypes = {
  CENTER_PIVOT: `center_pivot`,
  LINEAR_MOVE: `linear_move`,
  PROTOTYPE: `prototype`,
  PUMP: `pump`,
  PUMP_OFF_ONLY: `pump_off_only`,
  PUMP_ON_OFF: `pump_on_off`,
  PUMP_VFD: `pump_vfd`,
  REEL: `reel`,
  REEL_WITH_BOOSTER: `reel_with_booster_off_only`,
  TRAVELLER_SOFT: `traveller_soft`,
  UNCONFIGURED: `unconfigured`,
  VALVE: `valve`,
} as const

export type InstallationTypeName = ValuesType<typeof InstallationTypes>

export type InstallationType<
  K extends InstallationTypeName = InstallationTypeName,
> = K extends K ? K : never

/**
 * Does it move?
 */
export function isTravellerInstallationType(
  deviceInstallationType: InstallationType | null | undefined,
) {
  switch (deviceInstallationType) {
    case "center_pivot":
    case "linear_move":
    case "traveller_soft": {
      return true
    }
    default: {
      return false
    }
  }
}
export function isReelInstallationType(
  deviceInstallationType: InstallationType | null | undefined,
) {
  switch (deviceInstallationType) {
    case "reel_with_booster_off_only":
    case "reel": {
      return true
    }
    default: {
      return false
    }
  }
}

export function useFormatInstallationType() {
  const { t } = useTranslation()
  return (deviceInstallationType: InstallationType) => {
    return t(`deviceInstallationTypes.${deviceInstallationType}`)
  }
}
/**
 *
 */
export function isDeviceInMotion(sensorState: SensorState | null | undefined) {
  if (
    sensorState === "WF" ||
    sensorState === "WL" ||
    sensorState === "RE" ||
    sensorState === "RR"
  ) {
    return true
  }
  return false
}
export type ParticleProductId = number

/**
 * The names of sensors that can be configured by users
 */
export const MUTABLE_SENSOR_NAMES = [
  `flow`,
  `pressure`,
  `pressureSwitch`,
  `reel`,
  `wheel`,
] as const

export type MutableSensorName = (typeof MUTABLE_SENSOR_NAMES)[number]

/**
 * Other sensors are configured by the system
 */
export const CONFIG_SENSOR_NAMES = [
  ...MUTABLE_SENSOR_NAMES,
  `battery`,
  `gps`,
  `hallSwitch`,
  `icm`,
  `relay`,
  `temperature`,
  `vfd`,
] as const
export interface SensorConfigurations {
  battery: {
    ioPin: number | null | undefined
    voltageIntercept: number | null | undefined
    voltageSlope: number | null | undefined
    voltageThresholdLowMv: number | null | undefined
  }
  flow: {
    mlPerPulse: number | null | undefined
    sensorPort: SensorPort | null | undefined
  }
  gps: {
    gpsType: GpsType | null | undefined
  }
  hallSwitch: {
    sensorPort: number | null | undefined
    switchType: SwitchType | null | undefined
    threshold: number | null | undefined
  }
  icm: {
    installedOrientation: InstalledOrientation | null | undefined
  }
  pressure: {
    calibrationIntercept: number | null | undefined
    calibrationSlope: number | null | undefined
    sensorPort: SensorPort | null | undefined
    thresholdPsiLower: number | null | undefined
    thresholdPsiUpper: number | null | undefined
  }
  pressureSwitch: {
    sensorPort: SensorPort | null | undefined
    switchType: SwitchType | null | undefined
    threshold: number | null | undefined
  }
  reel: {
    hoseDiameterMm: number | null | undefined
    linearSpeedMmHMax: number | null | undefined
    linearSpeedMmHMin: number | null | undefined
    nMagnets: number | null | undefined
    nNozzles: number | null | undefined
    nWrapsOuterLayer: number | null | undefined
    nozzleDiameterMm: number | null | undefined
    outerHoseWrapRadiusMm: number | null | undefined
    sensorPort: SensorPort | null | undefined
    sprinklerType: SprinklerType | null | undefined
    swathWidthMm: number | null | undefined
    widthMm: number | null | undefined
  }
  relay: {
    ioPin: number | null | undefined
  }
  temperature: {
    calibrationIntercept: number | null | undefined
    calibrationSlope: number | null | undefined
  }
  vfd: {
    i2CAddress: number | null | undefined
    ioPin: number | null | undefined
    maxTrackingPercentage: number | null | undefined
    minTrackingPercentage: number | null | undefined
  }
  wheel: {
    diameterMm: number | null | undefined
    milliRpmFast: number | null | undefined
    milliRpmSlow: number | null | undefined
    nMagnets: number | null | undefined
    sensorPort: SensorPort | null | undefined
  }
}
export type ConfigSensorName = keyof SensorConfigurations

export type SensorConfigKey<S extends ConfigSensorName = ConfigSensorName> =
  S extends S ? keyof SensorConfigurations[S] : never

export type SensorConfig<S extends ConfigSensorName = ConfigSensorName> =
  Partial<SensorConfigurations[S]>

export const SENSOR_NAMES = [...CONFIG_SENSOR_NAMES, `device`] as const

export type SensorName<
  T extends (typeof SENSOR_NAMES)[number] = (typeof SENSOR_NAMES)[number],
> = T extends T ? T : never

/**
 * Get the names of all non-null sensors in device configuration
 *
 * @param dc
 */
export function listConfigSensors(dc: {
  [key in SensorName]?: unknown
}): ConfigSensorName[] {
  return CONFIG_SENSOR_NAMES.filter((sn): sn is ConfigSensorName => {
    return Boolean(dc[sn])
  })
}
// type T = SensorConfigKey
export const SENSOR_DEFAULTS: DeepNonNullable<SensorConfigurations> = {
  battery: {
    ioPin: 2,
    voltageIntercept: 0,
    voltageSlope: 107,
    voltageThresholdLowMv: 12000,
  },
  flow: {
    mlPerPulse: 10000,
    sensorPort: 1,
  },
  gps: {
    gpsType: "SAM_M8Q",
  },
  hallSwitch: {
    sensorPort: 1,
    switchType: "O",
    threshold: 2000,
  },
  icm: {
    installedOrientation: "bow",
  },
  pressure: {
    calibrationIntercept: 386.5,
    calibrationSlope: 12.753,
    sensorPort: 2,
    thresholdPsiLower: 60,
    thresholdPsiUpper: 140,
  },
  pressureSwitch: {
    sensorPort: 1,
    switchType: "O",
    threshold: 2000,
  },
  reel: {
    hoseDiameterMm: 127,
    linearSpeedMmHMax: 402396,
    linearSpeedMmHMin: 12192,
    nMagnets: 16,
    nNozzles: 1,
    nWrapsOuterLayer: 7,
    nozzleDiameterMm: 25,
    outerHoseWrapRadiusMm: 1651,
    sensorPort: 1,
    sprinklerType: "gun",
    swathWidthMm: 76200,
    widthMm: 1524,
  },
  relay: {
    ioPin: 200,
  },
  temperature: {
    calibrationIntercept: 0,
    calibrationSlope: 0,
  },
  vfd: {
    i2CAddress: 0x60,
    ioPin: 0,
    maxTrackingPercentage: 100,
    minTrackingPercentage: 0,
  },
  wheel: {
    diameterMm: 508,
    milliRpmFast: 1663,
    milliRpmSlow: 49,
    nMagnets: 2,
    sensorPort: 1,
  },
}
