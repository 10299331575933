/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "@fhq/app/translations"
import "react-datepicker/dist/react-datepicker.css"
import "react-medium-image-zoom/dist/styles.css"
import "./index.css"

import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"

import { AnalyticsProvider } from "@fhq/app/Analytics"
import { ErrorBoundary } from "@sentry/react"

import { SEGMENT_CLIENT } from "./pages/base/Analytics"
import { STORE } from "./pages/base/store"
import { WebApp } from "./WebApp"

// TODO: Reimplement strict mode:
// https://github.com/GeekyAnts/NativeBase/issues/5272
ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={STORE}>
    <AnalyticsProvider client={SEGMENT_CLIENT}>
      <ErrorBoundary showDialog>
        <WebApp />
      </ErrorBoundary>
    </AnalyticsProvider>
  </Provider>,
)
