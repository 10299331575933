import _ from "lodash"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { Badge, FormControl, Radio, Row } from "./components"
import { InputRightAddon } from "./components/form-control/Input"
import { useFormValidation } from "./form-validation"
import { PressureThresholdSlider } from "./PressureThresholdSlider"
import {
  getUserMeasurementPreferenceFromState,
  useIsPending,
} from "./selectors"
import {
  MUTABLE_SENSOR_NAMES,
  SENSOR_DEFAULTS,
  SensorPorts,
  SprinklerTypes,
  SwitchTypes,
} from "./sensor-configurations"
import { getSensorConversionSpec } from "./sensor-conversions"
import { getSensorUnitLabel, useFormatSensorName } from "./sensor-formatting"
import { isValidNumber } from "./type-guards"
import { useRootSelector } from "./useRootSelector"

import type { Path } from "react-hook-form"
import type { FormControlProviderProps } from "./components/form-control/base"
import type { InputProps } from "./components/form-control/Input"
import type {
  MutableSensorName,
  SensorConfigKey,
  SensorName,
} from "./sensor-configurations"

import type { DeviceConfiguration } from "./device-configurations.reducer"
/**
 *
 */
export function radioValueToString(itemValue: unknown): string {
  if (isValidNumber(itemValue)) {
    return `${itemValue}`
  }
  if (!Boolean(itemValue)) {
    return ""
  }
  if (typeof itemValue === "string") {
    return itemValue
  }
  throw new TypeError(`Invalid value supplied ${typeof itemValue}`)
}

/**
 *
 */
export function MagnetSelect({
  sensorName,
  ...rest
}: FormControlProviderProps & {
  sensorName: SensorName<"reel" | "wheel">
}): React.JSX.Element {
  const { required } = useFormValidation()

  const { t } = useTranslation("sensorFields")
  const { control } = useFormContext<DeviceConfiguration>()

  const fieldName = `${sensorName}.nMagnets` as const
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        let selectedValueAsString: string
        if (typeof value === "number") {
          selectedValueAsString = value.toFixed(0)
        } else {
          selectedValueAsString = ""
        }
        return (
          <FormControl.Provider
            isRequired
            id="nMagnets"
            isInvalid={isInvalid}
            {...rest}
          >
            <FormControl.Label>{t("nMagnets.displayName")}</FormControl.Label>
            <FormControl.Select
              selectedValue={selectedValueAsString}
              options={_.range(20, 1, -1).map((option) => {
                const optionAsString = `${option}`
                return {
                  label: optionAsString,
                  value: optionAsString,
                }
              })}
              onValueChange={(next) => onChange(Number.parseInt(next))}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
export function ChooseSensorPort({
  // _stack,
  sensorName,
  ...rest
}: FormControlProviderProps & {
  sensorName: MutableSensorName
  // _stack?: IVStackProps
}): React.JSX.Element {
  const { control, setValue, watch } = useFormContext<DeviceConfiguration>()
  const { t } = useTranslation("sensorFields")
  const { required } = useFormValidation()
  const formatSensorName = useFormatSensorName()

  const labelText: string = t("sensorPort.fieldLabelWithSensorName", {
    sensorName: formatSensorName(sensorName),
  })
  // const textProps: Tex = {
  //   textTransform: "capitalize"
  // }
  return (
    <Controller
      control={control}
      name={`${sensorName}.sensorPort`}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const selectedValue = radioValueToString(value)
        const isInvalid = Boolean(errorMessage)
        const handleChange = (nextValue: string) => {
          const valueAsNumber = Number.parseInt(nextValue)
          if (!isValidNumber(valueAsNumber)) {
            throw new TypeError(
              `Unable to parse value for sensor port ${nextValue} to number`,
            )
          }
          const configValues = watch()
          // If the configuration values in the form have another sensor
          // with a sensor port, we need to automatically switch that other sensor
          // port to prevent two sensors from being assigned to the same
          // port

          // Find the name of the other sensor
          const otherSensorWithSensorPort = MUTABLE_SENSOR_NAMES.find(
            (otherSensorName) => {
              if (otherSensorName === sensorName) {
                return false
              }
              const otherSensorValues = configValues[otherSensorName]
              if (otherSensorValues) {
                return true
              }
              return false
            },
          )

          // If one exists, flip its value
          if (otherSensorWithSensorPort) {
            setValue(
              `${otherSensorWithSensorPort}.sensorPort`,
              valueAsNumber === SensorPorts.ONE
                ? SensorPorts.TWO
                : SensorPorts.ONE,
            )
          }
          return onChange(valueAsNumber)
        }
        return (
          <FormControl.Provider id="sensorPort" isInvalid={isInvalid} {...rest}>
            <FormControl.Label>{labelText}</FormControl.Label>
            <Radio
              isInvalid={isInvalid}
              selectedValue={selectedValue}
              options={[
                {
                  label: t("sensorPort.values.port-1"),
                  value: `${SensorPorts.ONE}`,
                },
                {
                  label: t("sensorPort.values.port-2"),
                  value: `${SensorPorts.TWO}`,
                },
              ]}
              onChange={handleChange}
            />

            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
export function ChooseSwitchType({
  sensorName,
}: {
  sensorName: SensorName<"hallSwitch" | "pressureSwitch">
}) {
  const { control } = useFormContext<DeviceConfiguration>()
  const { t } = useTranslation("sensorFields")
  const { required } = useFormValidation()
  const labelText: string = t("switchType.displayName")

  return (
    <Controller
      control={control}
      name={`${sensorName}.switchType`}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)
        const selectedValue = radioValueToString(value)
        return (
          <FormControl.Provider id="switchType" isInvalid={isInvalid} w="$full">
            <FormControl.Label>{labelText}</FormControl.Label>
            <Radio
              selectedValue={selectedValue}
              textTransform="capitalize"
              options={[
                {
                  label: t("switchType.values.O"),
                  value: `${SwitchTypes.OPEN}`,
                },
                {
                  label: t("switchType.values.C"),
                  value: `${SwitchTypes.CLOSED}`,
                },
              ]}
              onChange={onChange}
            />

            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 * Choose reel sprinkler type
 */
export function ChooseSprinklerType(props: FormControlProviderProps) {
  const { control } = useFormContext<DeviceConfiguration>()
  const { t } = useTranslation("sensorFields")
  const { required } = useFormValidation()
  const labelText: string = t("sprinklerType.displayName")

  return (
    <Controller
      control={control}
      name="reel.sprinklerType"
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)
        const selectedValue = radioValueToString(value)
        return (
          <FormControl.Provider
            id="sprinklerType"
            {...props}
            isInvalid={isInvalid}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <Radio
              orientation="horizontal"
              selectedValue={selectedValue}
              textTransform="capitalize"
              options={[
                {
                  label: t("sprinklerType.values.gun"),
                  value: `${SprinklerTypes.GUN}`,
                },
                {
                  label: t("sprinklerType.values.boom"),
                  value: `${SprinklerTypes.BOOM}`,
                },
              ]}
              onChange={onChange}
            />

            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}
/**
 *
 */
export function SensorTextInput({
  _input,
  fieldName,
  sensorName,
  ...rest
}: FormControlProviderProps & {
  fieldName: SensorConfigKey
  sensorName: SensorName
  _input?: InputProps
}): React.JSX.Element {
  const { t } = useTranslation("sensorFields")

  // This component requires a context provider with a device
  // configuration as a parent/grandparent etc.
  const { control } = useFormContext<DeviceConfiguration>()

  // Display changes for metric or US users
  const measurementPreference = useRootSelector(
    getUserMeasurementPreferenceFromState,
  )
  const conversionUnits = getSensorConversionSpec({
    fieldName,
    measurementPreference,
    target: "user",
  })

  const sigDigits = conversionUnits?.sigDigits ?? 0
  const labelText: string = t(`${fieldName}.displayName`)

  // Get validation rules
  const { decimalPattern, integerPattern, minValue, required } =
    useFormValidation()

  // If significant digits are greater than 0, enforce a decimal regex
  let keyboardType: InputProps["keyboardType"]
  let pattern
  if (sigDigits > 0) {
    keyboardType = "numeric"
    pattern = decimalPattern
  } else {
    keyboardType = "number-pad"
    pattern = integerPattern
  }

  const fieldPath: Path<DeviceConfiguration> =
    `${sensorName}.${fieldName}` as Path<DeviceConfiguration>
  const extracted = useIsPending("CreateConfiguration")

  return (
    <Controller
      key={fieldName}
      control={control}
      name={fieldPath}
      render={({ field: { onBlur, onChange, ref, value }, fieldState }) => {
        // Values are stored as numbers when possible.
        //  If they are blank, the value will be an empty string
        if (typeof value === "number") {
          value = `${value}`
        }
        // Coerce null values to empty string
        if (!Boolean(value)) {
          value = ``
        }
        if (typeof value !== "string") {
          throw new TypeError(`Received invalid value in Sensor Text Input`)
        }

        // Unitlabels can be either a string or an object with metric and us props
        const unitLabel = getSensorUnitLabel({
          fieldName,
          labelLength: "long",
          measurementPreference,
        })

        const errorMessage = fieldState.error?.message

        return (
          <FormControl.Provider
            id={fieldName}
            isDisabled={extracted}
            isInvalid={Boolean(errorMessage)}
            {...rest}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Input
              ref={ref}
              keyboardType={keyboardType}
              returnKeyLabel="Done"
              returnKeyType="done"
              {..._input}
              value={value}
              InputRightElement={
                unitLabel ? (
                  <InputRightAddon>{unitLabel}</InputRightAddon>
                ) : null
              }
              onBlur={onBlur}
              onChangeText={onChange}
            />

            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
      rules={{
        min: minValue(0),
        pattern,
        required,
      }}
    />
  )
}

/**
 *
 */
export function PressureSliderConnected(): React.JSX.Element {
  const { control } = useFormContext<DeviceConfiguration>()

  const { t } = useTranslation("deviceConfiguration")
  return (
    <Controller
      control={control}
      name="pressure"
      render={({ field }) => {
        let labelText: string | undefined

        if (field.value) {
          labelText = t("sensorPort.displayNameWithVal", {
            ns: "sensorFields",
            val: field.value.sensorPort,
          })
        }
        return (
          <View>
            <Row justifyContent="space-between">
              <FormControl.Label>
                {t("pressureThresholdsTitle")}
              </FormControl.Label>
              <Badge>{labelText}</Badge>
            </Row>
            <PressureThresholdSlider
              thresholdPsiLower={
                field.value?.thresholdPsiLower ??
                SENSOR_DEFAULTS.pressure.thresholdPsiLower
              }
              thresholdPsiUpper={
                field.value?.thresholdPsiUpper ??
                SENSOR_DEFAULTS.pressure.thresholdPsiUpper
              }
              onChange={(next) => {
                return field.onChange({ ...field.value, ...next })
              }}
            />
          </View>
        )
      }}
    />
  )
}

/**
 *
 */
export function DeviceNameInput({
  _input,
  ...rest
}: FormControlProviderProps & { _input?: InputProps }): React.JSX.Element {
  const { control } = useFormContext<DeviceConfiguration>()
  const { t } = useTranslation()
  const { required } = useFormValidation()
  const isLoading = useIsPending(
    "RenameDevice",
    "CreateConfiguration",
    "RestoreConfigurationDefaults",
  )

  return (
    <Controller
      control={control}
      name="deviceName"
      rules={{ required }}
      render={({ field: { onChange, ref, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider
            id="deviceName"
            isDisabled={isLoading}
            isInvalid={Boolean(errorMessage)}
            {...rest}
          >
            <FormControl.Label>
              {t("deviceName", { ns: "common" })}
            </FormControl.Label>
            <FormControl.Input
              {..._input}
              ref={ref}
              onChangeText={onChange}
              {...field}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}
