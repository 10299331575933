import React from "react"
import { Outlet } from "react-router-dom"

import { Box } from "@fhq/app/components"
import { DeviceActivityAutoRefresher } from "@fhq/app/DeviceActivityAutoRefresher"

import { DEVICE_PROFILE_ROUTES } from "./DeviceProfile.Routes"
import { ScrollingContent } from "./pages/base/Layout"
import { PageErrorBoundary } from "./pages/base/PageErrorBoundary"
import { useIsTabFocused } from "./pages/base/useIsTabFocused"

import type { RouteObject } from "react-router-dom"

const AddDevicePage = React.lazy(async () => {
  return import("./pages/AddDevicePage")
})

const DeviceRosterPage = React.lazy(async () => {
  return import("./pages/DeviceRosterPage")
})

function DevicesRoot() {
  const isFocused = useIsTabFocused()
  return (
    <Box flex={1} id="devices">
      <DeviceActivityAutoRefresher
        callerName="DevicesRoutes"
        isFocused={isFocused}
        loadingElement={null}
      />
      <Outlet />
    </Box>
  )
}
/**
 * Routes for device roster and profile
 */
export const DEVICES_ROUTES: RouteObject = {
  children: [
    {
      element: <DeviceRosterPage />,
      index: true,
    },
    {
      element: (
        <ScrollingContent>
          <AddDevicePage />
        </ScrollingContent>
      ),
      path: "add",
    },
    {
      ...DEVICE_PROFILE_ROUTES,
      path: ":codaDeviceAlias",
    },
  ],
  element: <DevicesRoot />,
  errorElement: <PageErrorBoundary />,
  id: "devices",
}
