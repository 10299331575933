import { Geo } from "@fhq/app"

// Function to check if a coordinate is in the current bounds
export function isCoordinateInBounds({
  bounds,
  coordinate,
}: {
  bounds: google.maps.LatLngBoundsLiteral | null | undefined
  coordinate: google.maps.LatLngLiteral | null | undefined
}): boolean {
  if (!bounds || !coordinate) {
    return false
  }

  const { east, north, south, west } = bounds
  const { lat: markerLat, lng: markerLng } = coordinate

  return (
    markerLat >= south &&
    markerLat <= north &&
    markerLng >= west &&
    markerLng <= east
  )
}

export const pointTransformer: Geo.PointTransformer<
  google.maps.LatLngLiteral
> = (pt) => Geo.point(pt)?.toGmaps()
