import React from "react"
import { useParams } from "react-router"

import { Analytics } from "@fhq/app"
import { makeUseHelpDialog } from "@fhq/app/components"
import { objectEntries } from "@fhq/app/utility"
import { AnalyticsBrowser } from "@segment/analytics-next"
import * as Sentry from "@sentry/react"

import { Environment, logger } from "./base"
import { useCurrentRoutePath } from "./useRoutePath"

const writeKey = process.env.REACT_APP_SEGMENT_WRITE_KEY
const SEGMENT_CLIENT = new AnalyticsBrowser()

if (typeof writeKey === "string") {
  if (Environment.isAnalyticsEnabled) {
    SEGMENT_CLIENT.load({ writeKey })
      .then(() => logger.info("[Analytics.tsx]: Analytics loaded"))
      .catch((error) => {
        Sentry.captureException(error)
      })
  }
} else {
  const message = `Segment write key is undefined`
  logger.warn(message)
  Sentry.captureMessage(message)
}

Sentry.setTag("segment_analytics_version", SEGMENT_CLIENT.VERSION)

/**
 * Collect URL, referrer, other data when page changes
 */
export function useRecordPageVisit() {
  const referrer = React.useRef<string | undefined>()
  const routePath = useCurrentRoutePath()
  const { codaDeviceAlias, id, pageId, productId } = useParams()

  React.useEffect(() => {
    if (typeof routePath === "string") {
      const name = undefined
      const properties: { [key: string]: number | string | null | undefined } =
        {
          referrer: referrer.current,
          routePath,
          url: routePath,
        }
      const params = {
        codaDeviceAlias,
        id,
        pageId,
        productId,
      } as const
      for (const [key, value] of objectEntries(params)) {
        if (typeof value === "string") {
          properties[key] = value
        }
      }
      SEGMENT_CLIENT.page("page", name, properties).catch((error) =>
        Sentry.captureException(error),
      )
      referrer.current = routePath
    }
    return () => {
      referrer.current = undefined
    }
  }, [codaDeviceAlias, id, pageId, productId, routePath])
}

export { SEGMENT_CLIENT }
export const useTrackEvent = Analytics.makeEventTrackerHook({
  analyticsClient: SEGMENT_CLIENT,
  environmentInfo: Environment,
})

export const useHelpDialog = makeUseHelpDialog(useTrackEvent)
