import { Amplify } from "aws-amplify"
import _ from "lodash"

import { configureStore } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/core"

import { getUserPoolParams } from "./aws-cognito"
import { isValidDatabaseName } from "./Internal"
import { baseLogger, isValidLogLevel, makeFileLogger } from "./logger"
import { reduxLogger } from "./redux-logger"
import { getApiUrl } from "./Requests"
import { rootReducer } from "./root.reducer"
import { isPrimitive } from "./type-guards"

import type { LogLevelInput } from "./logger"
import type { RootThunkExtra } from "./Requests"

import type { RootState } from "./root.reducer"

const FILE_NAME = "app/store.ts"

const logger = makeFileLogger({ fileName: FILE_NAME })

export interface MakeStoreParameters extends RootThunkExtra {
  enableReduxLogger: boolean
  initialState: RootState | undefined
  isAnalyticsEnabled: boolean
  logLevel: LogLevelInput | null | undefined
  sentryReduxEnhancer: unknown
}

/**
 * Make a store creator for each app
 * @param params build-time parameters for store
 * @returns store
 */
export function makeStore(params: MakeStoreParameters) {
  const {
    analyticsClient,
    appName,
    appVersion,
    enableReduxLogger,
    environmentInfo,
    initialState,
    isEndToEndTest,
    localhost,
    logLevel,
    sentryReduxEnhancer,
    targetDatabaseName,
  } = params

  if (isValidLogLevel(logLevel)) {
    baseLogger.enable()
    baseLogger.setSeverity(logLevel)
  } else {
    baseLogger.disable()
  }

  for (const [key, value] of Object.entries(params)) {
    if (isPrimitive(value)) {
      Sentry.setTag(_.snakeCase(key), value)
    }
  }
  if (!isValidDatabaseName(targetDatabaseName)) {
    const asString = JSON.stringify(params, null, 2)
    throw new TypeError(
      `[${FILE_NAME}]:Invalid database name supplied: ${asString}`,
    )
  }

  Amplify.configure({
    Auth: getUserPoolParams(targetDatabaseName),
  })

  const backendTarget = getApiUrl(params)
  logger.info(backendTarget)

  if (typeof backendTarget.url === "undefined") {
    throw new TypeError(
      `Invalid url\n${JSON.stringify(backendTarget, null, 2)}`,
    )
  }

  if (typeof appVersion === "undefined") {
    logger.error("app version is undefined")
  }
  return configureStore({
    devTools: { name: appName },
    middleware: (getDefaultMiddleware) => {
      const extraArgument: RootThunkExtra = {
        analyticsClient,
        appName,
        appVersion,
        // axiosInstance,
        environmentInfo,
        isEndToEndTest,
        localhost,
        targetDatabaseName,
      }
      const middleware = getDefaultMiddleware({
        enhancers: Boolean(sentryReduxEnhancer)
          ? [sentryReduxEnhancer]
          : undefined,
        immutableCheck: false,
        serializableCheck: {
          warnAfter: 128,
        },
        thunk: { extraArgument },
      })
      // alert(JSON.stringify(reduxLogLevel))
      if (enableReduxLogger) {
        return middleware.concat(reduxLogger)
      }
      return middleware
    },
    preloadedState: { ...initialState },
    reducer: rootReducer,
  })
}
export type RootDispatch = ReturnType<typeof makeStore>["dispatch"]
