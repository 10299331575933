/* eslint-disable no-alert */

import React from "react"
import { useNavigate } from "react-router"

import {
  AddSensor,
  DeviceSummaryContext,
  Models,
  useRootSelector,
} from "@fhq/app"
import { Spinner } from "@fhq/app/components"

import { withPageLayout } from "./base/PageLayout"

function AddSensorFormPage() {
  const [selectedSensorName, setSelectedSensorName] = React.useState<
    AddSensor.AddableSensorName | undefined
  >()

  const { deviceId } = DeviceSummaryContext.useContext()
  const configuration = useRootSelector((state) =>
    Models.deviceConfiguration.selectById(state, deviceId),
  )

  const navigate = useNavigate()
  if (selectedSensorName) {
    if (typeof configuration === "undefined") {
      return <Spinner />
    }
    return (
      <AddSensor.AddSensorForm
        configuration={configuration}
        sensorName={selectedSensorName}
        onClose={() => setSelectedSensorName(undefined)}
        onSuccess={() => navigate("../configuration")}
      />
    )
  }
  return (
    <AddSensor.SelectSensor
      configuration={configuration}
      onChange={(sensorName) => setSelectedSensorName(sensorName)}
      onError={(message) => alert(message)}
    />
  )
}
export default withPageLayout(AddSensorFormPage, {
  id: "add-sensor",
  requiredPermissions: "canManageDeviceConfiguration",
})
