import React from "react"
import { useParams } from "react-router"

import { isTruthyString } from "@fhq/app"
import { SUPPORT_PAGE_DEVICES, SUPPORT_PAGE_ID } from "@fhq/app/Support"
import * as Sentry from "@sentry/react"

import {
  FC2deviceOverview,
  FC2inTheBox,
  FC2pumpHardwareInstallation,
  FC2reelHardwareInstallation,
  FC2troubleshooting,
  PC1deviceOverview,
  PC1inTheBox,
  PC1pumpHardwareInstallation,
  PC1reelHardwareInstallation,
  SolarPanelDeviceOverview,
  TC3deviceOverview,
  TC3electricPumpInstallation,
  TC3flowmeter,
  TC3inTheBox,
  TC3pumpHardwareInstallation,
  TC3reelHardwareInstallation,
  TC3troubleshooting,
} from "./support-files"

import type { FetchStatus } from "@fhq/app/Requests"
import type { SupportPageId, SupportProductId } from "@fhq/app/Support"
export const SUPPORT_PRODUCT_TO_FILE_MAP = {
  "FC2": {
    [SUPPORT_PAGE_ID.deviceOverview]: FC2deviceOverview,
    [SUPPORT_PAGE_ID.inTheBox]: FC2inTheBox,
    [SUPPORT_PAGE_ID.pumpHardwareInstallation]: FC2pumpHardwareInstallation,
    [SUPPORT_PAGE_ID.reelHardwareInstallation]: FC2reelHardwareInstallation,
    [SUPPORT_PAGE_ID.troubleshooting]: FC2troubleshooting,
  },
  "PC1": {
    [SUPPORT_PAGE_ID.deviceOverview]: PC1deviceOverview,
    [SUPPORT_PAGE_ID.inTheBox]: PC1inTheBox,
    [SUPPORT_PAGE_ID.pumpHardwareInstallation]: PC1pumpHardwareInstallation,
    [SUPPORT_PAGE_ID.reelHardwareInstallation]: PC1reelHardwareInstallation,
  },
  "TC3": {
    [SUPPORT_PAGE_ID.deviceOverview]: TC3deviceOverview,
    [SUPPORT_PAGE_ID.electricPump]: TC3electricPumpInstallation,
    [SUPPORT_PAGE_ID.flowmeter]: TC3flowmeter,
    [SUPPORT_PAGE_ID.inTheBox]: TC3inTheBox,
    [SUPPORT_PAGE_ID.pumpHardwareInstallation]: TC3pumpHardwareInstallation,
    [SUPPORT_PAGE_ID.reelHardwareInstallation]: TC3reelHardwareInstallation,
    [SUPPORT_PAGE_ID.troubleshooting]: TC3troubleshooting,
  },
  "solar-panel": {
    [SUPPORT_PAGE_ID.deviceOverview]: SolarPanelDeviceOverview,
  },
} as const
interface SupportState {
  fetchStatus: FetchStatus
  markdownText?: string | undefined
}

const INITIAL_STATE: SupportState = { fetchStatus: `pending` }
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type SupportPageParams = {
  currentPageId: SupportPageId
  currentProductId: SupportProductId
}
export interface SupportPageProps {
  markdownText: string | undefined
  productName: string | undefined
}

export const DEFAULT_PAGE_ID = "device-overview"
export const DEFAULT_PRODUCT_ID = "TC3"
export const useSupportPageParams = (): SupportPageParams => {
  const params = useParams<SupportPageParams>()
  const currentPageId = params.currentPageId ?? DEFAULT_PAGE_ID
  const currentProductId = params.currentProductId ?? DEFAULT_PRODUCT_ID
  return { currentPageId, currentProductId }
}
export function useSupportPageIds() {
  const { currentProductId } = useSupportPageParams()
  return Object.keys(SUPPORT_PRODUCT_TO_FILE_MAP[currentProductId])
}
export function useSupportPage() {
  const { currentPageId, currentProductId } = useSupportPageParams()
  const files = SUPPORT_PRODUCT_TO_FILE_MAP[currentProductId]
  let markdownFile: string | undefined

  if (typeof files === "object") {
    markdownFile = (files as { [key: string]: string })[currentPageId]
  } else {
    markdownFile = undefined
  }

  const [{ fetchStatus, markdownText }, setState] =
    React.useState<SupportState>(() => ({ ...INITIAL_STATE }))
  // const isSmallScreen = useIsSmallScreen();

  React.useEffect(
    /**
     * Fetch file contents asynchronously to dynamically generate page
     */
    () => {
      if (isTruthyString(markdownFile)) {
        setState({ ...INITIAL_STATE })
        fetch(markdownFile)
          .then(async (res) =>
            setState({
              fetchStatus: "fulfilled",
              markdownText: await res.text(),
            }),
          )
          .catch((error) => {
            Sentry.captureException(error)
            setState({ fetchStatus: "rejected" })
          })
      }
    },
    [markdownFile],
  )
  return React.useMemo(
    () => ({
      fetchStatus,
      markdownText,
      productName: SUPPORT_PAGE_DEVICES.find(
        (item) => item.id === currentProductId,
      )?.name,
    }),
    [currentProductId, fetchStatus, markdownText],
  )
}
