import React from "react"
import { useTranslation } from "react-i18next"

import { Box, Button, Row } from "./components"
import { AlertBodyText, AlertCard } from "./components/AlertCard"
import * as Models from "./models"
import { isValidNumber } from "./type-guards"
import { useRootSelector } from "./useRootSelector"

import type { AcceptsChildren } from "./components"
import type { DeviceConfiguration } from "./device-configurations.reducer"
import type { DeviceIdProps } from "./types"
// TODO: Extract Prompt for Device land screen
export function isTravellerPathSet(
  configuration: DeviceConfiguration | null | undefined,
): boolean {
  if (configuration) {
    if (configuration.deviceInstallationType === "linear_move") {
      return (
        Boolean(configuration.linearPath) &&
        Boolean(configuration.linearPathStopsLabels) &&
        typeof configuration.linearSpanWidthMm === "number"
      )
    } else if (configuration.deviceInstallationType === "center_pivot") {
      return (
        Boolean(configuration.pivotCenterGpsLocation) &&
        isValidNumber(configuration.pivotRadiusMeters)
      )
    }
  }
  return true
}

export function TravellerPathNotSetAlert({
  children,
  text,
}: AcceptsChildren & { text: string }) {
  return (
    <AlertCard my="$2" severity="warning">
      <Row>
        <Box mr="$2">
          <AlertBodyText flex={1}>{text}</AlertBodyText>
        </Box>
        {children}
      </Row>
    </AlertCard>
  )
}

export function SetTravellerPathPrompt({
  deviceId,
  hideButtonIfPathIsSet,
  onPressSetPath,
}: DeviceIdProps & {
  hideButtonIfPathIsSet: boolean
  onPressSetPath: (
    params: Pick<DeviceConfiguration, "deviceInstallationType">,
  ) => void
}) {
  const { t } = useTranslation("devices")
  const configuration = useRootSelector((state) =>
    Models.deviceConfiguration.selectById(state, deviceId),
  )

  if (!configuration || !isTravellerPathSet(configuration)) {
    return null
  }

  const handlePress = () => {
    onPressSetPath(configuration)
  }
  let element: JSX.Element | null = null
  switch (configuration.deviceInstallationType) {
    case "linear_move": {
      element = (
        <Button
          IconComponent="DeviceLinearMove"
          id="set-linear-path-link-btn"
          text={t("setLinearPathLinkTo")}
          onPress={handlePress}
        />
      )

      if (!isTravellerPathSet(configuration)) {
        // Path is not set
        element = (
          <TravellerPathNotSetAlert text={t("setLinearPathNotSetWarning")}>
            {element}
          </TravellerPathNotSetAlert>
        )
      } else if (hideButtonIfPathIsSet) {
        element = null
      }
      break
    }
    case "center_pivot": {
      element = (
        <Button
          IconComponent="DeviceCenterPivot"
          id="set-center-pivot-path-link-btn"
          text={t("setCenterPivotPathLinkTo")}
          onPress={handlePress}
        />
      )
      if (
        !configuration.pivotCenterGpsLocation ||
        typeof configuration.pivotRadiusMeters !== "number"
      ) {
        // Path is not set
        element = (
          <TravellerPathNotSetAlert text={t("setCenterPivotPathNotSetWarning")}>
            {element}
          </TravellerPathNotSetAlert>
        )
      } else if (hideButtonIfPathIsSet) {
        element = null
      }
      break
    }

    default:
      break
  }
  return element
}
