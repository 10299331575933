import _ from "lodash"

import { captureException } from "@sentry/core"
import * as turf from "@turf/turf"

import { COLORS } from "./components/theme"
import * as Geo from "./geo"

import type { DeviceConfiguration } from "./device-configurations.reducer"
import type * as Models from "./models"

export const CENTER_PIVOT_ARROW_PARAMS = {
  baseLengthMeters: 200,
  headAngle: 150,
  headSizeMeters: 30,
}

export function makePlotArrow<P extends Geo.AnyLatLng>(
  transformPoint: Geo.PointTransformer<P>,
) {
  return (
    deviceActivity: Models.DeviceActivity,
    configuration: DeviceConfiguration,
  ) => {
    const deviceHeading = _.findLast(
      deviceActivity.gpsHeadingsDegrees,
      (heading) => typeof heading === "number",
    )
    const arrowStemPath: P[] = []
    const arrowHeadPath: P[] = []
    if (typeof deviceHeading === "number") {
      // Find the center of the pivot frontMidpoint
      let frontMidpoint: Geo.PointInput | undefined
      const currentAzimuth =
        deviceActivity.pivotHeadingsDegrees?.[
          deviceActivity.pivotHeadingsDegrees.length - 1
        ]

      if (typeof currentAzimuth === "number") {
        const pivotCenter = configuration.pivotCenterGpsLocation
        const pivotRadius = configuration.pivotRadiusMeters

        if (typeof pivotRadius === "number" && pivotCenter) {
          frontMidpoint = Geo.point(pivotCenter)?.project({
            direction: currentAzimuth,
            distanceMeters: pivotRadius / 2,
          })
        }
      }

      const arrowStart = transformPoint(frontMidpoint)

      const arrowEnd = Geo.point(arrowStart)?.project({
        direction: deviceHeading,
        distanceMeters: CENTER_PIVOT_ARROW_PARAMS.baseLengthMeters / 2,
      })

      const arrowEndLatLng = transformPoint(arrowEnd)

      if (arrowStart && arrowEnd && arrowEndLatLng) {
        arrowStemPath.push(arrowStart, arrowEndLatLng)

        // Add arrowhead

        const arrowHeadLeft = transformPoint(
          arrowEnd.project({
            direction: deviceHeading + CENTER_PIVOT_ARROW_PARAMS.headAngle,
            distanceMeters: CENTER_PIVOT_ARROW_PARAMS.headSizeMeters,
          }),
        )

        const arrowHeadRight = transformPoint(
          arrowEnd.project({
            direction: deviceHeading - CENTER_PIVOT_ARROW_PARAMS.headAngle,
            distanceMeters: CENTER_PIVOT_ARROW_PARAMS.headSizeMeters,
          }),
        )
        if (arrowHeadLeft && arrowHeadRight) {
          arrowHeadPath.push(arrowHeadLeft, arrowEndLatLng, arrowHeadRight)
        }
      }
    }

    return { arrowHeadPath, arrowStemPath }
  }
}

/**
 * Factory to find the sector (pie wedge) and transform it into a path that
 * we can render using one of our mapping libraries.
 */
export function makeSectorFinder<P extends Geo.AnyLatLng>(
  transformPoint: Geo.PointTransformer<P>,
) {
  return ({
    azimuthEnd,
    azimuthStart,
    centerCoordinates: coordinates,
    direction,
    radiusMeters,
  }: {
    azimuthEnd: number | null | undefined
    azimuthStart: number | null | undefined
    centerCoordinates: Geo.Coordinates | null | undefined
    direction: Models.PivotDirection
    radiusMeters: number | null | undefined
  }) => {
    if (
      coordinates &&
      typeof azimuthStart === "number" &&
      typeof azimuthEnd === "number" &&
      typeof radiusMeters === "number" &&
      // If the pivot radius is 0, turf.sector throws an error 🙄
      Boolean(radiusMeters)
    ) {
      try {
        let startAngle = azimuthStart
        let endAngle = azimuthEnd

        if (direction === "pivot_counterclockwise") {
          startAngle = azimuthEnd
          endAngle = azimuthStart
        }

        const pivotPolygon = turf.sector(
          coordinates,
          radiusMeters,
          startAngle,
          endAngle,
          { units: "meters" },
        )
        const result: P[] = []
        const pathCoordinates = pivotPolygon.geometry.coordinates[0] ?? []
        for (const coordinate of pathCoordinates) {
          const transformed = transformPoint(coordinate)
          if (transformed) {
            result.push(transformed)
          }
        }

        return result
      } catch (e) {
        captureException(e)
      }
    }
    return undefined
  }
}

export function makePlotPivotOutline<P extends Geo.AnyLatLng>(
  transformPoint: Geo.PointTransformer<P>,
) {
  return (configuration: DeviceConfiguration) => {
    let extentPath: P[] | undefined

    if (
      configuration.deviceInstallationType === "center_pivot" &&
      typeof configuration.pivotRadiusMeters === "number" &&
      configuration.pivotCenterGpsLocation
    ) {
      const getSector = makeSectorFinder(transformPoint)

      const extentAzimuths = configuration.pivotPathStopsHeadingsDegrees

      if (extentAzimuths) {
        const extentStart = extentAzimuths[0] ?? 0
        const extentEnd = extentAzimuths[extentAzimuths.length - 1] ?? 360
        extentPath = getSector({
          azimuthEnd: extentEnd,
          azimuthStart: extentStart,
          centerCoordinates: configuration.pivotCenterGpsLocation.coordinates,
          direction: "pivot_clockwise",
          radiusMeters: configuration.pivotRadiusMeters,
        })
      }
    }
    return extentPath
  }
}

export function makePlotPivotPath<P extends Geo.AnyLatLng>(
  transformPoint: Geo.PointTransformer<P>,
) {
  const getSector = makeSectorFinder(transformPoint)
  return (
    configuration: DeviceConfiguration,
    deviceActivity: Models.DeviceActivity,
  ) => {
    let activityPath: P[] | undefined
    if (
      configuration.deviceInstallationType === "center_pivot" &&
      typeof configuration.pivotRadiusMeters === "number" &&
      configuration.pivotCenterGpsLocation
    ) {
      let activityDirection: Models.PivotDirection | undefined

      // Find the first index with a direction
      const azimuths = deviceActivity.pivotHeadingsDegrees ?? []
      for (let index = azimuths.length - 1; index > 0; index--) {
        const heading = deviceActivity.pivotHeadingsDegrees?.[index]
        if (typeof heading === "number") {
          const currentDirection = deviceActivity.travelerDirections?.[index]
          if (
            currentDirection === "pivot_clockwise" ||
            currentDirection === "pivot_counterclockwise"
          ) {
            activityDirection = currentDirection
          }
          break
        }
      }

      let startIndex: number | undefined
      let mostRecentValidAzimuth: number | undefined
      // TODO: clean this up

      // Find the index at which the direction started
      for (let index = azimuths.length - 1; index > 0; index--) {
        const directionCurrent = deviceActivity.travelerDirections?.[index]
        const isValidPivotReading =
          directionCurrent === "pivot_clockwise" ||
          directionCurrent === "pivot_counterclockwise" ||
          directionCurrent === null
        // If it changes, grab the index right after the change chronologically
        if (isValidPivotReading && directionCurrent !== activityDirection) {
          startIndex = index + 1
          break
        }
        // If it never changes, grab the first index
        if (index === 0 && isValidPivotReading) {
          startIndex = index
        }
        if (typeof mostRecentValidAzimuth === "undefined") {
          const azimuthNext = azimuths[index]
          if (typeof azimuthNext === "number") {
            mostRecentValidAzimuth = azimuthNext
          }
        }
      }

      let activityAzimuthStart: number | undefined
      if (typeof startIndex === "number") {
        activityAzimuthStart = azimuths[startIndex] ?? undefined
      }

      const coordinates = configuration.pivotCenterGpsLocation.coordinates

      if (
        typeof activityAzimuthStart === "number" &&
        typeof mostRecentValidAzimuth === "number" &&
        // If the pivot radius is 0, turf.sector throws an error 🙄
        Boolean(configuration.pivotRadiusMeters)
      ) {
        activityPath = getSector({
          azimuthEnd: mostRecentValidAzimuth,
          azimuthStart: activityAzimuthStart,
          centerCoordinates: coordinates,
          direction: activityDirection ?? "pivot_clockwise",
          radiusMeters: configuration.pivotRadiusMeters,
        })
      }
    }
    return activityPath
  }
}

export const CenterPivotColors = {
  outline: {
    strokeColor: COLORS.$cyan[500],
  },
}
