import React from "react"
import { FlatList as Base, StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { HEADER_LARGE_CHILD_SCROLL_ADJUSTMENT } from "./ScrollView"
import { testIds } from "./test-id"
import { SIZES, SPACING } from "./theme"

import type { FlatListProps as BaseProps } from "react-native"
import type { TestId } from "./test-id"

const styles = StyleSheet.create({
  contentContainer: {
    // marginBottom: SPACING.$8,
  },
  variantPage: {
    // backgroundColor: COLORS.$paper.light,
    borderRadius: SPACING.$2,
    marginHorizontal: "auto",
    marginTop: SPACING.$2,
    maxWidth: SIZES.$4xl,
    paddingBottom: SPACING.$toolbar,
    width: "100%",
  },
})

export type FlatListProps<T = unknown> = BaseProps<T> & {
  id?: TestId
  variant?: "page"
}

export function FlatList<T>({
  contentContainerStyle,
  id,
  variant,
  ...props
}: FlatListProps<T>) {
  return (
    <Base
      {...testIds(id)}
      contentInsetAdjustmentBehavior={HEADER_LARGE_CHILD_SCROLL_ADJUSTMENT}
      contentContainerStyle={useStyle(
        () => [
          styles.contentContainer,
          variant === "page" ? styles.variantPage : undefined,
          contentContainerStyle,
        ],
        [contentContainerStyle, variant],
      )}
      {...props}
    />
  )
}
