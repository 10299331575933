import React from "react"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import { AlertCard, AppText } from "./components"
import { Box } from "./components/Box"
import { activateFieldsAsync, archiveFieldsAsync } from "./farmhq-api"
import { useCancelFieldAction } from "./fields.reducer"
import { useBackendRequest } from "./useBackendRequest"

import type { BoxProps } from "./components/Box"
import type { FieldIdProps } from "./types"
interface Props extends BoxProps, FieldIdProps {
  onClosed?: () => void
}

/**
 * Activate a field.
 */
export function ActivateField({
  fieldId,
  onClosed,
  ...rest
}: Props): React.JSX.Element {
  const { t } = useTranslation("fields")

  const handleCancel = useCancelFieldAction()

  const { handleError, isLoading, sendRequest, toasts } =
    useBackendRequest(activateFieldsAsync)
  return (
    <Box id="activate-field" {...rest}>
      <AlertCard
        bodyText={t("activateFieldDescription")}
        id="instructions"
        severity="info"
        titleText={t("activateFieldTitle")}
      />
      <ActionButtons
        isLoading={isLoading}
        ml="auto"
        mt="$4"
        onPressCancel={onClosed ?? handleCancel}
        onPressSubmit={() => {
          sendRequest({ ids: [fieldId] })
            .then(() => {
              if (onClosed) {
                onClosed()
              }
              return toasts.success()
            })

            .catch((error) =>
              handleError(error, {
                toastMessage: "default",
              }),
            )
        }}
      />
    </Box>
  )
}

/**
 *
 */
export function ArchiveField({
  fieldId,
  onClosed,
  ...rest
}: Props): React.JSX.Element {
  const { t } = useTranslation("fields")
  const { handleError, isLoading, sendRequest, toasts } =
    useBackendRequest(archiveFieldsAsync)
  const handleCancel = useCancelFieldAction()
  const instructionsText = t("archiveFieldInstructions")

  return (
    <Box id="archive-field" {...rest}>
      <AppText id="instructions">{instructionsText}</AppText>
      <ActionButtons
        isLoading={isLoading}
        mt="$8"
        onPressCancel={onClosed ?? handleCancel}
        onPressSubmit={() => {
          sendRequest({ ids: [fieldId] })
            .then(() => {
              if (onClosed) {
                onClosed()
              }
              return toasts.success()
            })
            .catch((error) => handleError(error, { toastMessage: "default" }))
        }}
      />
    </Box>
  )
}
