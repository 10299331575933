import React from "react"
import { StyleSheet, View } from "react-native"

import { testIds } from "@fhq/app"
import {
  Backdrop,
  Box,
  CloseIconButton,
  IconButton,
  Paper,
  PresenceTransition,
  Row,
  useBreakpointValue,
} from "@fhq/app/components"
import { Z_INDEX } from "@fhq/app/components/theme"

import type { AcceptsChildren, IconButtonProps } from "@fhq/app/components"

import type { TestId } from "@fhq/app"
interface ProviderProps {
  onClosed?: () => void
  onOpened?: () => void
}

/**
 *
 */
function useSidebar({ onClosed, onOpened, ...rest }: ProviderProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleCloseSidebar = React.useCallback(() => {
    setIsOpen(false)
    if (onClosed) {
      onClosed()
    }
  }, [onClosed])
  const handleOpenSidebar = React.useCallback(() => {
    setIsOpen(true)
    if (onOpened) {
      onOpened()
    }
  }, [onOpened])
  const handleToggleSidebar = React.useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])
  const getBreakpointValue = useBreakpointValue()
  const isDocked =
    getBreakpointValue({
      base: false,
      lg: true,
      xl: true,
    }) === true
  return React.useMemo(() => {
    return {
      handleCloseSidebar,
      handleOpenSidebar,
      handleToggleSidebar,
      isDocked,
      isOpen,
      ...rest,
    }
  }, [
    handleCloseSidebar,
    handleOpenSidebar,
    handleToggleSidebar,
    isDocked,
    isOpen,
    rest,
  ])
}

type ContextValue = ReturnType<typeof useSidebar>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function SidebarProvider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useSidebar(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useSidebarContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`SidebarLayout Context must be used inside of provider`)
  }
  return ctx
}

export function CloseSidebarIconButton(
  props: Pick<IconButtonProps, "size" | "variant">,
): React.JSX.Element | null {
  const { handleCloseSidebar, isDocked } = useSidebarContext()
  if (isDocked) {
    return null
  }
  return (
    <Box ml="auto">
      <CloseIconButton
        id="close-sidebar-btn"
        onPress={handleCloseSidebar}
        {...props}
      />
    </Box>
  )
}

export function SidebarToggleIconButton({
  isFixed,
  ...rest
}: Omit<IconButtonProps, "IconComponent" | "onPress"> & {
  isFixed?: boolean
}): React.JSX.Element | null {
  const { handleToggleSidebar } = useSidebarContext()

  return (
    <IconButton
      IconComponent="Menu"
      size="lg"
      variant="primary"
      {...(isFixed === true
        ? {
            bottom: "screenPadding",
            position: "fixed",
            right: "screenPadding",
          }
        : {})}
      id="sidebar-toggle-btn"
      onPress={handleToggleSidebar}
      {...rest}
    />
  )
}
const WIDTH = 375
const TRANSITION_INITIAL = { translateX: -WIDTH }
const styles = StyleSheet.create({
  pageContent: {
    display: "flex",
    flex: 1,
    height: "100%",
    width: "100%",
  },
  pageContentDocked: {
    flex: 2,
    height: "100%",
    zIndex: 0,
  },
  root: {
    flex: 1,
    position: "relative",
  },
  sidebarContent: {
    flex: 1,
    height: "100%",
    minWidth: WIDTH + 100,
    shadowColor: "rgba(0, 0, 0, 0.25)",
    shadowOffset: {
      height: 4,
      width: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
  },
  transition: {
    bottom: 0,
    flex: 1,
    left: 0,
    maxWidth: "90%",
    position: "absolute",
    top: 0,
    width: WIDTH,
    zIndex: Z_INDEX.drawer,
  },
})

interface Props extends AcceptsChildren {
  SidebarComponent: React.FC | React.JSX.Element
  id?: TestId
}

function DockedLayout({ SidebarComponent, children, id }: Props) {
  const sidebarElement =
    typeof SidebarComponent === "function" ? (
      <SidebarComponent />
    ) : (
      SidebarComponent
    )
  return (
    <Row flex={1} {...testIds(id)}>
      <View {...testIds("sidebar-content")} style={styles.sidebarContent}>
        {sidebarElement}
      </View>
      <View style={styles.pageContentDocked} {...testIds("page-content")}>
        {children}
      </View>
    </Row>
  )
}

function DrawerLayout({
  SidebarComponent,
  children,
  id,
}: Props): React.JSX.Element {
  const { handleCloseSidebar, isOpen } = useSidebarContext()

  const sidebarElement =
    typeof SidebarComponent === "function" ? (
      <SidebarComponent />
    ) : (
      SidebarComponent
    )
  return (
    <View {...testIds(id)} style={styles.root}>
      {isOpen ? (
        <Backdrop
          style={{ flex: 1, zIndex: Z_INDEX.appBar - 1 }}
          onPress={handleCloseSidebar}
        />
      ) : null}
      <View {...testIds("page-content")} style={styles.pageContent}>
        {children}
      </View>
      <PresenceTransition
        initial={TRANSITION_INITIAL}
        style={styles.transition}
        visible={isOpen}
      >
        <Paper
          bottom="$0"
          flex={1}
          id="sidebar-content"
          left="$0"
          position="absolute"
          right="$0"
          top="$0"
        >
          {sidebarElement}
        </Paper>
      </PresenceTransition>
    </View>
  )
}

export function SidebarPage({ SidebarComponent, children, id }: Props) {
  const { isDocked } = useSidebarContext()
  if (isDocked) {
    return (
      <DockedLayout SidebarComponent={SidebarComponent} id={id}>
        {children}
      </DockedLayout>
    )
  }
  return (
    <DrawerLayout SidebarComponent={SidebarComponent} id={id}>
      {children}
    </DrawerLayout>
  )
}
