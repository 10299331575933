import React from "react"

import { testIds } from "../test-id"
import { useFormControlContext } from "./base"
import { useInputStyle } from "./useInputStyle"

import type { SelectProps } from "./Select"
/**
 * Select component
 */
export function Select({
  id,
  isDisabled,
  isInvalid,
  onValueChange,
  options,
  placeholder,
  selectedValue,
}: SelectProps) {
  const ctx = useFormControlContext()
  if (typeof isDisabled === "undefined") {
    isDisabled = ctx.isDisabled
  }
  if (typeof isInvalid === "undefined") {
    isInvalid = ctx.isInvalid
  }
  const {
    backgroundColor,
    borderColor,
    borderRadius,
    borderWidth,
    color,
    fontFamily,
    fontSize,
    paddingHorizontal,
    paddingVertical,
    placeholderTextColor,
    // ...rest
  } = useInputStyle({ isDisabled, isInvalid })

  return (
    <select
      aria-label={placeholder}
      disabled={isDisabled}
      value={selectedValue}
      {...testIds(id)}
      data-testid={id}
      style={{
        backgroundColor,
        borderColor,
        borderRadius,
        borderWidth,
        color,
        fontFamily,
        fontSize,
        paddingBottom: paddingVertical,
        paddingLeft: paddingHorizontal,
        paddingRight: paddingHorizontal,
        paddingTop: paddingVertical,
      }}
      onChange={(e) => {
        return onValueChange(e.target.value)
      }}
    >
      <option
        disabled
        color={placeholderTextColor}
        value=""
        style={{
          fontFamily,
          fontSize,
        }}
      >
        {placeholder ?? ""}
      </option>
      {options.map((option) => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )
      })}
    </select>
  )
}
