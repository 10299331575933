import React from "react"
import { useTranslation } from "react-i18next"

import {
  Geo,
  getActiveFarmCoordinatesFromState,
  getActiveFarmNameFromState,
  getMapTypeIdFromState,
  Urls,
  useRootSelector,
  useShallowEqualSelector,
} from "@fhq/app"
import { getFontName } from "@fhq/app/components/theme"
import { Marker } from "@react-google-maps/api"

const MARKER_URL = `${Urls.IMAGE_HOST}/markers/farm@2x.png` as const
const FONT_FAMILY = getFontName("RobotoSlab_900Black")

/**
 * Display location and name of active farm
 */
export default function ActiveFarmMarker(): React.JSX.Element | null {
  const { t } = useTranslation("farm")
  const mapTypeId = useRootSelector(getMapTypeIdFromState)
  const farmName = useRootSelector(getActiveFarmNameFromState)
  const farmLocation = useShallowEqualSelector((state) =>
    Geo.point(getActiveFarmCoordinatesFromState(state))?.toGmaps(),
  )
  const fallbackText: string = t("title")
  const { icon, label } = React.useMemo((): {
    icon: google.maps.Icon
    label: google.maps.MarkerLabel
  } => {
    return {
      icon: {
        labelOrigin: new google.maps.Point(30, 50),
        scaledSize: new google.maps.Size(40, 70),
        url: MARKER_URL,
      },
      label: {
        className: "active-farm-marker-label",
        color: "white",
        fontFamily: FONT_FAMILY,
        fontSize: "14px",
        fontWeight: "700",
        text: farmName ?? fallbackText,
      },
    }
  }, [fallbackText, farmName])

  if (farmLocation && mapTypeId !== "HYBRID") {
    return (
      <Marker
        animation={0}
        icon={icon}
        label={label}
        position={farmLocation}
        title="active-farm-marker"
      />
    )
  }

  return null
}
