import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { Box } from "./Box"
import { Heading } from "./Heading"
import { renderIconFromIconProp } from "./icons/AppIcons"
import { Paper } from "./Paper"
import { Row } from "./Row"
import { AppText } from "./Text"
import { COLORS } from "./theme"
import { SPACING } from "./theme/spacing"
import { useIsDarkMode } from "./ThemeProvider"

import type { HeadingProps } from "./Heading"
import type { IconProp } from "./icons/AppIcons"
import type { PaperProps } from "./Paper"
import type { NoChildren } from "./types"

import type { RowProps } from "./Row"

interface CardHeaderProps {
  IconComponent?: IconProp | null
  _titleTextProps?: NoChildren<HeadingProps>
  actionElement?: React.JSX.Element | null
  titleText?: string
}

const styles = StyleSheet.create({
  root: {
    paddingBottom: SPACING.$4,
    paddingHorizontal: SPACING.$4,
    paddingTop: SPACING.$2,
  },
  // titleIcon: {
  //   marginRight: SPACING.$2
  // },
  titleRow: {
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
  },
})
/**
 * Header element for the top of a Card component
 */
export function CardHeader({
  _titleTextProps,
  IconComponent,
  actionElement,
  titleText,
  ...rest
}: CardHeaderProps & NoChildren<RowProps>) {
  const isDark = useIsDarkMode()

  const titleElement =
    typeof titleText === "string" ? (
      <Box flex={1}>
        <Heading variant="h4" {..._titleTextProps}>
          {titleText}
        </Heading>
      </Box>
    ) : null

  let resolvedColor = isDark
    ? COLORS.$textLight.primary
    : COLORS.$textDark.primary

  if (_titleTextProps?.colorScheme === "lightText") {
    resolvedColor = COLORS.$textLight.primary
  } else if (_titleTextProps?.colorScheme === "darkText") {
    resolvedColor = COLORS.$textDark.primary
  }
  const iconElement = renderIconFromIconProp(IconComponent, {
    color: resolvedColor,
    size: 18,
  })

  // TODO: Fix the layout when there is no title but an icon exists

  if (Boolean(titleElement) || Boolean(iconElement) || Boolean(actionElement)) {
    return (
      <Row {...rest} style={styles.titleRow}>
        {iconElement ? (
          <Box data-testid="icon-container" mr="$2">
            {iconElement}
          </Box>
        ) : null}
        {titleElement}
        {actionElement ? <Box ml="auto">{actionElement}</Box> : null}
      </Row>
    )
  }
  return null
}

export type CardProps = CardHeaderProps & PaperProps

/**
 * A card component with a title and optional icon and action element
 */
export function Card({
  _titleTextProps,
  IconComponent,
  actionElement,
  children,
  id,
  style,
  titleText,
  ...rest
}: CardProps) {
  return (
    <Paper
      id={id}
      rounded="$default"
      style={useStyle(() => {
        return [styles.root, style]
      }, [style])}
      {...rest}
    >
      <CardHeader
        IconComponent={IconComponent}
        _titleTextProps={_titleTextProps}
        actionElement={actionElement}
        titleText={titleText}
      />
      {typeof children === "string" ? <AppText>{children}</AppText> : children}
    </Paper>
  )
}
