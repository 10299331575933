import * as turf from "@turf/turf"

import { logger } from "./logger"
import { isTruthyString, isValidNumber, makeValidator } from "./type-guards"
import { useRootSelector } from "./useRootSelector"

import type { RootState } from "./root.reducer"
import type { DeepPartial, SetDifference } from "utility-types"

export const MapTypeIds = {
  HYBRID: { default: "hybrid", web: "hybrid" },
  SATELLITE: { default: "satellite", web: "satellite" },
  TERRAIN: { default: "standard", web: "terrain" },
} as const
// HYBRID_FLYOVER: "hybridFlyover",
// MUTED_STANDARD: "mutedStandard",
// SATELLITE_FLYOVER: "satelliteFlyover",
export type MapTypeId = keyof typeof MapTypeIds
export const isValidMapTypeId = makeValidator(
  Object.keys(MapTypeIds) as MapTypeId[],
)

export type MapListView = "device" | "field"

export type MapItemType = MapListView | "reel-run"
export const GOOGLE_MAPS_LIBRARIES: Array<"drawing" | "visualization"> = [
  "drawing",
  "visualization",
]
export interface RnmLatLng {
  latitude: number
  longitude: number
}

export type Coordinates = [number, number]
export interface PointGeoJson {
  coordinates: Coordinates
  type: "Point"
}
export type AnyLatLng = google.maps.LatLngLiteral | RnmLatLng
export type AnyPoint =
  | AnyLatLng
  | Coordinates
  | google.maps.LatLng
  | PointGeoJson

export const PointsOfInterest: {
  [key in "CENTER_OF_USA"]: {
    coordinates: Coordinates
    native: RnmLatLng
    web: google.maps.LatLngLiteral
  }
} = {
  CENTER_OF_USA: {
    coordinates: [-103.46, 44.6],
    native: {
      latitude: 44.6,
      longitude: -103.46,
    },
    web: {
      lat: 44.6,
      lng: -103.46,
    },
  },
}
export type TrueLinearRing = [
  Coordinates,
  Coordinates,
  ...Coordinates[],
  Coordinates,
]
export interface PolygonGeoJson {
  coordinates: [TrueLinearRing, ...TrueLinearRing[]]
  type: "Polygon"
  bbox?: turf.BBox
}
export const round = turf.round

export function roundCoordinate(value: number): number {
  return round(value, 7)
}

export function roundCoordinates([lng, lat]: Coordinates): Coordinates {
  return [roundCoordinate(lng), roundCoordinate(lat)]
}

export interface PointProjectionOptions {
  distanceMeters: number
  direction?: number
}

export interface GeoPoint extends PointGeoJson {
  coordsEqual: (other: AnyPoint | Coordinates) => boolean
  getCoords: () => Coordinates | undefined
  getLat: () => number | undefined
  getLng: () => number | undefined
  project: (options: PointProjectionOptions) => GeoPoint | undefined
  projectMm: (options: {
    direction: number
    distance: number
  }) => GeoPoint | undefined
  toGmaps: () => google.maps.LatLngLiteral | undefined
  toJson: () => PointGeoJson
  toNative: () => RnmLatLng | undefined
}

export type PointInput =
  | DeepPartial<AnyPoint | Coordinates | number[] | PointGeoJson>
  | string
  | null
  | undefined
interface IPoint {
  getCoords: () => Coordinates | undefined
}

export function pointToGmaps(
  value: IPoint | undefined,
): google.maps.LatLngLiteral | undefined {
  const [longitude, latitude] = value?.getCoords() ?? []
  if (isValidNumber(longitude) && isValidNumber(latitude)) {
    return {
      lat: latitude,
      lng: longitude,
    }
  }
  return undefined
}

export function pointToNative(
  value: IPoint | undefined,
): RnmLatLng | undefined {
  const [longitude, latitude] = value?.getCoords() ?? []
  if (isValidNumber(longitude) && isValidNumber(latitude)) {
    return {
      latitude,
      longitude,
    }
  }
  return undefined
}

export function point(input: DeepPartial<PointInput>): GeoPoint | undefined {
  let lng: number | undefined
  let lat: number | undefined
  if (isTruthyString(input)) {
    return point(JSON.parse(input) as SetDifference<PointInput, string>)
  }
  if (input) {
    try {
      if ("coordinates" in input) {
        return point(input.coordinates)
      }
      if (Array.isArray(input)) {
        ;[lng, lat] = input
      } else if ("lng" in input) {
        if (isValidNumber(input.lng) && isValidNumber(input.lat)) {
          lng = input.lng
          lat = input.lat
        } else if (
          typeof input.lng === "function" &&
          typeof input.lat === "function"
        ) {
          lng = input.lng()
          lat = input.lat()
        }
      } else if ("longitude" in input) {
        lng = input.longitude
        lat = input.latitude
      }
      if (isValidNumber(lng) && isValidNumber(lat)) {
        return {
          coordinates: roundCoordinates([lng, lat]),
          coordsEqual(other) {
            if (Array.isArray(other)) {
              return other[0] === this.getLng() && other[1] === this.getLat()
            }
            if ("lng" in other && "lat" in other) {
              if (isValidNumber(other.lng) && isValidNumber(other.lat)) {
                return this.coordsEqual([other.lng, other.lat])
              } else if (
                typeof other.lng === "function" &&
                typeof other.lat === "function"
              ) {
                return this.coordsEqual([other.lng(), other.lat()])
              }
            } else if ("longitude" in other && "latitude" in other) {
              return this.coordsEqual([other.longitude, other.latitude])
            }

            return false
          },
          getCoords() {
            return [...this.coordinates]
          },
          getLat() {
            const result = this.coordinates[1]
            if (isValidNumber(result)) {
              return result
            }
            return undefined
          },
          getLng() {
            const result = this.coordinates[0]
            if (isValidNumber(result)) {
              return result
            }
            return undefined
          },
          project({ direction = -180, distanceMeters }) {
            try {
              const latitude = this.getLat()
              const longitude = this.getLng()
              if (isValidNumber(longitude) && isValidNumber(latitude)) {
                const geometry = turf.destination(
                  turf.point([this.coordinates[0], this.coordinates[1]]),
                  distanceMeters,
                  direction,
                  { units: "meters" },
                ).geometry
                return point(geometry.coordinates)
              }
            } catch (error) {
              logger.error(error)
              logger.error(
                "coordinates",
                this.getLng(),
                typeof this.getLng(),
                this.getLat(),
                typeof this.getLat(),
              )
            }
            return undefined
          },
          projectMm(options) {
            return this.project({
              direction: options.direction,
              distanceMeters: options.distance / 1000,
            })
          },
          toGmaps() {
            return pointToGmaps(this)
          },
          toJson() {
            return {
              coordinates: this.coordinates,
              type: this.type,
            }
          },
          toNative() {
            return pointToNative(this)
          },
          type: "Point",
        }
      }
    } catch (error) {
      logger.error(error)
    }
  }
  return undefined
}

export interface GeoPolygon {
  addBuffer: (bufferMeters: number) => GeoPolygon | undefined
  coordinates: TrueLinearRing[]
  getBoundingBox: () => google.maps.LatLngBoundsLiteral
  getCenter: () => GeoPoint | undefined
  getOuterRing: () => TrueLinearRing
  toGmaps: () => google.maps.LatLngLiteral[]
  toJson: () => PolygonGeoJson
  toNative: () => RnmLatLng[]
  type: "Polygon"
}

export type MakePolygonInput =
  | Coordinates[]
  | google.maps.LatLngLiteral[]
  | number[][]
  | PolygonGeoJson
  | RnmLatLng[]
  | turf.Position[]

/**
 * Validates points and creates 'true' linear ring (i.e. first and last points equal).
 *
 * @param input - outer path of the polygon coordinates as array of points (lat-lng, geojson, etc.)
 * @returns {TrueLinearRing} points converted to coordinates, closed if necessary, and validated
 */
function makeLinearRing(
  input: MakePolygonInput | null | undefined,
): TrueLinearRing | undefined {
  let outerRing: TrueLinearRing | undefined

  const initializer = [] as Coordinates[]
  if (Array.isArray(input)) {
    const points = [...input].reduce((acc, next): Coordinates[] => {
      const asCoordinates = point(next)
      if (asCoordinates) {
        return [...acc, asCoordinates.coordinates]
      }
      return acc
    }, initializer)
    const [first, second, ...rest] = points
    const last = rest.pop()
    if (first && second && last) {
      if (first[0] === last[0] && first[1] === last[1]) {
        outerRing = [first, second, ...rest, last]
      } else {
        outerRing = [first, second, ...rest, last, [...first]]
      }
    }
  } else {
    const coordinates = input?.coordinates[0]
    if (Array.isArray(coordinates)) {
      return makeLinearRing(coordinates)
    }
  }
  if (outerRing) {
    return outerRing
  }
  return undefined
}

export function polygon(
  input: MakePolygonInput | null | undefined,
): GeoPolygon | undefined {
  try {
    const outerRing = makeLinearRing(input)
    if (outerRing) {
      return {
        addBuffer(bufferMeters) {
          const asJson = this.toJson()
          const buffered = turf.buffer(
            turf.polygon(asJson.coordinates),
            bufferMeters,
            { units: "meters" },
          )
          return polygon(buffered.geometry.coordinates[0])
        },
        coordinates: [outerRing],
        getBoundingBox() {
          const asJson = this.toJson()
          const box = turf.bbox(turf.polygon(asJson.coordinates))
          const [west, south, east, north] = box
          return { east, north, south, west }
        },
        getCenter() {
          const center = turf.center(this).geometry.coordinates
          return point(center)
        },
        getOuterRing() {
          const ring = this.coordinates[0]
          if (typeof ring === "undefined") {
            throw new TypeError(`Outer ring is undefined`)
          }
          return ring
        },
        toGmaps() {
          const ring = this.getOuterRing()

          const initialValue = [] as google.maps.LatLngLiteral[]
          return ring.reduce((acc, next) => {
            const asCoord = point(next)
            if (asCoord) {
              const asGmaps = asCoord.toGmaps()
              if (asGmaps) {
                return [...acc, asGmaps]
              }
            }
            return acc
          }, initialValue)
        },
        toJson() {
          return {
            coordinates: [this.getOuterRing()],
            type: "Polygon",
          }
        },
        toNative() {
          return [...this.getOuterRing()].reduce((acc, next) => {
            const asPoint = point(next)?.toNative()
            if (asPoint) {
              return [...acc, asPoint]
            }
            return acc
          }, [] as RnmLatLng[])
        },
        type: "Polygon",
      }
    }
  } catch (error) {
    logger.error(error)
  }
  return undefined
}

/**
 * Compares the latitude and longitude of two points in any format.
 * Note that the format of the points can be different.
 *
 * @param a any point of accepted formats
 * @param b any point of accepted formats
 * @returns true if they points are comprised of equal coordinates, false otherwise
 */
export function pointsAreEqual<A extends AnyPoint, B extends AnyPoint>(
  a: A | undefined,
  b: B | undefined,
): boolean {
  const pointA = point(a)
  const pointB = point(b)

  if (pointA && pointB) {
    return pointA.coordsEqual(pointB.coordinates)
  }
  if (typeof pointA === "undefined") {
    return typeof pointB === "undefined"
  }
  return false
}

/**
 * Converts azimuth to human-readable bearing text
 *
 * @example
 * // returns `10`\u00b0` SW
 * convertAzimuthToBearingText(100)
 *
 * @param azimuth
 * @throws {Error} if quadrant is calculated as anything but an integer 0-4
 */
function convertAzimuthToBearingText(azimuth: number): string {
  const quadrantAsNumber = Math.floor(azimuth / 90)
  const degreesPastQuadrant = azimuth % 90
  /**
   * Add degrees symbol and quadrant name
   *
   * @param quadrant
   */
  function formatString(quadrant: string): string {
    return `${degreesPastQuadrant}${`\u00B0`} ${quadrant}`
  }

  switch (Math.abs(quadrantAsNumber)) {
    case 0:
    case 4:
      return formatString(`SW`)
    case 1:
      return formatString(`NW`)
    case 2:
      return formatString(`NE`)
    case 3:
      return formatString(`SE`)
    default: {
      logger.warn(`Invalid quadrant: ${quadrantAsNumber} (azimuth=${azimuth})`)
      return ""
    }
  }
}
/**
 * Convert azimuth value to bearing with cardinal direction and degrees
 * between zero and 90
 *
 * @param azimuth
 */
export function humanizeAzimuth(azimuth: number): string {
  const rounded = Math.round(Math.abs(azimuth))
  switch (rounded) {
    case 0:
    case 360:
      return `Due South`
    case 90:
      return `Due West`
    case 180:
      return `Due North`
    case 270:
      return `Due East`
    default: {
      return convertAzimuthToBearingText(rounded)
    }
  }
}
export function usePointSelector<T extends AnyPoint>(
  selector: (state: RootState) => T | undefined,
): T | undefined {
  return useRootSelector(selector, pointsAreEqual)
}
// export const Geo = {
//   point,
//   pointsAreEqual,
//   polygon,
// }

export interface PointTransformer<Point> {
  (point: PointInput | null | undefined): Point | undefined
}

export interface PolygonTransformer<Point> {
  (polygon: PolygonGeoJson | null | undefined): Point[] | undefined
}
export interface GeoTransformers<Point> {
  transformPoint: PointTransformer<Point>
  transformPolygon: PolygonTransformer<Point>
}
export interface MultiPoint {
  native: RnmLatLng
  web: google.maps.LatLngLiteral
}
export function createMultiPoint(
  inputValue: PointInput,
): MultiPoint | undefined {
  const pt = point(inputValue)
  const google = pt?.toGmaps()
  const native = pt?.toNative()
  if (google && native) {
    return { native, web: google }
  }
  return undefined
}
export interface MultiPath {
  native: RnmLatLng[]
  web: google.maps.LatLngLiteral[]
}
export function createMultiPath(
  inputValue: MakePolygonInput,
): MultiPath | undefined {
  const wrapped = polygon(inputValue)

  const native = wrapped?.toNative()
  const web = wrapped?.toGmaps()

  if (native && web) {
    return { native, web }
  }
  return undefined
}
export const Polygons: { [key in "CREATE_FIELD_TEST"]: PolygonGeoJson } = {
  CREATE_FIELD_TEST: {
    coordinates: [
      [
        [-122.3457162, 48.5004877],
        [-122.3563592, 48.5007152],
        [-122.3563592, 48.5002034],
        [-122.3524969, 48.4974734],
        [-122.3456304, 48.4974165],
        [-122.3457162, 48.5004877],
      ],
    ],
    type: "Polygon",
  },
}
export const FieldSvgPoints = {
  TIMS_HOUSE: {
    points:
      "0.00877450000000124, 0.006564600000004361 0.0027984999999972615, 0.006564600000004361 0.002712500000001228, 0.005427600000004418 0.0033985000000029686, 0.005399600000004057 0.0033985000000029686, 0.005399600000004057 0.0033985000000029686, 0.005399600000004057 0.003634499999989771, 0.005378600000000233 0.003409500000003618, 0.001804599999999823 0.005383499999993546, 0.001804599999999823 0.005973499999996079, 0.003928600000001836 0.005952499999992256, 0.004696600000002604 0.00707849999999155, 0.004703600000006247 0.007057500000001937, 0.0040636000000020545 0.006252500000002215, 0.0037586000000047193 0.005630499999995209, 0.0017976000000032855 0.00715449999999862, 0.001832600000000184 0.010597500000002924, 0.005434600000000955 0.008901499999993234, 0.005477600000006078 0.008934499999995182, 0.006564600000004361 0.00877450000000124, 0.006564600000004361",
    viewbox: "0 0 0.013313299999992978 0.008363200000005122",
  },
}
export const MAP_LINKS = {
  appleMaps:
    "https://maps.apple.com/?q=${encodedLabel}&${lat},${lng}&ll=${lat},${lng}",
  googleMaps:
    "https://www.google.com/maps?q=${lat},${lng}&ll=${lat},${lng}&label=${encodedLabel}",
} as const
export type MapProvider = keyof typeof MAP_LINKS

/**
 * Create url for google maps or apple maps
 */
export function convertLatLngToMapLink(
  { latitude: lat, longitude: lng }: RnmLatLng,
  { label, mapProvider }: { label: string; mapProvider: MapProvider },
): string {
  const path = MAP_LINKS[mapProvider]
  return path
    .replaceAll("${lng}", `${lng}`)
    .replaceAll("${lat}", `${lat}`)
    .replace("${encodedLabel}", encodeURIComponent(label))
}
