import React from "react"
import { Platform } from "react-native"
import * as ReactRouter from "react-router-dom"

import {
  configureLogging,
  fileLoggerFactory,
  getLogLevel,
  Loggers,
} from "@fhq/app"
import { COLORS } from "@fhq/app/components/theme"
import { isValidDatabaseName, Urls } from "@fhq/app/Internal"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

let TARGET_DATABASE_NAME = process.env.REACT_APP_TARGET_DATABASE_NAME

// TARGET DB NAME
if (typeof TARGET_DATABASE_NAME === "string") {
  TARGET_DATABASE_NAME = TARGET_DATABASE_NAME.toUpperCase()
}

if (!isValidDatabaseName(TARGET_DATABASE_NAME)) {
  let message = `Database Name is undefined`
  if (typeof TARGET_DATABASE_NAME === "string") {
    message = `Invalid databae name supplied: ${JSON.stringify(
      TARGET_DATABASE_NAME,
    )}`
  }
  throw new TypeError(message)
}

const APP_VERSION = process.env.REACT_APP_VERSION ?? "unknown"
const IS_END_TO_END_TEST = Boolean(window.Cypress)
const ENABLE_SENTRY =
  (!__DEV__ && !IS_END_TO_END_TEST) ||
  Boolean(process.env.REACT_APP_ENABLE_SENTRY)

// INITIALIZE SENTRY
Sentry.init({
  attachStacktrace: true,
  autoSessionTracking: true,
  // debug: __DEV__,
  dsn: Urls.SENTRY_DSN.web,
  enabled: ENABLE_SENTRY,
  environment: TARGET_DATABASE_NAME,
  ignoreErrors: [
    "NetworkError",
    // Ignore Safari browser errors
    "ResizeObserver loop limit exceeded",
  ],
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        ReactRouter.useLocation,
        ReactRouter.useNavigationType,
        ReactRouter.createRoutesFromChildren,
        ReactRouter.matchRoutes,
      ),
      traceXHR: true,
    }),
  ],
  release: APP_VERSION,
  sampleRate: 1,
  sendClientReports: true,
  tracesSampleRate: 1,
})

Sentry.setTag("Platform.OS", Platform.OS)
Sentry.setTag(
  "git_commit_sha",
  process.env.REACT_APP_GIT_COMMIT_SHA ?? "unknown",
)

/**
 * Environment variables
 */
export const Environment = {
  appVersion: APP_VERSION,
  getTimezone: () => Intl.DateTimeFormat().resolvedOptions().timeZone,
  isAnalyticsEnabled: TARGET_DATABASE_NAME === "PROD" && !__DEV__,
  isEndToEndTest: IS_END_TO_END_TEST,
  isSentryEnabled: ENABLE_SENTRY,
  linkUrls: Urls.getWebappUrls(
    TARGET_DATABASE_NAME,
    process.env.REACT_APP_LOCALHOST,
  ),
  localhost: process.env.REACT_APP_LOCALHOST ?? null,
  logLevel: getLogLevel({
    logLevel:
      process.env.LOG_LEVEL ?? process.env.REACT_APP_LOG_LEVEL ?? "error",
    targetDatabaseName: TARGET_DATABASE_NAME,
  }),
  targetDatabaseName: TARGET_DATABASE_NAME,
} as const

// LOGGING
configureLogging({
  level: Environment.logLevel,
})

export const logger = Loggers.WEB
logger.debug(Environment)
export const makeFileLogger = fileLoggerFactory("WEB")

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  ReactRouter.createBrowserRouter,
)
// Load Fonts
// Font.loadAsync(FONT_ASSETS).catch((error) => {
//   logger.error("Font Error")
//   logger.error(error)
// })

export interface UseLocationReturnType {
  state?: { from?: Location } | null | undefined
}
declare global {
  interface Window {
    Cypress?: unknown
  }
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_ENABLE_SENTRY: string | undefined
      // See .env files in env root
      REACT_APP_LOCALHOST: string | undefined
      REACT_APP_LOG_LEVEL: string | undefined
      REACT_APP_SEGMENT_WRITE_KEY: string | undefined
      REACT_APP_TARGET_DATABASE_NAME: string | undefined
      REACT_APP_VERSION: string | undefined
      SENTRY_AUTH_TOKEN: string | undefined
    }
  }
}

export const SWATH_OUTLINE_STROKE_WEIGHT = 2

export const BASE_SWATH_FILL_OPACITY = 0.5
export const BASE_SWATH_OPTIONS: google.maps.PolygonOptions = {
  fillColor: COLORS.$reelRunSwath.fill,
  fillOpacity: BASE_SWATH_FILL_OPACITY,
  strokeColor: COLORS.$reelRunSwath.outline,
}
