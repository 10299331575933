import React from "react"
import { useTranslation } from "react-i18next"

import { Badge, Box } from "./components"
import { AppText } from "./components/Text"

import type { BadgeProps } from "./components"
import type { FarmJoinCode } from "./models"
export function HelpContent() {
  const { t } = useTranslation("farmUserPermissions")
  return (
    <Box>
      <AppText>{t("farmJoinCodeHelpSummary")}</AppText>
    </Box>
  )
}

interface FarmJoinCodeStatusBadgeProps {
  codeStatus: FarmJoinCode["codeStatus"]
}

export function FarmJoinCodeStatusBadge({
  codeStatus,
  ...rest
}: FarmJoinCodeStatusBadgeProps): React.JSX.Element {
  const { t } = useTranslation("farm")
  // let colorScheme: BadgeProps["colorScheme"] = "$green"
  // if (codeStatus === "EXPIRED") {
  //   colorScheme = "$warning"
  // } else if (codeStatus === "NOT_CREATED") {
  //   colorScheme = "$gray"
  // }

  let text: string
  let colorScheme: BadgeProps["colorScheme"] = "$green"
  switch (codeStatus) {
    case "ACTIVE": {
      text = t("farmJoinCodeStatus.ACTIVE")
      break
    }
    case "EXPIRED": {
      text = t("farmJoinCodeStatus.EXPIRED")
      colorScheme = "$warning"
      break
    }
    case "NOT_CREATED": {
      text = t("farmJoinCodeStatus.NOT_CREATED")
      colorScheme = "$gray"
      break
    }
  }

  return (
    <Badge {...rest} colorScheme={colorScheme}>
      {text}
    </Badge>
  )
}
