import React from "react"

import { Box } from "./Box"
import { Heading } from "./Heading"
import { ICON_SIZES, renderIconFromIconProp } from "./icons"
import { Row } from "./Row"

import type { BoxProps } from "./Box"
import type { HeadingProps } from "./Heading"
import type { IconProp } from "./icons"
import type { NoChildren } from "./types"
import type { ViewProps } from "./View"

export interface TitleWithIconProps extends NoChildren<BoxProps> {
  titleText: string
  IconComponent?: IconProp | null
  _headingProps?: HeadingProps
  headingVariant?: HeadingProps["variant"]
  helpButtonElement?: JSX.Element | null
  rightElement?: JSX.Element | null
  subheaderElement?: React.JSX.Element | string | null
  variant?: "pageTitle"
}

/**
 * A title with an icon on the left and an optional element on the right.
 */
export function TitleWithIcon({
  _headingProps,
  IconComponent,
  headingVariant = "h4",
  helpButtonElement,
  rightElement,
  subheaderElement,
  titleText,
  ...rest
}: TitleWithIconProps & ViewProps): React.JSX.Element {
  const iconElement = Boolean(IconComponent)
    ? renderIconFromIconProp(IconComponent, { size: ICON_SIZES.$lg })
    : null
  return (
    <Box {...rest}>
      <Row w="$full">
        <Row flexWrap="nowrap" mb="$1">
          {iconElement ? <Box mr="$2">{iconElement}</Box> : null}
          <Heading
            isTruncated
            textTransform="capitalize"
            variant={headingVariant}
            {..._headingProps}
          >
            {titleText}
          </Heading>
          {helpButtonElement ? <Box ml="$1">{helpButtonElement}</Box> : null}
        </Row>
        {rightElement ? <Box ml="auto">{rightElement}</Box> : null}
      </Row>
      {typeof subheaderElement === "string" ? (
        <Heading colorScheme="secondary" variant="h5">
          {subheaderElement}
        </Heading>
      ) : (
        subheaderElement
      )}
    </Box>
  )
}
