import React from "react"
import { useTranslation } from "react-i18next"

import { useToasts } from "./components/useToasts"
import { makeFileLogger } from "./logger"
import { getIsDemoModeActiveFromState } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type { LiteralUnion } from "type-fest"
export interface ErrorOptions {
  toastMessage?: LiteralUnion<"default", string> | true
}

const logger = makeFileLogger({ fileName: `useErrorHandler.ts` })

/**
 * Hook for handling errors on external api calls
 */
export function useErrorHandler(baseOptions?: ErrorOptions) {
  const { t } = useTranslation()
  const toasts = useToasts()
  const isDemoEnabled = useRootSelector(getIsDemoModeActiveFromState)
  const baseToastMessage = baseOptions?.toastMessage
  // const defaultTitle = t("oops", { ns: "common" })
  const defaultMessage = t("somethingWentWrong", { ns: "common" })
  /**
   *
   */
  return React.useCallback(
    (error: unknown, overrides?: ErrorOptions): undefined => {
      let toastMessage = baseToastMessage ?? overrides?.toastMessage
      if (toastMessage === "default" || toastMessage === true) {
        toastMessage = defaultMessage
      }

      const errorName = (error as Partial<Error> | undefined)?.name
      // ignore async thunk condition rejection
      if (errorName === "ConditionError") {
        return undefined
      }
      if (
        !isDemoEnabled &&
        typeof toastMessage === "string" &&
        toastMessage.length > 0
      ) {
        toasts.error(toastMessage)
      }

      // log
      logger.error(error)
      return undefined
    },
    [baseToastMessage, defaultMessage, isDemoEnabled, toasts],
  )
}
