import React from "react"
import { View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { RADII } from "./theme"
import { COLORS } from "./theme/colors"
import { useIsDarkMode } from "./ThemeProvider"

import type { ViewProps } from "react-native"
export interface AvatarProps extends ViewProps {
  bg?: string
  size?: "lg" | "md" | "sm" | "xs"
  variant?: "paper"
}

export function Avatar({ bg, size = "sm", style, ...props }: AvatarProps) {
  const isDark = useIsDarkMode()
  let sizeValue: number
  switch (size) {
    case "xs": {
      sizeValue = 30
      break
    }
    case "sm": {
      sizeValue = 40
      break
    }
    case "md": {
      sizeValue = 45
      break
    }
    case "lg": {
      sizeValue = 60
      break
    }
  }
  const defaultBg = isDark ? COLORS.$gray[600] : COLORS.$gray[100]
  return (
    <View
      style={useStyle(() => {
        return [
          {
            alignItems: "center",
            backgroundColor: bg ?? defaultBg,
            borderRadius: RADII.$full,
            display: "flex",
            height: sizeValue,
            justifyContent: "center",
            width: sizeValue,
          },
          style,
        ]
      }, [bg, defaultBg, sizeValue, style])}
      {...props}
    />
  )
}
