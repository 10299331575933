import React from "react"
import { StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { isValidNumber } from "../type-guards"
import { testIds } from "./test-id"
import { AppText } from "./Text"
import { COLORS, RADII, SIZES } from "./theme"
import { useColorMode } from "./ThemeProvider"

import type { ViewProps } from "react-native"
import type { WithOptionalTestId } from "./test-id"

const styles = StyleSheet.create({
  dark: {
    backgroundColor: COLORS.$paper.dark,
  },
  root: {
    alignItems: "center",
    backgroundColor: COLORS.$paper.light,
    borderColor: COLORS.$dark[500],
    borderRadius: RADII.$full,
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex",
    height: SIZES.$5,
    justifyContent: "center",
    width: SIZES.$5,
  },
})

/**
 * A component that displays a number in a circle.
 */
export function ListNumber({
  id,
  value,
  ...rest
}: WithOptionalTestId<ViewProps> & {
  value: number | undefined
}): React.JSX.Element | null {
  const mode = useColorMode()
  const style = useStyle(() => {
    return [styles.root, mode === "dark" ? styles.dark : {}]
  }, [mode])
  if (isValidNumber(value)) {
    return (
      <View style={style} {...rest} {...testIds(id)}>
        <AppText fontSize="$xs">{value + 1}</AppText>
      </View>
    )
  }
  return null
}
