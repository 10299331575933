import React from "react"
import { Platform } from "react-native"

/**
 * A hook that disables scrolling on the body element.
 */
export function useDisableScroll() {
  React.useEffect(() => {
    const isWeb = Platform.OS === "web"
    if (isWeb) {
      document.body.style.overflow = "hidden"
    }
    return () => {
      if (isWeb) {
        document.body.style.overflow = "auto"
      }
    }
  }, [])
}
