import React from "react"
import { Pressable } from "react-native"

import { ActionButtons } from "./ActionButtons"
import { Accordion, AppText, Box, Button, Row } from "./components"
import { Checkbox } from "./components/Checkbox"
import { ListItem, ListItemEndIcon, ListSubHeader } from "./components/ListItem"
import { Radio } from "./components/Radio"
import { SPACING } from "./components/theme"
import { SEED_DATABASE_DEFAULTS, SEED_DATABASE_PHONE_NUMBER } from "./constants"
import { DEVICE_PERMISSIONS, FARM_PERMISSIONS } from "./permissions"
import { FarmUserPermissionToggle } from "./PermissionsForm"
import { seedTestDatabaseAsync } from "./seedTestDatabase"
import { canSeedTestDatabase } from "./selectors"
import { humanize } from "./string-formatting"
import { useBackendRequest } from "./useBackendRequest"
import { handleReloadApp } from "./utility"

import type { SeedDatabaseOptions } from "./constants"
import type { ButtonProps } from "./components"
import type { TargetDatabaseName } from "./Internal"
import type { FarmUserPermissionName } from "./permissions"

import type { ListItemProps } from "./components/ListItem"
export type SeedDatabasePreset = "demo" | "no-farms"

export interface SeedDatabaseButtonProps {
  onPress: (() => void) | undefined
  targetDatabaseName: TargetDatabaseName | null | undefined
}

/**
 *
 */
export function SeedDatabaseListItem({
  onPress,
  targetDatabaseName,
  ...rest
}: ListItemProps & SeedDatabaseButtonProps): React.JSX.Element | null {
  if (Boolean(targetDatabaseName) && canSeedTestDatabase(targetDatabaseName)) {
    return (
      <Pressable onPress={onPress}>
        <ListItem
          EndComponent={<ListItemEndIcon />}
          IconComponent="Database"
          primary="Seed Database"
          {...rest}
        />
      </Pressable>
    )
  }
  return null
}

/**
 * Admin only form for overriding seed database defaults
 */
export function SeedDatabaseOptionsForm({
  onClose,
  onSuccess,
  targetDatabaseName,
}: {
  onClose: () => void
  targetDatabaseName: TargetDatabaseName
  onSuccess?: () => void
}): React.JSX.Element {
  const [state, setState] = React.useState<SeedDatabaseOptions>(() => ({
    ...SEED_DATABASE_DEFAULTS,
  }))

  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    seedTestDatabaseAsync,
  )

  const handleSubmit = () => {
    if (!canSeedTestDatabase(targetDatabaseName)) {
      throw new TypeError(
        `Cannot seed database in this environment: ${targetDatabaseName}`,
      )
    }
    sendRequest(state)
      .then(() => {
        toasts.success("Database seeded")
        if (onSuccess) {
          onSuccess()
        }
        return handleReloadApp()
      })
      .catch((error) => {
        handleError(error, {
          toastMessage: "Failed to seed database",
        })
      })
  }
  const makePermissionChangeHandler = (key: FarmUserPermissionName) => {
    return (nextValue: boolean) => {
      return setState((prev) => ({
        ...prev,
        activeUserPermissions: {
          ...prev.activeUserPermissions,
          [key]: nextValue,
        },
      }))
    }
  }

  return (
    <React.Fragment>
      <Row key="presets" mb="$2">
        <AppText style={{ marginRight: SPACING.$4 }}>Preset</AppText>
        <Radio
          orientation="horizontal"
          selectedValue={state.presetName}
          options={[
            {
              label: "Default",
              value: "demo",
            },
            {
              label: "No Farms",
              value: "no-farms",
            },
          ]}
          onChange={(nextValue) =>
            setState((previous) => ({
              ...previous,
              presetName: nextValue as SeedDatabasePreset,
            }))
          }
        />
      </Row>
      <Box key="user-settings">
        <ListSubHeader>User Settings</ListSubHeader>
        {(
          [
            "activeUserIsAdmin",
            "termsOfServiceAccepted",
            "usesMetricSystem",
          ] as const
        ).map(
          (key): React.JSX.Element => (
            <Checkbox
              key={key}
              isChecked={state[key] === true}
              size="sm"
              title={humanize(key, { textFormat: "titleCase" })}
              onChange={() =>
                setState((previous) => ({
                  ...previous,
                  [key]: !Boolean(previous[key]),
                }))
              }
            />
          ),
        )}
      </Box>
      <Row my="$2">
        <AppText style={{ paddingRight: SPACING.$2 }}>
          Initial Phone Number
        </AppText>
        <Checkbox
          isChecked={Boolean(state.userPhoneNumbers)}
          size="sm"
          onChange={(nextValue) => {
            if (nextValue) {
              setState((prev) => ({
                ...prev,
                userPhoneNumbers: [{ ...SEED_DATABASE_PHONE_NUMBER }],
              }))
            } else {
              setState((prev) => ({ ...prev, userPhoneNumbers: null }))
            }
          }}
        />
      </Row>
      {state.presetName === "demo" ? (
        <React.Fragment>
          <Accordion defaultIsOpen={false} titleText="Device Permissions">
            {DEVICE_PERMISSIONS.getKeys().map((key) => {
              return (
                <FarmUserPermissionToggle
                  key={key}
                  isEnabled={state.activeUserPermissions[key]}
                  permissionName={key}
                  onChange={makePermissionChangeHandler(key)}
                  onPressHelp={() => {
                    // eslint-disable-next-line no-alert
                    alert("Help")
                  }}
                />
              )
            })}
          </Accordion>
          <Accordion
            defaultIsOpen={false}
            style={{ marginVertical: SPACING.$4 }}
            titleText="Farm Permissions"
          >
            {FARM_PERMISSIONS.getKeys().map((key) => {
              return (
                <FarmUserPermissionToggle
                  key={key}
                  isEnabled={state.activeUserPermissions[key]}
                  permissionName={key}
                  onChange={makePermissionChangeHandler(key)}
                  onPressHelp={() => {
                    // eslint-disable-next-line no-alert
                    alert("Help")
                  }}
                />
              )
            })}
          </Accordion>
        </React.Fragment>
      ) : null}
      <ActionButtons
        isLoading={isLoading}
        onPressCancel={onClose}
        onPressSubmit={handleSubmit}
      />
    </React.Fragment>
  )
}

export function SeedDatabaseButton({
  targetDatabaseName,
  ...props
}: ButtonProps & {
  targetDatabaseName: TargetDatabaseName
}) {
  if (!canSeedTestDatabase(targetDatabaseName)) {
    return null
  }
  return <Button IconComponent="Database" text="Seed Database" {...props} />
}
