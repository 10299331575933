import React from "react"
import { Pressable } from "react-native"

import {
  AppIcons,
  AppText,
  Box,
  Divider,
  IconButton,
  renderIconFromIconProp,
  Row,
} from "./components"

import type { IconButtonProps, IconProp } from "./components"
import type { SortDirectionKey } from "./Sorting"
export function SortDirectionIconButton(
  props: Omit<IconButtonProps, "IconComponent" | "onPress"> & {
    currentDirection: SortDirectionKey
    onPress: () => unknown
    isInverted?: boolean
  },
): React.JSX.Element {
  const value = props.currentDirection

  let iconName: IconProp = "ArrowDown"
  if (value === "asc") {
    iconName = "ArrowUp"
  }
  if (props.isInverted === true) {
    iconName = "ArrowUp"
    if (value === "asc") {
      iconName = "ArrowDown"
    }
  }
  return (
    <IconButton size="sm" variant="text" {...props} IconComponent={iconName} />
  )
}

export function SortListItem({
  IconComponent,
  isSelected,
  onPress,
  showDivider,
  sortDirection,
  text,
}: {
  isSelected: boolean
  onPress: () => void
  showDivider: boolean
  sortDirection: SortDirectionKey
  text: string
  IconComponent?: IconProp
}) {
  const directionIconElement =
    sortDirection === "asc" ? (
      <AppIcons.SortAscending />
    ) : (
      <AppIcons.SortDescending />
    )
  return (
    <React.Fragment>
      <Pressable onPress={onPress}>
        <Row my="$4">
          {renderIconFromIconProp(IconComponent)}
          <Box mx="$3">
            <AppText>{text}</AppText>
          </Box>
          {isSelected ? directionIconElement : null}
        </Row>
      </Pressable>
      {showDivider ? <Divider /> : null}
    </React.Fragment>
  )
}
