import { Controller, useForm } from "react-hook-form"
import { StyleSheet, View } from "react-native"

import { FormControl } from "@fhq/app/components"

const styles = StyleSheet.create({
  spacer: {
    marginVertical: 24,
  },
})

export function CustomComponents() {
  const { control } = useForm({
    defaultValues: {
      "a": "",
      "b": "",
      "c": "",
    },
  })
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "space-evenly",
        padding: "20%",
      }}
    >
      <Controller
        control={control}
        name="a"
        render={({ field: { onChange, ...field } }) => {
          return (
            <View style={styles.spacer}>
              <FormControl.Provider>
                <FormControl.Label>Hello</FormControl.Label>
                <FormControl.Input {...field} onChangeText={onChange} />
                <FormControl.ErrorMessage>Error!</FormControl.ErrorMessage>
              </FormControl.Provider>
            </View>
          )
        }}
      />
      <Controller
        control={control}
        name="b"
        render={({ field: { onChange, ...field } }) => {
          return (
            <View style={styles.spacer}>
              <FormControl.Provider isDisabled>
                <FormControl.Label>Hello</FormControl.Label>
                <FormControl.Input {...field} onChangeText={onChange} />
                <FormControl.ErrorMessage>Error!</FormControl.ErrorMessage>
              </FormControl.Provider>
            </View>
          )
        }}
      />
      <Controller
        control={control}
        name="c"
        render={({ field: { onChange, ...field } }) => {
          return (
            <View style={styles.spacer}>
              <FormControl.Provider isInvalid>
                <FormControl.Label>Hello</FormControl.Label>
                <FormControl.Input {...field} onChangeText={onChange} />
                <FormControl.ErrorMessage>Error!</FormControl.ErrorMessage>
              </FormControl.Provider>
            </View>
          )
        }}
      />
    </View>
  )
}
