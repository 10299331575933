import React from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import {
  AlertCard,
  AppText,
  Box,
  Button,
  Divider,
  Row,
  Switch,
} from "./components"
import { ListSubHeader } from "./components/ListItem"
import { PRELOAD_FONTS } from "./components/theme"
import { useToasts } from "./components/useToasts"
import { objectEntries } from "./utility"

import type { ViewProps } from "react-native"
import type { AlertSeverity } from "./constants"
export function FontDemo(props: ViewProps): React.JSX.Element {
  return (
    <View style={{ flex: 1 }} {...props}>
      {objectEntries(PRELOAD_FONTS).map(([alias]) => {
        return (
          <Box key={alias}>
            <Row justifyContent="space-between">
              <AppText colorScheme="secondary" fontFamily={alias}>
                {alias}:
              </AppText>
              <AppText fontFamily={alias}>{alias}</AppText>
            </Row>
            <Divider />
          </Box>
        )
      })}
    </View>
  )
}

const LOREM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent maximus porttitor nibh, eu tincidunt nisi mattis eget.`
const LEVELS: AlertSeverity[] = ["success", "error", "warning", "info"]

export function AlertsDemo(props: ViewProps): React.JSX.Element {
  const [showTitle, setShowTitle] = React.useState(false)
  const [index, setIndex] = React.useState(0)
  const toasts = useToasts()

  const { t } = useTranslation("dev")
  const severity = LEVELS[index] ?? "info"

  React.useEffect(() => {
    toasts[severity](LOREM)
  }, [severity, showTitle, toasts])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIndex(index > LEVELS.length ? 0 : index + 1)
    }, 5000)
    return () => {
      clearTimeout(timeout)
    }
  }, [index])

  return (
    <View {...props}>
      <Box px="$2">
        <ListSubHeader>{t("alertsDemo.title")}</ListSubHeader>
        <Row>
          <Row flex={1}>
            {LEVELS.map((key) => {
              const showToast = toasts[key]
              const handlePress = (): void => {
                showToast(LOREM)
              }
              return (
                <Box key={key} py="$2" w="$1/2">
                  <Button text={key} variant="primary" onPress={handlePress} />
                </Box>
              )
            })}
          </Row>
          <Box>
            <AppText>{t("alertsDemo.showTitleCheckbox")}</AppText>
            <Switch value={showTitle} onValueChange={setShowTitle} />
          </Box>
        </Row>
      </Box>
      <Box px="$2">
        <ListSubHeader>{t("alertsDemo.alerts")}</ListSubHeader>
        <Box>
          {Object.values(LEVELS).map((key) => {
            return (
              <AlertCard key={key} severity={key}>
                {LOREM}
              </AlertCard>
            )
          })}
        </Box>
      </Box>
    </View>
  )
}
