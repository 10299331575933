import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { AppText, Card, Heading } from "./components"
import { SPACING } from "./components/theme/spacing"

import type { CardProps, NoChildren } from "./components"
const styles = StyleSheet.create({
  column: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  text: {
    marginBottom: SPACING.$4,
  },
})

export function Forbidden({
  backButtonElement,
  ...rest
}: NoChildren<CardProps> & {
  backButtonElement?: JSX.Element | null
}) {
  const { t } = useTranslation("farmUserPermissions")
  return (
    <Card {...rest}>
      <View style={styles.column}>
        <Heading>{t("permissionDenied")}</Heading>
        <AppText style={styles.text}>
          {t("youDoNotHavePermissionToAccessThisResource")}
        </AppText>
        {backButtonElement}
      </View>
    </Card>
  )
}
