import React from "react"
import { useTranslation } from "react-i18next"
import { Pressable, StyleSheet, Text, View } from "react-native"

import { captureException } from "@sentry/core"

import { clearStoredFarmIds } from "./async-storage"
import { COLORS, getFontName } from "./components/theme"
import { ErrorDebugInfo } from "./ErrorDebugInfo"

import type { AcceptsChildren } from "./components"
import type { FallbackRenderProps } from "./types"

const styles = StyleSheet.create({
  container: { flex: 1 },
  dismissButton: {
    alignItems: "center",
    backgroundColor: COLORS.$gray[100],
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    textTransform: "uppercase",
    width: "90%",
  },
  dismissButtonText: {
    fontFamily: getFontName("RobotoSlab_900Black"),
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  header: {
    alignItems: "center",

    display: "flex",
    height: 300,
    justifyContent: "center",
  },
  safeArea: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  scrollView: {
    flex: 1,
    padding: 10,
  },
  title: {
    fontFamily: getFontName("Poppins_500Medium"),
    fontSize: 24,
    fontWeight: "600",
    paddingBottom: 12,
  },
})

interface ErrorFallbackProps extends AcceptsChildren, FallbackRenderProps {
  debugInfo?: string | null
  isInternalDistribution?: boolean
  onSignOut?: () => void
}

// !IMPORTANT: No native-base in this file
export function ErrorFallbackText({
  children,
  debugInfo,
  isInternalDistribution = false,
  onPressDismiss,
  onSignOut,
  resetError,
}: ErrorFallbackProps & {
  onPressDismiss: () => unknown
}): React.JSX.Element {
  const { t } = useTranslation("errors")
  const handleDismiss = async () => {
    await clearStoredFarmIds().catch((e) => {
      captureException(e)
    })
    onPressDismiss()
    return resetError()
  }
  return (
    <View style={styles.safeArea}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.title}>{t("loadErrorTitle")}</Text>
          <Pressable
            style={styles.dismissButton}
            onPress={handleDismiss as () => void}
          >
            <Text style={styles.dismissButtonText}>
              {t("dismiss", { ns: "common" })}
            </Text>
          </Pressable>
          {onSignOut ? (
            <Pressable
              style={styles.dismissButton}
              onPress={onSignOut as () => void}
            >
              <Text style={styles.dismissButtonText}>
                {t("signOut", { ns: "auth" })}
              </Text>
            </Pressable>
          ) : null}
          {children}
        </View>
        <ErrorDebugInfo
          isInternal={isInternalDistribution}
          style={styles.scrollView}
        >
          {debugInfo}
        </ErrorDebugInfo>
      </View>
    </View>
  )
}
