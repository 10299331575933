import React from "react"
import { useTranslation } from "react-i18next"

import { AlertBodyText, AlertCard, Collapse, IconButton } from "./components"

import type { AlerCardProps } from "./components"
export function ExperimentalFeatureWarning({
  bodyText,
  defaultExpanded = false,
  ...rest
}: Omit<AlerCardProps, "children"> & {
  bodyText?: string
  defaultExpanded?: boolean
}) {
  const [isExpanded, setIsExpanded] = React.useState(defaultExpanded)
  const { t } = useTranslation()
  const handleOpen = () => setIsExpanded(true)
  return (
    <AlertCard
      IconComponent={null}
      _titleTextProps={{ fontSize: "$md" }}
      id="experimental-feature-warning"
      titleText={t("experimentalFeatureTitle")}
      actionElement={
        isExpanded ? (
          <IconButton
            IconComponent="ExpandLess"
            variant="text"
            onPress={() => setIsExpanded(false)}
          />
        ) : (
          <IconButton
            IconComponent="ExpandMore"
            variant="text"
            onPress={handleOpen}
          />
        )
      }
      {...rest}
    >
      <Collapse isOpen={isExpanded} onOpen={handleOpen}>
        <AlertBodyText fontSize="$sm">
          {bodyText ?? t("experimentalFeatureWarning")}
        </AlertBodyText>
      </Collapse>
    </AlertCard>
  )
}
