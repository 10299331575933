import es from "./locales/es";
import it from "./locales/it";
import fr from "./locales/fr";
import en from "./locales/en";

export const resources={ 
		en,
	es,
	fr,
	it,
 } as const