import React from "react"
import { useTranslation } from "react-i18next"

import {
  AlertBodyText,
  AlertCard,
  Box,
  Button,
  CloseIconButton,
} from "./components"
import { COLORS } from "./components/theme"
import { getDeviceSummaryByDeviceIdFromState } from "./selectors"
import { useRootSelector } from "./useRootSelector"

import type { DeviceIdProps, PermissionCheckProps } from "./types"
interface Props extends DeviceIdProps, PermissionCheckProps {
  isDismissable: boolean
  onPressCreateConfiguration: () => void
}

/**
 * Warning to display when a device is unconfigured
 */
export function UnconfiguredDeviceWarning({
  deviceId,
  isDismissable,
  onPressCreateConfiguration,
  withPermissions,
}: Props): React.JSX.Element | null {
  const [isOpen, setIsOpen] = React.useState(true)
  const { t } = useTranslation("deviceConfiguration")
  const isUnconfigured = useRootSelector(
    (state) =>
      getDeviceSummaryByDeviceIdFromState(state, deviceId)
        ?.deviceInstallationType === "unconfigured",
  )

  if (isUnconfigured && isOpen) {
    return (
      <AlertCard
        my="$2"
        severity="warning"
        titleText={t("warning", { ns: "common" })}
        actionElement={
          isDismissable ? (
            <Box ml="$2">
              <CloseIconButton
                iconColor={COLORS.$textDark.primary}
                variant="text"
                onPress={() => setIsOpen(false)}
              />
            </Box>
          ) : null
        }
      >
        <Box mb="$2">
          <Box mb="$2">
            <AlertBodyText>{t("unconfiguredDeviceWarning")}</AlertBodyText>
          </Box>
          <Box maxW="$sm" ml="auto" w="$full">
            <Button
              flexGrow={1}
              id="set-up-device-btn"
              text={t("setUpDevice")}
              onPress={withPermissions({
                callback: onPressCreateConfiguration,
                required: "canManageDeviceConfiguration",
              })}
            />
          </Box>
        </Box>
      </AlertCard>
    )
  }
  return null
}
