import React from "react"

import { captureException } from "@sentry/core"

import { AppText } from "./components/Text"

interface State {
  hasError: boolean
  error?: unknown
  errorInfo?: unknown
}

interface ErrorBoundaryProps {
  fallback?: React.JSX.Element | null
  logToSentry?: boolean
}

export class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  State
> {
  state: State = { hasError: false }

  static getDerivedStateFromError(
    error: unknown,
  ): { hasError: boolean } | undefined {
    // Update state so the next render will show the fallback UI.
    if (error as Error | undefined) {
      return {
        hasError: true,
      }
    }
    return undefined
  }

  componentDidCatch(error: unknown, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    // logger.debug(error, errorInfo);
    // if (this.props.logToSentry === true) {
    captureException(error)
    // }
    this.setState({
      error,
      errorInfo,
      hasError: true,
    })

    // logErrorToMyService(error, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (__DEV__) {
        return <AppText>TODO:error info</AppText>
      }
      return this.props.fallback ?? null
    }

    return this.props.children
  }
}

export type TErrorFallback<P> =
  | JSX.Element
  | ((props: P) => JSX.Element | null)
  | null

export interface ErrorBoundaryOptions<P> {
  fallback: TErrorFallback<P>
}

export function withErrorBoundary<P>(
  Component: React.ComponentType<P>,
  options?: ErrorBoundaryOptions<P>,
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<P> & React.RefAttributes<unknown>
> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Wrapped = React.forwardRef<any, P>((props, ref) => {
    let fallbackElement: ErrorBoundaryProps["fallback"] = null
    if (options) {
      if (typeof options.fallback === "function") {
        fallbackElement = options.fallback(props)
      } else {
        fallbackElement = options.fallback
      }
    }

    const element: React.JSX.Element = <Component ref={ref} {...props} />
    return <ErrorBoundary fallback={fallbackElement}>{element}</ErrorBoundary>
  })

  return Wrapped
}
