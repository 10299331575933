import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import { Box, FormControl } from "./components"
import { testIds } from "./components/test-id"
import { useToasts } from "./components/useToasts"
import { renameDeviceAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import { UpdateConfigurationWarning } from "./UpdateConfigurationWarning"
import { useBackendRequest } from "./useBackendRequest"

import type { BoxProps } from "./components"
import type { DeviceSummary } from "./device-configurations.reducer"
/**
 *
 */
export function RenameDeviceForm({
  deviceId,
  deviceName,
  onClosed,
  ...rest
}: BoxProps &
  Pick<DeviceSummary, "deviceId" | "deviceName"> & {
    deviceName: string
    onClosed: () => void
  }): React.JSX.Element {
  const { required } = useFormValidation()
  const { t } = useTranslation("deviceConfiguration")

  const toasts = useToasts()
  const { handleError, isLoading, sendRequest } =
    useBackendRequest(renameDeviceAsync)
  const { control, handleSubmit } = useForm({
    defaultValues: { deviceName },
  })
  const onSubmit = handleSubmit((values) => {
    sendRequest({ deviceId, deviceName: values.deviceName })
      .then(() => {
        toasts.success()
        return onClosed()
      })
      .catch((error) => handleError(error, { toastMessage: "default" }))
  })
  const labelText: string = t("deviceNameInputLabel")
  const placeholderText: string = t("deviceNameInputPlaceholder")
  return (
    <Box {...rest}>
      <Controller
        control={control}
        name="deviceName"
        rules={{ required }}
        render={({ field: { onBlur, onChange, ref, value }, fieldState }) => {
          const errorMessage: string | undefined = fieldState.error?.message
          return (
            <FormControl.Provider
              {...testIds("deviceName")}
              isDisabled={isLoading}
              isInvalid={Boolean(errorMessage)}
            >
              <FormControl.Label>{labelText}</FormControl.Label>
              <FormControl.Input
                ref={ref}
                placeholder={placeholderText}
                value={value}
                onBlur={onBlur}
                onChangeText={onChange}
              />
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
      />
      <ActionButtons
        isLoading={isLoading}
        my="$8"
        onPressCancel={onClosed}
        onPressSubmit={onSubmit}
      />
      <UpdateConfigurationWarning />
    </Box>
  )
}
