import { COLORS } from "../theme/colors"
import { FONT_SIZES, getFontName } from "../theme/fonts"
import { RADII } from "../theme/radii"
import { SIZES } from "../theme/sizes"
import { useIsDarkMode } from "../ThemeProvider"

interface InputStyleArg {
  isDisabled: boolean | undefined
  isInvalid: boolean | undefined
}
export function getInputStyle({
  isDark,
  isDisabled = false,
  isInvalid = false,
}: InputStyleArg & {
  isDark: boolean
}) {
  // LIGHT MODE
  let backgroundColor = COLORS.$text[0]
  let borderColor = COLORS.$text[400]
  let placeholderTextColor = COLORS.$textDark.secondary
  let color = COLORS.$textDark.primary
  if (isInvalid) {
    borderColor = COLORS.$invalid
  } else if (isDisabled) {
    borderColor = COLORS.$text[200]
    color = COLORS.$gray[400]
  }

  if (isDark) {
    // DARK MODE
    backgroundColor = COLORS.$background.dark
    borderColor = COLORS.$divider.dark
    placeholderTextColor = COLORS.$textLight.secondary
    color = COLORS.$textLight.primary
    if (isInvalid) {
      borderColor = COLORS.$invalid
    } else if (isDisabled) {
      borderColor = COLORS.$text[600]
      color = COLORS.$gray[500]
    }
  }
  return {
    backgroundColor,
    borderColor,
    borderRadius: RADII.$default,
    borderWidth: 2,
    color,
    fontFamily: getFontName("OpenSans_400Regular"),
    fontSize: FONT_SIZES.$md,
    paddingHorizontal: SIZES.$2,
    paddingVertical: SIZES.$2,
    placeholderTextColor,
  }
}

export function useInputStyle(params: InputStyleArg) {
  const isDark = useIsDarkMode()
  return getInputStyle({ ...params, isDark })
}
