import React from "react"
import { View as Base } from "react-native"

import { testIds } from "./test-id"

import type { ViewProps as BaseProps } from "react-native"
import type { WithOptionalTestId } from "./test-id"
export type ViewProps = WithOptionalTestId<BaseProps>

/**
 * Encapsulation of the React Native View component.
 */
export function View({ id, ...rest }: ViewProps) {
  return <Base {...testIds(id)} {...rest} />
}
