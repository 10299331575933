import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { exitDemoMode } from "./async-storage"
import { AppText, Box, Button, Row } from "./components"
import { COLORS, SPACING } from "./components/theme"
import { FONT_SIZES } from "./components/theme/fonts"
import { getIsDemoModeActiveFromState } from "./user-farms.selectors"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"
import { handleReloadApp } from "./utility"

const styles = StyleSheet.create({
  root: {
    backgroundColor: COLORS.$primary[500],
    maxHeight: 80,
    paddingHorizontal: SPACING.$4,
    paddingVertical: SPACING.$1,
  },
  text: {
    color: COLORS.$textDark.primary,
    flex: 1,
    fontSize: FONT_SIZES.$xs,
    fontStyle: "italic",
    fontWeight: "bold",
  },
})

/**
 *
 */
export function DemoActiveBanner(): React.JSX.Element | null {
  const { t } = useTranslation("demoMode")
  const dispatch = useRootDispatch()

  const isVisible = useRootSelector(getIsDemoModeActiveFromState)

  if (isVisible) {
    return (
      <View style={styles.root}>
        <Row justifyContent="space-between">
          <AppText style={styles.text}>{t("isActiveText")}</AppText>
          <Box ml="auto">
            <Button
              size="sm"
              text={t("exitDemoButton")}
              variant="outline"
              _text={{
                style: { color: COLORS.$textDark.primary },
              }}
              onPress={async () =>
                dispatch(exitDemoMode()).then(handleReloadApp)
              }
            />
          </Box>
        </Row>
      </View>
    )
  }
  return null
}
