import React from "react"

import { Box } from "./Box"

import type { BoxProps } from "./Box"
import type { OptionalTestId } from "./test-id"

/**
 * A component that wraps its children in a container with padding.
 */
export function Container(props: BoxProps & OptionalTestId) {
  return <Box px="$4" {...props} />
}
