import { turf } from "@fhq/app"
import { View } from "@fhq/app/components"
import { GoogleMap, Polygon } from "@react-google-maps/api"

const center: google.maps.LatLngLiteral = {
  lat: 40.422,
  lng: -122.084,
}
const ELEMENTS = [
  { direction: "clockwise", end: 90, start: 0, startLocation: center },
  {
    direction: "clockwise",
    end: 0,
    start: 90,
    startLocation: { lat: center.lat, lng: center.lng + 0.003 },
  },
]

export function ScratchPage() {
  const polygons = ELEMENTS.map((element) => {
    return turf.sector(
      turf.point([element.startLocation.lng, element.startLocation.lat])
        .geometry,
      100,
      element.start,
      element.end,
      { units: "meters" },
    )
  })
  return (
    <View style={{ flex: 1 }}>
      <GoogleMap
        center={center}
        mapContainerStyle={{ flex: 1 }}
        mapTypeId="satellite"
        zoom={15}
      >
        {polygons.map((polygon) => {
          const path = polygon.geometry.coordinates[0]?.map(
            ([lng, lat]) => ({ lat, lng } as google.maps.LatLngLiteral),
          )
          if (path) {
            return <Polygon path={path} />
          }
          return null
        })}
      </GoogleMap>
    </View>
  )
}
