import React from "react"

/**
 * Return true if the browser's tab is focused on our app
 */
export function useIsTabFocused() {
  const [isFocused, setIsFocused] = React.useState(true)

  /**
   * Register event callback
   */
  React.useEffect(() => {
    const handleChange = () => {
      setIsFocused(document.visibilityState === "visible")
    }

    document.addEventListener("visibilitychange", handleChange)

    return () => {
      document.removeEventListener("visibilitychange", handleChange)
    }
  }, [])

  return isFocused
}
