import type { Unit } from "convert-units"
import convert from "convert-units"
import React from "react"

import * as Geo from "./geo"
import { useMeasurementPreference } from "./selectors"
import { REEL_RUN_GRAPH_KEYS, RUN_OBSERVATION_KEYS } from "./sensors"
import { makeValidator } from "./type-guards"

import type { ValuesType } from "utility-types"
import type { AnySensorKey, ReelRunGraphKey } from "./sensors"
export const MeasurementSystem = {
  METRIC: `metric`,
  US: `us`,
} as const
export type MeasurementPreference = ValuesType<typeof MeasurementSystem>
export interface MeasurementPreferenceProps {
  measurementPreference: MeasurementPreference
}
export const isValidMeasurementPreference = makeValidator(
  Object.values(MeasurementSystem),
)
export const DEVICE_DATA_CONVERSION_KEYS = [
  ...REEL_RUN_GRAPH_KEYS,
  ...RUN_OBSERVATION_KEYS,
  `internalSoc`,
  `altitude`,
  `diameterMm`,
  `hoseDiameterMm`,
  `linearSpeedMmHMax`,
  `linearSpeedMmHMin`,
  `milliRpmAvg`,
  `milliRpmFast`,
  `milliRpmSlow`,
  `mlPerPulse`,
  `nozzleDiameterMm`,
  `outerHoseWrapRadiusMm`,
  `rateLpmAvg`,
  `rateLpmMax`,
  `rateLpmMin`,
  `readingCelsius`,
  `readingKpa`,
  `runDistanceMmCurrent`,
  `runDistanceMmElapsed`,
  `runDistanceMmMax`,
  `runSpeedMmpm`,
  `speedMillirpm`,
  `speedMmpm`,
  `swathWidthMm`,
  `thresholdPsiLower`,
  `thresholdPsiUpper`,
  `voltageMv`,
  `voltageThresholdLowMv`,
  `volumeLFinal`,
  `volumeLInitial`,
  `volumeLTotal`,
  `widthMm`,
  "pivotRadiusMeters",
] as const

export type ConversionTarget = `database` | `user`

export type RequiresConversion =
  | (typeof DEVICE_DATA_CONVERSION_KEYS)[number]
  | ReelRunGraphKey

export function requiresConversion(
  key: AnySensorKey | "pivotRadiusMeters",
): key is RequiresConversion {
  return Boolean(
    Object.values(DEVICE_DATA_CONVERSION_KEYS).includes(
      key as (typeof DEVICE_DATA_CONVERSION_KEYS)[number],
    ),
  )
}

// type T = "mlPerPulse" extends AnySensorKey ? true : false
// eslint-disable-next-line consistent-return
export function getSensorConversionSpec({
  fieldName,
  measurementPreference,
  target,
}: MeasurementPreferenceProps & {
  fieldName: AnySensorKey | undefined
  target: ConversionTarget
}):
  | {
      rawUnit: Unit | `millirpm` | `mm/h` | `mm/m`
      sigDigits: number
      userPref: Unit | `ft/hr` | `meters/hr` | `rpm`
    }
  | undefined {
  if (typeof fieldName === `undefined` || !requiresConversion(fieldName)) {
    return undefined
  }
  const isMetric = measurementPreference === "metric"
  switch (fieldName) {
    //  * LENGTH (config measurements etc.)
    // decimals for us users, not for metric (mm)
    case `diameterMm`:
    case `outerHoseWrapRadiusMm`:
    case `nozzleDiameterMm`:
    case `hoseDiameterMm`:
    case `applicationRateReportsMm`:
    case `applicationRateEstimatedMm`:
    case `widthMm`: {
      let sigDigits = 0
      if (!isMetric && target === `user`) {
        sigDigits = 1
      }
      return { rawUnit: `mm`, sigDigits, userPref: isMetric ? `mm` : `in` }
    }
    // * PRESSURE
    case `pressureObservedKpa`:
    case `pressureReportsKpa`:
    case `readingKpa`:
    case `thresholdPsiLower`:
    case `thresholdPsiUpper`: {
      const userPref = isMetric ? `kPa` : `psi`
      return { rawUnit: `psi`, sigDigits: 0, userPref }
    }
    case "pivotRadiusMeters": {
      return { rawUnit: `m`, sigDigits: 0, userPref: isMetric ? `m` : `ft` }
    }
    // * LENGTH (field dimensions)
    case `altitude`:
    case `distanceObservedMm`:
    case `runDistanceMmCurrent`:
    case `runDistanceMmElapsed`:
    case `runDistanceMmMax`:
    case `swathWidthMm`: {
      return { rawUnit: `mm`, sigDigits: 0, userPref: isMetric ? `m` : `ft` }
    }
    case `readingCelsius`: {
      return { rawUnit: `C`, sigDigits: 1, userPref: isMetric ? `C` : `F` }
    }
    case `voltageThresholdLowMv`:
      return { rawUnit: `mV`, sigDigits: 0, userPref: `V` }
    // rpm
    case `speedMillirpm`:
    case `milliRpmFast`:
    case `milliRpmSlow`:
    case `milliRpmAvg`:
      return { rawUnit: `millirpm`, sigDigits: 2, userPref: `rpm` }
    // linear speeds
    case `linearSpeedMmHMin`:
    case `linearSpeedMmHMax`: {
      const userPref = isMetric ? `meters/hr` : `ft/hr`
      return { rawUnit: `mm/h`, sigDigits: 1, userPref }
    }
    case `speedObservedMmpm`:
    case `speedReportsMmpm`:
    case `runSpeedMmpm`:
    case `speedMmpm`: {
      const userPref = isMetric ? "meters/hr" : "ft/hr"
      return { rawUnit: "mm/m", sigDigits: 0, userPref }
    }
    case `voltageMv`: {
      return { rawUnit: `mV`, sigDigits: 1, userPref: `V` }
    }
    // flow rate
    case `flowRateEstimatedLpm`:
    case `rateLpmMin`:
    case `rateLpmMax`:
    case `rateLpmAvg`: {
      const userPref = isMetric ? `l/min` : `gal/min`
      return { rawUnit: `l/min`, sigDigits: 1, userPref }
    }
    case `volumeLTotal`:
    case `volumeLInitial`:
    case `volumeLFinal`: {
      return { rawUnit: `l`, sigDigits: 1, userPref: isMetric ? `l` : `gal` }
    }
    case "internalSoc": {
      return { rawUnit: "s", sigDigits: 1, userPref: "s" }
    }
    case "mlPerPulse": {
      return {
        rawUnit: "ml",
        sigDigits: 5,
        userPref: isMetric ? "l" : "gal",
      }
    }
  }
}
/**
 * Takes any valid sensor key/value pair and converts the value from
 * specification (see getConversionUnits).
 *
 * @param argument
 * @param argument.fieldName - any sensor configuration or event key
 * @param argument.value - any primitive value or a geojson point
 * @param argument.isMetric - user preference
 * @param argument.target - 'database' or 'user'
 * @param argument.rawValue
 * @throws {Error} if the key has no conversion parameters specified but requires conversion
 */
export function convertSensorValue({
  fieldName,
  measurementPreference,
  rawValue,
  target,
}: MeasurementPreferenceProps & {
  fieldName: RequiresConversion
  rawValue: number
  target: ConversionTarget
}): number {
  let fromUnit: Unit
  let toUnit: Unit
  let converted: number
  const isMetric = measurementPreference === "metric"
  const conversionSpec = getSensorConversionSpec({
    fieldName,
    measurementPreference,
    target,
  })
  if (typeof conversionSpec === `undefined`) {
    throw new TypeError(`Conversion spec is undefined for field ${fieldName}`)
  }

  const { rawUnit, sigDigits, userPref } = conversionSpec
  switch (rawUnit) {
    case `mm/m`: {
      let divisor: number

      if (isMetric) {
        divisor = 16.67
      } else {
        divisor = 5.08
      }

      if (target === `database`) {
        // appLogger.warn('Converting key in wrong direction', key);
      }
      converted = Math.round((rawValue / divisor) * 10) / 10
      break
    }
    case `mm/h`: {
      let factor: number
      if (isMetric) {
        factor = 1000
      } else {
        factor = 304.8
      }

      if (target === `database`) {
        return Geo.round(rawValue * factor)
      }
      return Geo.round(rawValue / factor, 1)
    }
    case `millirpm`: {
      if (target === `database`) {
        converted = Math.round(rawValue * 1000)
        break
      }
      converted = rawValue / 1000
      break
    }

    default: {
      if (
        userPref === `ft/hr` ||
        userPref === `meters/hr` ||
        userPref === `rpm`
      ) {
        throw new Error(`Fallthrough in switch: ${JSON.stringify(fieldName)}`)
      }
      if (target === `database`) {
        fromUnit = userPref
        toUnit = rawUnit
      } else {
        fromUnit = rawUnit
        toUnit = userPref
      }
      converted = convert(rawValue).from(fromUnit).to(toUnit)
      break
    }
  }

  if (sigDigits > 0) {
    return Number.parseFloat(converted.toFixed(sigDigits))
  }
  return Math.round(converted)
}

export function useConvertSensorValue(
  fieldName: AnySensorKey & RequiresConversion,
) {
  const measurementPreference = useMeasurementPreference()
  return React.useCallback(
    (rawValue: number, target: ConversionTarget) =>
      convertSensorValue({
        fieldName,
        measurementPreference,
        rawValue,
        target,
      }),
    [fieldName, measurementPreference],
  )
}
