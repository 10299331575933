import React from "react"
import { useTranslation } from "react-i18next"

import { PermissionDenied, useRootDispatch, useRootSelector } from "@fhq/app"
import { AlertDialog, AlertDialogScrollView, Button } from "@fhq/app/components"
import { SIZES } from "@fhq/app/components/theme"
import { clearPermissionDenied } from "@fhq/app/user-permissions.reducer"

/**
 *
 */
export function PermissionDeniedDialog(): React.JSX.Element {
  const { t } = useTranslation()
  const dispatch = useRootDispatch()

  const handleClose = (): void => {
    dispatch(clearPermissionDenied())
  }
  const permissionDenied = useRootSelector((state) =>
    Boolean(state.permissions.permissionDenied?.reason),
  )

  const titleText: string = t("permissionDeniedTitle", { ns: "errors" })
  return (
    <AlertDialog
      IconComponent="Warning"
      actionElement={<Button text={t("close")} onPress={handleClose} />}
      isOpen={permissionDenied}
      maxWidth={SIZES.$md}
      titleElement={titleText}
      onClose={handleClose}
    >
      <AlertDialogScrollView>
        <PermissionDenied />
      </AlertDialogScrollView>
    </AlertDialog>
  )
}
