import React from "react"
import { StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { COLORS, RADII, SIZES } from "./theme"

import type { ViewProps } from "react-native"
interface ProgressBarProps extends ViewProps {
  value: number // current value
  max?: number // maximum value
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: COLORS.$background.light,
    borderRadius: RADII.$default,
    height: SIZES.$2,
    width: "100%",
  },
})

/**
 * A component that displays a progress bar.
 */
export function Progress({ max = 100, style, value }: ProgressBarProps) {
  const width = (value / max) * 100
  const innerStyle = useStyle(() => {
    return {
      backgroundColor: COLORS.$primary[500],
      borderRadius: RADII.$default,
      flex: width,
    }
  }, [width])
  return (
    <View
      accessibilityRole="progressbar"
      style={useStyle(() => {
        return [styles.root, style]
      }, [style])}
    >
      <View style={innerStyle} />
    </View>
  )
}
