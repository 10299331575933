import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import {
  setFarmAnalyticsDateMsMax,
  setFarmAnalyticsDateMsMin,
} from "./async-storage"
import {
  AlertBodyText,
  AlertCard,
  Box,
  Button,
  Heading,
  ICON_SIZES,
  renderIconFromIconProp,
  Row,
  useIsDarkMode,
} from "./components"
import { ListItemTextPrimary } from "./components/ListItem"
import { Radio } from "./components/Radio"
import { COLORS } from "./components/theme"
import { FONT_SIZES, getFontName } from "./components/theme/fonts"
import {
  FlowAnalyticsSources,
  getFlowAnalyticsSourceFromState,
  isValidFlowAnalyticsSource,
  setFlowAnalyticsSource,
} from "./farm.reducer"
import {
  loadFlowMeteringAnalyticsAsync,
  loadFlowMeteringReportAsync,
} from "./farmhq-api"
import { isValidNumber } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"
import { useErrorHandler } from "./useErrorHandler"
import { getActiveFarmIdFromState } from "./user-farms.selectors"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"
import { dateInputToNumber } from "./utility"

import type { DomainTuple, ForAxes, VictoryLabelStyleObject } from "victory"
import type { ButtonProps, IconProp, NoChildren, RowProps } from "./components"
import type { FlowAnalyticsData } from "./farm.reducer"

import type { RootState } from "./root.reducer"

import type { DateChangeHandler, MinDateMaxDate } from "./types"

const ANALYTICS_GRADIENT = [
  COLORS.$blue,
  COLORS.$teal,
  COLORS.$yellow,
  COLORS.$darkBlue,
  COLORS.$green,
  COLORS.$indigo,
  COLORS.$emerald,
  COLORS.$lightBlue,
  COLORS.$cyan,
  COLORS.$lime,
]

const AXIS_LABEL_STYLE: VictoryLabelStyleObject = {
  fontFamily: getFontName("OpenSans_400Regular"),
  fontSize: 10,
}
export function useAxisLabelStyle(): VictoryLabelStyleObject {
  const isDark = useIsDarkMode()
  if (isDark) {
    return {
      ...AXIS_LABEL_STYLE,
      fill: COLORS.$textLight.primary,
    }
  }
  return AXIS_LABEL_STYLE
}
export const getDateValue = {
  max: (state: RootState) => state.farm.farmAnalytics.dateMsMax,
  min: (state: RootState) => state.farm.farmAnalytics.dateMsMin,
}
export function useDatepickerValues(): MinDateMaxDate {
  return {
    dateMsMax: useRootSelector(getDateValue.max),
    dateMsMin: useRootSelector(getDateValue.min),
  }
}

function useTexts() {
  return useTranslation("flowAnalytics")
}
/**
 *
 */
export function useDatePickers(): MinDateMaxDate & {
  onChangeDateMsMax: DateChangeHandler
  onChangeDateMsMin: DateChangeHandler
} {
  const dispatch = useRootDispatch()
  const handleError = useErrorHandler()
  const onChangeDateMsMax: DateChangeHandler = React.useCallback(
    (nextDate) => {
      const nextValue = dateInputToNumber(nextDate)
      if (isValidNumber(nextValue)) {
        dispatch(setFarmAnalyticsDateMsMax(nextValue)).catch(handleError)
      }
    },
    [dispatch, handleError],
  )
  const onChangeDateMsMin: DateChangeHandler = React.useCallback(
    (nextDate) => {
      const nextValue = dateInputToNumber(nextDate)
      if (isValidNumber(nextValue)) {
        dispatch(setFarmAnalyticsDateMsMin(nextValue)).catch(handleError)
      }
    },
    [dispatch, handleError],
  )
  return {
    ...useDatepickerValues(),
    onChangeDateMsMax,
    onChangeDateMsMin,
  }
}

export function FlowAnalyticsReportDownloadButton({
  handleReportDownloadResponse,
  ...props
}: NoChildren<ButtonProps> & {
  handleReportDownloadResponse: (
    reportTitle: string,
  ) => (response: { data: string }) => void
}): React.JSX.Element {
  const { t } = useTranslation("flowAnalytics")
  const { dateMsMax, dateMsMin } = useDatePickers()
  const reportTitle = `FarmHQ Flow Metering Report - ${dateMsMin}_${dateMsMax}.xlsx`

  const { handleError, isLoading, sendRequest } = useBackendRequest(
    loadFlowMeteringReportAsync,
  )

  const handlePress = () => {
    sendRequest({
      maxDateMs: dateMsMax,
      minDateMs: dateMsMin,
    })
      .then(handleReportDownloadResponse(reportTitle))
      .catch(handleError)
  }

  return (
    <Button
      {...props}
      IconComponent="Download"
      disabled={isLoading}
      text={t("downloadWaterUsageReportButton")}
      onPress={handlePress}
    />
  )
}

export function useLoadFarmAnalytics() {
  const source = useRootSelector(getFlowAnalyticsSourceFromState)
  const activeFarmId = useRootSelector(getActiveFarmIdFromState)
  const { dateMsMax, dateMsMin } = useDatepickerValues()

  const { handleError, isLoading, sendRequest } = useBackendRequest(
    loadFlowMeteringAnalyticsAsync,
  )

  const flowAnalytics: FlowAnalyticsData | null = useShallowEqualSelector(
    (state) => state.farm.farmAnalytics.flowAnalytics,
  )

  const handleFetch = React.useCallback(() => {
    // Need to re-fetch when active farm changes
    if (isValidNumber(activeFarmId)) {
      sendRequest({
        dateMsMax,
        dateMsMin,
        source,
      }).catch(handleError)
    }
  }, [activeFarmId, dateMsMax, dateMsMin, handleError, sendRequest, source])
  return {
    flowAnalytics,
    handleFetch,
    isLoading,
  }
}
export interface FlowAnalyticsProps extends MinDateMaxDate {
  flowAnalytics: FlowAnalyticsData
}

export interface FlowSummaryBoxProps {
  title: string
  unitLabel: string
  value: number | string
  labelSecondary?: string
}

export function useFlowSummaryBoxes({
  flowAnalytics: {
    deviceTotals: [highestPeakFlowDevice],
    farmTotals,
  },
}: FlowAnalyticsProps): {
  [key in
    | "averageDailyUsage"
    | "farmWideWaterUsage"
    | "highestFlowRate"
    | "peakDailyUsage"]: FlowSummaryBoxProps
} {
  const { t } = useTranslation("flowAnalytics")
  const notApplicable = t("notApplicable", { ns: "common" })

  const farmTotal = farmTotals.farmTotalAcreFeet.toFixed(2)

  const dailyAvg = (
    (farmTotals.farmTotalAcreFeet / farmTotals.dailyCumsumAcreFeet.length) *
    12
  ).toFixed(1)

  const highestPeakFlowDeviceName =
    highestPeakFlowDevice?.deviceName ?? t("none", { ns: "common" })

  const highestPeakFlow =
    highestPeakFlowDevice?.devicePeakGpm.toFixed(1) ?? notApplicable

  const highestPeakFarmFlow = Math.max(
    ...farmTotals.dailyVolumeTotalAcreFeet.map((d) => d.y),
  )
  const highestPeakFarmFlowData = farmTotals.dailyVolumeTotalAcreFeet.filter(
    (d) => d.y === highestPeakFarmFlow,
  )
  const [datum] = highestPeakFarmFlowData

  const peakFlowCardData = datum
    ? {
        date: new Date(datum.x),
        flow: (datum.y * 12).toFixed(1),
      }
    : { date: notApplicable, flow: notApplicable }

  const averageDailyUsage: FlowSummaryBoxProps = {
    title: t("averageDailyUsage.title"),
    unitLabel: t("averageDailyUsage.unitLabel"),
    value: dailyAvg,
  }
  const farmWideWaterUsage: FlowSummaryBoxProps = {
    title: t("farmWideWaterUsage.title"),
    unitLabel: t("farmWideWaterUsage.unitLabel"),
    value: farmTotal,
  }
  const highestFlowRate: FlowSummaryBoxProps = {
    labelSecondary: t("highestFlowRate.pumpWithDeviceName", {
      deviceName: highestPeakFlowDeviceName,
    }),
    title: t("highestFlowRate.title"),
    unitLabel: t("highestFlowRate.unitLabel"),
    value: highestPeakFlow,
  }

  const peakDailyUsage: FlowSummaryBoxProps = {
    labelSecondary:
      peakFlowCardData.date instanceof Date
        ? t("peakDailyUsage.onWithDate", {
            date: peakFlowCardData.date,
          })
        : notApplicable,
    title: t("peakDailyUsage.title"),
    unitLabel: t("peakDailyUsage.unitLabel"),
    value: peakFlowCardData.flow,
  }

  return {
    averageDailyUsage,
    farmWideWaterUsage,
    highestFlowRate,
    peakDailyUsage,
  }
}

export interface GraphTitleProps {
  IconComponent: IconProp
  text: string
}
export const graphStyles = {
  bar: {
    animate: { duration: 500 },
    labels: { fontSize: 36 },
  },
  colorGradient: ANALYTICS_GRADIENT.map((color) => color[400]),
  dateTickFormat: (t: number) => {
    return new Date(t).toISOString().substring(0, 10)
  },
}

/**
 * Shows water usage over time for farm
 */
export function useUsageOverTimeGraph({
  dateMsMax,
  dateMsMin,
  flowAnalytics: { farmTotals },
}: FlowAnalyticsProps) {
  const { t } = useTranslation("flowAnalytics")
  const title: GraphTitleProps = {
    IconComponent: "TimeTable",
    text: t("flowTimeseries.title"),
  }

  const domainX: ForAxes<DomainTuple> = [dateMsMin, dateMsMax]
  const domainY: ForAxes<DomainTuple> = [
    0,
    Math.max(...farmTotals.dailyVolumeTotalAcreFeet.map((d) => d.y)) * 1.2,
  ]
  return {
    axes: {
      x: {
        domain: domainX,
        tickCount: 6,
        tickFormat: graphStyles.dateTickFormat,
      },
      y: {
        domain: domainY,
        label: t("flowTimeseries.axisLabels.y"),
      },
    },
    bar: {
      animate: graphStyles.bar.animate,
      data: farmTotals.dailyVolumeTotalAcreFeet,
      name: "Farm Daily",
      style: {
        data: {
          fill: COLORS.$green[300],
        },
      },
    },
    title,
  }
}

const COLOR_SCALES = {
  totalVolumeOverTime: ANALYTICS_GRADIENT.map((color) => color[500]),
}
/**
 * Shows total volume over time by pump/stacked
 */

export function useTotalVolumeOverTimeGraph({
  dateMsMax,
  dateMsMin,
  flowAnalytics: { deviceTotals, farmTotals },
}: FlowAnalyticsProps) {
  const { t } = useTexts()
  const title: GraphTitleProps = {
    IconComponent: "Timeline",
    text: t("pumpFlowStackedTimeseries.title"),
  }

  const domainX: ForAxes<DomainTuple> = [dateMsMin, dateMsMax]
  let domainYMax = farmTotals.farmTotalAcreFeet
  if (domainYMax < 1) {
    domainYMax = 1
  }
  const domainY: ForAxes<DomainTuple> = [0, domainYMax]

  return {
    areaStackItems: deviceTotals.map((deviceTotal) => {
      return {
        animate: graphStyles.bar.animate,
        colorScale: COLOR_SCALES.totalVolumeOverTime,
        data: deviceTotal.dailyCumsumAcreFeet,
        key: deviceTotal.deviceName,
        labels: [deviceTotal.deviceName],
        name: deviceTotal.deviceName,
      } as const
    }),
    axes: {
      x: {
        domain: domainX,
        tickCount: 6,
        tickFormat: graphStyles.dateTickFormat,
      },
      y: {
        domain: domainY,
        label: t("pumpFlowStackedTimeseries.axisLabels.y"),
      },
    },
    title,
  }
}
/**
 * Logic for horizontal bar graph showing total water volume by pump
 */
export function useTotalVolumeByPumpGraph({
  flowAnalytics: { deviceTotals },
}: FlowAnalyticsProps) {
  const { t } = useTexts()
  const getBarFillColor = ({ datum }: { datum?: unknown }) => {
    return (datum as { fill?: string | undefined } | undefined)?.fill ?? "black"
  }
  const domainY: ForAxes<DomainTuple> = [0.5, deviceTotals.length + 0.5]
  let domainXMax = Math.max(
    ...deviceTotals.map((d) => d.deviceVolumeTotalAcreFeet * 1.6),
  )
  if (domainXMax < 1) {
    domainXMax = 1
  }
  const domainX: ForAxes<DomainTuple> = [0, domainXMax]
  const title: GraphTitleProps = {
    IconComponent: "PumpOn",
    text: t("pumpTotalHorizontalBar.title"),
  }

  return {
    axes: {
      x: {
        domain: domainX,
        label: t("pumpTotalHorizontalBar.axisLabels.x"),
      },
      y: {
        domain: domainY,
      },
    },
    bar: {
      animate: graphStyles.bar.animate,
      colorScale: graphStyles.colorGradient,
      data: deviceTotals.map((device, i) => {
        return {
          fill: graphStyles.colorGradient[i],
          label: `${device.deviceVolumeTotalAcreFeet.toFixed(2)} Ac. Ft.`,
          x: device.deviceName,
          y: device.deviceVolumeTotalAcreFeet,
        }
      }),
      style: {
        data: {
          fill: getBarFillColor,
        },
        labels: {
          fontFamily: AXIS_LABEL_STYLE.fontFamily,
        },
      },
    },
    title,
  }
}

/**
 * Flow summary table headings and title
 */
export function useFlowSummaryTable() {
  const { t } = useTexts()

  const title: GraphTitleProps = {
    IconComponent: "Water",
    text: t("flowSummaryTable.title"),
  }
  return {
    headings: {
      peakFlowRate: t("flowSummaryTable.headings.peakFlowRate"),
      pump: t("deviceInstallationTypes.pump", { ns: "common" }),
      totalVolume: t("flowSummaryTable.headings.totalVolumeAcreFeet"),
    },
    title,
  }
}
/**
 * Header for each graph/table
 */
export function GraphTitle({
  IconComponent,
  text,
  ...props
}: GraphTitleProps & RowProps) {
  return (
    <Row alignItems="center" {...props}>
      <Box mr="$2">
        {renderIconFromIconProp(IconComponent, { size: ICON_SIZES.$md })}
      </Box>
      <Heading colorScheme="secondary" style={{ flex: 1 }} variant="h5">
        {text}
      </Heading>
    </Row>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontFamily: getFontName("OpenSans_700Bold"),
    fontSize: FONT_SIZES.$sm,
  },
  valueHeading: {
    marginLeft: "auto",
    // TODO: Figure out RotateY
    // transform: [{ rotate: "90deg" }],
    textAlign: "right",
  },
})

/**
 *
 */
export function FlowSummaryTable({
  flowAnalytics: { deviceTotals },
}: FlowAnalyticsProps): React.JSX.Element {
  const { headings, title } = useFlowSummaryTable()

  const valueHeadingStyle = useStyle(() => {
    return [styles.heading, styles.valueHeading]
  }, [])
  return (
    <React.Fragment>
      <GraphTitle {...title} />
      <Row mb="$1" mt="$2">
        {/* Heading for device name */}
        <Box flex={1}>
          <Heading style={styles.heading}>{headings.pump}</Heading>
        </Box>
        <Box flex={1}>
          {/* Heading for deviceVolumeTotalAcreFeet */}
          <Heading style={valueHeadingStyle}>{headings.totalVolume}</Heading>
        </Box>
        <Box flex={1}>
          {/* Heading for devicePeakGpm */}
          <Heading style={valueHeadingStyle}>{headings.peakFlowRate}</Heading>
        </Box>
      </Row>
      {deviceTotals.map(
        ({ deviceName, devicePeakGpm, deviceVolumeTotalAcreFeet }) => {
          return (
            <Row
              key={deviceName}
              flexWrap="nowrap"
              py="$0.5"
              w="$full"
              // {...(showDivider ? LIST_DIVIDER_PROPS : undefined)}
            >
              <ListItemTextPrimary isTruncated flex={1}>
                {deviceName ?? "Unknown Device"}
              </ListItemTextPrimary>
              <Box display="flex" flex={1}>
                <Box ml="auto">
                  <ListItemTextPrimary>
                    {deviceVolumeTotalAcreFeet.toFixed(2)}
                  </ListItemTextPrimary>
                </Box>
              </Box>
              <Box display="flex" flex={1}>
                <Box ml="auto">
                  <ListItemTextPrimary>
                    {devicePeakGpm.toFixed(1)}
                  </ListItemTextPrimary>
                </Box>
              </Box>
            </Row>
          )
        },
      )}
    </React.Fragment>
  )
}

export function FlowSourceToggle(props: {
  orientation?: "horizontal" | "vertical"
}) {
  const value = useRootSelector(getFlowAnalyticsSourceFromState)
  const dispatch = useRootDispatch()
  return (
    <Radio
      orientation={props.orientation}
      selectedValue={value}
      options={[
        {
          label: "Actual",
          value: FlowAnalyticsSources.METERED,
        },
        {
          label: "Estimated",
          value: FlowAnalyticsSources.ESTIMATED,
        },
      ]}
      onChange={(nextValue) => {
        if (!isValidFlowAnalyticsSource(nextValue)) {
          throw new TypeError(`Invalid flow analytics source: ${nextValue}`)
        }
        dispatch(setFlowAnalyticsSource(nextValue))
      }}
    />
  )
}

export function ShowingEstimatesWarning() {
  const source = useRootSelector(getFlowAnalyticsSourceFromState)
  const { t } = useTranslation("flowAnalytics")

  if (source === "estimated") {
    return (
      <AlertCard
        IconComponent="Info"
        maxW="$lg"
        titleText={t("estimatesWarning.title")}
      >
        <AlertBodyText fontSize="$xs">
          {t("estimatesWarning.message")}
        </AlertBodyText>
      </AlertCard>
    )
  }
  return null
}
