import React from "react"
import { useTranslation } from "react-i18next"

import { AppText, Badge, Box, useIsDarkMode } from "./components"
import { AppIcons } from "./components/icons"
import { Row } from "./components/Row"
import { COLORS } from "./components/theme"
import { ProgressBar } from "./RunProgressBar"

import type { BadgeProps } from "./components"
import type { SensorEvent } from "./sensor-events"
export type VfdIndicatorProps = Pick<
  SensorEvent<"vfd">,
  | "currentPercentage"
  | "pidSensorSetpoint"
  | "stateCurrent"
  | "targetRampPercentage"
>

/**
 * Display VFD
 */
export function VfdIndicatorLarge({
  currentPercentage: percentCurrent,
  pidSensorSetpoint,
  stateCurrent,
  targetRampPercentage: percentTarget,
}: VfdIndicatorProps) {
  const { t } = useTranslation("devices")

  let fill = 0

  let statusElement: JSX.Element = (
    <Badge StartIconComponent="Timeline">{t("vfd.off")}</Badge>
  )

  switch (stateCurrent) {
    case null:
    case undefined:
    case "NONE":
    case "VFDOF": {
      break
    }
    case "VFDOV":
    case "VFDUN":
    case "VFDPIDT":
    case "VFDST": {
      let stateText = t("vfd.on")
      let badgeColorScheme: BadgeProps["colorScheme"] = "$green"
      if (stateCurrent === "VFDOV") {
        stateText = t("vfd.over")
        badgeColorScheme = "$warning"
      } else if (stateCurrent === "VFDUN") {
        stateText = t("vfd.under")
        badgeColorScheme = "$warning"
      }
      statusElement = (
        <Badge
          StartIconComponent="Timeline"
          colorScheme={badgeColorScheme}
          fontSize="$sm"
        >
          {stateText}
        </Badge>
      )

      if (typeof percentCurrent === "number") {
        fill = percentCurrent
      }
      break
    }

    case "VFDRP": {
      const isTargetValid = typeof percentTarget === "number"
      if (isTargetValid) {
        fill = percentTarget
      }
      const isCurrentValid = typeof percentCurrent === "number"
      if (isCurrentValid && isTargetValid) {
        let IconComponent = AppIcons.ArrowUp
        if (percentTarget < percentCurrent) {
          IconComponent = AppIcons.ArrowDown
        }
        statusElement = (
          <Badge
            EndIconComponent={IconComponent}
            StartIconComponent="Timeline"
            colorScheme="$green"
          >
            {t("vfd.ramping")}
          </Badge>
        )
      }
      break
    }
  }

  // /**
  //  * Create "pulsing" effect when ramping by changing the key
  //  * on a cadence.
  //  *
  //  * Hopefully this doesn't mess up performance
  //  */
  // React.useEffect(() => {
  //   let interval: NodeJS.Timer
  //   if (stateCurrent === "VFDRP") {
  //     interval = setInterval(() => {
  //       setKey((current) => (current === 1 ? 0 : 1))
  //     }, PULSE_INTERVAL_MS)
  //   }
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [stateCurrent])
  const isDark = useIsDarkMode()

  const trackingText =
    (stateCurrent === "VFDPIDT" ||
      stateCurrent === "VFDOV" ||
      stateCurrent === "VFDUN") &&
    typeof pidSensorSetpoint === "number" ? (
      <AppText fontSize="$sm">
        {t("vfd.trackingWithValue", {
          value: pidSensorSetpoint,
        })}
      </AppText>
    ) : null
  return (
    <Box
      bg={isDark ? COLORS.$gray[600] : COLORS.$gray[100]}
      mb="$2"
      px="$2"
      py="$2"
      rounded="$default"
      w="$64"
      style={{
        borderColor: isDark ? COLORS.$gray[700] : COLORS.$gray[300],
        borderRadius: 5,
        borderWidth: 2,
      }}
    >
      <Row alignItems="center" justifyContent="space-between" pb="$2">
        {statusElement}
        {trackingText}
        {/* This is the badge indicating the state of the VFD */}
      </Row>
      <ProgressBar
        barFillColor={COLORS.$green[300]}
        fillPercentage={fill}
        textLeft={`${percentCurrent ?? 0}%`}
      />
    </Box>
  )
}
