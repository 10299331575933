export const Z_INDEX = {
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "appBar": 21,
  "border": 100,
  "bottomSheet": 2,
  "datePicker": 999,
  "drawer": 22,
  "fab": 20,
  "graph": -1,
  "heatMapBase": 10,
  "heatMapShade": 60,
  "mapButton": 1,
  "mapView": 0,
} as const
