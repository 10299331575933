import React from "react"

import { ListItemTextPrimary } from "./components/ListItem"

import type { AcceptsChildren, NoChildren } from "./components"
import type { DeviceStatusData } from "./device-event-last.reducer"

import type { ListItemTextProps } from "./components/ListItem"
interface ExtraValues {
  showDeviceIds: boolean
}

export type ContextValue = DeviceStatusData & Partial<ExtraValues>

const Context = React.createContext<ContextValue | undefined>(undefined)

/**
 *
 */
export function Provider({
  children,
  ...value
}: AcceptsChildren & ContextValue): React.JSX.Element | null {
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext() {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(
      `[DeviceSummaryContext]: must be used inside of provider`,
    )
  }
  return ctx
}

export function DeviceNameText(
  props: NoChildren<ListItemTextProps>,
): React.JSX.Element {
  const { deviceName } = useContext()
  return (
    <ListItemTextPrimary
      isTruncated
      fontFamily="OpenSans_700Bold"
      textTransform="none"
      {...props}
    >
      {deviceName}
    </ListItemTextPrimary>
  )
}
