import React from "react"
import { FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import * as Auth from "./Auth"
import { signInAsync, useAuthFormHelpers } from "./auth.reducer"
import {
  AlertDialog,
  AlertDialogBodyText,
  AlertDialogScrollView,
  Box,
  Button,
  Spinner,
  SubmitButton,
} from "./components"
import { FormError } from "./components/FormError"
import { useIsPending } from "./selectors"
import { useRootDispatch } from "./useRootDispatch"

import type { AuthFormData } from "./auth.reducer"
import type { AuthErrorCode } from "./errors"

interface Props {
  onPressConfirmation: (values: { email: string }) => void
  onPressContactUs: () => void
  onPressForgotPassword: () => void
  onPressSignUp: () => void
  defaultValues?: Partial<AuthFormData>
  onSuccess?: () => void
}

export function SignInForm({
  defaultValues,
  onPressConfirmation,
  onPressContactUs,
  onPressForgotPassword,
  onPressSignUp,
  onSuccess,
}: Props) {
  const { t } = useTranslation("auth")
  const dispatch = useRootDispatch()

  // const isLoading = useIsPending("signIn")

  const isDisabled = useIsPending("signIn")

  const [unconfirmedEmail, setUnconfirmedEmail] = React.useState<string>()

  const { form, formErrorMessage, handleError } = useAuthFormHelpers({
    defaultValues: __DEV__ ? defaultValues : undefined,
  })

  const onSubmit = form.handleSubmit((values) => {
    dispatch(signInAsync(values))
      .unwrap()
      .then(onSuccess)
      .catch((error) => {
        if (typeof error === "object") {
          const e = error as
            | { code?: AuthErrorCode; name?: AuthErrorCode }
            | undefined
          const code = e?.name ?? e?.code
          if (code === "UserNotConfirmedException") {
            // This triggers the 'account not confirmed' dialog
            return setUnconfirmedEmail(values.email)
          }
        }
        return handleError(error)
      })
  })
  return (
    <FormProvider {...form}>
      <AlertDialog
        isOpen={typeof unconfirmedEmail === "string"}
        titleElement={t("accountNotConfirmed")}
        actionElement={
          <Button
            IconComponent="ArrowRight"
            iconPosition="right"
            id="confirm-sign-up-link"
            size="lg"
            text={t("confirmAccountButton")}
            variant="primary"
            onPress={() => {
              if (typeof unconfirmedEmail === "undefined") {
                throw new TypeError(`Unconfirmed email is undefined`)
              }
              onPressConfirmation({ email: unconfirmedEmail })
            }}
          />
        }
        onClose={() => setUnconfirmedEmail(undefined)}
      >
        <AlertDialogScrollView>
          <AlertDialogBodyText>
            {t("youMustConfirmYourAccount")}
          </AlertDialogBodyText>
        </AlertDialogScrollView>
      </AlertDialog>
      <View>
        <Auth.EmailField isDisabled={isDisabled} mb="$4" />
        <Auth.CurrentPasswordField isDisabled={isDisabled} mb="$4" />
        <Box my="$4">
          <SubmitButton isDisabled={isDisabled} size="xl" onPress={onSubmit} />
        </Box>
        <FormError>{formErrorMessage}</FormError>
        {isDisabled ? (
          <Box my="$2">
            <Spinner />
          </Box>
        ) : null}
        <Box mt="$4">
          <Auth.AuthLink
            id="password-reset-link"
            isDisabled={isDisabled}
            text={t("passwordResetLinkTo")}
            onPress={onPressForgotPassword}
          />
          <Auth.AuthLink
            id="sign-up-link"
            isDisabled={isDisabled}
            text={t("createAnAccount")}
            onPress={onPressSignUp}
          />
          <Auth.AuthLink
            id="contact-us-link"
            isDisabled={isDisabled}
            text={t("contactUs")}
            onPress={() => {
              onPressContactUs()
            }}
          />
        </Box>
      </View>
    </FormProvider>
  )
}
