import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import { createSelector } from "@reduxjs/toolkit"

import {
  Box,
  renderIconFromIconProp,
  Row,
  useBreakpointValue,
} from "./components"
import { ListNumber } from "./components/ListNumber"
import { AppText } from "./components/Text"
import { COLORS } from "./components/theme"
import { selectAllDeviceFunctionCalls } from "./device-profile.reducer"
import { NoListItems } from "./NoListItems"
import { isTruthyString, isValidNumber } from "./type-guards"
import { formatDateSafely } from "./utility"

import type { AppIconProps, IconProp, BoxProps, IconKey } from "./components"
import type { AppTextProps } from "./components/Text"
import type { SortDirectionKey } from "./Sorting"

import type { DeviceFunctionCall } from "./models"
import type { RootState } from "./root.reducer"
export function keyExtractor(item: DeviceFunctionCall): string {
  return `invocation.id=${item.id}/${item.requestSentTimestamp ?? "none"}`
}

function useTexts() {
  return useTranslation("deviceActions")
}

export const getRows = createSelector(
  selectAllDeviceFunctionCalls,
  (_state: RootState, deviceId: string | undefined) => deviceId,
  (
    _state: RootState,
    _deviceId: string | undefined,
    sortKey: SortDirectionKey,
  ) => sortKey,
  (invocations, deviceId, sortKey: SortDirectionKey) =>
    invocations
      .filter((value) => value.deviceId === deviceId)
      .map((item, index) => {
        let triggeredByText: string

        if (isTruthyString(item.email)) {
          if (item.email.endsWith("@codafarmtech.com")) {
            triggeredByText = i18next.t("adminUser", { ns: "deviceActions" })
          } else {
            triggeredByText = item.email
          }
        } else if (isValidNumber(item.initiatedByTriggerId)) {
          triggeredByText = "Automation"
        } else {
          triggeredByText = i18next.t("system", { ns: "deviceActions" })
        }
        let statusIconName: IconKey
        let statusIconColor: string

        if (item.httpStatusCode === 200) {
          statusIconName = "Success"
          statusIconColor = COLORS.$success[500]
        } else {
          statusIconName = "Error"
          statusIconColor = COLORS.$error[200]
        }
        const itemCount = invocations.length

        let functionNameText: string = item.functionName ?? "unknown action"

        if (
          item.namedDeviceAction &&
          typeof item.namedDeviceAction.displayName === "string"
        ) {
          functionNameText = item.namedDeviceAction.displayName
        }

        return {
          ...item,
          functionNameText,
          index,
          requestSentText: isTruthyString(item.requestSentTimestamp)
            ? formatDateSafely(new Date(item.requestSentTimestamp), "P p")
            : i18next.t("unknown", { ns: "common" }),
          showDivider: index < itemCount - 1,
          sortKey,
          statusIconColor,
          statusIconName,
          triggeredByText,
        }
      }),
)

const FONT_SIZE: AppTextProps["fontSize"] = "$sm"

/**
 * Row of text for device commands list item
 */
function TextRow({
  IconComponent,
  iconColor,
  text,
  textTransform,
  title,
}: Pick<AppTextProps, "textTransform"> & {
  text: string | null | undefined
  title: string
  IconComponent?: IconProp
  iconColor?: AppIconProps["color"]
}): React.JSX.Element | null {
  if (isTruthyString(text)) {
    const iconElement = renderIconFromIconProp(IconComponent, {
      color: iconColor,
    })
    return (
      <Row display="flex" flexWrap="wrap" w="$full">
        <Box mr="$2">
          <AppText fontSize={FONT_SIZE} textTransform={textTransform}>
            {`${title}:`}
          </AppText>
        </Box>
        <Row alignItems="center">
          {iconElement ? <Box mr="$2">{iconElement}</Box> : null}
          <AppText
            isTruncated
            fontSize={FONT_SIZE}
            textTransform={textTransform}
          >
            {text}
          </AppText>
        </Row>
      </Row>
    )
  }
  return null
}

export function ListEmptyComponent(): React.JSX.Element {
  const { t } = useTexts()
  return (
    <NoListItems
      message={t("deviceActionHistoryListEmpty")}
      severity="info"
      style={{ marginTop: Platform.select({ ios: "25%" }) }}
    />
  )
}
type ItemType = ReturnType<typeof getRows>[number]

/**
 * Device command history list item
 */
export function RenderItem({
  index,
  // TODO: Divider
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showDivider = false,
  ...item
}: ItemType & {
  index: number
  showDivider?: boolean
}): React.JSX.Element {
  const { t } = useTexts()

  const getValue = useBreakpointValue()
  const rowWidth = getValue<BoxProps["w"]>({
    base: "$full",
    sm: "$1/3",
  })
  return (
    <Row alignItems="center" py="$4">
      <ListNumber value={index} />
      <Row flex={1} ml="$2" mx="$1" w={rowWidth}>
        <TextRow text={item.requestSentText} title={t("time")} />
        <TextRow text={item.functionNameText} title={t("command")} />
      </Row>
      <Row mx="$1" w={rowWidth}>
        <Box mr="$2">
          <TextRow text={item.triggeredByText} title={t("triggeredBy")} />
        </Box>
        <TextRow
          IconComponent={item.statusIconName}
          iconColor={item.statusIconColor}
          text={item.statusIconName}
          textTransform="capitalize"
          title={t("result")}
        />
      </Row>
    </Row>
  )
}
