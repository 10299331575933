import { denyPermission } from "@fhq/app"
import { makePermissionCheckHook } from "@fhq/app/usePermissionCheckedAction"

export const usePermissionCheckedAction = makePermissionCheckHook({
  onDenied: ({ dispatch, missingPermissions }) => {
    return dispatch(
      denyPermission({
        missingPermissions,
        reason: "missing permissions",
      }),
    )
  },
})
