import React from "react"
import { useTranslation } from "react-i18next"

import {
  AlertBodyText,
  AlertCard,
  AlertDialogBodyText,
  AppIcons,
  Box,
  Row,
} from "./components"
import { COLORS } from "./components/theme"
import { useRootSelector } from "./useRootSelector"

import type { BoxProps } from "./components"
/**
 *
 */
export function PermissionDenied({
  ...props
}: BoxProps): React.JSX.Element | null {
  const { t } = useTranslation("errors")
  const current = useRootSelector((state) => state.permissions.permissionDenied)
  let details: React.JSX.Element | null = null
  let message: string | undefined
  let instructions: string | undefined
  if (current?.reason === "REQUEST_BLOCKED_IN_DEMO") {
    message = t("permissionDenied", { ns: "farmUserPermissions" })
  } else {
    message = t("permissionDeniedMessage", { ns: "errors" })
    instructions = t("permissionDeniedHowToGetPermissionHint")
    // Indicate to user that certain permissions are missing
    details = current?.missing ? (
      <Box my="$2">
        <Box mb="$2">
          <AlertBodyText>
            {t("permissionDeniedMissingPermissions")}
          </AlertBodyText>
        </Box>
        {current.missing.map((key): React.JSX.Element => {
          return (
            <Row key={key}>
              <AppIcons.Dot color={COLORS.$textDark.primary} />
              <Box>
                <AlertBodyText>
                  {t(`${key}.displayName`, { ns: "farmUserPermissions" })}
                </AlertBodyText>
              </Box>
            </Row>
          )
        })}
      </Box>
    ) : null
  }

  return (
    <Box {...props}>
      <AlertCard IconComponent={null} severity="warning">
        <AlertBodyText flex={1} fontSize="$md">
          {message}
        </AlertBodyText>
        {details}
      </AlertCard>
      <Box mt="$4">
        <AlertDialogBodyText>{instructions}</AlertDialogBodyText>
      </Box>
    </Box>
  )
}
