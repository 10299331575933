import React from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator, Pressable, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { AppIcons, renderIconFromIconProp } from "./icons/AppIcons"
import { testIds } from "./test-id"
import { AppText } from "./Text"
import { FONT_SIZES } from "./theme"
import { COLORS } from "./theme/colors"
import { RADII } from "./theme/radii"
import { SPACING } from "./theme/spacing"
import { useColorMode, useIsDarkMode } from "./ThemeProvider"

import type { SetOptional } from "type-fest"
import type { AcceptsChildren, NoChildren } from "./types"
import type { TestId } from "./test-id"
import type { AppTextProps } from "./Text"

import type { PressableProps, ViewStyle } from "react-native"
import type { IconProp } from "./icons/AppIcons"
export interface PressableState {
  pressed: boolean
  focused?: boolean
  hovered?: boolean
}

export type ButtonVariantName =
  | "danger"
  | "outline"
  | "primary"
  | "subtle"
  | "text"

interface ButtonBaseProps extends NoChildren<PressableProps> {
  bg?: string
  id?: TestId
  isDisabled?: boolean
  isLoading?: boolean
  size?: "lg" | "md" | "sm" | "xl"
  variant?: ButtonVariantName
  w?: ViewStyle["width"]
}
export interface ButtonProps extends ButtonBaseProps {
  IconComponent?: IconProp
  LeftIconComponent?: IconProp
  RightIconComponent?: IconProp
  _text?: AppTextProps
  flex?: number
  flexGrow?: number
  iconPosition?: "left" | "right"
  text?: string
}

/**
 * Adjust font color to match text
 */
function useTextColor({
  isDisabled,
  variant,
}: Pick<ButtonBaseProps, "isDisabled" | "variant">) {
  const isDark = useIsDarkMode()
  let textColors: { primary: string; secondary: string } = COLORS.$textDark
  if (variant === "text" || variant === "outline") {
    textColors = {
      primary: COLORS.$text[600],
      secondary: COLORS.$gray[500],
    }
    if (isDark) {
      textColors = COLORS.$textLight
    }
  }
  if (variant === "danger") {
    textColors = {
      primary: COLORS.$warning[700],
      secondary: COLORS.$warning[500],
    }
    if (isDark) {
      textColors = {
        primary: COLORS.$warning[600],
        secondary: COLORS.$warning[400],
      }
    }
  }

  /**
   * Determine font color based on state
   */

  if (isDisabled === true) {
    return textColors.secondary
  }
  return textColors.primary
}

function useButtonStyle({
  bg,
  isDisabled = false,
  variant = "subtle",
}: ButtonBaseProps) {
  const mode = useColorMode()
  const isDark = mode === "dark"
  return (state: unknown) => {
    const { hovered = false, pressed } = state as PressableState

    let backgroundColor: string | undefined
    let borderWidth = 1
    let borderColor: string | undefined = COLORS.$divider[mode]

    switch (variant) {
      case "danger": {
        borderWidth = 1
        borderColor = isDark ? COLORS.$error[500] : COLORS.$error[700]
        if (pressed) {
          backgroundColor = isDark ? COLORS.$error[300] : COLORS.$error[400]
        } else if (hovered) {
          backgroundColor = isDark ? COLORS.$error[200] : COLORS.$error[300]
        }
        break
      }
      case "primary": {
        borderWidth = 0
        backgroundColor = COLORS.$primary[500]
        if (pressed) {
          backgroundColor = COLORS.$primary[800]
        } else if (hovered) {
          backgroundColor = COLORS.$primary[700]
        } else if (isDisabled) {
          backgroundColor = COLORS.$gray[300]
        }

        break
      }
      case "text": {
        borderWidth = 0
        backgroundColor = undefined
        borderColor = undefined

        if (pressed) {
          backgroundColor = COLORS.$gray[300]
        } else if (hovered) {
          backgroundColor = COLORS.$gray[200]
        }
        if (isDark) {
          if (pressed) {
            backgroundColor = COLORS.$gray[800]
          } else if (hovered) {
            backgroundColor = COLORS.$gray[700]
          }
        }

        break
      }
      case "outline": {
        if (isDark) {
          backgroundColor = undefined
          if (pressed) {
            backgroundColor = COLORS.$gray[700]
          } else if (hovered) {
            backgroundColor = COLORS.$gray[600]
          }
        } else {
          backgroundColor = undefined
          if (pressed) {
            backgroundColor = COLORS.$gray[200]
          } else if (hovered) {
            backgroundColor = COLORS.$gray[300]
          }
        }

        break
      }
      case "subtle": {
        borderWidth = 0
        if (isDark) {
          backgroundColor = COLORS.$gray[400]
          if (pressed) {
            backgroundColor = COLORS.$gray[200]
          } else if (hovered) {
            backgroundColor = COLORS.$gray[300]
          }
        } else {
          backgroundColor = COLORS.$gray[300]
          if (pressed) {
            backgroundColor = COLORS.$gray[500]
          } else if (hovered) {
            backgroundColor = COLORS.$gray[400]
          }
        }
        break
      }
    }

    return {
      backgroundColor: bg ?? backgroundColor,
      borderColor,
      borderWidth,
    }
  }
}

function IconContainer(props: AcceptsChildren) {
  const iconContainerSize = 28
  return (
    <View
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: iconContainerSize,
      }}
      {...props}
    />
  )
}
/**
 *
 */
export function Button(props: ButtonProps & NoChildren<PressableProps>) {
  const {
    _text,
    flex,
    flexGrow,
    iconPosition = "left",
    id,
    isDisabled = false,
    isLoading = false,
    size = "md",
    style,
    text,
    ...rest
  } = props
  const getStyle = useButtonStyle(props)
  const fontColor = useTextColor(props)

  /**
   * Get IconComponent from name or directly from props
   */
  let StartIconComponent = props.IconComponent
  if (typeof StartIconComponent === "string") {
    StartIconComponent = AppIcons[StartIconComponent]
  }
  let LeftIconComponent = props.LeftIconComponent
  if (typeof props.LeftIconComponent === "string") {
    LeftIconComponent = AppIcons[props.LeftIconComponent]
  }

  let RightIconComponent = props.RightIconComponent
  if (typeof props.RightIconComponent === "string") {
    RightIconComponent = AppIcons[props.RightIconComponent]
  }
  const iconStyle = useStyle(
    () => ({
      color: fontColor,
      fontWeight: "800",
    }),
    [fontColor],
  )

  let fontSize: number = FONT_SIZES.$md
  let height: number
  let iconSize = 16
  switch (size) {
    case "xl": {
      fontSize = 20
      height = 48
      iconSize = 20
      break
    }
    case "lg": {
      fontSize = 18
      height = 40
      iconSize = 18
      break
    }
    case "md": {
      fontSize = 16
      height = 32
      iconSize = 14
      break
    }
    case "sm": {
      fontSize = 12
      height = 24
      iconSize = 14
      break
    }
  }
  const iconElement = isLoading ? (
    <ActivityIndicator color={COLORS.$text[500]} />
  ) : (
    renderIconFromIconProp(StartIconComponent, {
      size: iconSize,
      style: iconStyle,
    })
  )

  return (
    <Pressable
      accessible
      accessibilityRole="button"
      accessibilityState={{ disabled: isDisabled }}
      aria-disabled={isDisabled}
      disabled={isDisabled}
      focusable={!isDisabled}
      style={(state) => {
        return [
          {
            alignItems: "center",
            borderRadius: 5,
            display: "flex",
            flex,
            flexDirection: "row",
            flexGrow,
            justifyContent: "center",
            paddingHorizontal: SPACING.$1,
            ...getStyle(state),
            height,
          },
          typeof style === "function" ? style(state as PressableState) : style,
        ]
      }}
      {...rest}
      {...testIds(id)}
    >
      <IconContainer>
        {(iconPosition === "left" ? iconElement : null) ??
          renderIconFromIconProp(LeftIconComponent, {
            size: iconSize,
            style: iconStyle,
          })}
      </IconContainer>
      <AppText
        aria-disabled={isDisabled}
        fontFamily="Poppins_600SemiBold"
        numberOfLines={1}
        style={useStyle(
          () => [
            {
              color: fontColor,
              fontSize,
              marginHorizontal: "auto",
              // paddingHorizontal: SPACING.$1,
              textTransform: "uppercase",
              userSelect: "none",
            },
            _text?.style,
          ],
          [_text?.style, fontColor, fontSize],
        )}
        {..._text}
      >
        {text}
      </AppText>
      <IconContainer>
        {(iconPosition === "right" ? iconElement : null) ??
          renderIconFromIconProp(RightIconComponent, {
            size: iconSize,
            style: iconStyle,
          })}
      </IconContainer>
    </Pressable>
  )
}

export interface IconButtonProps extends ButtonBaseProps {
  IconComponent: IconProp
  iconColor?: string
}
export function IconButton({
  IconComponent,
  bg,
  iconColor,
  id,
  isDisabled,
  isLoading = false,
  size = "md",
  style,
  variant,
  ...rest
}: IconButtonProps) {
  const Icon =
    typeof IconComponent === "string" ? AppIcons[IconComponent] : IconComponent

  const getStyle = useButtonStyle({
    bg,
    isDisabled,
    style,
    variant,
  })

  let iconSize: number
  let padding: number
  switch (size) {
    case "sm": {
      padding = 4
      iconSize = 24 - padding
      break
    }
    case "md": {
      padding = 6
      iconSize = 32 - padding
      break
    }
    case "lg": {
      padding = 8
      iconSize = 40 - padding
      break
    }
    case "xl": {
      padding = 10
      iconSize = 48 - padding
      break
    }
  }
  const fontColor = useTextColor({ isDisabled, variant })
  let colorFinal = fontColor
  if (isLoading) {
    colorFinal = COLORS.$text[500]
  }
  if (typeof iconColor === "string") {
    colorFinal = iconColor
  }
  const isButtonDisabled = isLoading || isDisabled
  return (
    <Pressable
      accessible
      accessibilityRole="button"
      accessibilityState={{ disabled: isButtonDisabled }}
      aria-disabled={isButtonDisabled}
      disabled={isButtonDisabled}
      style={(state) => {
        return [
          getStyle(state),
          {
            borderRadius: RADII.$full,
            padding,
          },
        ]
      }}
      {...rest}
      {...testIds(id)}
    >
      {renderIconFromIconProp(Icon, {
        size: iconSize,
        style: { color: colorFinal },
      })}
    </Pressable>
  )
}

export type IconButtonStyleProps = Omit<
  IconButtonProps,
  "IconComponent" | "onPress"
>

/**
 *
 */
export function SubmitButton(
  props: SetOptional<ButtonProps, "text">,
): React.JSX.Element {
  const { t } = useTranslation()
  return (
    <Button
      accessibilityLabel="Submit"
      id="submit-btn"
      size="lg"
      text={t("submit")}
      variant="primary"
      {...props}
    />
  )
}

/**
 * Cancel button
 */
export function CancelButton({
  id = "cancel-btn",
  isDisabled,
  isLoading,
  onPress,
  text,
  ...rest
}: SetOptional<ButtonProps, "text">): React.JSX.Element {
  const { t } = useTranslation()

  return (
    <Button
      accessibilityLabel={t("cancel")}
      id={id}
      isDisabled={isDisabled === true || isLoading}
      isLoading={false}
      size="lg"
      text={text ?? t("cancel")}
      onPress={onPress}
      {...rest}
    />
  )
}

/**
 *
 */
export function CloseIconButton({
  onPress,
  ...rest
}: Omit<IconButtonProps, "IconComponent">) {
  if (onPress) {
    return (
      <IconButton
        IconComponent="Close"
        accessibilityLabel="Close"
        id="close-btn"
        size="sm"
        variant="text"
        onPress={onPress}
        {...rest}
      />
    )
  }
  return null
}
export function CloseButton({
  onPress,
  text,
  ...rest
}: Omit<
  SetOptional<ButtonProps, "text">,
  "IconComponent"
>): React.JSX.Element | null {
  const { t } = useTranslation()

  if (onPress) {
    return (
      <Button
        accessibilityLabel="Close"
        id="close-btn"
        size="sm"
        text={text ?? t("close")}
        variant="outline"
        onPress={onPress}
        {...rest}
      />
    )
  }
  return null
}

export interface InvertableProps {
  isInverted?: boolean
}

// export type IMailToButton = React.FC<MailToButtonProps>

export function SortListButton(
  props: InvertableProps & Omit<ButtonProps, "text">,
) {
  const { t } = useTranslation()

  return (
    <Button
      IconComponent={props.isInverted === true ? "ChevronUp" : "ChevronDown"}
      text={t("sort")}
      variant="subtle"
      {...props}
    />
  )
}

export function CycleButton(props: IconButtonProps) {
  return (
    <IconButton
      accessibilityRole="link"
      size="sm"
      variant="subtle"
      {...props}
    />
  )
}

export function HelpIconButton(props: Omit<IconButtonProps, "IconComponent">) {
  return (
    <IconButton IconComponent="Help" id="help-btn" variant="text" {...props} />
  )
}
