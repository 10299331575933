import type { Reducer } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import * as AppStorage from "./async-storage"
import { signOutAsync } from "./auth.reducer"
import {
  addDeviceAsync,
  createConfigurationAsync,
  removeDeviceFromFarmAsync,
  renameDeviceAsync,
  replaceDeviceAsync,
  restoreConfigurationDefaultsAsync,
  setDeviceLocationPermanentAsync,
} from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"
import { useFormatInstallationType } from "./sensor-configurations"
import { isMutableSensorName } from "./sensors"

import type {
  HardwareGeneration,
  InstallationType,
  ParticlePlatform,
  ParticleProductId,
  SensorConfig,
  SensorName,
} from "./sensor-configurations"
import type { SetOptional } from "type-fest"
import type * as Geo from "./geo"

import type { ModelState } from "./models"
import type { DeviceSortKey, SortDirectionKey } from "./Sorting"
export type SelectedDeviceActionName = "controls" | "createComment" | "location"

export interface DeviceSummary {
  codaDeviceAlias: string
  deviceId: string
  deviceInstallationType: InstallationType
  deviceName: string
  firmwareVersion: number | null
  gpsLocation: Geo.MultiPoint | undefined
  hardwareGeneration: HardwareGeneration
  configurationId?: number | null
}

interface SensorConfigurations {
  battery?: SensorConfig<"battery"> | null
  device?: undefined
  flow?: SensorConfig<"flow"> | null
  gps?: SensorConfig<"gps"> | null
  hallSwitch?: SensorConfig<"hallSwitch"> | null
  icm?: SensorConfig<"icm"> | null
  pressure?: SensorConfig<"pressure"> | null
  pressureSwitch?: SensorConfig<"pressureSwitch"> | null
  reel?: SensorConfig<"reel"> | null
  relay?: SensorConfig<"relay"> | null
  temperature?: SensorConfig<"temperature"> | null
  vfd?: SensorConfig<"vfd"> | null
  wheel?: SensorConfig<"wheel"> | null
}

export interface DeviceConfiguration
  extends SensorConfigurations,
    SetOptional<Omit<DeviceSummary, "gpsLocation">, "deviceName"> {
  id: number
  createDate?: string
  createdByUserId?: string | null
  deactivateDate?: string | null
  deactivatedByUserId?: string | null
  devicePlatform?: number | string
  deviceProductId?: ParticleProductId
  isActive?: boolean
  linearPath?: Array<{
    coordinates: Geo.Coordinates
    label?: string | null
  }> | null
  linearPathStopsLabels?: string[] | null
  linearSpanHeadingDegrees?: number | null
  linearSpanWidthMm?: number | null
  locationPermanent?: Geo.PointGeoJson | null
  particlePlatform?: ParticlePlatform
  pivotCenterGpsLocation?: Geo.PointGeoJson | null
  pivotPathStopsCoordinates?: Geo.Coordinates[] | null
  pivotPathStopsHeadingsDegrees?: number[] | null
  pivotPathStopsLabels?: string[] | null
  pivotRadiusMeters?: number | null
}

const adapter = Models.deviceConfiguration.adapter

export interface DeviceConfigurationState
  extends ModelState<DeviceConfiguration, string> {
  deviceRoster: { sortDirection: SortDirectionKey; sortKey: DeviceSortKey }
  // selectedConfigFieldName?: ConfigurableSensorKey &
  //   SensorConfigKey<MutableSensorName>

  // showNoConfiguredSensors?: boolean
}

const initialState: DeviceConfigurationState = {
  ...Models.getInitialEntityState(),
  deviceRoster: {
    sortDirection: AppStorage.STORAGE_DEFAULTS.deviceSortDirection,
    sortKey: AppStorage.STORAGE_DEFAULTS.deviceSortKey,
  },
}
const devicesSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(AppStorage.setDeviceSortDirection.pending, (state, action) => {
        state.deviceRoster.sortDirection = action.meta.arg
      })
      .addCase(AppStorage.setDeviceSortKey.pending, (state, action) => {
        state.deviceRoster.sortKey = action.meta.arg
      })
      .addCase(signOutAsync.fulfilled, () => {
        return { ...initialState }
      })
      .addCase(setDeviceLocationPermanentAsync.fulfilled, (state, action) => {
        const { deviceId, locationPermanent } = action.meta.arg
        adapter.updateOne(state, {
          changes: { locationPermanent },
          id: deviceId,
        })
      })
      .addCase(addDeviceAsync.fulfilled, (state, { payload }) => {
        if (payload.status === "VALID" && payload.deviceConfiguration) {
          adapter.upsertOne(state, payload.deviceConfiguration)
        }
      })
      .addCase(replaceDeviceAsync.fulfilled, (state, action) => {
        if (action.payload.status === "VALID") {
          if (action.payload.deviceConfiguration) {
            adapter.upsertOne(state, action.payload.deviceConfiguration)
          }
          adapter.removeOne(state, action.meta.arg.deviceIdOld)
        }
      })
      .addCase(
        AppStorage.readLocalStorageAsync.fulfilled,
        (state, { payload }) => {
          state.deviceRoster = {
            sortDirection: payload.deviceSortDirection,
            sortKey: payload.deviceSortKey,
          }
        },
      )
      .addCase(loadActiveFarmAsync.fulfilled, (state, { payload }) => {
        adapter.setAll(state, payload.deviceConfigurations)
      })
      .addCase(
        restoreConfigurationDefaultsAsync.fulfilled,
        /**
         * On success, given device config is replaced with a 'blank'
         * configuration containing only minimum sensor configuration based
         * on device generation. Installation type is 'unconfigured'
         */
        (state, { payload }) => {
          if (payload && payload.result) {
            adapter.setOne(state, payload.result)
          }
        },
      )

      .addCase(
        renameDeviceAsync.fulfilled,
        /**
         * Device name is changed in place; configuration is kept
         *
         * @param state
         * @param arg
         * @param args.meta
         * @param args.payload
         */
        (state, action) => {
          adapter.updateOne(state, {
            changes: { deviceName: action.meta.arg.deviceName },
            id: action.meta.arg.deviceId,
          })
        },
      )
      .addCase(removeDeviceFromFarmAsync.fulfilled, (state, { meta }) => {
        adapter.removeOne(state, meta.arg.deviceId)
      })
      .addCase(createConfigurationAsync.fulfilled, (state, { payload }) => {
        adapter.setOne(state, payload)
      }),

  initialState,
  name: `deviceConfigurations`,
  reducers: {},
})

export const deviceConfigurationsReducer: Reducer<DeviceConfigurationState> =
  devicesSlice.reducer

/**
 *
 */
export function isSensorCompatibleWithConfiguration({
  configuration,
  sensorName,
}: {
  configuration:
    | Pick<DeviceConfiguration, "deviceInstallationType" | "hardwareGeneration">
    | null
    | undefined
  sensorName: SensorName
}): boolean {
  let isCompatible = true
  if (!configuration) {
    return false
  }

  switch (configuration.deviceInstallationType) {
    case "reel_with_booster_off_only":
    case "reel": {
      if (sensorName === "wheel") {
        isCompatible = false
      }
      break
    }
    case "center_pivot":
    case "linear_move":
    case "traveller_soft": {
      if (sensorName === "reel" || sensorName === "hallSwitch") {
        isCompatible = false
      }
      break
    }
    case "valve": {
      if (
        sensorName === "flow" ||
        sensorName === "pressure" ||
        sensorName === "pressureSwitch"
      ) {
        isCompatible = true
      }
      break
    }
    case "pump_vfd":
    case "pump_on_off":
    case "pump_off_only":
    case "pump": {
      if (
        sensorName === "reel" ||
        sensorName === "wheel" ||
        sensorName === "hallSwitch"
      ) {
        isCompatible = false
      }
      break
    }
    case "prototype": {
      break
    }
    case "unconfigured": {
      isCompatible = false
      break
    }
  }
  if (
    configuration.hardwareGeneration !== "PC1" &&
    sensorName === "pressureSwitch"
  ) {
    isCompatible = false
  }
  return isCompatible
}

/**
 * For a given installation type, determine whether a user should be to
 * delete the given sensor
 */
export function canDeleteSensor({
  deviceInstallationType,
  sensorName,
}: {
  deviceInstallationType: InstallationType | null | undefined
  sensorName: SensorName
}) {
  let canDelete = isMutableSensorName(sensorName)
  switch (deviceInstallationType) {
    case null:
    case undefined: {
      break
    }
    case "traveller_soft":
    case "center_pivot":
    case "linear_move": {
      if (sensorName === "wheel") {
        canDelete = false
      }
      break
    }
    case "prototype": {
      break
    }
    case "valve":
    case "pump_off_only":
    case "pump_on_off":
    case "pump_vfd":
    case "pump": {
      break
    }
    case "reel_with_booster_off_only":
    case "reel": {
      if (sensorName === "reel") {
        canDelete = false
      }
      break
    }
    case "unconfigured": {
      break
    }
    // TODO: New installation types
  }
  return canDelete
}
export function useFormatDeviceSelectOption() {
  const formatInstallationType = useFormatInstallationType()

  return (device: DeviceConfiguration) => {
    const name = device.deviceName ?? device.codaDeviceAlias
    let text = name
    if (device.deviceName !== device.codaDeviceAlias) {
      text += ` (${device.codaDeviceAlias}) `
    }
    text += ` - ${formatInstallationType(device.deviceInstallationType)}`
    return text
  }
}
