import React from "react"
import { useStyle } from "react-native-style-utilities"

import {
  Entypo,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons"

import { FONT_SIZES } from "../theme"
import { COLORS } from "../theme/colors"
import { useIsDarkMode } from "../ThemeProvider"
import { getTextColorFromColorScheme } from "../utils"
import {
  CenterPivotSvgIcon,
  FarmSvgIcon,
  LinearMoveIcon,
  PumpSvgIcon,
  ReelSvgIcon,
} from "./CustomIcons"

import type { SnakeCase } from "type-fest"

import type { IconProps } from "@expo/vector-icons/build/createIconSet"
import type {
  ConfigSensorName,
  InstallationType,
} from "../../sensor-configurations"
import type { AppTextProps } from "../Text"
export const ICON_SETS = {
  ...Ionicons.font,
  ...MaterialIcons.font,
  ...MaterialCommunityIcons.font,
  ...Entypo.font,
}
export const ICON_SIZES = {
  "$2xl": 24,
  "$3xl": 28,
  "$4xl": 32,
  "$lg": FONT_SIZES.$lg,
  "$md": FONT_SIZES.$md,
  "$sm": FONT_SIZES.$sm,
  "$xl": FONT_SIZES.$xl,
} as const

export type AppIconProps = Omit<IconProps<string>, "name"> &
  Pick<AppTextProps, "colorScheme">

export type CustomIconComponent = (props: AppIconProps) => JSX.Element

function useThemedProps({
  color,
  colorScheme,
  size,
  style,
  ...rest
}: AppIconProps) {
  const isDark = useIsDarkMode()

  return {
    ...rest,
    style: useStyle(() => {
      let colorFinal = color
      if (typeof colorFinal === "undefined") {
        colorFinal = getTextColorFromColorScheme({
          colorScheme: colorScheme ?? "primary",
          isDark,
        })
      }
      if (typeof colorFinal === "undefined") {
        if (isDark) {
          colorFinal = COLORS.$textLight.primary
        } else {
          colorFinal = COLORS.$text[600]
        }
      }

      return [
        {
          color: colorFinal,
          fontSize: size ?? ICON_SIZES["$md"],
        },
        style,
      ]
    }, [color, colorScheme, isDark, size, style]),
  }
}

export const AppIcons = {
  Account: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="account" {...useThemedProps(props)} />
  },
  Activate: (props: AppIconProps) => {
    return <MaterialIcons name="check" {...useThemedProps(props)} />
  },
  Add: (props: AppIconProps) => (
    <MaterialCommunityIcons name="plus" {...useThemedProps(props)} />
  ),
  Admin: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="shield-account"
        {...useThemedProps(props)}
      />
    )
  },
  Analytics: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="chart-areaspline"
        {...useThemedProps(props)}
      />
    )
  },
  AppPreferences: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        {...useThemedProps(props)}
        name="keyboard-settings"
      />
    )
  },
  AppleMaps: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="apple" {...useThemedProps(props)} />
  },
  Archive: (props: AppIconProps) => {
    return <MaterialIcons name="archive" {...useThemedProps(props)} />
  },
  ArrowDown: (props: AppIconProps) => {
    return <MaterialIcons name="arrow-downward" {...useThemedProps(props)} />
  },
  ArrowLeft: (props: AppIconProps) => {
    return <MaterialIcons name="arrow-left" {...useThemedProps(props)} />
  },
  ArrowRight: (props: AppIconProps) => {
    return <MaterialIcons name="arrow-right" {...useThemedProps(props)} />
  },
  ArrowUp: (props: AppIconProps) => {
    return <MaterialIcons name="arrow-upward" {...useThemedProps(props)} />
  },
  Battery: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="car-battery" {...useThemedProps(props)} />
    )
  },
  Borders: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="border-none-variant"
        {...useThemedProps(props)}
      />
    )
  },
  CalendarMultipleCheck: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="calendar-multiple-check"
        {...useThemedProps(props)}
      />
    )
  },
  CalendarSingle: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="calendar" {...useThemedProps(props)} />
  },

  CheckMark: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="check-circle" {...useThemedProps(props)} />
    )
  },
  ChevronDown: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="chevron-down" {...useThemedProps(props)} />
    )
  },
  ChevronUp: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="chevron-up" {...useThemedProps(props)} />
    )
  },
  Clipboard: (props: AppIconProps) => {
    return <MaterialIcons name="content-copy" {...useThemedProps(props)} />
  },
  Clock: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="clock" {...useThemedProps(props)} />
  },
  Close: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="close" {...useThemedProps(props)} />
  },
  Comment: (props: AppIconProps) => {
    return <MaterialIcons name="comment" {...useThemedProps(props)} />
  },
  Compass: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="compass" {...useThemedProps(props)} />
  },
  Contrast: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="contrast-box" {...useThemedProps(props)} />
    )
  },
  Crash: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="bug" {...useThemedProps(props)} />
  },
  Cross: (props: AppIconProps) => {
    return <Entypo name="cross" {...useThemedProps(props)} />
  },
  CustomerService: (props: AppIconProps) => {
    return <MaterialIcons name="contact-phone" {...useThemedProps(props)} />
  },
  Database: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="database" {...useThemedProps(props)} />
  },
  Dev: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="bug" {...useThemedProps(props)} />
  },
  DeviceActivity: (props: AppIconProps) => {
    return <MaterialIcons name="timeline" {...useThemedProps(props)} />
  },
  DeviceCenterPivot: (props: AppIconProps) => {
    return <Ionicons name="pie-chart-sharp" {...useThemedProps(props)} />
  },
  DeviceCommandsRemote: (props: AppIconProps) => {
    return <MaterialIcons name="settings-remote" {...useThemedProps(props)} />
  },
  DeviceConfiguration: (props: AppIconProps) => {
    return <Ionicons name="settings" {...useThemedProps(props)} />
  },
  DeviceConnection: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="vector-link" {...useThemedProps(props)} />
    )
  },
  DeviceGeneric: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="router-wireless-settings"
        {...useThemedProps(props)}
      />
    )
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DeviceLinearMove: (props: AppIconProps) => {
    return (
      <LinearMoveIcon />
      // <MaterialCommunityIcons name="water-pump" {...useThemedProps(props)} />
    )
  },

  DeviceOffline: (props: AppIconProps) => (
    <MaterialCommunityIcons
      name="cloud-off-outline"
      {...useThemedProps(props)}
    />
  ),

  DevicePrototype: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="robot-industrial"
        {...useThemedProps(props)}
      />
    )
  },

  DevicePump: ({ color, ...rest }: AppIconProps) => {
    const isDark = useIsDarkMode()
    // TODO: Allow override for custom icon props
    if (typeof color === "undefined") {
      color = isDark ? COLORS.$textLight.primary : COLORS.$textDark.primary
    }
    return (
      <PumpSvgIcon
        fill={color}
        stroke={color}
        {...rest}
        //  {...useThemedProps(props)}
      />
    )
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DeviceReel: (props: AppIconProps) => {
    return (
      <ReelSvgIcon
      // {...useThemedProps(props)}
      />
    )
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DeviceTravellerSoft: (props: AppIconProps) => {
    return (
      <CenterPivotSvgIcon
      // name="water-pump" {...useThemedProps(props)}
      />
    )
  },

  DeviceValve: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="valve" {...useThemedProps(props)} />
  },

  Disconnect: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="lan-disconnect"
        {...useThemedProps(props)}
      />
    )
  },

  Dot: (props: AppIconProps) => {
    return <Entypo name="dot-single" {...useThemedProps(props)} />
  },

  DotsThreeHorizontal: (props: AppIconProps) => {
    return <Entypo name="dots-three-horizontal" {...useThemedProps(props)} />
  },
  DotsThreeVertical: (props: AppIconProps) => {
    return <Entypo name="dots-three-vertical" {...useThemedProps(props)} />
  },
  Download: (props: AppIconProps) => {
    return <MaterialIcons name="cloud-download" {...useThemedProps(props)} />
  },
  Draw: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="draw" {...useThemedProps(props)} />
  },
  Edit: (props: AppIconProps) => {
    return <MaterialIcons name="edit" {...useThemedProps(props)} />
  },
  Error: (props: AppIconProps) => {
    return <MaterialIcons name="error" {...useThemedProps(props)} />
  },
  ExpandLess: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="chevron-up" {...useThemedProps(props)} />
    )
  },
  ExpandMore: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="chevron-down" {...useThemedProps(props)} />
    )
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Farm: (props: AppIconProps) => {
    // return <MaterialCommunityIcons name="home" {...useThemedProps(props)} />
    return <FarmSvgIcon />
  },

  FarmJoinCode: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="account-plus" {...useThemedProps(props)} />
    )
  },

  Feedback: (props: AppIconProps) => {
    return <MaterialIcons name="feedback" {...useThemedProps(props)} />
  },

  Field: (props: AppIconProps) => {
    return <MaterialIcons name="view-quilt" {...useThemedProps(props)} />
  },
  FlowOff: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="water-boiler-off"
        {...useThemedProps(props)}
      />
    )
  },
  FlowOn: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="water-boiler" {...useThemedProps(props)} />
    )
  },
  Gauge: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="gauge" {...useThemedProps(props)} />
  },
  GaugeEmpty: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="gauge-empty" {...useThemedProps(props)} />
    )
  },
  GaugeFull: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="gauge-full" {...useThemedProps(props)} />
    )
  },
  GaugeLow: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="gauge-low" {...useThemedProps(props)} />
    )
  },
  GoogleMaps: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="google-maps" {...useThemedProps(props)} />
    )
  },
  GpsFixed: (props: AppIconProps) => {
    return <MaterialIcons name="gps-fixed" {...useThemedProps(props)} />
  },
  GpsOff: (props: AppIconProps) => {
    return <MaterialIcons name="gps-off" {...useThemedProps(props)} />
  },
  Help: (props: AppIconProps) => {
    return <MaterialIcons name="help" {...useThemedProps(props)} />
  },
  History: (props: AppIconProps) => {
    return <MaterialIcons name="history" {...useThemedProps(props)} />
  },
  Info: (props: AppIconProps) => {
    return <MaterialIcons name="info" {...useThemedProps(props)} />
  },
  LikeNo: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="thumb-down" {...useThemedProps(props)} />
    )
  },
  LikeYes: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="thumb-up" {...useThemedProps(props)} />
  },
  Link: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="link" {...useThemedProps(props)} />
  },
  ListItemEnd: (props: AppIconProps) => {
    return <Entypo name="chevron-right" {...useThemedProps(props)} />
  },
  LocationPinned: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="pin" {...useThemedProps(props)} />
  },
  LocationUnpinned: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="pin-off" {...useThemedProps(props)} />
  },
  Magnet: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="magnet" {...useThemedProps(props)} />
  },
  MagnetOn: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="magnet-on" {...useThemedProps(props)} />
    )
  },
  ManageUsers: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="account-group" {...useThemedProps(props)} />
    )
  },
  MapMarker: (props: AppIconProps) => {
    return <MaterialIcons name="place" {...useThemedProps(props)} />
  },
  Measurement: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="ruler" {...useThemedProps(props)} />
  },
  Menu: (props: AppIconProps) => {
    return <MaterialIcons name="menu" {...useThemedProps(props)} />
  },
  NotificationsOn: (props: AppIconProps) => {
    return <Ionicons name="notifications" {...useThemedProps(props)} />
  },
  Permissions: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="shield-account"
        {...useThemedProps(props)}
      />
    )
  },
  Phone: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="phone" {...useThemedProps(props)} />
  },
  Print: (props: AppIconProps) => {
    return <MaterialIcons name="print" {...useThemedProps(props)} />
  },
  PrivacyPolicy: (props: AppIconProps) => {
    return <MaterialIcons name="privacy-tip" {...useThemedProps(props)} />
  },
  PumpOff: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="water-pump-off"
        {...useThemedProps(props)}
      />
    )
  },
  PumpOn: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="water-pump" {...useThemedProps(props)} />
    )
  },
  ReelRun: (props: AppIconProps) => {
    return <Entypo name="flow-line" {...useThemedProps(props)} />
  },
  Refresh: (props: AppIconProps) => {
    return <MaterialIcons name="refresh" {...useThemedProps(props)} />
  },
  Repeat: (props: AppIconProps) => {
    return <MaterialIcons name="repeat" {...useThemedProps(props)} />
  },
  Restart: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="restart" {...useThemedProps(props)} />
  },
  Sensor: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="smoke-detector-variant"
        {...useThemedProps(props)}
      />
    )
  },
  Share: (props: AppIconProps) => (
    <MaterialCommunityIcons name="share" {...useThemedProps(props)} />
  ),
  SignOut: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="logout" {...useThemedProps(props)} />
  },
  Signal: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="signal-distance-variant"
        {...useThemedProps(props)}
      />
    )
  },
  SineWave: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="sine-wave" {...useThemedProps(props)} />
    )
  },
  SleepOff: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="sleep-off" {...useThemedProps(props)} />
    )
  },
  SleepOn: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="sleep" {...useThemedProps(props)} />
  },
  Sms: (props: AppIconProps) => {
    return <MaterialIcons name="sms" {...useThemedProps(props)} />
  },
  SortAlphabetical: (props: AppIconProps) => {
    return <MaterialIcons name="sort-by-alpha" {...useThemedProps(props)} />
  },
  SortAscending: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="sort-ascending"
        {...useThemedProps(props)}
      />
    )
  },
  SortDescending: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="sort-descending"
        {...useThemedProps(props)}
      />
    )
  },
  Speed: (props: AppIconProps) => {
    return <MaterialIcons name="speed" {...useThemedProps(props)} />
  },
  StatusMap: (props: AppIconProps) => {
    return <MaterialIcons name="map" {...useThemedProps(props)} />
  },
  Success: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="check-circle" {...useThemedProps(props)} />
    )
  },
  Support: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="lifebuoy" {...useThemedProps(props)} />
  },
  SupportDocumentation: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="book" {...useThemedProps(props)} />
  },
  SwapHorizontal: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="swap-horizontal"
        {...useThemedProps(props)}
      />
    )
  },
  SwapVertical: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="swap-vertical" {...useThemedProps(props)} />
    )
  },
  SwitchClosed: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="electric-switch-closed"
        {...useThemedProps(props)}
      />
    )
  },
  SwitchOpen: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="electric-switch"
        {...useThemedProps(props)}
      />
    )
  },
  SystemUpdate: (props: AppIconProps) => {
    return <MaterialIcons name="system-update" {...useThemedProps(props)} />
  },
  Target: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="target" {...useThemedProps(props)} />
  },
  TemperatureLow: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="temperature-celsius"
        {...useThemedProps(props)}
      />
    )
  },

  TermsOfService: (props: AppIconProps) => {
    return <MaterialIcons name="gavel" {...useThemedProps(props)} />
  },
  ThemeLightDark: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="theme-light-dark"
        {...useThemedProps(props)}
      />
    )
  },
  Ticket: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="ticket" {...useThemedProps(props)} />
  },
  TimeTable: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="timetable" {...useThemedProps(props)} />
    )
  },
  Timeline: (props: AppIconProps) => {
    return <MaterialIcons name="timeline" {...useThemedProps(props)} />
  },
  Timer10: (props: AppIconProps) => {
    return <MaterialIcons name="timer-10" {...useThemedProps(props)} />
  },
  TrashCan: (props: AppIconProps) => {
    return <MaterialIcons name="delete" {...useThemedProps(props)} />
  },
  Truck: (props: AppIconProps) => {
    return <MaterialCommunityIcons name="truck" {...useThemedProps(props)} />
  },
  UnderConstruction: (props: AppIconProps) => {
    return <MaterialIcons name="construction" {...useThemedProps(props)} />
  },
  Undo: (props: AppIconProps) => {
    return <MaterialIcons name="undo" {...useThemedProps(props)} />
  },
  ValveClosed: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="valve-closed" {...useThemedProps(props)} />
    )
  },
  ValveOpen: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons name="valve-open" {...useThemedProps(props)} />
    )
  },
  VerifyPhoneNumber: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="cellphone-check"
        {...useThemedProps(props)}
      />
    )
  },
  VisibilityOff: (props: AppIconProps) => {
    const themedProps = useThemedProps(props)

    return <MaterialIcons name="visibility-off" {...themedProps} />
  },
  VisibilityOn: (props: AppIconProps) => {
    return <MaterialIcons name="visibility" {...useThemedProps(props)} />
  },
  VoltageBolt: (props: AppIconProps) => {
    return <MaterialIcons name="bolt" {...useThemedProps(props)} />
  },
  Warning: (props: AppIconProps) => {
    return <MaterialIcons name="warning" {...useThemedProps(props)} />
  },
  Water: (props: AppIconProps) => {
    return <Ionicons name="water" {...useThemedProps(props)} />
  },
  Wheel: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="axis-x-rotate-clockwise"
        {...useThemedProps(props)}
      />
    )
  },
  ZoomIn: (props: AppIconProps) => {
    return (
      <MaterialCommunityIcons
        name="magnify-plus-outline"
        {...useThemedProps(props)}
      />
    )
  },
  ZoomOutMap: (props: AppIconProps) => {
    return <MaterialIcons name="zoom-out-map" {...useThemedProps(props)} />
  },
}
export type IconKey = keyof typeof AppIcons
export type IconProp = CustomIconComponent | IconKey | React.JSX.Element | null

/**
 * Get the icon for a given sensor name.
 */
export function getIconForSensor(
  sensorName: ConfigSensorName | SnakeCase<ConfigSensorName>,
): CustomIconComponent {
  switch (sensorName) {
    case "flow": {
      return AppIcons.FlowOn
    }
    case "vfd":
    case "pressure_switch":
    case "pressureSwitch":
    case "pressure": {
      return AppIcons.PumpOn
    }
    case "reel": {
      return AppIcons.Wheel
    }
    case "wheel": {
      return AppIcons.Wheel
    }
    case "battery": {
      return AppIcons.Battery
    }
    case "gps": {
      return AppIcons.GpsFixed
    }
    case "hall_switch":
    case "hallSwitch": {
      return AppIcons.MagnetOn
    }
    case "icm": {
      return AppIcons.Compass
    }
    case "relay": {
      return AppIcons.SwitchClosed
    }
    case "temperature": {
      return AppIcons.TemperatureLow
    }
    default: {
      return AppIcons.Sensor
    }
  }
}

/**
 * Get the icon for a given installation type.
 */
export function getIconForInstallationType(
  installationType: InstallationType,
): CustomIconComponent {
  switch (installationType) {
    case "valve": {
      return AppIcons.DeviceValve
    }
    case "reel":
    case "reel_with_booster_off_only": {
      return AppIcons.DeviceReel
    }
    case "center_pivot": {
      return AppIcons.DeviceCenterPivot
    }
    case "linear_move": {
      return AppIcons.DeviceLinearMove
    }
    case "prototype": {
      return AppIcons.DevicePrototype
    }
    case "pump":
    case "pump_off_only":
    case "pump_on_off":
    case "pump_vfd":
    case "traveller_soft": {
      return AppIcons.DevicePump
    }
    case "unconfigured": {
      return AppIcons.Warning
    }
    default: {
      return AppIcons.DeviceGeneric
    }
  }
}

const DEFAULT_SIZE = 16

/**
 * Take either an IconComponent or an IconKey and render it as a component.
 */
export function renderIconFromIconProp(
  Icon: IconProp | null | undefined,
  props?: AppIconProps,
) {
  if (typeof Icon === "string") {
    const Component = AppIcons[Icon]
    return <Component size={DEFAULT_SIZE} {...props} />
  }
  if (typeof Icon === "function") {
    return <Icon size={DEFAULT_SIZE} {...props} />
  }
  return Icon ?? null
}
