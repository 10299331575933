import { Provider } from "./base"
import { ErrorMessage } from "./ErrorMessage"
// import { FormField } from "./FormField"
import { HelperText } from "./HelperText"
import { Input } from "./Input"
import { Label } from "./Label"
import { Select } from "./Select"

export * from "./useInputStyle"
export const FormControl = {
  ErrorMessage,
  // FormField,
  HelperText,
  Input,
  Label,
  Provider,
  Select,
}
