import React from "react"
import { StyleSheet, TextInput as InputBase, View } from "react-native"

import { Box } from "../Box"
import { Row } from "../Row"
import { testIds } from "../test-id"
import { AppText } from "../Text"
import { SPACING } from "../theme"
import { useFormControlContext } from "./base"
import { useInputStyle } from "./useInputStyle"

import type { TextInputProps, ViewProps } from "react-native"
import type { TestId } from "../test-id"

const styles = StyleSheet.create({
  inputRightAddonText: {
    paddingRight: SPACING.$4,
  },
})

export function InputRightAddon({ children, ...rest }: ViewProps) {
  return (
    <View {...rest}>
      {typeof children === "string" ? (
        <AppText style={styles.inputRightAddonText}>{children}</AppText>
      ) : (
        children
      )}
    </View>
  )
}

export interface InputProps extends Omit<TextInputProps, "id"> {
  InputRightElement?: JSX.Element | null
  id?: TestId
  isDisabled?: boolean
  isInvalid?: boolean
}

export const Input = React.forwardRef(function Input(
  {
    InputRightElement,
    id,
    isDisabled,
    isInvalid,
    multiline,
    numberOfLines,
    style,
    textContentType,
    ...rest
  }: InputProps,
  ref: React.Ref<InputBase>,
) {
  const ctx = useFormControlContext()
  if (typeof isDisabled === "undefined") {
    isDisabled = ctx.isDisabled
  }
  if (typeof isInvalid === "undefined") {
    isInvalid = ctx.isInvalid
  }

  const { placeholderTextColor, ...baseStyle } = useInputStyle({
    isDisabled,
    isInvalid,
  })

  const element = (
    <InputBase
      {...rest}
      {...testIds(id)}
      ref={ref}
      accessibilityLabel={rest.placeholder}
      accessibilityLabelledBy={ctx.id}
      editable={!isDisabled}
      focusable={!isDisabled}
      maxFontSizeMultiplier={1.35}
      multiline={multiline}
      numberOfLines={numberOfLines}
      placeholderTextColor={placeholderTextColor}
      style={[baseStyle, style]}
      accessibilityState={{
        disabled: isDisabled,
      }}
      secureTextEntry={
        textContentType === "password" || textContentType === "newPassword"
      }
    />
  )
  if (InputRightElement) {
    return (
      <Row flexWrap="nowrap" position="relative">
        <Box flex={1}>{element}</Box>
        <Box
          alignItems="center"
          bottom="$0"
          display="flex"
          justifyContent="center"
          position="absolute"
          right="$0"
          top="$0"
        >
          <Box m="auto">{InputRightElement}</Box>
        </Box>
      </Row>
    )
  }
  return element
})
