import React from "react"
import { Switch as SwitchBase } from "react-native"

import { testIds } from "./test-id"
import { COLORS } from "./theme/colors"
import { useColorMode, useIsDarkMode } from "./ThemeProvider"

import type { SwitchProps as SwitchPropsBase } from "react-native"
import type { TestId } from "./test-id"
export interface SwitchProps extends Omit<SwitchPropsBase, "disabled" | "id"> {
  id?: TestId
  isDisabled?: boolean
}

export function Switch({ id, isDisabled, style, ...rest }: SwitchProps) {
  const mode = useColorMode()
  const isDarkMode = useIsDarkMode()
  const trackColor = {
    false: COLORS.$gray[isDarkMode ? 600 : 300],
    true: COLORS.$buttonPrimary[mode],
  }
  return (
    <SwitchBase
      accessibilityRole="switch"
      // @ts-ignore - this prop is web-only; RN types don't recognize it
      activeThumbColor={isDarkMode ? COLORS.$gray[200] : COLORS.$white}
      disabled={isDisabled}
      style={style}
      thumbColor={COLORS.$gray[100]}
      trackColor={trackColor}
      {...testIds(id)}
      {...rest}
    />
  )
}
