import React from "react"
import { useTranslation } from "react-i18next"

import { Box, Button, Row } from "./components"
import { DeviceConnectionDisplay } from "./DeviceConnectionDisplay"
import { ConfirmDeleteDeviceConnection } from "./DeviceConnectionsList"

import type * as Models from "./models"
import type {
  CodaDeviceAliasProps,
  DeviceIdProps,
  PermissionCheckProps,
} from "./types"

/**
 * Display a selected connection between devices
 */
export function SelectedConnection({
  onDeleteSuccess,
  onPressDevice,
  withPermissions,
  ...props
}: Models.EventActionTrigger &
  PermissionCheckProps & {
    onDeleteSuccess?: () => void
    onPressDevice?: (params: CodaDeviceAliasProps & DeviceIdProps) => void
  }) {
  const { t } = useTranslation("deviceConnections")
  const [isDeleting, setIsDeleting] = React.useState(false)

  return (
    <React.Fragment>
      <DeviceConnectionDisplay onPressDevice={onPressDevice} {...props} />
      <Row mt="$4">
        {isDeleting ? (
          <Box flex={1} mt="$2">
            <ConfirmDeleteDeviceConnection
              connectionId={props.id}
              sourceDeviceId={props.sourceDeviceId}
              withPermissions={withPermissions}
              onCancel={() => setIsDeleting(false)}
              onDeleteSuccess={onDeleteSuccess}
            />
          </Box>
        ) : (
          <Button
            IconComponent="TrashCan"
            text={t("delete", { ns: "common" })}
            onPress={withPermissions({
              callback: () => {
                setIsDeleting(true)
              },
              required: "canManageCustomTriggers",
            })}
          />
        )}
      </Row>
    </React.Fragment>
  )
}
