import React from "react"
import { useNavigate } from "react-router"

import { ActionButtons, CreateField, Geo, useRootSelector } from "@fhq/app"
import { AppText } from "@fhq/app/components"
import { FieldNameInput } from "@fhq/app/EditFieldLabel"
import { DrawingManager, GoogleMap } from "@react-google-maps/api"

import ActiveFarmMarker from "./base/ActiveFarmMarker"
import { DeviceMarker } from "./base/DeviceMarker"
import { Arrow } from "./base/DirectionArrow"
import { FieldLabel } from "./base/FieldLabel"
import { FloatingMapCard } from "./base/FloatingMapCard"
import { withPageLayout } from "./base/PageLayout"
import { getMapTypeValueFromState } from "./base/selectors"
import { FieldPolygon } from "./FieldPolygon"

import type { GoogleMapProps } from "@react-google-maps/api"
const MAP_STYLE: GoogleMapProps["mapContainerStyle"] = { flex: 1 }

/**
 * Create a new field.
 */
function Main({
  markerRef,
  polygonRef,
}: {
  markerRef: React.MutableRefObject<google.maps.Marker | undefined>
  polygonRef: React.MutableRefObject<google.maps.Polygon | undefined>
}) {
  const mapRef = React.useRef<google.maps.Map>()
  const mapTypeId = useRootSelector(getMapTypeValueFromState)
  const [isPolygonComplete, setIsPolygonComplete] = React.useState(false)
  const {
    deviceMarkers,
    fields,
    initialLocation,
    isLoading,
    isSuccess,
    onCancel,
    onSetCenter,
    onSubmit,
    polygonOptions,
    showFieldLabel,
    stage,
    t,
    texts,
    values: { fieldName, rowDirectionAzimuthDegrees },
  } = CreateField.useContext()
  const navigate = useNavigate()
  /**
   * Apply normal colors to field
   */
  const parsePolygonGeom = (ref: google.maps.Polygon | undefined) => {
    const path = ref?.getPath().getArray() as
      | google.maps.LatLngLiteral[]
      | undefined
    const polygonWrapped = Geo.polygon(path)
    return { center: polygonWrapped?.getCenter()?.toGmaps(), path }
  }
  const onClose = () => navigate(-1)

  const parsed = parsePolygonGeom(polygonRef.current)
  const centerLatLng = parsed.center
  const path = parsed.path

  let cardContent: JSX.Element
  if (isSuccess) {
    cardContent = <CreateField.Success onClearPolygon={null} />
  } else {
    // Stages
    cardContent = (
      <React.Fragment>
        <AppText>{texts.instructions}</AppText>
        {stage === "setName" ? (
          <FieldNameInput isLoading={isLoading} />
        ) : stage === "setRowDirection" ? (
          <CreateField.RowDirectionSlider />
        ) : null}
        <ActionButtons
          cancelText={t("back", { ns: "common" })}
          isLoading={isLoading}
          isSubmitDisabled={stage === "drawPolygon" && !isPolygonComplete}
          mt="$4"
          submitText={texts.submitButton}
          onPressSubmit={onSubmit(path)}
          onPressCancel={() => {
            if (stage === "drawPolygon") {
              onClose()
            } else {
              onCancel()
            }
          }}
        />
      </React.Fragment>
    )
  }
  const initialCenter = React.useMemo(
    () => Geo.point(initialLocation)?.toGmaps(),
    [initialLocation],
  )
  const mapOptions = React.useMemo((): GoogleMapProps["options"] => {
    return {
      disableDefaultUI: true,
      mapTypeId,
    }
  }, [mapTypeId])

  const handleMapLoaded = React.useCallback((mapInstance: google.maps.Map) => {
    mapRef.current = mapInstance
  }, [])

  const handleMarkerComplete = React.useCallback(
    (finishedMarker: google.maps.Marker) => {
      markerRef.current?.setMap(null)
      const asJson = finishedMarker.getPosition()?.toJSON()
      onSetCenter(asJson)
      if (asJson) {
        mapRef.current?.panTo(asJson)
        mapRef.current?.setZoom(15)
      }
      markerRef.current = finishedMarker
    },
    [markerRef, onSetCenter],
  )

  const handlePolygonComplete = React.useCallback(
    (p: google.maps.Polygon) => {
      setIsPolygonComplete(true)
      polygonRef.current?.setMap(null)
      polygonRef.current = p
      mapRef.current?.setZoom(15)
      const nextCenter = parsePolygonGeom(p).center
      nextCenter && mapRef.current?.panTo(nextCenter)
      polygonRef.current.setEditable(true)
      polygonRef.current.setOptions(polygonOptions)
    },
    [polygonOptions, polygonRef],
  )
  return (
    <React.Fragment>
      <FloatingMapCard>
        <CreateField.HeaderElement />
        {cardContent}
      </FloatingMapCard>
      <GoogleMap
        center={initialCenter}
        id="google-map"
        mapContainerStyle={MAP_STYLE}
        options={mapOptions}
        zoom={14}
        onLoad={handleMapLoaded}
      >
        {deviceMarkers.map((device) => {
          return (
            <DeviceMarker
              key={device.deviceId}
              deviceId={device.deviceId}
              isClickable={false}
              showCenterPivotOutline={false}
              showDeviceActivityVisualizations={false}
              showParkingSpots={false}
            />
          )
        })}
        {fields.map((field) => {
          if (field.path?.web) {
            return (
              <FieldPolygon
                key={field.id}
                isPressable={false}
                {...field}
                path={field.path.web}
              />
            )
          }
          return null
        })}
        <ActiveFarmMarker />
        {showFieldLabel ? (
          <FieldLabel
            fieldName={fieldName}
            labelRotation={0}
            location={centerLatLng}
          />
        ) : null}
        <DrawingManager
          options={{ drawingControl: false, polygonOptions }}
          drawingMode={
            stage === "drawPolygon"
              ? google.maps.drawing.OverlayType.POLYGON
              : null
          }
          onMarkerComplete={handleMarkerComplete}
          onPolygonComplete={handlePolygonComplete}
        />

        {stage === "setRowDirection" ? (
          <Arrow center={centerLatLng} direction={rowDirectionAzimuthDegrees} />
        ) : null}
      </GoogleMap>
    </React.Fragment>
  )
}

function WithProvider() {
  const navigate = useNavigate()
  const polygonRef = React.useRef<google.maps.Polygon>()
  const markerRef = React.useRef<google.maps.Marker>()

  const deleteShapes = () => {
    polygonRef.current?.setMap(null)
    markerRef.current?.setMap(null)
  }
  return (
    <CreateField.Provider
      onClosed={() => navigate("..")}
      onDeleteShapes={deleteShapes}
    >
      <Main markerRef={markerRef} polygonRef={polygonRef} />
    </CreateField.Provider>
  )
}
export default withPageLayout(WithProvider, {
  boxProps: { flex: 1 },
  id: "create-field",
  requiredPermissions: "canManageFields",
})
