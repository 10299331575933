import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import {
  Forbidden,
  getFarmUserPermissionsFromState,
  useRootSelector,
} from "@fhq/app"
import { Box, Button } from "@fhq/app/components"
import { identifyMissingPermissions } from "@fhq/app/permissions"

import type { AcceptsChildren, NoChildren, BoxProps } from "@fhq/app/components"

import type { TestId } from "@fhq/app"
import type { RequiredPermissions } from "@fhq/app/permissions"
export interface PageOptions {
  id: TestId
  boxProps?: NoChildren<BoxProps>

  requiredPermissions?: RequiredPermissions | null | undefined
}
export function PageLayout({
  boxProps,
  children,
  id,
  requiredPermissions,
}: AcceptsChildren & PageOptions) {
  const navigate = useNavigate()
  const hasPermission = useRootSelector((state): boolean => {
    if (Boolean(requiredPermissions)) {
      const provided = getFarmUserPermissionsFromState(state)
      const missing = identifyMissingPermissions({
        provided,
        required: requiredPermissions,
      })
      if (missing.length > 0) {
        return false
      }
    }
    return true
  })
  const { t } = useTranslation()

  return (
    <Box style={{ flex: 1 }} {...boxProps} id={id}>
      {hasPermission ? (
        children
      ) : (
        <Forbidden
          backButtonElement={
            <Button text={t("back")} onPress={() => navigate(-1)} />
          }
        />
      )}
    </Box>
  )
}
// eslint-disable-next-line @typescript-eslint/ban-types
export function withPageLayout<P = {}>(
  Component: React.FC<P & React.JSX.IntrinsicAttributes>,
  options: PageOptions & {
    headerLarge?: boolean
  },
) {
  const WrappedComponent: typeof Component = (props) => {
    return (
      <PageLayout {...options}>
        <Component {...props} />
      </PageLayout>
    )
  }
  WrappedComponent.displayName = `${Component.displayName ?? ""}WithPageLayout`

  return WrappedComponent
}
