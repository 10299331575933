import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import { Box, Row } from "./components"
import { TitleWithIcon } from "./components/TitleWithIcon"
import { getDeviceStatusListItemsFromState } from "./device-event-last.reducer"
import { createFieldAsync } from "./farmhq-api"
import { getActiveFieldsFromState } from "./fields.reducer"
import * as Geo from "./geo"
import { AzimuthDisplayText, SingleValueSlider } from "./sliders"
import { SuccessHandler } from "./SuccessHandler"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useFieldColors } from "./useFieldColors"
import { getActiveFarmCoordinatesFromState } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type {
  AcceptsChildren,
  HelpContentStatic,
  NoChildren,
  RowProps,
} from "./components"
export const HELPCONTENT: HelpContentStatic = {
  bodyText: i18n.t("createField:helpBodyText"),
  subject: "create_field",
  titleText: i18n.t("createField:helpTitle"),
}

interface ProviderProps {
  onClosed: () => void
  onDeleteShapes: (() => void) | null
}
type Stage = "drawPolygon" | "setName" | "setRowDirection"

const DEFAULT_STAGE: Stage = "drawPolygon"
const DEFAULTS = {
  bearingEnd: 0,
  bearingStart: 0,
  fieldName: "New Field",
  pivotRadius: {
    metric: 300,
    us: 1000,
  },
  rowDirectionAzimuthDegrees: 0,
  stage: DEFAULT_STAGE,
}
function useCreateField({ onDeleteShapes, ...rest }: ProviderProps) {
  const { t } = useTranslation("createField")
  const { fillColor, strokeColor } = useFieldColors()
  const { handleError, isLoading, sendRequest, toasts } =
    useBackendRequest(createFieldAsync)

  const fields = useRootSelector(getActiveFieldsFromState)
  const farmLocation = useRootSelector((state) =>
    getActiveFarmCoordinatesFromState(state),
  )
  const form = useForm({
    defaultValues: { fieldName: DEFAULTS.fieldName },
  })

  const [center, setCenter] = React.useState<Geo.RnmLatLng>()
  const [stage, setStage] = React.useState<Stage>(DEFAULTS.stage)
  const [isSuccess, setIsSuccess] = React.useState(false)

  const [rowDirectionAzimuthDegrees, setRowDirectionAzimuthDegrees] =
    React.useState(DEFAULTS.rowDirectionAzimuthDegrees)

  let submitText: string = t("next", { ns: "common" })
  let instructions: string
  const onClearFormData = () => {
    if (onDeleteShapes) {
      onDeleteShapes()
    }
    form.reset()
    setIsSuccess(false)
    setRowDirectionAzimuthDegrees(DEFAULTS.rowDirectionAzimuthDegrees)
    setCenter(undefined)
  }
  /**
   * Note: this returns a callback
   */
  let onSubmit: (
    polygonInput: Geo.MakePolygonInput | null | undefined,
  ) => () => void

  /**
   * If we are are the beginning of the sequence, exit the interaction.
   * otherwise, go back
   */
  let onCancel: () => void

  switch (stage) {
    case "drawPolygon": {
      onCancel = onClearFormData
      onSubmit = () => () => {
        setStage("setRowDirection")
      }
      instructions = t("drawPolygonInstructions")
      break
    }
    case "setRowDirection": {
      onCancel = () => {
        if (onDeleteShapes) {
          onDeleteShapes()
        }
        setStage("drawPolygon")
      }
      onSubmit = () => () => {
        setStage("setName")
      }
      instructions = t("setRowDirectionInstructions")
      break
    }
    case "setName": {
      submitText = t("submit", { ns: "common" })
      instructions = t("setNameInstructions")
      onCancel = () => {
        setStage("setRowDirection")
      }
      onSubmit = (polygonInput) => {
        const polygon = Geo.polygon(polygonInput)?.toJson()

        return form.handleSubmit(({ fieldName }) => {
          if (typeof polygon === "undefined") {
            throw new TypeError("Polygon input is undefined")
          }
          sendRequest({
            fieldName,
            fieldType: "polygon",
            polygon,
            rowDirectionAzimuthDegrees,
          })
            .then(() => {
              onClearFormData()
              setStage(DEFAULTS.stage)
              setIsSuccess(true)
              return toasts.success()
            })
            .catch((error) => {
              handleError(error, {
                toastMessage: "default",
              })
            })
          submitText = t("submit", { ns: "common" })
        })
      }

      break
    }
  }

  return {
    ...rest,
    deviceMarkers: useRootSelector(getDeviceStatusListItemsFromState),
    fields,
    form,
    initialLocation: farmLocation,
    isLoading,
    isSuccess,
    onCancel,
    onClearFormData,
    onSetCenter: (nextCenter: Geo.PointInput) => {
      const asNative = Geo.point(nextCenter)?.toNative()
      if (asNative) {
        setCenter(asNative)
      }
    },
    onSubmit,
    polygonOptions: { fillColor, strokeColor },
    setRowDirectionAzimuthDegrees,
    showFieldLabel: stage === "setName" || stage === "setRowDirection",
    stage,
    t,
    texts: {
      instructions,
      submitButton: submitText,
    },
    values: {
      ...form.watch(),
      center,
      rowDirectionAzimuthDegrees,
    },
  }
}

type ContextValue = ReturnType<typeof useCreateField>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): JSX.Element | null {
  const value = useCreateField(rest)

  return (
    <Context.Provider value={value}>
      <FormProvider {...value.form}>{children}</FormProvider>
    </Context.Provider>
  )
}

export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`CreateField Context must be used inside of provider`)
  }
  return ctx
}

export function HeaderElement(props: NoChildren<RowProps>) {
  const { t } = useContext()
  return (
    <TitleWithIcon {...props} IconComponent="Field" titleText={t("title")} />
  )
}
export function Success({
  onClearPolygon,
}: {
  onClearPolygon: (() => void) | null
}) {
  const { onClearFormData: onResetForm, onClosed, t } = useContext()
  return (
    <SuccessHandler maxW="$lg" message={t("successInstructions")} w="$full">
      <ActionButtons
        cancelText={t("done", { ns: "common" })}
        isLoading={false}
        mt="$4"
        submitText={t("drawMoreButton")}
        onPressCancel={onClosed}
        onPressSubmit={() => {
          if (onClearPolygon) {
            onClearPolygon()
          }
          onResetForm()
        }}
      />
    </SuccessHandler>
  )
}
export function RowDirectionSlider() {
  const { isLoading, setRowDirectionAzimuthDegrees, values } = useContext()
  return (
    <Row id="azimuth-slider" my="$2">
      <Box w="$24">
        <AzimuthDisplayText value={values.rowDirectionAzimuthDegrees} />
      </Box>
      <Box flex={1} my="$2">
        <SingleValueSlider
          disabled={isLoading}
          maximumValue={359}
          minimumValue={0}
          value={values.rowDirectionAzimuthDegrees}
          onValueChange={setRowDirectionAzimuthDegrees}
        />
      </Box>
    </Row>
  )
}
