import type { Reducer } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import * as AppStorage from "./async-storage"

import type { FieldColorScheme } from "./async-storage"
import type { MapTypeId } from "./geo"
import type { MeasurementPreference } from "./sensor-conversions"

interface UserPreferencesState {
  fieldColorPreference: FieldColorScheme
  mapTypeId: MapTypeId
  measurement?: MeasurementPreference
}

const initialState: UserPreferencesState = {
  fieldColorPreference: AppStorage.STORAGE_DEFAULTS.fieldColorPreference,
  mapTypeId: AppStorage.STORAGE_DEFAULTS.mapTypeId,
}
const slice = createSlice({
  extraReducers: (builder) => {
    return builder
      .addCase(AppStorage.setMeasurementPreference.pending, (state, action) => {
        state.measurement = action.meta.arg
      })
      .addCase(AppStorage.setFieldColorPreference.pending, (state, action) => {
        state.fieldColorPreference = action.meta.arg
      })
      .addCase(AppStorage.setMapTypeId.pending, (state, action) => {
        state.mapTypeId = action.meta.arg
      })
      .addCase(
        AppStorage.readLocalStorageAsync.fulfilled,
        (state, { payload: storedValues }) => {
          state.fieldColorPreference = storedValues.fieldColorPreference
          state.mapTypeId = storedValues.mapTypeId
          state.measurement = storedValues.measurementSystem
        },
      )
  },
  initialState,
  name: "user",
  reducers: {},
})

const user: Reducer<UserPreferencesState> = slice.reducer
export default user
