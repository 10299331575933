import type { Reducer } from "@reduxjs/toolkit"
import { createSlice, isAnyOf } from "@reduxjs/toolkit"

import {
  clearCommentsForDeviceAsync,
  createDeviceCommentAsync,
  loadDeviceActivityAsync,
} from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"

/**
 * Device Comments state
 */
export type DeviceCommentsState = Models.ModelState<
  Models.DeviceComment,
  string
>

/**
 *
 */
const INITIAL_STATE: DeviceCommentsState = {
  entities: {},
  ids: [],
}

/**
 *
 */
const slice = createSlice({
  extraReducers: (builder) => {
    return builder
      .addCase(createDeviceCommentAsync.fulfilled, (state, { payload }) => {
        Models.deviceComment.adapter.upsertOne(state, payload)
      })
      .addCase(clearCommentsForDeviceAsync.fulfilled, (state, { meta }) => {
        Models.deviceComment.adapter.updateOne(state, {
          changes: {
            status: "suppressed",
          },
          id: meta.arg.deviceId,
        })
      })
      .addMatcher(
        isAnyOf(
          loadDeviceActivityAsync.fulfilled,
          loadActiveFarmAsync.fulfilled,
        ),
        (state, { payload }) => {
          Models.deviceComment.adapter.setAll(state, payload.comments)
        },
      )
  },
  initialState: INITIAL_STATE,
  name: "deviceComment",
  reducers: {},
})

/**
 * Device Comments reducer for rootReducer
 */
export const deviceCommentsReducer: Reducer<DeviceCommentsState> = slice.reducer
