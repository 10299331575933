import React from "react"
import { Polygon as SvgPolygon, Svg } from "react-native-svg"
import { connect } from "react-redux"

import { COLORS } from "./components/theme"
import { withErrorBoundary } from "./ErrorBoundary"
import * as Models from "./models"
import { isTruthyString } from "./type-guards"
import { useFieldColors } from "./useFieldColors"

import type { PolygonProps as SvgPolygonProperties } from "react-native-svg"
import type { ConnectedProps } from "react-redux"
import type { RootState } from "./root.reducer"
import type { FieldIdProps } from "./types"
interface OwnProps
  extends Omit<SvgPolygonProperties, "height" | "id" | "points" | "width"> {
  height?: number
  width?: number
}

const connector = connect(
  (state: RootState, { fieldId, ...rest }: FieldIdProps & OwnProps) => {
    const field = Models.field.selectById(state, fieldId)
    return { fieldId, ...rest, ...field?.svgInfo }
  },
  {},
)

export const FieldSvgComponent = React.memo(function FieldSvgComponent({
  fieldId,
  points,
  viewbox,
  ...rest
}: ConnectedProps<typeof connector>): React.JSX.Element | null {
  const { svgColor } = useFieldColors()

  const width = rest.width ?? rest.height ?? 60
  const height = rest.height ?? rest.width ?? 60

  if (isTruthyString(points) && isTruthyString(viewbox)) {
    return (
      <Svg
        height={height}
        testID={`svg-${fieldId}`}
        viewBox={viewbox}
        width={width}
        {...rest}
      >
        <SvgPolygon
          fill={svgColor}
          points={points}
          stroke={COLORS.$black}
          strokeWidth={0.00004}
        />
      </Svg>
    )
  }
  return null
})
export const FieldSvgConnected = withErrorBoundary(connector(FieldSvgComponent))
