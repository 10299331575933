import { addHours, roundToNearestMinutes } from "date-fns"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FormControl } from "./components"
import { createDeviceActionScheduleAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import { useBackendRequest } from "./useBackendRequest"

import type { AcceptsChildren } from "./components"
import type { NamedDeviceAction } from "./models"
import type { DeviceIdProps } from "./types"

export interface ProviderProps extends DeviceIdProps {
  actions: NamedDeviceAction[]
  executionTimezone: string | undefined
  onClose: () => void
}

/**
 * Provides a form for creating a delayed shutdown schedule
 */
function useShutdownTimerForm({
  actions,
  deviceId,
  executionTimezone,
  onClose,
}: ProviderProps) {
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    createDeviceActionScheduleAsync,
  )
  const { required } = useFormValidation()
  const actionsValidated = actions.filter((action) => {
    return action.expectedOutcome === "dry"
  })
  const defaultActionId = actionsValidated[0]?.id ?? ""

  const form = useForm({
    defaultValues: {
      endDate: roundToNearestMinutes(addHours(new Date(), 4)).getTime(),
      hours: 1,
      minutes: 0,
      namedDeviceActionId: `${defaultActionId}`,
    },
  })

  React.useEffect(() => {
    if (typeof defaultActionId === "number") {
      form.setValue("namedDeviceActionId", `${defaultActionId}`)
    }
  }, [defaultActionId, form])
  return {
    actions: actionsValidated,
    form,
    isLoading,
    onClose,
    onSubmit: form.handleSubmit((formValues) => {
      if (typeof executionTimezone === "undefined") {
        throw new TypeError(`Execution Timezone is undefined`)
      }
      sendRequest({
        deviceId,
        executionTimeMs: new Date(formValues.endDate).getTime(),
        executionTimezone,
        isEnabled: true,
        namedDeviceActionId: Number.parseInt(formValues.namedDeviceActionId),
        shouldSyncSchedule: true,
      })
        .then(() => {
          onClose()
          return toasts.success()
        })
        .catch((error) => handleError(error, { toastMessage: "default" }))
    }),
    required,
  }
}

type ContextValue = ReturnType<typeof useShutdownTimerForm>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useShutdownTimerForm(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`ShutdownTimer Context must be used inside of provider`)
  }
  return ctx
}

export function ActionSelect() {
  const { actions, form, isLoading, required } = useContext()
  const { t } = useTranslation("createSchedule")
  const labelText: string = t("actionSelectLabel")

  return (
    <Controller
      control={form.control}
      name="namedDeviceActionId"
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState }) => {
        const errorMessage = fieldState.error?.message

        return (
          <FormControl.Provider
            isDisabled={isLoading}
            isInvalid={Boolean(errorMessage)}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Select
              label={labelText}
              selectedValue={value}
              options={actions.map(({ displayName, id, name }) => {
                return {
                  id,
                  label: displayName ?? name ?? `${id}`,
                  value: `${id}`,
                }
              })}
              onValueChange={onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}
