import React, { useEffect, useRef } from "react"

/**
 * A hook that provides the previous value of a prop or state.
 */
export function usePrevious<T>(value: T) {
  const ref = useRef<T>()

  const updatePrevious = React.useCallback((newVal: T) => {
    ref.current = newVal
  }, [])

  useEffect(() => {
    updatePrevious(value)
  }, [updatePrevious, value])
  return { updatePrevious, value: ref.current }
}
