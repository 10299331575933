import React from "react"
import { useTranslation } from "react-i18next"
import { Pressable, StyleSheet } from "react-native"

import { AppIcons, AppText, Box, Button, Heading, Row } from "./components"
import { Card } from "./components/Card"
import { SPACING } from "./components/theme/spacing"
import { loadEventsForDeviceAsync } from "./farmhq-api"
import * as Models from "./models"
import { getIsAdminModeEnabledFromState } from "./selectors"
import { SENSOR_NAMES } from "./sensor-configurations"
import { SENSOR_NAME_TO_EVENT_KEYS } from "./sensor-events"
import {
  useFormatSensorName,
  useFormatSensorValue,
  useSensorUnitLabel,
} from "./sensor-formatting"
import { isAdminOnlyField } from "./sensors"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"

import type {
  AcceptsChildren,
  ButtonProps,
  HelpContentStatic,
  RowProps,
} from "./components"
import type { SensorName } from "./sensor-configurations"
function useText() {
  return useTranslation("deviceEventHistory")
}

export const HELP_CONTENT: HelpContentStatic = {
  bodyText: i18n.t("deviceEventHistory:helpContentBodyText"),
  subject: "device_event_history_inspector",
}
interface ProviderProps {
  deviceId: string
}

const LIMIT = 10

const INDEX_MAX = LIMIT - 1

/**
 * Loads a set of device events in reverse chronological order to be
 * inspected by users.
 *
 * Lower indices are more recent than higher indices
 *
 */
function useDeviceEventHistoryInspector({ deviceId, ...rest }: ProviderProps) {
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [offset, setOffset] = React.useState(0)

  const [events, setEvents] = React.useState<Models.DeviceEvent[]>([])
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    loadEventsForDeviceAsync,
  )
  const selectedEvent = events[selectedIndex]

  /**
   * Get each sensor name that either exists in the configuration or in the event
   */
  const configuredSensors = useShallowEqualSelector((state) => {
    const config = Models.deviceConfiguration.selectById(state, deviceId)
    const result = SENSOR_NAMES.filter((sensorName) => {
      if (selectedEvent) {
        return Boolean(selectedEvent[sensorName])
      }
      if (config) {
        return Boolean(config[sensorName])
      }
      return false
    })
    result.sort()
    return result
  })

  const handleFetch = React.useCallback(() => {
    sendRequest({
      deviceId,
      limit: LIMIT,
      offset,
    })
      .then(({ items }) => setEvents(items))
      .catch((error) => handleError(error, { toastMessage: "default" }))
  }, [deviceId, handleError, offset, sendRequest])

  return {
    events,
    handleFetch,
    isLoadingEvents: isLoading,
    isNewerDisabled: isLoading || (offset === 0 && selectedIndex === 0),
    isOlderDisabled: isLoading || events.length < LIMIT,
    onPressNewer: () => {
      if (selectedIndex === 0) {
        // If i === 0, we are at the most recent event of the subset
        // Get the next 10 events (more recent)
        setOffset((prev) => prev - LIMIT)
        // Move to the oldest of the new subset
        setSelectedIndex(INDEX_MAX)
      } else {
        // Index goes lower (more recent)
        setSelectedIndex((prev) => prev - 1)
      }
    },
    onPressOlder: () => {
      if (selectedIndex === INDEX_MAX) {
        // Get 10 OLDER events
        setOffset((prev) => prev + LIMIT)
        // Start at the most recent event of the set
        setSelectedIndex(0)
      } else {
        // Index goes higher (older)
        setSelectedIndex((prev) => prev + 1)
      }
    },
    selectedEvent,
    sensorNames: configuredSensors,
    setOffset,
    setSelectedIndex,
    ...rest,
  }
}

type ContextValue = ReturnType<typeof useDeviceEventHistoryInspector>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useDeviceEventHistoryInspector(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(
      `EventInspector Context must be used inside of provider`,
    )
  }
  return ctx
}

const styles = StyleSheet.create({
  selectedEventCard: {
    marginBottom: SPACING.$2,
  },
  sensorReportCard: {
    marginBottom: SPACING.$2,
    marginTop: SPACING.$1,
  },
})

/**
 * Display formatted field values for the sensor with the given name
 */
export function SensorReport({
  sensorName,
}: {
  sensorName: SensorName
}): React.JSX.Element {
  const { t } = useText()
  const formatSensorName = useFormatSensorName()
  const formatValue = useFormatSensorValue()
  const getUnitLabel = useSensorUnitLabel()

  const { selectedEvent } = useContext()
  const sensorNameFormatted = formatSensorName(sensorName)
  const isAdmin = useRootSelector(getIsAdminModeEnabledFromState)
  const keys = [...SENSOR_NAME_TO_EVENT_KEYS[sensorName]].filter((key) => {
    if (isAdminOnlyField(key)) {
      return isAdmin
    }
    if (
      key === "sensorTriggeredUpdate" ||
      key === "statePrevious" ||
      key === "stateCurrent"
    ) {
      return false
    }
    return true
  })
  const sensorData = (selectedEvent ?? {})[sensorName]
  const [isExpanded, setIsExpanded] = React.useState(Boolean(sensorData))
  const fontSize = "$md"

  const sensorFields = sensorData ? (
    keys.map((key, i) => {
      const rawValue = sensorData[key as keyof typeof sensorData] ?? null
      const valueFormatted = formatValue(key, rawValue)
      const unitLabel = getUnitLabel(key)
      // SHOW DIVIDER
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const showDivider = i < keys.length - 1
      return (
        <Row key={key} id={key} justifyContent="space-between" my="$1">
          <AppText colorScheme="secondary" fontSize={fontSize}>
            {`${t(`${key}.displayName`, { ns: "sensorFields" })} ${unitLabel}`}
          </AppText>
          <AppText fontSize={fontSize}>
            {typeof valueFormatted === "string"
              ? `${valueFormatted} ${unitLabel}`
              : null}
          </AppText>
        </Row>
      )
    })
  ) : (
    <AppText>
      {t("noDataForSensorWithSensorName", {
        sensorName: sensorNameFormatted,
      })}
    </AppText>
  )
  const stateCurrent = sensorData?.stateCurrent
  return (
    <Pressable onPress={() => setIsExpanded(!isExpanded)}>
      <Card style={styles.sensorReportCard}>
        <Row>
          <Heading colorScheme="secondary" fontSize="$md" variant="h2">
            {sensorNameFormatted}
          </Heading>
          {isExpanded ? <AppIcons.ExpandLess /> : <AppIcons.ExpandMore />}

          {stateCurrent ? (
            <Row ml="auto">
              <Box mr="$2">
                <AppText fontSize={fontSize}>
                  {`${t(`stateCurrent.displayName`, { ns: "sensorFields" })}:`}
                </AppText>
              </Box>
              <AppText fontSize={fontSize} textTransform="capitalize">
                {formatValue("stateCurrent", stateCurrent)}
              </AppText>
            </Row>
          ) : null}
        </Row>
        <Box>{isExpanded ? sensorFields : null}</Box>
      </Card>
    </Pressable>
  )
}

/**
 * Display event metadata (timestamp, id etc)
 */
export function EventInfo(props: AcceptsChildren): React.JSX.Element | null {
  const { selectedEvent } = useContext()
  const { t } = useTranslation()

  return selectedEvent ? (
    <Card style={styles.selectedEventCard}>
      <Row>
        <Box mr="$2">
          <AppText colorScheme="secondary">{t("id")}</AppText>
        </Box>
        <AppText>{selectedEvent.id}</AppText>
      </Row>
      <Row id="device-event-timestamp">
        <Box mr="$2">
          <AppText colorScheme="secondary">{t("time")}</AppText>
        </Box>
        <AppText>
          {t("datetimeWithVal", {
            dateStyle: "long",
            ns: "common",
            timeStyle: "medium",
            val: new Date(selectedEvent.deviceEventTimestamp),
          })}
        </AppText>
      </Row>
      <Row>
        <Box mr="$2">
          <AppText colorScheme="secondary">{t("firmwareVersion")}</AppText>
        </Box>
        <AppText>{selectedEvent.firmwareVersion}</AppText>
      </Row>
      {props.children}
    </Card>
  ) : null
}

/**
 *
 */
export function CycleButtons(props: RowProps): React.JSX.Element {
  const { isNewerDisabled, isOlderDisabled, onPressNewer, onPressOlder } =
    useContext()
  const buttonProps: ButtonProps = {
    flexGrow: 1,
    variant: "primary",
  }

  return (
    <Row {...props}>
      <Box flexGrow={1} mr="$2">
        <Button
          {...buttonProps}
          IconComponent="ArrowLeft"
          id="older-btn"
          isDisabled={isOlderDisabled}
          text="older"
          onPress={onPressOlder}
        />
      </Box>
      <Box flexGrow={1} ml="$2">
        <Button
          {...buttonProps}
          IconComponent="ArrowRight"
          iconPosition="right"
          id="newer-btn"
          isDisabled={isNewerDisabled}
          text="newer"
          onPress={onPressNewer}
        />
      </Box>
    </Row>
  )
}

/**
 * Help Content
 */
