import { Platform } from "react-native"

import { getIsAdminModeEnabledFromState } from "./selectors"
import { useRootSelector } from "./useRootSelector"

import type { RootState } from "./root.reducer"
import type { AcceptsChildren } from "./components"
interface FilterOptions {
  fallbackElement?: React.JSX.Element
}
type Props = AcceptsChildren & FilterOptions
/**
 * If the filter return true, show the component. Otherwise, show the fallback
 */
export function makeFilterComponent({
  getIsVisible,
  ...defaultOptions
}: FilterOptions & {
  getIsVisible: (state: RootState) => boolean
}): React.FC<Props> {
  return function Filter({ children, fallbackElement }: Props) {
    const isVisible = useRootSelector(getIsVisible)
    if (isVisible) {
      return children as JSX.Element | null
    }
    return fallbackElement ?? defaultOptions.fallbackElement ?? null
  }
}

/**
 * Shown only in development mode
 */
export const DevOnly = makeFilterComponent({ getIsVisible: () => __DEV__ })
DevOnly.displayName = "DevOnly"

/**
 * Show only if admin
 */
export const AdminOnly = makeFilterComponent({
  getIsVisible: (state) => __DEV__ || getIsAdminModeEnabledFromState(state),
})
AdminOnly.displayName = "AdminOnly"

export function AndroidOnly({
  children,
}: AcceptsChildren): React.JSX.Element | null {
  if (Platform.OS === "android") {
    return children as JSX.Element | null
  }
  return null
}
