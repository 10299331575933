import React from "react"

import { useFieldColors } from "@fhq/app/useFieldColors"
import { Polygon as BasePolygon } from "@react-google-maps/api"

import type { PolygonProps } from "@react-google-maps/api"

export interface FieldPolygonProps
  extends Pick<PolygonProps, "onLoad" | "onMouseUp"> {
  path: google.maps.LatLngLiteral[]
  coloration?: "highlight" | "mute"
  fillOpacity?: number
  isEditable?: boolean
  isHidden?: boolean
  isPressable?: boolean
  onPress?: PolygonProps["onClick"]
  strokeOpacity?: number
  strokePosition?: google.maps.StrokePosition
  strokeWeight?: number
}

/**
 *
 */
export function FieldPolygon({
  coloration,
  fillOpacity = 0.05,
  isEditable,
  isHidden = false,
  isPressable = true,
  onLoad,
  onMouseUp,
  onPress,
  path,
  strokeOpacity,
  strokePosition,
  strokeWeight = 2,
}: FieldPolygonProps) {
  const { fillColor, strokeColor } = useFieldColors()

  const visible = !isHidden
  switch (coloration) {
    case "highlight": {
      strokeOpacity = (strokeOpacity ?? 0.5) + 0.2
      strokeWeight = 4
      break
    }
    case "mute": {
      strokeWeight = 1
      strokeOpacity = 0.35

      break
    }
    case undefined:
      break
  }

  const polygonOptions = React.useMemo((): google.maps.PolygonOptions => {
    return {
      clickable: isPressable,
      fillColor,
      fillOpacity,
      strokeColor,
      strokeOpacity,
      strokePosition,
      strokeWeight,
      visible,
    }
  }, [
    fillColor,
    fillOpacity,
    isPressable,
    strokeColor,
    strokeOpacity,
    strokePosition,
    strokeWeight,
    visible,
  ])

  if (path.length < 4) {
    return null
  }
  return (
    <BasePolygon
      editable={isEditable}
      options={polygonOptions}
      path={path}
      visible={visible}
      onClick={onPress}
      onLoad={onLoad}
      onMouseUp={onMouseUp}
    />
  )
}
