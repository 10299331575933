import format from "date-fns/format"
import * as Updates from "expo-updates"
import { Alert, NativeModules, Platform } from "react-native"

import { logger } from "./logger"
import { isValidNumber } from "./type-guards"

import type { Entries } from "type-fest"
export function objectEntries<T extends object>(target: T) {
  return Object.entries(target) as Entries<T>
}

export interface FormatDateOptions {
  firstWeekContainsDate?: number
  locale?: Locale
  useAdditionalDayOfYearTokens?: boolean
  useAdditionalWeekYearTokens?: boolean
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
}

export function formatDateSafely(
  value: Date | number | string | null | undefined,
  formatString = "Pp",
): string | undefined {
  if (typeof value === "string") {
    value = new Date(value)
  }
  if (typeof value !== "undefined" && value !== null) {
    try {
      return format(value, formatString)
    } catch (error) {
      logger.error(error)
      return undefined
    }
  }
  return undefined
}

export function getStandardDeviation(
  values: Array<number | null | undefined>,
): {
  getStandardDeviationsFromMean: (target: number) => number
  mean: number
  standardDeviation: number
} {
  const n = values.length
  const mean =
    values.reduce<number>((a, b) => {
      return a + (b ?? 0)
    }, 0) / n
  const standardDeviation = Math.sqrt(
    values.map((x) => (x ?? 0 - mean) ** 2).reduce((a, b) => a + b, 0) / n,
  )
  return {
    getStandardDeviationsFromMean: (target: number) =>
      (target - mean) / standardDeviation,
    mean,
    standardDeviation,
  }
}
export function dateInputToNumber(nextDate: Date | number | null | undefined) {
  let nextValue: number | undefined
  if (nextDate instanceof Date) {
    nextValue = nextDate.getTime()
  } else if (isValidNumber(nextDate)) {
    nextValue = nextDate
  }
  return nextValue
}

export function handleReloadApp() {
  const modules = NativeModules as
    | { DevSettings?: { reload?: () => unknown } }
    | null
    | undefined

  // Use dev modules to reload mobile in __DEV__

  // On web, we can control the window
  if (Platform.OS === "web") {
    window.location.reload()
  } else if (
    __DEV__ &&
    modules &&
    modules.DevSettings &&
    modules.DevSettings.reload
  ) {
    modules.DevSettings.reload()
  } else {
    // This also might work?
    Updates.reloadAsync().catch((err) => {
      logger.error(err)
      Alert.alert("Error", "Failed to reload app")
    })
  }
}
