import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { AppText, FormControl, SubmitButton } from "./components"
import { DataCollectionNoticeDialog } from "./DataCollectionNoticeDialog"
import { updateUserAccountAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import { isAdminUserEmail } from "./Internal"
import { getActiveUserEmailFromState } from "./selectors"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { AcceptsChildren, ButtonProps } from "./components"
export interface UserNameFormData {
  nameFamily: string
  nameGiven: string
}

interface ProviderProps {
  defaultValues?: UserNameFormData
  onSuccess?: () => void
}
function useUserNameForm({ defaultValues, ...props }: ProviderProps) {
  // TODO: Get rid of this once we identify the cause of the admin account's name getting cleared in the database
  const isAdminUser = useRootSelector((state) =>
    isAdminUserEmail(getActiveUserEmailFromState(state) ?? ""),
  )

  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    updateUserAccountAsync,
  )

  const form = useForm<UserNameFormData>({
    defaultValues:
      defaultValues ??
      (isAdminUser
        ? { nameFamily: "User", nameGiven: "Admin" }
        : { nameFamily: "", nameGiven: "" }),
  })
  return {
    form,
    isLoading,
    ...props,
    onSubmit: form.handleSubmit((values) => {
      sendRequest({
        nameFamily: values.nameFamily.trim(),
        nameGiven: values.nameGiven.trim(),
      })
        .then(() => {
          if (props.onSuccess) {
            props.onSuccess()
          }
          return toasts.success()
        })
        .catch((error) => {
          handleError(error, { toastMessage: "default" })
        })
    }),
  }
}

type ContextValue = ReturnType<typeof useUserNameForm>

const Context = React.createContext<ContextValue | undefined>(undefined)

/**
 *
 */
export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useUserNameForm(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`UserNameForm Context must be used inside of provider`)
  }
  return ctx
}

export function CollectionNotice() {
  const { t } = useTranslation("account")

  return (
    <DataCollectionNoticeDialog
      bodyText={t("userNameFormCollectionDisclaimer")}
      titleText={t("userNameFormCollectionTitle")}
    />
  )
}
/**
 *
 */
export function Instructions() {
  const { t } = useTranslation("account")
  return <AppText>{t("userNameFormInstructions")}</AppText>
}

/**
 *
 */
export function NameFirstInput() {
  const { form, isLoading } = useContext()
  const { t } = useTranslation("account")
  const { required } = useFormValidation()
  const labelText: string = t("nameFirstInputLabel")

  return (
    <Controller
      control={form.control}
      name="nameGiven"
      rules={{ required }}
      render={({ field: { onChange, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider
            isDisabled={isLoading}
            isInvalid={Boolean(errorMessage)}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Input
              {...field}
              autoComplete="name-given"
              placeholder={t("nameFirstInputPlaceholder")}
              textContentType="givenName"
              onChangeText={onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
export function NameLastInput() {
  const { form, isLoading } = useContext()
  const { t } = useTranslation("account")
  const labelText: string = t("nameLastInputLabel")
  const { required } = useFormValidation()
  return (
    <Controller
      control={form.control}
      name="nameFamily"
      rules={{ required }}
      render={({ field: { onChange, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider
            isDisabled={isLoading}
            isInvalid={Boolean(errorMessage)}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Input
              {...field}
              autoComplete="name-family"
              placeholder={t("nameLastInputPlaceholder")}
              textContentType="familyName"
              onChangeText={onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}
/**
 *
 */
export function SubmitBtn(props: ButtonProps) {
  const { isLoading, onSubmit } = useContext()
  return <SubmitButton {...props} isLoading={isLoading} onPress={onSubmit} />
}
