import React from "react"

import { AppText } from "./Text"
import { COLORS } from "./theme"

import type { AppTextProps } from "./Text"
/**
 * A form error message
 */
export function FormError({
  children,
  ...rest
}: AppTextProps): React.JSX.Element | null {
  if (Boolean(children)) {
    return (
      <AppText color={COLORS.$invalid} id="form-error" {...rest}>
        {children}
      </AppText>
    )
  }
  return null
}
