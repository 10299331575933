/* eslint-disable @typescript-eslint/consistent-type-imports */
import React from "react"

import { createAsyncThunk } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/core"

import * as AppStorage from "./async-storage"
import { getAuthSessionAsync } from "./auth.reducer"
import { loadActiveUserAsync } from "./farmhq-api"
import { TargetDatabaseName } from "./Internal"
import { disableLogging, logger, makeFileLogger } from "./logger"
import * as Models from "./models"
import {
  getAuthStatusFromState,
  getFetchStatusByName,
  getIsTermsOfServiceAcceptedFromState,
  getIsUserAuthenticatedFromState,
  getUserIsAdminFromState,
  getUserNameFamilyFromState,
  getUserNameGivenFromState,
} from "./selectors"
import {
  GetActionArgumentsType,
  GetResponseDataType,
  sendRequest,
} from "./send-request"
import { AnalyticsClient } from "./types"
import {
  getActiveFarmIdFromState,
  getActiveFarmLat,
  getActiveFarmLng,
  getActiveFarmNameFromState,
  getActiveFarmSubscriptionDueDate,
  getFarmAccountSubscriptionLockDate,
  getIsDemoModeActiveFromState,
  getRealFarmCountFromState,
} from "./user-farms.selectors"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { RootState, RootThunkConfig } from "./root.reducer"

// import type { AppView } from "./constants"

import type { FetchStatus } from "./Requests"

const fileLogger = makeFileLogger({
  fileName: "load-app.ts",
})

function isPending(
  fetchStatus: FetchStatus | undefined,
): fetchStatus is "pending" | undefined {
  if (fetchStatus === "pending" || typeof fetchStatus === "undefined") {
    return true
  }
  return false
}

export function getAppViewFromState(state: RootState) {
  const authStatus = getAuthStatusFromState(state)
  const readLocalStorage = getFetchStatusByName(state, "ReadLocalStorage")
  const loadUser = getFetchStatusByName(state, "LoadActiveUser")
  const isTosAccepted = getIsTermsOfServiceAcceptedFromState(state)
  const nameGiven = getUserNameGivenFromState(state)
  const nameFamily = getUserNameFamilyFromState(state)
  const numFarms = getRealFarmCountFromState(state)
  const isDemo = getIsDemoModeActiveFromState(state)
  const loadFarm = getFetchStatusByName(state, "LoadApp")
  const isInitialFarmLoaded = state.userFarms.isFarmLoaded
  const prepareDemo = getFetchStatusByName(state, "PrepareDemoEnvironment")
  const farmSubscriptionDueDate = getActiveFarmSubscriptionDueDate(state)
  const allPhoneNumbers = Models.phoneNumber.selectAll(state)
  const isAdminUser = getUserIsAdminFromState(state)
  const phoneNumberCount = allPhoneNumbers.length
  const verifiedPhoneNumbers = allPhoneNumbers.filter(
    (phoneNumber) => phoneNumber.isVerified,
  )

  // Checking user authentication
  if (isPending(authStatus)) {
    return "loading"
  }

  // User is not authenticated
  if (authStatus === "rejected") {
    return "auth"
  }

  // Waiting for user data & local storage
  if (isPending(readLocalStorage) || isPending(loadUser)) {
    return "loading"
  }

  // DB failed to return user data
  if (loadUser === "rejected") {
    return "load-error"
  }
  // load user is fulfilled...

  // User must accept terms of service
  if (!isTosAccepted) {
    return "accept-terms-of-service"
  }

  // Every user must input their name
  if (!Boolean(nameGiven) || !Boolean(nameFamily)) {
    return "userNameForm"
  }

  // Users must pay their subscription fee
  if (typeof farmSubscriptionDueDate !== "undefined") {
    const farmSubscriptionDueDateTimestamp = new Date(farmSubscriptionDueDate)

    const lockDate = getFarmAccountSubscriptionLockDate(
      farmSubscriptionDueDateTimestamp,
    )

    const now = new Date().getTime()
    if (lockDate.getTime() < now && !isAdminUser) {
      return "account-locked"
    }
  }
  // return "add-initial-phone-number"
  // Users must input their phone number
  if (state.userPhoneNumbers.promptStatus !== "skipped") {
    if (phoneNumberCount === 0) {
      return "add-initial-phone-number"
    }

    if (verifiedPhoneNumbers.length === 0) {
      return "verify-initial-phone-number"
    }
  }

  // Starting the "dashboard"
  const isLoadingInitialFarm = loadFarm === "pending" && !isInitialFarmLoaded

  // Show loading if we are loading the demo app
  if (prepareDemo === "pending") {
    return "loading"
  }
  // Show loading for initial farm (each time farm id changes)
  if (isLoadingInitialFarm) {
    return "loading"
  }
  // Database failed to load the farm app
  if (loadFarm === "rejected") {
    return "load-error"
  }
  // Dashboard app or demo?
  if (isDemo) {
    return "dashboard"
  }
  // User needs to connect their user account with a farm acccount
  if (numFarms === 0) {
    return "no-farms"
  }
  return "dashboard"
}

// export const getAppViewFromState = createSelector(
//   // authStatus
//   getAuthStatusFromState,
//   // read local storage
//   (state: RootState) => getFetchStatusByName(state, "ReadLocalStorage"),
//   // load the user
//   (state: RootState) => getFetchStatusByName(state, "LoadActiveUser"),
//   // is terms of service accepted?
//   getIsTermsOfServiceAcceptedFromState,
//   // first name
//   getUserNameGivenFromState,
//   // last name
//   getUserNameFamilyFromState,
//   // subtract the demo farm from the user's farm
//   getRealFarmCountFromState,
//   // is the demo going?
//   getIsDemoModeActiveFromState,
//   // Is the farm loading?
//   (state: RootState) => getFetchStatusByName(state, "LoadApp"),
//   // Is the first farm loaded
//   (state: RootState) => state.userFarms.isFarmLoaded,
//   // Is the demo loading?
//   (state: RootState) => getFetchStatusByName(state, "PrepareDemoEnvironment"),
//   // Subscription date
//   getActiveFarmSubscriptionDueDate,
//   (
//     authStatus,
//     readLocalStorage,
//     loadUser,
//     isTosAccepted,
//     nameGiven,
//     nameFamily,
//     numFarms,
//     isDemo,
//     loadFarm,
//     isInitialFarmLoaded,
//     prepareDemo,
//     farmSubscriptionDueDate,
//   ) => {

//     // Checking user authentication
//     if (isPending(authStatus)) {
//       return "loading"
//     }

//     // User is not authenticated
//     if (authStatus === "rejected") {
//       return "auth"
//     }

//     // Waiting for user data & local storage
//     if (isPending(readLocalStorage) || isPending(loadUser)) {
//       return "loading"
//     }

//     // DB failed to return user data
//     if (loadUser === "rejected") {
//       return "load-error"
//     }
//     // load user is fulfilled...
//     if (!isTosAccepted) {
//       return "accept-terms-of-service"
//     }

//     // Every user must input their name
//     if (!Boolean(nameGiven) || !Boolean(nameFamily)) {
//       return "userNameForm"
//     }

//     // Users must pay their subscription fee
//     if (typeof farmSubscriptionDueDate !== "undefined") {
//       const farmSubscriptionDueDateTimestamp = new Date(farmSubscriptionDueDate)

//       const lockDate = getFarmAccountSubscriptionLockDate(
//         farmSubscriptionDueDateTimestamp,
//       )

//       const now = new Date().getTime()
//       if (lockDate.getTime() < now) {
//         return "account-locked"
//       }
//     }

//     // Starting the "dashboard"
//     const isLoadingInitialFarm = loadFarm === "pending" && !isInitialFarmLoaded

//     // Show loading if we are loading the demo app
//     if (prepareDemo === "pending") {
//       return "loading"
//     }
//     // Show loading for initial farm (each time farm id changes)
//     if (isLoadingInitialFarm) {
//       return "loading"
//     }
//     // Database failed to load the farm app
//     if (loadFarm === "rejected") {
//       return "load-error"
//     }
//     // Dashboard app or demo?
//     if (isDemo) {
//       return "dashboard"
//     }
//     // User needs to connect their user account with a farm acccount
//     if (numFarms === 0) {
//       return "no-farms"
//     }
//     return "dashboard"

//   },
// )

export type AppView = ReturnType<typeof getAppViewFromState>

/**
 * Transform database values to app values here
 */
function handleActiveFarmResponse(response: Models.LoadActiveFarmResponse) {
  return {
    ...response,
    fields: response.fields.map((field) => Models.prepareField(field)),
  }
}

/**
 *
 */
export const loadActiveFarmAsync = createAsyncThunk<
  GetResponseDataType<"LoadApp">,
  GetActionArgumentsType<"LoadApp">,
  RootThunkConfig
>("LoadApp", async ({ activeFarmId }, { extra }) => {
  const values = await sendRequest("LoadApp", {
    actionArguments: { activeFarmId },
    activeFarmId,
    ...extra,
  })

  // fill default values
  const responseData = handleActiveFarmResponse(values)
  if (responseData.errorCode === "NO FARM ASSOCIATION") {
    fileLogger.error("Clearing stored farm ids...")
    await AppStorage.clearStoredFarmIds()
  }

  return responseData
})

/**
 * Checks auth, reads local storage, loads active user on auth success,
 * loads active farm when farm id changes.
 */
export function useAppLoader({
  analyticsClient,
  targetDatabaseName,
}: {
  analyticsClient: AnalyticsClient
  targetDatabaseName: TargetDatabaseName
}) {
  const dispatch = useRootDispatch()
  const isAuthenticated = useRootSelector(getIsUserAuthenticatedFromState)
  const isAdminUser = useRootSelector(getUserIsAdminFromState)

  const farmName = useRootSelector(getActiveFarmNameFromState)
  const farmLng = useRootSelector(getActiveFarmLng)
  const farmLat = useRootSelector(getActiveFarmLat)

  /**
   * Check user auth and read device's local storage
   */
  React.useEffect(() => {
    // On load
    Promise.all([
      dispatch(getAuthSessionAsync()),
      dispatch(AppStorage.readLocalStorageAsync()),
    ]).catch((error) => logger.error(error))
  }, [dispatch])

  /**
   * Disable logging unless the user is admin
   */
  React.useEffect(() => {
    Sentry.setTag("is_admin_user", isAdminUser)
    if (!isAdminUser && !__DEV__ && targetDatabaseName === "PROD") {
      disableLogging()
    }
  }, [isAdminUser, targetDatabaseName])
  /**
   * Load user data after user authenticates
   */
  React.useEffect(() => {
    // On auth success
    if (isAuthenticated) {
      dispatch(loadActiveUserAsync())
        .unwrap()
        .catch((error) => {
          fileLogger.error("Load active user failed")
          return fileLogger.error(error)
        })
    }
  }, [dispatch, isAuthenticated])
  const activeFarmId = useRootSelector(
    (state) => state.userFarms.demoFarmId ?? getActiveFarmIdFromState(state),
  )
  const handleLoadFarm = React.useCallback(() => {
    // On farm id changed

    if (isAuthenticated && typeof activeFarmId === "number") {
      logger.info(`Loading farm ${activeFarmId}`)
      dispatch(loadActiveFarmAsync({ activeFarmId })).catch((error) => {
        fileLogger.error(error)
      })
    }
  }, [activeFarmId, dispatch, isAuthenticated])
  /**
   *  Load data for user's active farm
   */
  React.useEffect(handleLoadFarm, [handleLoadFarm])

  /**
   * Analytics and tags
   */
  React.useEffect(() => {
    Sentry.setTag("active_farm_id", activeFarmId)
    Sentry.setTag("active_farm_name", farmName)
    if (typeof activeFarmId === "number") {
      analyticsClient
        .group(`${activeFarmId}`, {
          id: `${activeFarmId}`,
          latitude: farmLat,
          longitude: farmLng,
          name: farmName,
        })
        .catch((error) => {
          Sentry.captureException(error)
        })
    }
  }, [activeFarmId, analyticsClient, farmLat, farmLng, farmName])

  return {
    activeFarmId,
    appView: useRootSelector(getAppViewFromState),
    handleLoadFarm,
  }
}
