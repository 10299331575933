import React from "react"
import { Animated, Platform } from "react-native"

import { Box } from "./Box"
import { COLORS } from "./theme"
import { useIsDarkMode } from "./ThemeProvider"

import type { BoxProps } from "./Box"
function canUseDom() {
  if (typeof window !== "undefined" || Platform.OS !== "web") {
    return true
  }
  return false
}

const endColor = COLORS.$gray[300]
const speed = 3000
const fadeDuration = 0.5

export interface SkeletonProps extends BoxProps {
  isLoading: boolean
}

export function Skeleton({
  children,
  isLoading,
  ...resolvedProps
}: SkeletonProps) {
  const isDomUsable = canUseDom()
  // const { children, startColor, endColor, ...resolvedProps } =
  //   usePropsResolution("Skeleton", props)
  // Setting blink Animation
  const blinkAnim = React.useRef(new Animated.Value(0)).current

  const isDark = useIsDarkMode()
  const backgroundColor = isDark
    ? COLORS.$background.dark
    : COLORS.$background.light

  // Generating blink animation in a sequence
  React.useEffect(() => {
    //Check if window is loaded
    if (isDomUsable) {
      const blink = Animated.sequence([
        Animated.timing(blinkAnim, {
          duration: fadeDuration * 10000 * (1 / speed),
          toValue: 1,
          useNativeDriver: Platform.OS !== "web",
        }),
        Animated.timing(blinkAnim, {
          duration: fadeDuration * 10000 * (1 / speed),
          toValue: 0,
          useNativeDriver: Platform.OS !== "web",
        }),
      ])
      Animated.loop(blink).start()
    }
  }, [blinkAnim, isDomUsable, resolvedProps])

  const skeletonStyle = {
    skeleton: {
      backgroundColor,
      height: "100%",
      opacity: blinkAnim,
      width: "100%", // Bind opacity to animated value
    },
  }

  if (isLoading) {
    return (
      <Box bg={endColor} {...resolvedProps}>
        <Animated.View style={skeletonStyle.skeleton} />
      </Box>
    )
  }
  return children as React.JSX.Element
}
