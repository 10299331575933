import React from "react"
import { KeyboardAvoidingView as Base, Platform } from "react-native"

import { testIds } from "./test-id"

import type { WithOptionalTestId } from "./test-id"
import type { KeyboardAvoidingViewProps } from "react-native"

/**
 * Encapsulates a platform-aware keyboard avoiding view.
 */
export function KeyboardAvoidingView({
  id,
  ...rest
}: WithOptionalTestId<KeyboardAvoidingViewProps>) {
  return (
    <Base
      {...testIds(id)}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      {...rest}
    />
  )
}
