import React from "react"
import { StyleSheet } from "react-native"

import { Auth, FarmHqLogo, isTruthyString } from "@fhq/app"
import { AppText, Box, ScrollView } from "@fhq/app/components"
import { SIZES } from "@fhq/app/components/theme"

import { Environment } from "./base"
import PageContainer from "./PageContainer"

import type { AcceptsChildren } from "@fhq/app/components"

import type { TestIdProps } from "@fhq/app"

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
  },
  logo: {
    height: SIZES.$32,
    width: "100%",
  },
})

/**
 *
 */
export function AuthLayout({
  id,
  ...props
}: AcceptsChildren & TestIdProps & { title?: string }): React.JSX.Element {
  return (
    <ScrollView contentContainerStyle={styles.contentContainer}>
      <PageContainer preserveId flex={1} id={id} maxW="$lg" mx="auto" w="$full">
        <Box flex={1} mt="$4" mx="auto" px="$4" w="$full">
          <Box position="relative">
            <FarmHqLogo
              name="lockup-inline.png"
              resizeMode="center"
              style={styles.logo}
            />
            {Environment.targetDatabaseName === "PROD" ? null : (
              <Box
                bottom="$2"
                left="$1/2"
                position="absolute"
                right="$1/2"
                w="$full"
              >
                <AppText colorScheme="secondary">
                  {Environment.targetDatabaseName}
                </AppText>
              </Box>
            )}
          </Box>
          {isTruthyString(props.title) ? (
            <Box mb="$4">
              <Auth.PageTitle>{props.title}</Auth.PageTitle>
            </Box>
          ) : null}
          {props.children}
        </Box>
      </PageContainer>
    </ScrollView>
  )
}
