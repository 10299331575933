import React from "react"

import { AppText } from "./components"

import type { AppTextProps, NoChildren } from "./components"
import type { EnvironmentProps } from "./PrepareDemoButton"

export function EnvironmentIndicator({
  targetDatabaseName,
  ...rest
}: NoChildren<AppTextProps> &
  Pick<EnvironmentProps, "targetDatabaseName">): React.JSX.Element | null {
  if (targetDatabaseName === "PROD" && !__DEV__) {
    return null
  }

  return (
    <AppText colorScheme="secondary" {...rest}>
      {targetDatabaseName}
    </AppText>
  )
}
