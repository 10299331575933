import React from "react"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import { AppIcons, Box, FormControl, ICON_SIZES, Row } from "./components"
import { Button, SubmitButton } from "./components/Button"
import { AppText } from "./components/Text"
import { COLORS } from "./components/theme"
import {
  createPhoneNumberInternationalAsync,
  generateSmsVerificationCodeAsync,
} from "./farmhq-api"
import { useIsPending } from "./selectors"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import {
  getCanUserAddPhoneNumbers,
  parsePhoneNumberInput,
} from "./user-phone-numbers.reducer"
import { useRootSelector } from "./useRootSelector"

import type { HelpContentStatic } from "./components"
import type { ButtonProps } from "./components/Button"
import type { PhoneNumber } from "./models"

import type { ParsedPhoneNumber } from "./user-phone-numbers.reducer"
export const HELP_CONTENT: HelpContentStatic = {
  bodyText: i18n.t("phoneNumbers:addPhoneNumberHelpBodyText"),
  subject: "add_phone_number",
  titleText: i18n.t("phoneNumbers:addPhoneNumberHelpTitle"),
}

/**
 * User with phone number count under
 * the limit can press this to access
 * AddPhoneNumber form
 */
export function AddPhoneNumberButton({
  onPress,
  ...rest
}: ButtonProps): React.JSX.Element {
  const { t } = useTranslation("phoneNumbers")
  return (
    <Box ml="auto" mt="$2" my="$2">
      <Button
        {...rest}
        IconComponent="Add"
        id="add-phone-number-btn"
        size="md"
        text={t("addPhoneNumberLink")}
        onPress={onPress}
      />
    </Box>
  )
}

export interface AddPhoneNumberFormProps {
  onCancel: (() => void) | null
  onSuccess: ((response: Pick<PhoneNumber, "id" | "serialized">) => void) | null
  onTooManyNumbers: () => void
  defaultValue?: string
}

/**
 * TODO:DOC
 */
export function AddPhoneNumberForm({
  defaultValue,
  onCancel,
  onSuccess,
  onTooManyNumbers,
}: AddPhoneNumberFormProps): React.JSX.Element {
  const { t } = useTranslation("phoneNumbers")
  const canAdd = useRootSelector(getCanUserAddPhoneNumbers)

  const { dispatch, handleError, sendRequest } = useBackendRequest(
    createPhoneNumberInternationalAsync,
  )
  const isLoading = useIsPending(
    "CompleteSmsVerification",
    "GenerateSmsVerificationCode",
    "CreatePhoneNumberInternational",
  )
  const [isTyping, setIsTyping] = React.useState(false)
  let initialValue = ""
  if (__DEV__ && typeof defaultValue === "string") {
    initialValue = defaultValue
  }
  const [numberInput, setNumberInput] = React.useState(initialValue)
  const [parsedNumber, setParsedNumber] = React.useState<ParsedPhoneNumber>({
    isValid: false,
  })

  const isInvalid = !isTyping && numberInput.length > 0 && !parsedNumber.isValid

  /**
   * When user stops typing for a full second, show error if
   * the phone number is invalid.
   */
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTyping(false)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [numberInput])

  const handleSubmit = async () => {
    try {
      if (!isInvalid) {
        const phoneNumberE164Str = parsedNumber.phoneNumber?.number
        if (typeof phoneNumberE164Str === "undefined") {
          throw new TypeError("Undefined value for phone number")
        }
        if (!canAdd) {
          return onTooManyNumbers()
        }
        const phoneNumber = await sendRequest({ phoneNumberE164Str }).then(
          (response) => {
            if (onSuccess) {
              onSuccess(response)
            }
            return response
          },
        )

        // When the phone number is created, send second request to generate
        // SMS verification code
        await dispatch(
          generateSmsVerificationCodeAsync({
            upnId: phoneNumber.id,
          }),
        )
      }
    } catch (error) {
      return handleError(error, {
        toastMessage: true,
      })
    }
    return undefined
  }

  const labelText: string = t("addPhoneNumberInputLabel", {
    ns: "phoneNumbers",
  })
  return (
    <React.Fragment>
      <FormControl.Provider id="phoneNumber" isInvalid={isInvalid}>
        <FormControl.Label>{labelText}</FormControl.Label>
        <Box my="$1">
          <FormControl.Input
            autoComplete="tel"
            keyboardType="phone-pad"
            placeholder={t("addPhoneNumberInputPlaceholder")}
            returnKeyType="done"
            textContentType="telephoneNumber"
            value={numberInput}
            onChangeText={(nextValue): void => {
              setNumberInput(nextValue)
              setIsTyping(true)
              setParsedNumber(parsePhoneNumberInput(nextValue))
            }}
          />
        </Box>
        <FormControl.ErrorMessage>
          {isInvalid ? t("addPhoneNumberInvalidNumber") : null}
        </FormControl.ErrorMessage>
      </FormControl.Provider>
      {typeof numberInput === "string" && parsedNumber.isValid ? (
        <Row alignItems="center">
          <Box mr="$2">
            <AppIcons.Success
              color={COLORS.$success[500]}
              size={ICON_SIZES.$xl}
            />
          </Box>
          <AppText fontSize="$lg">
            {`${parsedNumber.phoneNumber.country}: +${parsedNumber.phoneNumber.countryCallingCode} ${parsedNumber.phoneNumber.nationalFormat}`}
          </AppText>
        </Row>
      ) : null}
      <Box mt="$4">
        {onCancel ? (
          <ActionButtons
            isLoading={isLoading}
            isSubmitDisabled={isInvalid || numberInput.length === 0}
            onPressCancel={onCancel}
            onPressSubmit={handleSubmit}
          />
        ) : (
          <SubmitButton
            isDisabled={isInvalid}
            isLoading={isLoading}
            onPress={handleSubmit}
          />
        )}
      </Box>
    </React.Fragment>
  )
}
