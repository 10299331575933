import { StyleSheet } from "react-native"

import { FarmHqLogo, UserNameForm } from "@fhq/app"
import { Box, Card } from "@fhq/app/components"
import { SIZES } from "@fhq/app/components/theme"

const styles = StyleSheet.create({
  logo: {
    height: SIZES.$24,
    width: SIZES.$32,
  },
})

export function UserNameFormPage() {
  return (
    <UserNameForm.Provider>
      <Box m="auto" my="$16" px="$4">
        <Card>
          <Box maxW="$sm">
            <FarmHqLogo name="lockup-inline.png" style={styles.logo} />
            <Box my="$4">
              <UserNameForm.Instructions />
            </Box>
            <Box mb="$4">
              <UserNameForm.NameFirstInput />
            </Box>
            <UserNameForm.NameLastInput />
            <Box mt="$8">
              <UserNameForm.SubmitBtn />
            </Box>
          </Box>
          <Box my="$4">
            <UserNameForm.CollectionNotice />
          </Box>
        </Card>
      </Box>
    </UserNameForm.Provider>
  )
}
