import React from "react"
import { View } from "react-native"

import { ActionButtons } from "./ActionButtons"
import * as CreateConfiguration from "./CreateConfiguration"
import {
  ChooseSensorPort,
  ChooseSprinklerType,
  ChooseSwitchType,
  MagnetSelect,
  PressureSliderConnected,
  SensorTextInput,
} from "./DeviceSensorFields"
import { ReelSpeedSliders } from "./ReelSpeedSlider"
import { isMutableSensorName } from "./sensors"
import { UpdateConfigurationWarning } from "./UpdateConfigurationWarning"

import type { SensorConfigKey, SensorName } from "./sensor-configurations"
/**
 * Show a form for the field that the user is currently editing
 */
function SelectedSensorField({
  fieldName,
  selectedSensorName,
}: {
  fieldName: SensorConfigKey
  selectedSensorName: SensorName
}) {
  switch (fieldName) {
    case "thresholdPsiLower":
    case "thresholdPsiUpper": {
      return <PressureSliderConnected />
    }
    case "sprinklerType": {
      return <ChooseSprinklerType />
    }
    case "switchType": {
      return <ChooseSwitchType sensorName="pressureSwitch" />
    }
    case "sensorPort": {
      if (isMutableSensorName(selectedSensorName)) {
        return <ChooseSensorPort sensorName={selectedSensorName} />
      }
      return null
    }
    case "nMagnets": {
      if (selectedSensorName === "reel" || selectedSensorName === "wheel") {
        return <MagnetSelect sensorName={selectedSensorName} />
      }
      throw new TypeError(`Invalid sensor name for field ${fieldName}`)
    }
    case "linearSpeedMmHMin":
    case "linearSpeedMmHMax": {
      return <ReelSpeedSliders />
    }
    case "voltageSlope":
    case "voltageIntercept":
    case "voltageThresholdLowMv":
    case "gpsType":
    case "ioPin":
    case "calibrationIntercept":
    case "calibrationSlope":
    case "diameterMm":
    case "hoseDiameterMm":
    case "installedOrientation":
    case "milliRpmFast":
    case "milliRpmSlow":
    case "mlPerPulse":
    case "nNozzles":
    case "nozzleDiameterMm":
    case "nWrapsOuterLayer":
    case "outerHoseWrapRadiusMm":
    case "swathWidthMm":
    case "threshold":
    case "i2CAddress":
    case "maxTrackingPercentage":
    case "minTrackingPercentage":
    case "widthMm": {
      return (
        <SensorTextInput
          fieldName={fieldName}
          sensorName={selectedSensorName}
          _input={{
            keyboardType: "numeric",
          }}
        />
      )
    }
  }
}

/**
 * Show a form for the field that the user is currently editing
 */
export function DeviceSensorFieldEditor({
  fieldName,
  onClose,
  selectedSensorName,
}: {
  fieldName: SensorConfigKey
  onClose: () => void
  selectedSensorName: SensorName
}): React.JSX.Element {
  const { isLoading, onSubmit } = CreateConfiguration.useContext()
  return (
    <View>
      <SelectedSensorField
        fieldName={fieldName}
        selectedSensorName={selectedSensorName}
      />
      <ActionButtons
        isLoading={isLoading}
        my="$4"
        onPressCancel={onClose}
        onPressSubmit={onSubmit}
      />
      <UpdateConfigurationWarning />
    </View>
  )
}
