import React from "react"

import {
  Geo,
  makePlotArrow,
  makePlotSwath,
  Models,
  useLinearPathParams,
  useRootSelector,
} from "@fhq/app"
import { COLORS } from "@fhq/app/components/theme"
import { Marker, Polygon, Polyline } from "@react-google-maps/api"
import { withErrorBoundary } from "@sentry/react"

import { BASE_SWATH_OPTIONS } from "./base"
import { pointTransformer } from "./map-utils"

/**
 * LinearPathVisualization
 */
function LinearPathVisualization({
  isClickable,
  showParkingSpots,
  ...props
}: Models.DeviceActivity & {
  isClickable: boolean
  showParkingSpots: boolean
}) {
  const params = useLinearPathParams(props)

  const plotTheArrow = makePlotArrow(pointTransformer)
  // Arrow from midpoint
  const { arrowHeadPath, arrowLinePath } = plotTheArrow(params)
  const plotSwath = makePlotSwath(pointTransformer)
  const corners = plotSwath(params)

  const swathOptions: google.maps.PolygonOptions = {
    ...BASE_SWATH_OPTIONS,
    clickable: isClickable,
  }
  const arrowOptions: google.maps.PolylineOptions = {
    clickable: isClickable,
    strokeColor: COLORS.$reelRunSwath.arrow,
  }
  const configuration = useRootSelector((state) =>
    Models.deviceConfiguration.selectById(state, props.device),
  )
  return (
    <React.Fragment>
      {configuration?.linearPath?.map((stop, index) => {
        const latLng = Geo.point(stop.coordinates)?.toGmaps()
        if (latLng && showParkingSpots) {
          return (
            <Marker
              key={stop.label ?? index}
              position={latLng}
              label={
                typeof stop.label === "string"
                  ? { color: COLORS.$white, text: stop.label }
                  : undefined
              }
            />
          )
        }
        return null
      })}
      {corners.length >= 3 ? (
        <Polygon options={swathOptions} path={corners} />
      ) : null}
      <Polyline options={arrowOptions} path={arrowLinePath} />
      <Polyline options={arrowOptions} path={arrowHeadPath} />
    </React.Fragment>
  )
}

export default withErrorBoundary(LinearPathVisualization, {})
