import { createAsyncThunk } from "@reduxjs/toolkit"

import { REQUEST_BLOCKED_IN_DEMO } from "./constants"
import * as Geo from "./geo"
import { Urls } from "./Internal"
import { makeFileLogger } from "./logger"
import { isFalsy } from "./type-guards"
import { getIsDemoModeActiveFromState } from "./user-farms.selectors"

import type { RootState } from "./root.reducer"

const logger = makeFileLogger({ fileName: "geocoding.ts" })

// export const FarmStatusCodes = {
//   NO_DEVICES: "NO DEVICES",
//   NO_FIELDS: "NO FIELDS",
//   USER_CREATED_FARM: "USER CREATED FARM",
//   USER_JOINED_FARM: "USER JOINED FARM",
// } as const
export type FarmStatusCode =
  | "NO DEVICES"
  | "NO FIELDS"
  | "USER CREATED FARM"
  | "USER JOINED FARM"
export interface FarmAccountProperties {
  address_line_1: string
  city: string
  country: string
  name: string
  postalCode: string
  region: string
  address_line_2?: string | null
  gpsLocation?: Geo.PointGeoJson | null
}

export interface FarmAccount extends FarmAccountProperties {
  id: number
  isDemo: boolean | null | undefined
  createDate?: string
  createdByUserId?: string
  deactivateDate?: string
  deactivatedByUserId?: string | null
  isActive?: boolean
  subscriptionRenewalDueDate?: string | null
}

export function formatFarmAddress(
  farmData: Omit<Partial<FarmAccountProperties>, "gpsLocation">,
): string {
  return `${farmData.address_line_1 ?? ""} ${farmData.address_line_2 ?? ""}, ${
    farmData.city ?? ""
  }, ${farmData.region?.toLocaleUpperCase() ?? ""}, ${
    farmData.country?.toLocaleUpperCase() ?? ""
  }, ${farmData.postalCode ?? ""}` as const
}

/**
 * Used to determine farm gps location on creation
 * @param googleMapsApiKey
 * @param farm
 */
export async function geocodeFarmAddress(
  farm: Omit<FarmAccountProperties, "gpsLocation">,
): Promise<Geo.PointGeoJson | undefined> {
  try {
    if (isFalsy(farm.address_line_2)) {
      farm.address_line_2 = ""
    }

    const address = formatFarmAddress(farm)
      // replace all spaces with "+"
      .split(" ")
      .filter((element) => element !== ``)
      .join(`+`)

    const response = await fetch(Urls.getReverseGeocode(address))

    const asJson = (await response.json()) as google.maps.GeocoderResponse
    const location = asJson.results[0]?.geometry.location as unknown as
      | google.maps.LatLngLiteral
      | undefined

    logger.info(`Geocode result: ${JSON.stringify(location ?? "null")}`)
    if (location) {
      return Geo.point(location)?.toJson()
    }
    return undefined
  } catch (error) {
    logger.error(error)
    throw error
  }
}
export const geocodeFarmAddressAsync = createAsyncThunk(
  "GeocodeFarmAddress",
  async (
    farm: FarmAccountProperties & { id?: number },
    { getState, rejectWithValue },
  ) => {
    try {
      const state = getState() as RootState
      if (getIsDemoModeActiveFromState(state)) {
        return rejectWithValue(REQUEST_BLOCKED_IN_DEMO)
      }
      const location = await geocodeFarmAddress(farm)
      return {
        ...farm,
        gpsLocation: location,
      }
    } catch (error) {
      logger.error("GeocodeFarmAddress", error)
      throw error
    }
  },
)
