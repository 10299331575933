import React from "react"

import { loadLastEventForDeviceAsync } from "./farmhq-api"
import * as Models from "./models"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { DeviceIdProps } from "./types"
const INTERVAL_SECONDS = 2
export function ForceUpdateDispatcher({ deviceId }: DeviceIdProps) {
  const dispatch = useRootDispatch()
  const previousEventId = useRootSelector(
    (state) => Models.deviceEventLast.selectById(state, deviceId)?.id ?? null,
  )
  const handleError = useErrorHandler()
  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        loadLastEventForDeviceAsync({ deviceId, previousEventId }),
      ).catch((error) => {
        handleError(error)
      })
    }, INTERVAL_SECONDS * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [deviceId, dispatch, handleError, previousEventId])
  return null
}
