import { captureException } from "@sentry/core"
import * as turf from "@turf/turf"

import { CENTER_PIVOT_ARROW_PARAMS } from "./CenterPivotPathVisualization"
import * as Geo from "./geo"
import * as Models from "./models"
import { useRootSelector } from "./useRootSelector"

export function useLinearPathParams(deviceActivity: Models.DeviceActivity) {
  const locations = deviceActivity.gpsLocations ?? []
  const configuration = useRootSelector((state) => {
    return Models.deviceConfiguration.selectById(state, deviceActivity.device)
  })

  const pathWidthMm = configuration?.linearSpanWidthMm
  const spanHeading = configuration?.linearSpanHeadingDegrees

  const startPoint = Geo.point(locations[0])
  const endPoint = Geo.point(locations[locations.length - 1])

  const deviceHeading =
    deviceActivity.gpsHeadingsDegrees?.[
      deviceActivity.gpsHeadingsDegrees.length - 1
    ]
  return { deviceHeading, endPoint, pathWidthMm, spanHeading, startPoint }
}

type LinearPathParams = ReturnType<typeof useLinearPathParams>
/**
 * Plot swath on map
 */
export function makePlotSwath<P extends Geo.AnyLatLng>(
  transformPoint: Geo.PointTransformer<P>,
) {
  return ({
    endPoint,
    pathWidthMm,
    spanHeading,
    startPoint,
  }: LinearPathParams) => {
    //
    const corners: P[] = []
    const startLatLng = transformPoint(startPoint)
    const endLatLng = transformPoint(endPoint)

    if (
      typeof pathWidthMm === "number" &&
      typeof spanHeading === "number" &&
      startPoint &&
      endPoint &&
      startLatLng &&
      endLatLng
    ) {
      corners.push(startLatLng)
      corners.push(endLatLng)

      const distanceMeters = pathWidthMm / 1000

      const endProjected = transformPoint(
        endPoint.project({
          direction: spanHeading,
          distanceMeters,
        }),
      )

      const startProjected = transformPoint(
        startPoint.project({
          direction: spanHeading,
          distanceMeters,
        }),
      )

      if (startProjected && endProjected) {
        corners.push(endProjected)
        corners.push(startProjected)
      }
    }
    return corners
  }
}

/**
 * Plot arrow on map
 */
export function makePlotArrow<P extends Geo.AnyLatLng>(
  transformPoint: Geo.PointTransformer<P>,
) {
  return ({
    deviceHeading,
    endPoint,
    pathWidthMm,
    spanHeading,
  }: LinearPathParams) => {
    //
    const arrowLinePath: P[] = []
    const arrowHeadPath: P[] = []

    try {
      if (
        typeof deviceHeading === "number" &&
        typeof pathWidthMm === "number" &&
        typeof spanHeading === "number" &&
        endPoint
      ) {
        // Arrow should at the end of the swath
        const arrowStart = transformPoint(
          turf.destination(endPoint, pathWidthMm / 2, spanHeading, {
            units: "millimeters",
          }).geometry,
        )

        // Arrow should end at an arbitrary distance past the device on its path
        const swathEnd = Geo.point(
          turf.destination(endPoint.coordinates, pathWidthMm / 2, spanHeading, {
            units: "millimeters",
          }).geometry,
        )

        // Extend the arrow past the end of the swath
        const arrowEnd = swathEnd?.project({
          direction: deviceHeading,
          distanceMeters: CENTER_PIVOT_ARROW_PARAMS.baseLengthMeters,
        })

        const arrowEndLatLng = transformPoint(arrowEnd)
        if (arrowStart && arrowEndLatLng) {
          arrowLinePath.push(arrowStart)
          arrowLinePath.push(arrowEndLatLng)
        }

        const arrowHeadLeft = transformPoint(
          arrowEnd?.project({
            direction: deviceHeading + CENTER_PIVOT_ARROW_PARAMS.headAngle,
            distanceMeters: CENTER_PIVOT_ARROW_PARAMS.headSizeMeters,
          }),
        )

        const arrowHeadRight = transformPoint(
          arrowEnd?.project({
            direction: deviceHeading - CENTER_PIVOT_ARROW_PARAMS.headAngle,
            distanceMeters: CENTER_PIVOT_ARROW_PARAMS.headSizeMeters,
          }),
        )

        if (arrowHeadLeft && arrowHeadRight && arrowEndLatLng) {
          arrowHeadPath.push(arrowHeadLeft)
          arrowHeadPath.push(arrowEndLatLng)
          arrowHeadPath.push(arrowHeadRight)
        }
      }
    } catch (e) {
      captureException(e, {
        tags: {
          source: "makePlotArrow",
        },
      })
    }
    return { arrowHeadPath, arrowLinePath }
  }
}
