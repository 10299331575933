import { createSelector } from "reselect"

import {
  Geo,
  getActiveFarmFromState,
  getMapTypeIdFromState,
  Models,
} from "@fhq/app"

export const getMapTypeValueFromState = createSelector(
  getMapTypeIdFromState,
  (id) => {
    return Geo.MapTypeIds[id].web
  },
)
export const getAllFieldGmaps = createSelector(
  Models.field.selectAll,
  (fields) => {
    return fields.map((field) => {
      const polygon = Geo.polygon(field.polygon)
      return {
        ...field,
        center: polygon?.getCenter()?.toGmaps(),
        path: polygon?.toGmaps(),
      }
    })
  },
)
export const getFarmLocationGmaps = createSelector(
  getActiveFarmFromState,
  (farm): google.maps.LatLngLiteral | undefined => {
    if (farm?.gpsLocation) {
      return Geo.point(farm.gpsLocation.coordinates)?.toGmaps()
    }
    return undefined
  },
)
