import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import {
  AlertCard,
  AppIcons,
  AppText,
  Box,
  FormControl,
  IconButton,
} from "./components"
import { Row } from "./components/Row"
import { COLORS, SIZES } from "./components/theme"
import { submitAppFeatureFeedbackAsync } from "./farmhq-api"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { AcceptsChildren } from "./components"
const FEATURE_NAME = "app"
interface ProviderProps {
  onClose: () => void
}

function useAppFeedback(props: ProviderProps) {
  const [isSuccess, setIsSuccess] = React.useState(false)
  const isLiked = useRootSelector((state) => state.appState.isAppLiked)

  const form = useForm({
    defaultValues: {
      feedbackText: "",
    },
  })
  const { t } = useTranslation("feedback")

  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    submitAppFeatureFeedbackAsync,
  )
  return {
    ...props,
    form,
    isLiked,
    isLoading,
    isSuccess,
    onSubmit: form.handleSubmit((values) => {
      sendRequest({
        ...values,
        featureName: FEATURE_NAME,
        isLiked,
      })
        .then(() => {
          toasts.success(t("successMessage"))
          return setIsSuccess(true)
        })
        .catch(handleError)
    }),
    setIsSuccess,
    t,
  }
}

type ContextValue = ReturnType<typeof useAppFeedback>

const Context = React.createContext<ContextValue | undefined>(undefined)

function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): JSX.Element | null {
  const value = useAppFeedback(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`Context must be used inside of provider`)
  }
  return ctx
}
function LikeOrDislikeButtons(): React.JSX.Element {
  const { isLiked, t } = useContext()
  const { handleError, sendRequest } = useBackendRequest(
    submitAppFeatureFeedbackAsync,
  )

  const makeChangeHandler = (nextValue: boolean) => {
    return () => {
      sendRequest({
        featureName: FEATURE_NAME,
        feedbackText: undefined,
        isLiked: nextValue,
      }).catch((error) => handleError(error, { toastMessage: "default" }))
    }
  }
  return (
    <Row mb="$2">
      <AppText>
        {t("likeOrDislikeWithFeatureName", { featureName: FEATURE_NAME })}
      </AppText>
      <Row ml="$4">
        <Box mr="$4">
          <IconButton
            IconComponent={AppIcons.LikeNo}
            bg={isLiked === false ? COLORS.$warning[500] : undefined}
            id="dislike-btn"
            variant={isLiked === false ? "primary" : "outline"}
            onPress={makeChangeHandler(false)}
          />
        </Box>
        <IconButton
          IconComponent={AppIcons.LikeYes}
          bg={isLiked === true ? COLORS.$success[500] : undefined}
          id="like-btn"
          variant={isLiked === true ? "primary" : "outline"}
          onPress={makeChangeHandler(true)}
        />
      </Row>
    </Row>
  )
}

function Success() {
  const { t } = useContext()
  return <AlertCard bodyText={t("successMessage")} my="$4" severity="success" />
}

function FeedbackTextInput() {
  const { form, t } = useContext()
  const labelText = t("feedbackTextInputLabel")
  const placeholderText = t("feedbackTextInputPlaceholder")
  return (
    <Controller
      control={form.control}
      name="feedbackText"
      render={({ field: { onChange, ref, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider
            isRequired
            id="feedbackText"
            isInvalid={Boolean(errorMessage)}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Input
              ref={ref}
              multiline
              numberOfLines={8}
              placeholder={placeholderText}
              returnKeyType="done"
              style={{ height: SIZES.$32 }}
              onChangeText={onChange}
              {...field}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}
function Main(): React.JSX.Element {
  const { isLoading, isSuccess, onClose, onSubmit } = useContext()

  return (
    <React.Fragment>
      <LikeOrDislikeButtons />
      {isSuccess ? (
        <Success />
      ) : (
        <React.Fragment>
          <FeedbackTextInput />
          <ActionButtons
            isLoading={isLoading}
            onPressCancel={onClose}
            onPressSubmit={onSubmit}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export function AppFeedbackForm(props: ProviderProps) {
  return (
    <Provider {...props}>
      <Main />
    </Provider>
  )
}
