import React from "react"

import { AppText } from "../Text"

import type { AppTextProps } from "../Text"
export type HelperTextProps = AppTextProps

export function HelperText(props: HelperTextProps) {
  return <AppText colorScheme="secondary" fontSize="$sm" {...props} />
}
