import React from "react"

import { Box } from "../Box"

import type { BoxProps } from "../Box"

export interface FormControlProviderProps extends BoxProps {
  isDisabled?: boolean
  isInvalid?: boolean
  isRequired?: boolean
}

function useFormControl({
  id,
  isDisabled,
  isInvalid,
  isRequired,
}: FormControlProviderProps) {
  return {
    id,
    isDisabled: isDisabled === true,
    isInvalid: isInvalid === true,
    isRequired: isRequired === true,
  }
}

type ContextValue = ReturnType<typeof useFormControl>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  id,
  isDisabled,
  isInvalid,
  isRequired,
  ...rest
}: React.PropsWithChildren<FormControlProviderProps>): JSX.Element | null {
  const value = useFormControl({
    id,
    isDisabled,
    isInvalid,
    isRequired,
  })
  return (
    <Context.Provider value={value}>
      <Box id={id} w="$full" {...rest}>
        {children}
      </Box>
    </Context.Provider>
  )
}

export function useFormControlContext(): ContextValue {
  const ctx = React.useContext(Context)

  return {
    id: ctx?.id,
    isDisabled: ctx?.isDisabled === true,
    isInvalid: ctx?.isInvalid === true,
    isRequired: ctx?.isRequired === true,
  }
}
