import React from "react"
import { useTranslation } from "react-i18next"

import { AppText, Box, Link } from "./components"
import { ErrorPageWrapper } from "./ErrorPageWrapper"

/**
 *
 */
export function NotFound404(): React.JSX.Element {
  const { t } = useTranslation("errors")

  return (
    <ErrorPageWrapper testId="page-404" title="404">
      <AppText>{t("notFoundMessage")}</AppText>
      <Box my="$4">
        <Link text={t("home", { ns: "common" })} to="/" />
      </Box>
    </ErrorPageWrapper>
  )
}
