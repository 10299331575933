import React from "react"
import { useTranslation } from "react-i18next"

import { Feedback } from "@fhq/app"
import { AlertDialog, AlertDialogScrollView } from "@fhq/app/components"

/**
 * Opens when user clicks feedback button
 */
export function FeedbackDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}): React.JSX.Element | null {
  const { t } = useTranslation("feedback")

  return (
    <AlertDialog
      IconComponent="Feedback"
      isOpen={isOpen}
      titleElement={t("title")}
      onClose={onClose}
    >
      <AlertDialogScrollView>
        <Feedback.AppFeedbackForm onClose={onClose} />
      </AlertDialogScrollView>
    </AlertDialog>
  )
}
