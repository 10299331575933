import React from "react"
import { useTranslation } from "react-i18next"

import { AppIcons, AppText, Box, Heading, ICON_SIZES, Row } from "./components"
import { COLORS } from "./components/theme"

import type { AcceptsChildren, BoxProps, NoChildren } from "./components"

export interface SuccessHandlerProps extends AcceptsChildren {
  actionElement?: JSX.Element
  message?: string
  title?: string
}

/**
 * A component to display a success message
 */
export function SuccessHandler({
  actionElement,
  children,
  message,
  title,
  ...rest
}: NoChildren<BoxProps> & SuccessHandlerProps): React.JSX.Element | null {
  const { t } = useTranslation()
  return (
    <Box id="success" py="$2" {...rest}>
      <Row alignItems="baseline" mb="$2">
        <AppIcons.Success color={COLORS.$green[500]} size={ICON_SIZES.$lg} />
        <Box flex={1} ml="$3">
          <Heading variant="h3">{title ?? t("success")}</Heading>
        </Box>
        <Box ml="auto">{actionElement}</Box>
      </Row>
      <AppText>{message}</AppText>
      {children}
    </Box>
  )
}
