import type {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
} from "@reduxjs/toolkit"
import { combineReducers } from "@reduxjs/toolkit"

// import { appFeedbackReducer as appFeedback } from "./app-features.reducer"
import appState from "./app-state.reducer"
import auth from "./auth.reducer"
import { createDeviceConnectionReducer } from "./CreateDeviceConnections"
import deviceActionSchedules from "./device-action-schedules.reducer"
import { deviceActivitiesReducer } from "./device-activities.reducer"
import { deviceCommandsReducer as deviceCommands } from "./device-commands.reducer"
import { deviceCommentsReducer } from "./device-comments.reducer"
import { deviceConfigurationsReducer as deviceConfigurations } from "./device-configurations.reducer"
import deviceEventLast from "./device-event-last.reducer"
import { deviceFarmAssociationsReducer as deviceFarmAssociations } from "./device-farm-association.reducer"
import { deviceProfileReducer as deviceProfile } from "./device-profile.reducer"
import { deviceShareCodesReducer as deviceShareCodes } from "./device-share-codes.reducer"
import { deviceUserAssociationsReducer as deviceUserAssociations } from "./device-user-association.reducer"
import { farmReducer as farm } from "./farm.reducer"
import fieldIrrigationHistory from "./field-irrigation-history.reducer"
import fields from "./fields.reducer"
import { namedDeviceActions } from "./named-device-actions.reducer"
import { notificationReducer as notifications } from "./notifications.reducer"
import { reelRunsActiveReducer as reelRunsActive } from "./reel-runs.reducer"
import requests from "./requests.reducer"
import statusMap from "./status-map.reducer"
import triggersReducer from "./triggers.reducer"
import userFarms from "./user-farms.reducer"
import permissions from "./user-permissions.reducer"
import userPhoneNumbers from "./user-phone-numbers.reducer"
import userPreferences from "./user-preferences.reducer"

import type { RootThunkExtra } from "./Requests"
import type { AnyRequestName } from "./send-request"
import type { RootDispatch } from "./store"
export const rootReducer = combineReducers({
  appState,
  auth,
  createConnection: createDeviceConnectionReducer,
  deviceActionSchedules,
  deviceActivities: deviceActivitiesReducer,
  deviceCommands,
  deviceComments: deviceCommentsReducer,
  deviceConfigurations,
  deviceEventLast,
  deviceFarmAssociations,
  deviceProfile,
  deviceShareCodes,
  deviceUserAssociations,
  farm,
  fieldIrrigationHistory,
  fields,
  namedDeviceActions,
  notifications,
  permissions,
  reelRunsActive,
  requests,
  statusMap,
  triggers: triggersReducer,
  userFarms,
  userPhoneNumbers,
  userPreferences,
})

export type RootState = ReturnType<typeof rootReducer>
export type GetRootState = () => RootState
// export type GetState = () => RootState
// export type Thunk<A = void, R = void> = (
//   arg: A
// ) => (dispatch: Dispatch, getState: GetState) => R
export interface RootThunkConfig {
  dispatch: RootDispatch
  extra: RootThunkExtra
  state: RootState
  fulfilledMeta?: unknown
  pendingMeta?: unknown
  rejectValue?: unknown
  rejectedMeta?: unknown
  serializedErrorType?: unknown
}

declare module "react-redux" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultRootState extends RootState {}
}

declare module "@reduxjs/toolkit" {
  export function createAsyncThunk<
    Returned = undefined,
    ThunkArg = void,
    ThunkApiConfig extends RootThunkConfig = RootThunkConfig,
  >(
    typePrefix: AnyRequestName,
    payloadCreator: AsyncThunkPayloadCreator<
      Returned,
      ThunkArg,
      ThunkApiConfig
    >,
    options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
  ): AsyncThunk<Returned, ThunkArg, ThunkApiConfig>
}
