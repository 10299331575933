/* eslint-disable import/no-named-as-default */

import ReactDatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"

import {
  AppIcons,
  AppText,
  Box,
  IconButton,
  Row,
  useIsDarkMode,
} from "@fhq/app/components"
import { COLORS } from "@fhq/app/components/theme"

import classes from "./Datepicker.module.css"

import type { IconButtonStyleProps } from "@fhq/app/components"
import type {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from "react-datepicker"
export function CustomHeader({
  date,
  decreaseMonth,
  increaseMonth,
  titleText,
}: ReactDatePickerCustomHeaderProps & { titleText?: string }) {
  const { t } = useTranslation()
  const iconButtonProps: IconButtonStyleProps = {
    size: "sm",
  }
  return (
    <Box alignItems="flex-start" display="flex">
      {typeof titleText === "string" ? (
        <Box ml="$3">
          <AppText colorScheme="darkText">{titleText}</AppText>
        </Box>
      ) : null}
      <Row justifyContent="space-between" w="$full">
        <IconButton
          {...iconButtonProps}
          IconComponent={AppIcons.ArrowLeft}
          onPress={decreaseMonth}
        />
        <AppText color={COLORS.$textDark.secondary} fontSize="$sm">
          {t("datetimeWithVal", { month: "long", val: date, year: "numeric" })}
        </AppText>
        <IconButton
          {...iconButtonProps}
          IconComponent={AppIcons.ArrowRight}
          onPress={increaseMonth}
        />
      </Row>
    </Box>
  )
}

// TODO: Adapt this for dark mode with dark mode className
export function DatePicker(props: ReactDatePickerProps) {
  const classNames = classes as
    | { [key: string]: string | undefined }
    | undefined
  const isDark = useIsDarkMode()
  return (
    <ReactDatePicker
      // todayButton={"Today"}

      calendarClassName={
        isDark ? classNames?.calendarDark : classNames?.calendar
      }
      // calendarContainer={({ children, className }) => (
      //   <div
      //     className={className}
      //     style={{
      //       width: "100%"
      //     }}
      //   >
      //     {children}
      //   </div>
      // )}
      {...props}
    />
  )
}
