import React from "react"
import { useTranslation } from "react-i18next"
import { Keyboard } from "react-native"

import { ActionButtons } from "./ActionButtons"
import { AlertCard } from "./components"
import { deleteFieldAsync } from "./farmhq-api"
import { useBackendRequest } from "./useBackendRequest"

/**
 * Permanently delete a field
 */
export function ConfirmDeleteField({
  fieldId,
  onClosed,
}: {
  fieldId: number
  onClosed: () => void
}): React.JSX.Element | null {
  const { t } = useTranslation("fields")

  const { handleError, isLoading, sendRequest, toasts } =
    useBackendRequest(deleteFieldAsync)

  const disableSubmit = isLoading

  return (
    <React.Fragment>
      <AlertCard
        bodyText={t("confirmDeleteFieldInstructions")}
        severity="warning"
      />
      <ActionButtons
        isLoading={isLoading}
        isSubmitDisabled={disableSubmit}
        mt="$8"
        onPressCancel={onClosed}
        onPressSubmit={() => {
          sendRequest({ fieldId })
            .then(() => {
              // Note: props.onSuccess is for navigating away from the deleted
              // profile; while context onSuccess shows a toast and clears
              // the field profile state.
              Keyboard.dismiss()
              onClosed()
              toasts.success()
              return undefined
              // return onSuccess()
            })
            .catch((error) => {
              handleError(error, { toastMessage: "default" })
            })
        }}
      />
    </React.Fragment>
  )
}
