import React from "react"

import { Box } from "./Box"

import type { BoxProps } from "./Box"

/**
 * A component that centers its children.
 */
export function Center(props: BoxProps): React.JSX.Element {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      w="$full"
      {...props}
    />
  )
}
