import React from "react"
import { useTranslation } from "react-i18next"
import { Alert, Share } from "react-native"

import { IconButton } from "./components"
import { logger } from "./logger"
import { useRootSelector } from "./useRootSelector"

import type { IconButtonStyleProps } from "./components"
import type { FarmJoinCodeStatus } from "./models"
export interface ShareFarmJoinCodeButtonProps {
  activeFarmName: string
  codeStatus: FarmJoinCodeStatus | undefined
  codeString: string
  joinFarmUrl: string
}

/**
 *
 */
export function ShareFarmJoinCodeButton({
  activeFarmName,
  codeStatus,
  codeString,
  joinFarmUrl,
  ...iconButtonProps
}: IconButtonStyleProps & ShareFarmJoinCodeButtonProps) {
  const { t } = useTranslation("farm")
  const isShareAvailable = useRootSelector(
    (state) => state.appState.isShareAvailable === true,
  )
  if (!isShareAvailable) {
    return null
  }
  const handlePress = () => {
    if (codeStatus === "ACTIVE") {
      // Share the join code if it is active
      const title = t("farmJoinCodeShareTitleWithActiveFarmName", {
        activeFarmName,
      })

      Share.share(
        {
          message: t(
            "farmJoinCodeShareMessageWithActiveFarmNameAndCodeString",
            {
              activeFarmName,
              codeString,
            },
          ),
          title,
          url: joinFarmUrl,
        },
        { subject: title },
      ).catch((error) => {
        logger.error(error)
      })
    } else {
      // Otherwise, show a deny modal
      Alert.alert(t("farmJoinCodeNotCreatedMessage"))
    }
  }
  return (
    <IconButton
      {...iconButtonProps}
      IconComponent="Share"
      onPress={handlePress}
    />
  )
}
