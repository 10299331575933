import { createSelector } from "@reduxjs/toolkit"

import * as AppStorage from "./async-storage"
import { COLORS } from "./components/theme"
import { getMapTypeIdFromState } from "./selectors"
import { useShallowEqualSelector } from "./useRootSelector"

import type { FieldColorScheme } from "./async-storage"
import type { MapTypeId } from "./geo"
import type { RootState } from "./root.reducer"
export function getFieldColorPreferenceFromState(
  state: RootState,
): FieldColorScheme {
  return state.userPreferences.fieldColorPreference
}
export const DEFAULT_FIELD_COLOR: FieldColorScheme = "yellow"

export interface UseFieldColorsReturn {
  fillColor: string
  scheme: FieldColorScheme
  strokeColor: string
  svgColor: string
}

/**
 * Get the field colors for a given color scheme
 */
function getFieldColorsForSchemePreference({
  colorScheme,
  mapTypeId,
}: {
  mapTypeId: MapTypeId
  colorScheme?: FieldColorScheme
}) {
  if (typeof colorScheme === "undefined") {
    colorScheme = AppStorage.STORAGE_DEFAULTS.fieldColorPreference
  }

  const swatch = COLORS[`$${colorScheme}`]
  const fillColor: string = swatch[400]
  let strokeColor: string = swatch[700]
  let svgColor: string = swatch[500]
  switch (colorScheme) {
    case "amber": {
      strokeColor = swatch[100]
      break
    }
    case "yellow": {
      svgColor = swatch[400]
      strokeColor = swatch[400]
      break
    }
    case "green": {
      if (mapTypeId === "TERRAIN") {
        break
      } else {
        strokeColor = swatch[200]
        break
      }
    }
    case "red": {
      strokeColor = swatch[800]
      svgColor = swatch[700]
      break
    }
    case "orange": {
      strokeColor = swatch[300]
      svgColor = swatch[400]
      break
    }
    case "dark": {
      if (mapTypeId === "TERRAIN") {
        break
      } else {
        strokeColor = swatch[700]
        break
      }
    }
    case "darkBlue":
    case "indigo": {
      strokeColor = swatch[100]
      break
    }
  }
  return { fillColor, scheme: colorScheme, strokeColor, svgColor }
}

export const getFieldColorsFromState = createSelector(
  getMapTypeIdFromState,
  (state: RootState, override?: FieldColorScheme) => {
    return override ?? getFieldColorPreferenceFromState(state)
  },
  (mapTypeId, colorscheme) =>
    getFieldColorsForSchemePreference({
      colorScheme: colorscheme,
      mapTypeId,
    }),
)

export function useFieldColors(
  override?: FieldColorScheme,
): UseFieldColorsReturn {
  return useShallowEqualSelector((state) =>
    getFieldColorsFromState(state, override),
  )
}
