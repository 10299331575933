import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

import { Forbidden, useRootSelector } from "@fhq/app"
import { Button } from "@fhq/app/components"
import { getUserHasAllPermissions } from "@fhq/app/user-permissions.reducer"

import PageContainer from "./PageContainer"
import { PageHeader } from "./PageHeader"

import type { AcceptsChildren, BoxProps } from "@fhq/app/components"
import type { PageHeaderProps } from "./PageHeader"

import type { FarmUserPermissionName } from "@fhq/app"
export interface PageProps extends PageHeaderProps, AcceptsChildren, BoxProps {
  requiredPermissions?: FarmUserPermissionName | FarmUserPermissionName[] | null
}

export function Page({
  IconComponent,
  children,
  helpContent,
  onClose,
  requiredPermissions,
  rightElement,
  subheaderElement,
  titleText,
  ...rest
}: PageProps): React.JSX.Element {
  const hasPermission = useRootSelector((state) => {
    return getUserHasAllPermissions(state, requiredPermissions)
  })
  const { t } = useTranslation()
  const navigate = useNavigate()
  if (!hasPermission) {
    return (
      <Forbidden
        backButtonElement={
          <Button text={t("back")} onPress={() => navigate(-1)} />
        }
      />
    )
  }
  return (
    <PageContainer {...rest}>
      <PageHeader
        IconComponent={IconComponent}
        helpContent={helpContent}
        rightElement={rightElement}
        subheaderElement={subheaderElement}
        titleText={titleText}
        onClose={onClose}
      />
      {children}
    </PageContainer>
  )
}
