import React from "react"
import { View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { SPACING } from "./theme"

import type { SpaceToken } from "./theme"
const DEFAULT_SPACE = SPACING.$2

/**
 * A component that adds space between its children.
 *
 * @deprecated Use `Box` instead.
 * TODO: DELETE
 */
export function Spacer({
  left,
  right,
  x,
  y,
}: React.PropsWithChildren<{
  left?: SpaceToken | boolean
  right?: SpaceToken | boolean
  x?: SpaceToken | boolean
  y?: SpaceToken | boolean
}>) {
  return (
    <View
      style={useStyle(() => {
        return {
          marginHorizontal:
            x === true
              ? DEFAULT_SPACE
              : x === false
              ? 0
              : x
              ? SPACING[x]
              : undefined,
          marginLeft:
            left === true
              ? DEFAULT_SPACE
              : left === false
              ? 0
              : left
              ? SPACING[left]
              : undefined,
          marginRight:
            right === true
              ? DEFAULT_SPACE
              : right === false
              ? 0
              : right
              ? SPACING[right]
              : undefined,
          marginVertical:
            y === true
              ? DEFAULT_SPACE
              : y === false
              ? 0
              : y
              ? SPACING[y]
              : undefined,
        }
      }, [left, right, x, y])}
    />
  )
}
