const EXTRA_SMALL = 375
const SMALL = 599
const MEDIUM = 960
const LARGE = 1280
const EXTRA_LARGE = 1920
export const BREAKPOINTS = {
  lg: LARGE,
  md: MEDIUM,
  sm: SMALL,
  xl: EXTRA_LARGE,
  xs: EXTRA_SMALL,
} as const
export type BreakpointToken = keyof typeof BREAKPOINTS

export function getBreakpointForWidth(width: number): BreakpointToken {
  if (width >= BREAKPOINTS.xl) {
    return "xl"
  }
  if (width >= BREAKPOINTS.lg) {
    return "lg"
  }
  if (width >= BREAKPOINTS.md) {
    return "md"
  }
  if (width >= BREAKPOINTS.sm) {
    return "sm"
  }
  return "xs"
}
