import React from "react"
import { Dimensions } from "react-native"
import { useDispatch } from "react-redux"

import { getCurrentBreakpointKey, setBreakpointKey } from "../app-state.reducer"
import { useRootSelector } from "../useRootSelector"
import { getBreakpointForWidth } from "./theme/breakpoints"

export function useTrackScreenSize() {
  const screenSize = useRootSelector(getCurrentBreakpointKey)

  const dispatch = useDispatch()
  /**
   * Dispatch an action only if the breakpoint changes
   */
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener("change", () => {
      const nextSize = getBreakpointForWidth(Dimensions.get("window").width)
      if (nextSize !== screenSize) {
        dispatch(setBreakpointKey(nextSize))
      }
    })

    return () => {
      subscription.remove()
    }
  }, [dispatch, screenSize])

  return screenSize
}
