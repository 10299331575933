import * as Localization from "expo-localization"
// eslint-disable-next-line import/no-named-as-default
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import * as Sentry from "@sentry/core"

import { DEFAULT_NAMESPACE } from "./constants"
import { resources } from "./resources"

export const SUPPORTED_APP_LANGUAGE_CODES = ["en", "es", "fr", "it"] as const

export type SupportedAppLanguageCode =
  (typeof SUPPORTED_APP_LANGUAGE_CODES)[number]
export function convertLanguageCodeToSupportedLanguage(
  code: string,
): SupportedAppLanguageCode {
  const [languageBase] = code.split("-")
  if (
    SUPPORTED_APP_LANGUAGE_CODES.includes(
      languageBase as SupportedAppLanguageCode,
    )
  ) {
    return languageBase as SupportedAppLanguageCode
  }
  return "en"
}

export const LOCALE = convertLanguageCodeToSupportedLanguage(
  Localization.locale,
)

// https://www.i18next.com/overview/configuration-options
// throw new TypeError(process.env.NODE_ENV)
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // debug: __DEV__,
    defaultNS: DEFAULT_NAMESPACE,
    fallbackLng: ["en"],
    interpolation: {
      defaultVariables: {
        // TODO: Add default variables
      },
      escapeValue: false,
      skipOnVariables: false, // react already safes from xss
    },
    lng: process.env.NODE_ENV === "test" ? "en" : LOCALE,
    missingInterpolationHandler: function onMissingInterpolationValue(
      text,
      value,
      options,
    ) {
      const namespace = `${JSON.stringify(options.ns)}`
      Sentry.captureMessage(
        `interpolation value is undefined for text: ${text}\nvalue=${JSON.stringify(
          value,
          null,
          2,
        )}\nnamespace=${namespace}`,
        {
          tags: {
            lng: options.lng,
            locale: LOCALE,
            namespace,
          },
        },
      )
    },
    // nonExplicitSupportedLngs: true,
    resources,
    returnObjects: true,
    // supportedLngs: SUPPORTED_APP_LANGUAGE_CODES
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
  })
  .catch((error) => {
    Sentry.captureException(error)
    throw error
  })

export default i18n
