import * as Df from "date-fns"

export interface CronParts {
  dayOfMonth: string
  daysOfWeek: number[]
  hours: number
  minutes: number
  month: string
}

export function daysOfWeekToDaysArray({ daysOfWeek }: { daysOfWeek: string }) {
  if (daysOfWeek === "*") {
    return []
  }
  return Array.from(daysOfWeek)
    .filter((el) => el !== ",")
    .map((num) => Number.parseInt(num))
}
export function cronToParts(cronString: string) {
  const [minutes, hours, dayOfMonth, month, daysOfWeek] = cronString.split(" ")
  if (typeof minutes === "undefined") {
    throw new TypeError(`minutes is undefined`)
  }
  if (typeof hours === "undefined") {
    throw new TypeError(`hours is undefined`)
  }
  if (typeof dayOfMonth === "undefined") {
    throw new TypeError(`dayOfMonth is undefined`)
  }
  if (typeof month === "undefined") {
    throw new TypeError(`month is undefined`)
  }
  if (typeof daysOfWeek === "undefined") {
    throw new TypeError(`dayOfWeek is undefined`)
  }

  const cronSchedule: CronParts = {
    dayOfMonth,
    daysOfWeek: daysOfWeekToDaysArray({ daysOfWeek }),
    hours: Number.parseInt(hours),
    minutes: Number.parseInt(minutes),
    month,
  }
  return cronSchedule
}

export function doesCronOccurOnDay({
  cronParts: { daysOfWeek },
  date,
}: {
  cronParts: Pick<CronParts, "daysOfWeek">
  date: Date
}) {
  if (daysOfWeek.includes(date.getDay())) {
    return true
  }
  return false
}

export function buildCronExecutionsForWeek({
  baseDate,
  cronParts: { daysOfWeek, hours, minutes },
}: {
  baseDate: Date
  cronParts: CronParts
}) {
  const result: number[] = []
  for (const dayIndex of daysOfWeek) {
    const withDay = Df.setDay(baseDate, dayIndex)
    const withHours = Df.setHours(withDay, hours)
    const withMinutes = Df.setMinutes(withHours, minutes)
    result.push(withMinutes.getTime())
  }
  return result
}
/**
 * Convert date to agenda key format
 * @param date
 * @returns day in YYYY/MM/DD format
 */
export function truncateDate(date: Date | number): string {
  return new Date(date).toISOString().substring(0, 10)
}
