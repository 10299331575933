import type { ViewProps } from "react-native"
import { StyleSheet, View } from "react-native"

import { COLORS } from "@fhq/app/components/theme"

const styles = StyleSheet.create({
  root: {
    backgroundColor: COLORS.$dark[200],
    left: 0,
    opacity: 70,
    position: "absolute",
    right: 0,
    top: 0,
  },
})

export function ImageHeader(props: ViewProps) {
  return <View style={styles.root} {...props} />
}
