import { useTranslation } from "react-i18next"

import { captureException } from "@sentry/core"

import { logger } from "./logger"
import i18n from "./translations/i18n"

/**
 * https://blog.webdevsimplified.com/2020-07/relative-time-format/
 */

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
] as const

export function getRelativeTimeParams(
  date: Date | number | string,
  currentTime?: Date,
) {
  if (typeof currentTime === "undefined") {
    currentTime = new Date()
  }

  let milliseconds: number

  if (typeof date === "string") {
    milliseconds = new Date(date).getTime()
  } else if (typeof date === "object" && date instanceof Date) {
    milliseconds = date.getTime()
  } else {
    milliseconds = date
  }

  let duration = (milliseconds - currentTime.getTime()) / 1000

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i]
    if (typeof division === "undefined") {
      throw new TypeError("No division")
    }
    if (Math.abs(duration) < division.amount) {
      const durationRounded = Math.round(duration)
      const unitLabel = division.name

      return {
        ns: "common",

        numeric: "auto",
        // Range determines which unit is shown
        range: unitLabel,
        style: "narrow",
        val: durationRounded,
      } as const
    }
    duration /= division.amount
  }
  return undefined
}

export function formatTimeAgo(
  date: Date | number | string,
  currentTime?: Date,
) {
  const params = getRelativeTimeParams(date, currentTime)
  if (params) {
    return i18n.t("lastCheckInWithVal", { ...params })
  }

  return undefined
}

export function useFormatLatCheckInText() {
  const { t } = useTranslation("devices")

  return ({
    currentTime,
    deviceEventTimestamp,
  }: {
    deviceEventTimestamp: Date | number | string | null | undefined
    currentTime?: Date
  }) => {
    try {
      if (
        typeof deviceEventTimestamp === "number" ||
        typeof deviceEventTimestamp === "string" ||
        (typeof deviceEventTimestamp === "object" &&
          deviceEventTimestamp instanceof Date)
      ) {
        const result = formatTimeAgo(deviceEventTimestamp, currentTime)

        return result
      }
    } catch (error) {
      logger.error(error)
      captureException(error)
    }
    return t("lastCheckInWithFallback", { ns: "common" })
  }
}
