import React from "react"
import { useTranslation } from "react-i18next"

import { setMeasurementPreference } from "./async-storage"
import { Radio } from "./components/Radio"
import { useToasts } from "./components/useToasts"
import { getUserMeasurementPreferenceFromState } from "./selectors"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

/**
 *
 */
export function MeasurementRadio(): React.JSX.Element {
  const { t } = useTranslation("account")
  const dispatch = useRootDispatch()
  const toasts = useToasts()

  const currentValue = useRootSelector(getUserMeasurementPreferenceFromState)
  const handleError = useErrorHandler()
  const onChange = (nextValue: string) => {
    if (nextValue === "metric" || nextValue === "us") {
      dispatch(setMeasurementPreference(nextValue))
        .then(() => toasts.success())
        .catch((e) => handleError(e, { toastMessage: "default" }))
    } else {
      throw new TypeError(`Invalid measurement ${nextValue}`)
    }
  }

  return (
    <Radio
      orientation="horizontal"
      selectedValue={currentValue}
      options={[
        {
          id: "metric-btn",
          label: t("measurementPreferenceValues.metric"),
          value: "metric",
        },
        {
          id: "us-btn",
          label: t("measurementPreferenceValues.us"),
          value: "us",
        },
      ]}
      onChange={onChange}
    />
  )
}
