import React from "react"
import { ScrollView } from "react-native"

import { loadMultifarmAnalyticsAsync, useBackendRequest } from "@fhq/app"
import { AppIcons, AppText, Box, Row, TitleWithIcon } from "@fhq/app/components"
import { ModalLoader } from "@fhq/app/Loader"

import { logger } from "./base/base"
import { DatePicker } from "./base/Datepicker"

import type { AcceptsChildren } from "@fhq/app/components"
import type { DeepPartial } from "utility-types"

import type {
  MultifarmAnalyticsArgs,
  MultifarmAnalyticsResponse,
} from "@fhq/app"
/**
 * Texts for the page.
 * Don't hardcode into the JSX to make it easier to localize.
 */
const TEXTS = {
  title: "Dealer Analytics",
}

/**
 * Layout for the page.
 */
function Layout({ children }: AcceptsChildren) {
  return (
    <ScrollView>
      <TitleWithIcon
        IconComponent={AppIcons.Analytics}
        titleText={TEXTS.title}
      />
      {children}
    </ScrollView>
  )
}

/**
 * Initial date interval for the analytics.
 */
const INITIAL_MIN_DATE_INTERVAL_MS = 7 * 24 * 60 * 60 * 1000

/**
 * Page for the multifarm analytics.
 */
export function MultifarmAnalyticsPage() {
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    loadMultifarmAnalyticsAsync,
  )

  const [data, setData] =
    React.useState<DeepPartial<MultifarmAnalyticsResponse>>()
  const [dateMsMin, setDateMsMin] = React.useState(
    new Date().getTime() - INITIAL_MIN_DATE_INTERVAL_MS,
  )
  const [dateMsMax, setDateMsMax] = React.useState(new Date().getTime())

  React.useEffect(() => {
    // TODO: This is a placeholder for the query parameters
    // update the type to add new stuff
    const queryParameters: MultifarmAnalyticsArgs = {
      dateMsMax,
      dateMsMin,
    }

    sendRequest(queryParameters)
      .then((response) => {
        logger.info(response)
        return setData(response)
      })
      .catch((error) => {
        logger.error(error)
        handleError(error, {
          toastMessage: "default",
        })
      })
  }, [dateMsMax, dateMsMin, handleError, sendRequest])

  const onChangeDateMin = (date: Date | null) => {
    if (date) {
      setDateMsMin(date.getTime())
    }
  }
  const onChangeDateMax = (date: Date | null) => {
    if (date) {
      setDateMsMax(date.getTime())
    }
  }
  return (
    <React.Fragment>
      <ModalLoader isOpen={isLoading} />
      <Layout>
        <Row mt="$4" zIndex={1}>
          <Box mr="$4">
            <DatePicker
              selected={new Date(dateMsMin)}
              onChange={onChangeDateMin}
            />
          </Box>
          <Box>
            <DatePicker
              selected={new Date(dateMsMax)}
              onChange={onChangeDateMax}
            />
          </Box>
        </Row>
        <Box my="$4">
          <AppText>{JSON.stringify(data, null, 2)}</AppText>
        </Box>
      </Layout>
    </React.Fragment>
  )
}
