/* eslint-disable sort-keys-fix/sort-keys-fix */
const PIXELS = {
  "$-4": -16,
  "$-3": -12,
  "$-2": -8,
  "$-1": -4,
  "$0": 0,
  "$px": "1px",
  "$0.5": 2,
  "$1": 4,
  "$1.5": 6,
  "$2": 8,
  "$2.5": 10,
  "$3": 12,
  "$3.5": 14,
  "$4": 16,
  "$5": 20,
  "$6": 24,
  "$7": 28,
  "$8": 32,
  "$9": 36,
  "$10": 40,
  "$12": 48,
  "$14": 56,
  "$16": 64,
  "$20": 80,
  "$24": 96,
  "$28": 112,
  "$32": 128,
  "$40": 160,
  "$44": 176,
  "$48": 192,
  "$56": 224,
  "$64": 256,
  "$72": 288,
  "$80": 320,
  "$96": 384,
}
export const SPACING = {
  ...PIXELS,
  "auto": "auto",
  "10%": "10%",
  "20%": "20%",
  "25%": "25%",
  "30%": "30%",
  "33.333%": "33.333%",
  "40%": "40%",
  "50%": "50%",
  "60%": "60%",
  "66.666%": "66.666%",
  "70%": "70%",
  "75%": "75%",
  "80%": "80%",
  "83.333%": "83.333%",
  "90%": "90%",
  "95%": "95%",
  "100%": "100%",
  "$1/2": "50%",
  "$1/3": "33.333%",
  "$1/4": "25%",
  "$1/5": "20%",
  "$1/6": "16.666%",
  "$2/3": "66.666%",
  "$2/4": "50%",
  "$2/5": "40%",
  "$2/6": "33.333%",
  "$3/4": "75%",
  "$3/5": "60%",
  "$3/6": "50%",
  "$4/5": "80%",
  "$4/6": "66.666%",
  "$5/6": "83.333%",
  "$full": "100%",
  "$minWidthMobile": 275,
  "$toolbar": 64,
}
export type SpaceToken = keyof typeof SPACING
