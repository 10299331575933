import React from "react"
import { StyleSheet, View } from "react-native"

import { Box } from "./components"
import { AlertCard } from "./components/AlertCard"
import { SPACING } from "./components/theme"

import type { ViewProps } from "react-native"
import type { AcceptsChildren, BoxProps } from "./components"
import type { TestId, WithOptionalTestId } from "./components/test-id"
import type { AlertSeverity } from "./constants"
export interface NoListItemProps extends WithOptionalTestId<ViewProps> {
  action?: React.JSX.Element
  severity?: AlertSeverity
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    marginHorizontal: "auto",
    width: "100%",
  },
  spacer: {
    marginVertical: SPACING.$1,
  },
})

export function ListEmptyContainer({ children }: AcceptsChildren) {
  return <Box p="$4">{children}</Box>
}
/**
 * Standard view to display when a list is empty.
 */
export function NoListItems({
  action,
  children,
  id = "no-list-items",
  message,
  style,
  ...rest
}: BoxProps & {
  message: string
  action?: React.JSX.Element | null
  id?: TestId
  severity?: AlertSeverity
}): React.JSX.Element {
  return (
    <Box id={id} style={[styles.root, style]} {...rest}>
      <AlertCard bodyText={message} severity="info" style={styles.spacer} />
      {Boolean(children) ? <View style={styles.spacer}>{children}</View> : null}
      {action ? <View style={styles.spacer}>{action}</View> : null}
    </Box>
  )
}
