import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { useIsDarkMode, View } from "@fhq/app/components"
import { COLORS } from "@fhq/app/components/theme"

import type { AcceptsChildren } from "@fhq/app/components"

const styles = StyleSheet.create({
  root: {
    backgroundColor: COLORS.$background.light,
    // display: "flex",
    flex: 1,
    // flexDirection: "column",
    // height: "100%",
    // overflow: "hidden",
    // width: "100%",
  },
  rootDark: {
    backgroundColor: COLORS.$background.dark,
  },
})
export function RootLayout({ children }: AcceptsChildren) {
  const isDark = useIsDarkMode()
  return (
    <View
      id="app"
      style={useStyle(() => {
        return [styles.root, isDark && styles.rootDark]
      }, [isDark])}
    >
      {children}
    </View>
  )
}
