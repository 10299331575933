import { Geo, SetLinearPath } from "@fhq/app"
import { Polygon } from "@react-google-maps/api"
import { withErrorBoundary } from "@sentry/react"

import type { LinearPathOutlineParams } from "@fhq/app/SetLinearPath"
import type { PolygonProps } from "@react-google-maps/api"
const SWATH_OUTLINE_OPTIONS: PolygonProps["options"] = {
  // fillColor: SetLinearPath.LINEAR_PATH_COLORS.swath.fill,
  fillOpacity: 0,
  strokeColor: SetLinearPath.LINEAR_PATH_COLORS.swath.stroke,
}

/**
 *
 */
function LinearSwathOutlineVisualization({
  isClickable,
  linearPathStops,
  linearSpanHeadingDegrees,
  linearSpanWidthMm,
}: LinearPathOutlineParams &
  Pick<PolygonProps, "onClick"> & {
    isClickable: boolean
  }) {
  const swathOutline = SetLinearPath.createLinearPathVisualization({
    linearPathStops,
    linearSpanHeadingDegrees,
    linearSpanWidthMm,
  })
  return (
    <Polygon
      options={{ ...SWATH_OUTLINE_OPTIONS, clickable: isClickable }}
      path={Geo.polygon(swathOutline)?.toGmaps() ?? []}
    />
  )
}

export default withErrorBoundary(LinearSwathOutlineVisualization, {})
