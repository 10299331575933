/* eslint-disable sort-keys-fix/sort-keys-fix */
import type { DeviceStatusData } from "@fhq/app"
import type { SortComparator } from "./models"

type SortFn = SortComparator<DeviceStatusData>

const INSTALLATION_ORDER_MAP = {
  "unconfigured": 0,
  "reel": 1,
  "reel_with_booster_off_only": 2,
  "center_pivot": 3,
  "linear_move": 4,
  "pump_vfd": 5,
  "pump_on_off": 6,
  "pump_off_only": 7,
  "pump": 8,
  "valve": 9,
  "traveller_soft": 10,
  "prototype": 11,
}
export const sortByDeviceInstallationType: SortFn = (left, right) => {
  const typeLeft = left?.deviceInstallationType ?? "unconfigured"
  const typeRight = right?.deviceInstallationType ?? "unconfigured"
  const indexLeft = INSTALLATION_ORDER_MAP[typeLeft]
  const indexRight = INSTALLATION_ORDER_MAP[typeRight]

  if (indexLeft < indexRight) {
    return -1
  }
  if (indexLeft > indexRight) {
    return 1
  }
  return 0
}

const sortByPressureState: SortFn = (left, right) => {
  const stateA = left?.pressureState
  const stateB = right?.pressureState
  if (stateA === "PHI" && stateB !== "PHI") {
    return -1
  }
  if (stateA !== "PHI" && stateB === "PHI") {
    return 1
  }
  return 0
}

const sortByStoppedShort: SortFn = (left, right) => {
  if (left?.isStoppedShort === true) {
    return -1
  }
  if (right?.isStoppedShort === true) {
    return 1
  }
  return 0
}

const sortByCompletionPct: SortFn = (left, right) => {
  const pctLeft = left?.runCompletion?.runCompletionPct ?? 0
  const pctRight = right?.runCompletion?.runCompletionPct ?? 0

  if (pctLeft > pctRight) {
    return -1
  }
  if (pctLeft < pctRight) {
    return 1
  }
  return 0
}

const sortByEventRecency: SortFn = (left, right) => {
  const timestampLeft = left?.deviceEventTimestamp
  const timestampRight = right?.deviceEventTimestamp
  if (Boolean(timestampLeft) && !Boolean(timestampRight)) {
    return -1
  }
  if (!Boolean(timestampLeft) && Boolean(timestampRight)) {
    return 1
  }
  if (typeof timestampLeft === "string" && typeof timestampRight === "string") {
    const dateLeft = new Date(timestampLeft)
    const dateRight = new Date(timestampRight)
    return dateRight.getTime() - dateLeft.getTime()
  }
  return 0
}

const sortByWheelMovement: SortFn = (left, right) => {
  const wheelLeft = left?.badges?.wheel
  const wheelRight = right?.badges?.wheel
  if (wheelLeft && !wheelRight) {
    return -1
  }
  if (!wheelLeft && wheelRight) {
    return 1
  }
  return 0
}

/**
 *
 * @param left
 * @param right
 */
export const sortByDeviceName: SortFn = (left, right) => {
  const nameLeft = left?.deviceName ?? ""
  const nameRight = right?.deviceName ?? ""

  if (nameLeft && !nameRight) {
    return -1
  }
  if (!nameLeft && nameRight) {
    return 1
  }

  return nameLeft.localeCompare(nameRight)
}

const sortByOnlineStatus: SortFn = (left, right) => {
  const isLeftOnline = left?.deviceStatus === "online"
  const isRightOnline = right?.deviceStatus === "online"
  if (isLeftOnline && !isRightOnline) {
    return -1
  }
  if (!isLeftOnline && isRightOnline) {
    return 1
  }
  return 0
}

const sortByVfdActivity: SortFn = (left, right) => {
  const vfdLeft = left?.badges?.vfdStatus
  const vfdRight = right?.badges?.vfdStatus

  if (vfdLeft && !vfdRight) {
    return -1
  }
  if (!vfdLeft && vfdRight) {
    return 1
  }

  const stateLeft = vfdLeft?.stateCurrent ?? null
  const stateRight = vfdRight?.stateCurrent ?? null

  if (stateLeft === "VFDOV" && stateRight !== "VFDOV") {
    return -1
  }

  if (stateLeft === "VFDUN" && stateRight !== "VFDUN") {
    return -1
  }
  if (stateLeft === "VFDPIDT" && stateRight !== "VFDPIDT") {
    return -1
  }

  if (stateLeft === "VFDRP" && stateRight !== "VFDRP") {
    return -1
  }
  if (stateLeft !== "VFDRP" && stateRight === "VFDRP") {
    return 1
  }

  if (stateLeft === "VFDST" && stateRight !== "VFDST") {
    return -1
  }
  if (stateLeft !== "VFDST" && stateRight === "VFDST") {
    return 1
  }

  // const currentLeft = vfdLeft?.currentPercentage ?? 0
  // const currentRight = vfdRight?.currentPercentage ?? 0

  // if (currentLeft > currentRight) {
  //   return -1
  // }
  // if (currentLeft < currentRight) {
  //   return 1
  // }
  return 0
}

// const sortByDeviceActivity: SortFn = (left, right) => {
//   // const activityLeft = left.deviceac
// }

// TODO: Incorporate scheduled actions
// TODO: Incorporate device activity

const SORT_FUNCTIONS_IN_ORDER = [
  sortByOnlineStatus,
  sortByStoppedShort,
  sortByCompletionPct,
  sortByWheelMovement,
  sortByVfdActivity,
  sortByPressureState,
  sortByEventRecency,
  sortByDeviceName,
] as const

export const sortDeviceStatusByUrgency: SortFn = (left, right) => {
  for (const sortFunction of SORT_FUNCTIONS_IN_ORDER) {
    const result = sortFunction(left, right)
    if (result !== 0) {
      return result
    }
  }
  return 0
}
