import React from "react"
import { StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { AppText, Spinner, useIsDarkMode } from "./components"
import { testIds } from "./components/test-id"
import { COLORS, SPACING, Z_INDEX } from "./components/theme"
import { RADII } from "./components/theme/radii"

import type { NoChildren, AcceptsChildren } from "./components"
import type { BoxProps } from "./components/Box"

interface LoaderSpinnerProps extends AcceptsChildren {
  message?: string
  textBackgroundColor?: string
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
  },
  loaderSpinnerOuter: {
    margin: "auto",
  },
  modalLoader: {
    backgroundColor: COLORS.$dark[600],
    bottom: 0,
    flex: 1,
    left: 0,
    opacity: 0.5,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: Z_INDEX.fab,
  },
  modalLoaderDark: {
    backgroundColor: COLORS.$dark[300],
  },
  text: {
    marginVertical: SPACING.$2,
  },
})

/**
 * A spinner with a message and optional children.
 */
function LoaderSpinner({
  children,
  message,
  textBackgroundColor,
}: LoaderSpinnerProps): React.JSX.Element {
  return (
    <View {...testIds("loader")} style={styles.loaderSpinnerOuter}>
      <View
        style={useStyle(() => {
          return {
            backgroundColor: textBackgroundColor,
            padding: SPACING.$2,
            rounded: RADII.$lg,
          }
        }, [textBackgroundColor])}
      >
        <Spinner />
        {typeof message === "string" && message.length > 0 ? (
          <AppText
            fontSize="$sm"
            style={styles.text}
            textTransform="capitalize"
          >
            {message}
          </AppText>
        ) : null}
        {children}
      </View>
    </View>
  )
}

/**
 * A spinner with a message and optional children.
 */
export function Loader({
  children,
  message,
  textBackgroundColor,
  variant,
  ...rest
}: LoaderSpinnerProps &
  NoChildren<BoxProps> & { variant?: "modal" }): React.JSX.Element {
  const isDark = useIsDarkMode()
  return (
    <View
      style={useStyle(
        () => [
          styles.container,
          variant === "modal" ? styles.modalLoader : undefined,
          isDark && variant === "modal" ? styles.modalLoaderDark : undefined,
        ],
        [isDark, variant],
      )}
      {...rest}
    >
      <LoaderSpinner
        message={message}
        textBackgroundColor={textBackgroundColor}
      >
        {children}
      </LoaderSpinner>
    </View>
  )
}

/**
 * A spinner with a message and optional children.
 */
export function ModalLoader({
  children,
  isOpen,
  ...rest
}: AcceptsChildren &
  LoaderSpinnerProps & {
    isOpen: boolean
  }): React.JSX.Element | null {
  const style = useStyle(() => [styles.container, styles.modalLoader], [])
  if (isOpen) {
    return (
      <View style={style}>
        <LoaderSpinner {...rest}>{children}</LoaderSpinner>
      </View>
    )
  }
  return null
}
