import type { PayloadAction, Reducer } from "@reduxjs/toolkit"
import * as dateFunctions from "date-fns"
import i18next from "i18next"
import _ from "lodash"
import { createCachedSelector } from "re-reselect"
import React from "react"

import { createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit"

import { selectReelRunHistorical } from "./actions"
import * as AppStorage from "./async-storage"
import { signOutAsync } from "./auth.reducer"
import { makeDate } from "./components/Time"
import { loadFieldIrrigationHistoryAsync } from "./farmhq-api"
import { round } from "./geo"
import * as Models from "./models"
import {
  calculateReelRunApplication,
  getIsFieldIrrigationAutozoomEnabled,
  getSwathGeometry,
  getSwathGeometryCachedByReelRunPropertiesFromState,
} from "./reel-runs.reducer"
import {
  getAreReelRunOutlinesVisibleFromState,
  getUserMeasurementPreferenceFromState,
  useIsPending,
} from "./selectors"
import { convertSensorValue, requiresConversion } from "./sensor-conversions"
import { formatSensorValue } from "./sensor-formatting"
import i18n from "./translations/i18n"
import { isTruthyString, isValidNumber, notNullish } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"
import { getActiveFarmCoordinatesFromState } from "./user-farms.selectors"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"
import { formatDateSafely } from "./utility"

import type { DeviceConfiguration } from "./device-configurations.reducer"
import type { PointInput } from "./geo"
import type { ModelState } from "./models"
import type { CalculateReelRunApplicationParams } from "./reel-runs.reducer"
import type { RootState } from "./root.reducer"
import type { MeasurementPreferenceProps } from "./sensor-conversions"
import type { AnySensorKey, ReelRunGraphKey } from "./sensors"
import type { DateChangeHandler, MinDateMaxDate, ReelRunIdProps } from "./types"

import type { ChartDatum } from "./GraphComponents"
export interface HeatMapSettings {
  contrast: "default" | "high"
}

interface FieldIrrigationHistoryState
  extends ModelState<Models.ReelRun>,
    MinDateMaxDate {
  heatmapSettings: HeatMapSettings
  isSettingsOpen: boolean
  currentFieldId?: number
  isAutozoomEnabled?: boolean
  selectedRunId?: number
  showOutlines?: boolean
}

const initialState: FieldIrrigationHistoryState = {
  isSettingsOpen: false,
  ...Models.getInitialEntityState(),
  dateMsMax: dateFunctions.subDays(new Date(), 365).getTime(),
  dateMsMin: Date.now(),
  heatmapSettings: {
    contrast: AppStorage.STORAGE_DEFAULTS.runHeatmapContrast,
  },
}

const adapter = Models.fieldProfileReelRuns.adapter

const fieldProfile = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(loadFieldIrrigationHistoryAsync.pending, (state, { meta }) => {
        adapter.removeAll(state)
        if (meta.arg.fieldId !== state.currentFieldId) {
          state.selectedRunId = undefined
        }
        state.currentFieldId = meta.arg.fieldId
      })
      .addCase(
        loadFieldIrrigationHistoryAsync.fulfilled,
        (state, { payload }) => {
          adapter.setAll(
            state,
            payload.items.map((reelRun, index) => ({
              ...reelRun,
              index,
              isHidden: false,
              ...calculateReelRunApplication(reelRun),
            })),
          )
        },
      )
      .addCase(selectReelRunHistorical, (state, action) => {
        state.selectedRunId = action.payload.reelRunId
      })
      .addCase(
        AppStorage.readLocalStorageAsync.fulfilled,
        (state, { payload }) => {
          // MIN DATE
          state.dateMsMin = payload.fieldIrrigationHistoryDateMin
          // MAX DATE
          state.dateMsMax = payload.fieldIrrigationHistoryDateMax

          state.showOutlines = payload.showReelRunOutline
          state.isAutozoomEnabled = payload.fieldRunHistoryAutozoomEnabled
          state.heatmapSettings = {
            contrast: payload.runHeatmapContrast,
          }
        },
      )
      .addCase(AppStorage.setRunHeatmapContrast.pending, (state, action) => {
        state.heatmapSettings.contrast = action.meta.arg
      })
      .addCase(AppStorage.setFieldProfileAutoZoom.pending, (state, action) => {
        state.isAutozoomEnabled = action.meta.arg
      })
      .addCase(AppStorage.setShowRunOutlines.pending, (state, action) => {
        state.showOutlines = action.meta.arg
      })
      .addCase(
        AppStorage.setIrrigationHistoryDateMin.pending,
        (state, action) => {
          // MIN DATE
          state.dateMsMin = action.meta.arg
        },
      )
      .addCase(
        AppStorage.setIrrigationHistoryDateMax.pending,
        (state, action) => {
          // MAX DATE
          state.dateMsMax = action.meta.arg
        },
      )
      .addMatcher(isAnyOf(signOutAsync.fulfilled), () => {
        return { ...initialState }
      }),
  initialState,
  name: "fieldProfile",
  reducers: {
    setHeatmapContrast: (
      state,
      { payload }: PayloadAction<HeatMapSettings["contrast"]>,
    ) => {
      state.heatmapSettings = { ...state.heatmapSettings, contrast: payload }
    },
    toggleUiSettingsMenu: (state) => {
      if (state.isSettingsOpen) {
        state.isSettingsOpen = false
      } else {
        state.isSettingsOpen = true
      }
    },
  },
})

const reducer: Reducer<FieldIrrigationHistoryState> = fieldProfile.reducer
export default reducer
export const { setHeatmapContrast, toggleUiSettingsMenu } = fieldProfile.actions

export function getSelectedHistoricalRunIdFromState(state: RootState) {
  return state.fieldIrrigationHistory.selectedRunId
}
export function getHistoricalReelRunIndexByIdFromState(
  state: RootState,
  id: number,
): number | undefined {
  if (isValidNumber(id)) {
    return Models.fieldProfileReelRuns.selectAll(state).findIndex((reelRun) => {
      return reelRun.reelRunId === id
    })
  }
  return undefined
}
export const fieldIrrigationHistoryDates = {
  getMax: (state: RootState) => state.fieldIrrigationHistory.dateMsMax,
  getMin: (state: RootState) => state.fieldIrrigationHistory.dateMsMin,
}
export function useFieldIrrigationHistoryDateValues(): MinDateMaxDate {
  return {
    //  MAX DATE
    dateMsMax: useRootSelector(fieldIrrigationHistoryDates.getMax),

    // MIN DATE
    dateMsMin: useRootSelector(fieldIrrigationHistoryDates.getMin),
  }
}
export function useFieldIrrigationHistoryDatePickers() {
  const dates = useFieldIrrigationHistoryDateValues()
  const dispatch = useRootDispatch()

  /**
   * Minimum date change handler
   */
  const onChangeMinDate: DateChangeHandler = (value) => {
    const asDate = makeDate(value)
    if (asDate instanceof Date) {
      void dispatch(AppStorage.setIrrigationHistoryDateMin(asDate.getTime()))
    }
    return undefined
  }

  /**
   * Maximum date change handler
   */
  const onChangeMaxDate: DateChangeHandler = (value) => {
    const asDate = makeDate(value)
    if (asDate instanceof Date) {
      void dispatch(AppStorage.setIrrigationHistoryDateMax(asDate.getTime()))
    }
    return undefined
  }
  return {
    isDisabled: useIsPending("LoadFieldIrrigationHistory"),
    ...dates,
    onChangeMaxDate,
    onChangeMinDate,
  }
}
export function calculateTotalApplicationForReelRuns(
  runs: CalculateReelRunApplicationParams[],
) {
  let result = 0
  for (const run of runs) {
    const { applicationAcreInchesTotal } = calculateReelRunApplication(run)

    if (isValidNumber(applicationAcreInchesTotal)) {
      result += applicationAcreInchesTotal
    }
  }
  return round(result, 2)
}
export const getTotalApplicationForSelectedField = createSelector(
  Models.fieldProfileReelRuns.selectAll,
  calculateTotalApplicationForReelRuns,
)

function makeCacheKey(_state: RootState, runId: number | undefined): string {
  return `${runId ?? "NONE"}`
}

export function calculateMetricsForSelectedRun({
  measurementPreference,
  runData,
}: MeasurementPreferenceProps & {
  runData: Pick<
    Models.ReelRun,
    | "applicationRateReportsMm"
    | "distanceMmCurrent"
    | "distanceMmMax"
    | "distanceReportsMm"
    | "endTimestamp"
    | "pressureReportsKpa"
    | "reelSwathWidthMm"
    | "speedReportsMmpm"
    | "startTimestamp"
  >
}) {
  const speedMmpmAverage = _.mean(runData.speedReportsMmpm)
  if (typeof runData.startTimestamp === "string") {
    const application = calculateReelRunApplication({
      applicationRateReportsMm: runData.applicationRateReportsMm,
      distanceMmCurrent: runData.distanceMmCurrent,
      distanceMmMax: runData.distanceMmMax,
      distanceReportsMm: runData.distanceReportsMm,
      reelSwathWidthMm: runData.reelSwathWidthMm,
    })
    const psiAverage = Array.isArray(runData.pressureReportsKpa)
      ? _.mean(runData.pressureReportsKpa)
      : undefined
    const applicationRateMmAverage = round(
      application.applicationRateMmAverage,
      2,
    )
    const applicationAcreInchesTotal = application.applicationAcreInchesTotal

    const formatValue = (
      fieldName: AnySensorKey,
      rawValue: number | undefined,
    ): string | undefined =>
      formatSensorValue({ fieldName, measurementPreference, rawValue })

    const start = new Date(runData.startTimestamp)

    let duration: Duration | undefined
    if (isTruthyString(runData.endTimestamp)) {
      const end = new Date(runData.endTimestamp)
      duration = dateFunctions.intervalToDuration({ end, start })
    }

    return {
      applicationAverage: i18n.t("averageApplicationRateWithVal", {
        ns: "fieldIrrigationHistory",
        val: formatValue(
          "applicationRateEstimatedMm",
          applicationRateMmAverage,
        ),
      }),
      applicationTotal: isValidNumber(applicationAcreInchesTotal)
        ? i18n.t("totalApplicationWithVal", {
            ns: "fieldIrrigationHistory",

            val: applicationAcreInchesTotal,
          })
        : undefined,
      psiAverage: isValidNumber(psiAverage)
        ? i18n.t("averagePressureWithVal", {
            ns: "fieldIrrigationHistory",

            val: formatValue("readingKpa", psiAverage),
          })
        : undefined,
      runDuration: i18n.t("durationWithVal", {
        ns: "fieldIrrigationHistory",

        val: duration
          ? dateFunctions.formatDuration(duration, {
              format: ["hours", "minutes"],
            })
          : i18n.t("ongoing", { ns: "common" }),
      }),
      speedAverage: i18n.t("averageSpeedWithVal", {
        ns: "fieldIrrigationHistory",

        val: formatValue("runSpeedMmpm", speedMmpmAverage),
      }),
      startDate: i18n.t("startDateWithVal", {
        ns: "fieldIrrigationHistory",
        val: dateFunctions.format(start, "Pp"),
      }),
    }
  }
  return undefined
}
export const getRunMetricsByIdFromState: (
  state: RootState,
  id: number | undefined,
) => ReturnType<typeof calculateMetricsForSelectedRun> | undefined =
  createCachedSelector(
    Models.fieldProfileReelRuns.selectById,
    getUserMeasurementPreferenceFromState,
    (runData, measurementPreference) => {
      if (runData) {
        return calculateMetricsForSelectedRun({
          measurementPreference,
          runData,
        })
      }
      return undefined
    },
  )(makeCacheKey)

export function useFieldProfileReelRuns() {
  const selectedRunId = useRootSelector(getSelectedHistoricalRunIdFromState)
  const [showUiSettings, setShowUiSettings] = React.useState(false)
  const showAllBorders = useRootSelector(getAreReelRunOutlinesVisibleFromState)
  const reelRunIds = useShallowEqualSelector(
    Models.fieldProfileReelRuns.selectIds,
  )
  return {
    isAnySelected: notNullish(selectedRunId),
    isAutozoomEnabled: useRootSelector(getIsFieldIrrigationAutozoomEnabled),
    onToggleUiSettings: React.useCallback(() => {
      setShowUiSettings(!showUiSettings)
    }, [showUiSettings]),
    reelRunIds,
    selectedRunGeometry: useShallowEqualSelector((state) => {
      if (isValidNumber(selectedRunId)) {
        return getSwathGeometryCachedByReelRunPropertiesFromState(state, {
          azimuthOverride: undefined,
          id: selectedRunId,
          variant: "historical",
        })
      }
      return undefined
    }),
    selectedRunId,
    showAllBorders,
    showUiSettings,
  }
}

// export function useFieldProfileTitle({
//   fieldId
// }: {
//   fieldId: number | undefined
// }):
//   | { acres: number | undefined; fieldName: string; subheaderText: string }
//   | undefined {
//   return useShallowEqualSelector((state) => {
//     const field = Models.field.selectById(state, fieldId)

//     if (field) {
//       const { areaAcres, fieldName } = field
//       if (isTruthyString(fieldName)) {
//         return {
//           acres: areaAcres,
//           fieldName,
//           subheaderText: `${fieldName}${
//             isValidNumber(areaAcres) ? ` (${areaAcres} acres)` : ""
//           }`
//         }
//       }
//     }
//     return undefined
//   })
// }

export function useReelRunCycler({ reelRunId }: ReelRunIdProps) {
  const index = useRootSelector((state) =>
    getHistoricalReelRunIndexByIdFromState(state, reelRunId),
  )
  const olderId = useRootSelector((state) => {
    if (isValidNumber(index)) {
      return Models.fieldProfileReelRuns.selectAll(state)[index - 1]?.reelRunId
    }
    return undefined
  })
  const newerId = useRootSelector((state) => {
    if (isValidNumber(index)) {
      return Models.fieldProfileReelRuns.selectAll(state)[index + 1]?.reelRunId
    }
    return undefined
  })

  return { index, newerId, olderId }
}

export interface ReelRunGraphProps extends MinDateMaxDate {
  maxValue: number
  values: ChartDatum[]
  xAxisTickValues: number[]
  yAxisFieldName: ReelRunGraphKey
}

export function calculateReelRunGraphData({
  graphKey,
  measurementPreference,
  runInfo,
}: MeasurementPreferenceProps & {
  graphKey: ReelRunGraphKey

  runInfo:
    | Pick<
        Models.ReelRun,
        | ReelRunGraphKey
        | "deviceEventTimestamps"
        | "endTimestamp"
        | "startTimestamp"
      >
    | undefined
}) {
  if (!runInfo) {
    return undefined
  }
  let maxValue: number
  switch (graphKey) {
    case "applicationRateReportsMm": {
      maxValue = 5
      break
    }
    case "pressureReportsKpa": {
      maxValue = 250
      break
    }
    case "speedReportsMmpm": {
      maxValue = 250
      break
    }
  }

  const startTimestamp = isTruthyString(runInfo.startTimestamp)
    ? new Date(runInfo.startTimestamp).getTime()
    : dateFunctions.subHours(new Date(), 8).getTime()

  const endTimestamp = isTruthyString(runInfo.endTimestamp)
    ? new Date(runInfo.endTimestamp).getTime()
    : Date.now()

  const runIntervalsMilliseconds = _.range(
    startTimestamp,
    endTimestamp,
    60 * 60 * 1000,
  )

  const initialValue: ReelRunGraphProps = {
    dateMsMax: endTimestamp,
    dateMsMin: startTimestamp,
    maxValue,
    values: [],
    xAxisTickValues: runIntervalsMilliseconds,
    yAxisFieldName: graphKey,
  }
  const valuesList = runInfo[graphKey] ?? []

  return valuesList.reduce(function generateGraphValues(
    acc,
    rawValue,
    index,
  ): ReelRunGraphProps {
    let timestamp = runInfo.deviceEventTimestamps[index]
    if (
      typeof runInfo.endTimestamp === "string" &&
      typeof runInfo.startTimestamp === "string"
    ) {
      const startMilliseconds = new Date(runInfo.startTimestamp).getTime()
      const endMilliseconds = new Date(runInfo.endTimestamp).getTime()

      const runLengthMinutes = (endMilliseconds - startMilliseconds) / 1000 / 60

      const spacing = runLengthMinutes / valuesList.length

      if (typeof timestamp === "undefined") {
        let fallbackTimestamp: Date
        if (index === 0) {
          fallbackTimestamp = new Date(runInfo.startTimestamp)
        } else {
          fallbackTimestamp = dateFunctions.addMinutes(
            new Date(runInfo.startTimestamp),
            spacing * index,
          )
        }
        timestamp = fallbackTimestamp.toISOString()
      }
    }
    if (isValidNumber(rawValue) && typeof timestamp === "string") {
      const value = requiresConversion(graphKey)
        ? convertSensorValue({
            fieldName: graphKey,
            measurementPreference,
            rawValue,
            target: "user",
          })
        : rawValue
      if (value > acc.maxValue) {
        acc.maxValue = value
      }
      const milliseconds = new Date(timestamp).getTime()
      return {
        ...acc,
        values: [...acc.values, { x: milliseconds, y: value }],
      }
    }
    return acc
  },
  initialValue)
}
export const getReelRunGraphDataByIdFromState: (
  _state: RootState,
  _id: number | undefined,
  graphKey: ReelRunGraphKey,
) => ReelRunGraphProps | undefined = createCachedSelector(
  Models.fieldProfileReelRuns.selectById,
  getUserMeasurementPreferenceFromState,
  (_state, _id, graphKey: ReelRunGraphKey) => graphKey,
  (runInfo, measurementPreference, graphKey): ReelRunGraphProps | undefined => {
    if (runInfo) {
      return calculateReelRunGraphData({
        graphKey,
        measurementPreference,
        runInfo,
      })
    }
    return undefined
  },
)(
  (_state, id: number | undefined, graphKey: ReelRunGraphKey) =>
    `${id ?? "undefined"}/${graphKey}`,
)

export function generateFieldRunHistoryItem({
  configuration,
  farmLocation,
  runData,
}: {
  configuration: DeviceConfiguration | null | undefined
  farmLocation: PointInput | null | undefined
  runData: Models.ReelRun
}) {
  let text = formatDateSafely(runData.startTimestamp)
  if (Boolean(runData.endTimestamp)) {
    const endTimestampText = formatDateSafely(runData.endTimestamp, "p")
    if (typeof endTimestampText === "string") {
      text += ` - ${endTimestampText}`
    }
  }
  // const configuration = configurations[runData.deviceId ?? ""]
  const application = calculateReelRunApplication(runData)

  const applicationText =
    typeof application.applicationAcreInchesTotal === "number"
      ? `${application.applicationAcreInchesTotal} ${i18next.t("acreInches")}`
      : undefined
  return {
    ...getSwathGeometry({
      ...runData,
      farmLocation,
    }),
    application: {
      ...application,
      applicationText,
    },
    deviceName:
      configuration?.deviceName ??
      configuration?.codaDeviceAlias ??
      i18n.t("unknown"),
    endTimestamp: runData.endTimestamp,
    reelRunId: runData.reelRunId,
    reelSwathWidthMm: runData.reelSwathWidthMm,
    startTimestamp: runData.startTimestamp,
    textPrimary: text,
  }
}
export const getFieldRunHistoryItemsFromState = createSelector(
  Models.fieldProfileReelRuns.selectAll,
  Models.deviceConfiguration.selectEntities,
  getActiveFarmCoordinatesFromState,
  (runs, configurations, farmLocation) =>
    runs.map((runData) => {
      return generateFieldRunHistoryItem({
        configuration:
          typeof runData.deviceId === "string"
            ? configurations[runData.deviceId]
            : undefined,
        farmLocation,
        runData,
      })
    }),
)
export const getReelRunIndexByRunIdFromState: (
  state: RootState,
  runId: number,
) => number = createSelector(
  Models.fieldProfileReelRuns.selectAll,
  (_state: RootState, runId: number) => runId,
  (reelRuns, runId) =>
    reelRuns.findIndex((reelRun) => reelRun.reelRunId === runId),
)

/**
 * @returns
 */
export function useFieldIrrigationHistory({
  fieldId,
}: {
  fieldId: number | undefined
}) {
  const reelRuns = useRootSelector(getFieldRunHistoryItemsFromState)
  const selectedRunId = useRootSelector(getSelectedHistoricalRunIdFromState)
  const { dateMsMax, dateMsMin, isDisabled, onChangeMaxDate, onChangeMinDate } =
    useFieldIrrigationHistoryDatePickers()
  const {
    dispatch,
    fetchStatus,

    handleError,
    isLoading,
    sendRequest,
    toasts,
    ...rest
  } = useBackendRequest(loadFieldIrrigationHistoryAsync)

  const handleFetch = React.useCallback(() => {
    if (isValidNumber(fieldId)) {
      sendRequest({
        fieldId,
        options: {
          // MAX DATE
          maxDateMs: dateMsMax,
          // MIN DATE
          minDateMs: dateMsMin,
        },
      }).catch(handleError)
    }
  }, [dateMsMax, dateMsMin, fieldId, handleError, sendRequest])

  const field = useShallowEqualSelector((state) =>
    Models.field.selectById(state, fieldId),
  )
  const selectedRunIndex = reelRuns.findIndex(
    (reelRun) => reelRun.reelRunId === selectedRunId,
  )
  return {
    ...rest,
    dateMsMax,
    dateMsMin,
    fetchStatus,
    field,
    handleError,
    handleFetch,
    handleSelectRun: (reelRunId: number) =>
      dispatch(selectReelRunHistorical({ reelRunId })),
    isDatepickerDisabled: isDisabled,
    isLoading,
    onChangeMaxDate,
    onChangeMinDate,
    reelRuns,
    selectedRunId,
    selectedRunIndex,

    sendRequest,
    showOutlines: useRootSelector(getAreReelRunOutlinesVisibleFromState),
    toasts,
  }
}
