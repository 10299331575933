import React from "react"

import { Container, TitleWithIcon } from "./components"
import {
  DevicePermissionsHeader,
  FarmPermissionsHeader,
  PermissionListItem,
} from "./PermissionListItem"
import { DEVICE_PERMISSIONS, FARM_PERMISSIONS } from "./permissions"
import {
  FarmUserPermissionHelpDialog,
  useFarmUserPermissionsHelpDialog,
} from "./PermissionsForm"
import { ResponsiveCard } from "./ResponsiveCard"
import { getFarmUserPermissionsFromState } from "./selectors"
import { getActiveFarmNameFromState } from "./user-farms.selectors"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"

export function ViewPermissions() {
  const farmName = useRootSelector(getActiveFarmNameFromState) ?? ""
  const permissions = useShallowEqualSelector(getFarmUserPermissionsFromState)
  const {
    helpKey,
    onCloseDialog: onClose,
    onOpenDialog,
  } = useFarmUserPermissionsHelpDialog()

  return (
    <React.Fragment>
      <FarmUserPermissionHelpDialog helpKey={helpKey} onClose={onClose} />
      <Container py="$2">
        <TitleWithIcon
          IconComponent="Farm"
          headingVariant="h5"
          titleText={farmName}
        />
      </Container>
      <ResponsiveCard mb="$4">
        <DevicePermissionsHeader />
        {DEVICE_PERMISSIONS.getKeys().map((key) => {
          const isEnabled = permissions?.[key] ?? false
          return (
            <PermissionListItem
              key={key}
              isEnabled={isEnabled}
              name={key}
              onPressHelp={onOpenDialog}
            />
          )
        })}
      </ResponsiveCard>
      <ResponsiveCard>
        <FarmPermissionsHeader />
        {FARM_PERMISSIONS.getKeys().map((key) => {
          const isEnabled = permissions?.[key] ?? false
          return (
            <PermissionListItem
              key={key}
              isEnabled={isEnabled}
              name={key}
              onPressHelp={onOpenDialog}
            />
          )
        })}
      </ResponsiveCard>
    </React.Fragment>
  )
}
