import classNames from "classnames"
import React from "react"
import { useHref, useNavigate } from "react-router"
import { Link, NavLink, useLinkClickHandler } from "react-router-dom"

import {
  Box,
  LinkText,
  renderIconFromIconProp,
  Row,
  useColorMode,
  useIsDarkMode,
} from "@fhq/app/components"
import { COLORS, FONT_SIZES } from "@fhq/app/components/theme"

import classes from "./CustomLink.module.css"

import type { IconProp, RowProps } from "@fhq/app/components"
import type { TestId } from "@fhq/app"

import type { LinkProps } from "react-router-dom"
import type { Overwrite } from "utility-types"
interface LinkContentProps {
  children: string
  IconComponent?: IconProp
}

export type CustomLinkProps = Overwrite<
  LinkContentProps & LinkProps,
  {
    id: TestId | undefined
    state?: unknown
  }
>
function LinkContent({
  IconComponent,
  children,
  ...rest
}: LinkContentProps & RowProps) {
  const isDark = useIsDarkMode()
  return (
    <Row alignItems="center" {...rest}>
      {renderIconFromIconProp(IconComponent, {
        color: isDark ? COLORS.$link.dark : COLORS.$link.light,
        size: FONT_SIZES.$lg,
      })}
      <Box ml="$1">
        {typeof children === "string" ? (
          <LinkText>{children}</LinkText>
        ) : (
          children
        )}
      </Box>
    </Row>
  )
}
function useClassNames() {
  const isDark = useColorMode() === "dark"
  const { currentPage, currentPageDark, root, rootDark } = classes as {
    [key: string]: string | undefined
  }
  return (props?: { isActive?: boolean }) => {
    const isActive = props?.isActive === true
    if (typeof currentPage === "undefined") {
      throw new TypeError(`no class named currentPage`)
    }
    if (typeof rootDark === "undefined") {
      throw new TypeError(`no class named rootDark`)
    }
    if (typeof currentPageDark === "undefined") {
      throw new TypeError(`no class named currentPageDark`)
    }
    return classNames(root, {
      [currentPage]: isActive,
      [currentPageDark]: isActive && isDark,
      [rootDark]: isDark,
    })
  }
}

function useCustomLink(props: CustomLinkProps) {
  const { id, onClick, replace, state, target, to } = props
  const href = useHref(to)
  const handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
  })
  return {
    ...props,
    "data-testid": id,
    "getClassNames": useClassNames(),
    href,
    "onClick": (event: React.MouseEvent<HTMLAnchorElement>) => {
      onClick?.(event)
      if (!event.defaultPrevented) {
        handleClick(event)
      }
    },
  }
}

export const CustomLink = React.forwardRef<HTMLAnchorElement, CustomLinkProps>(
  function CustomLink(props, ref) {
    const {
      IconComponent: iconName,
      children,
      getClassNames,
      ...rest
    } = useCustomLink(props)
    return (
      <Link ref={ref} {...rest} className={getClassNames()}>
        <LinkContent IconComponent={iconName}>{children}</LinkContent>
      </Link>
    )
  },
)
export const CustomNavLink = React.forwardRef<
  HTMLAnchorElement,
  CustomLinkProps
>(function CustomNavLink(props, ref) {
  const { IconComponent, children, getClassNames, ...rest } =
    useCustomLink(props)
  return (
    <NavLink
      ref={ref}
      className={({ isActive }) => getClassNames({ isActive })}
      {...rest}
    >
      <LinkContent IconComponent={IconComponent}>{children}</LinkContent>
    </NavLink>
  )
})
export const MenuItemNavLink = React.forwardRef<
  HTMLAnchorElement,
  CustomLinkProps
>(function MenuItemNavLink(props, ref) {
  const {
    IconComponent,
    children,
    getClassNames,
    replace,
    state,
    to,
    ...rest
  } = useCustomLink(props)
  const navigate = useNavigate()

  return (
    <NavLink
      {...rest}
      ref={ref}
      className={({ isActive }) => getClassNames({ isActive })}
      to={to}
      onClick={(e) => {
        navigate(to, { replace, state })
        if (props.onClick) {
          props.onClick(e)
        }
      }}
    >
      <Box py="$2">
        <LinkContent IconComponent={IconComponent}>{children}</LinkContent>
      </Box>
    </NavLink>
  )
})
