import React from "react"
import { FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import * as Auth from "./Auth"
import { signUpAsync, useAuthFormHelpers } from "./auth.reducer"
import { Box } from "./components"
import { FormError } from "./components/FormError"
import { useIsPending } from "./selectors"
import { useRootDispatch } from "./useRootDispatch"

/**
 *
 */
export function SignUpForm({
  onPressCancel,
  onSuccess,
}: {
  onPressCancel: () => void
  onSuccess: (params: { email: string }) => void
}) {
  const dispatch = useRootDispatch()
  const { t } = useTranslation("auth")
  const { form, formErrorMessage, handleError } = useAuthFormHelpers({})
  const spacing = "$4"
  return (
    <FormProvider {...form}>
      <Auth.PageTitle>{t("createAnAccount")}</Auth.PageTitle>
      <Box mt={spacing}>
        <Auth.EmailField />
      </Box>
      <Box mt={spacing}>
        <Auth.NewPasswordField />
      </Box>
      <Box mt={spacing}>
        <Auth.ConfirmPasswordField />
      </Box>
      <ActionButtons
        isLoading={useIsPending("signUp")}
        mt="$8"
        onPressCancel={onPressCancel}
        onPressSubmit={form.handleSubmit(({ email, password }) => {
          dispatch(signUpAsync({ email, password }))
            .unwrap()
            .then(() => onSuccess({ email }))
            .catch((error) => handleError(error))
        })}
      />
      <Box mt={spacing}>
        <FormError>{formErrorMessage}</FormError>
      </Box>
    </FormProvider>
  )
}
