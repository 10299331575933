import React from "react"
import { StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { AppText } from "./Text"
import { FONT_SIZES, getFontName } from "./theme"
import { COLORS } from "./theme/colors"
import { RADII } from "./theme/radii"

import type { ViewProps } from "react-native"

const DEFAULT_SIZE_PX = 24

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    backgroundColor: COLORS.$red[500],
    borderRadius: RADII.$full,
    color: COLORS.$textLight.primary,
    display: "flex",
    height: DEFAULT_SIZE_PX,
    justifyContent: "center",
    width: DEFAULT_SIZE_PX,
  },
  text: {
    color: COLORS.$textLight.primary,
    fontFamily: getFontName("OpenSans_500Medium"),
    fontSize: FONT_SIZES.$sm,
    margin: "auto",
  },
})

export type CounterProps = ViewProps

/**
 * Push notification-style counter to hang over buttons etc.
 */
export function Counter({
  count,
  style,
  ...rest
}: CounterProps & {
  count: number
}) {
  return (
    <View {...rest} style={useStyle(() => [styles.root, style])}>
      <AppText allowFontScaling={false} style={styles.text}>
        {count}
      </AppText>
    </View>
  )
}
