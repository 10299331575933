import { logger } from "../logger"
import { isTruthyString, isValidNumber } from "../type-guards"

/**
 * Construct a date from a value.
 *
 * @param value - The value to construct a date from.
 *
 * @returns The date constructed from the value, or undefined if the value is not a valid date.
 */
export function makeDate(
  value: Date | number | string | null | undefined,
): Date | undefined {
  try {
    if (isValidNumber(value) || isTruthyString(value)) {
      return new Date(value)
    }
    if (value instanceof Date) {
      return value
    }
  } catch (error) {
    logger.error(
      "unable to construct date from value",
      value,
      typeof value,
      error,
    )
  }
  return undefined
}
