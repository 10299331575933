import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import { Box, FormControl } from "./components"
import { IconButton } from "./components/Button"
import { AppText } from "./components/Text"
import { SPACING } from "./components/theme"
import * as DeviceListItem from "./DevicesListItem"
import {
  clearCommentsForDeviceAsync,
  createDeviceCommentAsync,
} from "./farmhq-api"
import { useBackendRequest } from "./useBackendRequest"

import type { BoxProps } from "./components"
import type * as Models from "./models"
import type { DeviceIdProps } from "./types"
interface Props extends DeviceIdProps, BoxProps {
  onClose: () => void
}

const MAX_LENGTH_CHARACTERS = 255

function useTexts() {
  return useTranslation("deviceComments")
}

/**
 * Form to create a new comment for a device.
 */
export function CreateDeviceCommentForm({
  deviceId,
  onClose,
  ...rest
}: Props): React.JSX.Element {
  const { t } = useTexts()
  const { control, handleSubmit } = useForm({
    defaultValues: { contentText: "" },
  })
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    createDeviceCommentAsync,
  )
  const onSubmit = handleSubmit(({ contentText }) => {
    sendRequest({ contentText, deviceId })
      .then(onClose)
      .then(() => toasts.success())
      .catch((error) => handleError(error, { toastMessage: "default" }))
  })

  const labelText: string = t("newDeviceComment")
  return (
    <Box {...rest}>
      <Controller
        control={control}
        name="contentText"
        render={({ field: { onChange, ...field }, fieldState }) => {
          const errorMessage = fieldState.error?.message
          return (
            <FormControl.Provider
              isDisabled={isLoading}
              isInvalid={Boolean(errorMessage)}
            >
              <FormControl.Label>{labelText}</FormControl.Label>
              <FormControl.Input
                multiline
                maxLength={MAX_LENGTH_CHARACTERS}
                numberOfLines={4}
                onChangeText={onChange}
                {...field}
              />
              <FormControl.HelperText style={{ marginVertical: SPACING.$4 }}>
                {t(
                  "thisCommentWillBeVisibleToAllOtherUsersAssociatedWithYourFarmAccount",
                )}
              </FormControl.HelperText>
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
        rules={{
          maxLength: {
            message: t("maxLengthWithVal", {
              ns: "validation",
              val: MAX_LENGTH_CHARACTERS,
            }),
            value: MAX_LENGTH_CHARACTERS,
          },
          required: {
            message: t("required", { ns: "validation" }),
            value: true,
          },
        }}
      />
      <ActionButtons
        isLoading={isLoading}
        mt="$4"
        onPressCancel={onClose}
        onPressSubmit={onSubmit}
      />
    </Box>
  )
}

/**
 * Display the current comment and provide buttons to edit or delete it.
 * @param {Models.DeviceComment & { onPressEdit: () => void }} props
 */
export function CurrentComment({
  onPressEdit,
  ...comment
}: Models.DeviceComment & { onPressEdit: () => void }) {
  const { t } = useTexts()
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    clearCommentsForDeviceAsync,
  )
  const [isDeleting, setIsDeleting] = React.useState(false)

  const element = (
    <DeviceListItem.DeviceCommentDisplay
      flex={1}
      deleteButtonElement={
        <IconButton
          IconComponent="TrashCan"
          isDisabled={isLoading}
          onPress={() => setIsDeleting(true)}
        />
      }
      editButtonElement={
        <IconButton
          IconComponent="Edit"
          isDisabled={isDeleting || isDeleting}
          onPress={onPressEdit}
        />
      }
      {...comment}
    />
  )

  if (isDeleting) {
    return (
      <React.Fragment>
        {element}
        <AppText>{t("clearThisNote")}</AppText>
        <ActionButtons
          isLoading={isLoading}
          onPressCancel={() => setIsDeleting(false)}
          onPressSubmit={() => {
            sendRequest({ deviceId: comment.device })
              .then(() => setIsDeleting(false))
              .catch((error) => {
                handleError(error, {
                  toastMessage: "default",
                })
              })
          }}
        />
      </React.Fragment>
    )
  }
  return element
}
