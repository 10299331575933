import React from "react"
import { useStyle } from "react-native-style-utilities"

import { Box } from "./Box"
import { Card } from "./Card"
import { AppIcons, ICON_SIZES, renderIconFromIconProp } from "./icons/AppIcons"
import { Row } from "./Row"
import { AppText } from "./Text"
import { SPACING } from "./theme"
import { COLORS } from "./theme/colors"
import { useIsDarkMode } from "./ThemeProvider"

import type { CardProps } from "./Card"
import type { AppTextProps } from "./Text"
export const ALERT_STATUSES = ["success", "info", "error", "warning"] as const
export type AlertStatus = (typeof ALERT_STATUSES)[number]

export interface AlertProps extends CardProps {
  bodyText?: string
  severity?: AlertStatus
}

/**
 * Displays the body of an alert message. Alerts have lighter backgrounds,
 * so we use dark text to ensure good contrast.
 */
export function AlertBodyText({
  colorScheme = "darkText",
  ...rest
}: AppTextProps) {
  return <AppText colorScheme={colorScheme} {...rest} />
}

const TITLE_TEXT_PROPS: CardProps["_titleTextProps"] = {
  colorScheme: "darkText",
}
export type AlerCardProps = AlertProps & CardProps

/**
 * A card that displays an alert message.
 */
export function AlertCard({
  IconComponent,
  actionElement,
  bodyText,
  children,
  id,
  severity = "info",
  style,
  titleText,
  ...rest
}: AlerCardProps) {
  let backgroundColor: string

  const isDark = useIsDarkMode()
  let iconColor: string | undefined

  switch (severity) {
    case "success": {
      backgroundColor = isDark ? COLORS.$green[700] : COLORS.$green[200]
      if (typeof IconComponent === "undefined") {
        IconComponent = AppIcons.Success
      }

      break
    }
    case "error": {
      backgroundColor = isDark ? COLORS.$red[600] : COLORS.$red[300]
      if (typeof IconComponent === "undefined") {
        IconComponent = AppIcons.Error
      }

      break
    }
    case "info": {
      backgroundColor = COLORS.$blue[100]
      if (isDark) {
        iconColor = COLORS.$textDark.secondary
        backgroundColor = COLORS.$blue[200]
      }

      if (typeof IconComponent === "undefined") {
        IconComponent = AppIcons.Info
      }

      break
    }
    case "warning": {
      backgroundColor = COLORS.$orange[300]
      if (isDark) {
        backgroundColor = COLORS.$orange[300]
        iconColor = COLORS.$textDark.secondary
      }
      if (typeof IconComponent === "undefined") {
        IconComponent = AppIcons.Warning
      }
      break
    }
  }

  if (typeof children === "string") {
    children = <AlertBodyText>{children}</AlertBodyText>
  }
  if (typeof children === "undefined" && typeof bodyText === "string") {
    children = <AlertBodyText>{bodyText}</AlertBodyText>
  }
  let headerIconComponent: typeof IconComponent | undefined = IconComponent
  if (typeof titleText === "undefined") {
    const iconElement = renderIconFromIconProp(IconComponent, {
      color: iconColor,
      size: ICON_SIZES.$lg,
    })
    children = (
      <Row alignItems="center" flexWrap="nowrap">
        {iconElement ? <Box mr="$4">{iconElement}</Box> : null}
        <Box flex={1}>{children}</Box>
      </Row>
    )
    headerIconComponent = undefined
  }

  return (
    <Card
      IconComponent={headerIconComponent}
      _titleTextProps={TITLE_TEXT_PROPS}
      actionElement={actionElement}
      id={id}
      pt="$3"
      titleText={titleText}
      style={useStyle(
        () => [
          {
            backgroundColor,
            paddingBottom: SPACING.$2,
            paddingTop: SPACING.$2,
          },
          style,
        ],
        [backgroundColor, style],
      )}
      {...rest}
    >
      {children}
    </Card>
  )
}
