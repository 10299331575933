import React from "react"
import { ScrollView } from "react-native"

import { ActionButtons } from "./ActionButtons"
import { Container, TitleWithIcon } from "./components"
import { AppText } from "./components/Text"
import {
  loadFarmPermissionsForUserAsync,
  updateFarmUserPermissionsAsync,
} from "./farmhq-api"
import {
  DevicePermissionsHeader,
  FarmPermissionsHeader,
  PermissionListItem,
} from "./PermissionListItem"
import {
  DEVICE_PERMISSIONS,
  FARM_PERMISSIONS,
  FARM_USER_PERMISSIONS,
} from "./permissions"
import {
  FarmUserPermissionHelpDialog,
  useFarmUserPermissionsHelpDialog,
} from "./PermissionsForm"
import { ResponsiveCard } from "./ResponsiveCard"
import { useBackendRequest } from "./useBackendRequest"
import { getActiveFarmNameFromState } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type { FarmUserPermissionName, FarmUserPermissions } from "./permissions"
import type { ManagePermissionsParams } from "./navigation"
export function useLoadFarmUserPermissionsForUser({
  isFocused,
  targetUserId,
}: {
  isFocused: boolean
  targetUserId?: string
}) {
  const [isError, setIsError] = React.useState(false)
  const { isLoading, sendRequest } = useBackendRequest(
    loadFarmPermissionsForUserAsync,
  )
  const [permissions, setPermissions] = React.useState(
    FARM_USER_PERMISSIONS.allFalse,
  )
  React.useEffect(() => {
    if (isFocused && typeof targetUserId === "string") {
      sendRequest({ targetUserId })
        .then((response) => {
          return setPermissions(response)
        })
        .catch(() => {
          setIsError(true)
        })
    }
  }, [isFocused, sendRequest, targetUserId])
  const handleValueChange = (key: FarmUserPermissionName) => {
    return (next: boolean): void =>
      setPermissions({ ...permissions, [key]: next })
  }
  return {
    isError,
    isLoading,
    onValueChange: handleValueChange,
    permissions,
  }
}

/**
 *
 */
export function ManagePermissionsForm({
  onChange: handleValueChange,
  onClose,
  permissions,
  targetUserEmail: email,
  targetUserId,
}: ManagePermissionsParams & {
  onChange: (name: FarmUserPermissionName) => (nextValue: boolean) => void
  onClose: () => void
  permissions: FarmUserPermissions
}) {
  const { helpKey, onCloseDialog, onOpenDialog } =
    useFarmUserPermissionsHelpDialog()
  const farmName = useRootSelector(getActiveFarmNameFromState) ?? ""

  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    updateFarmUserPermissionsAsync,
  )

  return (
    <ScrollView>
      <FarmUserPermissionHelpDialog helpKey={helpKey} onClose={onCloseDialog} />
      <Container py="$2">
        <AppText>{email}</AppText>
        <TitleWithIcon
          IconComponent="Farm"
          headingVariant="h5"
          titleText={farmName}
        />
      </Container>
      <ResponsiveCard mb="$4">
        <DevicePermissionsHeader />
        {DEVICE_PERMISSIONS.getKeys().map((key) => {
          const isEnabled = permissions[key]
          return (
            <PermissionListItem
              key={key}
              isEnabled={isEnabled}
              name={key}
              onPressHelp={onOpenDialog}
              onValueChange={handleValueChange(key)}
            />
          )
        })}
      </ResponsiveCard>
      <ResponsiveCard>
        <FarmPermissionsHeader />
        {FARM_PERMISSIONS.getKeys().map((key) => {
          const isEnabled = permissions[key]
          return (
            <PermissionListItem
              key={key}
              isEnabled={isEnabled}
              name={key}
              onPressHelp={onOpenDialog}
              onValueChange={handleValueChange(key)}
            />
          )
        })}
      </ResponsiveCard>
      <ActionButtons
        isLoading={isLoading}
        m="$4"
        onPressSubmit={() => {
          sendRequest({ permissions, targetUserId })
            .then(() => {
              toasts.success()
              return onClose()
            })
            .catch((error) => {
              handleError(error, { toastMessage: "default" })
            })
        }}
      />
    </ScrollView>
  )
}
