import snakecaseKeys from "snakecase-keys"

import { Auth } from "@aws-amplify/auth"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { clearStoredFarmIds, removeItem } from "./async-storage"
import { getNodeEnv } from "./constants"
import { logger } from "./logger"
import { getApiUrl } from "./Requests"
import { canSeedTestDatabase } from "./selectors"

import type { SeedDatabaseParams } from "./constants"
import type { LoadActiveFarmResponse } from "./models"

import type { RootThunkConfig } from "./root.reducer"
import type { GetActionArgumentsType, GetRequestBodyType } from "./send-request"
export interface SeedDatabaseResponse {
  actionArguments: GetActionArgumentsType<"SeedTestDatabase">
  responseData: LoadActiveFarmResponse
}

async function seedDatabase({
  appName,
  appVersion,
  authToken,
  isEndToEndTest,
  isWeb,
  localhost,
  options,
  shouldReloadPage,
  targetDatabaseName,
}: SeedDatabaseParams): Promise<SeedDatabaseResponse> {
  if (!canSeedTestDatabase(targetDatabaseName)) {
    throw new TypeError(`Cannot target ${targetDatabaseName} in seed database`)
  }

  const backend = getApiUrl({
    isEndToEndTest: false,
    localhost,
    targetDatabaseName,
  })
  if (backend.dbName === "PROD" || backend.dbName === "BETA") {
    throw new TypeError(`Cannot target ${backend.dbName} in seed database`)
  }

  const body: GetRequestBodyType<"SeedTestDatabase"> = {
    actionArguments: {
      // ...DEFAULT_SEED_DATABASE_OPTIONS,
      ...options,
    },
    activeFarmId: null,
    extra: {
      appName,
      appVersion,
      isEndToEndTest,
      nodeEnv: getNodeEnv(),
      targetDatabaseName,
    },
    requestedAction: "SeedTestDatabase",
  }
  if (typeof authToken === "undefined") {
    throw new TypeError(`[seedTestDatabase.ts]: Auth token is undefined`)
  }
  try {
    const response = await fetch(backend.url, {
      body: JSON.stringify(snakecaseKeys(body, { deep: true })),
      headers: { Authorization: authToken },
      method: "POST",
    })

    if (isWeb && shouldReloadPage) {
      window.location.reload()
    }
    const asJson = (await response.json()) as unknown

    return {
      actionArguments: body.actionArguments,
      responseData: asJson as LoadActiveFarmResponse,
    }
  } catch (error) {
    logger.error(error)
    throw error
  }
}

export const seedTestDatabaseAsync = createAsyncThunk<
  undefined,
  GetActionArgumentsType<"SeedTestDatabase">,
  RootThunkConfig
>(
  "SeedTestDatabase",
  async (actionArguments, { extra: { appName, isEndToEndTest, ...extra } }) => {
    try {
      const session = await Auth.currentSession()
      const authToken = session.getIdToken().getJwtToken()
      await clearStoredFarmIds()
      await removeItem("phoneNumberPromptStatus")

      await seedDatabase({
        appName,
        authToken,
        ...extra,
        isEndToEndTest: false,
        isWeb: appName === "FarmHQ Web",
        options: actionArguments,
        shouldReloadPage: !isEndToEndTest,
      })

      return undefined
    } catch (error) {
      logger.error(error)
      throw error
    }
  },
)
