import React from "react"
import { ActivityIndicator } from "react-native"

import { COLORS } from "./theme/colors"

import type { ActivityIndicatorProps } from "react-native"

/**
 * Encapsulates the native `ActivityIndicator` component with a default color.
 */
export function Spinner(props: ActivityIndicatorProps) {
  return <ActivityIndicator color={COLORS.$gray[500]} {...props} />
}
