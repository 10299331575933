import { Urls } from "./Internal"
import { makeFileLogger } from "./logger"

import type { BackendRequestName } from "./send-request"

import type { SetDifference } from "utility-types"

import type { RemoteDatabaseName, TargetDatabaseName } from "./Internal"
import type { AnalyticsProps, NodeEnv, SharedEnvironment } from "./types"

const fileLogger = makeFileLogger({ fileName: "Requests" })

export interface GetApiUrlParams {
  isEndToEndTest: boolean
  localhost: string | null
  targetDatabaseName: TargetDatabaseName
}

export interface RootThunkExtra extends GetApiUrlParams, AnalyticsProps {
  appName: string
  appVersion: string
  environmentInfo: SharedEnvironment
}

export interface BackendRequestBody<
  N extends BackendRequestName = BackendRequestName,
  A extends object | null | undefined | void = object | null | undefined | void,
> {
  actionArguments: A
  activeFarmId: number | null | undefined
  extra: Pick<
    RootThunkExtra,
    "appName" | "appVersion" | "isEndToEndTest" | "targetDatabaseName"
  > & {
    nodeEnv: NodeEnv
  }
  requestedAction: N
}

export type FetchStatus = "fulfilled" | "pending" | "rejected"

// export type GetFetchStatus<T extends FetchStatus> = T extends T ? T : never

export function getApiUrl({
  isEndToEndTest,
  localhost,
  targetDatabaseName,
}: GetApiUrlParams):
  | {
      dbName: SetDifference<TargetDatabaseName, "LOCAL">
      url: `${string}/${RemoteDatabaseName}`
    }
  | { dbName: "LOCAL"; url: string } {
  if (
    isEndToEndTest &&
    (targetDatabaseName === "BETA" || targetDatabaseName === "PROD")
  ) {
    fileLogger.info(`Changing target db to DEV for cypress testing`)
    targetDatabaseName = "DEV"
  }
  if (targetDatabaseName === "LOCAL") {
    if (!__DEV__) {
      fileLogger.error("targeting localhost from deployed code")
    }
    const url = `http://${localhost ?? "localhost"}:8000/django/`
    return { dbName: targetDatabaseName, url }
  }

  return {
    dbName: targetDatabaseName,
    url: Urls.getRemoteBackend(targetDatabaseName),
  }
}

export interface TestUser {
  email: string | undefined
  id: string | undefined
  password: string | undefined
}
export type TestUsers = {
  [key in TargetDatabaseName]?: TestUser
}
