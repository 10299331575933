import { AWS_REGION } from "./constants"

import type { RemoteDatabaseName, TargetDatabaseName } from "./Internal"

const IDENTITY_POOL_ID =
  `us-west-2:377f50ea-27ee-46c1-8aa2-2f3b6d8b7b39` as const
export interface CognitoUserPoolParams {
  identityPoolId: string
  region: string
  userPoolId: string
  userPoolWebClientId: string
}

const DB_NAME_TO_USER_POOL_CREDENTIALS: {
  [key in RemoteDatabaseName]: CognitoUserPoolParams
} = {
  BETA: {
    identityPoolId: IDENTITY_POOL_ID,
    region: AWS_REGION,
    userPoolId: "us-west-2_0LE7ruT9v",
    userPoolWebClientId: "1ngqrgne7j9qk9o71ku7jg00st",
  },
  DEMO: {
    identityPoolId: IDENTITY_POOL_ID,
    region: AWS_REGION,
    userPoolId: "us-west-2_lrIInKWkI",
    userPoolWebClientId: "63i9d51qe5heg5mlbvqa59td13",
  },
  DEV: {
    identityPoolId: "us-west-2:45eab45b-5655-4180-b20a-cda3b78e860d",
    region: AWS_REGION,
    userPoolId: "us-west-2_izfCnYzur",
    userPoolWebClientId: "491c10dvoanpubpcv4jc8rj080",
  },
  PROD: {
    identityPoolId: IDENTITY_POOL_ID,
    region: AWS_REGION,
    userPoolId: "us-west-2_vY9ky781T",
    userPoolWebClientId: "nl7di6o994hgsuuilc3qiuire",
  },
}

export function getUserPoolParams(targetDatabaseName: TargetDatabaseName): {
  identityPoolId: string
  region: string
  userPoolId: string
  userPoolWebClientId: string
} {
  if (targetDatabaseName === "LOCAL") {
    return DB_NAME_TO_USER_POOL_CREDENTIALS.DEV
  }
  return DB_NAME_TO_USER_POOL_CREDENTIALS[targetDatabaseName]
}
