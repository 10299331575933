import React from "react"
import { Controller, useForm, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import { Instructions } from "./components"
import { Box } from "./components/Box"
import { Row } from "./components/Row"
import { setFieldRowDirectionAsync } from "./farmhq-api"
import { useCancelFieldAction } from "./fields.reducer"
import { useFormValidation } from "./form-validation"
import * as Models from "./models"
import { useIsPending } from "./selectors"
import { AzimuthDisplayText, SingleValueSlider } from "./sliders"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { ControllerProps } from "react-hook-form"
import type { BoxProps } from "./components/Box"

import type * as ManageFieldContext from "./FieldProfileContext"
export interface FormValues {
  rowDirectionAzimuthDegrees: number
}
export function Actions({
  fieldId,
  onClosed,
}: {
  fieldId: number
  onClosed: () => void
}) {
  const { handleSubmit } = useFormContext<FormValues>()
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    setFieldRowDirectionAsync,
  )
  return (
    <ActionButtons
      isLoading={isLoading}
      onPressCancel={onClosed}
      onPressSubmit={handleSubmit(async (values): Promise<void> => {
        try {
          await sendRequest({
            fieldId,
            value: values.rowDirectionAzimuthDegrees,
          }).then(() => {
            onClosed()

            return toasts.success()
          })
        } catch (error) {
          handleError(error)
        }
      })}
    />
  )
}
export function SliderInstructions() {
  const { t } = useTranslation("fields")

  return <Instructions>{t("setFieldRowDirectionDragSliderHint")}</Instructions>
}
export function Slider() {
  const { control } = useFormContext<FormValues>()
  const { required } = useFormValidation()
  const isLoading = useIsPending("SetFieldRowDirection")
  return (
    <Controller
      control={control}
      name="rowDirectionAzimuthDegrees"
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <Row id="azimuth-slider" my="$2">
          <Box mb="$8" mr="$4" w="$24">
            <AzimuthDisplayText value={value} />
          </Box>
          <Box flex={1} my="$2">
            <SingleValueSlider
              disabled={isLoading}
              maximumValue={359}
              minimumValue={0}
              value={value}
              onValueChange={onChange}
            />
          </Box>
        </Row>
      )}
    />
  )
}
/**
 *
 */
export function SetFieldRowDirection({
  fieldId,
  onClosed,
  ...rest
}: BoxProps & {
  fieldId: number
  onClosed?: () => void
}): React.JSX.Element | null {
  const { t } = useTranslation("fields")
  const { required } = useFormValidation()

  const defaultValue = useRootSelector(
    (state) =>
      Models.field.selectById(state, fieldId)?.rowDirectionAzimuthDegrees ?? 0,
  )

  const { control, handleSubmit } = useForm({
    defaultValues: {
      rowDirectionAzimuthDegrees: defaultValue,
    },
  })
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    setFieldRowDirectionAsync,
  )
  const onCancel = useCancelFieldAction()

  const onSubmit = handleSubmit((values) => {
    if (typeof fieldId !== "number") {
      throw new TypeError(`fieldId is undefined`)
    }
    sendRequest({
      fieldId,
      value: values.rowDirectionAzimuthDegrees,
    })
      .then(() => {
        if (onClosed) {
          onClosed()
        }
        return toasts.success()
      })
      .catch((error) => {
        handleError(error, { toastMessage: "default" })
      })
  })

  const renderSlider: ControllerProps<
    ManageFieldContext.FieldActionsFormData,
    "rowDirectionAzimuthDegrees"
  >["render"] = React.useCallback(
    ({ field: { onChange, value } }) => (
      <Row id="azimuth-slider" my="$2">
        <Box w="$24">
          <AzimuthDisplayText value={value} />
        </Box>
        <Box flex={1} my="$2">
          <SingleValueSlider
            disabled={isLoading}
            maximumValue={359}
            minimumValue={0}
            value={value}
            onValueChange={onChange}
          />
        </Box>
      </Row>
    ),
    [isLoading],
  )

  return (
    <Box {...rest} id="set-field-row-direction">
      <Instructions>{t("setFieldRowDirectionDragSliderHint")}</Instructions>
      <Controller
        // @ts-ignore
        control={control}
        name="rowDirectionAzimuthDegrees"
        render={renderSlider}
        rules={{ required }}
      />
      <ActionButtons
        buttonSize="sm"
        isLoading={isLoading}
        onPressCancel={onClosed ?? onCancel}
        onPressSubmit={onSubmit}
      />
    </Box>
  )
}
