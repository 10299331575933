import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { ActionButtons } from "./ActionButtons"
import {
  AlertDialog,
  AlertDialogScrollView,
  AppIcons,
  Background,
  Box,
  Button,
  CancelButton,
  Container,
  FlatList,
  ICON_SIZES,
  ListItemCard,
  renderIconFromIconProp,
  Row,
  SubmitButton,
  Switch,
  TextWithIcon,
  TitleWithIcon,
  useBreakpointValue,
} from "./components"
import { SPACING } from "./components/theme"
import { DeviceConnectionDisplay } from "./DeviceConnectionDisplay"
import {
  deleteEventActionTriggersAsync,
  updateDeviceConnectionAsync,
} from "./farmhq-api"
import * as Models from "./models"
import { NoListItems } from "./NoListItems"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { FlatListProps, HelpContentStatic } from "./components"
import type { DeviceIdProps, PermissionCheckProps } from "./types"

const useTexts = () => {
  return useTranslation("deviceConnections")
}
export const HELP_CONTENT: HelpContentStatic = {
  bodyText: i18next.t("createDeviceConnection:helpBodyText"),
  subject: "device_connections",
  titleText: i18n.t("createDeviceConnection:aboutDeviceConnections"),
}
export function useDeviceConnectionsListData() {
  return useRootSelector((state) => {
    const allTriggers = Models.trigger.selectAll(state)
    allTriggers.sort((a, b) => {
      return a.sourceDeviceId.localeCompare(b.sourceDeviceId)
    })

    return allTriggers.filter((trigger) => {
      if (typeof trigger.targetDeviceId === "string") {
        return true
      }
      return false
    })
  })
}

export function useDeleteDeviceConnection({
  onDeleteSuccess,
}: {
  onDeleteSuccess?: () => void
}) {
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    deleteEventActionTriggersAsync,
  )
  const handleSubmit = ({
    connectionId,
    sourceDeviceId,
  }: {
    connectionId: number
    sourceDeviceId: string
  }) => {
    //
    sendRequest({
      deviceId: sourceDeviceId,
      ids: [connectionId],
      kind: "automation",
    })
      .then(() => {
        if (onDeleteSuccess) {
          onDeleteSuccess()
        }
        return toasts.success()
      })

      .catch((error) =>
        handleError(error, {
          toastMessage: "default",
        }),
      )
  }
  return { handleSubmit, isLoading }
}
/**
 * Delete a device connection
 */
export function ConfirmDeleteDeviceConnection({
  connectionId,
  onCancel,
  onDeleteSuccess,
  sourceDeviceId,
}: PermissionCheckProps & {
  connectionId: number
  onCancel: () => void

  sourceDeviceId: string
  onDeleteSuccess?: () => void
}) {
  const { t } = useTexts()

  const { handleSubmit, isLoading } = useDeleteDeviceConnection({
    onDeleteSuccess,
  })

  return (
    <React.Fragment>
      <TitleWithIcon
        IconComponent="Warning"
        subheaderElement={t("areYouSureYouWantToDisconnectTheseDevices?")}
        titleText={t("deleteConnection")}
      />
      <Row mt="$4">
        <Box flex={1} mr="$2">
          <CancelButton isDisabled={isLoading} onPress={onCancel} />
        </Box>
        <SubmitButton
          flex={1}
          isLoading={isLoading}
          onPress={() => handleSubmit({ connectionId, sourceDeviceId })}
        />
      </Row>
    </React.Fragment>
  )
}

/**
 * Component to toggle notifications for a device connection
 */
function ToggleNotify({
  connectionId,
  currentValue,
}: {
  connectionId: number
  currentValue: boolean
}) {
  const { handleError, sendRequest, toasts } = useBackendRequest(
    updateDeviceConnectionAsync,
  )
  const { t } = useTexts()
  const handleChange = () => {
    let nextValue: boolean
    let successMessage: string
    if (currentValue) {
      nextValue = false
      successMessage = t("notificationsDisabled")
    } else {
      nextValue = true
      successMessage = t("notificationsEnabled")
    }

    sendRequest({
      connectionId,
      notify: nextValue,
    })
      .then(() => toasts.success(successMessage))
      .catch((error) => handleError(error, { toastMessage: "default" }))
  }
  const getValue = useBreakpointValue()
  const iconElement = renderIconFromIconProp("NotificationsOn", {
    colorScheme: "secondary",
    size: ICON_SIZES.$2xl,
  })
  return (
    <Row>
      <Box mr="$2">
        {getValue({
          base: iconElement,
          sm: (
            <TextWithIcon
              IconComponent={iconElement}
              text={t("sendNotificationsToUsers")}
            />
          ),
        })}
      </Box>
      <Box>
        <Switch value={currentValue} onValueChange={handleChange} />
      </Box>
    </Row>
  )
}
export function DeleteConnectionDialog({
  onCancelDelete,
  onSuccess,
  toDeleteId,
}: {
  onCancelDelete: () => void
  onSuccess: () => void
  toDeleteId: number
}) {
  const { t } = useTexts()
  const { handleSubmit, isLoading } = useDeleteDeviceConnection({
    onDeleteSuccess: onSuccess,
  })
  const toDelete = useRootSelector((state) => {
    return Models.trigger.selectById(state, toDeleteId)
  })
  if (!toDelete) {
    return null
  }

  return (
    <AlertDialog
      isOpen
      titleElement={t("deleteConnection")}
      actionElement={
        <ActionButtons
          isLoading={isLoading}
          orientation="horizontal"
          onPressCancel={onCancelDelete}
          onPressSubmit={() => {
            handleSubmit({
              connectionId: toDelete.id,
              sourceDeviceId: toDelete.sourceDeviceId,
            })
          }}
        />
      }
      onClose={onCancelDelete}
    >
      <AlertDialogScrollView>
        <TextWithIcon
          IconComponent="Warning"
          text={t("areYouSureYouWantToDisconnectTheseDevices?")}
        />
        <Box my="$4">
          <DeviceConnectionDisplay {...toDelete} />
        </Box>
      </AlertDialogScrollView>
    </AlertDialog>
  )
}

type ItemType = ReturnType<typeof useDeviceConnectionsListData>[number]

const keyExtractor = (item: { id: number }): string => `${item.id}`

const ItemSeparatorComponent = () => <Box mb="$4" />

const styles = StyleSheet.create({
  contentContainer: {
    paddingBottom: SPACING.$toolbar,
  },
})

export function DeviceConnectionsList({
  onPressCreateConnection,
  onPressDevice,
  withPermissions,
  ...rest
}: Omit<FlatListProps<ItemType>, "data" | "renderItem"> &
  PermissionCheckProps & {
    onPressCreateConnection: () => void
    onPressDevice: (
      params: DeviceIdProps & {
        codaDeviceAlias: string
      },
    ) => void
  }) {
  const { t } = useTexts()
  const data = useDeviceConnectionsListData()
  const [idToDelete, setIdToDelete] = React.useState<number>()
  const handlePressCreateConnection = withPermissions({
    callback: onPressCreateConnection,
    required: "canManageCustomTriggers",
  })
  const addConnectionButton = (
    <Button
      IconComponent="Add"
      style={{ marginLeft: "auto" }}
      text={t("connectDevices")}
      onPress={handlePressCreateConnection}
    />
  )
  const onCancelDelete = () => setIdToDelete(undefined)
  // TODO: Implement a FAB for adding a new connection
  return (
    <FlatList
      {...rest}
      ItemSeparatorComponent={ItemSeparatorComponent}
      contentContainerStyle={styles.contentContainer}
      data={data}
      id="device-connections"
      keyExtractor={keyExtractor}
      ListEmptyComponent={
        <Container>
          <NoListItems
            action={data.length === 0 ? addConnectionButton : null}
            message={t("deviceConnectionsListEmpty")}
          />
        </Container>
      }
      ListHeaderComponent={
        <React.Fragment>
          {data.length > 0 ? (
            <Background px="$4" py="$2">
              {addConnectionButton}
            </Background>
          ) : null}
          {typeof idToDelete === "number" ? (
            <DeleteConnectionDialog
              toDeleteId={idToDelete}
              onCancelDelete={onCancelDelete}
              onSuccess={onCancelDelete}
            />
          ) : null}
        </React.Fragment>
      }
      renderItem={({ item }) => {
        const handleDelete = () => setIdToDelete(item.id)
        return (
          // TODO: Figure out overflow here
          <ListItemCard>
            <DeviceConnectionDisplay onPressDevice={onPressDevice} {...item} />
            <Row flexWrap="wrap-reverse" justifyContent="space-between" mt="$2">
              <Box>
                <Button
                  IconComponent={AppIcons.Disconnect}
                  size="sm"
                  text={t("disconnect")}
                  variant="danger"
                  onPress={withPermissions({
                    callback: handleDelete,
                    required: "canManageCustomTriggers",
                  })}
                />
              </Box>
              <ToggleNotify
                connectionId={item.id}
                currentValue={item.notify === true}
              />
            </Row>
          </ListItemCard>
        )
      }}
      {...rest}
    />
  )
}
