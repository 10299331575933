import { COLORS } from "./theme"

export type TextColorScheme =
  | "darkText"
  | "error"
  | "lightText"
  | "primary"
  | "secondary"

export function getTextColorFromColorScheme({
  colorScheme,
  isDark,
}: {
  colorScheme: TextColorScheme
  isDark: boolean
}): string {
  let color: string | undefined
  switch (colorScheme) {
    case "darkText": {
      color = COLORS.$textDark.primary
      break
    }
    case "lightText": {
      color = COLORS.$textLight.primary
      break
    }
    case "primary":
    case "secondary": {
      const colors = isDark ? COLORS.$textLight : COLORS.$textDark
      color = colors[colorScheme]
      break
    }
    case "error": {
      color = COLORS.$error[500]
      break
    }
  }
  return color
}
