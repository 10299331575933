import React from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { CreateDeviceConfiguration, DeviceOwnership } from "@fhq/app"
import { Box } from "@fhq/app/components/Box"

import { Environment } from "./pages/base/base"
import { DeviceProfileLayout } from "./pages/DeviceProfileLayout"

import type { RouteObject } from "react-router-dom"

const ConfiguredSensorsListPage = React.lazy(
  async () => import("./pages/ConfiguredSensorListPage"),
)
const EditSensorFieldPage = React.lazy(
  async () => import("./pages/EditSensorFieldPage"),
)
const DeviceSensorFieldsListPage = React.lazy(
  async () => import("./pages/DeviceSensorFieldsListPage"),
)
const CreateConfigurationPage = React.lazy(
  async () => import("./pages/CreateConfigurationPage"),
)

function ChooseInstallationTypePage() {
  const navigate = useNavigate()
  return (
    <Box flex={1} id="create-configuration">
      <CreateDeviceConfiguration.ChooseInstallationType onChange={navigate} />
    </Box>
  )
}

export const DeviceConfigurationRoutes: RouteObject = {
  children: [
    { element: <ConfiguredSensorsListPage />, index: true },
    {
      element: (
        <DeviceOwnership.CreateDeviceShareCode
          baseUrl={Environment.linkUrls.baseUrl}
        />
      ),
      path: "create-share-code",
    },
    {
      children: [
        { element: <CreateConfigurationPage />, path: ":installationType" },
        { element: <ChooseInstallationTypePage />, index: true },
      ],
      path: "create",
    },
    {
      children: [
        { element: <DeviceSensorFieldsListPage />, index: true },
        { element: <EditSensorFieldPage />, path: ":fieldName" },
      ],
      path: ":sensorName",
    },
  ],
  element: (
    <DeviceProfileLayout id="device-configuration">
      <Outlet />
    </DeviceProfileLayout>
  ),
}
