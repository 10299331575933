import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { AppText } from "../Text"
import { SPACING } from "../theme"
import { COLORS } from "../theme/colors"
import { useFormControlContext } from "./base"

import type { AppTextProps } from "../Text"

const styles = StyleSheet.create({
  root: {
    color: COLORS.$invalid,
    marginTop: SPACING.$2,
  },
})

export function ErrorMessage({
  children,
  isInvalid,
  style,
  ...rest
}: AppTextProps & { isInvalid?: boolean }) {
  const ctx = useFormControlContext()

  if (typeof isInvalid === "undefined") {
    isInvalid = ctx.isInvalid
  }

  const styleFinal = useStyle(() => [styles.root, style], [style])
  return isInvalid ? (
    <AppText
      accessibilityLabelledBy={ctx.id}
      accessibilityRole="alert"
      {...rest}
      style={styleFinal}
    >
      {children}
    </AppText>
  ) : null
}
