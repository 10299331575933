import { Box } from "@fhq/app/components"
import { NotFound404 } from "@fhq/app/NotFound404"

import PageContainer from "./base/PageContainer"

/**
 *
 */
export default function Page404(): React.JSX.Element {
  return (
    <PageContainer flex={1} id="page-404">
      <Box m="auto">
        <NotFound404 />
      </Box>
    </PageContainer>
  )
}
