import { makeStore } from "@fhq/app"
import * as Sentry from "@sentry/react"

import { SEGMENT_CLIENT } from "./Analytics"
import { Environment } from "./base"

import type { RootState } from "@fhq/app"

// Store
export const STORE = makeStore({
  analyticsClient: SEGMENT_CLIENT,
  appName: "FarmHQ Web",
  appVersion: Environment.appVersion,
  enableReduxLogger: false,
  environmentInfo: Environment,
  initialState: undefined,
  isAnalyticsEnabled: Environment.targetDatabaseName === "PROD" && !__DEV__,
  isEndToEndTest: Environment.isEndToEndTest,
  localhost: Environment.localhost,
  logLevel: Environment.logLevel,
  sentryReduxEnhancer: Sentry.createReduxEnhancer({
    stateTransformer: (state: RootState) => state,
  }) as unknown,
  targetDatabaseName: Environment.targetDatabaseName,
})
