import {
  createAction,
  createSelector,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit"

import { clickCancel, setActiveFeature } from "./actions"
import {
  readLocalStorageAsync,
  setDeviceStatusFilterKey,
} from "./async-storage"
import { deleteEventActionTriggersAsync } from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import { logger } from "./logger"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { Reducer } from "@reduxjs/toolkit"
import type { StoredValues } from "./async-storage"
import type { StatusMapFeatureName } from "./constants"
import type {
  DeviceIdProps,
  FieldIdProps,
  ReelRunIdProps,
  SelectedItem,
} from "./types"

import type { RootState } from "./root.reducer"
export const MAP_LIST_TYPES = ["devices", "fields"] as const

export interface MapVisibilityState {
  showFieldLabels?: boolean
  showRunCompletionText?: boolean
}

export interface StatusMapState extends MapVisibilityState {
  deviceStatusFilterKey: StoredValues["deviceStatusFilterKey"]
  listType: (typeof MAP_LIST_TYPES)[number]
  activeFeature?: StatusMapFeatureName
  selectedItem?: SelectedItem
}

type SetVisibilityPayload = Partial<MapVisibilityState>

export const setVisibility =
  createAction<SetVisibilityPayload>("SET_VISIBILITY")

export const changeMapListView = createAction<{
  value: StatusMapState["listType"]
}>("CHANGE_LIST_VIEW")

export const clearState = createAction("CLEAR_STATE")

export const selectDevice = createAction<DeviceIdProps>("SELECT_DEVICE")

export const selectField = createAction<FieldIdProps>("SELECT_FIELD")

export const selectReelRun = createAction<ReelRunIdProps>("SELECT_REEL_RUN")

export const selectDeviceActivity = createAction<DeviceIdProps>(
  "SELECT_DEVICE_ACTIVITY",
)

export const selectDeviceConnection = createAction<{
  id: number
}>("SELECT_CONNECTION")

export const deselectItem = createAction<SelectedItem | undefined>(
  "DESELECT_ITEM",
)
export const clearSelectedDevice = createAction<string | undefined>(
  "CLEAR_SELECTED_DEVICE",
)
export const clearSelectedField = createAction<number | undefined>(
  "CLEAR_SELECTED_FIELD",
)
export const clearSelectedReelRun = createAction<number | undefined>(
  "CLEAR_SELECTED_RUN",
)

const initialState: StatusMapState = {
  deviceStatusFilterKey: "all",
  listType: "devices",
  showFieldLabels: false,
  showRunCompletionText: false,
}

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(readLocalStorageAsync.fulfilled, (state, { payload }) => {
        state.deviceStatusFilterKey = payload.deviceStatusFilterKey
      })
      .addCase(setDeviceStatusFilterKey.pending, (state, action) => {
        state.deviceStatusFilterKey = action.meta.arg
      })
      .addCase(clearSelectedDevice, (state, { payload }) => {
        if (state.selectedItem && state.selectedItem.kind === "device") {
          const isMatch = state.selectedItem.id === payload
          if (typeof payload === "undefined" || isMatch) {
            state.selectedItem = undefined
          }
        }
      })
      .addCase(clearSelectedField, (state, { payload }) => {
        if (state.selectedItem && state.selectedItem.kind === "field") {
          const isMatch = state.selectedItem.id === payload
          if (typeof payload === "undefined" || isMatch) {
            state.selectedItem = undefined
          }
        }
      })
      .addCase(clearSelectedReelRun, (state, { payload }) => {
        if (state.selectedItem && state.selectedItem.kind === "reelRun") {
          const isMatch = state.selectedItem.id === payload
          if (typeof payload === "undefined" || isMatch) {
            state.selectedItem = undefined
          }
        }
      })
      .addCase(selectDevice, (state, { payload }) => {
        state.selectedItem = { id: payload.deviceId, kind: "device" }
        state.activeFeature = undefined
      })
      .addCase(selectField, (state, { payload }) => {
        state.selectedItem = { id: payload.fieldId, kind: "field" }
      })
      .addCase(selectReelRun, (state, { payload }) => {
        state.selectedItem = { id: payload.reelRunId, kind: "reelRun" }
      })
      .addCase(selectDeviceConnection, (state, { payload }) => {
        state.selectedItem = { id: payload.id, kind: "deviceConnection" }
      })
      .addCase(selectDeviceActivity, (state, { payload }) => {
        state.selectedItem = { id: payload.deviceId, kind: "deviceActivity" }
      })
      .addCase(deleteEventActionTriggersAsync.fulfilled, (state) => {
        if (state.selectedItem?.kind === "deviceConnection") {
          state.selectedItem = undefined
        }
      })
      .addCase(setVisibility, (state, { payload }) => {
        state.showFieldLabels = payload.showFieldLabels ?? state.showFieldLabels
        state.showRunCompletionText =
          payload.showRunCompletionText ?? state.showRunCompletionText
      })
      .addCase(deselectItem, (state) => {
        state.selectedItem = undefined
      })
      .addCase(changeMapListView, (state, { payload }) => {
        state.listType = payload.value
        state.activeFeature = "item-list"
      })
      .addCase(setActiveFeature, (state, { payload }) => {
        if (typeof payload === "string") {
          state.activeFeature = payload
        } else if (payload) {
          state.activeFeature = payload.value
        } else {
          state.activeFeature = payload
        }
        state.selectedItem = undefined
      })
      .addMatcher(
        isAnyOf(selectDevice, selectField, selectReelRun),
        (state) => {
          state.activeFeature = undefined
        },
      )
      .addMatcher(
        isAnyOf(clearState, clickCancel, loadActiveFarmAsync.pending),
        (state) => {
          state.activeFeature = undefined
          state.selectedItem = undefined
        },
      ),
  initialState,
  name: "statusMap",
  reducers: {},
})

const statusMap: Reducer<StatusMapState> = slice.reducer
export default statusMap

function getSelectedItem(state: RootState) {
  return state.statusMap.selectedItem
}

export const getSelectedRunIdFromState = createSelector(
  getSelectedItem,
  (selectedItem) => {
    if (selectedItem?.kind === "reelRun") {
      return selectedItem.id
    }
    return undefined
  },
)
export const getSelectedFieldIdFromState = createSelector(
  getSelectedItem,
  (selectedItem) => {
    if (selectedItem?.kind === "field") {
      return selectedItem.id
    }
    return undefined
  },
)
export const getSelectedDeviceIdFromState = createSelector(
  getSelectedItem,
  (selectedItem) => {
    if (selectedItem?.kind === "device") {
      return selectedItem.id
    }
    return undefined
  },
)

export const getSelectedDeviceConnectionIdFromState = createSelector(
  getSelectedItem,
  (selectedItem) => {
    if (selectedItem?.kind === "deviceConnection") {
      return selectedItem.id
    }
    return undefined
  },
)

export const getSelectedDeviceActivityIdFromState = createSelector(
  getSelectedItem,
  (selectedItem) => {
    if (selectedItem?.kind === "deviceActivity") {
      return selectedItem.id
    }
    return undefined
  },
)
export function getShowRunProgressTextFromState(state: RootState) {
  return state.statusMap.showRunCompletionText === true
}
export function getAreFieldLabelsVisibleFromState(state: RootState) {
  return state.statusMap.showFieldLabels === true
}
export function getActiveFeatureNameFromState(state: RootState) {
  return state.statusMap.activeFeature
}
export function getStatusMapListViewFromState(state: RootState) {
  return state.statusMap.listType
}
export const getShowAllDevicesFromState = (state: RootState) =>
  state.statusMap.deviceStatusFilterKey === "all"

export function useDeviceStatusFilterKey() {
  const dispatch = useRootDispatch()
  const showAllDevices = useRootSelector(getShowAllDevicesFromState)
  return {
    onToggleShowAllDevices: () => {
      dispatch(
        setDeviceStatusFilterKey(showAllDevices ? "visible" : "all"),
      ).catch((error) => {
        logger.error(error)
      })
    },
    showAllDevices,
  }
}
