import React from "react"
import { useTranslation } from "react-i18next"
import { SectionList, StyleSheet } from "react-native"

import { AppIcons, Box, Divider, ICON_SIZES, TitleWithIcon } from "./components"
import { Row } from "./components/Row"
import { AppText } from "./components/Text"
import { COLORS } from "./components/theme"
import { DEVICE_PERMISSIONS, FARM_PERMISSIONS } from "./permissions"

import type { FarmUserPermissionName, FarmUserPermissions } from "./permissions"

function keyExtractor(
  item: {
    key: FarmUserPermissionName
    value: boolean
  },
  index: number,
): string {
  return `${item.key}${index}`
}

const ItemSeparatorComponent = () => <Divider my="$0.5" />
const renderSectionFooter = () => <Box mb="$4" />

const styles = StyleSheet.create({
  contentContainer: {
    // padding: SPACING.$4,
  },
})

/**
 * Displays permissions by section
 */
export function FarmUserPermissionsSectionList({
  ListFooterComponent,
  ListHeaderComponent,
  values,
}: {
  values: Partial<FarmUserPermissions>
  ListFooterComponent?: JSX.Element | null
  ListHeaderComponent?: JSX.Element | null
}) {
  const { t } = useTranslation("farmUserPermissions")
  return (
    <SectionList
      ItemSeparatorComponent={ItemSeparatorComponent}
      ListFooterComponent={ListFooterComponent}
      ListHeaderComponent={ListHeaderComponent}
      contentContainerStyle={styles.contentContainer}
      keyExtractor={keyExtractor}
      renderSectionFooter={renderSectionFooter}
      renderItem={({ item }) => {
        return (
          <Row>
            <AppText style={{ flex: 1 }}>
              {t(`${item.key}.displayName`)}
            </AppText>
            {item.value ? (
              <AppIcons.CheckMark
                color={COLORS.$success[500]}
                size={ICON_SIZES.$xl}
              />
            ) : (
              <AppIcons.Cross size={ICON_SIZES.$xl} />
            )}
          </Row>
        )
      }}
      renderSectionHeader={({ section: { IconComponent, title } }) => {
        return <TitleWithIcon IconComponent={IconComponent} titleText={title} />
      }}
      sections={[
        {
          IconComponent: "Farm" as const,
          data: (FARM_PERMISSIONS.getKeys() as FarmUserPermissionName[]).map(
            (key) => ({
              key,
              value: Boolean(values[key]),
            }),
          ),
          title: t("farmPermissionsTitle"),
        },
        {
          IconComponent: "DeviceGeneric" as const,
          data: (DEVICE_PERMISSIONS.getKeys() as FarmUserPermissionName[]).map(
            (key) => ({
              key,
              value: Boolean(values[key]),
            }),
          ),
          title: t("devicePermissionsTitle"),
        },
      ]}
    />
  )
}
