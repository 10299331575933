import React from "react"
import { Pressable, StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"
import { Link, useNavigate } from "react-router-dom"

import { EnvironmentIndicator, FarmHqLogo, testIds } from "@fhq/app"
import { useScreenSizeBreakpoint } from "@fhq/app/app-state.reducer"
import {
  AppText,
  Box,
  IconButton,
  renderIconFromIconProp,
  Row,
  useIsDarkMode,
} from "@fhq/app/components"
import {
  COLORS,
  NAV_THEME,
  SIZES,
  SPACING,
  Z_INDEX,
} from "@fhq/app/components/theme"

import { AppbarMenu, useAppbarPages } from "./AppbarMenu"
import { Environment } from "./base"
import { useCurrentRoutePath } from "./useRoutePath"

import type { ViewProps } from "react-native"

const STYLES: { [key in "logoLink" | "pageLink"]: React.CSSProperties } = {
  logoLink: {
    flex: 1,
  },
  pageLink: {
    textTransform: "capitalize",
  },
}

const styles = StyleSheet.create({
  logo: {
    height: "100%",
    marginVertical: "auto",
    width: SIZES.$28,
  },
  pageLink: {
    marginHorizontal: SPACING.$2,
  },
})

function useCurrentPageMatch() {
  const currentPage = useCurrentRoutePath()
  return (link: { to: string }) => {
    let isSelected = false
    if (link.to === "/") {
      isSelected = currentPage === "/"
    } else if (currentPage.startsWith(link.to)) {
      isSelected = true
    }

    return isSelected
  }
}

export function Appbar({ children, ...rest }: ViewProps): React.JSX.Element {
  const navigate = useNavigate()
  const currentScreenSize = useScreenSizeBreakpoint()
  const pages = useAppbarPages()

  const isDark = useIsDarkMode()

  let linkColor = COLORS.$gray[500]
  let selectedColor = COLORS.$blue[600]
  let backgroundColor = NAV_THEME.card.light
  if (isDark) {
    linkColor = COLORS.$gray[200]
    selectedColor = COLORS.$primary[600]
    backgroundColor = NAV_THEME.card.dark
  }

  const getIsMatch = useCurrentPageMatch()

  return (
    <View
      {...testIds("app-bar")}
      style={useStyle(() => {
        return [
          {
            backgroundColor,
            height: SIZES.$toolbar,
            paddingHorizontal: SPACING.$4,
            width: "100%",
            zIndex: Z_INDEX.appBar,
          },
        ]
      }, [backgroundColor])}
      {...rest}
    >
      <Row
        alignItems="center"
        flexWrap="nowrap"
        justifyContent="space-between"
        minH="$toolbar"
      >
        <Box h="$full" maxW="$48" overflow="visible" position="relative">
          <Link replace style={STYLES.logoLink} to="/">
            <FarmHqLogo
              name="lockup-inline-nopad.png"
              resizeMode="contain"
              style={styles.logo}
            />
          </Link>
          <Box bottom="$2" position="absolute" right="$0">
            <AppText colorScheme="secondary" fontSize="$2xs">
              {Environment.appVersion}
            </AppText>
          </Box>
          <Box bottom="$2" left="$7" position="absolute">
            <EnvironmentIndicator
              fontSize="$2xs"
              targetDatabaseName={Environment.targetDatabaseName}
            />
          </Box>
        </Box>
        {currentScreenSize === "sm" || currentScreenSize === "md" ? (
          <Row ml="auto" mx="$-1">
            {pages.map((link) => {
              const extracted = getIsMatch(link)
              return (
                <Box key={link.id} mx="$1">
                  <IconButton
                    IconComponent={link.IconComponent}
                    iconColor={getIsMatch(link) ? selectedColor : linkColor}
                    id={link.id}
                    variant={extracted ? "outline" : "text"}
                    onPress={() => navigate(link.to)}
                  />
                </Box>
              )
            })}
          </Row>
        ) : null}
        {currentScreenSize === "lg" || currentScreenSize === "xl" ? (
          <Row alignItems="flex-start" ml="auto">
            {pages.map((link) => {
              const color = getIsMatch(link) ? selectedColor : linkColor
              return (
                <Pressable
                  {...testIds(`${link.id}-link`)}
                  key={link.id}
                  accessibilityLabel={`Link to ${link.id}`}
                  accessibilityRole="link"
                  style={styles.pageLink}
                  onPress={() => navigate(link.to)}
                >
                  <Row>
                    <Box mr="$1">
                      {renderIconFromIconProp(link.IconComponent, { color })}
                    </Box>
                    <AppText color={color}>{link.children}</AppText>
                  </Row>
                </Pressable>
              )
            })}
          </Row>
        ) : null}
        <AppbarMenu pages={pages} />
      </Row>
      {children}
    </View>
  )
}
