import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { useScreenSizeBreakpoint } from "./app-state.reducer"
import { Card } from "./components"

import type { CardProps } from "./components"
type ResponsiveCardProps = CardProps

const styles = StyleSheet.create({
  smallScreen: {
    borderRadius: 0,
  },
})

export function ResponsiveCard({ style, ...rest }: ResponsiveCardProps) {
  const screenSize = useScreenSizeBreakpoint()
  const isSmallScreen = screenSize === "xs" || screenSize === "sm"
  return (
    <Card
      {...rest}
      style={useStyle(() => {
        return [style, isSmallScreen && styles.smallScreen]
      }, [isSmallScreen, style])}
    />
  )
}
