import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { Slider as BaseSlider } from "@miblanchard/react-native-slider"

import { AppText } from "./components"
import { COLORS } from "./components/theme"
import { humanizeAzimuth } from "./geo"
import { isValidNumber } from "./type-guards"
import { useThemedValue } from "./useThemedValue"

import type { AppTextProps, NoChildren } from "./components"
import type { WithOptionalTestId } from "./components/test-id"
import type { Dimensions } from "@miblanchard/react-native-slider/lib/types"

import type { Animated, ImageSourcePropType, ViewStyle } from "react-native"
type SliderValue = number | [number, number]
type SliderOnChangeCallback<V extends SliderValue> = (value: V) => void
interface SliderProps<V extends SliderValue> {
  maximumValue: number
  animateTransitions?: boolean
  animationConfig?: {
    spring?: Animated.AnimatedProps<ViewStyle>
    timing?: Animated.AnimatedProps<ViewStyle>
  }
  animationType?: "spring" | "timing"
  containerStyle?: ViewStyle
  debugTouchArea?: boolean
  disabled?: boolean
  maximumTrackTintColor?: string
  minimumTrackTintColor?: string
  minimumValue?: number
  onSlidingComplete?: SliderOnChangeCallback<V>
  onSlidingStart?: SliderOnChangeCallback<V>
  onValueChange?: SliderOnChangeCallback<V>
  renderAboveThumbComponent?: (index: number) => React.ReactNode
  renderThumbComponent?: () => React.ReactNode
  renderTrackMarkComponent?: (index: number) => React.ReactNode
  step?: number
  thumbImage?: ImageSourcePropType
  thumbStyle?: ViewStyle
  thumbTintColor?: string
  thumbTouchSize?: Dimensions
  trackClickable?: boolean
  trackMarks?: number[]
  trackStyle?: ViewStyle
  value?: V
  vertical?: boolean
}

const styles = StyleSheet.create({
  containerStyle: {
    height: 24,
  },
})

function makeSlider<V extends SliderValue>(
  convertValue: (value: SliderValue) => V | undefined,
): React.ForwardRefExoticComponent<
  React.RefAttributes<BaseSlider> & SliderProps<V>
> {
  return React.forwardRef(function RenderSlider(
    {
      onSlidingComplete,
      onSlidingStart,
      onValueChange,
      ...rest
    }: SliderProps<V>,
    ref,
  ) {
    const getValue = useThemedValue()
    const handleSlidingStart = React.useCallback(
      (next: SliderValue): void => {
        if (onSlidingStart) {
          const converted = convertValue(next)
          if (typeof converted !== "undefined") {
            return onSlidingStart(converted)
          }
        }
        return undefined
      },
      [onSlidingStart],
    )
    const handleSlidingComplete = React.useCallback(
      (next: SliderValue): void => {
        if (onSlidingComplete) {
          const converted = convertValue(next)
          if (typeof converted !== "undefined") {
            return onSlidingComplete(converted)
          }
        }
        return undefined
      },
      [onSlidingComplete],
    )
    const handleValueChange = React.useCallback(
      (next: SliderValue): void => {
        if (onValueChange) {
          const converted = convertValue(next)
          if (typeof converted !== "undefined") {
            return onValueChange(converted)
          }
        }
        return undefined
      },
      [onValueChange],
    )
    type SliderCb = (next: number[] | number) => void

    const thumbStyle = useStyle(() => {
      return {
        backgroundColor: getValue({
          dark: COLORS.$textLight.secondary,
          light: COLORS.$textDark.secondary,
        }),
      }
    }, [getValue])
    return (
      <BaseSlider
        ref={ref}
        containerStyle={styles.containerStyle}
        data-testid="slider"
        minimumValue={0}
        step={1}
        thumbStyle={thumbStyle}
        onSlidingComplete={handleSlidingComplete as SliderCb}
        onSlidingStart={handleSlidingStart as SliderCb}
        onValueChange={handleValueChange as SliderCb}
        {...rest}
      />
    )
  })
}

export const SingleValueSlider = makeSlider((value) => {
  if (isValidNumber(value)) {
    return value
  }
  if (Array.isArray(value)) {
    const first = value[0]
    if (isValidNumber(first)) {
      return first
    }
  }
  return undefined
})

export const DoubleValueSlider = makeSlider((value) => {
  if (Array.isArray(value)) {
    const [first, second] = value
    if (isValidNumber(first) && isValidNumber(second)) {
      return [first, second] as [number, number]
    }
  }
  return undefined
})

export const AZIMUTH_MARKS = [270, 0, 90, 180] as const
interface AzimuthDisplayTextProps
  extends WithOptionalTestId<NoChildren<AppTextProps>> {
  value: number | undefined
  fallback?: string | null
}

export function AzimuthDisplayText({
  fallback,
  id = "azimuth-text",
  value,
  ...rest
}: AzimuthDisplayTextProps): React.JSX.Element {
  const { t } = useTranslation()
  return (
    <AppText id={id} {...rest}>
      {typeof value === "number"
        ? humanizeAzimuth(value)
        : fallback ?? t("dragSlider")}
    </AppText>
  )
}
