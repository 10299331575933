import { logger } from "../logger"
import { isTruthyString, isValidNumber } from "../type-guards"

export type RgbValues = {
  [K in "alpha" | "blue" | "green" | "red"]: number
}

/**
 * Extract red, green, and blue values from an RGB string
 */
export function parseRgbValues(rgb: string): RgbValues | undefined {
  try {
    const [red, green, blue, alpha] = rgb
      // remove rgb(
      .replace(/rgba?\(/, "")
      // remove )
      .replace(")", "")
      // make array
      .split(",")
      // stringify numbers
      .map((n): number => Number.parseFloat(n))
    if (isValidNumber(blue) && isValidNumber(green) && isValidNumber(red)) {
      return { alpha: alpha ?? 1, blue, green, red }
    }
    throw new Error(
      `Invalid color values in rgb ${red ?? "undefined"},${
        green ?? "undefined"
      },${blue ?? "undefined"}`,
    )
  } catch (error) {
    logger.error(`[theme-utils.parseRgbValues]`, error)
  }
  return undefined
}

export function rgbaToHex(rgb: string): string | undefined {
  const values = parseRgbValues(rgb)
  if (values) {
    const { alpha, blue, green, red } = values
    return `#${[red, green, blue, alpha]
      .map((x) => x.toString(16).padStart(2, "0"))
      .join("")}`
  }
  return undefined
}

type RgbaString = `rgba(${string}, ${string}, ${string}, ${string})`

export function hexToRgb(hex: string): RgbaString {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([\da-f])([\da-f])([\da-f])$/i
  hex = hex.replace(
    shorthandRegex,
    function (_m, r: string, g: string, b: string) {
      return r + r + g + g + b + b
    },
  )

  const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i
    .exec(hex)
    ?.map((n) => Number.parseInt(n, 16).toString())
  if (result) {
    const red = result[1]
    const green = result[2]
    const blue = result[3]
    const alpha = result[4] ?? 1
    if (isTruthyString(red) && isTruthyString(green) && isTruthyString(blue)) {
      return `rgba(${red}, ${green}, ${blue}, ${alpha})`
    }
  }
  throw new Error(`Failed to convert ${hex}`)
}

type ColorOpacityInput =
  | string
  | `#${string}`
  | `rgb(${number | string},${number | string},${number | string})`
  | `rgba(${number | string},${number | string},${number | string},${
      | number
      | string})`
export function adjustOpacityOfColor({
  color,
  opacity,
}: {
  color: ColorOpacityInput
  opacity: number
}): RgbaString | undefined {
  const rgb = color.startsWith("#") ? hexToRgb(color) : color
  const values = parseRgbValues(rgb)
  if (values) {
    const { blue, green, red } = values
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`
  }
  return undefined
}

export function truncateColor(value: number): void {
  if (value < 0) {
    value = 0
  }
  if (value > 255) {
    value = 255
  }
}
